<div class="mb-5 mb-lg-3">
  <p *ngIf="status === companyArchiveStatus.Active && isCenter" class="py-5 py-lg-2" i18n="@@CostActivationMsg">
    Are you sure you want to activate this cost?</p>
  <p *ngIf="status === companyArchiveStatus.Archived && isCenter" class="py-5 py-lg-2" i18n="@@CostArchiveMsg">
    Are you sure you want to archive this cost?</p>
  <p *ngIf="status === companyArchiveStatus.Active && !isCenter" class="py-5 py-lg-2" i18n="@@CostActivationMsg">
    Are you sure you want to activate this label?</p>
  <p *ngIf="status === companyArchiveStatus.Archived && !isCenter" class="py-5 py-lg-2" i18n="@@CostArchiveMsg">
    Are you sure you want to archive this label?</p>

  <div class="d-flex justify-content-end" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn px-5 font-14" (click)="cancel()" [disabled]="loader" i18n="@@cancelBtn">
      Cancel </button>
    <button class="blue_btn px-5"
      [ngClass]="{'m-progress': loader,  'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
      (click)="changeActivation();" [i18n]="status !== companyArchiveStatus.Active ? '@@activate' : '@@archive'">
      {{status === companyArchiveStatus.Active ? 'Activate' : 'Archive'}}
    </button>
  </div>

  <div *ngIf="dataService.mobileView">
    <button class="blue_btn px-5 w-100 mb-3" [ngClass]="{'m-progress': loader }" (click)="changeActivation();"
      [i18n]="status !== companyArchiveStatus.Active ? '@@activate' : '@@archive'">
      {{status === companyArchiveStatus.Active ? 'Activate' : 'Archive'}}
    </button>
    <button class="btn-outline-gray btn px-5 font-14 w-100" (click)=" cancel()" [disabled]="loader" i18n="@@cancelBtn">
      Cancel </button>
  </div>
</div>