import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { Observable } from "rxjs";
import { take, exhaustMap, map } from "rxjs/operators";
import { AuthServiceMain } from "./auth.service";
import { DataService } from "../services/data.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  event;
  constructor(
    private authService: AuthServiceMain,
    @Inject(PLATFORM_ID) private platformId,
    public Service: DataService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let sendeingHeaders = req.headers;

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 767) {
        sendeingHeaders = sendeingHeaders.append(
          "Client-Channel",
          "desktop-web"
        );
      } else {
        sendeingHeaders = sendeingHeaders.append(
          "Client-Channel",
          "mobile-web"
        );
      }
    }
    if (localStorage.getItem("utm_campaign")) {
      sendeingHeaders = sendeingHeaders.append(
        "utm_campaign",
        localStorage.getItem("utm_campaign")
      );
    }
    if (localStorage.getItem("utm_source")) {
      sendeingHeaders = sendeingHeaders.append(
        "utm_source",
        localStorage.getItem("utm_source")
      );
    }

    if (localStorage.getItem("utm_medium")) {
      sendeingHeaders = sendeingHeaders.append(
        "utm_medium",
        localStorage.getItem("utm_medium")
      );
    }
    return this.authService.user.pipe(
      take(1),
      exhaustMap((user) => {
        const userDataMain = localStorage.getItem("user");

        if (user !== null && this.Service.sendTokenInApiRequest(req.url)) {
          sendeingHeaders = sendeingHeaders.append("Authorization", user.token);
        } else if (
          localStorage.getItem("user") &&
          this.Service.sendTokenInApiRequest(req.url)
        ) {
          sendeingHeaders = sendeingHeaders.append(
            "Authorization",
            userDataMain
          );
        } else {
          if (localStorage.getItem("leads_email")) {
            sendeingHeaders = sendeingHeaders.append(
              "leads_email",
              localStorage.getItem("leads_email")
            );
          }
        }

        const modifiedReq = req.clone({
          headers: sendeingHeaders,
        });
        return next.handle(modifiedReq).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.event = event;

              if (
                (this.event.body.errorCode === -1 &&
                  !this.event.url.includes("company") &&
                  !this.event.url.includes("user/logout") &&
                  this.authService.isLogedIn &&
                  !this.Service.isCompany()) ||
                ((this.event.body.errorCode === -1 ||
                  this.event.body.errorCode === 403) &&
                  this.event.url.includes("company") &&
                  !this.event.url.includes("company/logout") &&
                  this.authService.isLogedIn &&
                  this.Service.isCompany())
              ) {
                this.authService.removeUser();
                //   return
              }
            }
            return event;
          })
        );
      })
    );
  }
}
