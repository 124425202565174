<div *ngIf="booking">
  <div class="row grey-bg font-16">
    <div class="col-6">
      <img  width="145" height="35"  src="assets/img/logo.svg">
    </div>
    <div class="col-6 gray-text" [ngClass]="{'text-right': Service.isSiteLtr(), 'text-left': Service.isSiteRtl()}">
      <p class="mb-0">https://www.safarhub.com</p>
      <p>41829 Albrae Street, Fremont, CA 94538</p>
    </div>
  </div>
  <p class="booking-type border-bottom gray-primaray-text bolder pb-4">
    <span *ngIf="!Service.inactiveWorkspace && bookingType === 'cancelled'" i18n="@@activeBookingCanceled">Your booking is canceled</span>
    <span *ngIf="!Service.inactiveWorkspace && bookingType === 'completed'" i18n="@@activeBookingCompleted">Your booking is completed</span>
    <span *ngIf="!Service.inactiveWorkspace && bookingType === 'confirmed'" i18n="@@activeBookingConfirmed">Your booking is confirmed</span>

    <span *ngIf="Service.inactiveWorkspace && bookingType === 'cancelled'" i18n="@@inActiveBookingCanceled">Booking is canceled</span>
    <span *ngIf="Service.inactiveWorkspace && bookingType === 'completed'" i18n="@@inActiveBookingCompleted">Booking is completed</span>
    <span *ngIf="Service.inactiveWorkspace && bookingType === 'confirmed'" i18n="@@inActiveBookingConfirmed">Booking is confirmed</span>
  </p>
  <p class="font-16 gray-text-dark py-4 mt-3" *ngIf="bookingType !== 'canceled'"><ng-container i18n="@@printConfirmFlight1">We're confirming your flight to {{summaryDeparture.arrivalAirport.city}} on </ng-container> <span class="gray-text-dark " [innerHTML]="Service.formatDateMMMdy(summaryDeparture.departureDate)"></span>. <ng-container i18n="@@printConfirmFlight2">Your SafarHub confirmation number is {{this.booking.hrsReferenceNumber}}</ng-container></p>
  <p class="font-16 gray-text-dark py-4 mt-3" *ngIf="bookingType === 'canceled'" i18n="@@printCancelFlight">Your flight has been cancelled, all the details of your flight cancellation listed below.</p>
  <p class="font-18 gray-primaray-text bold-600">{{summaryDeparture.departureAirport.city}} ({{summaryDeparture.departureAirport.iata}}) <span class="text-lowercase" i18n="@@to">to</span> {{summaryDeparture.arrivalAirport.city}} ({{summaryDeparture.arrivalAirport.iata}})</p>
  <p class="mb-0"><ng-container i18n="@@departOn">Departs on</ng-container>&nbsp;<span class="gray-text-dark " [innerHTML]="Service.formatDateMMMdy(summaryDeparture.departureDate)"></span>&nbsp;<ng-container i18n="@@at">at</ng-container>&nbsp;<span [innerHTML]="this.Service.formatDateTime12Hours(summaryDeparture.departureDate)">10:15pm</span></p>
  <ng-container *ngIf="summaryReturn">
    <p class="font-18 gray-primaray-text bold-600 mt-4">{{summaryReturn.departureAirport.city}} ({{summaryReturn.departureAirport.iata}}) <span class="text-lowercase" i18n="@@to">to</span> {{summaryReturn.arrivalAirport.city}} ({{summaryReturn.arrivalAirport.iata}})</p>
    <p class="mb-0"><ng-container i18n="@@departOn">Departs on</ng-container>&nbsp;<span class="gray-text-dark " [innerHTML]="Service.formatDateMMMdy(summaryReturn.departureDate)"></span>&nbsp;<ng-container i18n="@@at">at</ng-container>&nbsp;<span [innerHTML]="this.Service.formatDateTime12Hours(summaryReturn.departureDate)">10:15pm</span></p>
  </ng-container>



  <div class="mt-4" *ngIf="booking.tripDetails">
    <p class="gray-primaray-text font-16 mb-0">Airlines confirmation numbers</p>
    <ng-container *ngFor="let airline of booking.tripDetails.itineraryInfo.reservationItems">
      <p class="gray-text-dark font-14 mb-0">{{airline.marketingAirlineCode}} <ng-container i18n="@@airline">Airline</ng-container>: {{airline.airlinePNR}}</p>
    </ng-container>
  </div>




    <p class="gray-primaray-text font-16 mb-0 mt-4" i18n="@@theTraverlers">Travelers</p>
    <div *ngIf="booking.tripDetails" class="pb-3">
      <ng-container *ngFor="let traveler of booking.tripDetails.itineraryInfo.passengers">
        <p class="gray-text-dark font-14 mb-0">{{traveler.firstName}} {{traveler.lastName}} <span class="font-14 text-capitalize gray-text">- {{traveler.type.toLowerCase()}}</span></p>
        <p class="gray-text font-14 mb-0" *ngIf="traveler.eTicketNumber"><ng-container i18n="@@ticket">Ticket</ng-container> # {{traveler.eTicketNumber}}</p>
      </ng-container>
    </div>
    <ng-container *ngIf="!booking.tripDetails">
      <ng-container *ngIf="booking.bookRequest.adults">
        <ng-container *ngFor="let traveler of booking.bookRequest.adults">
          <p class="gray-text-dark font-14">{{traveler.firstName}} {{traveler.lastName}} <span class="font-14 text-capitalize gray-text">-&nbsp;<ng-container i18n="@@adult">Adult</ng-container></span></p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="booking.bookRequest.children">
        <ng-container *ngFor="let traveler of booking.bookRequest.children">
          <p class="gray-text-dark font-14">{{traveler.firstName}} {{traveler.lastName}} <span class="font-14 text-capitalize gray-text">-&nbsp;<ng-container i18n="@@child">Child</ng-container></span></p>
        </ng-container>
      </ng-container>
    </ng-container>





  <div class=" d-flex mt-5 pt-4 mb-3 mb-lg-0">
    <p class="primaray-text font-18 font-weight-bold"[ngClass]="{'pr-2': Service.isSiteLtr(), 'pl-2': Service.isSiteRtl()}"  *ngIf="Service.desktopView" [innerHTML]="Service.formatDateEEMMMdy(summaryDeparture.departureDate)"></p>
  <p class="gray-primaray-text font-16" *ngIf="Service.desktopView"> {{summaryDeparture.departureAirport.city}} <span class="text-lowercase" i18n="@@to">to</span> {{summaryDeparture.arrivalAirport.city}}</p>
  </div>



  <ng-container *ngFor="let flight of departureDetails ,  let i = index" >
    <div class="table-color">
      <div class="grey-bg p-4">
    <div class="d-flex">
      <img width="26.5" height="27" loading="lazy" class="max-100 img-min-width" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" [src]="flight.flightSegment.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />
      <div>
        <!--   <div class="d-flex">-->
        <p class="mb-0 font-16 gray-text-dark">{{flight.flightSegment.marketingAirlineName}} {{flight.flightSegment.marketingAirlineCode}} {{flight.flightSegment.flightNumber}}</p>
        <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus" i18n="@@noteBus">Note: This is bus service</p>
        <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train" i18n="@@noteTrain">Note: This is train service</p>
        <!--     </div>-->
        <p class="gray-text mb-0" *ngIf="flight.flightSegment.operatingAirline"><ng-container i18n="@@operatedBy">Operated by</ng-container> {{flight.flightSegment.operatingAirline.name}}</p>
        <p  class="gray-text mb-0">{{flight.flightSegment.cabinClassName}}</p>
      </div>
    </div>



    <div class="d-flex mt-3">
      <i class="icon-fly-from placeholder-text font-20" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
      <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
      <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>

      <p class="mb-0 gray-text-dark"><span [innerHTML]="this.Service.formatDateTime12Hours(flight.flightSegment.departureDateTime)">10:15pm</span><ng-container *ngIf="Service.desktopView"> - {{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</ng-container></p>
    </div>
    <div class="d-flex pb-2">
      <span class="border-right-square" [ngClass]="{'ml-2': Service.isSiteLtr(), 'mr-2': Service.isSiteRtl()}"></span>
      <div>
        <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="Service.desktopView">{{flight.flightSegment.departureAirport.city}}</p>
        <p class="mb-4 gray-text-dark" [ngClass]="{'ml-4 pl-1': Service.isSiteLtr(), 'mr-4 pr-1': Service.isSiteRtl()}" *ngIf="Service.mobileView">{{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</p>
        <p class="mb-4 font-12 gray-text" [ngClass]="{'ml-4 pl-1': Service.isSiteLtr(), 'mr-4 pr-1': Service.isSiteRtl()}"><ng-container i18n="@@duration">Duration:</ng-container>&nbsp; <span [innerHTML]="this.Service.calDuration(flight.flightSegment.journeyDuration)">2h 33m</span></p>
      </div>
    </div>
    <div class="d-flex">
      <i class="icon-fly-to placeholder-text font-18" [ngClass]="{'mr-2 pr-1': Service.isSiteLtr(), 'ml-2 pl-1': Service.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
      <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
      <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>

      <p class="mb-0 gray-text-dark"><span [innerHTML]="this.Service.formatDateTime12Hours(flight.flightSegment.arrivalDateTime)">10:15pm</span>
        <span class="font-14 font-weight-normal" *ngIf="Service.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) === 1" i18n="@@nextDay"> (Next day)</span>
        <span class="font-14 font-weight-normal" *ngIf="Service.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) > 1"> +{{Service.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)}}</span>
        <span class="font-14 font-weight-normal" *ngIf="Service.checkPreviousDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)" i18n="@@prevDay"> (Previous day)</span>
        <ng-container *ngIf="Service.desktopView"> - </ng-container><br *ngIf="Service.mobileView"/>{{flight.flightSegment.arrivalAirport.name}} ({{flight.flightSegment.arrivalAirport.iata}})</p>
    </div>
    <div class="d-flex pb-2">
      <span [ngClass]="{'ml-3': Service.isSiteLtr(), 'mr-3': Service.isSiteRtl()}"></span>
      <div>
        <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="Service.desktopView">{{flight.flightSegment.arrivalAirport.city}}</p>
      </div>
    </div>

    <div *ngIf="flight.stopDurationInMinutes" class="primaray-text font-16 mb-5 mt-4 "><i class="icon-wall-clock align-middle mr-2"></i> <span [innerHTML]="this.Service.calDuration(flight.stopDurationInMinutes)">2h 33m</span>&nbsp; <ng-container i18n="@@layoverIn">layover in</ng-container> {{flight.flightSegment.arrivalAirport.city}}<span *ngIf="!flight.flightSegment.airportChangeDescription"> ({{flight.flightSegment.arrivalAirport.iata}})</span></div>
    <div class="position-relative py-4" *ngIf="flight.flightSegment.airportChangeDescription">
      <div class="position-absolute font-12 text-white orange-bg change-stop rounded px-3">{{flight.flightSegment.airportChangeDescription}}</div>
    </div>



      </div>
    </div>
  </ng-container>


  <div *ngIf="returnDetails">
    <div class="d-flex mb-3 mb-lg-0 mt-5 pt-4">
      <p class="primaray-text font-18 font-weight-bold" [ngClass]="{'pr-2': Service.isSiteLtr(), 'pl-2': Service.isSiteRtl()}" *ngIf="Service.desktopView" [innerHTML]="Service.formatDateEEMMMdy(summaryReturn.departureDate)"></p>
    <p class="gray-primaray-text font-16" *ngIf="Service.desktopView"> {{summaryReturn.departureAirport.city}} to {{summaryReturn.arrivalAirport.city}}</p>
    </div>

    <div class="table-color">
      <div class="grey-bg p-4">
        <div>
          <ng-container *ngFor="let flight of returnDetails ,  let i = index" >
            <div class="d-flex">
              <img width="26.5" height="27" loading="lazy" class="max-100 img-min-width" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" [src]="flight.flightSegment.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />
              <div>
                <p class="mb-0 font-16 gray-text-dark">{{flight.flightSegment.marketingAirlineName}} {{flight.flightSegment.marketingAirlineCode}} {{flight.flightSegment.flightNumber}}</p>
                <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus" i18n="@@noteBus">Note: This is bus service</p>
                <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train" i18n="@@noteTrain">Note: This is train service</p>

                <p class="gray-text mb-0" *ngIf="flight.flightSegment.operatingAirline">Operated by {{flight.flightSegment.operatingAirline.name}}</p>
                <p  class="gray-text mb-0">{{flight.flightSegment.cabinClassName}}</p>
              </div>
            </div>



            <div class="d-flex mt-3">
              <i class="icon-fly-from placeholder-text font-20" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
              <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
              <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>

              <p class="mb-0 gray-text-dark"><span [innerHTML]="this.Service.formatDateTime12Hours(flight.flightSegment.departureDateTime)">10:15pm</span><ng-container *ngIf="Service.desktopView"> - {{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</ng-container></p>
            </div>
            <div class="d-flex pb-2">
              <span class="border-right-square" [ngClass]="{'ml-2': Service.isSiteLtr(), 'mr-2': Service.isSiteRtl()}" ></span>
              <div>
                <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="Service.desktopView">{{flight.flightSegment.departureAirport.city}}</p>
                <p class="mb-4 font-12 gray-text" [ngClass]="{'ml-4 pl-1': Service.isSiteLtr(), 'mr-4 pr-1': Service.isSiteRtl()}"><ng-container i18n="@@duration">Duration:</ng-container>&nbsp; <span [innerHTML]="this.Service.calDuration(flight.flightSegment.journeyDuration)">2h 33m</span></p>
              </div>
            </div>
            <div class="d-flex">
              <i class="icon-fly-to placeholder-text font-18" [ngClass]="{'mr-2 pr-1': Service.isSiteLtr(), 'ml-2 pl-1': Service.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
              <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
              <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>

              <p class="mb-0 gray-text-dark"><span [innerHTML]="this.Service.formatDateTime12Hours(flight.flightSegment.arrivalDateTime)">10:15pm</span>
                <span class="font-14 font-weight-normal" *ngIf="Service.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) === 1" i18n="@@nextDay"> (Next day)</span>
                <span class="font-14 font-weight-normal" *ngIf="Service.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) > 1"> +{{Service.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)}}</span>
                <span class="font-14 font-weight-normal" *ngIf="Service.checkPreviousDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)" i18n="@@prevDay"> (Previous day)</span>
                <ng-container *ngIf="Service.desktopView"> - </ng-container><br *ngIf="Service.mobileView"/>{{flight.flightSegment.arrivalAirport.name}} ({{flight.flightSegment.arrivalAirport.iata}})</p>
            </div>
            <div class="d-flex pb-2">
              <span [ngClass]="{'ml-3': Service.isSiteLtr(), 'mr-3': Service.isSiteRtl()}"></span>
              <div>
                <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="Service.desktopView">{{flight.flightSegment.arrivalAirport.city}}</p>
              </div>
            </div>

            <div *ngIf="flight.stopDurationInMinutes" class="primaray-text font-16 mb-5 mt-4 "><i class="icon-wall-clock align-middle mr-2"></i> <span [innerHTML]="this.Service.calDuration(flight.stopDurationInMinutes)">2h 33m</span>&nbsp; <ng-container i18n="@@layoverIn">layover in</ng-container> {{flight.flightSegment.arrivalAirport.city}}<span *ngIf="!flight.flightSegment.airportChangeDescription"> ({{flight.flightSegment.arrivalAirport.iata}})</span></div>
            <div class="position-relative py-4" *ngIf="flight.flightSegment.airportChangeDescription">
              <div class="position-absolute font-12 text-white orange-bg change-stop rounded px-3">{{flight.flightSegment.airportChangeDescription}}</div>
            </div>
          </ng-container>
        </div>

      </div>



    </div>
  </div>


  <div class="w-50 mt-5">

    <div class="p-0 mb-4" *ngIf="bookingType !== 'canceled'">
      <div class="p-3">
        <h5 class="font-18 gray-primaray-text mb-3 bold-600" [ngClass]="{'bolder' : !Service.isCompany()}" i18n="@@priceSummary">Price Summary</h5>



        <ng-container *ngFor="let traveler of booking.airItinerary.fareInfo.fareBreakdown; let travelerIndex = index">
          <ng-container *ngFor="let travelerRepeat of [].constructor(traveler.passengerTypeQuantity.quantity) ; let repeatIndex = index;" >

            <div class="discount d-flex font-16" >
              <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}" *ngIf="travelerIndex === 0"><ng-container i18n="@@traveler">Traveler</ng-container>{{repeatIndex + 1}}: <span class="text-capitalize">{{traveler.passengerTypeQuantity.type.toLowerCase()}}</span></span>
              <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"  *ngIf="travelerIndex === 1"><ng-container i18n="@@traveler">Traveler</ng-container>{{(booking.airItinerary.fareInfo.fareBreakdown[0].passengerTypeQuantity.quantity) + repeatIndex + 1}}: <span class="text-capitalize">{{traveler.passengerTypeQuantity.type.toLowerCase()}}</span></span>
              <span>${{traveler.sellingPassengerFare.totalFare.amount | number: '1.2-2'}}</span>
            </div>
            <div class="discount d-flex font-14 gray-text my-2" >
              <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}" i18n="@@flight">Flight</span> <span>${{traveler.sellingPassengerFare.baseFare.amount | number: '1.2-2'}}</span>
            </div>
            <div class="discount d-flex font-14 gray-text mb-4" >
              <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}" i18n="@@taxAndFees">Tax and fees</span>
              <span>
              <span>${{this.Service.calTaxes(traveler.sellingPassengerFare.taxes) | number: '1.2-2' }}</span>
            </span>
            </div>
          </ng-container>
        </ng-container>


      </div>



      <div class="table-color">
        <div class="grey-bg d-flex font-20 p-3">
          <span class="primaray-text" [ngClass]="{'bolder' : !Service.isCompany()}" i18n="@@Total"> Total </span>
          <span class="primaray-text bolder" [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}"><span  class="currency">${{booking.airItinerary.fareInfo.sellingTotalFares.total.amount | number: '1.0-2'}}</span><span class="amount"></span></span>
        </div>
      </div>
    </div>


    <div class="p-0 mb-4 font-16 gray-text-dark" *ngIf="bookingType === 'canceled'">
      <p>Total paid: ${{booking.airItinerary.fareInfo.sellingTotalFares.total.amount | number: '1.0-2'}}</p>
      <p>Refund amount: </p>
    </div>



  </div>


  <div class="pt-4 mt-5">
    <p class="font-18 gray-primaray-text bold-600" i18n="@@additionalInformation">Additional Information</p>
    <p>
    <p class="gray-primaray-text font-18" i18n="@@additionalFees">Additional fees</p>
    <p class="gray-text-dark" i18n="@@flightCancellation">We do not charge any change or cancellation fee. When the airline charge for such fee based on their rules and polices, the cost will be passed on you.</p>

    <p class="gray-text-dark mt-4" i18n="@@contactAirline">Please contact the airline for any additional baggage or extra services.</p>

    <p class="font-18 gray-primaray-text mt-5" i18n="@@beforeYouTravel1">Before you travel</p>
    <p class="gray-text-dark mb-4" i18n="@@photoId">Government-issued photo ID is required for airport check-in and security. </p>

    <p class="gray-text-dark mb-4" i18n="@@internationalTravel"> International Travel: Proof of citizenship is required along with all required documentation. e.g. passport, visa, transit permit...etc. </p>

    <p class="gray-text-dark" i18n="@@allNeededDocs"> Make sure you check all necessary documents before you travel. </p>

    <p class="font-18 gray-primaray-text mt-5" i18n="@@contactFor">Contact the airline for</p>
    <p class="gray-text-dark mb-0" i18n="@@beforeYouTravel5">Special meals</p>
    <p class="gray-text-dark mb-0" i18n="@@beforeYouTravel6"> Seat assignments</p>
    <p class="gray-text-dark" i18n="@@specialHelp"> Special assistance</p>

  </div>

</div>
