import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';
import {DatePipe} from "@angular/common";


@Component({
  selector: 'app-print-booking',
  templateUrl: './print-booking.component.html',
  styleUrls: ['./print-booking.component.scss']
})
export class PrintBookingComponent implements OnInit {

  constructor(public datePipe: DatePipe, public  router: Router,
              public restApi: DataService,
              private route: ActivatedRoute) {}
  /*
  data;
  booking;
  dataCancel;
    refFromURL = 'ref';
  refFromURLValue;
  identifierFromURL = 'identifier';
  identifierFromURLValue;
  type = 'LAST_NAME';
  emailVoucher = 'email';
  emailVoucherValue;
  retrieveObj ;
  allowCancellation = false;*/
  checkin;
  checkout;
  nights;
  bookingType;
  @Input() booking: any = {};
  ngOnInit() {

    this.booking = JSON.parse(this.booking);
    if (this.booking.bookingStatus === 'CANCELLED') {
      this.bookingType = 'cancelled';
    } else {
      if (this.booking.past) {
        this.bookingType = 'completed';
      } else {
        this.bookingType = 'confirmed';
      }
    }
    this.checkin = this.checkin = this.datePipe.transform(this.booking.checkinDate, 'MMM dd, y');
    this.checkout = this.checkin = this.datePipe.transform(this.booking.checkoutDate, 'MMM dd, y');
    let checkin = moment(this.booking.checkinDate).format('MMM') + ' ' + moment(this.booking.checkinDate).format('DD')
        + ', ' + moment(this.booking.checkinDate).format('YYYY');
    let checkout = moment(this.booking.checkoutDate).format('MMM') + ' ' + moment(this.booking.checkoutDate).format('DD')
        + ', ' + moment(this.booking.checkoutDate).format('YYYY');
    this.nights = Math.round(moment(checkout, 'MMM DD YYYY').diff(moment(checkin, 'MMM DD YYYY'), 'days', true));



    /*

    if (this.router.url.indexOf('voucher') >= 0) {
      this.route.queryParams.subscribe(params => {
        this.refFromURLValue = params[this.refFromURL];
        this.emailVoucherValue = params[this.emailVoucher];
      });
      this.retrieveObj = {
        customerIdentifier: {
          type: 'EMAIL',
          value: this.emailVoucherValue
        },
        referenceNumber: this.refFromURLValue
      };

    } else {
      this.route.queryParams.subscribe(params => {
        this.refFromURLValue = params[this.refFromURL];
        this.identifierFromURLValue = params[this.identifierFromURL];
      });

      if (this.identifierFromURLValue.indexOf('@') > -1) {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'EMAIL',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      } else {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'LAST_NAME',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      }
    }
    if (!(this.restApi.isCompany() && sessionStorage.getItem('voucher'))) {
    this.restApi.retrieve(this.retrieveObj).subscribe(
      data => {

        this.data = data;
        if (this.data.errorCode === 0) {
          this.booking = this.data.data;
          this.checkin = moment(this.booking.checkinDate).format('MMM') + ' ' + moment(this.booking.checkinDate).format('DD')
            + ', ' + moment(this.booking.checkinDate).format('YYYY');
          this.checkout = moment(this.booking.checkoutDate).format('MMM') + ' ' + moment(this.booking.checkoutDate).format('DD')
            + ', ' + moment(this.booking.checkoutDate).format('YYYY');
          this.nights = Math.round(moment(this.checkout, 'MMM DD YYYY').diff(moment(this.checkin, 'MMM DD YYYY'), 'days', true));
          if (this.booking.bookingStatus === 'CANCELLED') {
            this.bookingType = 'cancelled';
          } else {
            if (this.booking.past) {
              this.bookingType = 'completed';
            } else {
              this.bookingType = 'confirmed';
            }
          }

          if (this.bookingType === 'confirmed') {
            this.restApi.checkCancellationFees(this.retrieveObj).subscribe(
              dataCancel => {
                this.dataCancel = dataCancel;
                if (this.dataCancel.errorCode === 0) {
                  this.allowCancellation = true;
                } else {
                  this.allowCancellation = false;
                  this.restApi.messages(this.dataCancel.message, 'error');
                }

              },
              error => {
                this.restApi.messages(this.dataCancel.message, 'error');
              }
            );
          }

        } else {
          this.restApi.messages(this.data.message, 'error');

        }

      },
      error => {
        this.restApi.messages(this.data.message, 'error');
      }
    );
  } else {
      this.booking = JSON.parse(sessionStorage.getItem('voucher'));
      this.booking = this.booking.data;
      this.checkin = moment(this.booking.checkinDate).format('MMM') + ' ' + moment(this.booking.checkinDate).format('DD')
        + ', ' + moment(this.booking.checkinDate).format('YYYY');
      this.checkout = moment(this.booking.checkoutDate).format('MMM') + ' ' + moment(this.booking.checkoutDate).format('DD')
        + ', ' + moment(this.booking.checkoutDate).format('YYYY');
      this.nights = Math.round(moment(this.checkout, 'MMM DD YYYY').diff(moment(this.checkin, 'MMM DD YYYY'), 'days', true));
      if (this.booking.bookingStatus === 'CANCELLED') {
        this.bookingType = 'cancelled';
      } else {
        if (this.booking.past) {
          this.bookingType = 'completed';
        } else {
          this.bookingType = 'confirmed';
        }
      }
    }*/
  }

}
