import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BookingComponent } from "./booking/booking.component";
import { WorkspaceBookingFormComponent } from "../controls/workspace-booking-form/workspace-booking-form.component";
import { WorkspaceFlightBookingFormComponent } from "../controls/workspace-flight-booking-form/workspace-flight-booking-form.component";
import { HotelListComponent } from "./hotel-list/hotel-list.component";
import { HotelComponent } from "./hotel/hotel.component";
import { VoucherComponent } from "../booking/voucher/voucher.component";
import { FlightVoucherComponent } from "../booking/flight-voucher/flight-voucher.component";
import { OrderModule } from "ngx-order-pipe";
import { Featured } from "./hotel-list/featured.pipe";
import { NgxStripeModule } from "ngx-stripe";
import { AgmCoreModule } from "@agm/core";
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { Ng5SliderModule } from "ng5-slider";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { RatingDisplay } from "./hotel-list/ratingDisplay.pipe";
import { ReversePipe } from "./hotel-list/Reverse.pipe";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { SwiperModule } from "ngx-swiper-wrapper";
import { SharedModule } from "../shared/shared.module";
import { environment } from "../../environments/environment";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { MatProgressBarModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { DemoMaterialModule } from "../material-module";
import { FlightsListComponent } from "./flights-list/flights-list.component";
import { FlightBookingComponent } from "./flight-booking/flight-booking.component";
import { CostReportingManageComponent } from "./booking/cost-reporting-manage/cost-reporting-manage.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CostReportingDetailsModule } from "../controls/cost-reporting-details/cost-reporting-details.module";

@NgModule({
  imports: [
    SharedModule,
    NgxStripeModule.forChild(environment.stripeKey),
    Ng5SliderModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    OrderModule,
    InfiniteScrollModule,
    AgmJsMarkerClustererModule,
    AgmCoreModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyC4kJNUVBZ9__sTRMrWzyUwXEJVSwlsGac&libraries'
    // }),
    FormsModule,
    NgbCollapseModule,
    NgbDropdownModule,
    SwiperModule,
    ScrollToModule,
    NgbTabsetModule,
    MatProgressBarModule,
    DemoMaterialModule,
    NgMultiSelectDropDownModule,
    CostReportingDetailsModule
  ],
  declarations: [
    BookingComponent,
    WorkspaceFlightBookingFormComponent,
    WorkspaceBookingFormComponent,
    HotelListComponent,
    HotelComponent,
    FlightVoucherComponent,
    VoucherComponent,
    Featured,
    RatingDisplay,
    ReversePipe,
    FlightsListComponent,
    FlightBookingComponent,
    CostReportingManageComponent
  ],
  exports: [
    MatProgressBarModule,
    OrderModule,
    Ng5SliderModule,
    AgmJsMarkerClustererModule,
    AgmCoreModule,
    ScrollToModule,
    BookingComponent,
    WorkspaceFlightBookingFormComponent,
    WorkspaceBookingFormComponent,
    HotelListComponent,
    HotelComponent,
    FlightVoucherComponent,
    VoucherComponent,
    Featured,
    NgMultiSelectDropDownModule,
    RatingDisplay,
    ReversePipe,
    FlightsListComponent,
    FlightBookingComponent,
    CostReportingManageComponent
  ],
})

export class SharedAvailabilityModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedAvailabilityModule,
      providers: [
        MatProgressBarModule,
        OrderModule,
        Ng5SliderModule,
        AgmJsMarkerClustererModule,
        AgmCoreModule,
        ScrollToModule,
        BookingComponent,
        WorkspaceFlightBookingFormComponent,
        WorkspaceBookingFormComponent,
        HotelListComponent,
        HotelComponent,
        VoucherComponent,
        FlightVoucherComponent,
        Featured,
        RatingDisplay,
        ReversePipe,
      ],
    };
  }
}
