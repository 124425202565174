<form [formGroup]="updateCompanyInfo" name="userAddressForm">
  <div class="container pt-4 pb-lg-5">
    <ng-container
      *ngIf="
        this.dataService.rightSideDrawerName === 'editCompanyInfo' ||
        this.dataService.BottomSheetName === 'editCompanyInfo'
      "
    >
      <div class="form-group mb-4">
        <label for="displayName" class="gray-text" i18n="@@displayName"
          >Display name</label
        >
        <input
          id="displayName"
          type="text"
          formControlName="displayName"
          name="displayName"
          class="form-control placeholder-text-field"
          [ngClass]="{ 'is-invalid': submitted && c.displayName.errors }"
        />
      </div>
      <div
        *ngIf="submitted && c.displayName.errors"
        class="text-danger smaller mb-2"
      >
        <div *ngIf="c.displayName.errors.required" i18n="@@displayNameRequired">
          Display name is required
        </div>
      </div>
      <div class="form-group form-type-select mb-4">
        <label
          for="numberOfEmployees"
          class="gray-text"
          i18n="@@totalNumberEmployees"
          >Total number of employees</label
        >
        <select
          id="numberOfEmployees"
          name="numberOfEmployees"
          formControlName="numberOfEmployees"
          class="form-control select-custom-arrow placeholder-text-field"
          (change)="setSelectedNumberOfEmployees()"
        >
          <ng-container *ngFor="let item of vars.numberOfEmployeesArray">
            <option
              *ngIf="
                this.auth.companyInfo.numberOfEmployees &&
                item.id === this.auth.companyInfo.numberOfEmployees.trim()
              "
              [value]="this.auth.companyInfo.numberOfEmployees"
              [selected]="true"
            >
              {{ item.value }}
            </option>
            <option
              *ngIf="
                !this.auth.companyInfo.numberOfEmployees ||
                item.id !== this.auth.companyInfo.numberOfEmployees.trim()
              "
              [value]="item.id"
            >
              {{ item.value }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="form-group form-type-select mb-4">
        <label
          for="annualTravelSpend"
          class="gray-text"
          i18n="@@annualTravelSpend"
          >Annual travel spend</label
        >
        <select
          id="annualTravelSpend"
          name="annualTravelSpend"
          formControlName="annualTravelSpend"
          class="form-control select-custom-arrow placeholder-text-field"
          (change)="setSelectedTravelSpend()"
        >
          <ng-container *ngFor="let item of vars.annualTravelSpend">
            <option
              *ngIf="item === this.auth.companyInfo.annualTravelSpend"
              [value]="this.auth.companyInfo.annualTravelSpend"
              [selected]="true"
            >
              {{ item }}
            </option>
            <option
              *ngIf="item !== this.auth.companyInfo.annualTravelSpend"
              [value]="item"
            >
              {{ item }}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="form-group mb-4">
        <label
          for="legalCompanyName"
          class="gray-text"
          i18n="@@legalCompanyName"
          >Legal company name</label
        >
        <input
          id="legalCompanyName"
          type="text"
          formControlName="legalCompanyName"
          name="legalCompanyName"
          class="form-control placeholder-text-field"
        />
      </div>

      <div class="form-group form-type-select mb-4">
        <label for="industry" class="gray-text" i18n="@@industry"
          >Industry</label
        >
        <select
          id="industry"
          name="industry"
          formControlName="industry"
          class="form-control select-custom-arrow placeholder-text-field"
          (change)="setSelectedIndustry()"
        >
          <ng-container *ngFor="let industry of industries">
            <option
              *ngIf="industry.id === this.auth.companyInfo.industry"
              [value]="this.auth.companyInfo.industry"
              [selected]="true"
            >
              {{ industry.name }}
            </option>
            <option
              *ngIf="industry.id !== this.auth.companyInfo.industry"
              [value]="industry.id"
            >
              {{ industry.name }}
            </option>
          </ng-container>
        </select>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        this.dataService.rightSideDrawerName === 'editCompanyMainAddress' ||
        this.dataService.BottomSheetName === 'editCompanyMainAddress' ||
        this.dataService.rightSideDrawerName === 'editCompanyOtherAddress' ||
        this.dataService.BottomSheetName === 'editCompanyOtherAddress' ||
        this.dataService.rightSideDrawerName === 'addCompanyOtherAddress' ||
        this.dataService.BottomSheetName === 'addCompanyOtherAddress'
      "
    >
      <div class="form-group form-type-textfield">
        <input
          autocomplete="off"
          id="mainAddressName"
          type="text"
          formControlName="mainAddressName"
          name="mainAddressName"
          class="form-control placeholder-text-field"
          i18n-placeholder="@@name"
          placeholder="Name"
          [ngClass]="{ 'red-border': mainAddressNameRequired }"
        />
      </div>
      <p class="text-danger small">{{ mainAddressNameErrorRequiredMsg }}</p>

      <div class="form-group form-type-textfield form-name-address1">
        <input
          id="Address"
          type="text"
          formControlName="address1"
          name="address1"
          class="form-control placeholder-text-field"
          i18n-placeholder="@@address1"
          placeholder="Address 1"
          autocomplete="off"
          [ngClass]="{ 'red-border': addressRequired }"
        />
      </div>
      <p class="text-danger small">{{ addressErrorRequiredMsg }}</p>

      <div class="form-group form-type-textfield form-name-address2">
        <input
          autocomplete="off"
          id="Address2"
          type="text"
          formControlName="address2"
          name="address2"
          class="form-control placeholder-text-field"
          i18n-placeholder="@@address2"
          placeholder="Address 2"
        />
      </div>

      <div class="form-group form-type-select form-name-countrycode">
        <select
          id="countryCode"
          name="countryCode"
          formControlName="countryCode"
          class="form-control select-custom-arrow placeholder-text-field"
          (change)="setSelectedValue()"
          [ngClass]="{ 'placeholder-text': !selectedValue }"
        >
          <ng-container *ngFor="let country of countries; let i = index">
            <option
              class="placeholder-text"
              *ngIf="
                !selectedValue &&
                i === 0 &&
                (this.dataService.rightSideDrawerName ===
                  'addCompanyOtherAddress' ||
                  this.dataService.BottomSheetName === 'addCompanyOtherAddress')
              "
              value=""
              [selected]="true"
              i18n="@@country"
            >
              Country
            </option>
            <option
              class="placeholder-text"
              *ngIf="
                !selectedValue &&
                i === 0 &&
                this.dataService.rightSideDrawerName !==
                  'addCompanyOtherAddress' &&
                this.dataService.BottomSheetName !== 'addCompanyOtherAddress'
              "
              value="null"
              [selected]="true"
              i18n="@@country"
            >
              Country
            </option>
            <option
              *ngIf="country.code === addressObj.countryCode"
              [value]="addressObj.countryCode"
              [selected]="true"
            >
              {{ country.name }}
            </option>
            <option
              *ngIf="country.code !== addressObj.countryCode"
              [value]="country.code"
            >
              {{ country.name }}
            </option>
          </ng-container>
        </select>
      </div>
      <div
        class="form-group form-type-select form-name-statecode"
        *ngIf="selectedValue === 'US'"
      >
        <select
          id="stateCode"
          name="stateCode"
          formControlName="stateCode"
          class="form-control select-custom-arrow placeholder-text-field"
          (change)="setSelectedState()"
        >
          <ng-container *ngFor="let state of states; let i = index">
            <option
              *ngIf="!selectedState && i === 0"
              [value]="null"
              [selected]="true"
              i18n="@@state"
            >
              State
            </option>
            <option
              *ngIf="state.code === addressObj.stateCode"
              [value]="addressObj.stateCode"
              [selected]="true"
            >
              {{ state.name }}
            </option>
            <option
              *ngIf="state.code !== addressObj.stateCode"
              [value]="state.code"
            >
              {{ state.name }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="form-group form-type-textfield form-name-zipcode">
        <input
          id="ZipCode"
          type="text"
          formControlName="zipCode"
          name="zipCode"
          class="form-control placeholder-text-field"
          i18n-placeholder="@@ZIPCode"
          placeholder="ZIP Code"
        />
      </div>

      <div class="form-group form-type-textfield form-name-city">
        <input
          id="City"
          type="text"
          formControlName="city"
          name="city"
          class="form-control placeholder-text-field"
          i18n-placeholder="@@city"
          placeholder="City"
        />
      </div>
      <div class="form-group form-type-textfield form-name-phone">
        <input
          id="PhoneNumber"
          type="text"
          formControlName="phoneNumber"
          name="phoneNumber"
          class="form-control placeholder-text-field"
          i18n-placeholder="@@phoneNumber"
          placeholder="Phone number"
          pattern="^(\+)?[0-9]+"
          [ngClass]="{ 'is-invalid': c.phoneNumber.errors }"
        />
        <div class="invalid-feedback" i18n="@@invalidNumber">
          Oops! Please use only numbers when entering your phone number.
        </div>
      </div>
    </ng-container>
  </div>

  <div class="container pt-4 pb-lg-5">
    <div class="row d-flex justify-content-center mb-3">
      <div
        class="col-md-6 order-lg-1"
        [ngClass]="{
          'pl-lg-2': dataService.isSiteLtr(),
          'pr-lg-2': dataService.isSiteRtl()
        }"
      >
        <button
          class="mb-3 mb-lg-5 mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0"
          (click)="submitAccountUpdate()"
          *ngIf="
            this.dataService.rightSideDrawerName === 'editCompanyInfo' ||
            this.dataService.BottomSheetName === 'editCompanyInfo'
          "
        >
          <span
            class="mx-auto font-14"
            [ngClass]="{ 'm-progress': submitting }"
            i18n="@@save"
            >Save</span
          >
        </button>
        <button
          class="mb-3 mb-lg-5 mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0"
          (click)="updateCompanyAddress('editCompanyMainAddress')"
          *ngIf="
            this.dataService.rightSideDrawerName === 'editCompanyMainAddress' ||
            this.dataService.BottomSheetName === 'editCompanyMainAddress'
          "
        >
          <span
            class="mx-auto font-14"
            [ngClass]="{ 'm-progress': submitting }"
            i18n="@@save"
            >Save</span
          >
        </button>
        <button
          class="mb-3 mb-lg-5 mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0"
          (click)="updateCompanyAddress('editCompanyOtherAddress')"
          *ngIf="
            this.dataService.rightSideDrawerName ===
              'editCompanyOtherAddress' ||
            this.dataService.BottomSheetName === 'editCompanyOtherAddress'
          "
        >
          <span
            class="mx-auto font-14"
            [ngClass]="{ 'm-progress': submitting }"
            i18n="@@save"
            >Save</span
          >
        </button>
        <button
          class="mb-3 mb-lg-5 mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0"
          (click)="updateCompanyAddress('addCompanyOtherAddress')"
          *ngIf="
            this.dataService.rightSideDrawerName === 'addCompanyOtherAddress' ||
            this.dataService.BottomSheetName === 'addCompanyOtherAddress'
          "
        >
          <span
            class="mx-auto font-14"
            [ngClass]="{ 'm-progress': submitting }"
            i18n="@@save"
            >Save</span
          >
        </button>
      </div>
      <div
        class="col-md-6 order-lg-0"
        [ngClass]="{
          'pr-lg-2': dataService.isSiteLtr(),
          'pl-lg-2': dataService.isSiteRtl()
        }"
      >
        <button
          (click)="
            this.dataService.rightSideDrawerOff();
            this.dataService.BottomSheetOf()
          "
          class="mb-5 mt-3 btn-block btn btn-outline-gray px-5 d-flex"
          [ngClass]="{
            'ml-auto': dataService.isSiteLtr(),
            'mr-auto': dataService.isSiteRtl()
          }"
        >
          <span
            class="mx-auto gray-text-dark font-14 sharp-font bold-500"
            i18n="@@cancelBtn"
            >Cancel</span
          >
        </button>
      </div>
    </div>
  </div>
</form>
