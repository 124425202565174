
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="payment-list main-dashboard-content">
      <app-messages></app-messages>
   <!--   <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Cards </span>
      </div>-->

      <div class="container pt-4 pt-lg-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">

          <div class="row">
            <div class="col">
           <div class="pb-4 pb-lg-5 mb-3">
              <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);" i18n="@@menuItemSettings">Settings</a>
              <span class="font-16 gray-text-dark "> / <ng-container i18n="@@cards">Cards</ng-container> </span>
            </div>
            </div>
          </div>



        <!--  <div class=" font-24 sharp-font primaray-text bold-600">Company Cards</div>-->



        <div class="row pb-5" >
          <div class="col-md-6 d-flex flex-column">
          <div>
            <p class="gray-text-dark font-18"><span *ngIf="this.auth.companyInfo && this.auth.companyInfo.name" i18n="@@compCardMsg1">{{this.auth.companyInfo.name}} employees who sign up to SafarHub for Work can charge the payment method(s) below</span></p>
          </div>

                <div class="mt-auto min-width-workspace-btn"
                     [ngClass]="{'mr-auto': dataService.desktopView && dataService.isSiteLtr(), 'ml-auto': dataService.desktopView &&dataService.isSiteRtl()}">
                  <button (click)="addNewPayment()" class="btn btn-outline-blue font-14 px-5 sharp-font bold-500"
                  [ngClass]="{'btn-block': dataService.desktopView}" i18n="@@newCard">
                   New Card
                </button>
                </div>


          </div>
          <div class="col-md-6" *ngIf="dataService.desktopView">
            <div class="rounded-bottom p-5 right-box-style company-section-with-thick-border thick-top"
                 [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
              <p class="primaray-text bold-600 mb-0 font-16" i18n="@@teamsPayments">Teams payments</p>
              <p class="mb-0 gray-text-dark font-14" i18n="@@compCardMsg2">
                You can assign payment methods to different teams, if no team is assigned to a payment methods, then it can be used by all employees
              </p>
            </div>
          </div>
        </div>

        <div class="container py-5 mb-5" *ngIf="this.dataService.companyPaymentList && this.dataService.companyPaymentList.length > 0 && this.dataService.companyPaymentExpiryList.length > 0">
       <!--   <div class="row px-3 py-3 primaray-text font-weight-bold font-16 table-header">
            <div class="col-5">Card</div>
            <div class="col-5">Permission</div>
            <div class="col-2"></div>
          </div>-->


            <div class="row px-lg-3 py-4 hover-row-list font-14 items-full-list"
                 [ngClass]="{'border-bottom': dataService.desktopView, 'rounded border mb-4': dataService.mobileView}"
                 *ngFor="let item of this.dataService.companyPaymentList; let i = index">
              <div class="col-lg-5 d-flex" [ngClass]="{'h-100 justify-content-center align-items-center': dataService.desktopView}">
                <div class="card_type card_type_{{item.card.brand}}" [ngClass]="{'max-width-card': dataService.mobileView,
                 'text-right mr-4': dataService.desktopView && dataService.isSiteLtr(),
                    'text-left ml-4': dataService.desktopView && dataService.isSiteRtl()}"></div>
                <div [ngClass]="{'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}">
                 <!--   <div (click)="editAlias(item.card.id, item.card.paymentGatewayProvider, item.card.alias)" class="mb-1 pointer" *ngIf="item.card.alias"><span>{{item.card.alias}}</span><i class="font-12 icon-pencil-icon p-2 border rounded small ml-3 bg-white"></i></div>
                    <div (click)="editAlias(item.card.id, item.card.paymentGatewayProvider, null)" class="mb-1 pointer" *ngIf="!item.card.alias"><span>No alias</span><i class="font-12 icon-pencil-icon p-2 border rounded small ml-3 bg-white"></i></div>-->
                  <div class="mb-1" *ngIf="item.card.alias"><span>{{item.card.alias}}</span></div>
                  <div class="mb-1" *ngIf="!item.card.alias"><span i18n="@@noAlias">No alias</span></div>
                  <p class="gray-text mb-0" dir="ltr"> **** **** **** {{item.card.lastFour}}</p>
                 <div *ngIf="dataService.mobileView" class="min-height-20">
                   <p *ngIf="this.dataService.companyPaymentExpiryList[i]" class="text-danger mb-0"><i class="icon-warning-msg"
                   [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i><ng-container i18n="@@expired">Expired</ng-container></p>
                 </div>
                 <!--  <p *ngIf="this.dataService.companyPaymentExpiryList[i]" class="text-danger mb-0"><i class="icon-warning-msg mr-3"></i>Expired</p>-->
                </div>

              </div>
              <div class="col-lg-5">
                <p class="pt-4 pt-lg-0 mb-1 gray-text">
                  <span class="gray-text pr-2" *ngIf="dataService.mobileView"><ng-container i18n="@@team">Team</ng-container>:</span><span [ngClass]="{'text-bg font-12 px-3 py-2':dataService.mobileView}">{{item.description}}</span>
                </p>
                <p *ngIf="this.dataService.companyPaymentExpiryList[i] && dataService.desktopView" class="text-danger mb-0"><i class="icon-warning-msg"
                 [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i><ng-container i18n="@@expired">Expired</ng-container></p>
              </div>
              <div class="col-lg-2 pt-2">
               <!-- <p class="action-hide-show ml-3"><a class="blue-text" (click)="removePayment(item.card.id);">Delete</a></p>-->
                <div class="d-flex" >
                  <div class="actions" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    <i class="drop-down{{i}} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                       (click)="showActions = !showActions; selectedIndex = i"></i>
                    <div class=" border shadow font-14 position-absolute bg-white drop" *ngIf="showActions && i === selectedIndex">
                      <p class="pointer py-2" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                         (click)="editAlias(item.card.id, item.card.paymentGatewayProvider, item.card.alias)" i18n="@@edit">Edit</p>
                      <p class="pointer py-2" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                         (click)="removePayment(item.card.id)" i18n="@@deleteBtn">Delete</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>




        </div>



      <!--  <div *ngIf="dataService.mobileView && this.dataService.companyPaymentList && this.dataService.companyPaymentList.length > 0 && this.dataService.companyPaymentExpiryList.length > 0">
          <div *ngFor="let item of this.dataService.companyPaymentList; let i = index">
            <div class="p-3 card-bg mb-4">
              <div class="d-flex">
                <div class="mr-4 text-right card_type card_type_{{item.card.brand}}"></div>
                <div class="w-100">
                  <div class="d-flex">
                    <div class="pointer title-font-style" *ngIf="item.card.alias"><span>{{item.card.alias}}</span></div>
                    <div class="pointer title-font-style" *ngIf="!item.card.alias"><span>No alias</span></div>
                  </div>
                  <p class="gray-text mb-2 body-font-style"> **** **** **** {{item.card.lastFour}}</p>
                  <p *ngIf="this.dataService.companyPaymentExpiryList[i]" class="text-danger mb-0 body-font-style"><i class="icon-warning-msg mr-3"></i>Expired</p>
                </div>
              </div>
              <p>
                <span class="gray-text body-font-style">Permission: </span>
                <span class="body-font-style">{{item.description}}</span>
              </p>
              <div class="d-flex mb-0 mt-4">
                <a class="blue-text action-font-style left-action" (click)="removePayment(item.card.id);">Delete</a>
                <a *ngIf="item.alias" class="blue-text action-font-style right-action ml-auto" (click)="editAlias(item.card.id, item.card.paymentGatewayProvider, item.card.alias)">Edit</a>
                <a *ngIf="!item.alias" class="blue-text action-font-style right-action ml-auto"  (click)="editAlias(item.card.id, item.card.paymentGatewayProvider, null)">Edit</a>

              </div>
            </div>
          </div>


        </div>-->









      </div>


</div>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





