import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { StripeService, Elements, Element as StripeElement } from "ngx-stripe";
import {
  ScrollToService,
  ScrollToConfigOptions,
} from "@nicky-lenaers/ngx-scroll-to";
import * as moment from "moment";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { AuthServiceMain } from "../../auth/auth.service";
import { environment } from "../../../environments/environment";
import { GoogleAnalytics4Service } from "../../google-analytics4.service";
import { VariablesService } from "../../services/vars.service";
import { CostBookingStateService } from "src/app/services/states/cost-booking-state.service";

declare let paypal: any;

declare let ga: Function; // Declare ga as a function
declare let google: any;
declare let window: any;
export class User {
  constructor(
    public description: string,
    public id: string,
    public email: string,
    public teamName?: string,
    public selected?: boolean
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}
@Component({
  selector: "app-workspace-booking-form",
  templateUrl: "./workspace-booking-form.component.html",
  styleUrls: ["./workspace-booking-form.component.scss"],
})
export class WorkspaceBookingFormComponent implements OnInit {
  env = environment;
  readMore = false;
  getApprovalsList;
  ServiceDown: boolean = false;
  ServiceUp: boolean = false;
  userInfohasProperty = false;
  changeRememberMe = false;
  changeRememberMeData;
  PAypalPrice;
  paymentType = "new_card";
  elements: Elements;
  googlrSuported = false;
  card: StripeElement;
  stripeError = "";
  sscid;
  paytype = "STRIPE";
  cardToken;
  bookingForm: FormGroup;
  paymentID;
  submitted = false;
  data;
  cardholder = false;
  SelectedsavedCard = "";
  CardSelected;
  errorMsg = "";
  specialReq = false;
  savedCard;
  showcardControl = false;
  public showSuccess = false;
  public showCancel = false;
  public showError = false;
  PaxesCount: any;
  rate;
  diffInDays;
  TripAdviserRating;
  tripAdviserdata;
  extraCashback;
  previousUrl = sessionStorage.getItem("previousUrl");
  formFirstName = "";
  formLastName = "";
  formEmail = "";
  formPhone = "";
  invalidCardNumber = false;
  teamPersonalPaymentMethodsDisabled;
  @ViewChild("priceElem", { static: false }) priceElem?: ElementRef;
  userControl = new FormControl();
  selectedUser;
  lastFilter: string = "";
  dataUsers;
  requiresApprovalData;
  submittingApproval;
  submittedApproval;
  voucherUrl;
  phonePlaceHolder = $localize`:@@phoneNumber:Phone number`;
  reviewTitle =
    $localize`:@@reviewSummary:Review summary` +
    "<span>(" +
    $localize`:@@poweredByGoogle:Powered by Google` +
    ") </span>";
  userInfoObj = JSON.parse(localStorage.getItem("userInfo"));
  travelerId;
  traveler;
  @ViewChild("userInput") userInput: ElementRef;
  constructor(
    public vars: VariablesService,
    public router: Router,
    private formBuilder: FormBuilder,
    public Service: DataService,
    private stripeService: StripeService,
    public Auth: AuthServiceMain,
    private scrollToService: ScrollToService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public googleAnalytics4Service: GoogleAnalytics4Service,
    public costBookingState: CostBookingStateService
  ) {
    this.Service.loadScript(
      "https://www.paypalobjects.com/api/checkout.min.js"
    );
    this.Service.loadScript("https://pay.google.com/gp/p/js/pay.js");

    this.Service.componentMethodCalled$.subscribe(() => {
      if (
        this.Service.urlWithoutParams === "/availability/booking" ||
        (this.Auth.isLogedIn &&
          this.Auth.companyInfo &&
          this.Service.urlWithoutParams ===
            "/" + this.Auth.companyInfo.id + "/availability/booking")
      ) {
        this.Service.bookingPage = true;
        this.Service.hotelPage = false;
        this.showcardControl = false;
        this.submitted = false;
        if (this.Auth.isLogedIn) {
          this.Service.savedCard(this.travelerId).subscribe((success) => {
            this.savedCard = success;

            if (this.savedCard.errorCode === 0) {
              if (
                this.savedCard.data &&
                this.savedCard.data.cards &&
                this.savedCard.data.cards.length > 0
              ) {
                this.paytype = "savedCard";
                this.savedCard.data.cards.forEach((element, index) => {
                  if (element.primary === true) {
                    this.SelectedsavedCard = element.token;
                  }
                });
              } else {
                this.paytype = "STRIPE";
              }
            } else {
              this.paytype = "STRIPE";
            }

            if (
              this.Auth.isLogedIn &&
              this.savedCard.data &&
              this.savedCard.data.length
            ) {
              this.showcardControl = false;
            }

            if (this.Auth.isLogedIn && !this.savedCard.data) {
              this.showcardControl = true;
            }
          });
        } else {
          this.paytype = "STRIPE";
        }

        for (var prop in this.Auth.userInfo) {
          if (this.Auth.userInfo.hasOwnProperty(prop)) {
            this.userInfohasProperty = true;
            break;
          }
        }
      }
    });
    this.Service.changePrice$.subscribe(() => {
      setTimeout(() => {
        if (this.Service.newRate) {
          this.googlePay(this.Service.newRate.finalSellingPrice.toString());

          this.PayPalConfig(this.Service.newRate.finalSellingPrice.toString());
        }
      }, 3000);
    });

    this.Service.getCookie("shareasaleSSCID");
  }

  ngOnInit() {
    console.log(this.Auth)
    this.selectedUser = this.userInfoObj;
    if (this.Service.isCompany()) {
      this.phonePlaceHolder = "";
      const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
      this.voucherUrl = "/" + companyObj.id + "/availability/voucher";
    } else {
      this.voucherUrl = "/availability/voucher";
    }
    this.Service.loaderText = $localize`:@@bookingYourRoom:Booking your room ..`;

    setTimeout(() => {
      if (this.Service.newRate) {
        this.googlePay(this.Service.newRate.finalSellingPrice.toString());
        this.PayPalConfig(this.Service.newRate.finalSellingPrice.toString());
      }
    }, 1000);
    this.errorMsg = "";
    this.Service.errorMsg = "";

    this.bookingForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      LastName: ["", Validators.required],
      HolderName: ["", Validators.pattern("^[A-Za-z -]+$")],
      phone: ["", Validators.required],
      password: [""],
      FirstName: ["", Validators.required],

      specialRequests: ["", [Validators.maxLength(499)]],
    });

    const PaxesArray: any = this.Service.params.paxes.split(",");

    this.PaxesCount = PaxesArray.length + Number(PaxesArray[0]) - 1;
    this.Service.bookingSubmit = false;
    this.submittingApproval = false;
    this.Service.alerts = [];

    if (
      sessionStorage.getItem("BookingFormFields") &&
      this.Service.mobileView
    ) {
      const BookingFormFields = JSON.parse(
        sessionStorage.getItem("BookingFormFields")
      );
      sessionStorage.removeItem("BookingFormFields");
    }

    this.travelerId = sessionStorage.getItem("travelerId");
    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      if (
        !this.travelerId ||
        this.travelerId === "" ||
        this.travelerId === "guest"
      ) {
      } else {
        this.Service.getCompanyUserDetails(this.travelerId).subscribe((suc) => {
          this.data = suc;
          if (this.data.errorCode === 0) {
            if (this.bookingForm.controls.FirstName.value === "") {
              this.bookingForm
                .get("FirstName")
                .setValue(this.data.data.firstName);
            }
            if (this.bookingForm.controls.LastName.value === "") {
              this.bookingForm
                .get("LastName")
                .setValue(this.data.data.lastName);
            }
            if (this.bookingForm.controls.email.value === "") {
              this.bookingForm.get("email").setValue(this.data.data.email);
            }
            this.traveler = {
              travelerPersonalPaymentMethodsDisabled:
                this.data.data.personalPaymentMethodsDisabled,
              travelerName:
                this.data.data.firstName + " " + this.data.data.lastName,
            };
            let travelerPhone = "travelerPhone";
            let dialCode = "";
            if (this.data.data.phoneNumberCountryCode) {
              let selected = this.vars.countries.filter(
                (item) =>
                  item.dial_code === "+" + this.data.data.phoneNumberCountryCode
              );
              if (selected.length > 0) {
                dialCode = this.data.data.phoneNumberCountryCode;
              } else {
                selected = this.vars.countries.filter(
                  (item) => item.code === this.data.data.phoneNumberCountryCode
                );
                dialCode = selected[0].dial_code.replace("+", "");
              }
              this.traveler[travelerPhone] =
                dialCode + this.data.data.phoneNumber;
              this.bookingForm
                .get("phone")
                .setValue(this.traveler.travelerPhone);
            }
          }
        });
      }
    } else {
      if (this.bookingForm.controls.FirstName.value === "") {
        this.bookingForm.get("FirstName").setValue(this.Auth.userInfo.name);
      }
      if (this.bookingForm.controls.LastName.value === "") {
        this.bookingForm.get("LastName").setValue(this.Auth.userInfo.lastName);
      }
      this.traveler = {
        travelerPersonalPaymentMethodsDisabled:
          this.Auth.userInfo.personalPaymentMethodsDisabled,
        travelerName:
          this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
      };
      let travelerPhone = "travelerPhone";
      let dialCode = "";
      if (this.Auth.userInfo.phoneNumberCountryCode) {
        let selected = this.vars.countries.filter(
          (item) =>
            item.dial_code === "+" + this.Auth.userInfo.phoneNumberCountryCode
        );
        if (selected.length > 0) {
          dialCode = this.Auth.userInfo.phoneNumberCountryCode;
        } else {
          selected = this.vars.countries.filter(
            (item) => item.code === this.Auth.userInfo.phoneNumberCountryCode
          );
          dialCode = selected[0].dial_code.replace("+", "");
        }
        this.traveler[travelerPhone] =
          dialCode + this.Auth.userInfo.phoneNumber;
        this.bookingForm.get("phone").setValue(this.traveler.travelerPhone);
      } else {
        this.bookingForm.get("phone").setValue(this.Auth.userInfo.phoneNumber);
      }
      if (this.bookingForm.controls.email.value === "") {
        this.bookingForm.get("email").setValue(this.Auth.userInfo.email);
      }
    }

    this.rate = JSON.parse(sessionStorage.getItem("rate"));
    if (this.Service.isCompany()) {
      this.Service.requiresApprovalCheck(this.rate.rateKey).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.requiresApprovalData = this.data.data;
            if (this.requiresApprovalData) {
              this.Service.needRequestApproval = true;
            }
          }
        }
      );
    }

    this.diffInDays = moment
      .utc(this.Service.params.checkOut)
      .diff(moment.utc(this.Service.params.checkIn), "days");

    if (sessionStorage.getItem("tripAdviserdata")) {
      this.tripAdviserdata = JSON.parse(
        sessionStorage.getItem("tripAdviserdata")
      );

      this.TripAdviserRating = (this.tripAdviserdata.data.rating * 100) / 5;
    }

    this.stripeService.elements().subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#515e80",
              color: "#515e80",
              fontWeight: 300,
              fontFamily:
                'Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              fontSize: "14px",
              "::placeholder": {
                color: "#515e80",
              },
            },
          },
        });
        this.card.mount("#card-element");
      }
    });

    if (this.Auth.isLogedIn) {
      this.Service.savedCard(this.travelerId).subscribe((success) => {
        this.savedCard = success;

        if (this.savedCard.errorCode === 0) {
          this.paytype = "savedCard";

          if (
            this.savedCard.data &&
            this.savedCard.data.cards &&
            this.savedCard.data.cards.length > 0
          ) {
            this.paytype = "savedCard";
            this.savedCard.data.cards.forEach((element, index) => {
              if (index === 0) {
                this.SelectedsavedCard = element.token;
              }

              if (element.primary === true) {
                this.SelectedsavedCard = element.token;
              }
            });
          } else {
            this.paytype = "STRIPE";
          }
        } else {
          this.paytype = "STRIPE";
        }

        if (
          this.Auth.isLogedIn &&
          this.savedCard.data &&
          this.savedCard.data.length
        ) {
          this.showcardControl = false;
        }

        if (this.Auth.isLogedIn && !this.savedCard.data) {
          this.showcardControl = true;
        }
      });
    }
    if (this.Service.newRate) {
      this.PAypalPrice = this.Service.newRate.finalSellingPrice;
    }

    window.dataLayer.push({
      event: "fireRemarketing",

      ecomm_prodid: this.Service.hotelData.giataId,
      ecomm_pagetype: "cart",
      ecomm_totalvalue: this.rate.finalSellingPrice,
      hrental_id: this.Service.hotelData.giataId,
      hrental_pagetype: "conversionintent",
    });

    if (sessionStorage.getItem("voucurevist")) {
      sessionStorage.removeItem("voucurevist");
    }
  }
  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "bookingForm",
      duration: 650,
      easing: "easeOutElastic",
      offset: 20,
    };

    this.scrollToService.scrollTo(config);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.bookingForm.controls;
  }

  onSubmit() {
    if (this.Auth.isManager() || this.Auth.isAdmin() || this.Auth.isOwner()) {
      this.getApprovalsList = true;
    }
    let header = "";
    if (this.Service.sscid) {
      header = this.Service.sscid;
    }
    this.errorMsg = "";

    this.cardholder = false;

    if (this.bookingForm.controls.HolderName.value === "") {
      this.cardholder = true;
    } else {
      this.cardholder = false;
    }

    this.submitted = true;
    this.Service.alerts = [];

    const isValidCostReporting = this.costBookingState
      .setIsSubmittedAndCheckValidation(this.submitted)
      .valueOf();
    if (!isValidCostReporting) {
      this.Service.scrollTop();
      return;
    }

    if (
      (this.bookingForm.invalid ||
        (this.Service.isCompany() && !this.selectedUser)) &&
      this.paytype !== "PAYPAL"
    ) {
      this.triggerScrollTo();
      this.Service.scrollTop();
      return;
    }
    const dataObj = {} as any;
    if (!this.Service.isCompany()) {
      dataObj.email = this.bookingForm.controls.email.value;
      dataObj.guest = {
        firstName: this.bookingForm.controls.FirstName.value,
        lastName: this.bookingForm.controls.LastName.value,
      };
      dataObj.provider = "STRIPE";
    }
    dataObj.phone = this.bookingForm.controls.phone.value;
    dataObj.rateKey = this.Service.newRate.rateKey;
    dataObj.remark = this.bookingForm.controls.specialRequests.value;

    if (
      this.costBookingState?.selectedCostCenters &&
      this.costBookingState?.selectedCostCenters.length
    ) {
      dataObj.costCenterId = this.costBookingState?.selectedCostCenters[0]?.id;
    }

    if (
      this.costBookingState?.selectedLabels &&
      this.costBookingState?.selectedLabels.length
    ) {
      dataObj.labelsIds = this.costBookingState.selectedLabels?.map(
        (att) => att.id
      );
    }

    // for Company
    if (this.Service.isCompany()) {
      //dataObj.guestUserId = this.selectedUser.id;
      dataObj.guestUserId = this.travelerId;
      if (this.travelerId === "guest") {
        dataObj.guestEmail = this.bookingForm.controls.email.value;
        dataObj.guestFirstName = this.bookingForm.controls.FirstName.value;
        dataObj.guestLastName = this.bookingForm.controls.LastName.value;
      }
      dataObj.paymentGatewayProvider = "STRIPE";
    }
    if (this.paytype === "paypal") {
      if (!this.Service.isCompany()) {
        dataObj.provider = "PAYPAL";
        dataObj.paymentData = { response: { id: this.paymentID } };
      }
      // for Company
      if (this.Service.isCompany()) {
        dataObj.paymentGatewayProvider = "PAYPAL";
        dataObj.paypalPaymentId = this.paymentID;
      }
      if (!this.requiresApprovalData) {
        this.Service.bookingSubmit = true;
      } else {
        this.submittingApproval = true;
      }
      this.Service.bookingForm(dataObj, header).subscribe(
        (data) => {
          this.data = data;
          this.errorMsg = this.data.message;
          if (this.data.errorCode === 0) {
            this.Service.errorMsg = "";
            if (this.requiresApprovalData) {
              this.submittedApproval = true;
              this.router.navigate([
                "/" +
                  this.Auth.companyInfo.id +
                  "/user/requests/" +
                  this.data.data.id,
              ]);
              this.submittingApproval = false;
              if (this.getApprovalsList) {
                this.Service.getListApproversApprovals(
                  "PENDING_APPROVAL"
                ).subscribe(
                  (dataApprove) => {
                    this.data = dataApprove;
                    if (this.data.errorCode === 0) {
                      const pendingArray = this.data.data.filter(
                        (item) =>
                          item.status.toLowerCase() === "pending_approval"
                      );
                      localStorage.setItem(
                        "workspaceApprovalsBadge",
                        pendingArray.length
                      );
                      this.Service.workspaceApprovalsBadge =
                        pendingArray.length;
                    } else {
                      this.Service.messages(this.data.message, "error");
                    }
                  },
                  (error) => {
                    this.Service.messages(environment.messages.error, "error");
                  }
                );
              }
              return;
            }

            this.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Booking-PayPal",
              "Successful",
              1
            );
            ga("ecommerce:clear");

            this.googleAnalyticsService.addTransaction(
              this.data.data.referenceNumber,
              this.Service.newRate.finalSellingPrice
            );

            // GA4
            let location = "";
            if (this.data.data.cityName) {
              location = this.data.data.cityName;
            }
            if (this.data.data.stateName) {
              location += ", " + this.data.data.stateName;
            }
            if (this.data.data.countryName) {
              location += ", " + this.data.data.countryName;
            }
            this.googleAnalytics4Service.addTransaction(
              this.data.data.currency.currency,
              this.data.data.referenceNumber,
              this.Service.newRate.finalSellingPrice,
              this.data.data.companyId,
              name,
              location,
              "Hotel",
              "Self"
            );
            // end of GA4

            this.googleAnalyticsService.addItem(
              this.data.data.referenceNumber,
              this.Service.newRate.finalSellingPrice,
              this.Service.hotelData.name
            );
            sessionStorage.setItem("voucher", JSON.stringify(this.data));

            if (
              this.Service.RemoteConfg &&
              this.Service.RemoteConfg.enable_share_a_sale &&
              this.Service.RemoteConfg.enable_share_a_sale._value === "true" &&
              this.Service.sscid
            ) {
              this.Service.loadImg(
                this.data.data.referenceNumber,
                this.Service.sscid
              );
            }
            this.router.navigate([this.voucherUrl], {
              queryParams: {
                ref: this.data.data.referenceNumber,
                email: this.data.data.emailAddress,
                lastname: this.data.data.guestLastName,
              },
            });

            this.notSignInSignUP();
            // googleAnalyticsService
          } else if (this.data.errorCode === 20) {
            this.Service.bookingSubmit = false;
            this.router.navigateByUrl(this.previousUrl);
            this.Service.messagesOverRoute(this.errorMsg, "warning");
          } else if (this.data.errorCode === 54) {
            this.Service.bookingSubmit = false;
            this.Service.setModal(
              '<i class="icon-warning"></i> warning',
              "completeDuplicateBooking",
              this.data.message
            );
          } else {
            this.Service.bookingSubmit = false;
            this.Service.errorMsg = this.errorMsg;
            this.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Booking-PayPal",
              "Failed",
              1
            );
          }
        },
        (error) => {
          this.Service.errorMsg = this.errorMsg;
          this.Service.bookingSubmit = false;
        }
      );
    } else if (this.paytype === "savedCard") {
      this.CardSelected = this.SelectedsavedCard;
      if (!this.requiresApprovalData) {
        this.Service.bookingSubmit = true;
      } else {
        this.submittingApproval = true;
      }

      if (!this.Service.isCompany()) {
        dataObj.paymentData = { token: this.CardSelected };
      }
      if (this.Service.isCompany()) {
        dataObj.stripeToken = this.CardSelected;
      }
      this.Service.bookingForm(dataObj, header).subscribe(
        (data) => {
          this.data = data;
          this.errorMsg = this.data.message;
          if (this.data.errorCode === 0) {
            this.Service.errorMsg = "";
            if (this.requiresApprovalData) {
              this.submittedApproval = true;
              this.router.navigate([
                "/" +
                  this.Auth.companyInfo.id +
                  "/user/requests/" +
                  this.data.data.id,
              ]);
              this.submittingApproval = false;
              if (this.getApprovalsList) {
                this.Service.getListApproversApprovals(
                  "PENDING_APPROVAL"
                ).subscribe(
                  (dataApprove) => {
                    this.data = dataApprove;
                    if (this.data.errorCode === 0) {
                      const pendingArray = this.data.data.filter(
                        (item) =>
                          item.status.toLowerCase() === "pending_approval"
                      );
                      localStorage.setItem(
                        "workspaceApprovalsBadge",
                        pendingArray.length
                      );
                      this.Service.workspaceApprovalsBadge =
                        pendingArray.length;
                    } else {
                      this.Service.messages(this.data.message, "error");
                    }
                  },
                  (error) => {
                    this.Service.messages(environment.messages.error, "error");
                  }
                );
              }
              return;
            }

            if (
              this.Service.RemoteConfg &&
              this.Service.RemoteConfg.enable_share_a_sale &&
              this.Service.RemoteConfg.enable_share_a_sale._value === "true" &&
              this.Service.sscid
            ) {
              this.Service.loadImg(
                this.data.data.referenceNumber,
                this.Service.sscid
              );
            }

            this.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Booking-CC",
              "Successful",
              1
            );
            ga("ecommerce:clear");
            this.googleAnalyticsService.addTransaction(
              this.data.data.referenceNumber,
              this.Service.newRate.finalSellingPrice
            );
            // GA4
            let location = "";
            if (this.data.data.cityName) {
              location = this.data.data.cityName;
            }
            if (this.data.data.stateName) {
              location += ", " + this.data.data.stateName;
            }
            if (this.data.data.countryName) {
              location += ", " + this.data.data.countryName;
            }
            this.googleAnalytics4Service.addTransaction(
              this.data.data.currency.currency,
              this.data.data.referenceNumber,
              this.Service.newRate.finalSellingPrice,
              this.data.data.companyId,
              name,
              location,
              "Hotel",
              "Self"
            );
            // end of GA4
            this.googleAnalyticsService.addItem(
              this.data.data.referenceNumber,
              this.Service.newRate.finalSellingPrice,
              this.Service.hotelData.name
            );
            sessionStorage.setItem("voucher", JSON.stringify(this.data));
            this.router.navigate([this.voucherUrl], {
              queryParams: {
                ref: this.data.data.referenceNumber,
                email: this.data.data.emailAddress,
                lastname: this.data.data.guestLastName,
              },
            });
            this.notSignInSignUP();
          } else if (this.data.errorCode === 20) {
            this.router.navigateByUrl(this.previousUrl);
            this.Service.messagesOverRoute(this.errorMsg, "warning");
            this.Service.bookingSubmit = false;
            this.submittingApproval = false;
          } else if (this.data.errorCode === 54) {
            this.Service.setModal(
              '<i class="icon-warning"></i> warning',
              "completeDuplicateBooking",
              this.data.message
            );
            this.Service.bookingSubmit = false;
            this.submittingApproval = false;
          } else {
            this.Service.errorMsg = this.errorMsg;
            this.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Booking-CC",
              "Failed",
              1
            );
            this.Service.bookingSubmit = false;
            this.submittingApproval = false;
          }
        },
        (error) => {
          this.Service.errorMsg = this.errorMsg;
          this.Service.bookingSubmit = false;
          this.submittingApproval = false;
        }
      );
    }
  }

  private resetStatus(): void {
    this.showError = false;
    this.showSuccess = false;
    this.showCancel = false;
  }

  onItemChange(value) {
    this.paymentType = value.target.defaultValue;
    if (this.paymentType === "paypal" || this.paymentType === "gpay") {
      this.submitted = true;
    }
    this.bookingForm.get("HolderName").setValue("");
    if (this.paymentType === "paypal") {
      this.ServiceUp = false;
      this.Service.actuator().subscribe(
        (data) => {
          let res: any = data;
          if (res.status === "UP") {
            this.ServiceUp = true;
          } else {
            this.ServiceDown = true;
          }
        },
        (error1) => {
          this.ServiceDown = true;
        }
      );
    }
  }

  savedCardChange() {
    if (this.SelectedsavedCard !== "new") {
      this.paytype = "savedCard";
      this.showcardControl = false;
      this.changeRememberMe = false;
    } else {
      this.showcardControl = true;
      this.paytype = "STRIPE";
    }
    if (this.Auth.isLogedIn && this.SelectedsavedCard === "new") {
      this.showcardControl = true;
    }
    this.bookingForm.get("HolderName").setValue("");
  }

  PayPalConfig(price) {
    const dis = this;
    const myNode = document.getElementById("paypal-button-container");

    if (myNode) {
      while (myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
    }
    const payPalStyle = {
      size: "responsive",
      color: "gold",
      shape: "rect",
      label: "pay",
      height: 55,
    };
    if (this.requiresApprovalData) {
      payPalStyle.color = "blue";
      payPalStyle.label = "checkout";
    }
    if (paypal.Button) {
      paypal.Button.render(
        {
          env: environment.PAYPAL_AUTHEND, // sandbox | production
          style: payPalStyle,

          client: {
            sandbox: environment.paypal,
            production: environment.paypal,
          },

          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,

          // payment() is called when the button is clicked
          payment(data, actions) {
            const startPaypalTime = new Date().getTime();

            // Make a call to the REST api to create the payment

            return actions.payment.create({
              payment: {
                intent: "authorize",
                transactions: [
                  {
                    amount: { total: price, currency: "USD" },
                  },
                ],
              },
            });
          },

          // onAuthorize() is called when the buyer approves the payment
          onAuthorize(data, actions) {
            // Make a call to the REST api to execute the payment
            return actions.payment.execute().then(() => {
              dis.paymentID = data.paymentID;
              dis.paytype = "paypal";
              dis.onSubmit();
            });
          },
          onCancel: function (data, actions) {
            // Show a cancel page or return to cart

            dis.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Booking-PayPal",
              "Cancel",
              1
            );
          },
        },
        "#paypal-button-container"
      );
    }
  }

  notSignInSignUP() {
    if (
      !this.Auth.isLogedIn &&
      this.bookingForm.controls.password.value !== ""
    ) {
      this.Auth.signUp(
        this.bookingForm.controls.FirstName.value,
        this.bookingForm.controls.LastName.value,
        this.bookingForm.controls.email.value,
        this.bookingForm.controls.password.value,
        "",
        ""
      ).subscribe();
    }
  }

  tripAdvisorTrigger() {
    this.googleAnalyticsService.eventEmitter(
      "Stats",
      "TripAdvisor",
      "click",
      1
    );
  }

  googlePay(price) {
    const dis = this;
    const baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
    };

    const allowedCardNetworks = [
      "AMEX",
      "DISCOVER",
      "INTERAC",
      "JCB",
      "MASTERCARD",
      "VISA",
    ];

    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

    const tokenizationSpecification = {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: "stripe",
        "stripe:version": "2018-10-31",
        "stripe:publishableKey": environment.stripeKey,
      },
    };

    const baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
      },
    };

    /**
     * Describe your site's support for the CARD payment method including optional
     * fields
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
     */
    const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
      tokenizationSpecification: tokenizationSpecification,
    });

    /**
     * An initialized google.payments.api.PaymentsClient object or null if not yet set
     *
     * @see {@link getGooglePaymentsClient}
     */
    let paymentsClient = null;

    /**
     * Configure your site's support for payment methods supported by the Google Pay
     * API.
     *
     * Each member of allowedPaymentMethods should contain only the required fields,
     * allowing reuse of this base request when determining a viewer's ability
     * to pay and later requesting a supported payment method
     *
     * @returns {object} Google Pay API version, payment methods supported by the site
     */
    function getGoogleIsReadyToPayRequest() {
      return Object.assign({}, baseRequest, {
        allowedPaymentMethods: [baseCardPaymentMethod],
      });
    }

    /**
     * Configure support for the Google Pay API
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
     * @returns {object} PaymentDataRequest fields
     */
    function getGooglePaymentDataRequest() {
      let paymentDataRequest: any;
      paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
      paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
      paymentDataRequest.merchantInfo = {
        merchantId: "07587491259641497211",
        merchantName: "Triphop, Inc.",
      };
      return paymentDataRequest;
    }

    function getGooglePaymentsClient() {
      if (paymentsClient === null) {
        paymentsClient = new google.payments.api.PaymentsClient({
          environment: environment.googlePayEnv,
        });
      } else {
        dis.Service.bookingPage = false;
      }
      return paymentsClient;
    }
    if (typeof google !== "undefined") {
      paymentsClient = getGooglePaymentsClient();
    } else {
      dis.Service.bookingPage = false;
      return;
    }
    paymentsClient
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(function (response) {
        dis.Service.bookingPage = false;
        if (response.result) {
          dis.googlrSuported = true;
          addGooglePayButton();
        }
      })
      .catch(function (err) {
        dis.errorMsg = environment.messages.error;
        dis.Service.bookingPage = false;
      });

    function addGooglePayButton() {
      const myNode = document.getElementById("google-pay-container");

      while (myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
      const paymentsClient = getGooglePaymentsClient();
      const button = paymentsClient.createButton({
        onClick: onGooglePaymentButtonClicked,
      });
      document.getElementById("google-pay-container").appendChild(button);
    }

    function getGoogleTransactionInfo() {
      return {
        countryCode: "US",
        currencyCode: "USD",
        totalPriceStatus: "FINAL",
        // set to cart total
        totalPrice: price,
      };
    }

    /**
     * Prefetch payment data to improve performance
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
     */
    function prefetchGooglePaymentData() {
      const paymentDataRequest = getGooglePaymentDataRequest();
      // transactionInfo must be set but does not affect cache
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        currencyCode: "USD",
      };
      const paymentsClient = getGooglePaymentsClient();
      paymentsClient.prefetchPaymentData(paymentDataRequest);
    }

    /**
     * Show Google Pay payment sheet when Google Pay payment button is clicked
     */
    function onGooglePaymentButtonClicked() {
      const paymentDataRequest = getGooglePaymentDataRequest();
      paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

      const paymentsClient = getGooglePaymentsClient();
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(function (paymentData) {
          processPayment(paymentData);
        })
        .catch(function (err) {
          // show error in developer console for debugging
          dis.errorMsg = environment.messages.error;
          dis.Service.bookingPage = false;
        });
    }

    /**
     * Process payment data returned by the Google Pay API
     *
     * @param {object} paymentData response from Google Pay API after user approves payment
     * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
     */
    function processPayment(paymentData) {
      const token = JSON.parse(
        paymentData.paymentMethodData.tokenizationData.token
      ).id;
      dis.paytype = "STRIPE";
      dis.cardToken = token;
      dis.errorMsg = "";
      dis.onSubmit();
    }
  }

  SendBookingFormFields() {
    if (this.Service.mobileView) {
    }
  }

  openSSL() {
    window.open(
      "https://www.ssl.com/team/a5c-1bauoij/site_seals/0532d156-58ecc6a5/site_report",
      "site_report",
      "height=500, width=400, top=100, left=100"
    );
    return false;
  }

  optionClicked(event: Event, user: User) {
    this.selectedUser = user;
    this.Service.enableBodyScroll();
  }
}
