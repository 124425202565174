<!--<div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
  <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/users']);">Members</a>
  <span class="font-18 gray-text-dark font-weight-bold"> / Invite </span>
</div>-->
<div class="container pb-5 invite-page">

   <div>

<div class="share-box-center mx-auto d-block py-4 px-0">

    <p class="gray-primaray-text font-16 mb-2 mt-0 " i18n="@@emailAddresses">Email Addresses</p>
    <div class="input-group row no-gutters ">
      <div class="col" (click)="placeholder = false">

        <div class="tag-container">
          <tag-input [ngModel]="" [modelAsStrings]="true"
                     #tagInput
                     name="emails"
                     #email="ngModel"
                     [errorMessages]="errorMessages"
                     [validators]="validators"
                     [editable]='true'
                     [separatorKeyCodes]="[32,188,186,13,9]"
                     [placeholder]="''"
                     [secondaryPlaceholder]="''"
                     [addOnPaste]="true"
                     [addOnBlur]="true"
                     [onAdding]="onAddedFunc"
                     [blinkIfDupe]="true"
                     [ripple]="false"
                     [ngClass]="{ 'pl-3': dataService.isSiteLtr(), 'pr-3': dataService.isSiteRtl(), 'error': submitted && this.emails.length === 0 }"
                     class="tag-input-field border rounded"
                     required >

            <ng-template let-item="item" let-index="index">
              <div class="d-flex" style="direction: ltr"><p class="dotted-text">{{ item }}</p> <delete-icon (click)="tagInput.removeItem(item, index); removeFromArray(item, index)"></delete-icon></div>
            </ng-template>

          </tag-input>
        </div>
        <span class="placeholder-text position-absolute gray-text" *ngIf="placeholder"
              [ngClass]="{'font-11': dataService.isSiteRtl()}" i18n="@@insertEmail">Enter Emails (press enter to insert multi emails)</span>


      </div>
   <!--   <div class=" col-md-3 input-group-append">
        <button (click)="sendEmail()" class="btn btn-block btn-primary text-center pl-5 pr-5 mt-3 mt-lg-0"
                [ngClass]="{'m-progress': submitting }">Send Invitations</button>

      </div>-->
    </div>

  <div *ngIf="submitted" >
    <div *ngIf="this.emails.length === 0" class="empty-emails" i18n="@@emailsRequired">Emails are required</div>
  </div>
  <p class="gray-primaray-text font-16 mb-2 mt-4 pt-3" i18n="@@team">Team</p>
 <!-- <form name="usersInviteForm" [formGroup]="usersInviteForm" >
    <div class="form-group  mt-2 form-type-select" *ngIf="dataService.companyTeams && defaultTeamInfo">
      <select class="form-control" name="teamName" formControlName="teamName">
        <option value="0" [selected]="true">{{defaultTeamInfo.name}}</option>
        <ng-container  *ngFor="let team of this.dataService.companyTeams; " >
          <option *ngIf="team.id !== '0'" [value]="team.id" >{{team.name}}</option>
        </ng-container>
      </select>
    </div>
  </form>-->

<div>
  <mat-form-field class="w-100 chips-container rounded border"
                  *ngIf="dataService.desktopView" (click)="teamInput.select()">
    <i [ngClass]="{'gray-text': showClearIcon, 'prevent-pointer-event text-white': !showClearIcon}" class="icon-cross small  pointer clear-text p-1"
       (click)= "teamInput.value = '';" ></i>
    <input class="min-height mx-0 my-0" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}" #teamInput type="text" matInput [formControl]="teamControl" [matAutocomplete]="autoTeam"
           (blur)="resetTeam(); " (click)="showClearIcon = true" (focus)="showIcon()" >
    <mat-autocomplete #autoTeam="matAutocomplete" [displayWith]="displayFnTeam">
      <mat-option style="padding: 0" *ngFor="let team of filteredTeams | async;" [value]="team">
        <div  (click)="optionClickedTeam($event, team)" class="mat-checkbox-container">
          {{team.name}}
        </div>
      </mat-option>

        <mat-option style="padding: 0" *ngIf="dataService.desktopView && (this.auth.isAdmin() || this.auth.isOwner())">
          <div  (click)="this.dataService.setModal(this.dataService.createNewTeam, 'createNewTeam', '')" class="mat-checkbox-container">
            <p class="blue-text" [ngClass]="{'mb-0 ml-1': dataService.desktopView}" i18n="@@createNewTeam">Create new team</p>
          </div>
        </mat-option>

    </mat-autocomplete>
  </mat-form-field>

  <div class="form-control rounded" *ngIf="dataService.mobileView " (click)="showTeamPopUp();">
    <p *ngIf="selectedTeam" class="mt-2 pt-1">{{selectedTeam.name}}</p>
  </div>
</div>
  <input type="text" placeholder="No available teams" class="form-control"  disabled *ngIf="teamList && !teamList.length && dataService.mobileView">

  <div class="form-group font-16 mb-0 gray-primaray-text mt-4 pt-3">
    <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView, 'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" type="checkbox"
           id="isAdmin" name="isAdmin"  (change)="isAdmin = !isAdmin"/>
    <label [ngClass]="{'checkbox-mobile': dataService.mobileView}" for="isAdmin" i18n="@@setAsAdmin">Set as Admin</label>
    <!-- <span [ngClass]="{'realtime-loader custom2': dataService.adminLoader }"></span>-->
    <p class="gray-text font-14" [ngClass]="{'pl-1 ml-4': dataService.isSiteLtr(), 'pr-1 mr-4': dataService.isSiteRtl(), 'p-min-width': dataService.desktopView}" i18n="@@setAsAdminDesc">Admin can manage members, teams, travel policies, view subscription and billing history and workspace integrations.</p>
  </div>


</div>
   </div>
  <div class="d-flex justify-content-start mt-5" [ngClass]="{'ml-4': dataService.isSiteLtr(), 'mr-4': dataService.isSiteRtl()}" *ngIf="dataService.desktopView">
    <button class="btn-outline-gray btn font-14 py-1 px-5 sharp-font cancel-btn" (click)="cancelInvite()" i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn py-0 px-5 font-14 sharp-font" (click)="sendEmail()" [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl(), 'm-progress': submitting}" i18n="@@sendInvite">Send Invitation</button>
  </div>
  <div class="mt-5" *ngIf="dataService.mobileView">
    <div class="d-flex justify-content-end">
      <button class="btn blue_btn font-14 py-1 mb-4 px-5 w-100 sharp-font" (click)="sendEmail()" [ngClass]="{'m-progress': submitting}" i18n="@@sendInvite">Send Invitation</button>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn-outline-gray btn font-14 py-1 px-5 w-100 sharp-font"  (click)="cancelInvite()" i18n="@@cancelBtn">Cancel </button>
    </div>

  </div>
  <div *ngIf="errorMsg" >
    <div class="empty-emails pt-3"  [ngClass]="{ 'text-right': dataService.desktopView}">{{this.errorMsg}}</div>
  </div>
</div>





<div class="justify-content-end create-team" >

  <div class="" [ngClass]="{'in action-popup':showTeamAutoCompletePopUp}">
    <div [ngClass]="{'header':showTeamAutoCompletePopUp}" >
      <div *ngIf="showTeamAutoCompletePopUp">
        <div *ngIf="showTeamAutoCompletePopUp">

          <div class="info" >
            <span *ngIf="showTeamAutoCompletePopUp" i18n="@@team">Team</span>
          </div>

          <div class="close" (click)="hidePopUp();" i18n="@@cancelBtn">
            Cancel
          </div>
        </div>
      </div>




      <div class="position-relative mobile-search-field" *ngIf="showTeamAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}" type="text" matInput #teamInput id="teamInput" [formControl]="teamControl" [matAutocomplete]="autoTeam" (blur)="resetTeam()">
            <mat-autocomplete #autoTeam="matAutocomplete" [displayWith]="displayFnTeam">
              <mat-option style="padding: 0" *ngFor="let team of filteredTeams | async" [value]="team">
                <div (click)="optionClickedTeam($event, team)" class="mat-checkbox-container">
                  {{team.name}}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>





    </div>



  </div>
</div>
