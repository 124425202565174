<div class="container voucher-component mt-4">
  <div class="d-flex">
    <h2
      class="green-text bolder p-2 font-24-to-18"
      [ngClass]="{
        'mr-auto': Service.isSiteLtr(),
        'ml-auto': Service.isSiteRtl()
      }"
    >
      <i
        class="icon-success"
        [ngClass]="{ 'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl() }"
      >
      </i
      >&nbsp;<ng-container i18n="@@successfullyBookedHotel"
        >You have successfully booked your hotel!</ng-container
      >
    </h2>
    <div>
      <button
        class="btn btn-link karla-font font-16"
        [ngClass]="{ 'pr-3': Service.isSiteLtr(), 'pl-3': Service.isSiteRtl() }"
        (click)="this.Service.showHotelPrint = true; print()"
        *ngIf="this.Service.desktopView"
        i18n="@@print"
      >
        Print
      </button>
      <button
        (click)="openSendBookingVoucherPopUp()"
        class="btn btn-link karla-font font-16"
        *ngIf="this.Service.desktopView"
        i18n="@@email"
      >
        Email
      </button>
    </div>
  </div>
  <p
    *ngIf="Service.mobileView"
    class="blue-text font-weight-bold font-16 karla-font pt-3 pb-3"
    (click)="shareByEmailMobile()"
  >
    <i class="icon-share-email"></i>&nbsp;<ng-container i18n="@@shareByEmail"
      >Share by email</ng-container
    >
  </p>
  <div class="row">
    <div class="col-md-8">
      <div class="hotel-item-small rounded border mb-5 overflow-hidden">
        <div class="hotel-image">
          <img
            loading="lazy"
            *ngIf="
              VoucherData.data.hotelImages[0] &&
              VoucherData.data.hotelImages[0].url
            "
            [ngClass]="{
              'rounded-left': Service.isSiteLtr(),
              'rounded-right': Service.isSiteRtl()
            }"
            [src]="
              (VoucherData.data.hotelImages[0].url &&
                VoucherData.data.hotelImages[0].url) ||
              'assets/img/no_image.png'
            "
            alt="hotel image"
          />
          <img
            loading="lazy"
            class="no-hotel-mages"
            *ngIf="!VoucherData.data.hotelImages[0]"
            src="assets/img/no_image.png"
          />
        </div>
        <div class="hotel-info">
          <h4 class="primaray-text bolder font-18">
            {{ VoucherData.data.hotelName }}
          </h4>
          <div
            *ngIf="VoucherData.data.hotelRating"
            class="gray-text font-16 font-weight-normal"
          >
            {{ VoucherData.data.hotelRating }}-<ng-container
              *ngIf="VoucherData.data.hotelRating <= 2"
              i18n="@@oneStar"
              >star</ng-container
            ><ng-container
              *ngIf="VoucherData.data.hotelRating > 2"
              i18n="@@stars"
              >star</ng-container
            >
          </div>
          <div class="address">
            {{
              VoucherData.data.hotelAddress
                ? VoucherData.data.hotelAddress
                : ""
            }}{{
              VoucherData.data.hotelAddress && VoucherData.data.cityName
                ? " "
                : ""
            }}{{ VoucherData.data.cityName ? VoucherData.data.cityName : ""
            }}{{
              VoucherData.data.stateCode && VoucherData.data.cityName
                ? ", "
                : ""
            }}{{ VoucherData.data.stateCode ? VoucherData.data.stateCode : ""
            }}{{
              (VoucherData.data.stateCode || VoucherData.data.cityName) &&
              VoucherData.data.countryName
                ? ", "
                : ""
            }}{{
              VoucherData.data.countryName ? VoucherData.data.countryName : ""
            }}
          </div>
        </div>
      </div>
      <div class="section-info radius mb-5">
        <div class="section-header">
          <span
            class="primaray-text text-uppercase bolder"
            i18n="@@bookingSummary"
            >Booking Summary
          </span>
        </div>
        <div class="section-body gray-text-dark p-5">
          <div class="">
            <h4 class="mt-0 mb-3 bolder font-16" i18n="@@guestDetails">
              Guest Details
            </h4>
            <div class="gray-text-dark">
              <p>
                <ng-container i18n="@@confirmationNumber"
                  >Confirmation Number</ng-container
                >: {{ VoucherData.data.referenceNumber }}
              </p>
              <p>
                <ng-container i18n="@@firstNamePlaceHolder"
                  >First Name</ng-container
                >: {{ VoucherData.data.guestFirstName }}
              </p>
              <p>
                <ng-container i18n="@@lastNamePlaceHolder"
                  >Last Name</ng-container
                >: {{ VoucherData.data.guestLastName }}
              </p>
              <p>
                <ng-container i18n="@@emailAddress">Email Address</ng-container
                >: {{ VoucherData.data.emailAddress }}
              </p>
              <p>
                <ng-container i18n="@@phoneNumber">Phone Number</ng-container>:
                {{ VoucherData.data.phoneNumber }}
              </p>
            </div>
          </div>
          <div class="border-top mt-4 pt-4">
            <h4 class="mt-0 mb-3 bolder font-16" i18n="@@roomDetails">
              Room Details
            </h4>
            <div class="gray-text-dark">
              <p *ngIf="VoucherData.data.roomName">
                <ng-container i18n="@@roomName">Room Name</ng-container>:
                {{ VoucherData.data.roomName }}
              </p>
              <p *ngIf="VoucherData.data.boardDescription">
                {{ VoucherData.data.boardDescription }}
              </p>
              <div
                class=""
                *ngIf="
                  VoucherData.data.freeWifi || VoucherData.data.freeParking
                "
              >
                <p *ngIf="VoucherData.data.freeWifi" i18n="@@freeWiFi">
                  Free WiFi
                </p>
                <p *ngIf="VoucherData.data.freeParking" i18n="@@freeparking">
                  Free parking
                </p>
              </div>
            </div>
            <div class="gray-text-dark">
              <p *ngIf="VoucherData.data.checkinDate">
                <ng-container i18n="@@checkInNoDash">Check in</ng-container
                >&nbsp;:
                {{ VoucherData.data.checkinDate | date : "EE, MMM d " }}
              </p>
              <p *ngIf="VoucherData.data.checkoutDate">
                <ng-container i18n="@@checkOutNoDash">Check out</ng-container
                >&nbsp;:
                {{ VoucherData.data.checkoutDate | date : "EE, MMM d " }}
              </p>
              <div class="mb-3" *ngIf="VoucherData.data.dailtyRates">
                <ng-container i18n="@@totalStay">Total Stay</ng-container
                >&nbsp;: {{ VoucherData.data.dailtyRates.length }}
                <div class="d-inline">
                  <ng-container
                    *ngIf="VoucherData.data.dailtyRates.length === 1"
                    i18n="@@nightNoNumber"
                    >night</ng-container
                  >
                  <ng-container
                    *ngIf="VoucherData.data.dailtyRates.length === 2"
                    i18n="@@twoNightsNoNumber"
                    >nights</ng-container
                  >
                  <ng-container
                    *ngIf="VoucherData.data.dailtyRates.length > 2"
                    i18n="@@nights"
                    >nights</ng-container
                  >
                </div>
              </div>
              <div
                class="mb-3"
                *ngIf="VoucherData.data.paxes.roomInfos[0].numberOfAdults"
              >
                <ng-container i18n="@@adults">Adults</ng-container>&nbsp;:
                {{ VoucherData.data.paxes.roomInfos[0].numberOfAdults }}
                <div class="d-inline text-lowercase">
                  <ng-container
                    *ngIf="
                      VoucherData.data.paxes.roomInfos[0].numberOfAdults === 1
                    "
                    i18n="@@adult"
                    >adult</ng-container
                  >
                  <ng-container
                    *ngIf="
                      VoucherData.data.paxes.roomInfos[0].numberOfAdults === 2
                    "
                    i18n="@@twoAdults"
                    >adults</ng-container
                  >
                  <ng-container
                    *ngIf="
                      VoucherData.data.paxes.roomInfos[0].numberOfAdults > 2
                    "
                    i18n="@@adults"
                    >adults</ng-container
                  >
                </div>
              </div>
              <div
                class="mb-3"
                *ngIf="
                  VoucherData.data.paxes.roomInfos[0].childAges &&
                  VoucherData.data.paxes.roomInfos[0].childAges.length
                "
              >
                Children :
                {{ VoucherData.data.paxes.roomInfos[0].childAges.length }}
                <div class="d-inline text-lowercase">
                  <ng-container
                    *ngIf="
                      VoucherData.data.paxes.roomInfos[0].childAges.length === 1
                    "
                    i18n="@@child"
                    >child</ng-container
                  >
                  <ng-container
                    *ngIf="
                      VoucherData.data.paxes.roomInfos[0].childAges.length === 2
                    "
                    i18n="@@twoChildren"
                    >children</ng-container
                  >
                  <ng-container
                    *ngIf="
                      VoucherData.data.paxes.roomInfos[0].childAges.length > 2
                    "
                    i18n="@@children"
                    >children</ng-container
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="border-top mt-4 pt-4"
            *ngIf="VoucherData.data.specialRequestText"
          >
            <h4 class="mt-0 mb-3 bolder font-16" i18n="@@specialRequests">
              Special Requests
            </h4>
            <div class="text-medium">
              {{ VoucherData.data.specialRequestText }}
            </div>
          </div>
          <div
            class="border-top mt-4 pt-4"
            *ngIf="VoucherData.data.providerComment"
          >
            <h4 class="mt-0 mb-3 bolder font-16" i18n="@@additionalInfo">
              Additional Info.
            </h4>
            <p
              class="gray-text-dark list-unstyled"
              [innerHTML]="VoucherData.data.providerComment"
            ></p>
          </div>
          <div
            class="border-top mt-4 pt-4"
            *ngIf="VoucherData.data != 'completed'"
          >
            <h4 class="mt-0 mb-3 bolder font-16" i18n="@@cancellationPolicies">
              Cancellation Policies
            </h4>
            <div *ngIf="VoucherData.data.refundable">
              <div
                class="policy"
                *ngFor="let policy of VoucherData.data.cancellationPolicies"
              >
                {{ policy.description }}
              </div>
            </div>
            <div
              class="non-refundable"
              *ngIf="!VoucherData.data.refundable"
              i18n="@@nonrefundable"
            >
              Non-refundable
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="white-box p-0 mb-4">
        <div class="p-3">
          <h5 class="font-18 bolder mb-3" i18n="@@priceSummary">
            Price Summary
          </h5>
          <ng-container>
            <div
              class="daily"
              *ngFor="let daily of VoucherData.data.dailtyRates"
            >
              <div class="d-flex pb-1 text-medium">
                <span> {{ Service.formatDateEEMMMdy(daily.date) }}</span>
                <span
                  class="bolder"
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl(),
                    'bright-purple': VoucherData.data.primeBooking
                  }"
                >
                  <span class="currency">{{ daily.sellingPriceConvertedCurrency }}</span
                  ><span class="amount"> {{ daily.sellingPriceConverted | number }} </span>
                </span>
              </div>
            </div>
          </ng-container>
          <div
            class="discount"
            *ngIf="!VoucherData.data.taxIncluded && VoucherData.data.tax"
          >
            <div class="d-flex pb-1 text-medium">
              <span i18n="@@taxAndFees"> Tax and fees</span>
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                ><span class="currency">$</span
                ><span class="amount">{{ VoucherData.data.tax }}</span></span
              >
            </div>
          </div>
          <div class="discount" *ngIf="VoucherData.data.userDiscount">
            <div class="d-flex pb-1 primaray-text text-medium">
              <span class="bolder"> Discount</span>
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                ><span class="currency">-$</span
                ><span class="amount">{{
                  VoucherData.data.userDiscount.amount | number : "1.2-2"
                }}</span></span
              >
            </div>
          </div>
          <div
            class="discount"
            *ngIf="
              VoucherData.data.coupon && VoucherData.data.discountAmount > 0
            "
          >
            <div class="d-flex pb-1 primaray-text text-medium">
              <span class="bolder"> Coupon</span>
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                ><span class="currency">-$</span
                ><span class="amount">{{
                  VoucherData.data.discountAmount | number : "1.2-2"
                }}</span></span
              >
            </div>
          </div>
          <div
            class="discount d-flex text-medium"
            *ngIf="
              VoucherData.data.travelerId &&
              VoucherData.data.travelerId === 'guest'
            "
          >
            <span
              [ngClass]="{
                'mr-auto': Service.isSiteLtr(),
                'ml-auto': Service.isSiteRtl()
              }"
              i18n="@@bookingFee"
            >
              Booking fee</span
            >
            <span
              class="bolder"
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl()
              }"
              >${{ VoucherData.data.guestFee | number : "1.2-2" }}</span
            >
          </div>
        </div>
        <div class="white-box-footer rounded-bottom p-3">
          <div class="d-flex font-22 bolder">
            <span class="primaray-text" i18n="@@Total"> Total </span>
            <span
              class="primaray-text"
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl()
              }"
            >
              <span class="currency">{{
                VoucherData.data.sellingPriceConvertedCurrency
              }}</span
              ><span class="amount"> {{
                VoucherData.data.sellingPriceConverted
              }} </span></span
            >
          </div>
        </div>
      </div>
      <div class="white-box p-0" *ngIf="VoucherData.data.rewardAmount">
        <div
          class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"
        >
          <ng-container i18n="@@rewards">Rewards</ng-container>:
          <ng-container *ngIf="Service.isSiteLtr()">$</ng-container
          >{{ VoucherData.data.rewardAmount | number : "1.2-2"
          }}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
        </div>
        <div
          class="px-3 pt-3 pb-4 gray-primaray-text font-16"
          i18n="@@voucherUponCompleting"
        >
          You will earn ${{
            VoucherData.data.rewardAmount | number : "1.2-2"
          }}
          upon completing this stay
        </div>
      </div>
      <div *ngIf="VoucherData.data">
        <div class="map-side-details mt-3">
          <span>
            <button
              class="button btn-link border toggleView map-illustration not_button mb-md-5 mb-0 btn-auto"
              (click)="mapPopUp()"
            >
              <span
                ><ng-container i18n="@@viewMap">view map</ng-container
                ><i
                  class="icon-locator"
                  [ngClass]="{
                    'pl-2': Service.isSiteLtr(),
                    'pr-2': Service.isSiteRtl()
                  }"
                ></i
              ></span>
            </button>
          </span>
        </div>
      </div>
      <br />
      <div *ngIf="Service.isCompany() && Service.showExpensifyIcon">
        <img
          (click)="
            Service.showUploadExpensesPopUp(VoucherData.data.referenceNumber)
          "
          width="150"
          height="23"
          loading="lazy"
          class="max-100 initial-height mb-3 pointer"
          [ngClass]="{
            'pr-3': Service.isSiteLtr(),
            'pl-3': Service.isSiteRtl()
          }"
          src="/assets/img/expensify-logo--reverse.png"
        />
        <p
          class="gray-text mb-1"
          *ngIf="this.Service.expensifyLastModifiedDate"
          [innerHTML]="formatDate(this.Service.expensifyLastModifiedDate)"
        ></p>
        <p class="gray-text" *ngIf="this.Service.expensifyLastReportName">
          <ng-container i18n="@@updatedReportName"
            >Updated report name</ng-container
          >: {{ this.Service.expensifyLastReportName }}
        </p>
        <p
          class="blue-text font-weight-bold pointer"
          (click)="
            Service.showUploadExpensesPopUp(VoucherData.data.referenceNumber)
          "
          i18n="@@uploadExpense"
        >
          Upload Expense
        </p>
      </div>
      <app-cost-reporting-details
        [costCenters]="
          VoucherData.data?.costCenter ? [VoucherData.data?.costCenter] : ''
        "
        [labels]="VoucherData.data?.labels ? VoucherData.data?.labels : []"
      >
      </app-cost-reporting-details>
    </div>
  </div>
</div>
<app-notifications
  *ngIf="Service.ShowPushNotificationsPopUp"
></app-notifications>
