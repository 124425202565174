import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DataService} from '../../../services/data.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-disapprove-request',
  templateUrl: './disapprove-request.component.html',
  styleUrls: ['./disapprove-request.component.scss']
})
export class DisapproveRequestComponent implements OnInit {
  disapproveForm: FormGroup;
  submitting;
  constructor(private formBuilder: FormBuilder, public dataService: DataService) { }
  reasonsArray: any = [
    {id: 0, text: $localize`:@@declineArray1:Out of policy`},
    {id: 1, text: $localize`:@@declineArray2:Travel is not required`},
    {id: 2, text: $localize`:@@declineArray3:Find another hotel`},
    {id: 3, text: $localize`:@@declineArray4:Other`},
  ];
  @Input() approvalId: string;
  @Input() requesterName: string;
  @Input() commentAndReason: string;
  @Input() isFlight: string;
  @Input() pageType: string;
  selectedReason;
  data;
  dataFlight;
  errorMsg;
  ngOnInit(): void {
    this.disapproveForm = this.formBuilder.group({
      reasons: [''],
    }, {
    });
    if(this.isFlight) {
      this.reasonsArray[2].text = $localize`:@@declineArray5:Find another flight`;
    }
  }
  get ctrl() { return this.disapproveForm.controls; }
  selectReason(reason) {
    if (reason) {
      this.selectedReason = reason;
    }
  }
  confirmTripDisapproval(approvalId) {
    this.submitting = true;
    if (this.selectedReason) {
      this.commentAndReason += ('&reason=' + this.selectedReason);
    }
      this.dataService.disapproveTrip(approvalId, this.commentAndReason, this.isFlight).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            if (this.pageType === 'list') {
              this.dataService.globalListApproversApprovals('PENDING_APPROVAL');
            } else {


              if(this.isFlight){
                this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
              } else{
                this.dataService.getApprovalDetailsInfo(this.approvalId);
              }
              this.dataService.getListApproversApprovals('PENDING_APPROVAL').subscribe(
                dataApprove => {
                  this.data = dataApprove;
                  if (this.data.errorCode === 0) {
                    const pendingArray = this.data.data.filter(item => item.status.toLowerCase() === 'pending_approval');
                    this.dataService.getFlightListApproversApprovals('PENDING_APPROVAL').subscribe(

                      dataApproveFlight => {
                        this.dataFlight = dataApproveFlight;
                        if (this.dataFlight.errorCode === 0) {

                          const pendingArrayFlight = this.dataFlight.data.filter(item => item.status.toLowerCase() === 'pending_approval');
                          localStorage.setItem('workspaceApprovalsBadgeFlight', pendingArrayFlight.length);
                          this.dataService.workspaceApprovalsBadge = pendingArray.length + pendingArrayFlight.length;
                        } else {
                          this.errorMsg = this.dataFlight.message;
                        }
                      }, error => {
                        this.errorMsg = environment.messages.error;
                      }



                    );
                    /*  const pendingArray = this.data.data.filter(item => item.status.toLowerCase() === 'pending_approval');
                      localStorage.setItem('workspaceApprovalsBadge', pendingArray.length);
                      this.dataService.workspaceApprovalsBadge = pendingArray.length;*/
                  } else {
                    this.errorMsg = this.data.message;
                    this.submitting = false;
                  }
                }, error => {
                  this.errorMsg = environment.messages.error;
                  this.submitting = false;
                }
              );
            }
            if (this.dataService.desktopView) {
              this.dataService.modalOf();
              this.dataService.scrollTop();
            }
            if (this.dataService.mobileView) {
              this.dataService.BottomSheetOf();
            }
            this.submitting = false;
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
          }
        }, error => {
          this.errorMsg = environment.messages.error;
          this.submitting = false;
        }
      );

  }
}
