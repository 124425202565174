import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthServiceMain} from "../../../auth/auth.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class TeamsListComponent implements OnInit {

  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService, public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Teams list | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
data;
  approvalActions = false;
  selectedIndex;
 // teams;
  ngOnInit(): void {
    this.dataService.loadingFlag = false;
    this.dataService.getCompanyTeamsList();
  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target) ){
      this.approvalActions = false;
    }
  }
  removeTeam(teamId, teamName) {
    const teamData = {
      teamIdValue: teamId,
      teamNameValue: teamName  };
    if (this.dataService.desktopView) {
      this.dataService.setModal('Delete Team', 'removeTeam', teamData);
    } else {
      this.dataService.setBottomSheet('removeTeam', teamData);
    }
  }
  createNewTeam(){
    /*if (this.dataService.desktopView) {
      this.dataService.setModal('New Team', 'createNewTeam', '');
    } else {*/
      this.router.navigate([this.router.url + '/create']);
   // }
  }
}
