import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DataService } from "../../../../services/data.service";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { AuthServiceMain } from "../../../../auth/auth.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { VariablesService } from "../../../../services/vars.service";
@Component({
  selector: "app-create-policy",
  templateUrl: "./create-policy.component.html",
  styleUrls: ["./create-policy.component.scss"],
})
export class CreatePolicyComponent implements OnInit {
  constructor(
    public vars: VariablesService,
    public auth: AuthServiceMain,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public router: Router
  ) {}
  disableHotelPolicy = false;
  disableFlightPolicy = false;
  showIntervalList = false;
  editMaxInterval = false;
  editMaxNoOfTrips = false;
  stepCounter = 1;
  editData;
  priceCap: boolean = false;
  Query = "";
  SearchTextQuery = new Subject<string>();
  caption = "";
  autocompletelist;
  showDropdown = false;
  citiesList = [];
  showAutoCompleteField = false;
  @Input() policyId: string;
  focusInputMobile;
  urlArray;
  defaultRequired = false;
  createPolicyForm: FormGroup;
  submitting;
  submitted;
  policyInfo;
  data;
  editPolicyName = false;
  editPolicyLimit = false;
  editFairClass = false;
  editFlightLimit = false;
  editFlightLimitCurrency = false;
  editInternationalLimit = false;
  editDomesticLimit = false;
  editCitiesLimit = [];
  cityLoaders = [];
  errorMessage;
  showPolicyNamePopUpFlag = false;
  showPolicyLimitPopUpFlag = false;
  showMaxNoOfTripsPopUpFlag = false;
  showSearchPopUpautocompleteFlag = false;
  showDomesticPriceCapFlag = false;
  showInternationalPriceCapFlag = false;
  showFlightPriceCapFlag = false;
  showCityPolicyLimitPopUpFlag = [];
  checkboxLoader;
  nameLoader;
  limitLoader;
  priceCapLoader;
  fairClassLoader;
  tripsMaxNoLoader;
  maxIntervalLoader;
  hotelToggleLoader;
  flightToggleLoader;
  internationalPriceCapLoader;
  domesticPriceCapLoader;
  selectedCityIndex;
  cityLimitMobileErrorMsg;
  cityLimitCurrencyMobileErrorMsg;
  currenciesList;
  preferencesList;

  options: any = [
    {
      id: "NO_APPROVAL_REQUIRED",
      name: $localize`:@@noApproval:No approval`,
      isChecked: false,
    },
    {
      id: "RULES_VIOLATION_APPROVAL_REQUIRED",
      name: $localize`:@@outOfPolicy:Out of policy`,
      note: $localize`:@@recommended:Recommended`,
      isChecked: false,
    },
    {
      id: "ALWAYS_APPROVAL_REQUIRED",
      name: $localize`:@@allTrips:All trips`,
      isChecked: false,
    },
  ];
  selectedId = "RULES_VIOLATION_APPROVAL_REQUIRED";
  selectedFairClass = "ECONOMY";
  selectedMaxInterval = "MONTHLY";
  policyObj = {
    approvalProcessType: "",
    companyId: "",
    companyPolicyFlightRules: {
      domesticPriceLimitPerPassenger: 0,
      domesticPriceLimitPerPassengerCurrency: "",
      internationalPriceLimitPerPassenger: 0,
      internationalPriceLimitPerPassengerCurrency: "",
      maximumCabinClass: "",
    },
    companyPolicyHotelRules: {
      avgNightlyPriceLimit: 0,
      avgNightlyPriceLimitCurrency: "",
      exceptions: [],
    },
    name: "",
    flightBookingNotAllowed: false,
    hotelBookingNotAllowed: false,
  };
  policyName;

  ngOnInit(): void {
    this.getPreferences();
    this.initUrl();
    this.initForm();
    this.getData();
    this.initSearchQuery();
  }

  getPreferences() {
    this.dataService.getPreferencesList().subscribe(
      (result) => {
        this.preferencesList = result;
        if (this.preferencesList.errorCode === 0) {
          this.initData();
        } else {
          this.handleError();
        }
      },
      () => {}
    );
  }

  initData() {
    this.currenciesList = this.preferencesList.data.currencies;
  }

  handleError() {
    this.errorMessage = this.preferencesList.message;
  }

  initUrl() {
    this.urlArray = this.router.url.split("/");
    if (
      this.router.url.includes("edit") &&
      this.router.url.includes("travel-policies")
    ) {
      // as no directive in this case (page in mobile view not a modal)
      this.policyId = this.urlArray[3];
    }
  }

  initForm() {
    this.createPolicyForm = this.formBuilder.group(
      {
        policyName: ["", [Validators.required]],
        fairClass: ["ECONOMY"],
        maxInterval: ["MONTHLY"],
        flightPolicyLimit: [""],
        flightPolicyLimitCurrency: [""],
        policyLimit: [""],
        policyLimitCurrency: [""],
        maximumNumberOfTrips: [""],
        hotelAllowedToggle: [true],
        flightAllowedToggle: [true],
      },
      {}
    );
  }

  getData() {
    if (this.policyId) {
      this.dataService.getPolicyDetails(this.policyId).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.policyName = this.data.data.policy.name;
            this.policyInfo = this.data.data.policy;
            this.policyInfo.flightBookingNotAllowed =
              this.data.data.policy.flightBookingNotAllowed;
            this.policyInfo.hotelBookingNotAllowed =
              this.data.data.policy.hotelBookingNotAllowed;
            if (this.policyInfo.flightBookingNotAllowed === true) {
              this.createPolicyForm.get("flightAllowedToggle").setValue(false);
            } else {
              this.createPolicyForm.get("flightAllowedToggle").setValue(true);
            }
            if (this.policyInfo.hotelBookingNotAllowed === true) {
              this.createPolicyForm.get("hotelAllowedToggle").setValue(false);
            } else {
              this.createPolicyForm.get("hotelAllowedToggle").setValue(true);
            }
            this.createPolicyForm
              .get("policyName")
              .setValue(this.data.data.policy.name);
            this.createPolicyForm
              .get("policyLimit")
              .setValue(
                this.data.data.policy.companyPolicyHotelRules
                  .avgNightlyPriceLimit
              );
            this.createPolicyForm
              .get("policyLimitCurrency")
              .setValue(
                this.data.data.policy.companyPolicyHotelRules
                  .avgNightlyPriceLimitCurrency
              );
            if (
              this.data.data.policy.companyPolicyFlightRules
                .domesticPriceLimitPerPassenger ===
                this.data.data.policy.companyPolicyFlightRules
                  .internationalPriceLimitPerPassenger &&
              this.data.data.policy.companyPolicyFlightRules
                .domesticPriceLimitPerPassengerCurrency ===
                this.data.data.policy.companyPolicyFlightRules
                  .internationalPriceLimitPerPassengerCurrency
            ) {
              this.priceCap = false;
              this.createPolicyForm
                .get("flightPolicyLimit")
                .setValue(
                  this.data.data.policy.companyPolicyFlightRules
                    .internationalPriceLimitPerPassenger
                );
              this.createPolicyForm
                .get("flightPolicyLimitCurrency")
                .setValue(
                  this.data.data.policy.companyPolicyFlightRules
                    .internationalPriceLimitPerPassengerCurrency
                );
            } else {
              this.priceCap = true;
              this.createPolicyForm.removeControl("flightPolicyLimit");
              this.createPolicyForm.removeControl("flightPolicyLimitCurrency");
              this.createPolicyForm.addControl(
                "internationalPriceCap",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger,
                  Validators.required
                )
              );
              this.createPolicyForm.addControl(
                "domesticPriceCap",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger,
                  Validators.required
                )
              );
              this.createPolicyForm.addControl(
                "internationalPriceCapCurrency",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency,
                  Validators.required
                )
              );
              this.createPolicyForm.addControl(
                "domesticPriceCapCurrency",
                new FormControl(
                  this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency,
                  Validators.required
                )
              );
            }
            this.selectedId = this.data.data.policy.approvalProcessType;
            this.selectedFairClass =
              this.data.data.policy.companyPolicyFlightRules.maximumCabinClass;
            if (
              this.data.data.policy.companyPolicyFlightRules
                .maximumNumberOfTrips
            ) {
              this.showIntervalList = true;
              this.selectedMaxInterval =
                this.data.data.policy.companyPolicyFlightRules.maximumNumberOfTripsInterval;
              this.createPolicyForm
                .get("maximumNumberOfTrips")
                .setValue(
                  this.data.data.policy.companyPolicyFlightRules
                    .maximumNumberOfTrips
                );
            }
            this.data.data.policy.companyPolicyHotelRules.exceptions.forEach(
              (element, index) => {
                this.createPolicyForm.addControl(
                  "cityLimit" + index,
                  new FormControl(
                    element.avgNightlyPriceLimit,
                    Validators.required
                  )
                );
                this.createPolicyForm.addControl(
                  "cityLimitCurrency" + index,
                  new FormControl(
                    element.avgNightlyPriceLimitCurrency,
                    Validators.required
                  )
                );
                this.citiesList.push({
                  caption: element.caption,
                  code: element.code,
                  type: element.type,
                });
              }
            );
          } else {
            this.dataService.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
        }
      );
    }
  }

  initSearchQuery() {
    this.SearchTextQuery.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.autoCompleteList();
    });
  }

  requireApprovalType(name) {
    this.selectedId = name;
    if (this.policyId) {
      this.editPolicy("approvals", null, null);
    }
  }

  get f() {
    return this.createPolicyForm.controls;
  }

  setSelectedMaxInterval() {
    this.selectedMaxInterval = this.createPolicyForm.get("maxInterval").value;
    if (this.policyId) {
      this.editPolicy("maxInterval", null, null);
    }
  }

  setSelectedFairClass() {
    this.selectedFairClass = this.createPolicyForm.get("fairClass").value;
    if (this.policyId) {
      this.editPolicy("fairClass", null, null);
    }
  }

  showNewFields() {
    this.priceCap = !this.priceCap;
    if (this.policyId) {
      if (this.priceCap) {
        this.createPolicyForm.removeControl("flightPolicyLimit");
        this.createPolicyForm.removeControl("flightPolicyLimitCurrency");
        if (
          this.data.data.policy.companyPolicyFlightRules
            .domesticPriceLimitPerPassenger ===
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        ) {
          this.createPolicyForm.addControl(
            "internationalPriceCap",
            new FormControl("", Validators.required)
          );
          this.createPolicyForm.addControl(
            "domesticPriceCap",
            new FormControl("", Validators.required)
          );
          this.createPolicyForm.addControl(
            "internationalPriceCapCurrency",
            new FormControl("", Validators.required)
          );
          this.createPolicyForm.addControl(
            "domesticPriceCapCurrency",
            new FormControl("", Validators.required)
          );
          this.editInternationalLimit = true;
          this.editDomesticLimit = true;
        } else {
          this.createPolicyForm.addControl(
            "internationalPriceCap",
            new FormControl(
              this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger,
              Validators.required
            )
          );
          this.createPolicyForm.addControl(
            "domesticPriceCap",
            new FormControl(
              this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger,
              Validators.required
            )
          );
          this.createPolicyForm.addControl(
            "internationalPriceCapCurrency",
            new FormControl(
              this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency,
              Validators.required
            )
          );
          this.createPolicyForm.addControl(
            "domesticPriceCapCurrency",
            new FormControl(
              this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency,
              Validators.required
            )
          );
        }
      } else {
        this.createPolicyForm.removeControl("internationalPriceCap");
        this.createPolicyForm.removeControl("domesticPriceCap");
        this.createPolicyForm.removeControl("internationalPriceCapCurrency");
        this.createPolicyForm.removeControl("domesticPriceCapCurrency");
        if (
          this.data.data.policy.companyPolicyFlightRules
            .domesticPriceLimitPerPassenger ===
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        ) {
          this.createPolicyForm.addControl(
            "flightPolicyLimit",
            new FormControl(
              this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger,
              Validators.required
            )
          );
          this.createPolicyForm.addControl(
            "flightPolicyLimitCurrency",
            new FormControl(
              this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency,
              Validators.required
            )
          );
        } else {
          this.createPolicyForm.addControl(
            "flightPolicyLimit",
            new FormControl("", Validators.required)
          );
          this.createPolicyForm.addControl(
            "flightPolicyLimitCurrency",
            new FormControl("", Validators.required)
          );
          this.editFlightLimit = true;
          this.editFlightLimitCurrency = true;
        }
      }
    } else {
      if (this.priceCap) {
        this.createPolicyForm.removeControl("flightPolicyLimit");
        this.createPolicyForm.removeControl("flightPolicyLimitCurrency");
        this.createPolicyForm.addControl(
          "internationalPriceCap",
          new FormControl("", Validators.required)
        );
        this.createPolicyForm.addControl(
          "domesticPriceCap",
          new FormControl("", Validators.required)
        );
        this.createPolicyForm.addControl(
          "internationalPriceCapCurrency",
          new FormControl("", Validators.required)
        );
        this.createPolicyForm.addControl(
          "domesticPriceCapCurrency",
          new FormControl("", Validators.required)
        );
      } else {
        this.createPolicyForm.removeControl("internationalPriceCap");
        this.createPolicyForm.removeControl("domesticPriceCap");
        this.createPolicyForm.removeControl("internationalPriceCapCurrency");
        this.createPolicyForm.removeControl("domesticPriceCapCurrency");
        this.createPolicyForm.addControl(
          "flightPolicyLimit",
          new FormControl("", Validators.required)
        );
        this.createPolicyForm.addControl(
          "flightPolicyLimitCurrency",
          new FormControl("", Validators.required)
        );
      }
    }
  }

  cancelCreate() {
    if (
      this.dataService.desktopView &&
      !this.dataService.createEditPolicyPage
    ) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace("/create", "")]);
    }
  }

  createPolicy() {
    this.submitted = true;
    this.submitting = true;
    if (this.createPolicyForm.invalid) {
      this.submitting = false;
      this.dataService.scrollTop();
      return;
    }
    this.policyObj.name = this.createPolicyForm.controls.policyName.value;
    this.policyObj.hotelBookingNotAllowed =
      !this.createPolicyForm.controls.hotelAllowedToggle.value;
    this.policyObj.flightBookingNotAllowed =
      !this.createPolicyForm.controls.flightAllowedToggle.value;
    this.policyObj.approvalProcessType = this.selectedId;
    this.policyObj.companyId = this.auth.companyInfo.id;
    this.policyObj.companyPolicyFlightRules.maximumCabinClass =
      this.selectedFairClass;
    if (this.createPolicyForm.controls.flightPolicyLimit) {
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
        this.createPolicyForm.controls.flightPolicyLimit.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
        this.createPolicyForm.controls.flightPolicyLimit.value;
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
    } else {
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
        this.createPolicyForm.controls.domesticPriceCap.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
        this.createPolicyForm.controls.internationalPriceCap.value;
      this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.domesticPriceCapCurrency.value;
      this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
        this.createPolicyForm.controls.internationalPriceCapCurrency.value;
    }
    this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimit =
      this.createPolicyForm.controls.policyLimit.value;
    this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimitCurrency =
      this.createPolicyForm.controls.policyLimitCurrency.value;
    this.policyObj.companyPolicyHotelRules.exceptions = [];
    this.citiesList.forEach((element, index) => {
      const obj = {
        avgNightlyPriceLimit:
          this.createPolicyForm.controls["cityLimit" + index].value,
        avgNightlyPriceLimitCurrency:
          this.createPolicyForm.controls["cityLimitCurrency" + index].value,
        caption: element.caption,
        code: element.code,
        type: element.type,
      };
      this.policyObj.companyPolicyHotelRules.exceptions.push(obj);
    });
    if (this.createPolicyForm.get("maximumNumberOfTrips").value) {
      this.policyObj.companyPolicyFlightRules["maximumNumberOfTrips"] =
        this.createPolicyForm.get("maximumNumberOfTrips").value;
      this.policyObj.companyPolicyFlightRules["maximumNumberOfTripsInterval"] =
        this.selectedMaxInterval;
    }
    this.dataService.createPolicy(this.policyObj).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.cancelCreate();
        } else {
          this.submitting = false;
          this.errorMessage = this.data.message;
        }
      },
      (error) => {
        this.submitting = false;
        this.errorMessage = environment.messages.error;
      }
    );
  }

  mobileFocus() {
    if (this.showPolicyLimitPopUpFlag || this.showPolicyNamePopUpFlag) {
      setTimeout(() => {
        if (this.showPolicyLimitPopUpFlag) {
          this.focusInputMobile = document.getElementById(
            "limitInput"
          ) as HTMLInputElement;
        }
        if (this.showPolicyNamePopUpFlag) {
          this.focusInputMobile = document.getElementById(
            "nameInput"
          ) as HTMLInputElement;
        }
        this.focusInputMobile.focus();
        this.focusInputMobile.select();
      }, 200);
    }
  }

  hidePopUp() {
    this.cityLimitMobileErrorMsg = "";
    this.cityLimitCurrencyMobileErrorMsg = "";
    if (
      this.showCityPolicyLimitPopUpFlag[this.selectedCityIndex] &&
      !this.isValid("cityLimit" + this.selectedCityIndex)
    ) {
      this.cityLimitMobileErrorMsg = "Limit is required";
      return;
    } else if (
      this.showCityPolicyLimitPopUpFlag[this.selectedCityIndex] &&
      !this.isValid("cityLimitCurrency" + this.selectedCityIndex)
    ) {
      this.cityLimitCurrencyMobileErrorMsg = "Currency is required";
      return;
    } else {
      this.showPolicyNamePopUpFlag = false;
      this.showPolicyLimitPopUpFlag = false;
      this.showMaxNoOfTripsPopUpFlag = false;
      this.showSearchPopUpautocompleteFlag = false;
      this.showFlightPriceCapFlag = false;
      this.showInternationalPriceCapFlag = false;
      this.showDomesticPriceCapFlag = false;
      this.showCityPolicyLimitPopUpFlag[this.selectedCityIndex] = false;
      this.dataService.enableBodyScroll();
    }
  }

  showPolicyNamePopUp() {
    this.showPolicyNamePopUpFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm
      .get("policyName")
      .setValue(this.data.data.policy.name);
    setTimeout(() => {
      const input = document.getElementById("nameInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  showDomesticPolicyLimitPopUp() {
    this.showDomesticPriceCapFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm.removeControl("flightPolicyLimit");
    this.createPolicyForm.removeControl("flightPolicyLimitCurrency");
    if (this.createPolicyForm.get("domesticPriceCap")) {
      this.createPolicyForm
        .get("domesticPriceCap")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .domesticPriceLimitPerPassenger
        );
      this.createPolicyForm
        .get("domesticPriceCapCurrency")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .domesticPriceLimitPerPassengerCurrency
        );
    } else {
      this.createPolicyForm.addControl(
        "domesticPriceCap",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassenger,
          Validators.required
        )
      );
      this.createPolicyForm.addControl(
        "domesticPriceCapCurrency",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency,
          Validators.required
        )
      );
    }
    setTimeout(() => {
      const input = document.getElementById(
        "domesticPriceCap"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  showInternationalPolicyLimitPopUp() {
    this.showInternationalPriceCapFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm.removeControl("flightPolicyLimit");
    this.createPolicyForm.removeControl("flightPolicyLimitCurrency");
    if (this.createPolicyForm.get("internationalPriceCap")) {
      this.createPolicyForm
        .get("internationalPriceCap")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        );
      this.createPolicyForm
        .get("internationalPriceCapCurrency")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        );
    } else {
      this.createPolicyForm.addControl(
        "internationalPriceCap",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger,
          Validators.required
        )
      );
      this.createPolicyForm.addControl(
        "internationalPriceCapCurrency",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency,
          Validators.required
        )
      );
    }
    setTimeout(() => {
      const input = document.getElementById(
        "internationalPriceCap"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  showFlightPolicyLimitPopUp() {
    this.showFlightPriceCapFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm.removeControl("internationalPriceCap");
    this.createPolicyForm.removeControl("domesticPriceCap");
    this.createPolicyForm.removeControl("internationalPriceCapCurrency");
    this.createPolicyForm.removeControl("domesticPriceCapCurrency");
    if (this.createPolicyForm.get("flightPolicyLimit")) {
      this.createPolicyForm
        .get("flightPolicyLimit")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        );
      this.createPolicyForm
        .get("flightPolicyLimitCurrency")
        .setValue(
          this.data.data.policy.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        );
    } else {
      this.createPolicyForm.addControl(
        "flightPolicyLimit",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassenger,
          Validators.required
        )
      );
      this.createPolicyForm.addControl(
        "flightPolicyLimitCurrency",
        new FormControl(
          this.data.data.policy.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency,
          Validators.required
        )
      );
    }
    setTimeout(() => {
      const input = document.getElementById(
        "flightPolicyLimit"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  showPolicyLimitPopUp() {
    this.showPolicyLimitPopUpFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm
      .get("policyLimit")
      .setValue(
        this.data.data.policy.companyPolicyHotelRules.avgNightlyPriceLimit
      );
    this.createPolicyForm
      .get("policyLimitCurrency")
      .setValue(
        this.data.data.policy.companyPolicyHotelRules
          .avgNightlyPriceLimitCurrency
      );
    setTimeout(() => {
      const input = document.getElementById("limitInput") as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  showMaxNoOfTripsPopUp() {
    this.showMaxNoOfTripsPopUpFlag = true;
    this.dataService.disableBodyScroll();
    this.createPolicyForm
      .get("maximumNumberOfTrips")
      .setValue(
        this.data.data.policy.companyPolicyFlightRules.maximumNumberOfTrips
      );
    setTimeout(() => {
      const input = document.getElementById(
        "maximumNumberOfTrips"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  restorePolicy(fieldName, fieldIndex) {
    if (this.policyId) {
      if (fieldName === "name") {
        if (
          this.createPolicyForm.controls.policyName.value ===
          this.policyInfo.name
        ) {
        }
      }
      if (fieldName === "hotelLimit") {
        if (
          !this.policyInfo.companyPolicyHotelRules.exceptions ||
          !this.policyInfo.companyPolicyHotelRules.exceptions.length
        ) {
          this.emptyCityField();
        } else if (
          this.policyInfo.companyPolicyHotelRules.exceptions &&
          this.policyInfo.companyPolicyHotelRules.exceptions.length &&
          (!this.createPolicyForm.get("policyLimit").value ||
            !this.createPolicyForm.get("policyLimitCurrency").value)
        ) {
          this.createPolicyForm
            .get("policyLimit")
            .setValidators([Validators.required]);
          this.createPolicyForm.get("policyLimit").updateValueAndValidity();
          this.createPolicyForm
            .get("policyLimitCurrency")
            .setValidators([Validators.required]);
          this.createPolicyForm
            .get("policyLimitCurrency")
            .updateValueAndValidity();
          this.defaultRequired = true;
          this.editPolicyLimit = true;
          return;
        }
        if (
          this.createPolicyForm.controls.policyLimit.value ===
            this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimit &&
          this.createPolicyForm.controls.policyLimitCurrency.value ===
            this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimitCurrency
        ) {
          this.editPolicyLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "fairClass") {
        if (
          this.createPolicyForm.controls.fairClass.value ===
          this.policyInfo.companyPolicyFlightRules.maximumCabinClass
        ) {
        }
      }
      if (fieldName === "maxInterval") {
        this.createPolicyForm
          .get("maxInterval")
          .setValue(
            this.policyInfo.companyPolicyFlightRules
              .maximumNumberOfTripsInterval
          );
      }
      if (fieldName === "maximumNumberOfTrips") {
        this.createPolicyForm
          .get("maximumNumberOfTrips")
          .setValue(
            this.policyInfo.companyPolicyFlightRules.maximumNumberOfTrips
          );
      }
      if (fieldName === "flightLimit") {
        if (
          this.createPolicyForm.controls.flightPolicyLimit.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        ) {
        }
      }
      if (fieldName === "flightLimitCurrency") {
        if (
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        ) {
        }
      }
      if (fieldName === "internationalLimit") {
        if (
          this.createPolicyForm.controls.internationalPriceCap.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassenger
        ) {
        }
      }
      if (fieldName === "domesticLimit") {
        if (
          this.createPolicyForm.controls.domesticPriceCap.value ===
          this.policyInfo.companyPolicyFlightRules
            .domesticPriceLimitPerPassenger
        ) {
        }
      }
      if (fieldName === "internationalLimitCurrency") {
        if (
          this.createPolicyForm.controls.internationalPriceCapCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        ) {
        }
      }
      if (fieldName === "domesticLimitCurrency") {
        if (
          this.createPolicyForm.controls.domesticPriceCapCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .domesticPriceLimitPerPassengerCurrency
        ) {
        }
      }
      if (
        fieldName === "cityLimit" &&
        this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
      ) {
        if (
          this.createPolicyForm.controls["cityLimit" + fieldIndex].value ===
          this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
            .avgNightlyPriceLimit
        ) {
        }
      }
    }
  }

  removeMaxNoOfTrips() {
    this.showIntervalList = false;
    this.createPolicyForm.get("maximumNumberOfTrips").setValue(null);
    const input = document.getElementById(
      "maximumNumberOfTrips"
    ) as HTMLInputElement;
    if (input) {
      input.blur();
    }
    this.editPolicy("maximumNumberOfTrips", null, null);
  }

  editPolicy(fieldName, fieldIndex, removeItem) {
    if (this.policyId) {
      if (fieldName === "name") {
        if (
          this.createPolicyForm.controls.policyName.value ===
          this.policyInfo.name
        ) {
          this.editPolicyName = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "hotelLimit") {
        if (
          this.createPolicyForm.controls.policyLimit.value ===
            this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimit &&
          this.createPolicyForm.controls.policyLimitCurrency.value ===
            this.policyInfo.companyPolicyHotelRules.avgNightlyPriceLimitCurrency
        ) {
          this.editPolicyLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "fairClass") {
        if (
          this.createPolicyForm.controls.fairClass.value ===
          this.policyInfo.companyPolicyFlightRules.maximumCabinClass
        ) {
          this.editFairClass = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "maxInterval") {
        if (
          this.createPolicyForm.controls.maxInterval.value ===
          this.policyInfo.companyPolicyFlightRules.maximumNumberOfTripsInterval
        ) {
          this.editMaxInterval = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "flightLimit") {
        if (
          this.createPolicyForm.controls.flightPolicyLimit.value ===
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassenger &&
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value ===
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassengerCurrency
        ) {
          this.editFlightLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "flightLimitCurrency") {
        if (
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        ) {
          this.editFlightLimitCurrency = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "maximumNumberOfTrips") {
        if (
          this.createPolicyForm.controls.maximumNumberOfTrips.value ===
          this.policyInfo.companyPolicyFlightRules.maximumNumberOfTrips
        ) {
          this.editMaxNoOfTrips = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "internationalLimit") {
        if (
          this.createPolicyForm.controls.internationalPriceCap.value ===
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassenger &&
          this.createPolicyForm.controls.internationalPriceCapCurrency.value ===
            this.policyInfo.companyPolicyFlightRules
              .internationalPriceLimitPerPassengerCurrency
        ) {
          this.editInternationalLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "internationalLimitCurrency") {
        if (
          this.createPolicyForm.controls.internationalPriceCapCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .internationalPriceLimitPerPassengerCurrency
        ) {
          this.editInternationalLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "domesticLimit") {
        if (
          this.createPolicyForm.controls.domesticPriceCap.value ===
            this.policyInfo.companyPolicyFlightRules
              .domesticPriceLimitPerPassenger &&
          this.createPolicyForm.controls.domesticPriceCapCurrency.value ===
            this.policyInfo.companyPolicyFlightRules
              .domesticPriceLimitPerPassengerCurrency
        ) {
          this.editDomesticLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (fieldName === "domesticLimitCurrency") {
        if (
          this.createPolicyForm.controls.domesticPriceCapCurrency.value ===
          this.policyInfo.companyPolicyFlightRules
            .domesticPriceLimitPerPassengerCurrency
        ) {
          this.editDomesticLimit = false;
          this.hidePopUp();
          return;
        }
      }
      if (
        removeItem === false &&
        this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
      ) {
        if (
          this.createPolicyForm.controls["cityLimit" + fieldIndex].value ===
            this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
              .avgNightlyPriceLimit &&
          this.createPolicyForm.controls["cityLimitCurrency" + fieldIndex]
            .value ===
            this.policyInfo.companyPolicyHotelRules.exceptions[fieldIndex]
              .avgNightlyPriceLimitCurrency
        ) {
          this.editCitiesLimit[fieldIndex] = false;
          this.hidePopUp();
          return;
        }
      }
      this.submitted = true;
      this.errorMessage = "";
      this.submitting = true;
      if (this.createPolicyForm.invalid) {
        this.submitting = false;
        this.emptyCityField();
        return;
      }
      this.defaultRequired = false;
      if (fieldName === "name") {
        this.nameLoader = true;
      }
      if (fieldName === "hotelLimit") {
        this.limitLoader = true;
      }
      if (fieldName === "fairClass") {
        this.fairClassLoader = true;
      }
      if (fieldName === "flightLimit") {
        this.priceCapLoader = true;
      }
      if (fieldName === "flightLimitCurrency") {
        this.priceCapLoader = true;
      }
      if (fieldName === "internationalLimit") {
        this.internationalPriceCapLoader = true;
      }
      if (fieldName === "internationalLimitCurrency") {
        this.internationalPriceCapLoader = true;
      }
      if (fieldName === "domesticLimit") {
        this.domesticPriceCapLoader = true;
      }
      if (fieldName === "domesticLimitCurrency") {
        this.domesticPriceCapLoader = true;
      }
      if (fieldName === "cityLimit") {
        this.cityLoaders[fieldIndex] = true;
      }
      if (fieldName === "approvals") {
        this.checkboxLoader = true;
      }
      if (fieldName === "maximumNumberOfTrips") {
        this.tripsMaxNoLoader = true;
      }
      if (fieldName === "maxInterval") {
        this.maxIntervalLoader = true;
      }
      if (fieldName === "hotelAllowedToggle") {
        this.hotelToggleLoader = true;
      }
      if (fieldName === "flightAllowedToggle") {
        this.flightToggleLoader = true;
      }
      this.policyObj.name = this.createPolicyForm.controls.policyName.value;
      this.policyObj.hotelBookingNotAllowed =
        !this.createPolicyForm.controls.hotelAllowedToggle.value;
      this.policyObj.flightBookingNotAllowed =
        !this.createPolicyForm.controls.flightAllowedToggle.value;
      this.policyObj.approvalProcessType = this.selectedId;
      this.policyObj.companyId = this.auth.companyInfo.id;
      this.policyObj.companyPolicyFlightRules.maximumCabinClass =
        this.selectedFairClass;
      if (this.createPolicyForm.controls.flightPolicyLimit) {
        this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
          this.createPolicyForm.controls.flightPolicyLimit.value;
        this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
          this.createPolicyForm.controls.flightPolicyLimit.value;
        this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
        this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
          this.createPolicyForm.controls.flightPolicyLimitCurrency.value;
      } else {
        if (this.createPolicyForm.controls.domesticPriceCap) {
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
            this.createPolicyForm.controls.domesticPriceCap.value;
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
            this.createPolicyForm.controls.domesticPriceCapCurrency.value;
        } else {
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassenger =
            this.policyInfo.companyPolicyFlightRules.domesticPriceLimitPerPassenger;
          this.policyObj.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency =
            this.policyInfo.companyPolicyFlightRules.domesticPriceLimitPerPassengerCurrency;
        }
        if (this.createPolicyForm.controls.internationalPriceCap) {
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
            this.createPolicyForm.controls.internationalPriceCap.value;
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
            this.createPolicyForm.controls.internationalPriceCapCurrency.value;
        } else {
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassenger =
            this.policyInfo.companyPolicyFlightRules.internationalPriceLimitPerPassenger;
          this.policyObj.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency =
            this.policyInfo.companyPolicyFlightRules.internationalPriceLimitPerPassengerCurrency;
        }
      }
      this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimit =
        this.createPolicyForm.controls.policyLimit.value;
      this.policyObj.companyPolicyHotelRules.avgNightlyPriceLimitCurrency =
        this.createPolicyForm.controls.policyLimitCurrency.value;
      this.policyObj.companyPolicyHotelRules.exceptions = [];
      this.citiesList.forEach((element, index) => {
        const obj = {
          avgNightlyPriceLimit:
            this.createPolicyForm.controls["cityLimit" + index].value,
          avgNightlyPriceLimitCurrency:
            this.createPolicyForm.controls["cityLimitCurrency" + index].value,
          caption: element.caption,
          code: element.code,
          type: element.type,
        };
        this.policyObj.companyPolicyHotelRules.exceptions.push(obj);
      });
      if (removeItem === true) {
        this.policyObj.companyPolicyHotelRules.exceptions.splice(fieldIndex, 1);
      }
      if (this.createPolicyForm.get("maximumNumberOfTrips").value) {
        this.policyObj.companyPolicyFlightRules["maximumNumberOfTrips"] =
          this.createPolicyForm.get("maximumNumberOfTrips").value;
        this.policyObj.companyPolicyFlightRules[
          "maximumNumberOfTripsInterval"
        ] = this.selectedMaxInterval;
      } else {
        delete this.policyObj.companyPolicyFlightRules["maximumNumberOfTrips"];
        delete this.policyObj.companyPolicyFlightRules[
          "maximumNumberOfTripsInterval"
        ];
      }
      Object.assign(this.policyObj, { policyId: this.policyId });
      this.dataService.editPolicy(this.policyObj).subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitted = false;
            if (removeItem === true) {
              this.createPolicyForm.removeControl("cityLimit" + fieldIndex);
              this.createPolicyForm.removeControl(
                "cityLimitCurrency" + fieldIndex
              );
              this.citiesList.splice(fieldIndex, 1);
              this.cityLoaders.splice(fieldIndex, 1);
              this.editCitiesLimit.splice(fieldIndex, 1);
            }
            this.dataService.getPolicyDetails(this.policyId).subscribe(
              (dataDetails) => {
                this.data = dataDetails;
                if (this.data.errorCode === 0) {
                  this.nameLoader = false;
                  this.limitLoader = false;
                  this.fairClassLoader = false;
                  this.priceCapLoader = false;
                  this.internationalPriceCapLoader = false;
                  this.domesticPriceCapLoader = false;
                  this.cityLoaders[fieldIndex] = false;
                  this.checkboxLoader = false;
                  this.tripsMaxNoLoader = false;
                  this.maxIntervalLoader = false;
                  this.flightToggleLoader = false;
                  this.hotelToggleLoader = false;
                  this.policyInfo = this.data.data.policy;
                  if (fieldName === "maximumNumberOfTrips") {
                    if (
                      this.policyInfo.companyPolicyFlightRules
                        .maximumNumberOfTrips
                    ) {
                      this.showIntervalList = true;
                    } else {
                      this.showIntervalList = false;
                    }
                  }
                  if (this.dataService.desktopView) {
                    setTimeout(() => {
                      if (fieldName === "name") {
                        const input = document.getElementById(
                          "nameInputPolicy"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "hotelLimit") {
                        const input = document.getElementById(
                          "limitInputPolicy"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "fairClass") {
                        const input = document.getElementById(
                          "fairClass"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "maxInterval") {
                        const input = document.getElementById(
                          "maxInterval"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "maximumNumberOfTrips") {
                        const input = document.getElementById(
                          "maximumNumberOfTrips"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "flightLimit") {
                        const input = document.getElementById(
                          "flightPolicyLimit"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "flightLimitCurrecny") {
                        const input = document.getElementById(
                          "flightPolicyLimitCurrency"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "internationalLimit") {
                        const input = document.getElementById(
                          "internationalPriceCap"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "internationalLimitCurrency") {
                        const input = document.getElementById(
                          "internationalPriceCapCurrency"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "domesticLimit") {
                        const input = document.getElementById(
                          "domesticPriceCap"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "domesticLimitCurrency") {
                        const input = document.getElementById(
                          "domesticPriceCapCurrency"
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (fieldName === "cityLimit" && removeItem === false) {
                        const input = document.getElementById(
                          "place-" + fieldIndex
                        ) as HTMLInputElement;
                        input.blur();
                      }
                      if (
                        fieldName === "cityLimitCurrency" &&
                        removeItem === false
                      ) {
                        const input = document.getElementById(
                          "placeCurrency-" + fieldIndex
                        ) as HTMLInputElement;
                        input.blur();
                      }
                    }, 200);
                  }
                  this.hidePopUp();
                } else {
                  this.nameLoader = false;
                  this.limitLoader = false;
                  this.fairClassLoader = false;
                  this.priceCapLoader = false;
                  this.internationalPriceCapLoader = false;
                  this.domesticPriceCapLoader = false;
                  this.cityLoaders[fieldIndex] = false;
                  this.checkboxLoader = false;
                  this.tripsMaxNoLoader = false;
                  this.maxIntervalLoader = false;
                  this.flightToggleLoader = false;
                  this.hotelToggleLoader = false;
                  this.dataService.messages(this.data.message, "error");
                }
              },
              (error) => {
                this.nameLoader = false;
                this.limitLoader = false;
                this.fairClassLoader = false;
                this.priceCapLoader = false;
                this.internationalPriceCapLoader = false;
                this.domesticPriceCapLoader = false;
                this.cityLoaders[fieldIndex] = false;
                this.checkboxLoader = false;
                this.tripsMaxNoLoader = false;
                this.maxIntervalLoader = false;
                this.flightToggleLoader = false;
                this.hotelToggleLoader = false;
                this.dataService.messages(environment.messages.error, "error");
              }
            );
            this.editMaxInterval = false;
            this.editMaxNoOfTrips = false;
            this.editPolicyName = false;
            this.editFairClass = false;
            this.editDomesticLimit = false;
            this.editInternationalLimit = false;
            this.editFlightLimit = false;
            this.editFlightLimitCurrency = false;
            this.editPolicyLimit = false;
            this.editCitiesLimit[fieldIndex] = false;
            this.showPolicyNamePopUpFlag = false;
            this.showPolicyLimitPopUpFlag = false;
            this.showMaxNoOfTripsPopUpFlag = false;
            this.showCityPolicyLimitPopUpFlag[fieldIndex] = false;
            this.showDomesticPriceCapFlag = false;
            this.showInternationalPriceCapFlag = false;
            this.showFlightPriceCapFlag = false;
          } else {
            this.tripsMaxNoLoader = false;
            this.maxIntervalLoader = false;
            this.flightToggleLoader = false;
            this.hotelToggleLoader = false;
            this.nameLoader = false;
            this.limitLoader = false;
            this.fairClassLoader = false;
            this.priceCapLoader = false;
            this.internationalPriceCapLoader = false;
            this.domesticPriceCapLoader = false;
            this.cityLoaders[fieldIndex] = false;
            this.checkboxLoader = false;
            this.errorMessage = this.data.message;
            this.editPolicyName = true;
            this.editFairClass = true;
            this.editDomesticLimit = true;
            this.editInternationalLimit = true;
            this.editFlightLimit = true;
            this.editFlightLimitCurrency = true;
            this.editPolicyLimit = true;
            this.editCitiesLimit[fieldIndex] = true;
          }
        },
        (error) => {
          this.tripsMaxNoLoader = false;
          this.maxIntervalLoader = false;
          this.flightToggleLoader = false;
          this.hotelToggleLoader = false;
          this.nameLoader = false;
          this.limitLoader = false;
          this.fairClassLoader = false;
          this.priceCapLoader = false;
          this.internationalPriceCapLoader = false;
          this.domesticPriceCapLoader = false;
          this.cityLoaders[fieldIndex] = false;
          this.checkboxLoader = false;
          this.errorMessage = environment.messages.error;
          this.editPolicyName = true;
          this.editFairClass = true;
          this.editDomesticLimit = true;
          this.editInternationalLimit = true;
          this.editFlightLimit = true;
          this.editFlightLimitCurrency = true;
          this.editPolicyLimit = true;
          this.editCitiesLimit[fieldIndex] = true;
        }
      );
    }
  }

  enterActionFunc(fieldName, index, removeItem) {
    if (this.policyId) {
      this.editPolicy(fieldName, index, removeItem);
    } else {
      this.createPolicy();
    }
  }

  showCityPolicyLimitPopUp(index) {
    this.selectedCityIndex = index;
    this.showCityPolicyLimitPopUpFlag[index] = true;
    this.dataService.disableBodyScroll();
    if (this.policyInfo.companyPolicyHotelRules.exceptions[index]) {
      this.createPolicyForm.controls["cityLimit" + index].setValue(
        this.policyInfo.companyPolicyHotelRules.exceptions[index]
          .avgNightlyPriceLimit
      );
      this.createPolicyForm.controls["cityLimitCurrency" + index].setValue(
        this.policyInfo.companyPolicyHotelRules.exceptions[index]
          .avgNightlyPriceLimitCurrency
      );
    }
    setTimeout(() => {
      const input = document.getElementById(
        "place-" + index
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }

  setSearchText(entity, dist) {
    this.citiesList.push(entity);
    this.createPolicyForm.addControl(
      "cityLimit" + (this.citiesList.length - 1),
      new FormControl("", Validators.required)
    );
    this.createPolicyForm.addControl(
      "cityLimitCurrency" + (this.citiesList.length - 1),
      new FormControl("", Validators.required)
    );
    this.editCitiesLimit[this.citiesList.length - 1] = true;
    this.showSearchPopUpautocompleteFlag = false;
    if (this.dataService.mobileView && this.policyId) {
      this.showCityPolicyLimitPopUp(this.citiesList.length - 1);
    }

    this.showDropdown = false;
    setTimeout(() => {
      this.autocompletelist = [];
      this.Query = "";
      this.caption = "";
    }, 500);
  }

  autoCompleteList() {
    if (this.Query && this.Query.length > 2) {
      this.dataService
        .autoComplete(this.Query, sessionStorage.getItem("session-token"))
        .subscribe((success) => {
          this.data = success;
          this.autocompletelist = this.data.data;
        });
    }
  }

  autocompleteOnFocus(event) {
    this.autocompletelist = [];
    event.target.select();
    this.showDropdown = true;
  }

  onChangeSearch() {
    this.showDropdown = true;
  }

  removeCity(index) {
    if (this.policyId) {
      if (
        index >
        this.policyInfo.companyPolicyHotelRules.exceptions.length - 1
      ) {
        this.citiesList.splice(index, 1);
        this.createPolicyForm.removeControl("cityLimit" + index);
        this.createPolicyForm.removeControl("cityLimitCurrency" + index);
      }
      this.editPolicy("cityLimit", index, true);
    } else {
      if (index >= 0) {
        this.citiesList.splice(index, 1);
        this.createPolicyForm.removeControl("cityLimit" + index);
        this.createPolicyForm.removeControl("cityLimitCurrency" + index);
      }
    }
  }

  isValid(fieldName) {
    return this.createPolicyForm.controls[fieldName].valid;
  }

  checkCont1() {
    if (
      !this.priceCap &&
      this.f.flightPolicyLimit &&
      !this.f.flightPolicyLimit.value
    ) {
      this.f.flightPolicyLimit.touched;
      this.f.flightPolicyLimit.dirty;
      this.submitted = true;
      this.f.flightPolicyLimit.setErrors(Validators.required)
      return;
    }
    if (
      !this.priceCap &&
      this.f.flightPolicyLimitCurrency &&
      !this.f.flightPolicyLimitCurrency.value
    ) {
      this.f.flightPolicyLimitCurrency.touched;
      this.f.flightPolicyLimitCurrency.dirty;
      this.submitted = true;
      this.f.flightPolicyLimitCurrency.setErrors(Validators.required)
      return;
    }
    if (
      this.priceCap &&
      ((this.f.internationalPriceCap && !this.f.internationalPriceCap.value) ||
        (this.f.domesticPriceCap && !this.f.domesticPriceCap.value))
    ) {
      this.f.internationalPriceCap.touched;
      this.f.internationalPriceCap.dirty;
      this.f.domesticPriceCap.touched;
      this.f.domesticPriceCap.dirty;
      this.submitted = true;
      this.f.internationalPriceCap.setErrors(Validators.required)
      this.f.domesticPriceCap.setErrors(Validators.required)
      return;
    }
    if (
      this.priceCap &&
      ((this.f.internationalPriceCapCurrency &&
        !this.f.internationalPriceCapCurrency.value) ||
        (this.f.domesticPriceCapCurrency &&
          !this.f.domesticPriceCapCurrency.value))
    ) {
      this.f.internationalPriceCapCurrency.touched;
      this.f.internationalPriceCapCurrency.dirty;
      this.f.domesticPriceCapCurrency.touched;
      this.f.domesticPriceCapCurrency.dirty;
      this.submitted = true;
      this.f.domesticPriceCapCurrency.setErrors(Validators.required)
      this.f.internationalPriceCapCurrency.setErrors(Validators.required)
      return;
    }
    this.stepCounter = 2;
  }

  checkCont2() {
    this.cityLimitMobileErrorMsg = "";
    if (
      (this.f.policyLimit &&
        !this.f.policyLimit.value) ||
      (this.f.policyLimitCurrency &&
        !this.f.policyLimitCurrency.value)
    ) {
      this.f.policyLimit.touched;
      this.f.policyLimit.dirty;
      this.f.policyLimitCurrency.touched;
      this.f.policyLimitCurrency.dirty;
      this.submitted = true;
      this.f.policyLimit.setErrors(Validators.required);
      this.f.policyLimitCurrency.setErrors(Validators.required);
      return;
    }
    this.citiesList.forEach((item, index) => {
      if(!this.createPolicyForm.controls["cityLimit" + index].value) {
        this.submitted = true;
        this.createPolicyForm.controls["cityLimit" + index].setErrors(Validators.required);
        throw new Error("Stopping the function!");
      }
      if(!this.createPolicyForm.controls["cityLimitCurrency" + index].value) {
        this.submitted = true;
        this.createPolicyForm.controls["cityLimitCurrency" + index].setErrors(Validators.required);
        throw new Error("Stopping the function!");
      }
    });
    this.stepCounter = 3;
  }

  checkCityLimit(gotToStep) {
    this.cityLimitMobileErrorMsg = "";
    const invalidArray = [];
    this.citiesList.forEach((item, index) => {
      invalidArray.push(
        this.createPolicyForm.controls["cityLimit" + index].value ? false : true
      );
      invalidArray.push(
        this.createPolicyForm.controls["cityLimitCurrency" + index].value ? false : true
      );
    });
    if (invalidArray.includes(true)) {
      this.cityLimitMobileErrorMsg = "Limit is required";
      return;
    } else {
      this.stepCounter = gotToStep;
    }
  }

  showMobileAutoCompletePopUp() {
    this.showSearchPopUpautocompleteFlag = true;
    setTimeout(() => {
      const input = document.getElementById(
        "city-mobile-input"
      ) as HTMLInputElement;
      if (input) {
        input.focus();
        input.select();
        input.click();
      }
    }, 200);
  }

  emptyCityField() {
    const input = document.getElementById("list") as HTMLInputElement;
    setTimeout(() => {
      if (input) {
        input.value = "";
        this.caption = "";
        this.showAutoCompleteField = false;
      }
    }, 200);
    if (
      (this.policyId &&
        (!this.policyInfo.companyPolicyHotelRules.exceptions ||
          !this.policyInfo.companyPolicyHotelRules.exceptions.length)) ||
      (!this.policyId &&
        (!this.policyObj.companyPolicyHotelRules.exceptions ||
          !this.policyObj.companyPolicyHotelRules.exceptions.length))
    ) {
      this.createPolicyForm.get("policyLimit").setValidators(null);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.createPolicyForm.get("policyLimitCurrency").setValidators(null);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.defaultRequired = true;
    }
  }

  notSelected(caption) {
    let notSelected = true;
    if (this.policyInfo && this.policyInfo.companyPolicyHotelRules) {
      this.policyInfo.companyPolicyHotelRules.exceptions.forEach((element) => {
        if (element.caption === caption) {
          notSelected = false;
        }
      });
    }
    return notSelected;
  }

  addRequiredToDefaultValue() {
    if (this.createPolicyForm.get("policyLimit").value) {
      if (this.dataService.desktopView) {
        this.showAutoCompleteField = true;
        setTimeout(() => {
          const input = document.getElementById("list") as HTMLInputElement;
          if (input) {
            input.focus();
          }
        }, 200);
      } else {
        this.showSearchPopUpautocompleteFlag = true;
      }
    } else {
      this.createPolicyForm
        .get("policyLimit")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimit").updateValueAndValidity();
      this.createPolicyForm
        .get("policyLimitCurrency")
        .setValidators([Validators.required]);
      this.createPolicyForm.get("policyLimitCurrency").updateValueAndValidity();
      this.defaultRequired = true;
      this.editPolicyLimit = true;
      this.emptyCityField();
    }
  }
}
