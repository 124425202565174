import {Component, ElementRef, OnInit} from '@angular/core';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {DataService} from '../../../../services/data.service';
import {SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {Router} from '@angular/router';
import {VariablesService} from '../../../../services/vars.service';
import {environment} from "../../../../../environments/environment";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-user-payments-list',
  templateUrl: './user-payments-list.component.html',
  styleUrls: ['./user-payments-list.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class UserPaymentsListComponent implements OnInit {

  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('Payment methods list | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }

  data;
  dataPayment;
  paymentMethods;
  paymentObject: {};
  showActions = false;
  selectedIndex;

  ngOnInit() {
    this.dataService.listPaymentService();
  }

  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target) ){
      this.showActions = false;
    }
  }

  NewPaymentCard(){
    if(this.dataService.desktopView){
      this.dataService.setModal( $localize`:@@addNewCard:Add new card`, 'NewPaymentCard', '')
    } else {
      this.dataService.setBottomSheet('NewPaymentCard', '')
    }
  }

  openDeleteDialogue(paymentId) {
    if(this.dataService.desktopView){
      this.dataService.setModal( $localize`:@@deleteUserCard:Warning, you're deleting payment method!`, 'deletePayment', paymentId);
    } else {
      this.dataService.setBottomSheet('removeCompanyPayment', paymentId);
    }
  }

  makePrimary(paymentId, channelProviderVal, channelTypeVal) {
    this.dataService.alerts = [];
    this.paymentObject = {
      paymentGatewayProvider: channelProviderVal,
      channelType: channelTypeVal,
      id: paymentId,
      primary: true
    };
    this.dataService.updatePayment(this.paymentObject).subscribe(
      data => {
        this.dataPayment = data;
        if (this.dataPayment.errorCode === 0) {
          this.dataService.listPaymentService();
          this.dataService.scrollTop();
        } else {
          this.dataService.messages(this.dataPayment.message, 'error');
          this.dataService.scrollTop();
        }
      },
      error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.dataService.scrollTop();
      }
    );
  }

  editAlias(idValue, gatewayValue, oldAliasValue, primaryValue) {
    this.dataService.userPaymentObjForEdit = {
      id: idValue,
      paymentGatewayProvider: gatewayValue,
      oldAlias: oldAliasValue,
      primary: primaryValue};
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@editCard:Edit Card`, 'editUserCard', '');
    } else {
      this.dataService.setBottomSheet('editUserCard', '');
    }
  }

  removePayment(paymentId) {
      this.dataService.setBottomSheet('removeCompanyPayment', paymentId);
  }
}
