import { Component, Input, OnInit } from '@angular/core';
import { CostManagementService } from 'src/app/services/cost-management.service';
import { DataService } from 'src/app/services/data.service';
import { CostManagementStateService } from 'src/app/services/states/cost-management-state.service';
import { CompanyArchiveStatus } from 'src/app/shared/enums/company-archive-status';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cost-activation',
  templateUrl: './cost-activation.component.html'
})
export class CostActivationComponent implements OnInit {
  @Input() id: string;
  @Input() status: string;
  @Input() isCenter: boolean;
  @Input() isArchivingDenied: boolean;
  companyArchiveStatus = CompanyArchiveStatus;
  loader: boolean = false;
  hasError: boolean = false;

  constructor(public costState: CostManagementStateService,
    public dataService: DataService,
    public costManagementService: CostManagementService) { }

  ngOnInit(): void { }

  changeActivation() {
    this.loader = true;

    if (this.isArchivingDenied) {
      const errorMsg = this.isCenter ? 
      'Cost Centers are mandatory; at least one center must be active.':
      'Labels are mandatory; at least one label must be active.';
      this.handleFailed(errorMsg);
      return;
    }

    const service = this.isCenter ? this.costManagementService.setCostCenterStatus(this.id, this.status) :
      this.costManagementService.setLabelStatus(this.id, this.status);

    service.subscribe(
      result => this.handleSuccess(result),
      error => this.handleFailed(error.message)
    );
  }

  handleSuccess(result) {
    if (result.errorCode) {
      this.handleFailed(result.message);
      return;
    }

    if (this.isCenter) {
      this.costState.modifyCostCenters(result.data);
    } else {
      this.costState.modifyLabels(result.data);
    }

    this.loader = false;
    this.cancel();
  }

  handleFailed(error) {
    if (error.includes('Http failure response')) {
      error = environment.messages.error;
    }

    this.dataService.messages(error ?? environment.messages.error, 'error');
    this.loader = false;
    this.hasError = true;
    this.cancel();
  }

  cancel() {
    if (this.dataService.desktopView) {
      this.dataService.modalOf();
      if (this.isCenter || this.hasError) {
        this.dataService.scrollTop();
      }
    }
    if (this.dataService.mobileView) {
      this.dataService.BottomSheetOf();
    }
  }
}
