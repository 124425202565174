<div class="main-bottom-sheet bg-white animate-C" [ngStyle]="{'direction' : Service.siteDirection.toLowerCase()}"
  [ngClass]="{'active': Service.BottomSheetVisible}">
  <p class="border-bottom text-center mb-0 py-3 font-15 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'NewPaymentCard'" i18n="@@addNewCard">Add New Card</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'removeTeam'" i18n="@@removeTeam">Delete Team</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'removeMember'" i18n="@@removeMember">Remove Member</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'removePolicy'" i18n="@@removePolicy">Delete Policy</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'removeCompanyPayment'" i18n="@@deleteCard">Delete Card</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'editAlias'" i18n="@@editCardAlias">Edit Card Alias</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'tripApproval'" i18n="@@tripApproval">Trip Approval</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'changePrice'" i18n="@@warningPriceChanged">Warning, Price changed</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'tripDisapproval'" i18n="@@declineTrip">Decline trip</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'editBilling'" i18n="@@editBillingCard">Edit Billing Card</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'editUserCard'" i18n="@@editCard">Edit Card</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'editBillingPlan'" i18n="@@billingPlans">Billing Plans</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'deleteSubscription'" i18n="@@deleteSubscription">Delete Subscription</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'switchPlan'" i18n="@@switchToPlanBilling">Switch to
    {{this.Service.workspacePlan}} Billing</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'ForgotPassword'" i18n="@@ForgotPassword">Forgot Password</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'userReactivationFees'" i18n="@@reactivateUser">User Reactivation Fees</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'reactivateAccount'" i18n="@@activateMySubscription">Activate My
    Subscription</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'companyHotelShare'" i18n="@@shareHotel">Share a hotel</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'companySendBookingVoucher' || this.Service.BottomSheetName === 'SendBookingVoucher'"
    i18n="@@shareVoucher">Email to another address</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'removeProfileImage'" i18n="@@removeProfileImage">Remove Profile Image</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'uploadExpenses'" i18n="@@uploadExpenses">Upload Expenses</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'withdrawApprovalRequest' || this.Service.BottomSheetName === 'withdrawApprovalRequestFlight'"
    i18n="@@withdrawApprovalRequest">Withdraw Approval Request</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'addAdmin'" i18n="@@setAsAdmin">Set as admin</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'removeAdmin'" i18n="@@removeAdmin">Remove admin</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'deleteRewardsProgram'" i18n="@@deleteRewardsProg">Delete Rewards Program
  </p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'bothApprovalDisapproval'" i18n="@@tripApproval">Trip Approval</p>

  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'travelDocument'" i18n="@@travelDoc">Travel Document</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'flyerProgram'" i18n="@@flyerProgramOne">Frequent Flyer Program</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'memberInfo'" i18n="@@memberInfo">Member info</p>

  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'deleteDoc'" i18n="@@removeTravelDoc">Remove Travel Document</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'deleteProgram'" i18n="@@removeFlyerProg">Remove Flyer Program</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'requestDemo'" i18n="@@landingRequestBtn">Request Demo</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'cancelInvitation'" i18n="@@withdrawInviteTitle">Withdraw Invitation</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'memberPassword'" i18n="@@changePassword">Change Password</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'editCompanyInfo'" i18n="@@companyInfo">Company Info</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'editCompanyMainAddress'" i18n="@@officeAddress">Office address</p>
  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'addCompanyOtherAddress'" i18n="@@officeBranchesAdd">Add office branch</p>

  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold px-3"
    *ngIf="this.Service.BottomSheetName === 'editCompanyOtherAddress'" i18n="@@officeBranches">Office branches</p>

  <p class="border-bottom text-center mb-0 py-3 font-20 primaray-text font-weight-bold"
    *ngIf="this.Service.BottomSheetName === 'costActivation'" i18n="@@costActivation">Cost Activation</p>


  <app-account-update *ngIf="this.Service.BottomSheetName === 'editCompanyInfo' ||
  this.Service.BottomSheetName === 'editCompanyMainAddress' || this.Service.BottomSheetName === 'editCompanyOtherAddress' ||
  this.Service.BottomSheetName === 'addCompanyOtherAddress'"></app-account-update>

  <app-user-password *ngIf="this.Service.BottomSheetName === 'memberPassword'"></app-user-password>

  <app-request-demo *ngIf="this.Service.BottomSheetName === 'requestDemo'"></app-request-demo>

  <app-remove-program-or-passport
    *ngIf="this.Service.BottomSheetName === 'deleteDoc' || this.Service.BottomSheetName === 'deleteProgram'"></app-remove-program-or-passport>
  <app-add-edit-travel-document
    *ngIf="this.Service.BottomSheetName === 'travelDocument'"></app-add-edit-travel-document>
  <app-add-edit-flyer-program *ngIf="this.Service.BottomSheetName === 'flyerProgram'"></app-add-edit-flyer-program>
  <app-update-user-info *ngIf="this.Service.BottomSheetName === 'memberInfo'"></app-update-user-info>

  <app-workspace-remove-rewards
    *ngIf="this.Service.BottomSheetName === 'deleteRewardsProgram'"></app-workspace-remove-rewards>
  <app-upload-expenses *ngIf="this.Service.BottomSheetName === 'uploadExpenses'"></app-upload-expenses>
  <app-user-profile-image *ngIf="this.Service.BottomSheetName === 'profileImageMobileMenu'"></app-user-profile-image>
  <app-savings-calculator *ngIf="this.Service.BottomSheetName === 'corporateCalculateSavings'"></app-savings-calculator>
  <app-company-share-hotel *ngIf="this.Service.BottomSheetName === 'companyHotelShare' ||
this.Service.BottomSheetName === 'companySendBookingVoucher'"></app-company-share-hotel>
<app-update-user-preferences *ngIf="this.Service.BottomSheetName === 'memberPreferences'"></app-update-user-preferences>
<app-account-preferences-update *ngIf="this.Service.BottomSheetName === 'companyPreferences'"></app-account-preferences-update>

  <app-workspace-reactivate-account
    *ngIf="this.Service.BottomSheetName === 'reactivateAccount'"></app-workspace-reactivate-account>
  <app-forgot-password *ngIf="this.Service.BottomSheetName === 'ForgotPassword'"></app-forgot-password>
  <app-billing-plans *ngIf="this.Service.BottomSheetName === 'editBillingPlan'"></app-billing-plans>
  <app-switch-plan *ngIf="this.Service.BottomSheetName === 'switchPlan'"></app-switch-plan>
  <app-edit-user-payment *ngIf="this.Service.BottomSheetName === 'editUserCard'"></app-edit-user-payment>
  <app-user-reactivation-fees
    *ngIf="this.Service.BottomSheetName === 'userReactivationFees'"></app-user-reactivation-fees>
  <app-update-billing-card *ngIf="this.Service.BottomSheetName === 'editBilling'"></app-update-billing-card>
  <div [ngClass]="{'white-box': this.Service.BottomSheetName !== 'corporateCalculateSavings'}">



    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'deleteAddress'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16" i18n="@@AreSureDeleteAddress">Are you sure you want to delete
          {{Service.BottomSheetData.name}}? This action cannot be undone!
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="Service.deleteOfficeAddress(Service.BottomSheetData);" i18n="@@deleteBtn">Delete</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>



    <app-edit-payment-alias *ngIf="this.Service.BottomSheetName === 'editAlias'"></app-edit-payment-alias>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'cancelInvitation'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16"><ng-container i18n="@@withdrawInvite1">Are you sure you want to
            withdraw</ng-container> <span class="font-weight-bold">{{Service.BottomSheetData}}</span>
          <ng-container i18n="@@withdrawInvite2">invitation?</ng-container>
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="this.Service.cancelInvitation(Service.BottomSheetData)" i18n="@@withdrawInvite">Withdraw
          Invitation</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>
    <app-new-payment-card *ngIf="this.Service.BottomSheetName === 'NewPaymentCard'"></app-new-payment-card>
    <app-approve-request
      *ngIf="this.Service.BottomSheetName === 'tripApproval' || this.Service.BottomSheetName === 'changePrice'"
      approvalId="{{this.Service.BottomSheetData.approvalId}}"
      ignoreRatePriceChange="{{this.Service.BottomSheetData.ignoreRatePriceChange}}"
      averageNightlySellingPrice="{{this.Service.BottomSheetData.averageNightlySellingPrice}}"
      pageType="{{this.Service.BottomSheetData.pageType}}"
      isFlight="{{this.Service.BottomSheetData.isFlight}}"></app-approve-request>
    <app-disapprove-request *ngIf="this.Service.BottomSheetName === 'tripDisapproval'"
      approvalId="{{this.Service.BottomSheetData.approvalId}}"
      requesterName="{{this.Service.BottomSheetData.requesterName}}"
      commentAndReason="{{this.Service.BottomSheetData.commentAndReason}}"
      pageType="{{this.Service.BottomSheetData.pageType}}"
      isFlight="{{this.Service.BottomSheetData.isFlight}}"></app-disapprove-request>
    <app-costs-manage *ngIf="Service.BottomSheetName === 'manageCosts'" [id]="Service.BottomSheetData.id"
      [name]="Service.BottomSheetData.name" [isCenter]="Service.BottomSheetData.isCenter"
      [description]="Service.BottomSheetData.description">
    </app-costs-manage>
    <app-cost-activation *ngIf="Service.BottomSheetName === 'costActivation'" [id]="Service.BottomSheetData.id"
      [status]="Service.BottomSheetData.status" [isCenter]="Service.BottomSheetData.isCenter"
      [isArchivingDenied]="Service.BottomSheetData.isArchivingDenied">
    </app-cost-activation>
    <app-remove-profile-image *ngIf="this.Service.BottomSheetName === 'removeProfileImage'"></app-remove-profile-image>
    <div *ngIf="this.Service.BottomSheetName === 'bothApprovalDisapproval'">
      <button class=" mt-5 btn-block blue_btn sharp-font bold-500 font-14  px-4 text-center"
        (click)="this.Service.setBottomSheet('tripApproval', Service.BottomSheetData.approvalObj);" i18n="@@approve">
        Approve
      </button>
      <button class=" mt-5 btn-block btn btn-outline-blue sharp-font bold-500 font-14 px-4 text-center"
        (click)="Service.requestMoreInfoMobile(Service.BottomSheetData.approvalId, Service.BottomSheetData.commentValue)"
        i18n="@@requestMoreInfo">
        Request more info
      </button>
      <button class=" mt-5 mb-2 btn-block btn btn-outline-red sharp-font bold-500 font-14 px-4 text-center"
        [ngClass]="{'m-progress': Service.submitting }"
        (click)="this.Service.setBottomSheet('tripDisapproval', Service.BottomSheetData.disapprovalObj);"
        i18n="@@decline">
        Decline
      </button>
      <button class=" mt-5 mb-2 btn-block btn btn-outline-red sharp-font bold-500 font-14 px-4 text-center"
        [ngClass]="{'m-progress': Service.loader }" (click)="Service.setBottomSheet('manageCosts', '');">
        Manage Cost
      </button>
      <button class=" mt-5 btn-block blue_btn sharp-font bold-500 font-14  px-4 text-center"
        (click)="this.Service.setBottomSheet('CostActivation', Service.BottomSheetData.approvalObj);" i18n="@@approve">
        CostActivation
      </button>
      <p class="text-danger" *ngIf="Service.errorMsg">{{Service.errorMsg}}</p>

    </div>

    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'removeAdmin'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16">Are you sure you want remove the user <span
            class="font-weight-bold">{{this.Service.BottomSheetData.userName}}</span> as admin?</p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="this.Service.setUserAsAdmin(this.Service.BottomSheetData.userId, false);">Remove admin</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()">Cancel </button>
      </div>
      <p class="text-danger mt-3">{{this.Service.errorMsg}}</p>
    </div>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'addAdmin'">
      <div class="d-flex mb-4">
        <p class="gray-text-dark font-16">Are you sure you want set the user <span
            class="font-weight-bold">{{this.Service.BottomSheetData.userName}}</span> as admin?</p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn blue_btn font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="this.Service.setUserAsAdmin(this.Service.BottomSheetData.userId, true);">Set as admin</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()">Cancel </button>
      </div>
      <p class="text-danger mt-3">{{this.Service.errorMsg}}</p>
    </div>


    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'withdrawApprovalRequest' || this.Service.BottomSheetName === 'withdrawApprovalRequestFlight'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16" i18n="@@withdrawMsg">Are you sure you want to withdraw your request? This
          action cannot be undone!</p>
      </div>
      <div class="d-flex justify-content-center">
        <button *ngIf="this.Service.BottomSheetName === 'withdrawApprovalRequest'"
          class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="this.Service.withdrawApprovalRequestFunc(this.Service.BottomSheetData); "
          i18n="@@withdraw">Withdraw</button>
        <button *ngIf="this.Service.BottomSheetName === 'withdrawApprovalRequestFlight'"
          class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="this.Service.withdrawFlightApprovalRequestFunc(this.Service.BottomSheetData); "
          i18n="@@withdraw">Withdraw</button>

      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
      <p class="text-danger mt-3">{{this.Service.errorMsg}}</p>
    </div>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'deleteSubscription'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16">Are you sure you want to delete your subscription? This action cannot be
          undone!</p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }" (click)="this.Service.deleteSubscription(); ">Delete</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'removeCompanyPayment'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16" i18n="@@deletePay">Are you sure you want to delete payment method? This action
          cannot be undone!</p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          [ngClass]="{'m-progress': Service.submitting }"
          (click)="this.Service.deleteCompanyPayment(this.Service.BottomSheetData);" i18n="@@deleteCard">Delete
          Card</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'removeTeam'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg3 pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16">
          <ng-container i18n="@@removeTeam3">By deleting</ng-container>
          <span> {{this.Service.BottomSheetData.teamNameValue}} </span>
          <ng-container i18n="@@removeTeam5">team, members will not be assigned to any travel policy.</ng-container>
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          (click)="this.Service.removeTeam(this.Service.BottomSheetData.teamIdValue); this.Service.BottomSheetOf();"
          i18n="@@deleteBtn">Delete</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'removeMember'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16"><ng-container i18n="@@removeMemberMsg1">Are you sure you want to
            remove</ng-container>&nbsp;<span
            class="font-weight-bold">{{this.Service.BottomSheetData.memberDescription}}</span>
          &nbsp;<ng-container i18n="@@fromTeam">from</ng-container>&nbsp;<span
            class="font-weight-bold">{{this.Service.BottomSheetData.teamName}}</span>&nbsp;<ng-container
            i18n="@@removeMemberMsg2">team? By removing the member, all booking approval requests will be sent to the
            owner.</ng-container></p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          (click)="this.Service.removeMemberFromTeam(this.Service.BottomSheetData.teamId, this.Service.BottomSheetData.memberId); this.Service.BottomSheetOf();"
          i18n="@@removeMemberWithThe">Remove Member</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>
    <div class="mt-4" [ngClass]="{'text-left': Service.isSiteLtr(), 'text-right': Service.isSiteRtl()}"
      *ngIf="this.Service.BottomSheetName === 'removePolicy'">
      <div class="d-flex mb-4">
        <i class="icon-warning-msg pt-1" [ngClass]="{'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl()}"></i>
        <p class="gray-text-dark font-16">
          <ng-container i18n="@@removePolicy3">By deleting</ng-container>
          <span> {{this.Service.BottomSheetData.policyName}} </span>
          <ng-container i18n="@@removePolicyMsg3">policy, members linked to this policy will not have any travel
            restrictions.</ng-container>
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5 mb-3"
          (click)="this.Service.removePolicy(this.Service.BottomSheetData.policyId); this.Service.BottomSheetOf();"
          i18n="@@deleteBtn">Delete</button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="w-100 btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
          (click)="this.Service.BottomSheetOf()" i18n="@@cancelBtn">Cancel </button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="this.Service.BottomSheetOn" (click)="this.Service.BottomSheetOf()" class="modal-backdrop show"></div>