import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class PaymentMethodsListComponent implements OnInit {

  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
               public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Account payment methods | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  showActions = false;
  selectedIndex;

  ngOnInit(): void {
    if (this.auth.isLogedIn && !this.auth.companyInfo) {
      this.auth.fetchCompanyData();
    }
    this.dataService.companyListPaymentService();
  }

  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target) ){
      this.showActions = false;
    }
  }

  removePayment(paymentId) {
      if (this.dataService.desktopView) {
        this.dataService.setModal($localize`:@@deleteUserCard:Warning, you're deleting payment method!`, 'removeCompanyPayment', paymentId);
      } else {
        this.dataService.setBottomSheet('removeCompanyPayment', paymentId);
      }
  }

  addNewPayment(){
    if(this.dataService.desktopView){
      this.dataService.setModal($localize`:@@addNewCard:Add new card`, 'NewPaymentCard', '');
    } else {
      this.dataService.setBottomSheet('NewPaymentCard', '');
    }
  }

  editAlias(id, gateway, oldAlias) {
    this.dataService.companyPaymentIdForAlias = id;
    this.dataService.companyPaymentGatewayForAlias = gateway;
    this.dataService.companyPaymentOldAlias = oldAlias;
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@editCardAlias:Edit Card Alias`, 'editAlias', '');
    } else {
      this.dataService.setBottomSheet('editAlias', '');
    }
  }
}
