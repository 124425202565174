<header
  [ngClass]="{
    'header-z-index': this.screenSize.desktopView,
    'landing-page-header':
      this.screenSize.desktopView && (router.url === '/' || router.url === '/'),
    'workspace-header-color':
      this.screenSize.desktopView &&
      !router.url.includes('login') &&
      !router.url.includes('code') &&
      !router.url.includes('join') &&
      !router.url.includes('onboarding') &&
      !router.url.includes('upload') &&
      router.url !== '/slack'
  }"
>
  <div
    id="header"
    [ngClass]="{
      'bg-white':
        router.url === '/info/contact-us' || router.url === '/info/about'
    }"
  >
    <div
      class="container"
      [ngClass]="{
        'fixed-page-container':
          router.url.includes('join') ||
          router.url.includes('login') ||
          router.url.includes('reset') ||
          router.url.includes('/slack')
      }"
      *ngIf="!(router.url === '/' && screenSize.mobileView)"
    >
      <div
        class="row align-items-center content-row header-row"
        [ngClass]="{
          'fixed-logo':
            router.url.includes('join') ||
            router.url.includes('login') ||
            router.url.includes('reset') ||
            router.url.includes('/slack')
        }"
      >
        <div
          class="col-auto logo-col"
          *ngIf="!router.url.includes('/availability/booking')"
        >
          <a
            href="https://safarhub.com/"
            class="logo_link"
            *ngIf="!this.logedIn.isLogedIn"
          >
            <img
              width="145"
              height="35"
              loading="lazy"
              src="assets/img/logo.svg"
              class="logo-image initial-height"
              [ngClass]="{ 'max-width': this.screenSize.mobileView }"
              alt="SafarHub Logo"
            />
          </a>
          <a
            (click)="this.router.navigate(['/' + this.logedIn.companyInfo.id])"
            class="logo_link"
            *ngIf="this.logedIn.isLogedIn"
          >
            <img
              width="145"
              height="35"
              loading="lazy"
              src="assets/img/logo.svg"
              class="logo-image initial-height"
              [ngClass]="{ 'max-width': this.screenSize.mobileView }"
              alt="SafarHub Logo"
            />
          </a>
        </div>
        <div
          class="col-auto logo-col"
          *ngIf="
            this.screenSize.mobileView &&
            router.url.includes('/availability/booking')
          "
        >
          <img
            width="145"
            height="35"
            loading="lazy"
            src="assets/img/logo.svg"
            class="logo-image initial-height"
            [ngClass]="{ 'max-width': this.screenSize.mobileView }"
            (click)="opendropDowmBookingForm()"
            alt="SafarHub Logo"
          />
        </div>
        <div
          *ngIf="!env.production"
          class="col-auto py-3 px-2 font-weight-bold"
          [ngClass]="{
            'position-absolute lang-selector': screenSize.desktopView,
            'ml-auto': screenSize.isSiteLtr(),
            'mr-auto': screenSize.isSiteRtl()
          }"
        >
          <span
            *ngIf="this.screenSize.corporateLocale.includes('en')"
            (click)="screenSize.changeLocale('ar')"
            [ngClass]="{ 'mx-2 pr-2': !this.logedIn.isLogedIn }"
            class="pointer gray-text-dark font-14"
            >عربي</span
          >
          <span
            *ngIf="this.screenSize.corporateLocale.includes('ar')"
            (click)="screenSize.changeLocale('en-US')"
            [ngClass]="{ 'mx-2 pl-2': !this.logedIn.isLogedIn }"
            class="pointer gray-text-dark font-14"
            >En</span
          >
        </div>
        <div class="col-auto d-lg-none p-0" *ngIf="screenSize.mobileView">
          <i
            class="align-middle font-24 icon-workspace-mobile-menu font-weight-bold invisible"
            [ngClass]="{
              'mr-4': screenSize.isSiteLtr(),
              'ml-4': screenSize.isSiteRtl()
            }"
            *ngIf="screenSize.mobileView && router.url === '/'"
          ></i>
          <div
            class="toggle_primary_menu toggle_popup p-3 mt-2"
            (click)="openManageWorkspaceMenu(); this.Modal.disableBodyScroll()"
            *ngIf="
              this.logedIn.isLogedIn &&
              !router.url.includes('code') &&
              router.url &&
              !router.url.includes('/availability/booking') &&
              !router.url.includes('upload') &&
              !router.url.includes('onboarding')
            "
          >
            <i class="icon-menu font-weight-bold"></i>
          </div>
        </div>
        <div
          class="bookingDropLogo position-relative"
          *ngIf="
            this.screenSize.desktopView &&
            router.url.includes('/availability/booking')
          "
        >
          <div class="col-auto logo-col">
            <img
              width="145"
              height="35"
              loading="lazy"
              src="assets/img/logo.svg"
              class="logo-image initial-height"
              [ngClass]="{ 'max-width': this.screenSize.mobileView }"
              (click)="opendropDowmBookingForm()"
              alt="SafarHub Logo"
            />
          </div>
          <div
            class="dropdown-menu header-menu shadow-sm show"
            [ngClass]="{ 'text-right': screenSize.isSiteRtl() }"
            *ngIf="Modal.dropDowmBookingForm"
          >
            <div>
              <a
                (click)="CompleteYourBooking()"
                class="dropdown-item gray-text-dark font-16"
              >
                <span>
                  <i
                    class="icon-bed"
                    [ngClass]="{
                      'mr-2': screenSize.isSiteLtr(),
                      'ml-2': screenSize.isSiteRtl()
                    }"
                    *ngIf="!router.url.includes('flights')"
                  ></i
                  ><i
                    class="icon-flight-item"
                    [ngClass]="{
                      'mr-2': screenSize.isSiteLtr(),
                      'ml-2': screenSize.isSiteRtl()
                    }"
                    *ngIf="router.url.includes('flights')"
                  ></i
                  ><ng-container i18n="@@completeBooking"
                    >Complete your booking</ng-container
                  ></span
                >
              </a>
            </div>
            <div>
              <a
                (click)="this.Modal.goBackBookingForm()"
                class="dropdown-item gray-text-dark font-16"
                ><span
                  ><i
                    [ngClass]="{
                      'mr-2 icon-arrow-left2': screenSize.isSiteLtr(),
                      'ml-2 icon-arrow-right2': screenSize.isSiteRtl()
                    }"
                  ></i>
                  Select other
                  <ng-container i18n="@@selectOther">Select other</ng-container
                  >&nbsp;<span
                    *ngIf="!router.url.includes('flights')"
                    i18n="@@anotherRoom"
                  >
                    room </span
                  ><span
                    *ngIf="router.url.includes('flights')"
                    i18n="@@anotherFlight"
                  >
                    flight
                  </span>
                </span>
              </a>
            </div>

            <div>
              <a
                href="tel:8887070618"
                class="dropdown-item gray-text-dark font-16"
                ><span class="small"
                  ><i
                    class="icon-phone"
                    [ngClass]="{
                      'mr-2': screenSize.isSiteLtr(),
                      'ml-2': screenSize.isSiteRtl()
                    }"
                  ></i
                  ><ng-container i18n="@@needHelpCall"
                    >Need help? Call us at</ng-container
                  >&nbsp;<span dir="ltr">(888) 707-0618</span></span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<app-manage-workspace-popup></app-manage-workspace-popup>
