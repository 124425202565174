import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CostManagementService } from 'src/app/services/cost-management.service';
import { CompanyArchiveStatus } from 'src/app/shared/enums/company-archive-status';
import { CostCenter } from 'src/app/shared/model/cost-management/cost-center';
import { Label } from 'src/app/shared/model/cost-management/label';
import { CostManagementStateService } from 'src/app/services/states/cost-management-state.service';
import { DataService } from 'src/app/services/data.service';
import { AuthServiceMain } from 'src/app/auth/auth.service';
import { SidenavService } from 'src/app/corporate-travel/workspace-dashboard/sidenav.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cost-centers-list',
  templateUrl: './cost-centers-list.component.html',
  styleUrls: ['./cost-centers-list.component.scss'],
  host: {
    '(body:click)': 'onClickBody($event)',
  }
})
export class CostCentersListComponent implements OnInit {
  @Input() isCostCenterMandatory: boolean = false;
  @Input() isLabelMandatory: boolean = false;
  @Input() loader: boolean = false;
  showActions: boolean = false;
  selectedIndex: number;
  companyArchiveStatus = CompanyArchiveStatus;

  constructor(public eref: ElementRef, public dataService: DataService,
    public costManagementService: CostManagementService, public costState: CostManagementStateService,
    private sidenavService: SidenavService, public auth: AuthServiceMain) {
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    this.costState.setIsActiveCosts();
    this.getCosts();

    if (this.auth.companyInfo.rewardProgram) {
      this.dataService.showSliders = true;
      this.dataService.showSliders = true;
    }
    else {
      this.dataService.showSliders = false;
    }
  }

  getCosts() {
    this.loader = true;
    this.costManagementService.getCostCenters().subscribe(
      result => this.handleSuccess(result),
      error => this.handleFailed(error.message)
    ).add(() => {
      this.loader = false;
    });
  }

  handleSuccess(result, refreshData = true) {
    if (result.errorCode) {
      this.handleFailed(result.message);
      return;
    }

    if (refreshData) {
      const data = result?.data ?? [];
      this.costState.setCostCenters(data);
    }
  }

  handleFailed(error) {
    if (error.includes('Http failure response')) {
      error = environment.messages.error;
    }
    this.dataService.messages(error ?? environment.messages.error, 'error');
  }

  manageItem(item: CostCenter | Label = undefined) {
    this.dataService.headerOn = false;

    const data = {
      id: item?.id,
      name: item?.name,
      description: item?.description,
      isCenter: true
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal('', 'manageCosts', data);
    } else {
      this.dataService.setBottomSheet('manageCosts', data);
    }
  }

  changeActivation(item: any) {
    const isLastActiveCostCenter = this.costState?.costs?.filter(att => att.status === this.companyArchiveStatus.Active)?.length == 1;
    const isArchiving = item.status === this.companyArchiveStatus.Active;
    
    const data = {
      id: item.id,
      status: !isArchiving ? this.companyArchiveStatus.Active : this.companyArchiveStatus.Archived,
      isCenter: true,
      isArchivingDenied: isArchiving && isLastActiveCostCenter && this.isCostCenterMandatory
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@costActivation:Cost Activation`, 'costActivation', data);
    } else {
      this.dataService.setBottomSheet('costActivation', data);
    }
  }

  mandotaryChange(event) {
    if (!this.costState?.costs?.filter(att => att.status === this.companyArchiveStatus.Active)?.length) {
      event.source.checked = this.isCostCenterMandatory;
      this.handleFailed("To make cost centers mandatory, ensure that at least one center is active.");
      return;
    }
    this.isCostCenterMandatory = event.checked;
    this.costManagementService.setMandatory(this.isCostCenterMandatory, this.isLabelMandatory).subscribe(
      result => this.handleSuccess(result, false),
      error => this.handleFailed(error.message)
    ).add(() => {
      this.loader = false;
    });
  }

  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target)) {
      this.showActions = false;
    }
  }
}