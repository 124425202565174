import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import * as lightgallery from "../../../assets/js/lightgallery/lightgallery";
import * as thumbnail from "../../../assets/js/lightgallery/lg-thumbnail.min";
import { SwiperComponent, SwiperConfigInterface } from "ngx-swiper-wrapper";
import { AuthServiceMain } from "../../auth/auth.service";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { VariablesService } from "../../services/vars.service";

declare let window: any;

@Component({
  selector: "app-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["./hotel.component.scss"],
})
export class HotelComponent implements OnInit {
  reviewTitle =
    $localize`:@@reviewSummary:Review summary` +
    "<span>(" +
    $localize`:@@poweredByGoogle:Powered by Google` +
    ") </span>";
  showGygWidget;
  gygWidget;
  env = environment;
  hotelImages;
  selBox;
  errorData = false;
  text;
  checkHoldcount = 0;
  // isFavorite;
  selectedIndex = 1;
  showDropdown = false;
  data;
  mapNearBy = {};
  Params: any = {
    checkIn: "",
    checkOut: "",
    searchQuery: "",
    paxes: "2",
    taxBreakdown: true,
    searchCode: "",
    searchType: "",
  };
  nearByList;
  showAllRates = null;
  nuberofroom = 4;
  showCashBackDesc;
  giataId;
  policyCurrency
  tripAdviserdata;
  TripAdviserRating;
  hotelCode;
  hotel;
  PaxesArray = [];
  queryParams;
  readMore = false;
  numberOfNights;
  activelightbox = false;
  public isCollapsed = true;
  // tslint:disable-next-line:max-line-length
  activeClass;
  public config: SwiperConfigInterface = {
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 0,
    observer: true,
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    },
  };

  public facilitiesConfig: SwiperConfigInterface = {
    direction: "horizontal",
    loop: false,
    slidesPerView: "auto",
    spaceBetween: 12,
    observer: true,
    freeMode: true,
  };
  public configLb;
  public thumb: SwiperConfigInterface = {
    spaceBetween: 10,
    slidesPerView: "auto",
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    loop: true,
  };
  envMode = environment.production;
  discountMsg = environment.messages.USER_HAS_DISCOUNT;
  cashbackMsg = environment.messages.USER_HAS_CASHBACK;
  discountData;
  extraCashbackData;
  encodedName;
  encodedBody;

  perks = [
    {
      hrsCode: "2",
      isChecked: false,
      title: "Premium WIFI",
    },
    {
      hrsCode: "4",
      isChecked: false,
      title: "Free breakfast",
    },
    {
      hrsCode: "6",
      isChecked: false,
      title: "room upgrade",
    },

    {
      hrsCode: "7",
      isChecked: false,
      title: "Early check-in",
    },
    {
      hrsCode: "5",
      isChecked: false,
      title: "Free room hold",
    },
    {
      hrsCode: "3",
      isChecked: false,
      title: "Late check-out",
    },
    {
      hrsCode: "1",
      isChecked: false,
      title: "No prepayment",
    },
  ];
  tropHophotelCode;
  urlArray;
  exceptionValue;
  hasException = false;
  policyDetails;
  currency;
  companyPolicyHotelRules;
  showRewardsBox = true;
  travelerId;
  constructor(
    public Service: DataService,
    public route: ActivatedRoute,
    public logedIn: AuthServiceMain,
    public googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private titleService: Title,
    public Vars: VariablesService
  ) {
    if (!this.Service.isCompany()) {
      this.Service.componentMethodCalled$.subscribe(() => {
        if (this.Service.urlWithoutParams === "/availability/hotel") {
          this.Service.alerts = [];
          if (this.router.url.includes("availability/hotel")) {
            this.Service.hotelPage = true;
          }
          this.Service.unlockRate = true;
          let header = "";
          if (sessionStorage.getItem("session-token")) {
            header = sessionStorage.getItem("session-token");
          }
          if (
            this.queryParams.checkOut === "null" ||
            this.queryParams.checkIn === "null" ||
            moment(this.queryParams.checkIn).isBefore(moment(), "day") ||
            !moment(this.queryParams.checkIn).isValid() ||
            !moment(this.queryParams.checkOut).isValid() ||
            this.numberOfNights < 0
          ) {
            if (!this.queryParams.checkinOffset && !this.queryParams.nights) {
              return;
            }
          }

          if (
            (!this.queryParams.nights &&
              !this.queryParams.checkinOffset &&
              !this.queryParams.checkIn) ||
            this.numberOfNights === 0
          ) {
            return;
          }

          this.Service.availabilityList(this.Params, header).subscribe(
            (success) => {
              if (sessionStorage.getItem("session-token")) {
                sessionStorage.removeItem("session-token");
              }
              this.data = success;
              this.Service.hotelPage = false;
              if (this.data.errorCode === 0) {
                if (
                  this.data.data.extraData &&
                  this.data.data.extraData.userData &&
                  this.data.data.extraData.userData.policy
                ) {
                  this.companyPolicyHotelRules =
                    this.data.data.extraData.userData.policy.companyPolicyHotelRules;
                  this.policyDetails = this.data.data.extraData.userData.policy;
                  this.policyCurrency = this.policyDetails.companyPolicyHotelRules.avgNightlyPriceLimitCurrency;
                  this.policyDetails.companyPolicyHotelRules.exceptions.forEach(
                    (item) => {
                      if (item.isApplied) {
                        this.hasException = true;
                        this.exceptionValue = item.avgNightlyPriceLimit;
                      }
                    }
                  );
                }
                if (
                  !this.data.data.hotels ||
                  this.data.data.hotels.length <= 0
                ) {
                  this.errorData = true;
                  this.Service.hotelPage = false;
                  return;
                }
                this.hotel = this.data.data.hotels[0];
                this.Service.destCity = this.hotel.cityName;
                this.showGygWidget = true;
                this.Service.destCountryCode = this.hotel.countryCode;
                this.Service.destStateCode = this.hotel.stateCode;
                this.titleService.setTitle(this.hotel.name + " | SafarHub");
                this.Service.hotelCodeToShare = this.hotel.code;
                this.tropHophotelCode = this.hotel.code;
                this.giataId = this.hotel.giataId;
                this.hotelImages = this.hotel.images;
                this.currency = this.hotel.lowestSellingPriceRate.averageNightlySellingPriceConvertedCurrency;
                this.hotelImages = this.hotelImages.filter((item) => {
                  return item.url;
                });
                if (this.hotel.additionalData) {
                  if (this.hotel.additionalData) {
                    if (this.hotel.additionalData) {
                      this.Service.isFavoriteHotel = true;
                    }
                  }
                }
                this.encodedName =
                  "SafarHub, " +
                  encodeURIComponent(
                    this.hotel.name +
                      ", " +
                      this.hotel.cityName +
                      ", " +
                      this.hotel.countryName
                  );
                this.encodedBody =
                  "Checkout, " +
                  encodeURIComponent(
                    "Check this hotel on SafarHub, they offer up to 15% cash back on each booking"
                  ) +
                  "%20%3A%0D%0A" +
                  encodeURIComponent(document.location.href);
                if (!this.hotel.rooms || this.hotel.rooms.length < 0) {
                  this.Service.messages(
                    environment.messages.NO_AVAILABILITY,
                    "warning"
                  );
                } else {
                  window.dataLayer.push({
                    event: "fireRemarketing",
                    ecomm_prodid: this.hotel.giataId,
                    ecomm_pagetype: "product",
                    ecomm_totalvalue:
                      this.hotel.lowestSellingPriceRate.sellingPrice,
                    hrental_id: this.hotel.giataId,
                    hrental_pagetype: "conversionintent",
                  });
                }

                setTimeout(() => {
                  this.Service.sendCaption(
                    this.Params.searchQuery,
                    this.data.data.searchCaption,
                    this.Params.paxes,
                    this.Params.searchCode,
                    this.Params.searchType
                  );
                }, 200);
              } else {
                this.errorData = true;
                this.Service.messages(this.data.message, "warning");
              }
            },
            (error) => {
              if (sessionStorage.getItem("session-token")) {
                sessionStorage.removeItem("session-token");
              }
              this.errorData = true;
              this.Service.messages(environment.messages.error, "error");
              this.Service.hotelPage = false;
            }
          );
        }
      });
    }
  }

  @ViewChild("swiperView", { static: true }) swiperView: SwiperComponent;
  @ViewChild("thumbview", { static: true }) thumbview: SwiperComponent;

  showPolicyDetails = true;

  ngOnInit() {
    if (this.router.url.includes("travelerId=")) {
      this.travelerId = ("" + this.router.url).substring(
        ("" + this.router.url).indexOf("travelerId=") + 11
      );
    } else {
      this.travelerId = null;
    }
    this.urlArray = this.router.url.split("/");
    this.Service.alerts = [];
    this.Service.unlockRate = true;
    this.Service.bookingPage = false;
    this.Service.flightBookingPage = false;
    this.Service.bookingSubmit = false;
    this.Service.flightBookingSubmit = false;
    this.Service.bookingPage = false;
    if (this.router.url.includes("availability/hotel")) {
      this.Service.hotelPage = true;
    }
    this.Service.addNonLoggedInFavId = "";
    this.Service.ratesExpiry = false;
    this.mainFunc();
  }
  calculateNumberOfNights(checkin, checkout) {
    return moment.utc(checkout).diff(moment.utc(checkin), "days");
  }

  mainFunc() {
    this.Service.ratesExpiryDuration();
    this.route.queryParams.subscribe((params) => {
      if (this.router.url.includes("travelerId=")) {
        this.travelerId = ("" + this.router.url).substring(
          ("" + this.router.url).indexOf("travelerId=") + 11
        );
        let travelerIdKey = "travelerId";
        this.Params[travelerIdKey] = this.travelerId;
      } else {
        this.travelerId = null;
      }
      this.queryParams = params;

      if (params.checkinOffset && !params.checkIn) {
        const checkinOffset = Number(params.checkinOffset);
        this.Params.checkIn = moment()
          .add(checkinOffset, "days")
          .format("YYYY-MM-DD");
        if (!params.checkOut) {
          this.Params.checkOut = moment()
            .add(3 + checkinOffset, "days")
            .format("YYYY-MM-DD");
        }
      }
      if (params.nights && !params.checkOut) {
        const nights = Number(params.nights);
        if (params.checkIn) {
          this.Params.checkIn = moment(params.checkIn).format("YYYY-MM-DD");

          this.Params.checkOut = moment(params.checkIn)
            .add(nights, "days")
            .format("YYYY-MM-DD");
        } else {
          this.Params.checkOut = moment(this.Params.checkIn)
            .add(nights, "days")
            .format("YYYY-MM-DD");
        }
      }

      if (
        params.checkOut === "null" ||
        params.checkIn === "null" ||
        moment(params.checkIn).isBefore(moment(), "day") ||
        !moment(params.checkIn).isValid() ||
        !moment(params.checkOut).isValid() ||
        moment(params.checkOut).diff(moment(params.checkIn), "days") < 0
      ) {
        if (this.Params.checkIn === "") {
          this.Params.checkIn = moment().add(14, "days").format("YYYY-MM-DD");
        }

        if (this.Params.checkOut === "") {
          this.Params.checkOut = moment().add(17, "days").format("YYYY-MM-DD");
        }
        if (!params.checkinOffset && !params.nights) {
          this.Service.GlobelPreviousUrlFunc();
          if (this.Service.isCompany()) {
            this.router.navigate([this.urlArray[1] + "/availability/hotel"], {
              queryParams: {
                checkIn: this.Params.checkIn,
                checkOut: this.Params.checkOut,
                travelerId: this.travelerId,
              },
              queryParamsHandling: "merge",
            });
          } else {
            this.router.navigate(["availability/hotel"], {
              queryParams: {
                checkIn: this.Params.checkIn,
                checkOut: this.Params.checkOut,
              },
              queryParamsHandling: "merge",
            });
          }
          return;
        }
      } else if (
        params.checkIn !== undefined &&
        params.checkOut !== undefined &&
        params.paxes !== undefined
      ) {
        this.Params.checkOut = moment(params.checkOut).format("YYYY-MM-DD");

        this.Params.checkIn = moment(params.checkIn).format("YYYY-MM-DD");
      } else {
        if (params.checkIn === undefined && this.Params.checkIn === "") {
          this.Params.checkIn = moment().add(14, "days").format("YYYY-MM-DD");
        } else if (this.Params.checkIn === "") {
          this.Params.checkIn = moment(params.checkIn).format("YYYY-MM-DD");
        }

        if (params.checkOut === undefined && this.Params.checkOut === "") {
          this.Params.checkOut = moment().add(17, "days").format("YYYY-MM-DD");
        } else {
          this.Params.checkOut = moment(params.checkOut).format("YYYY-MM-DD");
        }
      }
      if (!params.nights && !params.checkinOffset && !params.checkIn) {
        this.Params.checkIn = moment().add(14, "days").format("YYYY-MM-DD");

        this.Params.checkOut = moment().add(17, "days").format("YYYY-MM-DD");
        this.Service.GlobelPreviousUrlFunc();
        if (this.Service.isCompany()) {
          this.router.navigate([this.urlArray[1] + "/availability/hotel"], {
            queryParams: {
              checkIn: this.Params.checkIn,
              checkOut: this.Params.checkOut,
              travelerId: this.travelerId,
            },
            queryParamsHandling: "merge",
          });
        } else {
          this.router.navigate(["availability/hotel"], {
            queryParams: {
              checkIn: this.Params.checkIn,
              checkOut: this.Params.checkOut,
            },
            queryParamsHandling: "merge",
          });
        }
        return;
      }
      this.numberOfNights = this.calculateNumberOfNights(
        this.queryParams.checkIn,
        this.queryParams.checkOut
      );
      if (this.numberOfNights === 0) {
        let hoursResult = moment(params.checkOut).diff(
          moment(params.checkIn),
          "hours"
        );
        hoursResult = hoursResult % 24;
        if (hoursResult === 0) {
          this.Params.checkOut = moment(params.checkOut)
            .add(1, "days")
            .format("YYYY-MM-DD");
          this.Service.GlobelPreviousUrlFunc();
          if (this.Service.isCompany()) {
            this.router.navigate([this.urlArray[1] + "/availability/hotel"], {
              queryParams: {
                checkIn: this.Params.checkIn,
                checkOut: this.Params.checkOut,
                travelerId: this.travelerId,
              },
              queryParamsHandling: "merge",
            });
          } else {
            this.router.navigate(["availability/hotel"], {
              queryParams: {
                checkIn: this.Params.checkIn,
                checkOut: this.Params.checkOut,
              },
              queryParamsHandling: "merge",
            });
          }
          return;
        }
      }
      const days = moment
        .utc(this.Params.checkOut)
        .diff(moment.utc(this.Params.checkIn), "hours");
      if (days <= 0) {
        this.Params.checkIn = moment().add(14, "days").format("YYYY-MM-DD");
        this.Params.checkOut = moment().add(17, "days").format("YYYY-MM-DD");
      }
      if (params.searchCode || params.id) {
        if (params.searchCode) {
          this.Params.searchCode = params.searchCode;
        } else {
          this.Params.searchCode = params.id;
        }
        this.Params.searchType = params.searchType;
        delete this.Params.searchQuery;
      } else {
        this.Params.searchQuery = params.searchQuery;
        delete this.Params.searchType;

        delete this.Params.searchCode;
      }
      if (params.paxes !== undefined) {
        this.Params.paxes = params.paxes;
      } else {
        if (params.paxes === undefined) {
          this.Params.paxes = "2";
        }
      }
      if (params.editSearchBar === "") {
        this.isCollapsed = false;
      }
      this.PaxesArray = this.Params.paxes.split(",");
      if (params.searchQuery) {
        const hotelCode = params.searchQuery.split(";");
        this.hotelCode = hotelCode[0];
      } else {
        this.hotelCode = params.id;
      }
      sessionStorage.setItem("params", JSON.stringify(this.Params));
    });

    this.configLb = {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      direction: "horizontal",
      loop: true,
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 0,
      observer: true,
    };

    let header = "";
    if (sessionStorage.getItem("session-token")) {
      header = sessionStorage.getItem("session-token");
    }

    if (
      this.queryParams.checkOut === "null" ||
      this.queryParams.checkIn === "null" ||
      moment(this.queryParams.checkIn).isBefore(moment(), "day") ||
      !moment(this.queryParams.checkIn).isValid() ||
      !moment(this.queryParams.checkOut).isValid() ||
      moment(this.queryParams.checkOut).diff(
        moment(this.queryParams.checkIn),
        "days"
      ) < 0
    ) {
      if (!this.queryParams.checkinOffset && !this.queryParams.nights) {
        return;
      }
    }

    if (
      (!this.queryParams.nights &&
        !this.queryParams.checkinOffset &&
        !this.queryParams.checkIn) ||
      this.numberOfNights === 0
    ) {
      return;
    }
    this.Service.availabilityList(this.Params, header).subscribe(
      (success) => {
        if (sessionStorage.getItem("session-token")) {
          sessionStorage.removeItem("session-token");
        }
        this.data = success;
        this.Service.hotelPage = false;
        if (this.data.errorCode === 0) {
          if (
            this.data.data.extraData &&
            this.data.data.extraData.userData &&
            this.data.data.extraData.userData.policy
          ) {
            this.Service.setApplicablePolicyDetails(
              this.data.data.extraData.userData.policy
            );
            this.companyPolicyHotelRules =
              this.data.data.extraData.userData.policy.companyPolicyHotelRules;
            this.policyDetails = this.data.data.extraData.userData.policy;
            this.policyCurrency = this.policyDetails.companyPolicyHotelRules.avgNightlyPriceLimitCurrency;
            this.policyDetails.companyPolicyHotelRules.exceptions.forEach(
              (item) => {
                if (item.isApplied) {
                  this.hasException = true;
                  this.exceptionValue = item.avgNightlyPriceLimit;
                }
              }
            );
          }
          if (!this.data.data.hotels || this.data.data.hotels.length <= 0) {
            this.errorData = true;

            this.Service.hotelPage = false;
            return;
          }
          this.hotel = this.data.data.hotels[0];
          this.currency = this.hotel.lowestSellingPriceRate.averageNightlySellingPriceConvertedCurrency;
          if (!environment.production) {
          }
          this.Service.destCity = this.hotel.cityName;
          this.showGygWidget = true;
          this.Service.destCountryCode = this.hotel.countryCode;
          this.Service.destStateCode = this.hotel.stateCode;
          this.titleService.setTitle(this.hotel.name + " | SafarHub");
          this.Service.hotelCodeToShare = this.hotel.code;
          this.tropHophotelCode = this.hotel.code;
          this.giataId = this.hotel.giataId;
          this.hotelImages = this.hotel.images;
          this.currency = this.hotel.lowestSellingPriceRate.averageNightlySellingPriceConvertedCurrency;
          this.hotelImages = this.hotelImages.filter((item) => {
            return item.url;
          });
          if (this.hotel.additionalData) {
            if (this.hotel.additionalData) {
              if (this.hotel.additionalData) {
                this.Service.isFavoriteHotel = true;
              }
            }
          }
          this.encodedName =
            "SafarHub, " +
            encodeURIComponent(
              this.hotel.name +
                ", " +
                this.hotel.cityName +
                ", " +
                this.hotel.countryName
            );
          this.encodedBody =
            "Checkout, " +
            encodeURIComponent(
              "Check this hotel on SafarHub, they offer up to 15% cash back on each booking"
            ) +
            "%20%3A%0D%0A" +
            encodeURIComponent(document.location.href);
          if (!this.hotel.rooms || this.hotel.rooms.length < 0) {
            this.Service.messages(
              environment.messages.NO_AVAILABILITY,
              "warning"
            );
          } else {
            window.dataLayer.push({
              event: "fireRemarketing",
              ecomm_prodid: this.hotel.giataId,
              ecomm_pagetype: "product",
              ecomm_totalvalue: this.hotel.lowestSellingPriceRate.sellingPrice,
              hrental_id: this.hotel.giataId,
              hrental_pagetype: "conversionintent",
            });
          }
          setTimeout(() => {
            this.Service.sendCaption(
              this.Params.searchQuery,
              this.data.data.searchCaption,
              this.Params.paxes,
              this.Params.searchCode,
              this.Params.searchType
            );
          }, 200);

        } else {
          this.errorData = true;

          this.Service.messages(this.data.message, "warning");
        }
        setTimeout(() => {
          const lightgallerys = document.getElementById("lightgallery");
          if (lightgallerys && lightgallery && thumbnail) {
            window.lightGallery(
              document
                .getElementById("lightgallery")
                .getElementsByClassName("swiper-wrapper")[0],
              {
                thumbnail: true,
              }
            );
          }
        }, 800);
      },
      (error) => {
        if (sessionStorage.getItem("session-token")) {
          sessionStorage.removeItem("session-token");
        }
        this.errorData = true;

        this.Service.messages(environment.messages.error, "error");
        this.Service.hotelPage = false;
      }
    );
  }

  getNearbyPlaces() {
    if (!this.nearByList) {
      this.Service.nearByHotel(this.hotelCode).subscribe((success) => {
        this.data = success;
        if (this.data.errorCode === 0) {
          this.nearByList = this.data.data;
        }
      });
    }
  }

  changeRateValue(e) {
    this.showAllRates = e;
  }

  showCashBackDescFun(item, data) {
    if (this.Service.mobileView) {
      this.Service.setModal("Cash Back information", "CashBackinfo", data);
    } else {
      this.showCashBackDesc = item;
    }
  }

  tripAdvisorTrigger() {
    this.googleAnalyticsService.eventEmitter(
      "Stats",
      "TripAdvisor",
      "click",
      1
    );
  }

  mapPopUp(status) {
    this.mapNearBy = {
      nearByList: this.nearByList,
      latitude: this.hotel.latitude,
      isCollapsed: status,
      longitude: this.hotel.longitude,
    };
    this.Service.setModal(this.hotel.name, "hotelMap", this.mapNearBy);
  }

  refreshPage() {
    location.reload();
  }

  copyLink() {
    this.selBox = document.createElement("textarea");
    this.text = window.location.href;
    this.selBox.style.position = "fixed";
    this.selBox.style.left = "0";
    this.selBox.style.top = "0";
    this.selBox.style.opacity = "0";
    this.selBox.value = this.text;
    document.body.appendChild(this.selBox);
    this.selBox.focus();
    this.selBox.select();
    document.execCommand("copy");
    document.body.removeChild(this.selBox);
  }

  showRoomInfo(name, modalName, data) {
    this.Service.udateslider();
    this.Service.setModal(name, modalName, data);
    if (data.description) {
      this.googleAnalyticsService.eventEmitter(
        "Stats",
        "Room-Description",
        "Successful",
        1
      );
    } else {
      this.googleAnalyticsService.eventEmitter(
        "Stats",
        "Room-Description",
        "Failed",
        1
      );
    }
  }

  hideDropDown() {
    setTimeout(() => {
      this.showDropdown = false;
    }, 300);
  }

  openHotelSharePopUp(hotel) {
    if (hotel.images.length > 0) {
      localStorage.setItem("shareHotelImage", hotel.images[0].url);
    }
    localStorage.setItem("shareHotelName", hotel.name);
    localStorage.setItem("shareHotelAddress", hotel.address);
    if (this.Service.isCompany()) {
      if (this.Service.desktopView) {
        this.Service.setModal(
          $localize`:@@shareHotel:Share a hotel`,
          "companyHotelShare",
          ""
        );
      } else {
        this.Service.setBottomSheet("companyHotelShare", "");
      }
    } else {
      this.Service.setModal(
        "Share your favorite hotels with a friend",
        "HotelShare",
        ""
      );
    }
  }

  openLg() {
    let element: HTMLElement = document.querySelector(
      "#lightgallery .swiper-slide-active"
    ) as HTMLElement;
    element.click();
  }

  onIndexChange(e) {
    this.selectedIndex = e + 1;
  }

  showPrivateDescFunClick() {
    this.Service.PromoBoxShow = true;
    this.Service.PromoshowPrivateDesc = true;
    this.Service.PromoBoxShow = true;
    this.Service.setModal("", "PromoBox", "");
    this.Service.PromoBox = true;
    if (this.Service.modalName === "PromoBox") {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("modal-promo");
    }
  }

  checkHold(value) {
    if (this.checkHoldcount === 0 && value) {
      this.googleAnalyticsService.eventEmitter("Stats", "Room-hold", "View", 1);
      this.checkHoldcount++;
    } else {
      return;
    }
    return value;
  }

  checkFacility(facilities) {
    let hasFacilities = false;
    if (facilities) {
      facilities.forEach((element) => {
        if (element.featured) {
          hasFacilities = true;
          return hasFacilities;
        }
      });
    }
    return hasFacilities;
  }
}
