import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {DataService} from "../../../services/data.service";
import {AuthServiceMain} from "../../../auth/auth.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-slack-workspace-login',
  templateUrl: './slack-workspace-login.component.html',
  styleUrls: ['./slack-workspace-login.component.scss']
})
export class SlackWorkspaceLoginComponent implements OnInit {

  constructor(private titleService: Title, public router: Router, private formBuilder: FormBuilder, public dataService: DataService,
              public auth: AuthServiceMain, public route: ActivatedRoute) {
    this.titleService.setTitle('Integrate your slack workspace | SafarHub');
    this.dataService.componentMethodCalled$.subscribe(
      () => {

      }
    );
  }

  urlArray;
  data;
  companyID;
  ngOnInit(): void {
    this.dataService.companyIdForSlackLogin = null;

 /*   this.urlArray = (this.router.url).split('/');
    this.companyID = this.urlArray[1];*/
    if (this.auth.isLogedIn) {
      const slackState = sessionStorage.getItem('state');
      this.router.navigate(['/slack/connect'], {queryParams: {state: slackState}});
    }


  }
}
