import { Component, Input, OnInit } from '@angular/core';
import { CostCenter } from 'src/app/shared/model/cost-management/cost-center';
import { Label } from 'src/app/shared/model/cost-management/label';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CostManagementService } from 'src/app/services/cost-management.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cost-reporting-details',
  templateUrl: './cost-reporting-details.component.html',
  styleUrls: ['./cost-reporting-details.component.scss']
})
export class CostReportingDetailsComponent implements OnInit {
  @Input() costCenters: CostCenter[] = [];
  @Input() labels: Label[] = [];
  @Input() costCentersIds: any[] = [];
  @Input() labelsIds: any[] = [];
  costCentersDropdownSettings: IDropdownSettings = {};
  labelsDropdownSettings: IDropdownSettings = {};
  loader: boolean = false;

  constructor(public costManagementService: CostManagementService, public dataService: DataService) { }

  ngOnInit() {
    this.prepareDropDownSettings();

    if (this.costCentersIds.length) {
      if (typeof this.costCentersIds[0] !== 'string') {
        this.costCentersIds = this.costCentersIds.map(att => att.id);
      }
      this.getCosts();
    }

    if (this.labelsIds.length) {
      if (typeof this.labelsIds[0] !== 'string') {
        this.labelsIds = this.labelsIds.map(att => att.id);
      }
      this.getLabels();
    }
  }

  getCosts() {
    this.loader = true;
    this.costManagementService.getCostCenters().subscribe(
      result => this.handleSuccess(result),
      error => this.handleFailed(error.message)
    ).add(() => {
      this.loader = false;
    });
  }

  getLabels() {
    this.loader = true;
    this.costManagementService.getLabels().subscribe(
      result => this.handleSuccess(result, false),
      error => this.handleFailed(error.message)
    ).add(() => {
      this.loader = false;
    });
  }

  handleSuccess(result, isCostCenters: boolean = true) {
    if (result.errorCode) {
      this.handleFailed(result.message);
      return;
    }

    if (isCostCenters) {
      this.costCenters = result.data.filter(att => this.costCentersIds.includes(att.id));
    } else {
      this.labels = result.data.filter(att => this.labelsIds.includes(att.id));
    }
  }

  handleFailed(error) {
    if (error.includes('Http failure response')) {
      error = environment.messages.error;
    }
    this.dataService.messages(error ?? environment.messages.error, 'error');
  }

  prepareDropDownSettings() {
    this.costCentersDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      limitSelection: 1,
      allowSearchFilter: true,
      searchPlaceholderText: $localize`:@@searchBtn:Search`
    };

    this.labelsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false,
      searchPlaceholderText: $localize`:@@searchBtn:Search`
    };
  }
}