<!--<div *ngIf="this.dataService.mobileView">

  <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="this.auth.companyInfo && dataService.companyUserInfo">
     <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
       (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/users']);">Members</a> <span class="font-18 gray-text-dark"> / </span>
    <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
       *ngIf="dataService.companyUserInfo"
       (click)=" this.router.navigate([this.router.url.replace('/edit' , '')]);">  {{dataService.companyUserInfo.fullName}}
    </a>
  </div>

</div>-->
<div class="pb-5" [ngClass]="{'container': dataService.desktopView}">
  <div [ngClass]="{'mb-5 rounded border pt-4 workspace-rounded-container pb-4': dataService.desktopView,
  'pl-4': dataService.desktopView && dataService.isSiteLtr(), 'pr-4': dataService.desktopView && dataService.isSiteRtl()}">
  <div class="d-flex pt-3" *ngIf="dataService.companyUserInfo">
    <img width="40" height="40" loading="lazy" *ngIf="dataService.companyUserInfo.profileImage"
         class="user-img d-inline-block mb-3"
         onError="this.src='assets/img/default-user.png'"    [src]="dataService.companyUserInfo.profileImage" />
    <div class="name-first-letters small" *ngIf="!dataService.companyUserInfo.profileImage" [ngStyle]="{'background-color':  dataService.companyUserInfo.profileImageBackgroundHexColor}">
      {{dataService.companyUserInfo.firstName.substring(0,1).toUpperCase()}}{{dataService.companyUserInfo.lastName.substring(0,1).toUpperCase()}}
    </div>


    <div class="mb-5" [ngClass]="{'ml-0 ml-lg-4 pl-3': dataService.isSiteLtr(), 'mr-0 mr-lg-4 pr-3': dataService.isSiteRtl()}">
      <p class="gray-primaray-text mt-2 mt-lg-0 mb-0">{{dataService.companyUserInfo.fullName}}</p>
      <p class="gray-text mb-0 dotted-text dotted-text-direction">{{dataService.companyUserInfo.email}}</p>
      <p class="gray-text mb-0"><ng-container i18n="@@joinDate">Join date</ng-container>:&nbsp;<span class="mb-0"  [innerHTML]="dataService.formatDateMMMdy(dataService.companyUserInfo.creationDate)"></span></p>
      <p class="text-danger font-16 bold-600 mb-0" *ngIf="dataService.companyUserInfo.inactive" i18n="@@inActive">Deactivated</p>
    </div>
  </div>

<form name="usersEditForm" [formGroup]="usersEditForm" >
  <div [ngClass]="{'w-75' : this.dataService.desktopView && !dataService.userUpdatePage, 'max-col-width' : this.dataService.desktopView && dataService.userUpdatePage}">

  <p class="gray-text mb-1 mt-4" i18n="@@team">Team</p>
  <mat-form-field class="w-100 chips-container rounded" [ngClass]="{'border-0': !editUserTeam, 'border' :  editUserTeam, 'realtime-loader': teamLoader  }"
                  *ngIf="dataService.desktopView" (click)="editUserTeam = true; teamInput.select()">
    <i *ngIf="!teamLoader" [ngClass]="{'gray-text': editUserTeam, 'prevent-pointer-event inactive-clear': !editUserTeam}" class="icon-cross small  pointer clear-text p-1"
       (click)= "applyBlur = false;  desktopTeamFocus()"></i>
    <input class="min-height mx-0 my-0" id="teamInputHtml" #teamInput type="text" matInput [formControl]="teamControl" [matAutocomplete]="autoTeam"
           (blur)="resetTeam();" #trigger="matAutocompleteTrigger" (keyup.enter)="onEnter(); trigger.closePanel();"
           [ngClass]="{'edit-field rounded': !editUserTeam, 'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
           (keyup.escape)="restoreSelectedUser();" (focus)="this.teamAutoComplete();">
    <mat-autocomplete #autoTeam="matAutocomplete" [displayWith]="displayFnTeam">
      <mat-option style="padding: 0" *ngFor="let team of filteredTeams | async; " [value]="team">
        <div   (click)="optionClickedTeam($event, team)" class="mat-checkbox-container">
          {{team.name}}
        </div>
      </mat-option>

        <mat-option style="padding: 0" *ngIf="dataService.desktopView && (this.auth.isAdmin() || this.auth.isOwner())">
          <div  (click)="this.dataService.setModal(this.dataService.createNewTeam, 'createNewTeam', '')" class="mat-checkbox-container">
            <p class="blue-text" [ngClass]="{'mb-0': dataService.desktopView, 'ml-1': dataService.desktopView && dataService.isSiteLtr(), 'mr-1': dataService.desktopView && dataService.isSiteRtl()}" i18n="@@createNewTeam">Create new team</p>
          </div>
        </mat-option>

    </mat-autocomplete>
  </mat-form-field>

  <div class="form-control rounded" *ngIf="dataService.mobileView" [ngClass]="{'edit-field': !editUserTeam, 'border-0': !editUserTeam, 'border' :  editUserTeam  }" (click)="showTeamPopUp();">
    <p *ngIf="selectedTeam" class="mt-2 pt-3">{{selectedTeam.name}}</p>
  </div>


  <div class="gray-text mb-1 mt-4" [ngClass]="{'mt-5': dataService.userUpdatePage}" i18n="@@travelPolicy">Travel Policy<!-- <p class="tooltip d-inline" *ngIf="dataService.desktopView"><i class="icon-warning-msg mlight-text" ></i>
    <span class="tooltip-text border rounded bg-white p-3 font-12">When applying a policy to a member, it will overwrite the member team's travel policy</span>
     </p>
    <p class="tooltip d-inline" *ngIf="dataService.mobileView" (click)=" this.dataService.setNotifyModal('updateCompanyUserPolicyNote', '')">
      <i class="icon-warning-msg mlight-text" ></i>
    </p>
    <p class="gray-text font-12 mb-1">Member can be part of one team at a time</p>-->

  </div>
  <mat-form-field class="w-100 chips-container rounded" [ngClass]="{'border-0': !editTeamPolicy, 'border' :  editTeamPolicy, 'realtime-loader': policyLoader  }"
                  *ngIf="dataService.desktopView" (click)="editTeamPolicy = true; policyInput.select()">
    <i *ngIf="!policyLoader" [ngClass]="{'gray-text': editTeamPolicy, 'prevent-pointer-event inactive-clear': !editTeamPolicy}" class="icon-cross small  pointer clear-text p-1"
       (click)= "applyBlur = false;  desktopPolicyFocus()"></i>
    <input class="min-height mx-0 my-0" #policyInput type="text" id="policyInputHtml" matInput [formControl]="policyControl" [matAutocomplete]="autoPolicy"
           (blur)="resetPolicy();" #trigger="matAutocompleteTrigger" (keyup.enter)="onEnterPolicy(); trigger.closePanel();"
           [ngClass]="{'edit-field rounded': !editTeamPolicy, 'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
           (keyup.escape)="restoreSelectedPolicy();" (focus)="policyAutoComplete()">
    <mat-autocomplete #autoPolicy="matAutocomplete" [displayWith]="displayFnPolicy">
      <mat-option style="padding: 0" *ngFor="let policy of filteredPolicies | async" [value]="policy">
        <div (click)="optionClickedPolicy($event, policy)" class="mat-checkbox-container">
          {{policy.name}}
        </div>
      </mat-option>

        <mat-option style="padding: 0" *ngIf="dataService.desktopView && (this.auth.isAdmin() || this.auth.isOwner())">
          <div  (click)="this.dataService.setModal(dataService.newPolicyLocalized, 'createPolicy', '')" class="mat-checkbox-container">
            <p class="blue-text" [ngClass]="{'mb-0': dataService.desktopView, 'ml-1': dataService.desktopView && dataService.isSiteLtr(), 'mr-1': dataService.desktopView && dataService.isSiteRtl()}" i18n="@@createNewPolicy">Create new policy</p>
          </div>
        </mat-option>

    </mat-autocomplete>
  </mat-form-field>

  <div class="form-control rounded" *ngIf="dataService.mobileView" [ngClass]="{'edit-field': !editTeamPolicy, 'border-0': !editTeamPolicy, 'border' :  editTeamPolicy  }" (click)="showPolicyPopUp();">
    <p *ngIf="selectedPolicy" class="mt-2 pt-3">{{selectedPolicy.name}}</p>
  </div>

    <p class="gray-text mb-1 mt-4" [ngClass]="{'text-nowrap': dataService.desktopView}" i18n="@@membersPolicyInfo">Member’s travel policy will overwrite the team policy.</p>
 <!-- <div class="form-group font-16 mb-0 gray-text-dark pt-4">
    <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView || dataService.userUpdatePage}" class="mr-2" type="checkbox" [checked]="travelApprovalValue"
           id="travelApproval" name="travelApproval"  formControlName="travelApproval" (change)="travelApprovalValue = !travelApprovalValue; editUser('travel')"/>
    <label  [ngClass]="{'checkbox-mobile': dataService.mobileView || dataService.userUpdatePage}" for="travelApproval">No approval required for in-policy trips</label>
    <span [ngClass]="{'realtime-loader custom': travelLoader }"></span>
  </div>-->


<div class="edit-user">

  <p class="gray-text mb-1 mt-4" [ngClass]="{'mt-4 pt-2': dataService.userUpdatePage}" i18n="@@CompCards">Company Cards Methods</p>
  <mat-form-field class="w-100 chips-container rounded" [ngClass]="{'edit-field': this.dataService.companyUserInfo && (!this.dataService.companyUserInfo.companyCards || (this.dataService.companyUserInfo.companyCards && !this.dataService.companyUserInfo.companyCards.length)) && !editUserPayments,
  'border': editUserPayments}" (click)="showPaymentsPopUp(); editUserPayments = true;  desktopPaymentFocus()"
                  *ngIf="(dataService.userUpdatePage && dataService.desktopView) ||  (getAllPaymentFlag && payments.length && dataService.mobileView)" >
    <mat-chip-list #chipListPayment >
      <mat-chip class="pointer" (click)="showPaymentsPopUp(); editUserPayments = true; desktopPaymentFocus()"
                *ngFor="let payment of selectedPayments">
        {{ payment.description }}
        <!-- <mat-icon matChipRemove >cancel</mat-icon>-->
        <i class="icon-danger placeholder-text" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
           *ngIf="dataService.desktopView && editUserPayments" (click)="toggleSelectionPayment(payment)"></i>
      </mat-chip>
      <mat-hint class="pt-3" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                style="color: black; pointer-events: none" *ngIf="this.dataService.companyUserInfo && (!this.dataService.companyUserInfo.companyCards || (this.dataService.companyUserInfo.companyCards && !this.dataService.companyUserInfo.companyCards.length)) && !editUserPayments" i18n="@@noPayment">No Payment Methods</mat-hint>
      <input  id="desktopPaymentInput" *ngIf="this.dataService.desktopView && editUserPayments" #paymentInput class="min-height mx-0 my-0 pl-4" type="text" placeholder="Select Payment Methods" aria-label="Select Payment Methods"
             matInput [matAutocomplete]="autoB" [formControl]="paymentControl"  (focus)="resetPayment()" (blur)="resetPaymentBlur()"
             [matChipInputFor]="chipListPayment"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"

      />
    </mat-chip-list>
    <!-- <input class="border" type="text" placeholder="Select Users" aria-label="Select Users" matInput [matAutocomplete]="auto" [formControl]="userControl">-->
    <!--  <mat-hint>Enter text to find users by name</mat-hint>-->
  </mat-form-field>



  <mat-autocomplete #autoB="matAutocomplete" [displayWith]="displayFnPayment">
    <mat-option style="padding: 0" *ngFor="let payment of filteredPayments | async , let i = index" [value]="selectedPayments">
      <div  (click)="optionClickedPayment($event, payment)" class="mat-checkbox-container">
        <mat-checkbox color="primary" class="position-absolute" [checked]="payment.selected" (change)="toggleSelectionPayment(payment)" (click)="$event.stopPropagation()">
        </mat-checkbox>
        <p [ngClass]="{'mb-0': dataService.desktopView, 'ml-5': dataService.desktopView && dataService.isSiteLtr(), 'mr-5': dataService.desktopView && dataService.isSiteRtl()}"> {{ payment.description }}</p>
      </div>
    </mat-option>
       <mat-option style="padding: 0" *ngIf="dataService.desktopView && (this.auth.isOwner())">
        <div  (click)="this.dataService.setModal(this.dataService.addNewCard, 'NewPaymentCard', '')" class="mat-checkbox-container">
          <p class="blue-text" [ngClass]="{'mb-0': dataService.desktopView, 'ml-1': dataService.desktopView && dataService.isSiteLtr(), 'mr-1': dataService.desktopView && dataService.isSiteRtl()}" i18n="@@createNewPayment">Create new payment</p>
        </div>
      </mat-option>

  </mat-autocomplete>
  <input type="text" placeholder="No available payment methods" class="form-control"  disabled *ngIf="getAllPaymentFlag && !payments.length && (dataService.mobileView || !dataService.userUpdatePage)">

  <div class="d-flex justify-content-end mt-3" *ngIf="editUserPayments">
    <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="cancelEditUserPayments()" i18n="@@cancelBtn">Cancel </button>
    <button class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
            [ngClass]="{'m-progress': submittingPayment, 'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}" (click)="saveEditUserPayments();" i18n="@@save">Save</button>
  </div>
  <div *ngIf="errorMessagePay" class="text-danger smaller pt-3" [ngClass]="{'text-right': dataService.desktopView}">
     {{errorMessagePay}}
  </div>
  <div class="form-item form-type-checkbox mt-3 pb-1" *ngIf="getAllPaymentFlag && payments.length && selectedPayments.length" >
    <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView || dataService.userUpdatePage, 'ml-4': dataService.desktopView && !dataService.userUpdatePage &&  dataService.isSiteLtr(), 'mr-4': dataService.desktopView && !dataService.userUpdatePage && dataService.isSiteRtl(), 'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" id="restrict-user" type="checkbox" [checked]="restrictUser" (change)="disablePersonalPayments();" />
    <label [ngClass]="{'checkbox-mobile': dataService.mobileView || dataService.userUpdatePage}" for="restrict-user" class="gray-text-dark font-16" i18n="@@onlyAssignedCards">Member can book using assigned cards only</label>
    <span [ngClass]="{'realtime-loader custom1': paymentLoader }"></span>
  </div>
</div>
  </div>

  <div class="form-group mb-0 gray-primaray-text pt-3" [ngClass]="{'mt-4 pt-2': dataService.userUpdatePage}"
       *ngIf="this.dataService.companyUserInfo && this.dataService.companyUserInfo.role.toLowerCase() !== 'owner'">
    <p class="gray-text mb-1" i18n="@@role">Role</p>
    <input [ngClass]="{'mt-2 input-mobile': dataService.mobileView, 'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" type="checkbox" [checked]="dataService.isAdmin"
           id="isAdmin" name="isAdmin"  formControlName="isAdmin" (change)="confirmAdminChange()"/>
    <label class="font-16" [ngClass]="{'checkbox-mobile': dataService.mobileView}" for="isAdmin" i18n="@@setAsAdmin">Set as Admin</label>
   <!-- <span [ngClass]="{'realtime-loader custom2': dataService.adminLoader }"></span>-->
    <p class="gray-text ml-4 pl-1 p-max-width" i18n="@@setAsAdminUser">Admin role allows {{this.dataService.companyUserInfo.firstName}} to manage members, teams, travel policies, view subscription and billing history and workspace integrations.  </p>
  </div>

</form>

  <div *ngIf="errorMessage" class="text-danger pt-3">
    {{errorMessage}}
  </div>

</div>
</div>



<div class="justify-content-end edit-user" >

  <div (click)="mobileFocus()" [ngClass]="{'in action-popup':showPaymentsAutoCompletePopUp || showTeamAutoCompletePopUp || showPolicyAutoCompletePopUp}">
    <div [ngClass]="{'header': showPaymentsAutoCompletePopUp || showTeamAutoCompletePopUp || showPolicyAutoCompletePopUp}" >
      <div *ngIf=" showPaymentsAutoCompletePopUp || showTeamAutoCompletePopUp || showPolicyAutoCompletePopUp">
        <div *ngIf=" showPaymentsAutoCompletePopUp || showTeamAutoCompletePopUp || showPolicyAutoCompletePopUp">

          <div class="info" [ngClass]="{'pay-and-member' : showPaymentsAutoCompletePopUp}" >
            <span *ngIf="showPaymentsAutoCompletePopUp" i18n="@@paymentMethods">Payment Methods</span>
            <span *ngIf="showPolicyAutoCompletePopUp" i18n="@@policy">Policy</span>
            <span *ngIf="showTeamAutoCompletePopUp" i18n="@@team">Team</span>
          </div>
          <div class="done" *ngIf=" showPaymentsAutoCompletePopUp">
            <span *ngIf="showPaymentsAutoCompletePopUp" (click)="saveSelectedPayments(); hidePopUp();" i18n="@@done">Done</span>
          </div>
          <div class="close" (click)="cancelEditTempPayments(); hidePopUp();" i18n="@@cancelBtn">
            Cancel
          </div>
        </div>
      </div>


      <div class="position-relative mobile-search-field" *ngIf="showTeamAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                   type="text" matInput #teamInput id="teamInput" [formControl]="teamControl" [matAutocomplete]="autoTeam" (blur)="resetTeam()">
            <mat-autocomplete #autoTeam="matAutocomplete" [displayWith]="displayFnTeam">
              <mat-option style="padding: 0" *ngFor="let team of filteredTeams | async; " [value]="team">
                <div  (click)="optionClickedTeam($event, team)" class="mat-checkbox-container">
                  {{team.name}}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>

      <div class="position-relative mobile-search-field" *ngIf="showPolicyAutoCompletePopUp">
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input class="bg-white min-height mx-0 my-0" [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                   type="text" matInput #policyInput id="policyInput" [formControl]="policyControl" [matAutocomplete]="autoPolicy" (blur)="resetPolicy()">
            <mat-autocomplete #autoPolicy="matAutocomplete" [displayWith]="displayFnPolicy">
              <mat-option style="padding: 0" *ngFor="let policy of filteredPolicies | async" [value]="policy">
                <div (click)="optionClickedPolicy($event, policy)" class="mat-checkbox-container">
                  {{policy.name}}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>

      <div class="position-relative mobile-search-field" *ngIf="showPaymentsAutoCompletePopUp" >
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container mobile-header-chips border rounded">
            <mat-chip-list #chipList >
              <mat-chip
                *ngFor="let payment of mobileTempSelectedPayments">
                {{ payment.description }}
                <i class="icon-danger placeholder-text" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"
                   (click)="toggleSelectionTempPayment(payment)"></i>
              </mat-chip>
              <input #paymentInput id="paymentInput" class=" mobile-input mx-0 my-0"
                     [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                     type="text" placeholder="Select Users" aria-label="Select Payment Methods"
                     matInput [matAutocomplete]="autoPayment" [formControl]="paymentControl"
                     (focus)="resetPayment()" (blur)="resetPaymentBlur()"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"

              />
            </mat-chip-list>

            <mat-autocomplete #autoPayment="matAutocomplete" [displayWith]="displayFnPayment">
              <mat-option style="padding: 0" *ngFor="let payment of filteredPayments | async, let i = index" [value]="mobileTempSelectedPayments">
                <div  (click)="optionClickedMobilePayment($event, payment)" class="mat-checkbox-container">
                  <mat-checkbox color="primary" class="position-absolute" [checked]="payment.selected" (change)="toggleSelectionTempPayment(payment)" (click)="$event.stopPropagation()">
                  </mat-checkbox>
                  {{ payment.description }}
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>


    </div>

  </div>
</div>
