<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pb-5 pt-4 pt-lg-5 company-info-container">
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-4 pb-lg-5 mb-3">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                    '/' + this.auth.companyInfo.id + '/settings'
                  ])
                "
                i18n="@@menuItemSettings"
                >Settings</a
              >
              <span class="font-16 gray-text-dark">
                / <ng-container i18n="@@account">Account</ng-container>
              </span>
            </div>
          </div>
        </div>
        <div class="row mx-lg-4 pb-5">
          <div class="col-lg-6" *ngIf="auth.companyInfo">
            <div class="row">
              <div class="col-lg-8 min-left-side-col-width">
                <div *ngIf="!router.url.includes('edit')">
                  <p class="gray-primaray-text pb-3">
                    <span
                      class="font-16"
                      [ngClass]="{
                        'pr-3': dataService.isSiteLtr(),
                        'pl-3': dataService.isSiteRtl()
                      }"
                      i18n="@@companyInfo"
                      >Company Info</span
                    ><span
                      class="blue-text pointer font-14"
                      (click)="editCompanyInfo()"
                      i18n="@@edit"
                      >Edit</span
                    >
                  </p>
                  <p class="gray-text font-14 mb-1" i18n="@@displayName">
                    Display name
                  </p>
                  <p class="gray-primaray-text pb-3 font-16">
                    {{ auth.companyInfo.name }}
                  </p>
                  <p
                    class="gray-text font-14 mb-1"
                    i18n="@@totalNumberEmployees"
                  >
                    Total number of employees
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="this.auth.companyInfo.numberOfEmployees"
                  >
                    {{ this.auth.companyInfo.numberOfEmployees }}
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="!this.auth.companyInfo.numberOfEmployees"
                  >
                    {{ this.notSet }}
                  </p>
                  <p class="gray-text font-14 mb-1" i18n="@@annualTravelSpend">
                    Annual travel spend
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="this.auth.companyInfo.annualTravelSpend"
                  >
                    {{ auth.companyInfo.annualTravelSpend }}
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="!this.auth.companyInfo.annualTravelSpend"
                  >
                    {{ this.notSet }}
                  </p>
                  <p class="gray-text font-14 mb-1" i18n="@@legalCompanyName">
                    Legal company name
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="this.auth.companyInfo.legalCompanyName"
                  >
                    {{ auth.companyInfo.legalCompanyName }}
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="!this.auth.companyInfo.legalCompanyName"
                  >
                    {{ this.notSet }}
                  </p>

                  <p class="gray-text font-14 mb-1" i18n="@@industry">
                    Industry
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="this.auth.companyInfo.industry"
                  >
                    {{ getSelectedIndustry() }}
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="!this.auth.companyInfo.industry"
                  >
                    {{ this.notSet }}
                  </p>
                  <hr />
                  <p class="gray-primaray-text pb-3">
                    <span
                      class="font-16"
                      [ngClass]="{
                        'pr-3': dataService.isSiteLtr(),
                        'pl-3': dataService.isSiteRtl()
                      }"
                      i18n="@@userPreferences"
                      >Preferences</span
                    >&nbsp;<span
                      class="blue-text pointer font-14"
                      (click)="editPreferences(auth.companyInfo.systemPreferences)"
                      i18n="@@edit"
                      >Edit</span
                    >
                  </p>
                  <p class="gray-text font-14 mb-1" i18n="@@langPlaceHolder">
                    Language
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="
                      auth.companyInfo &&
                      auth.companyInfo.systemPreferences
                      "
                    dir="ltr"
                  >
                    {{
                      auth.companyInfo.systemPreferences.preferredLanguage.name ===
                      "Arabic"
                        ? "العربية"
                        : auth.companyInfo.systemPreferences.preferredLanguage.name
                    }}
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="!auth.companyInfo.systemPreferences"
                  >
                    &mdash;
                  </p>

                  <p
                    class="gray-text font-14 mb-1 mt-4"
                    i18n="@@preferedCurrency"
                  >
                    Currency
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16 text-capitalize"
                    *ngIf="
                      auth.companyInfo &&
                      auth.companyInfo.systemPreferences
                      "
                  >
                    {{
                      auth.companyInfo.systemPreferences.preferredCurrency.name
                    }}&nbsp;({{
                      auth.companyInfo.systemPreferences.preferredCurrency.code
                    }})
                  </p>
                  <p
                    class="gray-primaray-text pb-3 font-16"
                    *ngIf="!auth.companyInfo.systemPreferences"
                  >
                    &mdash;
                  </p>
                  <hr />

                  <div [ngClass]="{ 'max-width-330': dataService.desktopView }">
                    <p class="gray-primaray-text font-16 mt-4 pt-2 pb-4">
                      <ng-container i18n="@@MainOfficeAddress"
                        >Main office address</ng-container
                      >
                    </p>
                    <div class="items-full-list">
                      <div class="border rounded p-3 position-relative mb-3">
                        <ng-container *ngIf="this.auth.companyInfo.address">
                          <div class="d-flex">
                            <p
                              class="font-16 gray-primaray-text"
                              i18n="@@MainOffice"
                              [ngClass]="{
                                'pr-2': dataService.isSiteLtr(),
                                'pl-2': dataService.isSiteRtl()
                              }"
                            >
                              {{ this.auth.companyInfo.address.name }}
                            </p>
                            <img
                              width="16"
                              height="16"
                              loading="lazy"
                              *ngIf="this.auth.companyInfo.address.countryCode"
                              class="mt-1 max-100 img-min-width pr-1"
                              [src]="
                                'https://www.triphop.com/cdn/flags/' +
                                this.auth.companyInfo.address.countryCode.toLowerCase() +
                                '.svg'
                              "
                              onError="this.src='/assets/img/transperant.png'"
                            />
                          </div>
                          <div class="gray-text font-14">
                            <p
                              class="mb-0"
                              *ngIf="this.auth.companyInfo.address.address1"
                            >
                              {{ this.auth.companyInfo.address.address1 }}
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="this.auth.companyInfo.address.address2"
                            >
                              {{ this.auth.companyInfo.address.address2 }}
                            </p>
                            <p class="mb-0">
                              <span *ngIf="this.auth.companyInfo.address.city"
                                >{{ this.auth.companyInfo.address.city
                                }}{{
                                  this.auth.companyInfo.address.city &&
                                  (this.auth.companyInfo.address.stateCode ||
                                    this.auth.companyInfo.address.zipCode)
                                    ? ","
                                    : ""
                                }} </span
                              ><span
                                *ngIf="this.auth.companyInfo.address.stateCode"
                                >{{
                                  this.auth.companyInfo.address.stateCode
                                }} </span
                              ><span
                                *ngIf="this.auth.companyInfo.address.zipCode"
                                >{{ this.auth.companyInfo.address.zipCode }}
                              </span>
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="this.auth.companyInfo.address.countryCode"
                            >
                              <ng-container
                                *ngFor="let country of this.vars.countries"
                              >
                                <span
                                  *ngIf="
                                    country.code ===
                                    this.auth.companyInfo.address.countryCode
                                  "
                                >
                                  {{ country.name }}
                                </span>
                              </ng-container>
                            </p>
                            <p
                              class="mb-0"
                              *ngIf="this.auth.companyInfo.address.phoneNumber"
                            >
                              <ng-container i18n="@@phoneNumber"
                                >Phone number</ng-container
                              >: {{ this.auth.companyInfo.address.phoneNumber }}
                            </p>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="!this.auth.companyInfo.address">
                          <p class="gray-primaray-text font-16 mb-0">
                            <ng-container i18n="@@address"
                              >Address</ng-container
                            >
                          </p>
                          <p class="gray-text mb-2 font-16">-</p>
                        </ng-container>
                        <i
                          class="add-drop-down icon-three-dots font-18 ellipsis gray-text pointer p-3"
                          (click)="showActionsAddMain = !showActionsAddMain"
                        ></i>
                        <div
                          class="border shadow font-14 position-absolute bg-white drop"
                          *ngIf="showActionsAddMain"
                        >
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            (click)="addEditMainAddress()"
                            i18n="@@edit"
                          >
                            Edit
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="pb-3">
                    <span
                      class="gray-text font-14"
                      [ngClass]="{
                        'pr-3': dataService.isSiteLtr(),
                        'pl-3': dataService.isSiteRtl()
                      }"
                      i18n="@@offices"
                      >Offices</span
                    ><span
                      class="blue-text font-14 pointer"
                      (click)="addOfficeAnotherAddress()"
                      i18n="@@add"
                      >Add</span
                    >
                  </p>
                  <p
                    class="gray-text font-14"
                    i18n="@@addAnyCompAddresses"
                    *ngIf="!hasOtherAddresses()"
                  >
                    Add any company branches addresses
                  </p>
                  <div class="items-full-list" *ngIf="hasOtherAddresses()">
                    <ng-container
                      *ngFor="
                        let address of dataService.getAllAddresses;
                        let i = index
                      "
                    >
                      <div
                        *ngIf="address.id >= 2"
                        class="border rounded p-3 position-relative mb-3"
                      >
                        <div class="d-flex">
                          <p
                            class="font-16 gray-primaray-text"
                            i18n="@@MainOffice"
                            [ngClass]="{
                              'pr-2': dataService.isSiteLtr(),
                              'pl-2': dataService.isSiteRtl()
                            }"
                          >
                            {{ address.name }}
                          </p>
                          <img
                            width="16"
                            height="16"
                            loading="lazy"
                            *ngIf="address.countryCode"
                            class="mt-1 max-100 img-min-width pr-1"
                            [src]="
                              'https://www.triphop.com/cdn/flags/' +
                              address.countryCode.toLowerCase() +
                              '.svg'
                            "
                            onError="this.src='/assets/img/transperant.png'"
                          />
                        </div>
                        <div class="gray-text font-14">
                          <p class="mb-0" *ngIf="address.address1">
                            {{ address.address1 }}
                          </p>
                          <p class="mb-0" *ngIf="address.address2">
                            {{ address.address2 }}
                          </p>
                          <p class="mb-0">
                            <span *ngIf="address.city"
                              >{{ address.city
                              }}{{
                                address.city &&
                                (address.stateCode || address.zipCode)
                                  ? ","
                                  : ""
                              }} </span
                            ><span *ngIf="address.stateCode"
                              >{{ address.stateCode }} </span
                            ><span *ngIf="address.zipCode"
                              >{{ address.zipCode }}
                            </span>
                          </p>
                          <p class="mb-0" *ngIf="address.countryCode">
                            <ng-container
                              *ngFor="let country of this.vars.countries"
                            >
                              <span
                                *ngIf="country.code === address.countryCode"
                              >
                                {{ country.name }}
                              </span>
                            </ng-container>
                          </p>
                          <p class="mb-0" *ngIf="address.phoneNumber">
                            <ng-container i18n="@@phoneNumber"
                              >Phone number</ng-container
                            >: {{ address.phoneNumber }}
                          </p>
                        </div>
                        <i
                          class="add-other-drop-down{{
                            i
                          }} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                          (click)="
                            showActions = !showActions; selectedIndex = i
                          "
                        ></i>
                        <div
                          class="border shadow font-14 position-absolute bg-white drop"
                          *ngIf="showActions && i === selectedIndex"
                        >
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            (click)="editAnotherAddress(address)"
                            i18n="@@edit"
                          >
                            Edit
                          </p>
                          <p
                            class="pointer py-2"
                            [ngClass]="{
                              'pl-4': dataService.isSiteLtr(),
                              'pr-4': dataService.isSiteRtl()
                            }"
                            (click)="deleteAddress(address)"
                            i18n="@@deleteBtn"
                          >
                            Delete
                          </p>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <app-account-update
                  *ngIf="router.url.includes('edit')"
                ></app-account-update>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="dataService.desktopView">
            <div
              class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top"
              [ngClass]="{
                'ml-auto': dataService.isSiteLtr(),
                'mr-auto': dataService.isSiteRtl()
              }"
            >
              <p
                class="primaray-text font-weight-bold mb-1 font-16"
                i18n="@@displayName"
              >
                Display name
              </p>
              <p class="mb-0 gray-text-dark" i18n="@@displayNameNote">
                Employees will see your company’s display name for Work emails,
                reservations, and invite page.
              </p>
            </div>
          </div>
        </div>
        <div
          class="mx-lg-5 gray-text-dark"
          *ngIf="!router.url.includes('edit') && this.auth.isOwner()"
        >
          <p class="font-weight-bold" i18n="@@transferAccountOwnership">
            Transfer Account Ownership
          </p>
          <p i18n="@@transferAccountOwnershipMsg1">
            Your company account can have only one owner at a time, and only the
            owner can have full access to the company account.
          </p>
          <p>
            <ng-container i18n="@@transferAccountOwnershipMsg2"
              >To transfer the ownership to other member, click</ng-container
            >&nbsp;<a
              class="blue-text"
              (click)="
                this.router.navigate([
                  '/' +
                    this.auth.companyInfo.id +
                    '/settings/account/transfer-ownership'
                ])
              "
              i18n="@@here"
              >here</a
            >&nbsp;<ng-container i18n="@@orContactUsAt"
              >or contact us at</ng-container
            >&nbsp;<a href="mailto:support@safarhub.com"
              >support@safarhub.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
