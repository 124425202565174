import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import * as moment from 'moment';


@Component({
  selector: 'app-deactivate-subscription',
  templateUrl: './deactivate-subscription.component.html',
  styleUrls: ['./deactivate-subscription.component.scss'],
  animations: [ onMainContentChange ]
})
export class DeactivateSubscriptionComponent implements OnInit {

  constructor(public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    this.dataService.getSubscriptionDetails();
 /*   if (!this.auth.companyInfo) {
      this.auth.fetchCompanyData();
    }*/
  }
  formatDate(date) {
    return moment(date).format('MMMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }
  confirmDeactivate() {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@cancelSubscription:Cancel Subscription`, 'cancelSubscription', '');
    } else {
      this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing/cancel-subscription/reasons']);

    }
   }
}
