import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import { SlackInstallationComponent} from './corporate-travel/slack-connection/slack-installation/slack-installation.component';
import { SlackWorkspaceLoginComponent} from './corporate-travel/slack-connection/slack-workspace-login/slack-workspace-login.component';
import { SlackConnectComponent} from './corporate-travel/slack-connection/slack-connect/slack-connect.component';
import { SlackErrorPageComponent} from './corporate-travel/slack-connection/slack-error-page/slack-error-page.component';
import { SlackThankYouPageComponent} from './corporate-travel/slack-connection/slack-thank-you-page/slack-thank-you-page.component';


const routes: Routes = [

  { path: '', redirectTo: 'login' , pathMatch: 'full'},
  { path: 'slack',
  children : [
    {path: '', component: SlackInstallationComponent},
    {path: 'login', component: SlackWorkspaceLoginComponent},
    {path: 'connect', component: SlackConnectComponent},
    {path: 'thank-you', component: SlackThankYouPageComponent},
    {path: 'error', component: SlackErrorPageComponent},
    ]},
  {
    path: '',
    loadChildren: './corporate-travel/corporate-travel.module#CorporateTravelModule',
  },
  { path: '**', redirectTo: 'login'  },


];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })

  ]
})
export class AppRoutingModule {

}

