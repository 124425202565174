import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { AuthServiceMain } from "../../auth/auth.service";
import * as moment from "moment";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { FireService } from "../../services/firebase.service";
import "firebase/remote-config";

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  userInfo;
  data;
  hotelData;
  params;
  diffInDays;
  extraCashback;
  TripAdviserRating;
  tripAdviserdata;
  PaxesArray;
  PaxesCount;
  discount;
  login;
  discountfromInit = true;
  discountfromdata = false;
  reviewTitle =
    $localize`:@@reviewSummary:Review summary` +
    "<span>(" +
    $localize`:@@poweredByGoogle:Powered by Google` +
    ") </span>";
  envMode = environment.production;

  constructor(
    public Service: DataService,
    public Auth: AuthServiceMain,
    public googleAnalyticsService: GoogleAnalyticsService,
    public router: Router,
    private titleService: Title,
    public FireService: FireService
  ) {
    this.titleService.setTitle("Book | SafarHub");
    this.discount = false;
    this.Service.componentMethodCalled$.subscribe(() => {
      if (this.Service.urlWithoutParams === "/availability/booking") {
        this.discountfromInit = false;
        if (this.Auth.isLogedIn) {
          this.Service.checkCashback(
            this.Service.newRate.finalSellingPrice
          ).subscribe((success) => {
            this.data = success;
            if (this.data.errorCode === 0) {
              this.extraCashback = this.data.data;
            } else {
              this.extraCashback = false;
            }
          });
        }
        if (this.Auth.isLogedIn && !this.Auth.isPrime) {
        } else {
          this.discountfromdata = false;
        }
        this.Service.clearAllMsgs();
        this.Service.ratesExpiryDuration();
        this.Service.recheckAll(false);
      }
    });
  }

  ngOnInit() {
    this.Service.FacilitiesFeaturedavailabile = false;
    this.Service.dropDowmBookingForm = false;
    this.Service.errorMsgCoupon = "";
    this.Service.couponValue = "";
    this.Service.bookingPage = true;
    this.Service.hotelPage = false;
    this.Service.validcoupon = false;
    if (!sessionStorage.getItem("hotelData")) {
      this.router.navigate(["/"]);
    }
    this.Service.clearAllMsgs();
    this.Service.ratesExpiryDuration();
    this.Service.recheckAll(true);
    this.Service.hotelData = JSON.parse(sessionStorage.getItem("hotelData"));
    this.Service.roomName = sessionStorage.getItem("room");
    this.Service.params = JSON.parse(sessionStorage.getItem("params"));
    this.PaxesArray = this.Service.params.paxes.split(",");
    this.PaxesCount = this.PaxesArray.length + Number(this.PaxesArray[0]) - 1;
    this.diffInDays = moment
      .utc(this.Service.params.checkOut)
      .diff(moment.utc(this.Service.params.checkIn), "days");
    if (sessionStorage.getItem("tripAdviserdata")) {
      this.tripAdviserdata = JSON.parse(
        sessionStorage.getItem("tripAdviserdata")
      );
      this.TripAdviserRating = (this.tripAdviserdata.data.rating * 100) / 5;
    }
    this.userInfo = this.Auth.userInfo;
    if (this.Service.hotelData) {
      this.Service.hotelData.myFacilities.map((item) => {
        if (item.featured) {
          this.Service.FacilitiesFeaturedavailabile = true;
        }
      });
    }
    this.FireService.remoteConfig();
  }

  tripAdvisorTrigger() {
    this.googleAnalyticsService.eventEmitter(
      "Stats",
      "TripAdvisor",
      "click",
      1
    );
  }

  refreshPage() {
    location.reload();
  }

  clearDiscountAll() {
    if (this.discount) {
      this.discount.data.discounts.forEach((item) => (item.isChecked = false));
    }
    this.Service.clearDiscountAll();
  }
}
