
<div class="main-container border-0 pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">

  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}">


    <div class="row">
      <div class="col-lg-6 pb-5 pb-lg-0 order-lg-1 extra-padding-top" *ngIf="!slackConnected">
        <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box" [ngClass]="{'white-box position-relative mr-auto' : dataService.desktopView, 'mx-auto': dataService.mobileView}">
          <div class="text-center">
            <h4 class="font-24 pt-md-5 mb-3 font-weight-bold text-center" i18n="@@safarHubApp">
              SafarHub App
            </h4>
          </div>
          <p class="gray-text font-16 mb-0 pt-4 text-center workspace-registration-container mx-auto"><ng-container i18n="@@loggedInAs">Logged in as</ng-container>&nbsp;<span *ngIf="auth.userInfo">{{auth.userInfo.email}}</span>.</p>
          <p class="gray-text font-16 mb-5 text-center workspace-registration-container mx-auto" i18n="@@connectSafarHubToSlack">Connect SafarHub to your slack workspace</p>
          <div class="row" >
            <div class="col">
              <button  [ngClass]="{'m-progress': submitting }" [disabled]="this.stateValue === '' || this.stateValue === null || this.stateValue === undefined" (click)="submit()"
                       class="mb-3 btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
                <span class="mx-auto" i18n="@@connect">Connect</span>
              </button>
            </div>
          </div>
          <p class="text-danger" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">{{ this.errorMsg}}</p>

        </div>
      </div>


      <div class="col-lg-6 pb-5 pb-lg-0 order-lg-1 extra-padding-top" *ngIf="slackConnected">
        <div class="login-form workspace-registration-container px-lg-5 pt-5 corporate-box" [ngClass]="{'white-box position-relative mr-auto' : dataService.desktopView, 'mx-auto': dataService.mobileView}">
          <div class="text-center">
            <h4 class="font-24 pt-md-5 mb-3 bold-600 text-center" i18n="@@connectSafarHubApp">
              Connect SafarHub App
            </h4>
          </div>
          <p class="gray-text pt-4 font-16 mb-5 text-center workspace-registration-container mx-auto px-3" i18n="@@connectedSafarHubBot">You’ve successful connected SafarHub bot to your slack workspace.</p>
          <div class="row" >
            <div class="col">
              <button *ngIf="this.auth.companyInfo" (click)="this.router.navigate(['/' + this.auth.companyInfo.id])"  [ngClass]="{'m-progress': submitting }" class="btn btn-outline-blue btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
                <span class="mx-auto" i18n="@@myWorkSpace">My Workspace</span>
              </button>
            </div>
          </div>
          <p class="text-danger" [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">{{ this.errorMsg}}</p>

        </div>
      </div>

      <div class="col-lg-6 d-lg-flex z-index-10">
        <div class="text-center" [ngClass]="{'text-lg-left': dataService.isSiteLtr(), 'text-lg-right': dataService.isSiteRtl()}">
          <div class="w-100 mb-2">
            <img width="120" height="38"  loading="lazy" src="assets/img/slack.png" />
          </div>
          <div class="w-100 mb-4">
            <p class="gray-text font-16" i18n="@@manageYourBusinessInSlack">Manage your business travel in slack</p>
          </div>
          <div class="w-100">
            <img width="350" height="430"  loading="lazy" class="initial-height img-mobile" src="assets/img/slack-business.png" />
          </div>
        </div>
      </div>


    </div>




  </div>






</div>


