import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AuthServiceMain} from '../../auth/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss']
})
export class CompanyLoginComponent implements OnInit {

  constructor(private titleService: Title, private formBuilder: FormBuilder,  public dataService: DataService, public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Sign in to your workspace | SafarHub');
    this.dataService.componentMethodCalled$.subscribe(
      () => {
        if(this.auth.isLogedIn && this.auth.companyInfo) {
          this.router.navigate(['/' + this.auth.companyInfo.id]);
        }

      }
    );
  }

 /* loginForm: FormGroup;
  submitted = false;
  submitting = false;
  companyId;
  errorMsg = '';
  data;*/
  ngOnInit(): void {
    this.dataService.signInErrorMsg = false;
    if(this.auth.isLogedIn && this.auth.companyInfo) {
      this.router.navigate(['/' + this.auth.companyInfo.id]);
    }
   /* this.dataService.signInErrorMsg = false;
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    }, {
    });*/
  }

 /* get c() { return this.loginForm.controls; }



  onSubmitLogin() {
    this.submitted = true;
    this.submitting = true;
    if (this.loginForm.invalid) {
      this.submitting = false;
      return;
    }
    this.dataService.companyEmail = this.loginForm.controls.email.value;
    this.dataService.getCompanyByEmail(this.loginForm.controls.email.value).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if(this.data.data) {
            this.companyId = this.data.data.id;
            this.router.navigate(['/' + this.companyId + '/login']);
          } else {
            this.submitting = false;

            this.dataService.signInErrorMsg = true;
          }
        } else {
          this.submitting = false;
          this.dataService.signInErrorMsg = true;
        }
      }, error => {
        this.submitting = false;
        this.errorMsg = environment.messages.error;
      }
    );



  }*/

}
