import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {GoogleAnalyticsService} from "../../google-analytics.service";

@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.scss']
})
export class FlightComponent implements OnInit {

  constructor(public route: ActivatedRoute, public googleAnalyticsService: GoogleAnalyticsService, public router: Router, public  dataService: DataService) { }
  item;
  departureDetails;
  returnDetails;
  summaryDeparture;
  summaryReturn;
  data;
  departRulesArray = [];
  departBag = [];
  returnRulesArray = [];
  returnBag = [];
  rules;
  rulesLoader = true;
  travelerId;
  ngOnInit(): void {
    if(this.router.url.includes('travelerId=')){
      this.travelerId = ('' + this.router.url).substring(('' + this.router.url).indexOf('travelerId=') + 11);
    } else{
      this.travelerId = null;
    }


    this.googleAnalyticsService.eventEmitter('FlightStats', 'FlightDetails', 'View', 1);
    this.dataService.errorMsg = '';
    this.item = this.dataService.rightSideDrawerData;
    // this.dataService.getFlightRules(this.item.details.fareInfo.fareKey);


    this.summaryDeparture = this.item.details.originDestinationOptionsGroups[0].summary;
    if (this.item.trip === 'oneway') {
      this.departureDetails = this.item.details.originDestinationOptionsGroups[0].originDestinationOptions;
    } else if (this.item.trip === 'roundtrip') {
      this.summaryReturn = this.item.details.originDestinationOptionsGroups[1].summary;
      this.departureDetails = this.item.details.originDestinationOptionsGroups[0].originDestinationOptions;
      this.returnDetails = this.item.details.originDestinationOptionsGroups[1].originDestinationOptions;
    }

    if(!this.item.approvalDetails){
    this.dataService.flightRules(this.item.details.fareInfo.fareKey).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.rules = this.data.data.baggageInfo;
          this.departureDetails.forEach((flight) => {
            this.rules.forEach((rule) => {
              if(rule.baggageDescription) {
                if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departRulesArray.length) {
                  this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                  this.departBag.push(rule.baggageDescription);
                } else if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departBag.includes(rule.baggageDescription)) {
                  this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                  this.departBag.push(rule.baggageDescription);
                }
               } else{
                if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departRulesArray.length) {
                  this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                  this.departBag.push(rule.baggage);
                } else if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departBag.includes(rule.baggage)) {
                  this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                  this.departBag.push(rule.baggage);
                }
              }




            });
          });

          if (this.item.trip === 'roundtrip') {
            this.returnDetails.forEach((flight) => {
              this.rules.forEach((rule) => {
                if(rule.baggageDescription) {
                  if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnRulesArray.length) {
                    this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                    this.returnBag.push(rule.baggageDescription);
                  } else if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnBag.includes(rule.baggageDescription)) {
                    this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                    this.returnBag.push(rule.baggageDescription);
                  }
                } else{
                  if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnRulesArray.length) {
                    this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                    this.returnBag.push(rule.baggage);
                  } else if (rule.flightNo === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnBag.includes(rule.baggage)) {
                    this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                    this.returnBag.push(rule.baggage);
                  }
                }

              });
            });
          }
          this.rulesLoader = false;
        } else {
          this.dataService.errorMsg = this.data.message;
          this.rulesLoader = false;
        }
      }, error => {
        this.dataService.errorMsg = environment.messages.error;
        this.rulesLoader = false;
      }
    );
  }

    this.dataService.flightBookingPage = false;
    this.dataService.flightBookingSubmit = false;
  }
  formatDate(date) {
    return  moment(date).format('ddd') + ', ' + moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }

}
