
<div *ngIf="this.dataService.hotellistLoader" class="loading_icon_caption text-center mt-5"></div>
<div *ngIf="this.dataService.hotellistLoader" class="loading-message text-center"> Loading </div>

<div class="company-section-with-thick-border border"  [ngClass]="{'custom-container rounded-right': dataService.desktopView, 'thick-top rounded-bottom': dataService.mobileView}" *ngIf=" this.dataService.favoritesList.length === 0 && !this.dataService.hotellistLoader && dataService.isCompany()">
  <div class="row mx-0 p-4 h-100 justify-content-center align-items-center">
    <div class="col-md-8 font-16">
      <p class="mb-0 gray-primaray-text" i18n="@@myFavoriteMsg">When you find a hotel you like, save it. Share it with your friends and come back to find it here again.</p>

    </div>
    <div class="col-md-4" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
      <button   (click)="this.router.navigate(['/' + this.logedIn.companyInfo.id]);"
                class="w-100 btn px-5 py-2 mt-4 mb-3 my-lg-2 rounded text-center sharp-font bold-500 font-14 find-btn btn-primary"
                [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" i18n="@@findHotel">Find a Hotel</button>
    </div>
  </div>
</div>

<div class="mt-4 mt-lg-5 mb-5" *ngIf=" this.dataService.favoritesList.length === 0 && !this.dataService.hotellistLoader && !dataService.isCompany()">
  <div class="row mt-5 pt-lg-3 trips">
    <div class="col-sm-6 mb-5">
      <p class="pb-4 pt-3 pt-lg-3 gray-primaray-text mb-1 font-18 font-weight-bold">Save Your Favorite Hotels</p>
      <p class="font-16 gray-text-dark">When you find a hotel you like, save it. Share it with your friends and come back to find it here again.</p>
      <a class="green_btn mt-4 font-16 sharp-font w-100" routerLink="/" >Find a hotel </a>
    </div>
    <div class="col-sm-6 pt-3">
      <img width="362" height="200" loading="lazy" class="initial-height" src="assets/img/no_search_reult.png">
    </div>
  </div>
</div>

<div class="fav-hotels container custom-container mt-5 pt-lg-4 mb-5 px-0" *ngIf=" this.dataService.favoritesList.length > 0 && !this.dataService.hotellistLoader">
  <div *ngIf="dataService.desktopView">
    <div *ngFor="let item of this.dataService.favoritesList;let i = index">
      <div class="row">
        <div class="col-8">
          <p class="font-18-to-14 gray-primaray-text">
            {{item.caption}}
          </p>
        </div>
        <div class="col-4 hotelShare user_menu dropdown d-flex">
          <span [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"><!--<i (click)="this.dataService.shareAllFavHotels(item.caption, true)" class="icon-share2 fav blue-text"
                   [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>--></span>
          <span (click)="trashAllFavHotels(item.caption)"><i class="icon-trash fav blue-text"></i></span>
        </div>

      </div>

      <div class=" mb-4 hotel-item rounded border overflow-hidden" *ngFor="let hotel of item.items; let y = index ;  ">

        <div class="row">

          <div class="col-md-5 col-lg-4" [ngClass]="{'pr-lg-0': dataService.isSiteLtr(), 'pl-lg-0': dataService.isSiteRtl()}">
            <div class="hotel_image ">
              <a target="_blank"
                 [routerLink]="[hotelNavigateUrl]" [queryParams]="{searchQuery: hotel.searchQuery}">
              <img width="1000" height="667" loading="lazy" [ngClass]="{'rounded-left': dataService.isSiteLtr(), 'rounded-right': dataService.isSiteRtl()}"
                   [src]="hotel.imageUrl || 'assets/img/no_image.png'">
              </a>
              <div class="favorite-heart" class="favorite-heart" (click)="this.dataService.toggleFavoriteHotel(hotel.id); isFav[''+i+y] = !isFav[''+i+y]">
                                                  <span class="position-absolute font-20"
                                                          [ngClass]="{'icon-colored-heart' : isFav[''+i+y], 'icon-heart' : !isFav[''+i+y] }">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                  </span>
              </div>
            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="pt-4 pb-4" [ngClass]="{'pr-4': dataService.isSiteLtr(), 'pl-4': dataService.isSiteRtl()}">
              <div class="row">
                <div class="col-lg-11 col-md-11">

                  <div class="hotel_info" [ngClass]="{'pl-3 pl-lg-1': dataService.isSiteLtr(), 'pr-3 pr-lg-1': dataService.isSiteRtl()}">
                    <div class="teaser_top">

                      <div class="d-flex">
                        <a class="primaray-text font-16 bolder" [routerLink]="[hotelNavigateUrl]" [queryParams]="{searchQuery: hotel.searchQuery}">
                          {{hotel.name}}&nbsp;.&nbsp;
                        </a>

                        <span *ngIf="hotel.rating" class="hotel-stars-text text-nowrap gray-text mt-1 font-weight-bold">
                       {{hotel.rating}}-<ng-container *ngIf="hotel.rating <=2" i18n="@@oneStar">star</ng-container><ng-container *ngIf="hotel.rating >2" i18n="@@stars">star</ng-container>
                    </span>
                      </div>




                      <div class="address gray-text-dark mt-3">{{hotel.address? hotel.address :
                        ''}}, {{item.caption}}

                      </div>

                    </div>

                    <div class="google-review-cont d-inline-block p-3 mt-3 rounded border"
                         *ngIf="hotel.googleReview">
                      <div class="text-medium font-weight-bold gray-primaray-text align-middle d-inline-block">
                        {{hotel.googleReview.rating}}
                      </div>
                      <div class="rating-stars-google d-inline-block">
                        <div class="rating-active"
                             [ngStyle]="getWidth(hotel.googleReview.rating)"></div>
                      </div>

                      <br/>

                      <div class="text-sm gray-text-dark d-inline-block font-weight-bold separater-dot">
                        {{hotel.googleReview.ratingDescription}}
                      </div>
                      <span class="blue-text text-sm d-inline-block"><span
                              [ngClass]="{'mr-1': dataService.isSiteLtr(), 'ml-1': dataService.isSiteRtl()}"> {{hotel.googleReview.userRatingsTotal | number:'':'en-US'}}</span>reviews</span>
                    </div>


                  </div>
                </div>
                <div class="col-md-1 col-lg-1">
                  <div class="hotelShare d-none d-md-block user_menu dropdown"  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    <!--<span><i  (click)="this.dataService.shareAllFavHotels(hotel.id, false)" class="icon-share fav"></i></span>-->
                    <span><i  (click)="openHotelSharePopUp(hotel);" class="icon-share fav"></i></span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




      </div>
    </div>

  </div>
  <div *ngIf="dataService.mobileView">
    <div *ngFor="let item of this.dataService.favoritesList;let i = index" >
      <div class="row">
        <div class="col-10" [ngClass]="{'pr-0': dataService.isSiteLtr(), 'pl-0': dataService.isSiteRtl()}">
          <p class="font-18-to-14 sharp-font bolder gray-primaray-text">
            {{item.caption}}
          </p>
        </div>
        <div class="col-2 hotelShare user_menu dropdown d-flex" [ngClass]="{'pl-2': dataService.isSiteLtr(), 'pr-2': dataService.isSiteRtl()}">
          <span [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"><!--<i (click)="this.dataService.shareAllFavHotels(item.caption, true)" class="icon-share2 fav blue-text"
                   [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>--></span>
          <span (click)="trashAllFavHotels(item.caption)"><i class="icon-trash fav blue-text"></i></span>
        </div>

      </div>

      <div class=" mb-4 hotel-item rounded border overflow-hidden" *ngFor="let hotel of item.items;let y = index">


        <div class="row">

          <div class="col-md-5 col-lg-4" [ngClass]="{'pr-lg-0': dataService.isSiteLtr(), 'pl-lg-0': dataService.isSiteRtl()}">


            <div class="hotel_image ">
              <a target="_blank"
                 [routerLink]="[hotelNavigateUrl]" [queryParams]="{searchQuery: hotel.searchQuery}"
                 class="hotel_image-mobile text-white">
                <img width="1000" height="667" loading="lazy" [ngClass]="{'rounded-left': dataService.isSiteLtr(), 'rounded-right': dataService.isSiteRtl()}"
                     [src]="hotel.imageUrl || 'assets/img/no_image.png'">
              </a>
              <div class="favorite-heart" class="favorite-heart" (click)="this.dataService.toggleFavoriteHotel(hotel.id); isFav[''+i+y] = !isFav[''+i+y]">
                                                         <span class="position-absolute font-20"
                                                               [ngClass]="{'icon-colored-heart' : isFav[''+i+y], 'icon-heart' : !isFav[''+i+y] }">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                  </span>
              </div>
            <!--  <div (click)="this.dataService.shareAllFavHotels(hotel.id, false)">
                                                <span>
                                                  <i class="icon-share position-absolute font-16 pt-1 text-white"></i>
                                                </span>
              </div>-->
              <div (click)="openHotelSharePopUp(hotel);">
                                                <span>
                                                  <i class="icon-share position-absolute font-16 pt-1 text-white"></i>
                                                </span>
              </div>

              <div class="google-review-cont d-inline-block position-absolute"
                   *ngIf="hotel.googleReview">
                <div class="text-medium font-weight-bold gray-primaray-text align-middle d-inline-block text-white">
                  {{hotel.googleReview.rating}}&nbsp;
                </div>

                <div class="rating-stars-google d-inline-block">
                  <div class="rating-active"
                       [ngStyle]="getWidth(hotel.googleReview.rating)"></div>
                </div>
                <span class="text-white text-sm d-inline-block"><span
                        [ngClass]="{'mr-1': dataService.isSiteLtr(), 'ml-1': dataService.isSiteRtl()}"> &nbsp;({{hotel.googleReview.userRatingsTotal | number:'':'en-US'}}</span>reviews)</span>
              </div>

            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="pt-4 pb-4" [ngClass]="{'pr-4': dataService.isSiteLtr(), 'pl-4': dataService.isSiteRtl()}">
              <div class="row">
                <div class="col-lg-11 col-md-11">

                  <div class="hotel_info" [ngClass]="{'pl-3 pl-lg-1': dataService.isSiteLtr(), 'pr-3 pr-lg-1': dataService.isSiteRtl()}">
                    <div class="teaser_top">

                      <div class="d-flex">
                        <a class="primaray-text font-16 bolder" [routerLink]="[hotelNavigateUrl]" [queryParams]="{searchQuery: hotel.searchQuery}">
                          {{hotel.name}}&nbsp;.&nbsp; </a>   <span *ngIf="hotel.rating" class="hotel-stars-text text-nowrap gray-text mt-1 font-weight-bold">
                       {{hotel.rating}}-<ng-container *ngIf="hotel.rating <=2" i18n="@@oneStar">star</ng-container><ng-container *ngIf="hotel.rating >2" i18n="@@stars">star</ng-container>
                    </span>



                      </div>




                      <div class="address gray-text-dark mt-2">{{hotel.address? hotel.address :
                        ''}}, {{item.caption}}

                      </div>

                    </div>


                  </div>
                </div>
                <div class="col-md-1 col-lg-1">
                  <div class="hotelShare d-none d-md-block user_menu dropdown" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




      </div>
    </div>

  </div>
</div>
<!--<div id="snackbar" [ngClass]="{'active': this.dataService.copyFlag, 'add-bottom-value' : this.dataService.copyFlag && this.dataService.showCookiesMsg}"  class="snackbar font-14"> <p class="text-center">Your favorite hotels link is ready to be shared!</p></div>-->
