<div class="flight-booking-form">
  <h1
    class="green-text bolder mb-md-2 mb-3"
    [ngClass]="{ 'px-3': Service.mobileView }"
  >
    <i class="icon-locked"></i
    ><ng-container i18n="@@secureBooking">Secure Booking</ng-container
    >&nbsp;<span class="smaller"
      ><ng-container i18n="@@almostDone"
        >(You are almost done)</ng-container
      ></span
    >
  </h1>
  <h4 class="orange-text mb-2" [ngClass]="{ 'px-3': Service.mobileView }">
    <i class="icon-warning"></i>&nbsp;<ng-container i18n="@@actFast"
      >Act fast! Pricing and availability may change!</ng-container
    >
  </h4>

  <div *ngIf="Service.flightNewData && Service.mobileView">
    <!----------->
    <div class="container" *ngIf="summaryDetailsDepart">
      <div class="pointer rounded border mx-0">
        <div class="pt-4 px-3 pb-4 h-100">
          <div class="d-flex">
            <div
              [ngClass]="{
                'pr-2': Service.isSiteLtr(),
                'pl-2': Service.isSiteRtl()
              }"
            >
              <div class="d-flex">
                <div
                  [ngClass]="{
                    'pr-0': Service.isSiteLtr(),
                    'pl-0': Service.isSiteRtl()
                  }"
                >
                  <img
                    width="26.5"
                    height="27"
                    loading="lazy"
                    class="mobile-img initial-height pointer"
                    [src]="
                      summaryDetailsDepart.airlineInfoSummary
                        .marketingAirlineLogoUrl
                    "
                    onError="this.src='/assets/img/airline.png'"
                  />
                </div>
                <div
                  [ngClass]="{
                    'pl-2': Service.isSiteLtr(),
                    'pr-2': Service.isSiteRtl()
                  }"
                >
                  <p class="primaray-text font-weight-bold mb-0">
                    <span
                      class="mb-0 primaray-text"
                      [innerHTML]="
                        this.Service.formatDateTime12Hours(
                          summaryDetailsDepart.departureDate
                        )
                      "
                      >10:15pm</span
                    >
                    -
                    <span
                      class="mb-0 primaray-text"
                      [innerHTML]="
                        this.Service.formatDateTime12Hours(
                          summaryDetailsDepart.arrivalDate
                        )
                      "
                      >10:15pm</span
                    >
                    <span
                      class="font-12 font-weight-normal"
                      *ngIf="
                        Service.checkNextDay(
                          summaryDetailsDepart.departureDate,
                          summaryDetailsDepart.arrivalDate
                        ) === 1
                      "
                      i18n="@@nextDay"
                    >
                      (Next day)</span
                    >
                    <span
                      class="font-12 font-weight-normal"
                      *ngIf="
                        Service.checkNextDay(
                          summaryDetailsDepart.departureDate,
                          summaryDetailsDepart.arrivalDate
                        ) > 1
                      "
                    >
                      +{{
                        Service.checkNextDay(
                          summaryDetailsDepart.departureDate,
                          summaryDetailsDepart.arrivalDate
                        )
                      }}</span
                    >
                    <span
                      class="font-12 font-weight-normal"
                      *ngIf="
                        Service.checkPreviousDay(
                          summaryDetailsDepart.departureDate,
                          summaryDetailsDepart.arrivalDate
                        )
                      "
                      i18n="@@prevDay"
                    >
                      (Previous day)</span
                    >
                  </p>
                  <p class="gray-text-dark text-nowrap">
                    <span>{{ summaryDetailsDepart.departureAirport.iata }}</span
                    ><span class="super-vertical-align"> ____ </span
                    ><span>{{ summaryDetailsDepart.arrivalAirport.iata }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                'ml-auto text-right': Service.isSiteLtr(),
                'mr-auto text-left': Service.isSiteRtl()
              }"
            >
              <p
                class="mb-0 primaray-text"
                [ngClass]="{ 'max-width-content': Service.isSiteLtr() }"
              >
                <span
                  [innerHTML]="
                    this.Service.calDuration(
                      summaryDetailsDepart.journeyDurationInMins
                    )
                  "
                  >6h 45m</span
                >&nbsp;
                <span *ngIf="summaryDetailsDepart.totalStops === 1"
                  >(<ng-container i18n="@@oneStop">1 stop</ng-container>)</span
                >
                <span *ngIf="summaryDetailsDepart.totalStops === 2"
                  >(<ng-container i18n="@@twoStops">2 stops</ng-container
                  >)</span
                >
                <span *ngIf="summaryDetailsDepart.totalStops > 2">
                  ({{ summaryDetailsDepart.totalStops }}&nbsp;<ng-container
                    i18n="@@stops"
                    >stops</ng-container
                  >)</span
                >
              </p>
              <div
                *ngFor="
                  let stop of summaryDetailsDepart.stopSummaries;
                  let i = index
                "
              >
                <p class="gray-text-dark mb-0">
                  <span
                    [innerHTML]="
                      this.Service.calDuration(stop.stopDurationInMinutes)
                    "
                  ></span
                  >&nbsp;<ng-container i18n="@@in">in</ng-container
                  >&nbsp;<span>{{ stop.airport.iata }}</span>
                </p>
              </div>
              <p
                class="green-text"
                *ngIf="summaryDetailsDepart.totalStops === 0"
                i18n="@@nonStop"
              >
                Nonstop
              </p>
            </div>
          </div>
          <div
            class="font-12"
            [ngClass]="{
              'p-left-round-trip': Service.flightData.trip === 'roundtrip',
              'p-left-one-way': Service.flightData.trip === 'oneway'
            }"
          >
            <p
              class="mb-0 gray-text-dark"
              *ngIf="
                summaryDetailsDepart.airlineInfoSummary.marketingAirlines
                  .length === 1
              "
            >
              {{
                summaryDetailsDepart.airlineInfoSummary.marketingAirlines[0]
              }}.
              {{
                Service.flightData.details.originDestinationOptionsGroups[0]
                  .originDestinationOptions[0].flightSegment.cabinClassName
              }}
            </p>
            <p
              class="mb-0 gray-text-dark"
              *ngIf="
                summaryDetailsDepart.airlineInfoSummary.marketingAirlines
                  .length > 1
              "
            >
              <span i18n="@@multipleAirlines">Multiple Airlines: </span>
              <span
                *ngFor="
                  let airlineItem of summaryDetailsDepart.airlineInfoSummary
                    .marketingAirlines
                "
              >
                {{ airlineItem }}.
              </span>
              {{
                Service.flightData.details.originDestinationOptionsGroups[0]
                  .originDestinationOptions[0].flightSegment.cabinClassName
              }}
            </p>
            <div
              class="gray-text"
              *ngIf="summaryDetailsDepart.airlineInfoSummary.operatedBy.length"
            >
              <p
                class="mb-0"
                *ngFor="
                  let operator of summaryDetailsDepart.airlineInfoSummary
                    .operatedBy;
                  let indexOperator = index
                "
              >
                {{ operator }}
              </p>
            </div>
          </div>

          <div
            class="d-flex pt-3"
            *ngIf="Service.flightData.trip === 'roundtrip'"
          >
            <div
              [ngClass]="{
                'pr-2': Service.isSiteLtr(),
                'pl-2': Service.isSiteRtl()
              }"
            >
              <div class="d-flex">
                <div
                  [ngClass]="{
                    'pr-0': Service.isSiteLtr(),
                    'pl-0': Service.isSiteRtl()
                  }"
                >
                  <img
                    width="26.5"
                    height="27"
                    loading="lazy"
                    class="mobile-img initial-height pointer"
                    [src]="
                      summaryDetailsReturn.airlineInfoSummary
                        .marketingAirlineLogoUrl
                    "
                    onError="this.src='/assets/img/airline.png'"
                  />
                </div>
                <div
                  [ngClass]="{
                    'pl-2': Service.isSiteLtr(),
                    'pr-2': Service.isSiteRtl()
                  }"
                >
                  <p class="primaray-text font-weight-bold mb-0">
                    <span
                      class="mb-0 primaray-text"
                      [innerHTML]="
                        this.Service.formatDateTime12Hours(
                          summaryDetailsReturn.departureDate
                        )
                      "
                      >10:15pm</span
                    >
                    -
                    <span
                      class="mb-0 primaray-text"
                      [innerHTML]="
                        this.Service.formatDateTime12Hours(
                          summaryDetailsReturn.arrivalDate
                        )
                      "
                      >10:15pm</span
                    >
                    <span
                      class="font-12 font-weight-normal"
                      *ngIf="
                        Service.checkNextDay(
                          summaryDetailsReturn.departureDate,
                          summaryDetailsReturn.arrivalDate
                        ) === 1
                      "
                      i18n="@@nextDay"
                    >
                      (Next day)</span
                    >
                    <span
                      class="font-12 font-weight-normal"
                      *ngIf="
                        Service.checkNextDay(
                          summaryDetailsReturn.departureDate,
                          summaryDetailsReturn.arrivalDate
                        ) > 1
                      "
                    >
                      +{{
                        Service.checkNextDay(
                          summaryDetailsReturn.departureDate,
                          summaryDetailsReturn.arrivalDate
                        )
                      }}</span
                    >
                    <span
                      class="font-12 font-weight-normal"
                      *ngIf="
                        Service.checkPreviousDay(
                          summaryDetailsReturn.departureDate,
                          summaryDetailsReturn.arrivalDate
                        )
                      "
                      i18n="@@prevDay"
                    >
                      (Previous day)</span
                    >
                  </p>
                  <p class="gray-text-dark text-nowrap">
                    <span>{{ summaryDetailsReturn.departureAirport.iata }}</span
                    ><span class="super-vertical-align"> ____ </span
                    ><span>{{ summaryDetailsReturn.arrivalAirport.iata }}</span>
                  </p>
                </div>
              </div>
            </div>

            <div
              [ngClass]="{
                'ml-auto text-right': Service.isSiteLtr(),
                'mr-auto text-left': Service.isSiteRtl()
              }"
            >
              <p
                class="mb-0 primaray-text"
                [ngClass]="{ 'max-width-content': Service.isSiteLtr() }"
              >
                <span
                  [innerHTML]="
                    this.Service.calDuration(
                      summaryDetailsReturn.journeyDurationInMins
                    )
                  "
                  >6h 45m</span
                >
                <span *ngIf="summaryDetailsReturn.totalStops === 1"
                  >(<ng-container i18n="@@oneStop">1 stop</ng-container>)</span
                >
                <span *ngIf="summaryDetailsReturn.totalStops === 2"
                  >(<ng-container i18n="@@twoStops">2 stops</ng-container
                  >)</span
                >
                <span *ngIf="summaryDetailsReturn.totalStops > 2">
                  ({{ summaryDetailsReturn.totalStops }}&nbsp;<ng-container
                    i18n="@@stops"
                    >stops</ng-container
                  >)</span
                >
              </p>
              <div
                *ngFor="
                  let stop of summaryDetailsReturn.stopSummaries;
                  let i = index
                "
              >
                <p class="gray-text-dark mb-0">
                  <span
                    [innerHTML]="
                      this.Service.calDuration(stop.stopDurationInMinutes)
                    "
                  ></span
                  >&nbsp;<ng-container i18n="@@in">in</ng-container
                  >&nbsp;<span>{{ stop.airport.iata }}</span>
                </p>
              </div>
              <p
                class="green-text"
                *ngIf="summaryDetailsReturn.totalStops === 0"
                i18n="@@nonStop"
              >
                Nonstop
              </p>
            </div>
          </div>
          <div
            class="font-12"
            *ngIf="Service.flightData.trip === 'roundtrip'"
            [ngClass]="{
              'p-left-round-trip': Service.flightData.trip === 'roundtrip',
              'p-left-one-way': Service.flightData.trip === 'oneway'
            }"
          >
            <p
              class="mb-0 gray-text-dark"
              *ngIf="
                summaryDetailsReturn.airlineInfoSummary.marketingAirlines
                  .length === 1
              "
            >
              {{
                summaryDetailsReturn.airlineInfoSummary.marketingAirlines[0]
              }}.
              {{
                Service.flightData.details.originDestinationOptionsGroups[1]
                  .originDestinationOptions[0].flightSegment.cabinClassName
              }}
            </p>
            <p
              class="mb-0 gray-text-dark"
              *ngIf="
                summaryDetailsReturn.airlineInfoSummary.marketingAirlines
                  .length > 1
              "
            >
              <span i18n="@@multipleAirlines">Multiple Airlines: </span>
              <span
                *ngFor="
                  let airlineItem of summaryDetailsReturn.airlineInfoSummary
                    .marketingAirlines
                "
              >
                {{ airlineItem }}.
              </span>
              {{
                Service.flightData.details.originDestinationOptionsGroups[0]
                  .originDestinationOptions[0].flightSegment.cabinClassName
              }}
            </p>
            <div
              class="gray-text"
              *ngIf="summaryDetailsReturn.airlineInfoSummary.operatedBy.length"
            >
              <p
                class="mb-0"
                *ngFor="
                  let operator of summaryDetailsReturn.airlineInfoSummary
                    .operatedBy;
                  let indexOperator = index
                "
              >
                {{ operator }}
              </p>
            </div>
          </div>
        </div>
        <p
          class="mt-3"
          [ngClass]="{
            'pl-3': Service.isSiteLtr(),
            'pr-3': Service.isSiteRtl()
          }"
        >
          <span
            class="blue-text pointer"
            (click)="
              showDetails(Service.flightData.details, Service.flightData.trip)
            "
            i18n="@@showTripDetails"
            >Show trip details</span
          >
        </p>
      </div>
    </div>
  </div>

  <form
    [formGroup]="Service.flightBookingForm"
    (ngSubmit)="onSubmit()"
    id="bookingForm"
  >
    <div
      class="section-info mb-4 mt-4"
      [ngClass]="{ radius: Service.desktopView }"
    >
      <div class="section-header">
        <span class="primaray-text font-weight-bold"
          ><i class="icon-user"></i>&nbsp;<ng-container i18n="@@travelerDetails"
            >Traveler Details</ng-container
          ></span
        >
      </div>

      <div class="section-body p-5">
        <h5 class="bolder font-16">
          <ng-container i18n="@@traveler1Adult">Traveler 1: Adult</ng-container
          >&nbsp;<span class="font-14" i18n="@@primaryContact"
            >(Primary contact)</span
          >
        </h5>
        <!--   <p class="font-14 gray-text">Traveler names must match government-issued photo ID exactly.</p>-->

        <div
          class="row gutters-10 mb-4"
          *ngIf="this.travelerId && this.travelerId !== 'guest'"
        >
          <div class="col-sm-6">
            <div class="d-flex align-items-center form-control">
              {{ traveler?.travelerName }}
            </div>
          </div>
        </div>
        <div
          class="row gutters-10"
          *ngIf="this.travelerId && this.travelerId === 'guest'"
        >
          <div
            class="form-group col-sm-6 col-md-6"
            [ngClass]="{
              'pr-lg-2': Service.isSiteLtr(),
              'pl-lg-2': Service.isSiteRtl()
            }"
          >
            <input
              pattern="^[A-Za-z -]+$"
              class="form-control"
              formControlName="FirstName"
              type="text"
              name="FirstName"
              i18n-placeholder="@@firstNamePlaceHolder"
              placeholder="First Name"
              [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }"
            />

            <div
              *ngIf="
                submitted && f.FirstName.errors && f.FirstName.errors.required
              "
              class="invalid-feedback"
              i18n="@@requiredFirstNameError"
            >
              First Name is required
            </div>
          </div>

          <div
            class="form-group col-sm-6 col-md-6"
            [ngClass]="{
              'pr-lg-2': Service.isSiteLtr(),
              'pl-lg-2': Service.isSiteRtl()
            }"
          >
            <input
              pattern="^[A-Za-z -]+$"
              class="form-control"
              formControlName="LastName"
              type="text"
              name="LastName"
              i18n-placeholder="@@lastNamePlaceHolder"
              placeholder="Last Name"
              [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }"
            />
            <div
              *ngIf="
                submitted && f.LastName.errors && f.LastName.errors.required
              "
              class="invalid-feedback"
              i18n="@@requiredLastNameError"
            >
              Last name is required
            </div>
          </div>
        </div>
        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@emailAddress"
            >
              Email address
            </p>
            <div class="form-group with-icon">
              <input
                class="form-control"
                formControlName="email"
                type="text"
                name="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                autocomplete="off"
              />
              <i class="icon-email"></i>
            </div>
            <div
              *ngIf="submitted && f.email.errors"
              class="smaller text-danger"
            >
              <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">
                email is required
              </div>
              <div *ngIf="f.email.errors.email" i18n="@@invalidEmail">
                Invalid email
              </div>
            </div>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@countryCode"
            >
              Country code
            </p>
            <div ngbDropdown class="form-group">
              <div
                ngbDropdownToggle
                class="form-control select-country-code clickable-cursor select-custom-arrow"
                (click)="this.Service.SelectCountryCode()"
              >
                {{ this.Service.defultCountry.code }} ({{
                  this.Service.defultCountry.dial_code
                }})
              </div>
              <div
                class="country-code-dropdown"
                *ngIf="this.Service.showCountryDrop"
                ngbDropdownMenu
              >
                <app-country-code></app-country-code>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@phoneNumber"
            >
              Phone number
            </p>
            <div class="form-group with-icon">
              <input
                class="form-control"
                formControlName="phone"
                pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
                type="tel"
                name="phone"
                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
              />
              <i class="icon-phone"></i>
            </div>
            <div
              *ngIf="submitted && f.phone.errors"
              class="smaller text-danger"
            >
              <div *ngIf="f.phone.errors.required" i18n="@@phoneNumberRequired">
                Phone number is required
              </div>
              <div
                *ngIf="f.phone.errors && !f.phone.errors.required"
                i18n="@@invalidPhoneNumber"
              >
                Invalid phone number
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@gender"
            >
              Gender
            </p>
            <select
              class="form-control mt-2 select-custom-arrow"
              formControlName="gender"
              name="gender"
              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
            >
              <option value="{{ item.id }}" *ngFor="let item of genderArray">
                {{ item.name }}
              </option>
            </select>
            <div
              *ngIf="submitted && f.gender.errors"
              class="smaller text-danger"
            >
              <div *ngIf="f.gender.errors.required" i18n="@@genderRequired">
                Gender is required
              </div>
            </div>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p class="font-16 gray-text-dark mb-2 mt-3 required" i18n="@@dob">
              Date of birth
            </p>
            <div class="row date-of-birth">
              <div class="col-5 position-relative">
                <select
                  class="form-control select-custom-arrow"
                  formControlName="monthOfBirth"
                  name="months"
                  (change)="changeMonth()"
                  [ngClass]="{
                    'is-invalid': submitted && f.monthOfBirth.errors,
                    'placeholder-text': !selectedMonth
                  }"
                >
                  <option
                    class="placeholder-text"
                    value="null"
                    disabled
                    i18n="@@month"
                  >
                    Month
                  </option>
                  <option
                    value="{{ item.id }}"
                    *ngFor="let item of monthsArray"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <!--<span *ngIf="!selectedMonth" class="gray-text position-absolute label-DOB">Month</span>-->
              </div>
              <div class="col-3 px-0 position-relative">
                <select
                  class="form-control select-custom-arrow"
                  formControlName="dayOfBirth"
                  name="days"
                  (change)="changeDay()"
                  [ngClass]="{
                    'is-invalid': submitted && f.dayOfBirth.errors,
                    'placeholder-text': !selectedDay
                  }"
                >
                  <option
                    class="placeholder-text"
                    value="null"
                    disabled
                    i18n="@@day"
                  >
                    Day
                  </option>
                  <option value="{{ item.id }}" *ngFor="let item of daysArray">
                    {{ item.id }}
                  </option>
                </select>
                <!-- <span *ngIf="!selectedDay" class="gray-text position-absolute label-DOB day">Day</span>-->
              </div>
              <div class="col-4 position-relative">
                <select
                  class="form-control select-custom-arrow"
                  formControlName="yearOfBirth"
                  name="years"
                  (change)="changeYear()"
                  [ngClass]="{
                    'is-invalid': submitted && f.yearOfBirth.errors,
                    'placeholder-text': !selectedYear
                  }"
                >
                  <option
                    class="placeholder-text"
                    value="null"
                    disabled
                    i18n="@@year"
                  >
                    Year
                  </option>
                  <option value="{{ item }}" *ngFor="let item of yearArray">
                    {{ item }}
                  </option>
                </select>
                <!-- <span *ngIf="!selectedYear"  class="gray-text position-absolute label-DOB year">Year</span>-->
              </div>
            </div>
            <div
              *ngIf="
                submitted &&
                ((f.monthOfBirth.errors && f.monthOfBirth.errors.required) ||
                  (f.dayOfBirth.errors && f.dayOfBirth.errors.required) ||
                  (f.yearOfBirth.errors && f.yearOfBirth.errors.required))
              "
              class="smaller text-danger"
              i18n="@@dobRequired"
            >
              Date of birth is required
            </div>
            <p class="smaller text-danger">{{ bodErrorMsg }}</p>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@passportCountry"
            >
              Passport issuing country
            </p>
            <div class="form-type-select form-name-countrycode">
              <!--  <label class="font-weight-bold font-16"  for="countryCode">Select a country:</label>-->
              <select
                name="countryCode"
                formControlName="passportIssueCountryCode"
                class="form-control select-custom-arrow placeholder-text-field"
                (change)="passportCountryChange($event)"
                [ngClass]="{
                  'is-invalid': submitted && f.passportIssueCountryCode.errors
                }"
              >
                <!--  <ng-container *ngFor="let country of vars.countries; let i = index">
                <option *ngIf="selectedPassportIssueCountryCode && selectedPassportIssueCountryCode === country.code" [value]="country.code" [selected]="true">{{country.name}}</option>
                <option *ngIf="!selectedPassportIssueCountryCode || selectedPassportIssueCountryCode !== country.code" [value]="country.code">{{country.name}}</option>

              </ng-container>-->
                <option
                  *ngFor="let country of vars.countries; let i = index"
                  [value]="country.code"
                >
                  {{ country.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.passportIssueCountryCode.errors"
                class="smaller text-danger"
              >
                <div
                  *ngIf="f.passportIssueCountryCode.errors.required"
                  i18n="@@passportCountryRequired"
                >
                  Passport issuing country is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@passportNumber"
            >
              Passport Number
            </p>

            <input
              class="form-control"
              formControlName="passportNumber"
              type="text"
              name="passportNumber"
              [ngClass]="{ 'is-invalid': submitted && f.passportNumber.errors }"
              autocomplete="off"
            />

            <div
              *ngIf="submitted && f.passportNumber.errors"
              class="smaller text-danger"
            >
              <div
                *ngIf="f.passportNumber.errors.required"
                i18n="@@passportNumberRequired"
              >
                Passport number is required
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3 required"
              i18n="@@passportExpiryDate"
            >
              Passport expiry date
            </p>

            <!--  <div class="form-group mb-0 border with-icon calendar-item overflow-hidden text-nowrap">
              <div class="calendar-value-cont font-14">
                     <span class=" d-inline-block"><span>{{passportExpiryFormatted}}</span></span>
              </div>
              <input type="text" id="passportExpiry" class="form-control form-control-sm" name="passportExpiry">
            </div>-->

            <!--       <mat-form-field>-->
            <div
              class="form-control position-relative"
              [ngClass]="{ 'is-invalid': submitted && f.passportExpiry.errors }"
            >
              <input
                formControlName="passportExpiry"
                matInput
                [matDatepicker]="picker"
                [min]="minDate"
                class="form-control pointer"
                autocomplete="off"
                (click)="picker.open()"
                (dateChange)="formatExpiryDate('change', $event)"
                style="opacity: 0"
              />
              <span
                class="position-absolute format-date"
                (click)="picker.open()"
                >{{ formatExpiryDateText }}</span
              >
              <mat-datepicker #picker></mat-datepicker>
              <!--    </mat-form-field>-->
            </div>
            <div
              *ngIf="submitted && f.passportExpiry.errors"
              class="smaller text-danger"
            >
              <div
                *ngIf="f.passportExpiry.errors.required"
                i18n="@@passportExpiryDateRequired"
              >
                Passport expiry date is required
              </div>
            </div>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p
              class="font-16 gray-text-dark mb-2 mt-3"
              i18n="@@flyerProgramOne"
            >
              Frequent flyer program
            </p>
            <select
              class="form-control mt-2 select-custom-arrow mb-3"
              formControlName="flyerProgram"
              name="flyerProgram"
              (change)="programChange($event)"
              (focus)="getFrequentFlyerPrograms()"
            >
              <option [value]="item.code" *ngFor="let item of programsList">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row gutters-10">
          <div class="col-sm-6">
            <p class="font-16 gray-text-dark mb-2 mt-3" i18n="@@programNumber">
              Program number
            </p>

            <input
              class="form-control"
              formControlName="programNumber"
              name="programNumber"
              [ngClass]="{ 'is-invalid': submitted && f.programNumber.errors }"
            />
          </div>
        </div>

        <!-- <div class="row gutters-10" >
        <div class="form-group col-sm-6 col-md-6" [ngClass]="{'pr-lg-2': Service.isSiteLtr(), 'pl-lg-2': Service.isSiteRtl()}">
          <p class="font-16 gray-text-dark mb-2 mt-3" i18n="@@mealRequest">Meal request</p>
          <input class="form-control"  formControlName="mealRequest"   type="text" name="mealRequest"/>
          <p class="font-12 gray-text" i18n="@@flightBookingSpecial">Upon availability. Please contact the airline to confirm special assistance requests. </p>
        </div>

      </div> -->

        <div
          class="border-top pt-5 mt-5"
          *ngFor="
            let item of [].constructor(otherAdultsTravelers);
            let i = index
          "
        >
          <h5 class="bolder font-16" i18n="@@travelerXAdult">
            Traveler {{ i + 2 }}: Adult
          </h5>
          <p class="font-14 gray-text" i18n="@@flightBookingMsgPassport">
            Traveler names must match government-issued photo ID exactly.
          </p>
          <div class="row gutters-10">
            <div
              class="form-group col-sm-6 col-md-6"
              [ngClass]="{
                'pr-lg-2': Service.isSiteLtr(),
                'pl-lg-2': Service.isSiteRtl()
              }"
            >
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@firstNamePlaceHolder"
              >
                First name
              </p>
              <input
                pattern="^[A-Za-z -]+$"
                class="form-control"
                formControlName="FirstName{{ i }}"
                autocomplete="off"
                type="text"
                name="FirstName{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('FirstName' + i)
                }"
              />
              <div
                *ngIf="submitted && !isValid('FirstName' + i)"
                class="smaller text-danger"
                i18n="@@requiredFirstNameError"
              >
                First Name is required
              </div>
            </div>

            <div
              class="form-group col-sm-6 col-md-6"
              [ngClass]="{
                'pr-lg-2': Service.isSiteLtr(),
                'pl-lg-2': Service.isSiteRtl()
              }"
            >
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@lastNamePlaceHolder"
              >
                Last name
              </p>
              <input
                pattern="^[A-Za-z -]+$"
                class="form-control"
                formControlName="LastName{{ i }}"
                type="text"
                name="LastName{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('LastName' + i)
                }"
              />
              <div
                *ngIf="submitted && !isValid('LastName' + i)"
                class="smaller text-danger"
                i18n="@@requiredLastNameError"
              >
                Last name is required
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@gender"
              >
                Gender
              </p>
              <select
                class="form-control mt-2 select-custom-arrow"
                formControlName="gender{{ i }}"
                name="gender{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('gender' + i)
                }"
              >
                <option value="{{ item.id }}" *ngFor="let item of genderArray">
                  {{ item.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && !isValid('gender' + i)"
                class="smaller text-danger"
                i18n="@@genderRequired"
              >
                Gender is required
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p class="font-16 gray-text-dark mb-2 mt-3 required" i18n="@@dob">
                Date of birth
              </p>
              <div class="row date-of-birth">
                <div class="col-5 position-relative">
                  <select
                    class="form-control select-custom-arrow"
                    formControlName="monthOfBirth{{ i }}"
                    name="months{{ i }}"
                    (change)="adultsChangeMonth(i)"
                    [ngClass]="{
                      'is-invalid': submitted && !isValid('monthOfBirth' + i),
                      'placeholder-text': !adultsSelectedMonth[i]
                    }"
                  >
                    <option
                      class="placeholder-text"
                      value="null"
                      disabled
                      i18n="@@month"
                    >
                      Month
                    </option>
                    <option
                      value="{{ item.id }}"
                      *ngFor="let item of monthsArray"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <span
                    *ngIf="!adultsSelectedMonth[i]"
                    class="gray-text position-absolute label-DOB"
                    i18n="@@month"
                    >Month</span
                  >
                </div>
                <div class="col-3 px-0 position-relative">
                  <select
                    class="form-control select-custom-arrow"
                    formControlName="dayOfBirth{{ i }}"
                    name="days{{ i }}"
                    (change)="adultsChangeDay(i)"
                    [ngClass]="{
                      'is-invalid': submitted && !isValid('dayOfBirth' + i),
                      'placeholder-text': !adultsSelectedDay[i]
                    }"
                  >
                    <option
                      class="placeholder-text"
                      value="null"
                      disabled
                      i18n="@@day"
                    >
                      Day
                    </option>
                    <option
                      value="{{ item.id }}"
                      *ngFor="let item of daysArray"
                    >
                      {{ item.id }}
                    </option>
                  </select>
                  <span
                    *ngIf="!adultsSelectedDay[i]"
                    class="gray-text position-absolute label-DOB day"
                    i18n="@@day"
                    >Day</span
                  >
                </div>
                <div class="col-4 position-relative">
                  <select
                    class="form-control select-custom-arrow"
                    formControlName="yearOfBirth{{ i }}"
                    name="years{{ i }}"
                    (change)="adultsChangeYear(i)"
                    [ngClass]="{
                      'is-invalid': submitted && !isValid('yearOfBirth' + i),
                      'placeholder-text': !adultsSelectedYear[i]
                    }"
                  >
                    <option
                      class="placeholder-text"
                      value="null"
                      disabled
                      i18n="@@year"
                    >
                      Year
                    </option>
                    <option value="{{ item }}" *ngFor="let item of yearArray">
                      {{ item }}
                    </option>
                  </select>
                  <span
                    *ngIf="!adultsSelectedYear[i]"
                    class="gray-text position-absolute label-DOB year"
                    i18n="@@year"
                    >Year</span
                  >
                </div>
              </div>
              <div
                *ngIf="
                  submitted &&
                  (!isValid('monthOfBirth' + i) ||
                    !isValid('dayOfBirth' + i) ||
                    !isValid('yearOfBirth' + i))
                "
                class="smaller text-danger"
                i18n="@@dobRequired"
              >
                Date of birth is required
              </div>
              <p class="smaller text-danger">{{ adultsDOBErrorMsg[i] }}</p>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@passportCountry"
              >
                Passport issuing country
              </p>
              <div class="form-type-select form-name-countrycode">
                <!--  <label class="font-weight-bold font-16"  for="countryCode">Select a country:</label>-->
                <select
                  name="countryCode{{ i }}"
                  formControlName="passportIssueCountryCode{{ i }}"
                  class="form-control select-custom-arrow placeholder-text-field"
                  (change)="passportCountryChange($event)"
                  [ngClass]="{
                    'is-invalid':
                      submitted && !isValid('passportIssueCountryCode' + i)
                  }"
                >
                  <ng-container
                    *ngFor="let country of vars.countries; let i = index"
                  >
                    <option [value]="country.code">{{ country.name }}</option>
                  </ng-container>
                </select>
                <div
                  *ngIf="submitted && !isValid('passportIssueCountryCode' + i)"
                  class="smaller text-danger"
                  i18n="@@passportCountryRequired"
                >
                  Passport issuing country is required
                </div>
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@passportNumber"
              >
                Passport Number
              </p>

              <input
                class="form-control"
                formControlName="passportNumber{{ i }}"
                type="text"
                name="passportNumber{{ i }}"
                [ngClass]="{
                  'is-invalid': submitted && !isValid('passportNumber' + i)
                }"
                autocomplete="off"
              />

              <div
                *ngIf="submitted && !isValid('passportNumber' + i)"
                class="smaller text-danger"
                i18n="@@passportNumberRequired"
              >
                Passport number is required
              </div>
            </div>

            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3 required"
                i18n="@@passportExpiryDate"
              >
                Passport expiry date
              </p>
              <div [formGroupName]="i">
                <!-- must use form group to make different date picker for each user --->
                <div
                  class="form-control position-relative"
                  [ngClass]="{
                    'is-invalid': submitted && !this.adultsExpiryDate[i]
                  }"
                >
                  <input
                    formControlName="adultsPassportExpiry"
                    matInput
                    [matDatepicker]="picker"
                    [min]="minDate"
                    class="form-control pointer"
                    autocomplete="off"
                    (click)="picker.open()"
                    (dateChange)="adultsFormatExpiryDate('change', $event, i)"
                    style="opacity: 0"
                  />
                  <span class="position-absolute format-date">{{
                    adultFormatExpiryDateText[i]
                  }}</span>
                  <mat-datepicker #picker></mat-datepicker>
                  <!--    </mat-form-field>-->
                </div>
              </div>
              <div
                *ngIf="submitted && !this.adultsExpiryDate[i]"
                class="smaller text-danger"
                i18n="@@passportExpiryDateRequired"
              >
                Passport expiry date is required
              </div>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3"
                i18n="@@flyerProgramOne"
              >
                Frequent flyer program
              </p>
              <select
                class="form-control mt-2 select-custom-arrow mb-3"
                formControlName="flyerProgram{{ i }}"
                name="flyerProgram{{ i }}"
                (change)="programChange($event)"
                (focus)="getFrequentFlyerPrograms()"
              >
                <option [ngValue]="item.code" *ngFor="let item of programsList">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row gutters-10">
            <div class="col-sm-6">
              <p
                class="font-16 gray-text-dark mb-2 mt-3"
                i18n="@@programNumber"
              >
                Program number
              </p>

              <input
                class="form-control"
                formControlName="programNumber{{ i }}"
                pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
                name="programNumber{{ i }}"
              />
            </div>
          </div>

          <div class="row gutters-10">
            <div
              class="form-group col-sm-6 col-md-6"
              [ngClass]="{
                'pr-lg-2': Service.isSiteLtr(),
                'pl-lg-2': Service.isSiteRtl()
              }"
            >
              <p class="font-16 gray-text-dark mb-2 mt-3" i18n="@@mealRequest">
                Meal request
              </p>
              <input
                class="form-control"
                formControlName="mealRequest{{ i }}"
                type="text"
                name="mealRequest{{ i }}"
              />
              <p class="font-12 gray-text" i18n="@@flightBookingSpecial">
                Upon availability. Please contact the airline to confirm special
                assistance requests.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-info mb-4" [ngClass]="{ radius: Service.desktopView }">
      <div class="section-header">
        <span class="primaray-text font-weight-bold"
          ><i class="icon-credit-card-alt"></i>&nbsp;<ng-container
            i18n="@@paymentDetails"
            >Payment Details</ng-container
          ></span
        >
      </div>

      <div class="section-body gray-text-dark p-5">
        <div class="row">
          <div
            class="col-md-7"
            [ngClass]="{ 'custom-col-width': this.Service.sideNavState }"
          >
            <h5 i18n="@@choosePaymentMethod">Please choose a payment method</h5>

            <div class="form-type-radio">
              <label for="new_card_payment_method" class="radio-inline">
                <input
                  type="radio"
                  name="payment_method"
                  id="new_card_payment_method"
                  checked="checked"
                  (change)="onItemChange($event)"
                  class="form-checkbox"
                  value="new_card"
                />
                <span i18n="@@creditDebitCard"
                  >Credit / Debit Card
                </span></label
              >
            </div>

            <div
              *ngIf="
                savedCard &&
                savedCard.data &&
                savedCard.data.cards &&
                savedCard.data.cards.length > 0
              "
            >
              <select
                [ngClass]="{
                  'd-block':
                    this.Auth.isLogedIn &&
                    paymentType === 'new_card' &&
                    savedCard.data
                }"
                [ngModelOptions]="{ standalone: true }"
                class="form-control mb-3 d-none"
                (change)="savedCardChange()"
                [(ngModel)]="SelectedsavedCard"
              >
                <ng-container
                  *ngFor="let method of savedCard.data.cards; let i = index"
                >
                  <option
                    *ngIf="method.primary === false"
                    [value]="method.token"
                  >
                    {{ method.alias }} - {{ method.brand }} ending with
                    {{ method.lastFour }}
                  </option>
                </ng-container>
                <ng-container
                  *ngFor="let method of savedCard.data.cards; let i = index"
                >
                  <option
                    *ngIf="method.primary === true"
                    [ngValue]="method.token"
                    [selected]="true"
                  >
                    {{ method.alias }} - {{ method.brand }} ending with
                    {{ method.lastFour }}
                  </option>
                </ng-container>

                <option
                  value="new"
                  [ngClass]="{
                    'd-none':
                      this.travelerId &&
                      this.travelerId !== 'guest' &&
                      this.traveler?.personalPaymentMethodsDisabled
                  }"
                >
                  Use a new payment method
                </option>
              </select>
            </div>

            <div
              class="mt-2"
              [ngClass]="{
                'd-none': paytype === 'savedCard' || paymentType !== 'new_card'
              }"
            >
              <div class="form-group">
                <label *ngIf="this.Service.desktopView">
                  Cardholder Name
                </label>
                <input
                  *ngIf="this.Service.desktopView"
                  type="text"
                  name="HolderName"
                  formControlName="HolderName"
                  class="form-control"
                  placeholder=""
                  autocomplete="off"
                  [ngClass]="{
                    'is-invalid': cardholder || f.HolderName.errors
                  }"
                />
                <input
                  *ngIf="this.Service.mobileView"
                  type="text"
                  name="HolderName"
                  formControlName="HolderName"
                  class="form-control"
                  placeholder="Cardholder Name"
                  autocomplete="off"
                  [ngClass]="{
                    'is-invalid': cardholder || f.HolderName.errors
                  }"
                />

                <div *ngIf="cardholder" class="text-danger font-12 pt-1">
                  Cardholder Name is required
                </div>

                <div *ngIf="f.HolderName.errors" class="smaller text-danger">
                  Invalid Cardholder Name
                </div>
              </div>

              <div class="">
                <p *ngIf="this.Service.desktopView" i18n="@@enterCardDetails">
                  Enter card details here
                </p>
                <div class="form-control">
                  <div id="card-element" class="field"></div>
                </div>

                <div class="font-12 mt-1 text-danger">
                  {{ stripeError }}
                </div>
              </div>
            </div>

            <div
              class="mt-2"
              *ngIf="
                paymentType === 'new_card' &&
                this.Auth.isLogedIn &&
                paytype !== 'savedCard' &&
                !(requiresApprovalData && !submittedApproval)
              "
            >
              <div class="form-item form-type-checkbox form-name-remember-card">
                <input
                  [(ngModel)]="changeRememberMe"
                  [ngModelOptions]="{ standalone: true }"
                  id="remember_card"
                  type="checkbox"
                />
                <label
                  class="gray-text-dark"
                  for="remember_card"
                  i18n="@@rememberPaymentMethod"
                >
                  Remember this payment method
                </label>
              </div>
            </div>

            <div
              class="gray-text font-12 pt-4 pb-4 pb-lg-0"
              *ngIf="
                paymentType === 'new_card' &&
                this.Auth.isLogedIn &&
                paytype !== 'savedCard' &&
                requiresApprovalData &&
                !submittedApproval
              "
              i18n="@@yourCardwillBeSaved"
            >
              Your card will be saved and used once the booking is approved
            </div>

            <div
              class="paypal-text mt-2 d-none"
              [ngClass]="{ 'd-block': paymentType !== 'new_card' }"
              i18n="@@makeSurePaymDetails"
            >
              Make sure to fill all the details then press the checkout button
              at the bottom, you will be redirected to complete the payment.
              After you complete, you will come back to SafarHub to view your
              booking confirmation.
            </div>
          </div>

          <div
            class="col-md-5 border-sm-0"
            [ngClass]="{
              'border-left': Service.isSiteLtr(),
              'border-right': Service.isSiteRtl(),
              'custom-col-width': this.Service.sideNavState
            }"
          >
            <h3 class="gray-primaray-text text-medium" i18n="@@WeAccept">
              We accept
            </h3>
            <ul class="list-inline card-type-list">
              <li class="list-inline-item">
                <img
                  width="48"
                  height="30"
                  loading="lazy"
                  src="assets/img/payment_methods/visa.png"
                  alt="visa"
                />
              </li>
              <li class="list-inline-item">
                <img
                  width="48"
                  height="30"
                  loading="lazy"
                  src="assets/img/payment_methods/mastercard.png"
                  alt="mastercard"
                />
              </li>
            </ul>

            <h3 class="gray-primaray-text text-medium" i18n="@@countOnUs">
              You can count on us
            </h3>
            <ul class="checks">
              <li class="mb-1" i18n="@@secureData">
                Data is transmitted securely
              </li>
              <li class="mb-1" i18n="@@protectInfo">
                We protect your personal information
              </li>
            </ul>

            <div class="d-none d-md-block">
              <a
                (click)="openSSL()"
                style="
                  border: none;
                  margin-bottom: 1rem;
                  zoom: 0.6;
                  cursor: pointer;
                "
                ><img
                  width="130px"
                  src="assets/img/ssl_seal_1.png"
                  alt="Ssl seal"
              /></a>
              <div i18n="@@infoExchanged">
                Information exchanged is encrypted before transmission.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="p-4 mb-4"
      [ngClass]="{
        'rounded border': Service.desktopView,
        'table-header': Service.mobileView
      }"
    >
      <p class="font-16 gray-primaray-text" i18n="@@reviewTermsPolicy">
        Review terms & policy
      </p>
      <p class="gray-text-dark" i18n="@@reviewTicket">
        Please review ticket trip details before completing the payment.
      </p>
      <p class="gray-text-dark" i18n="@@checkTravelerName">
        Check traveler name spelling. Airlines rules require names to exactly
        match government-issued photo ID.
      </p>

      <p class="gray-text-dark mb-0" i18n="@@termsOfBooking">
        Terms of the booking:
      </p>

      <ul
        class="gray-text-dark"
        [ngClass]="{ 'pl-4': Service.isSiteLtr(), 'pr-4': Service.isSiteRtl() }"
      >
        <li>
          {{
            this.Service.flightData.details.originDestinationOptionsGroups[0]
              .summary.departureAirport.iata
          }}
          <span class="text-lowercase" i18n="@@to">to</span>
          {{
            this.Service.flightData.details.originDestinationOptionsGroups[0]
              .summary.arrivalAirport.iata
          }}:
          <span
            class="mb-0 gray-text-dark"
            *ngIf="
              this.Service.flightData.details.originDestinationOptionsGroups[0]
                .summary.airlineInfoSummary.marketingAirlines.length === 1
            "
          >
            {{
              this.Service.flightData.details.originDestinationOptionsGroups[0]
                .summary.airlineInfoSummary.marketingAirlines[0]
            }}.
            {{
              this.Service.flightData.details.originDestinationOptionsGroups[0]
                .originDestinationOptions[0].flightSegment.cabinClassName
            }}
          </span>
          <span
            class="mb-0 gray-text-dark"
            *ngIf="
              this.Service.flightData.details.originDestinationOptionsGroups[0]
                .summary.airlineInfoSummary.marketingAirlines.length > 1
            "
          >
            <span i18n="@@multipleAirlines">Multiple Airlines: </span>
            <span
              *ngFor="
                let airlineItem of this.Service.flightData.details
                  .originDestinationOptionsGroups[0].summary.airlineInfoSummary
                  .marketingAirlines
              "
            >
              {{ airlineItem }}.
            </span>
            {{
              this.Service.flightData.details.originDestinationOptionsGroups[0]
                .originDestinationOptions[0].flightSegment.cabinClassName
            }}
          </span>
        </li>
        <li *ngIf="this.Service.flightData.trip === 'roundtrip'">
          {{
            this.Service.flightData.details.originDestinationOptionsGroups[1]
              .summary.departureAirport.iata
          }}
          <span class="text-lowercase" i18n="@@to">to</span>
          {{
            this.Service.flightData.details.originDestinationOptionsGroups[1]
              .summary.arrivalAirport.iata
          }}:
          <span
            class="mb-0 gray-text-dark"
            *ngIf="
              this.Service.flightData.details.originDestinationOptionsGroups[1]
                .summary.airlineInfoSummary.marketingAirlines.length === 1
            "
          >
            {{
              this.Service.flightData.details.originDestinationOptionsGroups[1]
                .summary.airlineInfoSummary.marketingAirlines[0]
            }}.
            {{
              this.Service.flightData.details.originDestinationOptionsGroups[1]
                .originDestinationOptions[0].flightSegment.cabinClassName
            }}
          </span>
          <span
            class="mb-0 gray-text-dark"
            *ngIf="
              this.Service.flightData.details.originDestinationOptionsGroups[1]
                .summary.airlineInfoSummary.marketingAirlines.length > 1
            "
          >
            <span i18n="@@multipleAirlines">Multiple Airlines: </span>
            <span
              *ngFor="
                let airlineItem of this.Service.flightData.details
                  .originDestinationOptionsGroups[1].summary.airlineInfoSummary
                  .marketingAirlines
              "
            >
              {{ airlineItem }}.
            </span>
            {{
              this.Service.flightData.details.originDestinationOptionsGroups[1]
                .originDestinationOptions[0].flightSegment.cabinClassName
            }}
          </span>
        </li>
      </ul>

      <p class="font-16 mt-5 gray-primaray-text" i18n="@@cancellation1">
        Cancellation
      </p>
      <p
        class="gray-text-dark mb-0"
        *ngIf="
          this.Service.flightData.details.fareInfo.refundable !== null &&
          this.Service.flightData.details.fareInfo.refundable !== undefined
        "
      >
        <ng-container i18n="@@ticketIs">Ticket is</ng-container>&nbsp;<span
          *ngIf="this.Service.flightData.details.fareInfo.refundable === true"
          >refundable</span
        ><span
          *ngIf="this.Service.flightData.details.fareInfo.refundable === false"
          i18n="@@nonrefundable"
          >Nonrefundable</span
        >
      </p>
      <p class="gray-text-dark mb-0" i18n="@@flightCancellation">
        We do not charge any change or cancellation fee. When the airline charge
        for such fee based on their rules and polices, the cost will be passed
        on you.
      </p>

      <p class="font-16 mt-5 gray-primaray-text" i18n="@@reservationTerms">
        Reservation Terms
      </p>
      <p>
        <ng-container i18n="@@completeBookAgree"
          >By completing this booking, you agree to the</ng-container
        >&nbsp;<a
          *ngIf="Service.isCompany()"
          [href]="env.termsUrl"
          target="_blank"
          ><ng-container i18n="@@termsAndConditions"
            >terms and conditions</ng-container
          ><small><i class="fas fa-external-link-alt"></i></small></a
        >&nbsp;<ng-container i18n="@@ofSafarHub">of SafarHub.</ng-container>
      </p>
      <p class="gray-text-dark mb-0" i18n="@@youWillChargedFLightBooking">
        You will be charged ${{
          this.Service.flightData.details.fareInfo.sellingTotalFares.total
            .amount
        }}
        USD.
      </p>
    </div>

    <div
      *ngIf="Service.flightNewData && Service.mobileView"
      class="container mb-4"
    >
      <div class="white-box p-0 mb-4 price_summary">
        <div class="p-3">
          <h5
            class="text-big mb-3"
            [ngClass]="{ bolder: !Service.isCompany() }"
            i18n="@@priceSummary"
          >
            Price Summary
          </h5>

          <ng-container
            *ngFor="
              let traveler of Service.flightData.details.fareInfo.fareBreakdown;
              let travelerIndex = index
            "
          >
            <ng-container
              *ngFor="
                let travelerRepeat of [].constructor(
                  traveler.passengerTypeQuantity.quantity
                );
                let repeatIndex = index
              "
            >
              <div class="discount d-flex font-16">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  *ngIf="travelerIndex === 0"
                  ><ng-container i18n="@@traveler">Traveler</ng-container
                  >{{ repeatIndex + 1 }}:
                  <span class="text-capitalize">{{
                    traveler.passengerTypeQuantity.type.toLowerCase()
                  }}</span></span
                >
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  *ngIf="travelerIndex === 1"
                  ><ng-container i18n="@@traveler">Traveler</ng-container
                  >{{
                    Service.flightData.details.fareInfo.fareBreakdown[0]
                      .passengerTypeQuantity.quantity +
                      repeatIndex +
                      1
                  }}:
                  <span class="text-capitalize">{{
                    traveler.passengerTypeQuantity.type.toLowerCase()
                  }}</span></span
                >
                <span
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                  >${{
                    traveler.sellingPassengerFare.totalFare.amount
                      | number : "1.2-2"
                  }}</span
                >
              </div>
              <div class="discount d-flex font-14 gray-text my-2">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@flight"
                  >Flight</span
                >
                <span
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                  >${{
                    traveler.sellingPassengerFare.baseFare.amount
                      | number : "1.2-2"
                  }}</span
                >
              </div>
              <div class="discount d-flex font-14 gray-text mb-4">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@taxAndFees"
                  >Tax and fees</span
                >
                <span
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                >
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    >${{
                      this.Service.calTaxes(traveler.sellingPassengerFare.taxes)
                        | number : "1.2-2"
                    }}</span
                  >
                </span>
              </div>
            </ng-container>
          </ng-container>
          <div
            class="discount d-flex font-14 gray-text mb-4"
            *ngIf="this.travelerId && this.travelerId === 'guest'"
          >
            <span
              [ngClass]="{
                'mr-auto': Service.isSiteLtr(),
                'ml-auto': Service.isSiteRtl()
              }"
              i18n="@@bookingFee"
              >Booking fee</span
            >
            <span
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl()
              }"
            >
              <span
                [ngClass]="{
                  'mr-auto': Service.isSiteLtr(),
                  'ml-auto': Service.isSiteRtl()
                }"
                >${{
                  Service.flightData.details.fareInfo.sellingTotalFares.guestFee
                    | number : "1.2-2"
                }}</span
              >
            </span>
          </div>
        </div>

        <div class="white-box-footer rounded-bottom p-3">
          <div class="d-flex font-20">
            <span
              class="primaray-text"
              [ngClass]="{ bolder: !Service.isCompany() }"
              i18n="@@totalPayNow"
            >
              Total to pay now:
            </span>
            <span
              class="primaray-text bolder"
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl()
              }"
              ><span class="currency"
                >${{
                  Service.flightData.details.fareInfo.sellingTotalFares.total
                    .amount | number : "1.0-2"
                }}</span
              ><span class="amount"></span
            ></span>
          </div>
        </div>

        <app-cost-reporting-manage></app-cost-reporting-manage>
      </div>

      <div
        class="white-box p-0"
        *ngIf="
          this.Service.flightData.details.userData &&
          this.Service.flightData.details.userData.rewardAmount
        "
      >
        <div
          class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"
        >
          <ng-container i18n="@@rewards">Rewards</ng-container>:
          <ng-container *ngIf="Service.isSiteLtr()">$</ng-container
          >{{
            this.Service.flightData.details.userData.rewardAmount
              | number : "1.2-2"
          }}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
        </div>
        <div
          class="px-3 pt-3 pb-4 gray-primaray-text font-16"
          i18n="@@uponCompletingFlightBooking"
        >
          You will earn ${{
            this.Service.flightData.details.userData.rewardAmount
              | number : "1.2-2"
          }}
          upon completing this trip
        </div>
      </div>
    </div>

    <div [ngClass]="{ 'px-3': Service.mobileView }">
      <div class="form-action">
        <div class="hotel-details-side d-block d-md-none"></div>

        <div
          class="book_button_container"
          [ngClass]="{
            'd-block':
              !requiresApprovalData ||
              (requiresApprovalData && paymentType !== 'paypal')
          }"
        >
          <div class="row">
            <div class="col-md-4" *ngIf="Service.flightBookingForm">
              <div
                class="d-none"
                [ngClass]="{ 'd-block': paymentType === 'new_card' }"
              >
                <button
                  class="green_btn btn-block book-btn"
                  [disabled]="
                    this.Service.flightBookingSubmit ||
                    this.requiresApprovalData
                  "
                  [ngClass]="{ 'disabled-button': requiresApprovalData }"
                  i18n="@@confirmBooking"
                >
                  CONFIRM BOOKING
                </button>
              </div>
              <ng-container *ngIf="!requiresApprovalData">
                <div
                  class="d-none"
                  [ngClass]="{
                    'd-block': paymentType === 'paypal' && ServiceUp
                  }"
                >
                  <div
                    [ngClass]="{ 'd-none': Service.flightBookingForm.invalid }"
                    id="paypal-button-container"
                  ></div>

                  <div
                    [ngClass]="{
                      'd-none': Service.flightBookingForm.invalid === false,
                      'text-left': Service.isSiteLtr(),
                      'text-right': Service.isSiteRtl()
                    }"
                    class="text-danger"
                    i18n="@@fillRequiredInfo"
                  >
                    Please fill all required info.
                  </div>
                </div>
                <p
                  *ngIf="ServiceDown"
                  class="text-warning font-18 my-5"
                  i18n="@@payPalNotAvailable"
                >
                  PayPal is not available at the moment, try again later
                </p>
              </ng-container>
              <div
                class="d-none"
                [ngClass]="{ 'd-block': paymentType === 'gpay' }"
              >
                <div
                  [ngClass]="{ 'd-none': Service.flightBookingForm.invalid }"
                  id="google-pay-container"
                  class="field"
                ></div>
                <div
                  [ngClass]="{
                    'd-none': Service.flightBookingForm.invalid === false,
                    'text-left': Service.isSiteLtr(),
                    'text-right': Service.isSiteRtl()
                  }"
                  class="text-danger"
                  i18n="@@fillRequiredInfo"
                >
                  Please fill all required info.
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="text-danger font-16">{{this.Service.errorMsg}}</div>-->
        </div>
      </div>
      <ng-container *ngIf="paymentType !== 'paypal'">
        <div
          class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
          *ngIf="
            requiresApprovalData &&
            requiresApprovalData.limitExceeded &&
            !submittedApproval
          "
        >
          <p>
            <ng-container i18n="@@approvalMsgFlight1"
              >This booking exceeds your travel policy. Approval request will be
              sent to</ng-container
            >&nbsp;<span class="font-weight-bold"
              >{{ requiresApprovalData.firstName }}
              {{ requiresApprovalData.lastName }}</span
            ><ng-container i18n="@@approvalMsgFlight2"
              >. Your flight will be automatically booked once
              approved.</ng-container
            >
          </p>

          <p class="bold-600 mt-4 mb-2" *ngIf="Auth.userInfo">
            <ng-container i18n="@@travelPolicy">Travel Policy</ng-container
            >&nbsp;
            <span class="font-weight-normal font-14"
              >({{ Auth.userInfo.effectivePolicyName }})</span
            >
          </p>

          <ng-container
            *ngIf="
              this.rate &&
              this.rate.details &&
              this.rate.details.userData &&
              !this.rate.details.userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  this.rate.details &&
                  this.rate.details.userData &&
                  !this.rate.details.userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@maxPrice" class="font-16 gray-text"
                >Max price:</span
              >

              <span class="gray-text mb-1 px-2" *ngIf="requiresApprovalData"
                >${{ requiresApprovalData.limit }}</span
              >
            </p>

            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  this.rate.details &&
                  this.rate.details.userData &&
                  !this.rate.details.userData.maximumCabinClassViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@fareClass" class="font-16 gray-text"
                >Fare class</span
              >
            </p>

            <p
              class="mb-1 gray-text"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  this.rate.details &&
                  this.rate.details.userData &&
                  !this.rate.details.userData.maximumNumberOfTripsViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@maxNoOfTrips" class="font-16 gray-text"
                >Max trip</span
              >
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              this.rate.details &&
              this.rate.details.userData &&
              this.rate.details.userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.flightBookingNotAllowed"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
                >Flight booking allowed</span
              >
            </p>
          </ng-container>

          <div
            class="col-sm-5 px-0 mt-5 min-width-220"
            [ngClass]="{
              'pl-lg-5 ml-auto': Service.isSiteLtr(),
              'pr-lg-5 mr-auto': Service.isSiteRtl()
            }"
          >
            <button
              class="blue_btn btn-block font-16 py-1 sharp-font"
              [ngClass]="{ 'm-progress': submittingApproval }"
              i18n="@@requestapproval"
            >
              Request Approval
            </button>
          </div>
        </div>
        <div
          class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
          *ngIf="
            requiresApprovalData &&
            !requiresApprovalData.limitExceeded &&
            !submittedApproval
          "
        >
          <p>
            <ng-container i18n="@@approvalMsg3"
              >Booking approval is required. Approval request will be sent
              to</ng-container
            >&nbsp;
            <span class="font-weight-bold"
              >{{ requiresApprovalData.firstName }}
              {{ requiresApprovalData.lastName }}</span
            ><ng-container i18n="@@approvalMsgFlight2"
              >. Your flight will be automatically booked once
              approved.</ng-container
            >
          </p>

          <p class="bold-600 mt-4 mb-2" *ngIf="Auth.userInfo">
            <ng-container i18n="@@travelPolicy">Travel Policy</ng-container
            >&nbsp;
            <span class="font-weight-normal font-14"
              >({{ Auth.userInfo.effectivePolicyName }})</span
            >
          </p>

          <ng-container
            *ngIf="
              this.rate &&
              this.rate.details &&
              this.rate.details.userData &&
              !this.rate.details.userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  !this.rate.details.userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@maxPrice" class="font-16 gray-text"
                >Max price:</span
              >

              <span class="gray-text mb-1 px-2" *ngIf="requiresApprovalData"
                >${{ requiresApprovalData.limit }}</span
              >
            </p>

            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="!this.rate.details.userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@fareClass" class="font-16 gray-text"
                >Fare class</span
              >
            </p>

            <p
              class="mb-1 gray-text"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="!this.rate.details.userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@maxNoOfTrips" class="font-16 gray-text"
                >Max trip</span
              >
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              this.rate.details.userData &&
              this.rate.details.userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.userData.approverDetails
                    .flightBookingNotAllowed
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
                >Flight booking allowed</span
              >
            </p>
          </ng-container>

          <div
            class="col-sm-5 px-0 mt-5 min-width-220"
            [ngClass]="{
              'pl-lg-5 ml-auto': Service.isSiteLtr(),
              'pr-lg-5 mr-auto': Service.isSiteRtl()
            }"
          >
            <button
              class="blue_btn btn-block font-16 py-1 sharp-font"
              [ngClass]="{ 'm-progress': submittingApproval }"
              i18n="@@requestapproval"
            >
              Request Approval
            </button>
          </div>
        </div>
      </ng-container>

      <div
        class="d-none mb-5"
        [ngClass]="{
          'd-block':
            paymentType === 'paypal' && ServiceUp && requiresApprovalData
        }"
      >
        <div
          class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
        >
          <ng-container
            *ngIf="
              requiresApprovalData &&
              requiresApprovalData.limitExceeded &&
              !submittedApproval
            "
          >
            <p *ngIf="requiresApprovalData">
              <ng-container i18n="@@approvalMsgFlight1"
                >This booking exceeds your travel policy. Approval request will
                be sent to</ng-container
              >&nbsp;<span class="font-weight-bold"
                >{{ requiresApprovalData.firstName }}
                {{ requiresApprovalData.lastName }}</span
              ><ng-container i18n="@@approvalMsgFlight2"
                >. Your flight will be automatically booked once
                approved.</ng-container
              >
            </p>
            <p i18n="@@checkOutPayPal">
              Check out with PayPal to proceed with your approval request. we
              will only authorize the payment. The payment will be captured when
              your booking is approved.
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              requiresApprovalData &&
              !requiresApprovalData.limitExceeded &&
              !submittedApproval
            "
          >
            <p *ngIf="requiresApprovalData">
              <ng-container i18n="@@approvalMsg3"
                >Booking approval is required. Approval request will be sent
                to</ng-container
              >&nbsp;
              <span class="font-weight-bold"
                >{{ requiresApprovalData.firstName }}
                {{ requiresApprovalData.lastName }}</span
              ><ng-container i18n="@@approvalMsgFlight2"
                >. Your flight will be automatically booked once
                approved.</ng-container
              >
            </p>
            <p>
              Check out with PayPal to proceed with your approval request. we
              will only authorize the payment. The payment will be captured when
              your booking is approved.
            </p>
          </ng-container>

          <p class="bold-600 mt-4 mb-2" *ngIf="Auth.userInfo">
            <ng-container i18n="@@travelPolicy">Travel Policy</ng-container
            >&nbsp;
            <span class="font-weight-normal font-14"
              >({{ Auth.userInfo.effectivePolicyName }})</span
            >
          </p>

          <ng-container
            *ngIf="
              this.rate &&
              this.rate.details &&
              this.rate.details.userData &&
              !this.rate.details.userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="
                  !this.rate.details.userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="
                  this.rate.details.userData.priceLimitPerPassengerViolated
                "
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@maxPrice" class="font-16 gray-text"
                >Max price:</span
              >

              <span class="gray-text mb-1 px-2" *ngIf="requiresApprovalData"
                >${{ requiresApprovalData.limit }}</span
              >
            </p>

            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="!this.rate.details.userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.maximumCabinClassViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@fareClass" class="font-16 gray-text"
                >Fare class</span
              >
            </p>

            <p
              class="mb-1 gray-text"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-check green-text font-12"
                *ngIf="!this.rate.details.userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.maximumNumberOfTripsViolated"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>

              <span i18n="@@maxNoOfTrips" class="font-16 gray-text"
                >Max trip</span
              >
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              this.rate &&
              this.rate.details.userData &&
              this.rate.details.userData.flightBookingNotAllowed
            "
          >
            <p
              class="mb-1"
              [ngClass]="{
                'mr-0': Service.isSiteLtr(),
                'ml-0': Service.isSiteRtl()
              }"
            >
              <i
                class="icon-close-light text-danger font-12"
                *ngIf="this.rate.details.userData.flightBookingNotAllowed"
                [ngClass]="{
                  'pr-2': Service.isSiteLtr(),
                  'pl-2': Service.isSiteRtl()
                }"
              ></i>
              <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
                >Flight booking allowed</span
              >
            </p>
          </ng-container>

          <div class="row mt-5 mx-lg-0">
            <div
              class="col-lg-6 px-lg-0 max-width-215"
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl(),
                'pl-0': Service.mobileView
              }"
            >
              <div
                [ngClass]="{ 'd-none': Service.flightBookingForm.invalid }"
                id="paypal-button-container"
              ></div>
              <div
                [ngClass]="{
                  'd-none': Service.flightBookingForm.invalid === false,
                  'text-left': Service.isSiteLtr(),
                  'text-right': Service.isSiteRtl()
                }"
                class="text-danger"
                i18n="@@fillRequiredInfo"
              >
                Please fill all required info.
              </div>
              <p
                *ngIf="ServiceDown"
                class="text-warning font-18 my-5"
                i18n="@@payPalNotAvailable"
              >
                PayPal is not available at the moment, try again later
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-danger font-16 pt-3">{{ this.Service.errorMsg }}</div>
    </div>
  </form>
</div>
