import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ScrollToConfigOptions,
  ScrollToService,
} from "@nicky-lenaers/ngx-scroll-to";
import { GoogleAnalyticsService } from "../google-analytics.service";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Subject } from "rxjs/Subject";
import { Location } from "@angular/common";
import { VariablesService } from "./vars.service";
import { DeviceDetectorService } from "ngx-device-detector";
import * as moment from "moment";
import { FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { CostBookingStateService } from "./states/cost-booking-state.service";

declare let gtag: any;

declare let ga: Function; // Declare ga as a function

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(
    @Inject(DOCUMENT) private doc: any,
    public datePipe: DatePipe,
    @Inject(PLATFORM_ID) private platformId,
    private http: HttpClient,
    public router: Router,
    private route: ActivatedRoute,
    private scrollToService: ScrollToService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public location: Location,
    public VarsService: VariablesService,
    private deviceService: DeviceDetectorService,
    public costBookingState: CostBookingStateService
  ) {}
  flightTabActive = false;
  loadingFlag = false;
  flightFilter = false;
  hotelFilter = false;
  applicablePolicyExp;
  applicablePolicy;
  exceptionsHotelPolicy = [];
  applicablePolicySelectedClass;
  newPolicyLocalized = $localize`:@@newPolicy:New Policy`;
  roundTrip = $localize`:@@roundTrip:Round-trip`;
  oneWay = $localize`:@@oneWay:One-way`;
  createNewTeam = $localize`:@@createNewTeamTitle:Create new Team`;
  addNewCard = $localize`:@@addNewCard:Add new card`;
  siteLanguage = "English";
  siteDirection;
  corporateLocale;
  languageList = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
    { code: "ar", label: "العربية" },
  ];
  userHasTravelPref = false;
  unsubscribeSuccessfully = false;
  routeToWorkSpaceHomePage = environment.routeToWorkSpaceHomePage;
  ShowrestOfHome: boolean = false;
  leadEmail: string;
  loginType: string;
  SubmitPreorder: boolean = false;
  showCountryDrop: boolean = false;
  urlWithoutParams = "";
  fromTravel = false;
  hidePrevBtn = false;
  showFeefoCont = false;
  flowFromPopUpCount: number = 0;
  placementLeft = true;
  rightSideDrawerVisible: boolean = false;
  rightSideDrawerName: string;
  rightSideDrawerOn: boolean = false;
  rightSideDrawerData: any;
  BottomSheetVisible: boolean = false;
  BottomSheetName: string;
  BottomSheetData: any;
  BottomSheetOn: boolean = false;
  flowFromPopUp: boolean = false;
  IsSaveNow: boolean = false;
  shadowClickOff: boolean = false;
  ShowPushNotificationsPopfade: boolean = false;
  fireBaseScript = ["/assets/js/lightgallery/lg-thumbnail.min.js"];
  getAllAddresses;
  showPrint = false;
  printData;
  showHotelPrint = false;
  printHotelData;
  isAdmin = false;
  ShowPushNotificationsPopUp = false;
  roomName;
  params;
  FacilitiesFeaturedavailabile = false;
  submitting = false;
  loader = false;
  hotelPage = false;
  GlobelPreviousUrl: string;
  defultCountry;
  hideStickyBottomBannerRates = true;
  PrimeDataPayment;
  primePlan;
  fields;
  sscid;
  loaderText = "Booking your room ..";
  ShowholdRoom = true;
  unlockRateFromTop = false;
  holdRoom = false;
  unlockRate = false;
  snakBarText = "";
  bookingPage = false;
  SignUpBtn = "Sign Up";
  showScrolltop = false;
  paypalPrice = "";
  sessionToken = "";
  scrollFromTopOnScroll = 0;
  previousUrl;
  scrollFromTop = 0;
  bookingSubmit = false;
  RemoteConfg;
  data;
  hotelData;
  flightData;
  flightNewData;
  flightDetailsInListOn = false;
  RememberSaveCard = false;
  isTextFieldType = true;
  isTextFieldType2 = true;
  isTextFieldType3 = true;
  modalShow = false;
  modalVisible = false;
  NotifymodalShow = false;
  NotifymodalVisible = false;
  triphopCardData;

  searchCode;
  searchType;
  paxes;
  PromoBox = false;
  PromoBoxShow = false;
  PromoshowPrivateDesc = false;
  SubscribeBoxShow = false;
  extraCashback;
  validcoupon = false;
  discountActive = false;
  couponValue = "";
  discount;
  selectedDiscount = "show_coupon";
  rate;
  ratesExpiry = false;
  flightBookingForm: FormGroup;
  flightRatesExpiry = false;
  timer = null;
  newRate;

  NotifymodalOn = false;
  NotifymodalData;
  modalOn = false;
  modalData;
  isCollapsed = false;
  headerOn = true;
  queryParams;
  SearchQuery;
  caption;
  captionReturn;
  captionDepart = "";
  captionReturnArray = [];
  captionDepartArray = [];
  hotelCode;
  modalTitle;
  NotifymodalName = "";
  modalName;
  innerWidth;
  mobileView;
  desktopView;
  message;
  menuActive;
  manageWorkspaceMenuActive;
  landingWorkspaceMenuActive;
  userWorkspaceMenuActive;
  alerts = [];
  alertsOverRoute = [];
  guestInfo;
  loginpage = false;
  hotellistLoader = false;
  dataBooking;
  destCity;
  destCountryCode;
  destStateCode;
  errorMsgCoupon = "";
  dropDowmBookingForm = false;
  isCollapsedFlight = true;
  errorMsg;
  referralPrograms = {
    inviterReferralProgram: `${environment.referralPrograms.inviterReferralProgram}`,
    defaultReferralProgram: `${environment.referralPrograms.defaultReferralProgram}`,
  };
  walletData;
  walletId;
  addingCheque = false;
  showAddingCardForm = false;
  editingCheque = false;
  chequeType;
  chequeData;
  findWallets = false;
  wallets;
  noWallets;
  userObjStorage;
  paymentMethods;
  paymentData;
  selectedSavedCard;
  fees;
  dataPaymentMethods;
  calculating = true;
  voucherEmail;
  verificationData;
  toggleData;
  favData;
  favoritesList = [];
  favoriteGroups;
  copyElement;
  copyFlag = false;
  toBeSharedGroup;
  toBeSharedHotel;
  range;
  useForm = true;
  isBooking = false;
  emailVerificationCodeValue;
  verifyEmailMessage;
  verifyEmailMessageType;
  resendCodeLoader = false;
  resetPasswordMessage;
  resetPasswordMessageType;
  resetPasswordCodeValue;
  tripsBadge = 0;
  hotelCodeToShare;
  showCookiesMsg = false;
  statements;
  addNonLoggedInFavId = "";
  addNonLoggedInFavItem;
  isFavoriteHotel = false;
  isLoginTitleFavorite = false;
  sendVoucherMsg = "";
  loginTitleFavorite = "Sign in to add and manage your favorite hotels";
  showFooter = true;
  ActiveSnakBar = false;
  closedPopUp = true;
  hideSubcont = true;
  companyEmail;
  signInErrorMsg = false;
  companyName;
  companyDomain;
  companyId;
  hideWorkspaceFooter = true;
  profilePageValue: string = "";
  userReactivateId;
  public componentMethodCallSource = new Subject<any>();
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();
  public SliderUpdate = new Subject<any>();
  SliderUpdate$ = this.SliderUpdate.asObservable();
  public changePrice = new Subject<any>();
  changePrice$ = this.changePrice.asObservable();
  public fetchUserData = new Subject<any>();
  public fetchCompanyData = new Subject<any>();
  public leadChange = new Subject<any>();
  leadChange$ = this.leadChange.asObservable();
  fetchUserData$ = this.fetchUserData.asObservable();
  isAnswerSelected = false;
  arrayOfAnswers = {};
  questionsCounter = 0;
  questionsCounterDynamic = 0;
  editQuestion = false;
  showUserInfoQuestion = false;
  showUserGenderQuestion = false;
  dynamicQuestionsCount = 0;
  isDisabled = true;
  showPassSignUp = true;
  showPassNewsletterSignUp = true;
  onSideNavChange = true;
  initialMarginValue = true;
  sideNavState = true;
  linkText = true;
  workspaceApprovalsBadge;
  workspaceApprovalsBadgeFlight;
  workspaceFlightApprovalsBadge;
  companyTeams;
  selectedTeamToViewDetails;
  teamMembers = [];
  filteredTeamMembersList;
  teamMembersActiveArray;
  teamMembersInactiveArray;
  teamMembersSortType = "all";
  teamPaymentMethods;
  teamInfo;
  companyUserInfo;
  companyPaymentMethods;
  companyPoliciesList;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  companyPaymentList;
  companyPaymentExpiryList = [];
  userPaymentExpiryList = [];
  companyPaymentIdForAlias;
  companyPaymentGatewayForAlias;
  companyPaymentOldAlias;
  workspaceViewListFlag = true;
  needRequestApproval = false;
  dataFlight;
  approvalsFlightList;
  filteredApprovalsFlightList;
  approvedArrayFlight;
  pendingArrayFlight;
  disapprovedArrayFlight;
  expiredArrayFlight;
  approvalsList;
  filteredApprovalsList;
  approvedArray;
  expiredArray;
  pendingArray;
  pendingArrayUsers;
  // showHeaderTable;
  filteredPending = [];
  disapprovedArray;
  sortType = "pending_approval";
  requestSortType = "pending_approval";
  // for details page
  approvalData;
  approvalHotelData;
  approvalFacilitiesFeaturedavailabile = false;
  approvalBookingInfo;
  approvalLabelOptions;
  approvalPaxes;
  flightApprovalPaxes;
  workspaceSubscriptionDetails;
  workspaceLastSubscription;
  companyBillingList;
  companyBillingExpiryList = [];
  companyBillingObjForEdit;
  userPaymentObjForEdit;
  historyArray;
  flightHistoryArray;
  workspacePlan;
  workspacePlanId;
  nextBillingDate;
  showNoPaymentMsg = true;
  workspaceLoader;
  inactiveWorkspace;
  policyLoader;
  editProfileImage = false;
  companyHistoryArray;
  // for details page
  policyInfo;
  increaseHeaderZIndex = true;
  answerIds;
  // apis
  userProfileImageWithRandomText;
  showDefaultUserImage;
  expensifyLastModifiedDate;
  expensifyLastReportName;
  showExpensifyIcon;
  createEditTeamPage;
  createEditPolicyPage;
  userUpdatePage;
  flightsHistoryArrayDepart;
  flightsHistoryArrayReturn;
  flightBookingPage = false;
  flightBookingSubmit = false;
  flightParams;
  // browserRefresh = false;
  requestsList = [];
  requesterFilteredApprovalsList;
  requesterApprovedArray;
  requesterExpiredArray;
  requesterPendingArray;
  requesterDisapprovedArray;
  requesterWithdrawnArray;
  requestsFlightList = [];
  requesterFilteredApprovalsFlightList;
  requesterApprovedArrayFlight;
  requesterPendingArrayFlight;
  requesterDisapprovedArrayFlight;
  requesterExpiredArrayFlight;
  requesterWithdrawnArrayFlight;
  summaryDetailsDepart;
  summaryDetailsReturn;
  companyUsersList = [];
  usersSortType = "all";
  filteredUsersList;
  activeArray;
  inactiveArray;
  showSliders;
  rules;
  selectedTrip;
  companyIdForSlackLogin;
  flightListLoader = false;
  apisNoTokenInRequest = [
    environment.apiBase + this.VarsService.apiUrls.contactUs,
    environment.apiBase + this.VarsService.apiUrls.actuator,
    environment.apiBase +
      this.VarsService.apiUrls.getSavingsCalculatorQuestions,
    environment.apiBase + this.VarsService.apiUrls.calculateSavings,
    environment.apiBase + this.VarsService.apiUrls.nearby,
  ];
  isSiteLtr() {
    return this.siteDirection === "Ltr";
  }
  isSiteRtl() {
    return this.siteDirection === "Rtl";
  }
  isCompany() {
    return (
      environment.routeToWorkSpaceHomePage
    );
  }
  sendTokenInApiRequest(reqUrl) {
    let send = true;
    this.apisNoTokenInRequest.forEach((apiUrl, index) => {
      if (reqUrl.includes(apiUrl)) {
        send = false;
      }
    });
    return send;
  }
  makeEditable(section) {
    this.profilePageValue = section;
  }

  makeUneditable() {
    this.profilePageValue = undefined;
  }

  favDelete(id) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.delFavoriteHotels}?id=` +
          id,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.favDelete}?id=` + id,
        ""
      );
    }
  }
  shareHotels(emails, ids) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.shareHotels}?emails=` +
        emails +
        `&ids=` +
        ids,
      ""
    );
  }
  companyShareHotels(id, userIds, paxes) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.companyShareHotels}?id=` +
        id +
        `&userIds=` +
        userIds +
        paxes,
      ""
    );
  }
  activatePrimePreorder() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.activatePrimePreorder}`,
      ""
    );
  }
  unsub(code, customerFeedback, reason) {
    let extraParameters = "";
    if (customerFeedback) {
      extraParameters += "&customerFeedback=" + customerFeedback;
    }
    if (reason) {
      extraParameters += "&reason=" + reason;
    }
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.unsub}?code=` +
        code +
        extraParameters,
      ""
    );
  }
  validateUnsubCode(code) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.validateUnsubCode}?code=` +
        code,
      ""
    );
  }
  savePushToken(token, email) {
    email = email.toLowerCase();

    const body = new HttpParams()
      .set("email", email)
      .set("token", token)
      .set("provider", "WEB");

    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.savePushToken}`,
      body
    );
  }
  resetPassword(newPassword, code) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.companyResetPassword}?newPassword=` +
          newPassword +
          `&code=` +
          code,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.resetPassword}?newPassword=` +
          newPassword +
          `&code=` +
          code,
        ""
      );
    }
  }
  checkExpireDate(date) {
    const expiryDate = moment(date);
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    let futureDateToCompareWith = moment(currentDate).add(3, "M");
    const futureDateEndToCompareWith = moment(futureDateToCompareWith).endOf(
      "month"
    );

    if (
      expiryDate.date() !== futureDateToCompareWith.date() &&
      futureDateToCompareWith.isSame(
        futureDateEndToCompareWith.format("YYYY-MM-DD")
      )
    ) {
      futureDateToCompareWith = futureDateToCompareWith.add(1, "d");
    }
    if (expiryDate.isBefore(moment()) || expiryDate.isSame(moment())) {
      return "expired";
    } else if (expiryDate.isBefore(futureDateToCompareWith)) {
      return "willExpireSoon";
    } else {
      return "valid";
    }
  }
  getApplicablePolicy(travelerId: string = "") {
    if (travelerId && travelerId !== "") {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getApplicablePolicy}?travelerId=` +
          travelerId,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getApplicablePolicy}`,
        ""
      );
    }
  }
  userDeals() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.userDeals}`,
      ""
    );
  }
  getExperiences() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getExperiences}`,
      ""
    );
  }
  generalDeals() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.generalDealsV2}`,
      ""
    );
  }

  OurRecommendation() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.OurRecommendation}`,
      ""
    );
  }

  finishedAnswers() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.finishedAnswers}`,
      ""
    );
  }

  linkAccountVerification(newPassword, code) {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.linkAccountVerification}?password=` +
        newPassword +
        `&code=` +
        code
    );
  }

  connectSlack(state) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.connectSlack}?state=` +
        state,
      ""
    );
  }
  verifyEmail(code) {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.verify}?code=` + code
    );
  }
  sendCompanyVerificationEmail(email) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.sendCompanyVerificationEmail}?email=` +
        email,
      ""
    );
  }
  resendPendingInvitation(email) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.resendPendingInvitation}?email=` +
        email,
      ""
    );
  }
  cancelPendingInvitation(email) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.cancelPendingInvitation}?email=` +
        email,
      ""
    );
  }
  getCompanyCodeEmail(code) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getCompanyCodeEmail}?code=` +
        code,
      ""
    );
  }
  createCompany(
    companyId,
    companyName,
    verificationCode,
    ownerPassword,
    ownerFirstName,
    ownerLastName
  ) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.createCompany}?companyId=` +
        companyId +
        "&companyName=" +
        companyName +
        "&verificationCode=" +
        verificationCode +
        "&ownerPassword=" +
        ownerPassword +
        "&ownerFirstName=" +
        ownerFirstName +
        "&ownerLastName=" +
        ownerLastName,
      ""
    );
  }
  getCompanyByEmail(email) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getCompanyByEmail}?email=` +
        email,
      ""
    );
  }
  updateCompany(companyInfo) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateCompany}` +
        companyInfo,
      ""
    );
  }
  checkCompanyId(companyId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.checkCompanyId}?companyId=` +
        companyId,
      ""
    );
  }
  companyInfoById(companyId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.companyInfoById}?companyId=` +
        companyId,
      ""
    );
  }
  registerToCompany(invitationCode, password, firstName, lastName) {
    let body = new HttpParams();
    body = body.append("password", password);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.registerToCompany}?invitationCode=` +
        invitationCode +
        "&firstName=" +
        firstName +
        "&lastName=" +
        lastName,
      body,
      { headers }
    );
  }
  getEmailByInvitationCode(code) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getEmailByInvitationCode}?code=` +
        code,
      ""
    );
  }
  updateCompanyLogo(image) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateCompanyLogo}`,
      image
    );
  }
  setProfileImage(image) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.setProfileImage}`,
      image
    );
  }
  removeProfileImage() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeProfileImage}`,
      ""
    );
  }
  getCompanyInfo() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getCompanyInfo}`,
      ""
    );
  }
  getCompanyUsersList() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.userList}`,
      ""
    );
  }
  disablePersonalPayments(userId, disable) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.disablePersonalPayments}?userId=` +
        userId +
        "&disable=" +
        disable,
      ""
    );
  }
  deactivateUser(userId, reason) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deactivateUser}?userId=` +
        userId +
        "&reason=" +
        reason,
      ""
    );
  }
  userReactivation(userId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.userReactivation}?userId=` +
        userId,
      ""
    );
  }
  userReactivationFees() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.userReactivationFees}`,
      ""
    );
  }
  getCompanyPendingUsers() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getPendingInvitations}`,
      ""
    );
  }
  getTeamsList() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getTeamsList}`,
      ""
    );
  }
  policyList() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.policyList}`,
      ""
    );
  }
  getAirLoyaltyPrograms() {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getAirLoyaltyProgramsCompany}`,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getAirLoyaltyPrograms}`,
        ""
      );
    }
  }
  deletePolicy(policyId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deletePolicy}?policyId=` +
        policyId,
      ""
    );
  }
  createTeam(teamDetails) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.createTeam}` +
        teamDetails,
      ""
    );
  }
  createPolicy(policy) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.createPolicy}`,
      policy
    );
  }
  transferOwnership(currentPassword, setCurrentOwnerAsAdmin, userId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.setOwner}?currentPassword=` +
        currentPassword +
        "&setCurrentOwnerAsAdmin=" +
        setCurrentOwnerAsAdmin +
        "&userId=" +
        userId,
      ""
    );
  }
  editPolicy(policy) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.editPolicy}`,
      policy
    );
  }
  updateTeam(teamDetails) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateTeam}` +
        teamDetails,
      ""
    );
  }
  getTeamMembers(teamId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getTeamMembers}?teamId=` +
        teamId,
      ""
    );
  }
  getTeamPaymentMethods(teamId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.teamListPaymentMethods}?teamId=` +
        teamId,
      ""
    );
  }
  deleteTeam(teamId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deleteTeam}?teamId=` +
        teamId,
      ""
    );
  }
  removeTeamManager(teamId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeTeamManager}?teamId=` +
        teamId,
      ""
    );
  }
  removeTeamPolicy(teamId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeTeamPolicy}?teamId=` +
        teamId,
      ""
    );
  }
  addUsersToTeam(teamId, userIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.addUsersToTeam}?teamId=` +
        teamId +
        "&userIds=" +
        userIds,
      ""
    );
  }
  removeUsersFromTeam(teamId, userIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeUsersFromTeam}?teamId=` +
        teamId +
        "&userIds=" +
        userIds,
      ""
    );
  }
  removeUserPolicy(userId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeUserPolicy}?userId=` +
        userId,
      ""
    );
  }
  removePaymentsFromTeam(teamId, paymentIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deleteTeamPayment}?teamId=` +
        teamId +
        "&paymentMethodIds=" +
        paymentIds,
      ""
    );
  }
  addPaymentsToTeam(teamId, paymentIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.addTeamPayment}?teamId=` +
        teamId +
        "&paymentMethodIds=" +
        paymentIds,
      ""
    );
  }
  removePaymentsFromUser(userId, paymentIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deletePaymentMethodFromUser}?userId=` +
        userId +
        "&paymentMethodIds=" +
        paymentIds,
      ""
    );
  }
  addPaymentsToUser(userId, paymentIds) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.addPaymentMethodToUser}?userId=` +
        userId +
        "&paymentMethodIds=" +
        paymentIds,
      ""
    );
  }

  disableTeamPersonalPayments(teamId, disable) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.disableTeamPersonalPayments}?teamId=` +
        teamId +
        "&disable=" +
        disable,
      ""
    );
  }

  getTeamDetails(teamId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getTeamDetails}?teamId=` +
        teamId,
      ""
    );
  }
  getPolicyDetails(policyId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getPolicyDetails}?policyId=` +
        policyId,
      ""
    );
  }
  getAddresses() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getAddresses}`,
      ""
    );
  }
  slack() {
    return this.http.get(`${environment.slackApiBase}` + "/slack/install", {
      responseType: "text",
      withCredentials: true,
    });
  }
  addOtherAddress(address) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.addOtherAddress}` +
        address,
      ""
    );
  }
  updateOtherAddress(address) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateOtherAddress}` +
        address,
      ""
    );
  }
  removeOtherAddress(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeOtherAddress}?id=` +
        id,
      ""
    );
  }

  companyAddressAction(action, address) {
    if (action === "editCompanyMainAddress") {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updateCompanyAddress}` +
          address,
        ""
      );
    } else if (action === "editCompanyOtherAddress") {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updateOtherAddress}` +
          address,
        ""
      );
    } else if (action === "addCompanyOtherAddress") {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addOtherAddress}` +
          address,
        ""
      );
    }
  }
  updateCompanyAddress(address) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateCompanyAddress}` +
        address,
      ""
    );
  }
  inviteToCompany(dataObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.inviteToCompany}`,
      dataObj
    );
  }
  getFlightListApproversApprovals(status) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.flightListApproversApprovals}?status=` +
        status,
      ""
    );
  }
  getListApproversApprovals(status) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.listApproversApprovals}?status=` +
        status,
      ""
    );
  }
  getListRequesterApprovals() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.listRequesterApprovals}`,
      ""
    );
  }
  getListRequesterFlightApprovals() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.listRequesterFlightApprovals}`,
      ""
    );
  }
  approveTrip(approvalId, commentAndIgnoreRatePriceChange, isFlight) {
    if (isFlight) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.approvalApproveFlight}?approvalId=` +
          approvalId +
          commentAndIgnoreRatePriceChange,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.approvalApprove}?approvalId=` +
          approvalId +
          commentAndIgnoreRatePriceChange,
        ""
      );
    }
  }
  withdrawApprovalRequest(approvalId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.withdrawApprovalRequest}?approvalId=` +
        approvalId,
      ""
    );
  }
  withdrawFlightApprovalRequest(approvalId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.withdrawFlightApprovalRequest}?approvalId=` +
        approvalId,
      ""
    );
  }
  disapproveTrip(approvalId, commentAndReason, isFlight) {
    if (isFlight) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.approvalDisapproveFlight}?approvalId=` +
          approvalId +
          commentAndReason,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.approvalDisapprove}?approvalId=` +
          approvalId +
          commentAndReason,
        ""
      );
    }
  }
  getApprovalDetails(approvalId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.approvalDetails}?approvalId=` +
        approvalId,
      ""
    );
  }
  getFlightApprovalDetails(approvalId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.flightApprovalDetails}?approvalId=` +
        approvalId,
      ""
    );
  }
  approvalSubmitMoreInfo(approvalId, comment) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.approvalSubmitMoreInfo}?approvalId=` +
        approvalId +
        "&comment=" +
        comment,
      ""
    );
  }
  flightApprovalSubmitMoreInfo(approvalId, comment) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.flightApprovalSubmitMoreInfo}?approvalId=` +
        approvalId +
        "&comment=" +
        comment,
      ""
    );
  }
  approvalRequestMoreInfo(approvalId, comment) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.approvalRequestMoreInfo}?approvalId=` +
        approvalId +
        "&comment=" +
        comment,
      ""
    );
  }
  flightApprovalRequestMoreInfo(approvalId, comment) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.flightApprovalRequestMoreInfo}?approvalId=` +
        approvalId +
        "&comment=" +
        comment,
      ""
    );
  }
  switchPlan(planId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.switchPlan}?planId=` +
        encodeURIComponent(planId),
      ""
    );
  }
  changePlan(planId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.changePlan}?planId=` +
        encodeURIComponent(planId),
      ""
    );
  }
  subscriptionDetails() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.subscriptionDetails}`,
      ""
    );
  }
  lastSubscriptionDetails() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.lastSubscriptionDetails}`,
      ""
    );
  }
  cancelSubscriptionSchedule() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.cancelSubscriptionSchedule}`,
      ""
    );
  }
  getExpensifyCreds() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getExpensifyCreds}`,
      ""
    );
  }
  removeExpensifyCreds() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.removeExpensifyCreds}`,
      ""
    );
  }
  setExpensifyCreds(partnerUserId, partnerUserSecret) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.setExpensifyCreds}?partnerUserId=` +
        partnerUserId +
        "&partnerUserSecret=" +
        encodeURIComponent(partnerUserSecret),
      ""
    );
  }
  getExpensifyPolicies() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getExpensifyPolicies}`,
      ""
    );
  }
  getExpensifyStatus() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getExpensifyStatus}`,
      ""
    );
  }
  expensifyReports() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.expensifyReports}`,
      ""
    );
  }
  expensifyAddHotelBookingExpense(data) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.expensifyAddHotelBookingExpense}` +
        data,
      ""
    );
  }
  enableExpensifyCreds(enabled) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.enableExpensifyCreds}?enabled=` +
        enabled,
      ""
    );
  }
  downgradeSub() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.downgradeSub}`,
      ""
    );
  }
  planSwitchFees() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.planSwitchFees}`,
      ""
    );
  }
  changePlanFees(planId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.changePlanFees}?planId=` +
        encodeURIComponent(planId),
      ""
    );
  }
  upgradeSub() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.upgradeSub}`,
      ""
    );
  }
  getWorkspacePlans() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.workspacePlans}`,
      ""
    );
  }
  invoices() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.invoices}`,
      ""
    );
  }
  deactivateCompany(reason) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deactivateCompany}?reason=` +
        reason,
      ""
    );
  }
  reactivateCompany(cardId, planId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.reactivateCompany}?cardId=` +
        encodeURIComponent(cardId) +
        "&planId=" +
        encodeURIComponent(planId),
      ""
    );
  }
  companyReactivationFees(planId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.reactivationFees}?planId=` +
        encodeURIComponent(planId),
      ""
    );
  }
  getSavingsCalculatorQuestions() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getSavingsCalculatorQuestions}`,
      ""
    );
  }
  calculateSavings(answers) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.calculateSavings}`,
      answers
    );
  }
  linkProfile(accessToken, email, password, socialNetwork) {
    email = email.toLowerCase();
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.companyLinkEmailToSocial}?accessToken=` +
          accessToken +
          "&email=" +
          email +
          "&password=" +
          password +
          "&socialNetwork=" +
          socialNetwork,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.linkProfile}?accessToken=` +
          accessToken +
          "&email=" +
          email +
          "&password=" +
          password +
          "&socialNetwork=" +
          socialNetwork,
        ""
      );
    }
  }

  linkUserSocialProfile(accessToken, socialNetwork) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.linkUserSocialProfile}?accessToken=` +
        accessToken +
        "&socialNetwork=" +
        socialNetwork,
      ""
    );
  }

  fav(hotelId) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addFavoriteHotel}?id=` +
          hotelId,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.fav}?id=` + hotelId,
        ""
      );
    }
  }
  addNonLoggedInFav(hotelId) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addFavoriteHotel}?id=` +
          hotelId +
          "&skipRemove=true",
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.fav}?id=` +
          hotelId +
          "&skipRemove=true",
        ""
      );
    }
  }

  getFavorites() {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getFavoriteHotels}`,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getFavorites}`,
        ""
      );
    }
  }
  getPersonalizedQuestions() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getPersonalizedQuestions}`,
      ""
    );
  }

  getPersonalizedQuestion(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getPersonalizedQuestion}?questionId=` +
        id,
      ""
    );
  }

  answerPersonalizedQuestion(answerIds, questionId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.answerPersonalizedQuestion}?answerIds=` +
        answerIds +
        `&questionId=` +
        encodeURIComponent(questionId),
      ""
    );
  }
  resendVerificationCode() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.resendVerificationCode}`,
      ""
    );
  }

  redeemCashback(redeemAmount, walletId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.redeemCashback}?redeemAmount=` +
        redeemAmount +
        `&walletId=` +
        encodeURIComponent(walletId),
      ""
    );
  }

  listCashback() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.listCashback}`,
      ""
    );
  }

  checkRedemptionFees(amount, channelType) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.checkRedemptionFees}?redeemAmount=` +
        amount +
        `&channelType=` +
        channelType,
      ""
    );
  }

  flightHotelListBookings(bookingStatus, includeUpcoming, includePast) {
    if (this.isCompany()) {
      if (this.inactiveWorkspace) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.retrieveCompanyBookings}?bookingStatus=` +
            bookingStatus +
            `&includeUpcoming=` +
            includeUpcoming +
            `&includePast=` +
            includePast,
          ""
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.retrieveAllBookingsMe}?bookingStatus=` +
            bookingStatus +
            `&includeUpcoming=` +
            includeUpcoming +
            `&includePast=` +
            includePast,
          ""
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.retrieveAllBookingsConsumer}?bookingStatus=` +
          bookingStatus +
          `&includeUpcoming=` +
          includeUpcoming +
          `&includePast=` +
          includePast,
        ""
      );
    }
  }
  listBookings(bookingStatus, includeUpcoming, includePast) {
    if (this.isCompany()) {
      if (this.inactiveWorkspace) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.retrieveCompanyBookings}?bookingStatus=` +
            bookingStatus +
            `&includeUpcoming=` +
            includeUpcoming +
            `&includePast=` +
            includePast,
          ""
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.retrieveBookingsMe}?bookingStatus=` +
            bookingStatus +
            `&includeUpcoming=` +
            includeUpcoming +
            `&includePast=` +
            includePast,
          ""
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.listBookings}?bookingStatus=` +
          bookingStatus +
          `&includeUpcoming=` +
          includeUpcoming +
          `&includePast=` +
          includePast,
        ""
      );
    }
  }
  deletePayments(paymentId) {
    if (this.isCompany() && this.router.url.includes("settings/payment")) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.deleteCompanyPayment}?cardId=` +
          encodeURIComponent(paymentId),
        ""
      );
    } else if (
      this.isCompany() &&
      this.router.url.includes("settings/billing")
    ) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.deleteBillingCard}?cardId=` +
          encodeURIComponent(paymentId),
        ""
      );
    } else if (this.isCompany() && this.router.url.includes("user/payment")) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.deletePaymentMe}?cardId=` +
          encodeURIComponent(paymentId),
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.deletePayment}?cardId=` +
          encodeURIComponent(paymentId),
        ""
      );
    }
  }

  updatePayment(paymentObj) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updatePaymentMe}`,
        paymentObj
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updatePayment}`,
        paymentObj
      );
    }
  }

  updateCompanyPayment(paymentObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateCompanyPayment}`,
      paymentObj
    );
  }
  updateCompanyBilling(paymentObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateBillingCard}`,
      paymentObj
    );
  }

  listPayments() {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getPaymentsMe}`,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.listPayments}`,
        ""
      );
    }
  }

  addWallet(walletObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.addWallet}`,
      walletObj
    );
  }

  editWallet(walletObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.editWallet}`,
      walletObj
    );
  }

  deleteWallets(walletId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deleteWallets}?id=` +
        encodeURIComponent(walletId),
      ""
    );
  }

  listWallets() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.listWallets}`,
      ""
    );
  }

  changePassword(currentPassword, newPassword) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.changePasswordMe}?currentPassword=` +
          currentPassword +
          `&newPassword=` +
          newPassword,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.changePassword}?currentPassword=` +
          currentPassword +
          `&newPassword=` +
          newPassword,
        ""
      );
    }
  }

  changeEmail(newEmail, currentPassword) {
    newEmail = newEmail.toLowerCase();
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.changeEmail}?newEmail=` +
        newEmail +
        `&currentPassword=` +
        currentPassword,
      ""
    );
  }

  userUpdate(userInfo) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.userUpdateMe}?` +
          userInfo,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.userUpdate}?` +
          userInfo,
        ""
      );
    }
  }

  userDashboard() {
    if (this.isCompany()) {
      if (this.inactiveWorkspace || this.router.url.includes("reports")) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.companyDashboard}`,
          ""
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.getUserMetricsMe}`,
          ""
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.userDashboard}`,
        ""
      );
    }
  }

  cancel(cancelObj) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.cancelBookingMe}`,
        cancelObj
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.cancel}`,
        cancelObj
      );
    }
  }

  sendBookingVoucher(referenceNumber, recipientEmail, customerIdentifier) {
    recipientEmail = recipientEmail.toLowerCase();

    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.sendVoucher}?referenceNumber=` +
        referenceNumber +
        `&recipientEmail=` +
        recipientEmail,
      customerIdentifier
    );
  }
  sendCompanyBookingVoucher(referenceNumber, userId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.sendCompanyBookingVoucher}?referenceNumber=` +
        referenceNumber +
        `&userId=` +
        userId,
      ""
    );
  }
  shareBookingByEmail(emails, referenceNumber) {
    if (
      this.router.url.includes("flights") ||
      this.router.url.includes("flight")
    ) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.shareFlightBookingByEmail}` +
          emails +
          "&hrsReferenceNumber=" +
          referenceNumber,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.shareHotelBookingByEmail}` +
          emails +
          "&referenceNumber=" +
          referenceNumber,
        ""
      );
    }
  }
  checkMail(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.mailId}?id=` + id,
      ""
    );
  }

  promoContent(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.promoContent}?id=` + id,
      ""
    );
  }

  checkCancellationFees(cancelObj) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.calculateCancellationFeesMe}`,
        cancelObj
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.checkCancellationFees}`,
        cancelObj
      );
    }
  }
  getPreferencesList() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getPreferences}`, ""
    )
  }
  leaveBookingFeedback(
    feedbackComment,
    rating,
    email,
    lastName,
    referenceNumber,
    feedbackSubject
  ) {
    email = email.toLowerCase();

    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.leaveBookingFeedback}?feedbackComment=` +
        feedbackComment +
        `&rating=` +
        rating +
        `&email=` +
        email +
        `&lastName=` +
        lastName +
        `&referenceNumber=` +
        referenceNumber +
        `&feedbackSubject=` +
        feedbackSubject,
      ""
    );
  }

  refInfo(refId) {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.refInfo}?refId=` + refId
    );
  }

  refererDetails(userId) {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.refererDetails}?uid=` +
        userId
    );
  }

  userInvite(dataObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.userInvite}`,
      dataObj
    );
  }

  priceGuarantee(
    phone,
    confirmNo,
    lowestPrice,
    website,
    email,
    name,
    formData
  ) {
    email = email.toLowerCase();

    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.priceGuarantee}?phoneNumber=` +
        phone +
        `&referenceNumber=` +
        confirmNo +
        `&price=` +
        lowestPrice +
        `&priceUrl=` +
        website +
        `&email=` +
        email +
        `&name=` +
        name,
      formData
    );
  }
  /*  bookingByRef(refNo) {
      return this.http.get(`${environment.apiBase}${this.VarsService.apiUrls.bookingByRef}?refNo=` + refNo);
    }*/
  retrieve(retrieveObj) {
    if (this.isCompany()) {
      if (this.inactiveWorkspace) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.bookingByRef}?referenceNumber=` +
            retrieveObj.referenceNumber,
          ""
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.retrieveBookingMe}`,
          retrieveObj
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.retrieve}`,
        retrieveObj
      );
    }
  }

  retrieveAirBooking(retrieveObj) {
    if (this.isCompany()) {
      if (this.inactiveWorkspace) {
        // return this.http.post(`${environment.apiBase}${this.VarsService.apiUrls.bookingByRef}?referenceNumber=` + retrieveObj.referenceNumber, '');
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.retrieveAirBookingMe}`,
          retrieveObj
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.retrieveFlightBookingConsumer}`,
        retrieveObj
      );
    }
  }

  retrieveHold(retrieveObj) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.retrieveHold}`,
      retrieveObj
    );
  }

  addFlyerProgram(travelLoyaltyProgramPassengerDetails) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addFlyerProgram}`,
        travelLoyaltyProgramPassengerDetails
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addFrequentFlyerProgram}`,
        travelLoyaltyProgramPassengerDetails
      );
    }
  }
  addTravelDocument(travelDocument) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addTravelDocument}`,
        travelDocument
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addTravelDocumentConsumer}`,
        travelDocument
      );
    }
  }

  updateFrequentFlyerProgram(travelLoyaltyProgramPassengerDetails) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updateFrequentFlyerProgram}`,
        travelLoyaltyProgramPassengerDetails
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updateFrequentFlyerProgramConsumer}`,
        travelLoyaltyProgramPassengerDetails
      );
    }
  }
  updateTravelDocument(travelDocument) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updateTravelDocument}`,
        travelDocument
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.updateTravelDocumentConsumer}`,
        travelDocument
      );
    }
  }

  removeFrequentFlyerProgram(id) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.removeFrequentFlyerProgram}?id=` +
          encodeURIComponent(id),
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.removeFrequentFlyerProgramConsumer}?id=` +
          encodeURIComponent(id),
        ""
      );
    }
  }
  removeTravelDocument(id) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.removeTravelDocument}?id=` +
          encodeURIComponent(id),
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.removeTravelDocumentConsumer}?id=` +
          encodeURIComponent(id),
        ""
      );
    }
  }

  contactUs(email, message, name, subject) {
    email = email.toLowerCase();
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.contactUs}?email=` +
        email +
        `&message=` +
        message +
        `&name=` +
        name +
        `&subject=` +
        subject,
      ""
    );
  }

  emailNewsLetter(email, destination) {
    email = email.toLowerCase();
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.collect_lead}?email=` +
        email +
        destination,
      email
    );
  }

  /*  leadsAccess(email) {

      return this.http.post(`${environment.apiBase}${this.VarsService.apiUrls.leadsAccess}?email=` + email, email,);

    }*/

  leadsAccess(email, destination) {
    email = email.toLowerCase();
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.leadsAccess}?email=` +
        email +
        destination,
      ""
    );
  }
  autoComplete(Query, header) {
    let headers = new HttpHeaders();

    if (header !== "" && header) {
      headers = headers.append("session-token", header);
    }
    if (this.isCompany()) {
      return this.http.get(
        `${environment.apiBase}${this.VarsService.apiUrls.companyLookup}?keyword=` +
          Query,
        { headers }
      );
    } else {
      return this.http.get(
        `${environment.apiBase}${this.VarsService.apiUrls.lookup}?keyword=` +
          Query,
        { headers }
      );
    }
  }

  autoCompleteAirports(Query, header) {
    let headers = new HttpHeaders();

    if (header !== "" && header) {
      headers = headers.append("session-token", header);
    }
    if (this.isCompany()) {
      return this.http.get(
        `${environment.apiBase}${this.VarsService.apiUrls.companyLookupAirports}?keyword=` +
          Query,
        { headers }
      );
    } else {
      return this.http.get(
        `${environment.apiBase}${this.VarsService.apiUrls.lookupAirports}?keyword=` +
          Query,
        { headers }
      );
    }
  }

  //   voidPaypayPalPayment(price, paymentID, paymentToken ){
  //
  //     let headers = new HttpHeaders();
  //
  //     let authorizationID = paymentID;
  //     let access_token = 'S23AAH0_HqNnxg6iIjAlU993xF6hJBHGQs9tx0lkCtzPBgtdWqjyYfYoKiPzXyWfdR-4I2iLvx-qOWiLICwbFsN_G_Wob03mQ"';
  //
  //     headers = headers.append('Accept', 'application/json' );
  //     headers = headers.append('Authorization', `Bearer ${ access_token }`);
  //     headers = headers.append('data', '{amount: {currency_code: USD, value:'+price+'}}'
  //     );
  //
  //
  // // // 1b. Point your server to the PayPal API
  //     let  PAYPAL_AUTHORIZATION_API = 'https://api.sandbox.paypal.com/v2/payments/authorizations/';
  //
  // // // 2. Get the authorization ID from your database
  //
  // // 3. Call PayPal to void the authorization
  //     return this.http.post(PAYPAL_AUTHORIZATION_API + authorizationID + '/void', '', {headers});
  //
  // // 4. Handle any errors from the call
  //
  //
  //
  //   }

  autoCompleteDestination(Query, header) {
    let headers = new HttpHeaders();

    if (header !== "" && header) {
      headers = headers.append("session-token", header);
    }

    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.lookupDestination}?keyword=` +
        Query,
      { headers }
    );
  }
  getUserInfo() {
    if (this.isCompany() || this.routeToWorkSpaceHomePage) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.companyUserInfo}`,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.userInfo}`,
        ""
      );
    }
  }
  getCompanyUserDetails(userId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.companyUserInfo}?userId=` +
        userId,
      ""
    );
  }

  updateCompanyUser(userNewInfo) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.updateCompanyUser}` +
        userNewInfo,
      ""
    );
  }
  saveRewardProgram(rewardProgram) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.saveRewardProgram}`,
      rewardProgram
    );
  }
  deleteRewardProgram(rewardProgram) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.deleteRewardProgram}`,
      rewardProgram
    );
  }
  getOnlinePrices(queryParams) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getOnlinePrices}`,
      queryParams
    );
  }

  getOnlinePricesV2(queryParams) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getOtaPrices}`,
        queryParams
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getOnlinePricesV2}`,
        queryParams
      );
    }
  }

  CheckEmail(email) {
    email = email.toLowerCase();

    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.checkmail}?email=` +
        email
    );
  }

  sendAccountLink(email) {
    email = email.toLowerCase();

    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.sendAccountLink}?email=` +
        email
    );
  }

  actuator() {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.actuator}`
    );
  }

  forgotPasswrod(email) {
    email = email.toLowerCase();
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.companyForgetPassword}?email=` +
          email,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.forgetPassword}?email=` +
          email,
        ""
      );
    }
  }

  availabilityList(queryParams, header) {
    let headers = new HttpHeaders();
    if (header !== "") {
      headers = headers.append("session-token", header);
    }
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.companyAvailability}`,
        queryParams,
        { headers }
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.availability}`,
        queryParams,
        { headers }
      );
    }
  }
  flightAvailabilityList(queryParams, header) {
    let headers = new HttpHeaders();
    if (header !== "") {
      headers = headers.append("session-token", header);
    }
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.companyAirAvailability}`,
        queryParams,
        { headers }
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.flightAvailability}`,
        queryParams,
        { headers }
      );
    }
  }
  nearByHotel(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.nearby}?id=` + id,
      ""
    );
  }

  tripAdviser(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.tripAdviser}?code=` +
        id,
      ""
    );
  }

  bookingFormFlight(dataObj, header) {
    let headers = new HttpHeaders();
    if (header !== "") {
      headers = headers.append("shareasale_sscid", header);
    }
    this.guestInfo = dataObj;
    if (this.isCompany()) {
      if (this.needRequestApproval) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.requestFlightApproval}`,
          dataObj,
          { headers }
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.companyAirBook}`,
          dataObj,
          { headers }
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.airBook}`,
        dataObj,
        { headers }
      );
    }
  }
  bookingForm(guestInfo, header) {
    let headers = new HttpHeaders();
    if (header !== "") {
      headers = headers.append("shareasale_sscid", header);
    }
    this.guestInfo = guestInfo;
    if (this.isCompany()) {
      if (this.needRequestApproval) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.requestApproval}`,
          guestInfo,
          { headers }
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.companyBooking}`,
          guestInfo,
          { headers }
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.booking}`,
        guestInfo,
        { headers }
      );
    }
  }

  passSubscribePartner(data) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.passSubscribePartner}`,
      data
    );
  }
  partnerOrder(data) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.partnerOrder}`,
      data
    );
  }

  couponOrder(data) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.couponOrder}`,
      data
    );
  }

  isPass() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.isPass}`,
      ""
    );
  }

  userPassOrder(guestInfo) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.userPassOrder}`,
      guestInfo
    );
  }

  passOrderDetails() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.passOrderDetails}`,
      ""
    );
  }

  bookingFormFromHold(guestInfo, header) {
    let headers = new HttpHeaders();
    if (header !== "") {
      headers = headers.append("shareasale_sscid", header);
    }
    //  this.guestInfo = guestInfo;
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.bookingHold}`,
      guestInfo,
      { headers }
    );
  }
  addPayment(cardInfo) {
    if (this.isCompany()) {
      const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
      if (
        this.router.url.includes("booking") ||
        window.location.href.includes("booking") ||
        this.router.url.includes("user/payment")
      ) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.addPaymentMe}`,
          cardInfo
        );
      } else if (
        this.router.url.includes("billing") ||
        window.location.href.includes("billing") ||
        companyObj.inactive
      ) {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.addBillingCard}`,
          cardInfo
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.addCompanyPayment}`,
          cardInfo
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.addPayment}`,
        cardInfo
      );
    }
  }
  companyListPaymentMethods() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.companyListPaymentMethods}`,
      ""
    );
  }
  companyListBillingMethods() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.listBillingMethods}`,
      ""
    );
  }
  getRewardWalletOperations() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.getRewardWalletOperations}`,
      ""
    );
  }
  recheck(key) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.recheckRate}?rateKey=` +
          encodeURIComponent(key),
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.recheck}?rateKey=` +
          encodeURIComponent(key),
        ""
      );
    }
  }
  flightRules(code) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.airItineraryRulesCompany}?code=` +
          encodeURIComponent(code),
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.airItineraryRules}?code=` +
          encodeURIComponent(code),
        ""
      );
    }
  }
  validateFlightForBooking(code, travelerId: string = "") {
    if (this.isCompany()) {
      if (travelerId && travelerId !== "") {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.companyAirValidate}?code=` +
            encodeURIComponent(code) +
            `&travelerId=` +
            travelerId,
          ""
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.companyAirValidate}?code=` +
            encodeURIComponent(code),
          ""
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.airValidate}?code=` +
          encodeURIComponent(code),
        ""
      );
    }
  }

  requiresAirApprovalCheck(key) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.requiresAirApprovalCheck}?key=` +
        encodeURIComponent(key),
      ""
    );
  }
  requiresApprovalCheck(key) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.requiresApprovalCheck}?rateKey=` +
        encodeURIComponent(key),
      ""
    );
  }
  applyDiscount(key, id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.applyDiscount}?rateKey=` +
        encodeURIComponent(key) +
        "&discountId=" +
        id,
      ""
    );
  }

  fetchSearchHistory(type) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.getUserSearchHistory}?clear=` +
          type,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.searchHistory}?clear=` +
          type,
        ""
      );
    }
  }

  fetchFlightSearchHistory(type) {
    if (this.isCompany()) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.flightSearchHistory}?clear=` +
          type,
        ""
      );
    } else {
    }
  }

  clearDiscount(key) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.clearDiscount}?rateKey=` +
        encodeURIComponent(key),
      ""
    );
  }

  clearCoupon(key) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.clearCoupon}?rateKey=` +
        encodeURIComponent(key),
      ""
    );
  }

  applyCoupon(key, code) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.applyCoupon}?rateKey=` +
        encodeURIComponent(key) +
        "&code=" +
        code,
      ""
    );
  }

  checkCashback(price) {
    if (price === null) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.checkCashback}`,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.checkCashback}?sellingPrice=` +
          price,
        ""
      );
    }
  }

  savedCard(travelerId: string = "") {
    if (this.isCompany()) {
      if (travelerId && travelerId !== "") {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.getBookingPaymentMethods}?travelerId=` +
            travelerId,
          ""
        );
      } else {
        return this.http.post(
          `${environment.apiBase}${this.VarsService.apiUrls.getBookingPaymentMethods}`,
          ""
        );
      }
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.savedCard}`,
        ""
      );
    }
  }

  discounts(key) {
    if (key === null) {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.discounts}`,
        ""
      );
    } else {
      return this.http.post(
        `${environment.apiBase}${this.VarsService.apiUrls.discounts}?rateKey=` +
          encodeURIComponent(key),
        ""
      );
    }
  }

  HoldingForm(guestInfo) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.holdRate}`,
      guestInfo
    );
  }
  primeSubscribe(data) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primeSubscribe}`,
      data
    );
  }

  primeChangePaymentMethod(id) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primeChangePaymentMethod}?paymentMethodId=` +
        encodeURIComponent(id),
      ""
    );
  }
  primePlans(promotion) {
    let promo = new HttpParams();
    if (promotion) {
      promo = new HttpParams().set("promo", "true");
    }

    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primePlans}`,
      promo
    );
  }
  couponOffers() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.couponOffers}`,
      ""
    );
  }

  primeAutoRenew(enable) {
    const body = new HttpParams().set("enable", enable);
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primeAutoRenew}`,
      body
    );
  }

  primeCancel() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primeCancel}`,
      ""
    );
  }
  primeBilling() {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primeBilling}`,
      ""
    );
  }
  primeChangePlan(PlanId) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.primeChangePlan}?planId=` +
        PlanId,
      ""
    );
  }
  checkPrimePhoneNumber(phone) {
    // const body = new HttpParams().set('primePhoneNumber', encodeURIComponent(phone));
    // return this.http.post(`${environment.apiBase}${this.VarsService.apiUrls.checkPrimePhoneNumber}`, body);
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.checkPrimePhoneNumber}?primePhoneNumber=` +
        encodeURIComponent(phone),
      ""
    );
  }

  getFeefoSerivceReviews(source) {
    return this.http.get(
      `${environment.apiBase}${this.VarsService.apiUrls.getFeefoSerivceReviews}?source=` +
        source
    );
  }

  // common functions
  disableBodyScroll() {
    //  this.increaseHeaderZIndex = false;
    this.scrollFromTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const html = document.getElementsByTagName("html")[0];

    const body = document.getElementsByTagName("body")[0];

    body.classList.add("overflow-popup");

    html.classList.add("overflow-popup");
  }

  enableBodyScroll() {
    // this.increaseHeaderZIndex = true;
    const html = document.getElementsByTagName("html")[0];

    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("overflow-popup");
    html.classList.remove("overflow-popup");

    document.body.scrollTop = this.scrollFromTop;
    document.documentElement.scrollTop = this.scrollFromTop;
  }
  increaseQuestionsCounter() {
    this.questionsCounter++;
    this.router.navigate([this.urlWithoutParams], {
      queryParams: { Q: this.questionsCounter },
      queryParamsHandling: "merge",
    });
    this.checkTravel();
  }
  decreaseQuestionsCounter() {
    if (this.questionsCounter !== 0) {
      this.questionsCounter--;
      this.router.navigate([this.urlWithoutParams], {
        queryParams: { Q: this.questionsCounter },
        queryParamsHandling: "merge",
      });
    }
    this.isAnswerSelected = true;
    this.checkTravel();
  }
  shareAllFavHotels(caption, isCaption) {
    if (isPlatformBrowser(this.platformId)) {
      const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
      this.copyElement = document.createElement("span");
      if (isCaption) {
        for (var i in this.favoriteGroups) {
          if (i === caption) {
            this.toBeSharedGroup = this.favoriteGroups[i];

            for (var y = 0; y < this.toBeSharedGroup.length; y++) {
              this.copyElement.appendChild(
                document.createTextNode(
                  `${environment.host}` +
                    "/social-card.jsp?image=" +
                    this.toBeSharedGroup[y].imageUrl +
                    "&destination=" +
                    `${environment.companyHost}` +
                    "/" +
                    companyObj.id +
                    "/availability/hotel?searchQuery=" +
                    this.toBeSharedGroup[y].searchQuery +
                    "&title=" +
                    encodeURIComponent(this.toBeSharedGroup[y].name) +
                    "&description=" +
                    encodeURIComponent(
                      this.toBeSharedGroup[y].address + "," + i
                    )
                )
              );
              this.copyElement.appendChild(document.createElement("br"));
            }

            // create temp element

            this.copyElement.id = "tempCopyToClipboard";
            document.body.append(this.copyElement);

            // select the text
            this.range = document.createRange();
            this.range.selectNode(this.copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(this.range);

            // copy & cleanup
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            this.copyElement.remove();
            this.copyFlag = true;
            this.SnakBar("Your favorite hotels link is ready to be shared!");
            /* setTimeout(() => {
               this.copyFlag = false;
             }, 2000);*/
          }
        }
      } else {
        for (var i in this.favoriteGroups) {
          for (var y = 0; y < this.favoriteGroups[i].length; y++) {
            if (caption === this.favoriteGroups[i][y].id) {
              this.toBeSharedHotel = this.favoriteGroups[i][y];

              this.copyElement.appendChild(
                document.createTextNode(
                  `${environment.host}` +
                    "/social-card.jsp?image=" +
                    this.toBeSharedHotel.imageUrl +
                    "&destination=" +
                    `${environment.companyHost}` +
                    "/" +
                    companyObj.id +
                    "/availability/hotel?searchQuery=" +
                    this.toBeSharedHotel.searchQuery +
                    "&title=" +
                    encodeURIComponent(this.toBeSharedHotel.name) +
                    "&description=" +
                    encodeURIComponent(this.toBeSharedHotel.address + "," + i)
                )
              );

              // create temp element

              this.copyElement.id = "tempCopyToClipboard";
              document.body.append(this.copyElement);

              // select the text
              this.range = document.createRange();
              this.range.selectNode(this.copyElement);
              window.getSelection().removeAllRanges();
              window.getSelection().addRange(this.range);

              // copy & cleanup
              document.execCommand("copy");
              window.getSelection().removeAllRanges();
              this.copyElement.remove();
              this.copyFlag = true;
              this.SnakBar("Your favorite hotel link is ready to be shared!");
              /* setTimeout(() => {
                 this.copyFlag = false;
               }, 2000);*/
            }
          }
        }
      }
    }
  }

  toggleFavoriteHotel(hotelId) {
    this.fav(hotelId).subscribe(
      (data) => {
        this.toggleData = data;
        if (this.toggleData.errorCode === 0) {
        } else {
        }
      },
      (error) => {}
    );
  }

  getFavoritesList() {
    this.hotellistLoader = true;
    this.favoritesList = [];
    this.getFavorites().subscribe(
      (data) => {
        this.favData = data;
        if (this.favData.errorCode === 0) {
          this.favoriteGroups = this.favData.data;
          for (let i in this.favoriteGroups) {
            this.favoritesList.push({
              caption: i,
              items: this.favoriteGroups[i],
            });
          }

          this.hotellistLoader = false;
        } else {
        }
      },
      (error) => {}
    );
  }

  messages(messages, messageType) {
    const msgs = [];
    this.alerts.map((item) => {
      if (!msgs.includes(item.message)) {
        msgs.push(item.message);
      }
    });
    if (this.alerts.length > 0) {
      if (!msgs.includes(messages)) {
        this.alerts.push({ type: messageType, message: messages });
      } else {
        return false;
      }
    } else {
      this.alerts.push({ type: messageType, message: messages });
    }
  }

  messagesOverRoute(messages, messageType) {
    if (
      !this.router.url.includes("flights/availability") &&
      !this.router.url.includes("/availability/booking") &&
      !this.router.url.includes("/hold-room") &&
      !this.router.url.includes("/availability/hotel")
    ) {
      this.alertsOverRoute = [];
    } else {
      if (this.alertsOverRoute.length > 0) {
        if (
          this.alertsOverRoute[this.alertsOverRoute.length - 1].message !==
          messages
        ) {
          this.alertsOverRoute.push({ type: messageType, message: messages });
        } else {
          return false;
        }
      } else {
        this.alertsOverRoute.push({ type: messageType, message: messages });
      }
    }
  }

  closeMenu() {
    this.menuActive = false;
    this.enableBodyScroll();
  }

  openRegistrationDialogue() {
    if (this.mobileView) {
      if (
        !this.router.url.includes("user/register") &&
        !this.router.url.includes("user/login")
      ) {
        sessionStorage.setItem("PrevURLLogin", this.router.url);
      }

      this.router.navigate(["/user/register"]);

      this.closeMenu();

      return;
    }

    this.setModal("Join for Free", "signUp", "");
  }

  openloginDialogue() {
    if (this.mobileView) {
      if (
        !this.router.url.includes("user/register") &&
        !this.router.url.includes("user/login")
      ) {
        sessionStorage.setItem("PrevURLLogin", this.router.url);
      }

      this.router.navigate(["/user/login"]);

      this.closeMenu();
      return;
    }

    this.setModal("Sign In", "login", "");
  }

  forgotPasswordDialogue() {
    if (this.desktopView) {
      this.setModal(
        $localize`:@@ForgotPassword:Forgot Password`,
        "ForgotPassword",
        ""
      );
    } else {
      this.setBottomSheet("ForgotPassword", "");
    }
  }
  removeBodyClass() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("workspace-modal");
    body.classList.remove("workspace-page");
  }
  setModal(modalTitle, modalName, modalData) {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-open");
    this.modalData = modalData;
    this.modalOn = true;
    this.modalTitle = modalTitle;
    this.modalName = modalName;
    setTimeout(() => {
      body.classList.remove("workspace-page");
      this.modalShow = true;
      this.disableBodyScroll();
    }, 100);
    setTimeout(() => {
      body.classList.remove("workspace-page");
      this.modalVisible = true;
    }, 300);
  }

  setBottomSheet(Name, Data) {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-open");
    this.BottomSheetData = Data;
    this.BottomSheetOn = true;
    this.BottomSheetName = Name;
    this.BottomSheetVisible = true;
  }
  BottomSheetOf() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
    this.BottomSheetVisible = false;
    if (
      this.BottomSheetName === "addAdmin" ||
      this.BottomSheetName === "removeAdmin"
    ) {
      this.isAdmin = this.companyUserInfo.role.toLowerCase() === "admin";
    }
    this.BottomSheetName = undefined;

    setTimeout(() => {
      this.BottomSheetOn = false;
    }, 700);
  }
  setRightSideDrawer(Name, Data) {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-open");
    body.classList.add("right-side-drawer-main");
    this.rightSideDrawerData = Data;
    this.rightSideDrawerOn = true;
    this.rightSideDrawerName = Name;
    this.rightSideDrawerVisible = true;
  }
  rightSideDrawerOff() {
    this.flightDetailsInListOn = false;
    this.rightSideDrawerData = null;
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
    body.classList.remove("right-side-drawer-main");
    this.rightSideDrawerVisible = false;
    this.rightSideDrawerName = undefined;
    setTimeout(() => {
      this.rightSideDrawerOn = false;
    }, 200);
  }
  PushNotificationsPopUpOf() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
    this.ShowPushNotificationsPopfade = false;

    sessionStorage.setItem("PushNotificationsPopUp", "true");
    setTimeout(() => {
      this.ShowPushNotificationsPopUp = false;
    }, 400);
  }

  PushNotificationsPopUp() {
    let alloweddBrowser = false;
    if (
      this.deviceService &&
      (this.deviceService.browser === "Chrome" ||
        this.deviceService.browser === "Firefox")
    ) {
      alloweddBrowser = true;
    }
    if (
      !sessionStorage.getItem("PushNotificationsPopUp") &&
      Notification &&
      Notification.permission === "default" &&
      alloweddBrowser
    ) {
      setTimeout(() => {
        const body = document.getElementsByTagName("body")[0];
        body.classList.add("modal-open");
        this.ShowPushNotificationsPopUp = true;
        setTimeout(() => {
          this.ShowPushNotificationsPopfade = true;
        }, 400);
      }, this.VarsService.timeOutPushNotification);
    }
  }

  setNotifyModal(modalName, modalData) {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-open");
    this.NotifymodalData = modalData;
    this.NotifymodalOn = true;
    this.NotifymodalName = modalName;
    setTimeout(() => {
      this.NotifymodalShow = true;
      this.disableBodyScroll();
    }, 300);
    setTimeout(() => {
      this.NotifymodalVisible = true;
    }, 700);
  }

  SnakBar(Text) {
    this.ActiveSnakBar = true;

    this.snakBarText = Text;
    setTimeout(() => {
      this.ActiveSnakBar = false;
    }, 3000);
  }
  onResize(event) {
    if (event.target.innerWidth <= 992) {
      this.mobileView = true;

      this.desktopView = false;
    } else {
      this.mobileView = false;

      this.desktopView = true;
    }
    if (event.target.innerWidth <= 1390) {
      this.placementLeft = true;
    } else {
      this.placementLeft = false;
    }
  }

  onScroll(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.showScrolltop = event.currentTarget.scrollY > 100;

      if (this.holdRoom && this.mobileView) {
        let startDate: HTMLElement = document.getElementById(
          "HoldRoom"
        ) as HTMLElement;

        this.ShowholdRoom =
          startDate.offsetTop - window.innerHeight + 170 >
          event.currentTarget.scrollY;
      }
      let unlockRateNewsLetter: HTMLElement = document.getElementById(
        "unlockRateNewsLetter"
      ) as HTMLElement;

      if (this.unlockRate && this.mobileView && unlockRateNewsLetter) {
        this.unlockRateFromTop =
          unlockRateNewsLetter.offsetTop + 250 < event.currentTarget.scrollY;
      }

      if (
        this.urlWithoutParams === "/" &&
        this.ShowrestOfHome === false &&
        this.showScrolltop
      ) {
        this.ShowrestOfHome = true;
      }
    }
  }
  removejscssfile(filename, filetype) {
    const targetelement =
      filetype === "js" ? "script" : filetype === "css" ? "link" : "none"; // determine element type to create nodelist from
    const targetattr =
      filetype === "js" ? "src" : filetype === "css" ? "href" : "none"; // determine corresponding attribute to test for
    const allsuspects = document.getElementsByTagName(targetelement);
    for (let i = allsuspects.length; i >= 0; i--) {
      // search backwards within nodelist for matching elements to remove
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute(targetattr) !== null &&
        allsuspects[i].getAttribute(targetattr).indexOf(filename) !== -1
      )
        allsuspects[i].parentNode.removeChild(allsuspects[i]); // remove element by calling parentNode.removeChild()
    }
  }
  // Function to remove the Zendesk script
  removeZendeskScript() {
    // Code to remove the Zendesk script element from the DOM
    let zendeskIframe = document.querySelector(
      'iframe[style*="widgetOnLoad;"]'
    ) as HTMLIFrameElement;
    if (zendeskIframe) {
      zendeskIframe.style.display = "none";
    }
    const scriptElement = document.querySelector(
      'script[src^="https://static.zdassets.com"]'
    );
    if (scriptElement) {
      scriptElement.remove();
    }
  }

  loadScript(url: string) {
    const dis = this;
    let notExists = true;
    for (var i = 0; i < document.scripts.length; i++) {
      if (document.scripts[i].src === url) {
        notExists = false;
        break;
      }
    }
    if (notExists) {
      const body = document.body as HTMLDivElement;
      const script = document.createElement("script");
      script.innerHTML = "";
      script.src = url;
      script.async = true;
      if (url.includes("static.zdassets.com")) {
        script.id = "ze-snippet";
        let zendeskIframe = document.querySelector(
          'iframe[style*="widgetOnLoad;"]'
        ) as HTMLIFrameElement;
        if (zendeskIframe) {
          zendeskIframe.style.display = "block";
        }
      }
      if (url === "https://www.paypalobjects.com/api/checkout.min.js") {
        script.onload = function () {
          dis.changePrice.next();
        };
      }
      if (url === "https://www.paypalobjects.com/js/external/api.js") {
        script.onload = function () {
          dis.loadScript("../../../assets/js/paypal.min.js");
        };
      }

      if (url === "https://api.feefo.com/api/javascript/triphop") {
        script.onload = function () {
          dis.showFeefoCont = true;
        };
      }

      body.appendChild(script);
    }
  }

  loadScripLightBox() {
    const dis = this;
    let notExists = true;

    for (var i = 0; i < document.scripts.length; i++) {
      if (this.fireBaseScript.includes(document.scripts[i].src)) {
        notExists = false;
        break;
      }
    }

    if (notExists) {
      const body = document.body as HTMLDivElement;
      this.fireBaseScript.map((item, index) => {
        const script = document.createElement("script");

        script.innerHTML = "";
        script.src = item;
        //   script.defer = true;

        body.appendChild(script);
        //         if(item === '/assets/js/lightgallery/lg-thumbnail.min.js'){
        //           script.onload =  function() {
        //             setTimeout(() => {
        //               const lightgallery = document.getElementById('lightgallery');
        //               if(lightgallery){
        //
        //                 lightGallery(document.getElementById('lightgallery').getElementsByClassName('swiper-wrapper')[0], {
        //                   thumbnail:true
        //                 });
        //               }
        //
        //             }, 11100);
        //           };
        //         }
      });
    }

    this.recursivelyAddScript(this.fireBaseScript[this.count]);
  }
  count = 0;
  recursivelyAddScript(script: any) {
    let el: any;

    el = document.createElement("script");
    el.src = script;
    if (this.count < this.fireBaseScript.length) {
      this.count++;
      el.onload = this.recursivelyAddScript(this.fireBaseScript[this.count]);
      document.body.appendChild(el);
    } else {
      return;
    }
  }

  scrollTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  closedGruopuserPopup(
    rate,
    name,
    hotel,
    tripAdviserdata,
    event,
    checkIn,
    checkOut
  ) {
    const allData = {
      rate: rate,
      name: name,
      hotel: hotel,
      tripAdviserdata: tripAdviserdata,
      event: event,
      checkIn: checkIn,
      checkOut: checkOut,
    };
    const body = document.getElementsByTagName("body")[0];

    body.classList.add("modal-promo");
    this.setModal(
      '<div class="font-20-to-18 font-weight-normal gray-primaray-text">Book hidden rates with SafarHub. Start your free trial now.</div>',
      "PassModalForLead",
      allData
    );
  }
  bookingFlight(flight, event, travelerId: string = "") {
    const elm = event;
    elm.srcElement.classList.add("m-progress");
    this.validateFlightForBooking(
      flight.details.fareInfo.fareKey,
      travelerId
    ).subscribe(
      (success) => {
        this.data = success;
        elm.target.classList.remove("m-progress");

        if (this.data.errorCode === 0) {
          this.googleAnalyticsService.eventEmitter(
            "FlightStats",
            "FareValidation",
            "Successful",
            1
          );

          sessionStorage.setItem("previousUrl", this.router.url);
          this.rightSideDrawerOff();

          this.flightNewData = {
            details: this.data.data.airItinerary,
            trip: flight.trip,
          };
          sessionStorage.setItem(
            "flightData",
            JSON.stringify(this.flightNewData)
          );
          sessionStorage.setItem(
            "travelerIdFlight",
            this.data.data.airItinerary.userData.travelerId
          );
          if (
            Math.abs(
              this.flightNewData.details.fareInfo.sellingTotalFares.total
                .amount - flight.details.fareInfo.sellingTotalFares.total.amount
            ) >= 1
          ) {
            this.messagesOverRoute(
              this.VarsService.messages.priceFlightChangeMsg,
              "warning"
            );
          }

          if (this.isCompany()) {
            const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
            this.router.navigate([
              "/" + companyObj.id + "/flights/availability/booking",
            ]);
          } else {
            this.router.navigate(["/flights/availability/booking"]);
          }
        } else {
          this.googleAnalyticsService.eventEmitter(
            "FlightStats",
            "FareValidation",
            "Failed",
            1
          );
          this.errorMsg = this.data.message;
        }
      },
      (error) => {
        this.googleAnalyticsService.eventEmitter(
          "FlightStats",
          "FareValidation",
          "Failed",
          1
        );
      }
    );
  }

  booking(rate, name, hotel, tripAdviserdata, event) {
    this.costBookingState.refrehData();
    const elm = event;
    elm.srcElement.classList.add("m-progress");
    this.rate = rate;
    this.recheck(rate.rateKey).subscribe(
      (success) => {
        this.data = success;
        elm.target.classList.remove("m-progress");

        if (this.data.errorCode === 0) {
          sessionStorage.setItem("previousUrl", this.router.url);
          sessionStorage.setItem("room", name);
          sessionStorage.setItem(
            "travelerId",
            this.data.data[0].userData.travelerId
          );
          sessionStorage.setItem("rate", JSON.stringify(rate));
          sessionStorage.setItem("hotelData", JSON.stringify(hotel));
          if (this.isCompany()) {
            const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
            this.router.navigate([
              "/" + companyObj.id + "/availability/booking",
            ]);
          } else {
            this.router.navigate(["/availability/booking"]);
          }

          this.newRate = this.data.data[0];
        } else {
          this.googleAnalyticsService.eventEmitter(
            "EventStatus",
            "Rate-Recheck",
            "Not-Available",
            1
          );
          this.router.navigateByUrl(this.router.url);
          this.messagesOverRoute(this.data.message, "warning");
        }
      },
      (error) => {
        this.googleAnalyticsService.eventEmitter(
          "EventStatus",
          "Rate-Recheck",
          "Failed",
          1
        );
      }
    );
  }

  HoldRoom(rate, name, hotel, tripAdviserdata) {
    if (tripAdviserdata.data) {
      sessionStorage.setItem(
        "tripAdviserdata",
        JSON.stringify(tripAdviserdata)
      );
    }
    this.googleAnalyticsService.eventEmitter("Stats", "Room-hold", "Click", 1);
    sessionStorage.setItem("previousUrl", this.router.url);
    sessionStorage.setItem("HoldRoomRoom", name);
    sessionStorage.setItem("rate", JSON.stringify(rate));
    sessionStorage.setItem("HoldRoomHotelData", JSON.stringify(hotel));
    if (this.isCompany()) {
      const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
      this.router.navigate(["/" + companyObj.id + "/hold-room"]);
    } else {
      this.router.navigate(["/hold-room"]);
    }
  }

  sendCaption(SearchQuery, caption, paxes, searchCode, searchType) {
    this.SearchQuery = SearchQuery;
    this.caption = caption;
    this.paxes = paxes;
    this.searchCode = searchCode;
    this.searchType = searchType;
  }

  completeDuplicateBooking() {
    this.modalOf();
    this.bookingSubmit = true;
    let header = "";
    if (this.sscid) {
      header = this.sscid;
    }
    const guestInfo = this.guestInfo;
    guestInfo.checkDuplicates = false;
    this.bookingForm(guestInfo, header).subscribe((data) => {
      this.dataBooking = data;
      this.errorMsg = this.dataBooking.message;

      if (this.dataBooking.errorCode === 0) {
        this.errorMsg = "";
        sessionStorage.setItem("voucher", JSON.stringify(this.dataBooking));
        this.modalOn = false;
        const body = document.getElementsByTagName("body")[0];
        body.classList.remove("modal-open");

        this.triggerScrollTo();
        this.hotelData = JSON.parse(sessionStorage.getItem("hotelData"));
        gtag("event", "fireRemarketing", {
          ecomm_prodid: this.hotelData.giataId,
          ecomm_pagetype: "purchase",
          ecomm_totalvalue: this.newRate.finalSellingPrice,
          hrental_id: this.hotelData.giataId,
          hrental_pagetype: "conversionintent",
        });
        ga("ecommerce:clear");

        this.googleAnalyticsService.eventEmitter(
          "EventStatus",
          "Booking-CC",
          "Successful",
          1
        );

        this.googleAnalyticsService.addTransaction(
          this.dataBooking.data.referenceNumber,
          this.newRate.finalSellingPrice
        );

        this.googleAnalyticsService.addItem(
          this.dataBooking.data.referenceNumber,
          this.newRate.finalSellingPrice,
          this.hotelData.name
        );

        if (
          this.RemoteConfg &&
          this.RemoteConfg.enable_share_a_sale &&
          this.RemoteConfg.enable_share_a_sale._value === "true" &&
          this.sscid
        ) {
          this.loadImg(this.dataBooking.data.referenceNumber, this.sscid);
        }
        this.router.navigate(["/availability/voucher"], {
          queryParams: {
            ref: this.dataBooking.data.referenceNumber,
            email: this.dataBooking.data.emailAddress,
            lastname: this.dataBooking.data.guestLastName,
          },
        });
      } else if (this.data.errorCode === 20) {
        this.messagesOverRoute(this.errorMsg, "warning");
        this.scrollTop();

        this.router.navigateByUrl(this.previousUrl);

        this.bookingSubmit = false;
      } else {
        this.scrollTop();

        this.messages(this.errorMsg, "error");
        this.googleAnalyticsService.eventEmitter(
          "EventStatus",
          "Booking-CC",
          "Failed",
          1
        );
        this.bookingSubmit = false;
      }
    });
  }

  listPaymentService() {
    const companyObj = JSON.parse(localStorage.getItem("companyInfo"));
    if (
      (this.isCompany() && this.router.url.includes("settings/billing")) ||
      (this.isCompany() && companyObj && companyObj.inactive)
    ) {
      this.companyListBillingService();
    } else if (
      this.isCompany() &&
      (this.router.url.includes("settings/payment") ||
        this.router.url.includes("teams"))
    ) {
      this.companyListPaymentService();
    } else {
      this.listPayments().subscribe(
        (data) => {
          this.dataPaymentMethods = data;
          if (this.dataPaymentMethods.errorCode === 0) {
            this.paymentMethods = this.dataPaymentMethods.data;
            this.userPaymentExpiryList = [];
            this.paymentMethods.cards.forEach((element) => {
              const splitDate = element.expiry.split("-");
              if (splitDate[0] > this.currentYear) {
                this.userPaymentExpiryList.push(false);
              } else {
                if (splitDate[1] >= this.currentMonth) {
                  this.userPaymentExpiryList.push(false);
                } else {
                  this.userPaymentExpiryList.push(true);
                }
              }
            });
          } else {
          }
        },
        (error) => {}
      );
    }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "header",
      duration: 650,
      easing: "easeOutElastic",
      offset: 20,
    };

    this.scrollToService.scrollTo(config);
  }

  CouponsScroll() {
    const config: ScrollToConfigOptions = {
      target: "couponHeader",
      duration: 650,
      easing: "easeOutElastic",
      offset: 20,
    };

    this.scrollToService.scrollTo(config);
  }

  listWalletsService() {
    this.alerts = [];
    this.userObjStorage = JSON.parse(localStorage.getItem("userInfo"));
    this.listWallets().subscribe(
      (data) => {
        this.chequeData = data;
        if (this.chequeData.errorCode === 0) {
          this.wallets = this.chequeData.data;
          if (this.wallets && this.wallets.length > 0) {
            this.wallets.forEach((element, index) => {
              if (element.primary === true) {
                this.selectedSavedCard = element;
                this.checkRedemptionFees(
                  this.userObjStorage.Balance,
                  this.selectedSavedCard.channelType
                ).subscribe(
                  (dataFee) => {
                    this.data = dataFee;
                    if (this.data.errorCode === 0) {
                      this.fees = this.data.data.feesAmount;
                      this.calculating = false;
                    } else {
                      this.fees = 0;
                      this.calculating = false;
                    }
                  },
                  (error) => {}
                );
              }
            });
          }

          this.findWallets = true;

          if (!this.wallets || this.wallets.length === 0) {
            this.findWallets = false;
            if (
              this.userObjStorage.Balance > 0 &&
              this.router.url.indexOf("cashback") > 0
            ) {
              this.messages(this.VarsService.messages.ADD_WALLET, "warning");
              this.findWallets = false;
            }

            this.noWallets =
              '<b>You have no redemption channels</b><br/><span class="gray-text font-14">' +
              "You can add a new redemption channels by any of the following methods</span>";
          } else {
            this.noWallets = "";
          }
          if (
            this.wallets &&
            this.wallets.length > 0 &&
            this.userObjStorage.Balance > 0 &&
            !this.userObjStorage.verified
          ) {
            this.messages(
              this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
                "Resend verification email",
              "warning"
            );
          }
        } else {
          this.messages(this.chequeData.message, "error");
        }
      },
      (error) => {
        this.messages(this.VarsService.messages.error, "error");
      }
    );
  }

  goToAddWallet() {
    this.router.navigate(["/user/payment"]);
  }

  resendCode() {
    this.resendCodeLoader = true;
    this.resendVerificationCode().subscribe(
      (data) => {
        this.verificationData = data;
        if (this.verificationData.errorCode === 0) {
          this.messages(this.VarsService.messages.VERIFICATION_SENT, "success");
          this.resendCodeLoader = false;
        } else {
          this.messages(this.verificationData.message, "error");
          this.resendCodeLoader = false;
        }
      },
      (error) => {
        this.resendCodeLoader = false;
      }
    );
  }

  deleteWallet(walletId) {
    this.alerts = [];
    this.deleteWallets(walletId).subscribe(
      (data) => {
        this.walletData = data;
        if (this.walletData.errorCode === 0) {
          this.listWalletsService();
          if (
            this.wallets &&
            this.wallets.length > 0 &&
            this.userObjStorage.Balance > 0 &&
            !this.userObjStorage.verified
          ) {
            this.messages(
              this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
                "Resend verification email",
              "warning"
            );
          }
        } else {
          this.messages(this.walletData.message, "error");
          if (
            this.wallets &&
            this.wallets.length > 0 &&
            this.userObjStorage.Balance > 0 &&
            !this.userObjStorage.verified
          ) {
            this.messages(
              this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
                "Resend verification email",
              "warning"
            );
          }
          this.modalOf();
          this.scrollTop();
        }
      },
      (error) => {
        this.messages(this.VarsService.messages.error, "error");
        if (
          this.wallets &&
          this.wallets.length > 0 &&
          this.userObjStorage.Balance > 0 &&
          !this.userObjStorage.verified
        ) {
          this.messages(
            this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
              "Resend verification email",
            "warning"
          );
        }
        this.modalOf();
        this.scrollTop();
      }
    );
  }

  deletePayment(paymentId) {
    this.alerts = [];
    this.submitting = true;
    this.deletePayments(paymentId).subscribe(
      (data) => {
        // this.messages('delete payment successfully', 'success');

        this.paymentData = data;
        this.submitting = true;
        if (this.paymentData.errorCode === 0) {
          this.listPaymentService();
          if (
            this.wallets &&
            this.wallets.length > 0 &&
            this.userObjStorage.Balance > 0 &&
            !this.userObjStorage.verified
          ) {
            this.messages(
              this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
                "Resend verification email",
              "warning"
            );
          }
          this.submitting = false;
          this.modalOf();
          this.BottomSheetOf();
        } else {
          this.messages(this.paymentData.message, "error");
          if (
            this.wallets &&
            this.wallets.length > 0 &&
            this.userObjStorage.Balance > 0 &&
            !this.userObjStorage.verified
          ) {
            this.messages(
              this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
                "Resend verification email",
              "warning"
            );
          }
          this.submitting = false;
          this.modalOf();
          this.BottomSheetOf();
          this.scrollTop();
        }
      },
      (error) => {
        this.submitting = false;
        this.messages(this.VarsService.messages.error, "error");
        if (
          this.wallets &&
          this.wallets.length > 0 &&
          this.userObjStorage.Balance > 0 &&
          !this.userObjStorage.verified
        ) {
          this.messages(
            this.VarsService.messages.ACCOUNT_NOT_VERIFIED +
              "Resend verification email",
            "warning"
          );
        }
        this.modalOf();
        this.BottomSheetOf();
        this.scrollTop();
      }
    );
  }

  deleteCompanyPayment(paymentId) {
    this.submitting = true;
    this.alerts = [];
    this.deletePayments(paymentId).subscribe(
      (data) => {
        // this.messages('delete payment successfully', 'success');

        this.paymentData = data;
        if (this.paymentData.errorCode === 0) {
          this.submitting = false;

          this.listPaymentService();
          this.modalOf();
          this.BottomSheetOf();
        } else {
          this.submitting = false;
          this.messages(this.paymentData.message, "error");
          this.modalOf();
          this.BottomSheetOf();
          this.scrollTop();
        }
      },
      (error) => {
        this.submitting = false;
        this.messages(this.VarsService.messages.error, "error");
        this.modalOf();
        this.scrollTop();
        this.BottomSheetOf();
      }
    );
  }

  goBackBookingForm() {
    this.previousUrl = sessionStorage.getItem("previousUrl");
    this.router.navigateByUrl(this.previousUrl);
    this.NotifymodalOf();
    this.dropDowmBookingForm = false;
  }

  validateFlight(priceChange: boolean, travelerId: string = "") {
    this.previousUrl = sessionStorage.getItem("previousUrl");
    this.flightData = JSON.parse(sessionStorage.getItem("flightData"));
    this.validateFlightForBooking(
      this.flightData.details.fareInfo.fareKey,
      travelerId
    ).subscribe(
      (success) => {
        this.data = success;
        if (this.data.errorCode === 0) {
          // this.browserRefresh = false;
          // this.flightNewData = JSON.parse(sessionStorage.getItem('flightData'));
          //  this.flightNewData.details = this.data.data.airItinerary;
          // const oldData = JSON.parse(sessionStorage.getItem('flightData'));
          this.flightNewData = {
            details: this.data.data.airItinerary,
            trip: this.flightData.trip,
          };
          this.getFlightRules(this.flightData.details.fareInfo.fareKey);
          if (
            priceChange &&
            Math.abs(
              this.flightNewData.details.fareInfo.sellingTotalFares.total
                .amount -
                this.flightData.details.fareInfo.sellingTotalFares.total.amount
            ) >= 1
          ) {
            this.messagesOverRoute(
              this.VarsService.messages.priceFlightChangeMsg,
              "warning"
            );
            setTimeout(() => {
              //  this.googleAnalyticsService.eventEmitter(  'EventStatus', 'Rate-Recheck', 'Successful-Price-Changed', 1);
            }, 500);
          } else {
            //   setTimeout(() => {
            // this.googleAnalyticsService.eventEmitter('EventStatus', 'Rate-Recheck', 'Successful', 1);
            // }, 2000);
          }

          sessionStorage.setItem(
            "flightData",
            JSON.stringify(this.flightNewData)
          );
          if (
            this.flightNewData.details.fareInfo.sellingTotalFares.total.amount
          ) {
            this.paypalPrice =
              this.flightNewData.details.fareInfo.sellingTotalFares.total.amount.toString();
          } else {
            this.router.navigateByUrl(this.previousUrl);
          }
        } else {
          this.messages(this.data.message, "warning");
          if (!priceChange) {
            this.setNotifyModal("refreshFlightBooking", this.previousUrl);
          } else {
            this.router.navigateByUrl(this.previousUrl);
          }
        }
      }
    );
  }

  recheckAll(priceChange: boolean) {
    this.previousUrl = sessionStorage.getItem("previousUrl");
    this.rate = JSON.parse(sessionStorage.getItem("rate"));

    this.recheck(this.rate.rateKey).subscribe(
      (success) => {
        this.data = success;
        if (this.data.errorCode === 0) {
          this.newRate = this.data.data[0];
          sessionStorage.setItem(
            "travelerId",
            this.data.data[0].userData.travelerId
          );
          if (
            priceChange &&
            Math.abs(this.newRate.sellingPrice - this.rate.sellingPrice) >= 1
          ) {
            this.messagesOverRoute(
              this.VarsService.messages.priceChangeMsg,
              "warning"
            );
            setTimeout(() => {
              this.googleAnalyticsService.eventEmitter(
                "EventStatus",
                "Rate-Recheck",
                "Successful-Price-Changed",
                1
              );
            }, 500);
          } else {
            //   setTimeout(() => {
            this.googleAnalyticsService.eventEmitter(
              "EventStatus",
              "Rate-Recheck",
              "Successful",
              1
            );

            // }, 2000);
          }

          sessionStorage.setItem("rate", JSON.stringify(this.newRate));
          if (this.newRate.sellingPrice) {
            this.paypalPrice = this.newRate.sellingPrice.toString();
          } else {
            this.router.navigateByUrl(this.previousUrl);
          }
          if (!this.holdRoom) {
            this.afterNewRate();
          }
        } else {
          this.googleAnalyticsService.eventEmitter(
            "EventStatus",
            "Rate-Recheck",
            "Not-Available",
            1
          );

          this.messagesOverRoute(this.data.message, "warning");
          this.router.navigateByUrl(this.previousUrl);
        }
      },
      (error) => {
        this.googleAnalyticsService.eventEmitter(
          "EventStatus",
          "Rate-Recheck",
          "Failed",
          1
        );
      }
    );
  }

  afterNewRate() {
    /*   this.discounts(this.newRate.rateKey).subscribe(
           success => {
             this.discount = success;
             this.selectedDiscount = 'default'
   
           }
       );*/

    this.changePrice.next();
  }

  applyDiscountAll(id) {
    this.selectedDiscount = id;
    if (this.discount.data) {
      for (let i = 0; i < this.discount.data.discounts.length; i++) {
        this.discount.data.discounts[i].isChecked = false;

        if (id === this.discount.data.discounts[i].id) {
          this.discount.data.discounts[i].isChecked = true;
        }
      }
    }

    if (this.selectedDiscount !== "show_coupon") {
      this.applyDiscount(this.rate.rateKey, id).subscribe((success) => {
        this.data = success;
        if (this.data.errorCode === 0) {
          this.newRate = this.data.data;
          this.changePrice.next();
          this.validcoupon = false;
          this.discountActive = true;
          this.paypalPrice = this.newRate.finalSellingPrice.toString();
        }
      });
    } else {
      this.discountActive = false;
    }
    if (this.mobileView) {
      this.CouponsScroll();
    }
  }

  applyCouponAll(code) {
    this.applyCoupon(this.rate.rateKey, code).subscribe((success) => {
      this.data = success;

      if (this.data.errorCode === 0) {
        this.newRate = this.data.data;
        this.changePrice.next();

        this.errorMsgCoupon = "";
        this.validcoupon = true;
      } else {
        this.errorMsgCoupon = this.data.message;
      }
    });
  }

  clearDiscountAll() {
    this.clearDiscount(this.rate.rateKey).subscribe((success) => {
      this.data = success;

      if (this.data.errorCode === 0) {
        this.newRate = this.data.data;
        this.changePrice.next();

        if (this.discount.data) {
          this.discount.data.discounts.forEach(
            (item) => (item.isChecked = false)
          );
          this.discountActive = false;
        }
      }
    });
  }

  clearCouponAll() {
    this.clearCoupon(this.rate.rateKey).subscribe((success) => {
      this.data = success;

      if (this.data.errorCode === 0) {
        this.newRate = this.data.data;
        this.changePrice.next();

        this.validcoupon = false;
      }
    });
  }

  modalOf() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
    body.classList.remove("modal-promo");
    body.classList.remove("workspace-modal");
    if (this.router.url.includes("availability/hotel?")) {
      body.classList.add("search-page");
    }
    this.modalOn = false;
    this.headerOn = true;
    this.modalShow = false;
    this.modalVisible = false;
    if (this.modalName === "addAdmin" || this.modalName === "removeAdmin") {
      this.isAdmin = this.companyUserInfo.role.toLowerCase() === "admin";
    }
    if (this.modalName === "PassModalForLead") {
      this.googleAnalyticsService.eventEmitter(
        "Website-Pass-Free-Trial",
        "Rate-booking-options",
        "Cancel",
        1
      );
    }

    this.isLoginTitleFavorite = false;
    this.enableBodyScroll();
    this.PromoshowPrivateDesc = false;
    this.flowFromPopUp = false;
    this.shadowClickOff = false;
  }
  NotifymodalOf() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
    body.classList.remove("modal-promo");
    this.enableBodyScroll();
    this.NotifymodalOn = false;
  }
  modalOfPromo() {
    this.modalOf();
    if (!localStorage.getItem("closedpopUp")) {
      localStorage.setItem("closedpopUp", "true");
    }
  }

  ratesExpiryDuration() {
    setTimeout(() => {
      this.ratesExpiry = true;
    }, environment.ratesExpiryDurationMS);
  }
  flightRatesDuration(whichCase) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.rightSideDrawerOff();
      if (
        this.router.url.includes("flights/availability") &&
        !this.router.url.includes("voucher")
      ) {
        if (
          (whichCase === "flightListExpiry" &&
            !this.router.url.includes("booking")) ||
          (whichCase === "bookingFormExpiry" &&
            this.router.url.includes("booking"))
        ) {
          this.flightRatesExpiry = true;
          this.setNotifyModal(whichCase, this.previousUrl);
        } else {
          this.flightRatesExpiry = false;
        }
      }
    }, environment.flightRateDurationMS);
  }

  callComponentMethod() {
    this.componentMethodCallSource.next();
  }

  clearAllMsgs() {
    this.alerts = [];
    this.alertsOverRoute = [];
  }

  togglePasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  togglePasswordFieldType2() {
    this.isTextFieldType2 = !this.isTextFieldType2;
  }
  togglePasswordFieldType3() {
    this.isTextFieldType3 = !this.isTextFieldType3;
  }
  NewsletterSubscription(type) {
    /*  fbq('trackCustom', 'Newsletter Subscription', {
        location: 'Newsletter-Subscription',
        location_path: location.pathname
      });*/
    let NewsType = "";
    if (type && type !== "") {
      NewsType = "-" + type;
    } else {
      NewsType = "";
    }

    this.googleAnalyticsService.eventEmitter(
      "Stats",
      "Newsletter-Subscription" + NewsType,
      this.urlWithoutParams,
      1
    );
  }

  udateslider() {
    this.SliderUpdate.next();
  }

  leadChangeFunc() {
    this.leadChange.next();
  }

  loadImg(trackingnumber, sscid) {
    const body = document.body as HTMLDivElement;
    const img = document.createElement("img");
    img.setAttribute("data-hj-suppress", "");

    img.innerHTML = "";
    img.src =
      "https://shareasale.com/sale.cfm?amount=0.00&tracking=" +
      trackingnumber +
      "&currency=USD&transtype=lead&merchantID=91454&xtype=hotel-booking&sscid=" +
      sscid;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = "https://shareasale-analytics.com/j.js";
    script.async = true;
    script.defer = true;
    body.appendChild(img);
    body.appendChild(script);
  }
  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(";");
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) === 0) {
        this.sscid = c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }

  SelectCountryCode() {
    const body = document.getElementsByTagName("body")[0];
    if (this.mobileView) {
      body.classList.add("modal-promo");
    }
    if (!this.flowFromPopUp) {
      this.setModal("Select Country Code", "CountryCode", "");
    } else {
      this.showCountryDrop = true;
    }
  }

  SetCountryCode(value) {
    this.defultCountry = value;

    if (!this.flowFromPopUp) {
      this.modalOf();
    } else {
      this.showCountryDrop = false;
    }
  }

  primeChangePlanFunc(data) {
    this.submitting = true;

    this.primeChangePlan(data.planId).subscribe(
      (data) => {
        let primeChangeData;
        primeChangeData = data;
        this.submitting = false;
        this.errorMsg = "";

        if (primeChangeData.errorCode === 0) {
          this.modalOf();
          this.messages(this.VarsService.messages.planSuccess, "success");

          this.fetchUserData.next();
        } else {
          this.errorMsg = primeChangeData.message;

          this.submitting = false;
        }
      },
      (error) => {
        this.errorMsg = this.VarsService.messages.error;
        this.submitting = false;
      }
    );
  }
  GlobelPreviousUrlFunc() {
    if (this.GlobelPreviousUrl) {
      window.history.replaceState(
        window.location.href,
        "",
        this.GlobelPreviousUrl
      );
    }
  }

  checkTokenUtm() {
    debugger;
    let utms;
    utms = this.route.snapshot.params;

    if (!utms.hasOwnProperty("token") && !this.router.url.includes("user")) {
      const destinationUrl = this.router.url.slice(1);

      this.router.navigateByUrl("/user/login?destination=" + destinationUrl);
    }
  }
  checkTravel() {
    if (this.urlWithoutParams === "/pass/onboarding") {
      this.fromTravel = true;
      this.hidePrevBtn = this.questionsCounter === 2;
    }
  }

  flowFromPopUpCountFunc() {
    setTimeout(() => {
      switch (this.flowFromPopUpCount) {
        case 1:
          this.modalTitle = "Select your plan";
          break;
        case 2:
          this.modalTitle = "Choose a payment method";
          break;
        case 3:
          this.modalTitle = "You are almost there!";
          break;

        // }
      }
    }, 50);
  }

  SubscribePopUp() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-promo");
    this.setModal("", "primeMembershipPopupFlow", this.modalData);
  }

  getCompanyTeamsList() {
    this.loadingFlag = true;
    this.getTeamsList().subscribe(
      (dataList) => {
        this.data = dataList;
        if (this.data.errorCode === 0) {
          this.loadingFlag = false;
          this.companyTeams = this.data.data;
        } else {
          this.loadingFlag = false;
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.loadingFlag = false;
        this.messages(environment.messages.error, "error");
      }
    );
  }

  getCompanyTeamPaymentMethods(teamId) {
    this.getTeamPaymentMethods(teamId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.teamPaymentMethods = this.data.data;
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getCompanyTeamMembers(teamId) {
    this.getTeamMembers(teamId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.teamMembers = this.data.data;
          this.filteredTeamMembersList = this.data.data;
          if (this.teamMembers) {
            this.teamMembersActiveArray = this.data.data.filter(
              (item) => item.inactive === false
            );
            this.teamMembersInactiveArray = this.data.data.filter(
              (item) => item.inactive === true
            );
          }
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  removeTeam(teamId) {
    this.deleteTeam(teamId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.messages("Team was removed successfully", "success");
          this.getCompanyTeamMembers(teamId);
          if (this.desktopView) {
            this.modalOf();
            // this.scrollTop();
          }
          if (this.mobileView) {
            this.BottomSheetOf();
          }
          const url = this.router.url.split("/");
          if (url[url.length - 1] === "teams") {
            this.getCompanyTeamsList();
          } else {
            this.router.navigate([this.router.url.replace("/" + teamId, "")]);
          }
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getTeamInfo(teamId) {
    this.getTeamDetails(teamId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.teamInfo = this.data.data;
          if (this.desktopView) {
            setTimeout(() => {
              const input = document.getElementById(
                "nameInputTeam"
              ) as HTMLInputElement;
              if (input) {
                input.blur();
              }
            }, 200);
          }
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getCompanyUserInfo(userId) {
    // this.userDetailsPolicy = '';
    // this.userDetailsCompanyCards = [];
    this.getCompanyUserDetails(userId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.companyUserInfo = this.data.data;

          /*
               if (this.companyUserInfo && this.companyUserInfo.membership.companyMembership.teamId) {
                 this.getTeamInfo(this.companyUserInfo.membership.companyMembership.teamId);
                }
               const companyObj = JSON.parse(localStorage.getItem('companyInfo'));
               if (!this.router.url.includes('edit') && companyObj && companyObj.inactive === false) {
                 if (this.companyUserInfo && this.companyUserInfo.membership.companyMembership.policyId) {
                   this.policyList().subscribe(
                     dataPolicy => {
                       this.data = dataPolicy;
                       if (this.data.errorCode === 0) {
                         this.data.data.forEach((item) => {
                           if (this.companyUserInfo.membership.companyMembership.policyId === item.policy.policyId) {
                             this.userDetailsPolicy = item.policy.name;
                           }
                         });
                       } else {
                         this.messages(this.data.message, 'error');
                       }
                     }, error => {
                       this.messages(environment.messages.error, 'error');
                     }
                   );
                 }
                 if (this.companyUserInfo && this.companyUserInfo.membership.companyMembership.paymentMethodsIds &&
                   this.companyUserInfo.membership.companyMembership.paymentMethodsIds.length) {


                   this.companyListPaymentMethods().subscribe(
                     dataCards => {

                       this.data = dataCards;
                       if (this.data.errorCode === 0) {
                         this.data.data.forEach((item) => {
                           if (this.companyUserInfo.membership.companyMembership.paymentMethodsIds.includes(item.card.id)) {
                             this.userDetailsCompanyCards.push(item);
                           }
                         });
                       } else {
                       }

                     },
                     error => {

                     }
                   );


                 }
               }*/
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  removeMemberFromTeam(teamId, userIds) {
    this.removeUsersFromTeam(teamId, userIds).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getCompanyTeamMembers(teamId);
          this.getTeamInfo(teamId);
          if (this.desktopView) {
            const input = document.getElementById(
              "teamInputHtml"
            ) as HTMLInputElement;
            if (input) {
              input.blur();
            }
          }
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getCompanyPolicies() {
    this.loadingFlag = true;
    this.policyList().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.loadingFlag = false;
          this.companyPoliciesList = this.data.data;
          this.policyLoader = true;
        } else {
          this.loadingFlag = false;
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.loadingFlag = false;
        this.messages(environment.messages.error, "error");
      }
    );
  }
  removePolicy(policyId) {
    this.deletePolicy(policyId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.messages("Policy was removed successfully", "success");
          this.getCompanyPolicies();
          if (this.desktopView) {
            this.modalOf();
            this.scrollTop();
          }
          if (this.mobileView) {
            this.BottomSheetOf();
            this.router.navigate([this.router.url.replace("/" + policyId, "")]);
          }
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  getAllListRequesterApprovals() {
    this.hotellistLoader = true;
    this.getListRequesterApprovals().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.requestsList = this.data.data;
          this.requesterFilteredApprovalsList = this.requestsList;
          this.requesterApprovedArray = this.requestsList.filter(
            (item) => item.status.toLowerCase() === "approved"
          );
          this.requesterPendingArray = this.requestsList.filter(
            (item) => item.status.toLowerCase() === "pending_approval"
          );
          this.requesterDisapprovedArray = this.requestsList.filter(
            (item) => item.status.toLowerCase() === "disapproved"
          );
          this.requesterExpiredArray = this.requestsList.filter(
            (item) => item.status.toLowerCase() === "expired"
          );
          this.requesterWithdrawnArray = this.requestsList.filter(
            (item) => item.status.toLowerCase() === "withdrawn"
          );
          if (this.requestSortType === "approved") {
            this.requesterFilteredApprovalsList =
              this.requesterApprovedArray.slice();
          } else if (this.requestSortType === "pending_approval") {
            this.requesterFilteredApprovalsList =
              this.requesterPendingArray.slice();
          } else if (this.requestSortType === "disapproved") {
            this.requesterFilteredApprovalsList =
              this.requesterDisapprovedArray.slice();
          } else if (this.requestSortType === "expired") {
            this.requesterFilteredApprovalsList =
              this.requesterExpiredArray.slice();
          } else if (this.requestSortType === "withdrawn") {
            this.requesterFilteredApprovalsList =
              this.requesterWithdrawnArray.slice();
          }

          ///////////////////////

          this.getListRequesterFlightApprovals().subscribe(
            (dataFlight) => {
              this.dataFlight = dataFlight;
              if (this.dataFlight.errorCode === 0) {
                this.requestsFlightList = this.dataFlight.data;
                this.requesterFilteredApprovalsFlightList =
                  this.requestsFlightList;
                this.requesterApprovedArrayFlight =
                  this.requestsFlightList.filter(
                    (item) => item.status.toLowerCase() === "approved"
                  );
                this.requesterPendingArrayFlight =
                  this.requestsFlightList.filter(
                    (item) => item.status.toLowerCase() === "pending_approval"
                  );
                this.requesterDisapprovedArrayFlight =
                  this.requestsFlightList.filter(
                    (item) => item.status.toLowerCase() === "disapproved"
                  );
                this.requesterExpiredArrayFlight =
                  this.requestsFlightList.filter(
                    (item) => item.status.toLowerCase() === "expired"
                  );
                this.requesterWithdrawnArrayFlight =
                  this.requestsFlightList.filter(
                    (item) => item.status.toLowerCase() === "withdrawn"
                  );
                if (this.requestSortType === "approved") {
                  this.requesterFilteredApprovalsFlightList =
                    this.requesterApprovedArrayFlight.slice();
                } else if (this.requestSortType === "pending_approval") {
                  this.requesterFilteredApprovalsFlightList =
                    this.requesterPendingArrayFlight.slice();
                } else if (this.requestSortType === "disapproved") {
                  this.requesterFilteredApprovalsFlightList =
                    this.requesterDisapprovedArrayFlight.slice();
                } else if (this.requestSortType === "expired") {
                  this.requesterFilteredApprovalsFlightList =
                    this.requesterExpiredArrayFlight.slice();
                } else if (this.requestSortType === "withdrawn") {
                  this.requesterFilteredApprovalsFlightList =
                    this.requesterWithdrawnArrayFlight.slice();
                }
                this.hotellistLoader = false;
                // this.diffInDays = moment.utc(this.data.data.bookingInfo.searchRequest.checkOut).diff(moment.utc(this.data.data.bookingInfo.searchRequest.checkIn), 'days');
              } else {
                this.hotellistLoader = false;
                this.messages(this.dataFlight.message, "error");
              }
            },
            (error) => {
              this.hotellistLoader = false;
              this.messages(environment.messages.error, "error");
            }
          );

          ///////////////////////
        } else {
          this.hotellistLoader = false;
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.hotellistLoader = false;
        this.messages(environment.messages.error, "error");
      }
    );
  }

  globalListApproversApprovals(status) {
    this.hotellistLoader = true;
    this.getListApproversApprovals(status).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.approvalsList = this.data.data;
          this.filteredApprovalsList = this.approvalsList;
          if (status === "PENDING_APPROVAL") {
            this.pendingArray = this.approvalsList;
          } else if (status === "DISAPPROVED") {
            this.disapprovedArray = this.approvalsList;
          } else if (status === "APPROVED") {
            this.approvedArray = this.approvalsList;
          } else if (status === "EXPIRED") {
            this.expiredArray = this.approvalsList;
          }
          /* this.approvedArray = this.approvalsList.filter(item => item.status.toLowerCase() === 'approved');
           this.pendingArray = this.approvalsList.filter(item => item.status.toLowerCase() === 'pending_approval');
           this.disapprovedArray = this.approvalsList.filter(item => item.status.toLowerCase() === 'disapproved');
           this.expiredArray = this.approvalsList.filter(item => item.status.toLowerCase() === 'expired');*/
          /*   if (this.sortType === 'approved') {
               this.filteredApprovalsList =  this.approvedArray.slice();
             } else if ( this.sortType === 'pending_approval') {
               this.filteredApprovalsList =  this.pendingArray.slice();
             } else if ( this.sortType === 'disapproved') {
               this.filteredApprovalsList =  this.disapprovedArray.slice();
             } else if ( this.sortType === 'expired') {
               this.filteredApprovalsList =  this.expiredArray.slice();
             }*/

          //////////////////

          this.getFlightListApproversApprovals(status).subscribe(
            (dataFlight) => {
              this.dataFlight = dataFlight;
              if (this.dataFlight.errorCode === 0) {
                this.approvalsFlightList = this.dataFlight.data;
                this.filteredApprovalsFlightList = this.approvalsFlightList;
                this.filteredApprovalsList = this.approvalsList;
                if (status === "PENDING_APPROVAL") {
                  this.pendingArrayFlight = this.approvalsFlightList;
                  this.filteredApprovalsList = this.pendingArray.slice();
                  this.filteredApprovalsFlightList =
                    this.pendingArrayFlight.slice();
                } else if (status === "DISAPPROVED") {
                  this.disapprovedArrayFlight = this.approvalsFlightList;
                  this.filteredApprovalsList = this.disapprovedArray.slice();
                  this.filteredApprovalsFlightList =
                    this.disapprovedArrayFlight.slice();
                } else if (status === "APPROVED") {
                  this.approvedArrayFlight = this.approvalsFlightList;
                  this.filteredApprovalsList = this.approvedArray.slice();
                  this.filteredApprovalsFlightList =
                    this.approvedArrayFlight.slice();
                } else if (status === "EXPIRED") {
                  this.expiredArrayFlight = this.approvalsFlightList;
                  this.filteredApprovalsList = this.expiredArray.slice();
                  this.filteredApprovalsFlightList =
                    this.expiredArrayFlight.slice();
                }
                /*  this.approvedArrayFlight = this.approvalsFlightList.filter(item => item.status.toLowerCase() === 'approved');
                  this.pendingArrayFlight = this.approvalsFlightList.filter(item => item.status.toLowerCase() === 'pending_approval');
                  this.disapprovedArrayFlight = this.approvalsFlightList.filter(item => item.status.toLowerCase() === 'disapproved');
                  this.expiredArrayFlight = this.approvalsFlightList.filter(item => item.status.toLowerCase() === 'expired');
                  if (this.sortType === 'approved') {
                    this.filteredApprovalsFlightList =  this.approvedArrayFlight.slice();
                  } else if ( this.sortType === 'pending_approval') {
                    this.filteredApprovalsFlightList =  this.pendingArrayFlight.slice();
                  } else if ( this.sortType === 'disapproved') {
                    this.filteredApprovalsFlightList =  this.disapprovedArrayFlight.slice();
                  } else if ( this.sortType === 'expired') {
                    this.filteredApprovalsFlightList =  this.expiredArrayFlight.slice();
                  }*/

                if (this.pendingArrayFlight) {
                  this.workspaceApprovalsBadgeFlight =
                    this.pendingArrayFlight.length;
                }
                if (this.pendingArray) {
                  this.workspaceApprovalsBadge =
                    this.pendingArray.length +
                    this.workspaceApprovalsBadgeFlight;
                }

                localStorage.setItem(
                  "workspaceApprovalsBadgeFlight",
                  this.workspaceApprovalsBadgeFlight
                );

                localStorage.setItem(
                  "workspaceApprovalsBadge",
                  this.workspaceApprovalsBadge
                );
                this.hotellistLoader = false;
              } else {
                this.hotellistLoader = false;
                this.messages(this.dataFlight.message, "error");
              }
            },
            (error) => {
              this.hotellistLoader = false;
              this.messages(environment.messages.error, "error");
            }
          );

          //////////////////////////////////////
        } else {
          this.hotellistLoader = false;
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.hotellistLoader = false;
        this.messages(environment.messages.error, "error");
      }
    );
  }

  companyListBillingService() {
    this.companyListBillingMethods().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.companyBillingList = this.data.data;
          if (this.companyBillingList && this.companyBillingList.length) {
            this.companyBillingExpiryList = [];
            this.companyBillingList.forEach((element, index) => {
              const splitDate = element.expiry.split("-");
              if (splitDate[0] > this.currentYear) {
                this.companyBillingExpiryList.push(false);
              } else {
                if (splitDate[1] >= this.currentMonth) {
                  this.companyBillingExpiryList.push(false);
                } else {
                  this.companyBillingExpiryList.push(true);
                }
              }
            });
          }
          // this.messages('get all credit cards', 'success');
        } else {
        }
      },
      (error) => {}
    );
  }
  companyListPaymentService() {
    this.companyListPaymentMethods().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.companyPaymentList = this.data.data;
          if (this.companyPaymentList && this.companyPaymentList.length) {
            this.companyPaymentList.forEach((element, index) => {
              const splitDate = element.card.expiry.split("-");
              if (splitDate[0] > this.currentYear) {
                this.companyPaymentExpiryList.push(false);
              } else {
                if (splitDate[1] >= this.currentMonth) {
                  this.companyPaymentExpiryList.push(false);
                } else {
                  this.companyPaymentExpiryList.push(true);
                }
              }
            });
          }
          // this.messages('get all credit cards', 'success');
        } else {
        }
      },
      (error) => {}
    );
  }
  withdrawFlightApprovalRequestFunc(approvalId) {
    this.withdrawFlightApprovalRequest(approvalId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.approvalData = this.data.data;
          const url = this.router.url.split("/");
          if (url[url.length - 1] === "requests") {
            this.getAllListRequesterApprovals();
          }
          this.modalOf();
          this.BottomSheetOf();
        } else {
          this.errorMsg = this.data.message;
        }
      },
      (error) => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  withdrawApprovalRequestFunc(approvalId) {
    this.withdrawApprovalRequest(approvalId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.approvalData = this.data.data;
          this.approvalBookingInfo = this.data.data.bookingInfo;
          this.approvalHotelData = this.data.data.bookingInfo.hotel;
          const paxesArray =
            this.data.data.bookingInfo.searchRequest.paxes[0].split(",");
          this.approvalPaxes = Number(paxesArray[0]) + paxesArray.length - 2;
          this.approvalLabelOptions = {
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: "12px",
            text:
              "$" +
              Math.round(
                this.approvalBookingInfo.hotel.rooms[0].rates[0]
                  .averageNightlySellingPrice
              ),
          };
          this.approvalHotelData.myFacilities.map((item) => {
            if (item.featured) {
              this.approvalFacilitiesFeaturedavailabile = true;
            }
          });
          const url = this.router.url.split("/");
          if (url[url.length - 1] === "requests") {
            this.getAllListRequesterApprovals();
          }
          this.modalOf();
          this.BottomSheetOf();
        } else {
          this.errorMsg = this.data.message;
        }
      },
      (error) => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  getFlightApprovalDetailsInfo(approvalId) {
    this.getFlightApprovalDetails(approvalId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.approvalData = this.data.data;
          this.flightApprovalPaxes =
            this.data.data.approvalRequest.bookRequest.adults.length - 1;
          if (this.data.data.approvalRequest.bookRequest.children) {
            this.flightApprovalPaxes +=
              this.data.data.approvalRequest.bookRequest.children?.length;
          }
          this.summaryDetailsDepart =
            this.data.data.airItineraryValidationResponse.airItinerary.originDestinationOptionsGroups[0].summary;
          if (
            this.data.data.approvalRequest.bookRequest.searchRequest.roundTrip
          ) {
            this.summaryDetailsReturn =
              this.data.data.airItineraryValidationResponse.airItinerary.originDestinationOptionsGroups[1].summary;
          }
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getApprovalDetailsInfo(approvalId) {
    this.getApprovalDetails(approvalId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.approvalData = this.data.data;
          this.approvalBookingInfo = this.data.data.bookingInfo;
          this.approvalHotelData = this.data.data.bookingInfo.hotel;
          const paxesArray =
            this.data.data.bookingInfo.searchRequest.paxes[0].split(",");
          this.approvalPaxes = Number(paxesArray[0]) + paxesArray.length - 2;
          this.approvalLabelOptions = {
            color: "#ffffff",
            fontWeight: "bold",
            fontSize: "12px",
            text:
              "$" +
              Math.round(
                this.approvalBookingInfo.hotel.rooms[0].rates[0]
                  .averageNightlySellingPrice
              ),
          };
          this.approvalHotelData.myFacilities.map((item) => {
            if (item.featured) {
              this.approvalFacilitiesFeaturedavailabile = true;
            }
          });
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getSubscriptionDetails() {
    this.subscriptionDetails().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.workspaceSubscriptionDetails = this.data.data;
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  getLastSubscriptionDetails() {
    this.lastSubscriptionDetails().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.workspaceLastSubscription = this.data.data;
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }
  deleteSubscription() {
    this.submitting = true;
    this.cancelSubscriptionSchedule().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getSubscriptionDetails();
          this.submitting = false;
          this.BottomSheetOf();
          this.modalOf();
        } else {
          this.messages(this.data.message, "error");
          this.submitting = false;
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
        this.submitting = false;
      }
    );
  }
  getPendingUsers() {
    this.getCompanyPendingUsers().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.pendingArrayUsers = this.data.data;
          this.filteredPending = this.data.data;
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  reactivateAccount() {
    if (
      !this.companyBillingList ||
      (this.companyBillingList && !this.companyBillingList.length)
    ) {
      this.showNoPaymentMsg = true;
      if (this.desktopView) {
        this.setModal(
          $localize`:@@activateMySubscription:Activate My Subscription`,
          "reactivateAccount",
          ""
        );
      } else {
        this.setBottomSheet("reactivateAccount", "");
      }
    } else {
      this.showNoPaymentMsg = false;
      this.getWorkspacePlans().subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.data.data.forEach((element, index) => {
              if (index === 0) {
                this.workspacePlanId = element.planId;

                if (this.desktopView) {
                  this.setModal(
                    $localize`:@@activateMySubscription:Activate My Subscription`,
                    "reactivateAccount",
                    ""
                  );
                } else {
                  this.setBottomSheet("reactivateAccount", "");
                }
              }
            });
          } else {
            this.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.messages(environment.messages.error, "error");
        }
      );
    }
  }
  showUploadExpensesPopUp(refNumber) {
    if (this.desktopView) {
      this.setModal("Upload Expenses", "uploadExpenses", refNumber);
    } else {
      this.setBottomSheet("uploadExpenses", refNumber);
    }
  }

  showExpensifyInBooking() {
    this.getExpensifyStatus().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if (this.data.data && this.data.data.enabled) {
            this.showExpensifyIcon = true;
          }
        } else {
        }
      },
      (error) => {}
    );
  }

  calDuration(timeInMinutes) {
    const hours = timeInMinutes / 60;
    const roundHours = Math.floor(hours);
    const minutes = (hours - roundHours) * 60;
    const roundMinutes = Math.round(minutes);
    return roundHours + "h " + roundMinutes + "m";
  }

  formatDateTime12Hours(date) {
    return this.datePipe.transform(date, "hh:mma");
  }

  checkNextDay(departureDate, returnDate) {
    if (
      moment(returnDate).toDate().getMonth() -
        moment(departureDate).toDate().getMonth() ===
      0
    ) {
      return (
        moment(returnDate).toDate().getDate() -
        moment(departureDate).toDate().getDate()
      );
    } else {
      return moment.utc(returnDate).diff(moment.utc(departureDate), "days");
    }
  }

  checkPreviousDay(departureDate, returnDate) {
    return moment.utc(returnDate).isBefore(moment.utc(departureDate), "days");
  }

  calTaxes(taxArray) {
    let sum = 0;
    taxArray.forEach((item) => {
      sum = sum + item.amount;
    });
    return sum;
  }

  setUserAsAdmin(userId, isAdmin) {
    this.submitting = true;
    const userAdmin = "?userId=" + userId + "&admin=" + isAdmin;
    this.updateCompanyUser(userAdmin).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;

          this.getCompanyUserDetails(userId).subscribe(
            (dataUser) => {
              this.data = dataUser;
              if (this.data.errorCode === 0) {
                this.companyUserInfo = this.data.data;
                this.isAdmin =
                  this.companyUserInfo.role.toLowerCase() === "admin";
                this.modalOf();
                this.BottomSheetOf();
              } else if (this.data.errorCode === -1) {
                this.modalOf();
                this.BottomSheetOf();
              } else {
                this.errorMsg = this.data.message;
              }
            },
            (error) => {
              this.errorMsg = environment.messages.error;
            }
          );
        } else {
          this.submitting = false;
          this.errorMsg = this.data.message;
        }
      },
      (error) => {
        this.submitting = false;
        this.errorMsg = environment.messages.error;
      }
    );
  }

  resendInvitation(email) {
    this.resendPendingInvitation(email).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.SnakBar($localize`:@@inviteOnWay:Your invitation is on its way`);
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  cancelInvitation(email) {
    this.submitting = true;
    this.errorMsg = "";
    this.cancelPendingInvitation(email).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getPendingUsers();
          this.modalOf();
          this.BottomSheetOf();
          this.SnakBar(
            $localize`:@@withdrawInviteMsg:Your invitation was withdrawn successfully`
          );
        } else {
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      },
      (error) => {
        this.errorMsg = environment.messages.error;
        this.submitting = false;
      }
    );
  }

  getAllCompanyUsers() {
    this.getCompanyUsersList().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.companyUsersList = this.data.data;
          this.filteredUsersList = this.data.data;
          this.activeArray = this.data.data.filter(
            (item) => item.inactive === false
          );
          this.inactiveArray = this.data.data.filter(
            (item) => item.inactive === true
          );
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  getFlightRules(fareKey) {
    this.rules = "";
    this.flightRules(fareKey).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.rules = this.data.data;
        } else {
          this.errorMsg = this.data.message;
        }
      },
      (error) => {
        this.errorMsg = environment.messages.error;
      }
    );
  }

  requestMoreInfoMobile(approvalId, comment) {
    this.errorMsg = "";
    if (comment === undefined || comment === null || comment === "") {
      this.errorMsg = "Please enter you message";
      return;
    }
    this.approvalRequestMoreInfo(approvalId, comment).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getApprovalDetailsInfo(approvalId);
          this.BottomSheetOf();
          this.submitting = false;
        } else {
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      },
      (error) => {
        this.errorMsg = environment.messages.error;
        this.submitting = false;
      }
    );
  }

  formatDateEEMMMdy(date) {
    return this.datePipe.transform(date, "EE MMM dd, y");
  }

  formatDateMMMdy(date) {
    return this.datePipe.transform(date, "MMM dd, y");
  }

  formatDateMMMdd(date) {
    return this.datePipe.transform(date, "MMM dd");
  }

  formatDateEEMMMdd(date) {
    return this.datePipe.transform(date, "EE, MMM dd");
  }

  getApplicablePolicyDetails(travelerId: string = "") {
    this.applicablePolicy = null;
    this.exceptionsHotelPolicy = [];
    this.getApplicablePolicy(travelerId).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.setApplicablePolicyDetails(data);
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  setApplicablePolicyDetails(data) {
    if(data.errorCode === 0) {
      this.applicablePolicy = data.data;
    } else {
      this.applicablePolicy = data;
    }
    this.VarsService.classType.forEach((element) => {
      if (
        this.applicablePolicy &&
        this.applicablePolicy.companyPolicyFlightRules &&
        this.applicablePolicy.companyPolicyFlightRules.maximumCabinClass ===
          element.value
      ) {
        this.applicablePolicySelectedClass = element.name;
      }
    });
    if (
      this.applicablePolicy &&
      this.applicablePolicy.companyPolicyHotelRules
    ) {
      this.applicablePolicy.companyPolicyHotelRules.exceptions.forEach(
        (element, index) => {
          this.exceptionsHotelPolicy.push({
            caption: element.caption,
            avgNightlyPriceLimit: element.avgNightlyPriceLimit,
          });
        }
      );
    }
  }

  changeLocale(locale) {
    localStorage.setItem("locale", locale);
    window.open(this.router.url, "_self");
  }

  deleteOfficeAddress(address) {
    this.submitting = true;
    this.removeOtherAddress(address.id).subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          this.getAllAddressesFun();
          this.modalOf();
          this.BottomSheetOf();
        } else {
          this.messages(this.data.message, "error");
          this.submitting = false;
          this.modalOf();
          this.BottomSheetOf();
          this.scrollTop();
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
        this.submitting = false;
        this.modalOf();
        this.BottomSheetOf();
        this.scrollTop();
      }
    );
  }

  getAllAddressesFun() {
    this.getAddresses().subscribe(
      (data) => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.getAllAddresses = this.data.data;
        } else {
          this.messages(this.data.message, "error");
        }
      },
      (error) => {
        this.messages(environment.messages.error, "error");
      }
    );
  }

  create_cost_center(approvalId, commentAndReason, isFlight) {
    return this.http.post(
      `${environment.apiBase}${this.VarsService.apiUrls.approvalDisapproveFlight}?approvalId=` +
        approvalId +
        commentAndReason,
      ""
    );
  }

  getInterval(interval) {
    if (interval === "MONTHLY") {
      return $localize`:@@forEachMonth:Month`;
    } else {
      return $localize`:@@forEachYear:Year`;
    }
  }

  camelCaseText(text) {
    if (!text.includes(" ")) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
      const arr = text.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      return arr.join(" ");
    }
  }
}
