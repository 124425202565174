import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { SidenavService } from '../../../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../../../workspace-dashboard/animations/animations';
import { Router } from '@angular/router';
import { AuthServiceMain } from '../../../../auth/auth.service';
import { VariablesService } from '../../../../services/vars.service';
import { environment } from '../../../../../environments/environment';
import * as moment from 'moment';
import { Title } from "@angular/platform-browser";


@Component({
  selector: 'app-billing-subscription',
  templateUrl: './billing-subscription.component.html',
  styleUrls: ['./billing-subscription.component.scss'],
  animations: [onMainContentChange],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class BillingSubscriptionComponent implements OnInit {
  subscriptionDesc: string;
  defaultSubscriptionDesc: string = "Review your current subscription details below.";

  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
    public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('Account billing | SafarHub');
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });

    this.dataService.componentMethodCalled$.subscribe(
      () => {
        if (this.auth.isLogedIn && this.auth.companyInfo && this.auth.companyInfo.inactive === false && (this.auth.isAdmin() || this.auth.isOwner())) {
          this.dataService.getSubscriptionDetails();
        }
        if (this.auth.isLogedIn && this.auth.companyInfo && this.auth.companyInfo.inactive === true && this.auth.isOwner()) {
          this.dataService.getLastSubscriptionDetails();
        }
        if (this.auth.isAdmin() || this.auth.isOwner()) {
          this.dataService.companyListBillingService();
        }
      });
  }
  date = new Date();
  submitting;
  data;
  showActions = false;
  selectedIndex;
  annualLocalizedText = 'Annual';
  ngOnInit(): void {
    if (this.auth.companyInfo && this.auth.companyInfo.inactive === false) {
      this.dataService.getSubscriptionDetails();
    }
    if (this.auth.companyInfo && this.auth.companyInfo.inactive) {
      this.dataService.getLastSubscriptionDetails();
    }
    if (this.auth.isAdmin() || this.auth.isOwner()) {
      this.dataService.companyListBillingService();
    }

    this.getSubscriptionDesc();
  }

  getSubscriptionDesc() {
    this.dataService.getWorkspacePlans().subscribe(
      (result: any) => {
        if (result.errorCode) {
          this.dataService.messages(this.data.message, 'error');
          return;
        }

        this.subscriptionDesc = result.data?.find(x => x.activePlan)?.metadata?.description;
        if (!this.subscriptionDesc) {
          this.subscriptionDesc = this.defaultSubscriptionDesc;
        }
      },
      (error) => {
        this.dataService.messages(environment.messages.error, 'error');
      }
    );
  }


  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target)) {
      this.showActions = false;
    }
  }
  contactOwner() {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@contactOwnerTile:Contact Owner`, 'contactOwner', '');
    } else {
      this.dataService.setNotifyModal('contactOwner', '')
    }
  }
  showPlans(currentPlanCycle, currentPlanId) {
    const currentPlan = {
      currentPlanCycleValue: currentPlanCycle,
      currentPlanIdValue: currentPlanId
    };
    // this.dataService.workspacePlan = currentPlanCycle;
    this.dataService.nextBillingDate = this.formatDate(this.dataService.workspaceSubscriptionDetails.current.nextBillingDate);
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@billingPlans:Billing Plans`, 'editBillingPlan', currentPlan);
    } else {
      this.dataService.setBottomSheet('editBillingPlan', currentPlan);
    }
  }
  switchToAnnualPlan() {
    this.dataService.workspacePlan = $localize`:@@annual:Annual`;
    this.dataService.nextBillingDate = this.formatDate(this.dataService.workspaceSubscriptionDetails.current.nextBillingDate);
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@switchToAnnualBilling:Switch to Annual Billing`, 'switchPlan', '');
    } else {
      this.dataService.setBottomSheet('switchPlan', '');
    }
  }
  formatDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }
  addNewPayment() {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@addNewCard:Add new card`, 'NewPaymentCard', '');
    } else {
      this.dataService.setBottomSheet('NewPaymentCard', '');
    }
  }
  editAlias(idValue, gatewayValue, oldAliasValue, primaryValue) {
    this.dataService.companyBillingObjForEdit = {
      id: idValue,
      paymentGatewayProvider: gatewayValue,
      oldAlias: oldAliasValue,
      primary: primaryValue
    };
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@editBillingCard:Edit Billing Card`, 'editBilling', '');
    } else {
      this.dataService.setBottomSheet('editBilling', '');
    }

  }
  removePayment(paymentId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@deleteUserCard:Warning, you're deleting payment method!`, 'removeCompanyPayment', paymentId);
    } else {
      this.dataService.setBottomSheet('removeCompanyPayment', paymentId);
    }
  }
  deleteSubscription() {
    // this.dataService.workspacePlan = this.dataService.workspaceSubscriptionDetails.current.cycle;
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@deleteSubscription:Delete Subscription`, 'deleteSubscription', '');
    } else {
      this.dataService.setBottomSheet('deleteSubscription', '');
    }
  }
  continueSubscription() {
    this.submitting = true;
    this.dataService.cancelSubscriptionSchedule().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.getSubscriptionDetails();
          this.dataService.SnakBar($localize`:@@subReactivated:Your subscription is reactivated successfully`);
          this.submitting = false;
        } else {
          this.dataService.messages(this.data.message, 'error');
          this.submitting = false;
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.submitting = false;
      }
    );
    /*   if (this.dataService.desktopView) {
         this.dataService.setModal('Continue Subscription', 'continueSubscription', '');
       } else {
         this.dataService.setBottomSheet('continueSubscription', '');
       }*/
  }
  setAsPrimary(cardId, cardProvider) {
    const paymentObject = {
      paymentGatewayProvider: cardProvider,
      id: cardId,
      primary: true
    };
    this.dataService.updateCompanyBilling(paymentObject).subscribe(

      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.listPaymentService();
        } else {
          this.dataService.messages(this.data.message, 'error');
        }
      },
      error => {
        this.dataService.messages(environment.messages.error, 'error');
      }


    );
  }
}
