<ng-container *ngIf="!router.url.includes('flights')">


<ng-container *ngIf="loaderName === 'flightLeftSide' && this.loaders.hotellistLoader && loaders.flightFilter === false">
<ng-container *ngIf="loaders.desktopView">
  <div class="filters-loaders">
  <div class="d-flex mt-4 pt-3 mb-2">
    <h4 class="w-75 mb-0" i18n="@@theStops">Stops</h4>
    <div class="w-25 ml-auto" i18n="@@starting">Starting</div>
  </div>

  <div class="main">
    <div class="form-checkboxes">
      <div class="d-flex">
        <div class="form-item form-type-checkbox mb-1 w-75">
         <div class="d-flex">
           <input type="checkbox"/>
          <!-- <label class="gray-text-dark d-flex"> Nonstop (<p class="lines mt-2" style="width: 20px"></p>)</label>-->
           <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
         </div>
        </div>
        <div class="w-25 ml-auto d-flex">
          <span>$</span>
          <p class="w-100 lines mt-2 mx-2"></p>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-item form-type-checkbox mb-1 w-75">
          <div class="d-flex">
            <input type="checkbox"/>
           <!-- <label class="gray-text-dark d-flex"> 1 Stop (<p class="lines mt-2" style="width: 20px"></p>)</label>-->
            <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
          </div>
        </div>
        <div class="w-25 ml-auto d-flex">
          <span>$</span>
          <p class="w-100 lines mt-2 mx-2"></p>
        </div>
      </div>

      <div class="d-flex">
        <div class="form-item form-type-checkbox mb-1 w-75">
          <div class="d-flex">
            <input type="checkbox"/>
            <!--<label class="gray-text-dark d-flex"> 2+ Stops (<p class="lines mt-2" style="width: 20px"></p>)</label>-->
            <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
          </div>
        </div>
        <div class="w-25 ml-auto d-flex">
          <span>$</span>
          <p class="w-100 lines mt-2 mx-2"></p>
        </div>
      </div>
    </div>
  </div>






    <div class="d-flex mt-4 pt-3 mb-2">
      <h4 class="w-75 mb-0" i18n="@@airlines">Airlines</h4>
      <div class="lines w-25 ml-auto"></div>
    </div>

    <div class="main">
      <div class="form-checkboxes">
        <div class="d-flex" *ngFor="let item of [].constructor(4)">
          <div class="form-item form-type-checkbox mb-1 w-75">
            <div class="d-flex">
              <input type="checkbox"/>
              <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
            </div>
          </div>
          <div class="w-25 ml-auto d-flex">
            <span>$</span>
            <p class="w-100 lines mt-2 mx-2"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex mt-4 pt-3 mb-2">
      <h4 class="w-75 mb-0" i18n="@@airports">Airports</h4>
      <div class="w-25 ml-auto"></div>
    </div>
    <div class="d-flex mb-3">
      <div class="mb-0 lines" style="width: 65%"></div>
      <div class="lines w-25 ml-auto"></div>
    </div>
    <div class="main">
      <div class="form-checkboxes">
        <div class="d-flex" *ngFor="let item of [].constructor(4)">
          <div class="form-item form-type-checkbox mb-1 w-75">
            <div class="d-flex">
              <input type="checkbox"/>
              <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
            </div>
          </div>
          <div class="w-25 ml-auto d-flex">
            <span>$</span>
            <p class="w-100 lines mt-2 mx-2"></p>
          </div>
        </div>
      </div>
    </div>






  </div>

</ng-container>
</ng-container>

<div *ngIf="this.loaders.hotellistLoader && !loaderName">
<ng-container *ngIf="loaders.desktopView">
  <div class="not-mobile hotel-item rounded mb-4 shadow">

    <div class="row">

      <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
        <div class="hotel_image shine"  style="    height: 235px;">





        </div>


      </div>
      <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
        <div class="pt-4 pb-4"  [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
          <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
          </a>
          </h2>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

              <div class="hotel_info">
                <div class="teaser_top">

                  <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                  </a>
                  </h2>






                </div>


              </div>
            </div>
            <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

              <div class="price position-relative">
                <div class="">

                  <div class="value">
                  </div>
                  <div class="current_price clearfix">



                    <br/>
                  </div>
                </div>
                <div class="">
                  <div class="cashback position-relative" >
                    <div class="d-flex">
                      <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                    </div>

                    <div class="d-flex">
                      <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                      <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                    </div>


                  </div>

                  <div class="tax">
                    <div class="lines" style="height: 48px; margin-top: 20px"></div>

                  </div>
                </div>

              </div>



            </div>
          </div>
        </div>

      </div>



    </div>


  </div>
  <div class="not-mobile hotel-item rounded mb-4 shadow">

    <div class="row">

      <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
        <div class="hotel_image shine"  style="    height: 235px;">





        </div>


      </div>
      <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
        <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
          <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
          </a>
          </h2>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

              <div class="hotel_info">
                <div class="teaser_top">

                  <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                  </a>
                  </h2>






                </div>


              </div>
            </div>
            <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

              <div class="price position-relative">
                <div class="">

                  <div class="value">
                  </div>
                  <div class="current_price clearfix">



                    <br/>
                  </div>
                </div>
                <div class="">
                  <div class="cashback position-relative" >
                    <div class="d-flex">
                      <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                    </div>

                    <div class="d-flex">
                      <div class="lines-small w-100 " [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                      <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                    </div>


                  </div>

                  <div class="tax">
                    <div class="lines" style="height: 48px; margin-top: 20px"></div>

                  </div>
                </div>

              </div>



            </div>
          </div>
        </div>

      </div>



    </div>


  </div>
  <div class="not-mobile hotel-item rounded mb-4 shadow d-none d-md-block">

    <div class="row">

      <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
        <div class="hotel_image shine"  style="    height: 235px;">





        </div>


      </div>
      <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
        <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
          <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
          </a>
          </h2>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

              <div class="hotel_info">
                <div class="teaser_top">

                  <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                  </a>
                  </h2>






                </div>


              </div>
            </div>
            <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

              <div class="price position-relative">
                <div class="">

                  <div class="value">
                  </div>
                  <div class="current_price clearfix">



                    <br/>
                  </div>
                </div>
                <div class="">
                  <div class="cashback position-relative" >
                    <div class="d-flex">
                      <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                    </div>

                    <div class="d-flex">
                      <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                      <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                    </div>


                  </div>

                  <div class="tax">
                    <div class="lines" style="height: 48px; margin-top: 20px"></div>

                  </div>
                </div>

              </div>



            </div>
          </div>
        </div>

      </div>



    </div>


  </div>
  <div class="not-mobile hotel-item rounded mb-4 shadow">

    <div class="row">

      <div class="col-md-5 col-lg-4 "  *ngIf="!router.url.includes('flights')">
        <div class="hotel_image shine"  style="    height: 235px;">





        </div>


      </div>
      <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
        <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
          <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
          </a>
          </h2>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

              <div class="hotel_info">
                <div class="teaser_top">

                  <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                  </a>
                  </h2>






                </div>


              </div>
            </div>
            <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

              <div class="price position-relative">
                <div class="">

                  <div class="value">
                  </div>
                  <div class="current_price clearfix">



                    <br/>
                  </div>
                </div>
                <div class="">
                  <div class="cashback position-relative" >
                    <div class="d-flex">
                      <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                    </div>

                    <div class="d-flex">
                      <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                      <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                    </div>


                  </div>

                  <div class="tax">
                    <div class="lines" style="height: 48px; margin-top: 20px"></div>

                  </div>
                </div>

              </div>



            </div>
          </div>
        </div>

      </div>



    </div>


  </div>
  <div class="not-mobile hotel-item rounded mb-4 shadow">

    <div class="row">

      <div class="col-md-5 col-lg-4 "  *ngIf="!router.url.includes('flights')">
        <div class="hotel_image shine"  style="    height: 235px;">





        </div>


      </div>
      <div class="col-md-7-8 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
        <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
          <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
          </a>
          </h2>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

              <div class="hotel_info">
                <div class="teaser_top">

                  <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                  </a>
                  </h2>






                </div>


              </div>
            </div>
            <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

              <div class="price position-relative">
                <div class="">

                  <div class="value">
                  </div>
                  <div class="current_price clearfix">



                    <br/>
                  </div>
                </div>
                <div class="">
                  <div class="cashback position-relative" >
                    <div class="d-flex">
                      <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                    </div>

                    <div class="d-flex">
                      <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                      <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                    </div>


                  </div>

                  <div class="tax">
                    <div class="lines" style="height: 48px; margin-top: 20px"></div>

                  </div>
                </div>

              </div>



            </div>
          </div>
        </div>

      </div>



    </div>


  </div>
  <div class="not-mobile hotel-item rounded mb-4 shadow">

    <div class="row">

      <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
        <div class="hotel_image shine"  style="    height: 235px;">





        </div>


      </div>
      <div class="col-md-7 col-lg-8 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
        <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
          <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
          </a>
          </h2>
          <div class="row">
            <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

              <div class="hotel_info">
                <div class="teaser_top">

                  <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                  </a>
                  </h2>






                </div>


              </div>
            </div>
            <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

              <div class="price position-relative">
                <div class="">

                  <div class="value">
                  </div>
                  <div class="current_price clearfix">



                    <br/>
                  </div>
                </div>
                <div class="">
                  <div class="cashback position-relative" >
                    <div class="d-flex">
                      <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                    </div>

                    <div class="d-flex">
                      <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                      <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                    </div>


                  </div>

                  <div class="tax">
                    <div class="lines" style="height: 48px; margin-top: 20px"></div>

                  </div>
                </div>

              </div>



            </div>
          </div>
        </div>

      </div>



    </div>


  </div>



</ng-container>
  <ng-container *ngIf="loaders.mobileView">
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>
    <div class="not-mobile hotel-item rounded mb-4 shadow">

      <div class="row">

        <div class="col-md-5 col-lg-4 ">
          <div class="hotel_image shine"  style="    height: 235px;">





          </div>


        </div>
        <div class="col-md-7 col-lg-8 ">
          <div class="p-4">
            <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
            </a>
            </h2>
            <div class="row">
              <div class="col-lg-7 col-md-6">

                <div class="hotel_info">
                  <div class="teaser_top">

                    <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                    </a>
                    </h2>






                  </div>


                </div>
              </div>
              <div class="col-md-6 col-lg-5 cash-back-cont">

                <div class="price position-relative">
                  <div class="">

                    <div class="value">
                    </div>
                    <div class="current_price clearfix">



                      <br/>
                    </div>
                  </div>
                  <div class="">
                    <div class="cashback position-relative" >
                      <div class="d-flex">
                        <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                      </div>

                      <div class="row justify-content-end">
                        <div class="col-4">
                          <div class="lines-small w-100  "></div>
                          <div class="lines-small w-100  "></div>

                        </div>


                      </div>


                    </div>


                  </div>

                </div>



              </div>
            </div>
          </div>

        </div>



      </div>


    </div>

  </ng-container>


</div>


</ng-container>









<ng-container *ngIf="router.url.includes('flights')">


  <ng-container *ngIf="loaderName === 'flightLeftSide' && this.loaders.flightListLoader && loaders.flightFilter === false">
    <ng-container *ngIf="loaders.desktopView">
      <div class="filters-loaders">
        <div class="d-flex mt-4 pt-3 mb-2">
          <h4 class="w-75 mb-0" i18n="@@theStops">Stops</h4>
          <div class="w-25 ml-auto" i18n="@@starting">Starting</div>
        </div>

        <div class="main">
          <div class="form-checkboxes">
            <div class="d-flex">
              <div class="form-item form-type-checkbox mb-1 w-75">
                <div class="d-flex">
                  <input type="checkbox"/>
                  <!-- <label class="gray-text-dark d-flex"> Nonstop (<p class="lines mt-2" style="width: 20px"></p>)</label>-->
                  <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
                </div>
              </div>
              <div class="w-25 ml-auto d-flex">
                <span>$</span>
                <p class="w-100 lines mt-2 mx-2"></p>
              </div>
            </div>

            <div class="d-flex">
              <div class="form-item form-type-checkbox mb-1 w-75">
                <div class="d-flex">
                  <input type="checkbox"/>
                  <!-- <label class="gray-text-dark d-flex"> 1 Stop (<p class="lines mt-2" style="width: 20px"></p>)</label>-->
                  <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
                </div>
              </div>
              <div class="w-25 ml-auto d-flex">
                <span>$</span>
                <p class="w-100 lines mt-2 mx-2"></p>
              </div>
            </div>

            <div class="d-flex">
              <div class="form-item form-type-checkbox mb-1 w-75">
                <div class="d-flex">
                  <input type="checkbox"/>
                  <!--<label class="gray-text-dark d-flex"> 2+ Stops (<p class="lines mt-2" style="width: 20px"></p>)</label>-->
                  <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
                </div>
              </div>
              <div class="w-25 ml-auto d-flex">
                <span>$</span>
                <p class="w-100 lines mt-2 mx-2"></p>
              </div>
            </div>
          </div>
        </div>






        <div class="d-flex mt-4 pt-3 mb-2">
          <h4 class="w-75 mb-0" i18n="@@airlines">Airlines</h4>
          <div class="lines w-25 ml-auto"></div>
        </div>

        <div class="main">
          <div class="form-checkboxes">
            <div class="d-flex" *ngFor="let item of [].constructor(4)">
              <div class="form-item form-type-checkbox mb-1 w-75">
                <div class="d-flex">
                  <input type="checkbox"/>
                  <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
                </div>
              </div>
              <div class="w-25 ml-auto d-flex">
                <span>$</span>
                <p class="w-100 lines mt-2 mx-2"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mt-4 pt-3 mb-2">
          <h4 class="w-75 mb-0" i18n="@@airports">Airports</h4>
          <div class="w-25 ml-auto"></div>
        </div>
        <div class="d-flex mb-3">
          <div class="mb-0 lines" style="width: 65%"></div>
          <div class="lines w-25 ml-auto"></div>
        </div>
        <div class="main">
          <div class="form-checkboxes">
            <div class="d-flex" *ngFor="let item of [].constructor(4)">
              <div class="form-item form-type-checkbox mb-1 w-75">
                <div class="d-flex">
                  <input type="checkbox"/>
                  <label class="gray-text-dark d-flex"><p class="lines mt-2" style="width: 85px"></p></label>
                </div>
              </div>
              <div class="w-25 ml-auto d-flex">
                <span>$</span>
                <p class="w-100 lines mt-2 mx-2"></p>
              </div>
            </div>
          </div>
        </div>






      </div>

    </ng-container>
  </ng-container>

  <div *ngIf="this.loaders.flightListLoader && !loaderName">
    <ng-container *ngIf="loaders.desktopView">
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
            <div class="pt-4 pb-4"  [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="d-flex">
                          <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                          <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                        </div>


                      </div>

                      <div class="tax">
                        <div class="lines" style="height: 48px; margin-top: 20px"></div>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
            <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="d-flex">
                          <div class="lines-small w-100 " [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                          <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                        </div>


                      </div>

                      <div class="tax">
                        <div class="lines" style="height: 48px; margin-top: 20px"></div>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow d-none d-md-block">

        <div class="row">

          <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
            <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="d-flex">
                          <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                          <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                        </div>


                      </div>

                      <div class="tax">
                        <div class="lines" style="height: 48px; margin-top: 20px"></div>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 "  *ngIf="!router.url.includes('flights')">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
            <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="d-flex">
                          <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                          <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                        </div>


                      </div>

                      <div class="tax">
                        <div class="lines" style="height: 48px; margin-top: 20px"></div>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 "  *ngIf="!router.url.includes('flights')">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7-8 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
            <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="d-flex">
                          <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                          <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                        </div>


                      </div>

                      <div class="tax">
                        <div class="lines" style="height: 48px; margin-top: 20px"></div>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 " *ngIf="!router.url.includes('flights')">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 " [ngClass]="{'col-lg-8': !this.router.url.includes('flights'), 'col-lg-12': this.router.url.includes('flights')}">
            <div class="pt-4 pb-4" [ngClass]="{'ml-5': this.router.url.includes('flights') && loaders.isSiteLtr(), 'mr-5': this.router.url.includes('flights') && loaders.isSiteRtl(),
        'pr-4': loaders.isSiteLtr(), 'pl-4': loaders.isSiteRtl()}">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-md-6" [ngClass]="{'col-lg-7': !this.router.url.includes('flights'), 'col-lg-8': this.router.url.includes('flights')}">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 cash-back-cont" [ngClass]="{'col-lg-5': !this.router.url.includes('flights'), 'col-lg-4': this.router.url.includes('flights')}">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="d-flex">
                          <div class="lines-small w-100" [ngClass]="{'mr-3': loaders.isSiteLtr(), 'ml-3': loaders.isSiteRtl()}"></div>
                          <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>


                        </div>


                      </div>

                      <div class="tax">
                        <div class="lines" style="height: 48px; margin-top: 20px"></div>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>



    </ng-container>
    <ng-container *ngIf="loaders.mobileView">
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>
      <div class="not-mobile hotel-item rounded mb-4 shadow">

        <div class="row">

          <div class="col-md-5 col-lg-4 ">
            <div class="hotel_image shine"  style="    height: 235px;">





            </div>


          </div>
          <div class="col-md-7 col-lg-8 ">
            <div class="p-4">
              <h2 class="b w-75"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
              </a>
              </h2>
              <div class="row">
                <div class="col-lg-7 col-md-6">

                  <div class="hotel_info">
                    <div class="teaser_top">

                      <h2 class="b w-50 mb-2"><a href="#" target="_blank" class="primaray-text font-weight-bold">   <div class="lines"></div>
                      </a>
                      </h2>






                    </div>


                  </div>
                </div>
                <div class="col-md-6 col-lg-5 cash-back-cont">

                  <div class="price position-relative">
                    <div class="">

                      <div class="value">
                      </div>
                      <div class="current_price clearfix">



                        <br/>
                      </div>
                    </div>
                    <div class="">
                      <div class="cashback position-relative" >
                        <div class="d-flex">
                          <div class="lines" [ngClass]="{'ml-auto': loaders.isSiteLtr(), 'mr-auto': loaders.isSiteRtl()}"></div>

                        </div>

                        <div class="row justify-content-end">
                          <div class="col-4">
                            <div class="lines-small w-100  "></div>
                            <div class="lines-small w-100  "></div>

                          </div>


                        </div>


                      </div>


                    </div>

                  </div>



                </div>
              </div>
            </div>

          </div>



        </div>


      </div>

    </ng-container>


  </div>


</ng-container>









<div  class="loading_overlay" *ngIf="this.loaders.bookingSubmit || this.loaders.flightBookingSubmit">
  <div id="preloaderSpinner" class="preloader spinner">
    <div class="spinWrap">
      <p class="loader"></p>
      <p class="loader-img"></p>

      <p class="loadingMessage" id="spinnerMessage">{{loaders.loaderText}}</p>
      <p class="loadingSubHeading" id="spinnerSubHeading"></p>
    </div>
  </div>
</div>


<div *ngIf="this.loaders.hotelPage" class="container">
  <div class="row mt-5">

    <div class="col-lg-9 col-md-8">
      <div class="L-lines w-25 "></div>
      <div class="lines-small w-50 mt-3 mb-5"></div>
      <div class="row gutters-5 pt-4 d-none d-md-flex">

        <div class="col-md-2">
          <div class="hotel_image shine"  style="height: 400px;">

          </div>
        </div>
          <div class="col-md-8">
            <div class="hotel_image shine"  style="height: 400px;">

            </div>
          </div>
            <div class="col-md-2">
              <div class="hotel_image shine"  style="height: 400px;">

              </div>

            </div>

          </div>


      <div class="row gutters-5 d-flex d-md-none mb-4">
        <div class="col-3">
          <div class="d-flex"><div  class="rounded-circle x-lines-small" [ngClass]="{'mr-2': loaders.isSiteLtr(), 'ml-2': loaders.isSiteRtl()}" style=" width:15px; height: 15px; margin-top: 6px"></div> <div class="x-lines-small w-75 d-inline-block"></div></div>
        </div>
        <div class="col-3">
          <div class="d-flex"><div  class="rounded-circle x-lines-small" [ngClass]="{'mr-2': loaders.isSiteLtr(), 'ml-2': loaders.isSiteRtl()}" style=" width:15px; height: 15px; margin-top: 6px"></div> <div class="x-lines-small w-75 d-inline-block"></div></div>
        </div>

      </div>

      <div class="hotel_image shine d-block d-md-none"  style="height: 250px;">
      </div>



      </div>

    <div class="col-lg-3 col-md-4" >
      <div class="border-light rounded p-4 text-center" >
        <div class="lines-small w-50 mt-5 mb-2 d-inline-block"></div>
<div class="d-flex">

  <div class="lines-small w-75 d-inline-block"></div>
  <div class="rounded-circle lines-small ml-3" style=" width:15px; height: 15px; margin-top: 6px"></div>
</div>


        <div class="hotel_image shine mt-3 rounded"  style="    height: 45px;">


        </div>
      </div>


    </div>
      </div>
  <div class="hotel_image shine mt-5 "  style="    height: 45px;">


  </div>




</div>



<div  class="container mt-5" *ngIf="this.loaders.bookingPage || this.loaders.flightBookingPage">
  <div class="row">
    <div class="col-md-8">
      <div  class="lines w-75 mt-3 mb-5"></div>
      <div  class="x-lines-small w-50 mt-3 mb-5"></div>
      <div  class="hotel_image shine px-3  py-1 rounded">
        <div  class="lines w-75  mb-3 light"></div>
        <div  class="lines w-50 mb-3 light"></div>

      </div>
      <div  class="hotel_image shine px-3 mt-5  py-1 rounded-top">
        <div  class="lines w-75  mb-3 light"></div>

      </div>
      <div class="border-loader rounded-bottom p-3" style="height:180px;">
        <div  class="lines w-75  mb-3 light"></div>
        <div  class="lines w-25 mb-3 light"></div>


      </div>



      <div  class="hotel_image shine px-3 mt-5  py-1 rounded-top  d-md-block d-none">
        <div  class="lines w-25  mb-3 light"></div>

      </div>
      <div class="border-loader rounded-bottom p-3  d-md-block d-none" style="height:180px;">
        <div class="row">
          <div class="col-md-7">
            <div  class="lines w-50  mb-3 light"></div>
            <div class="row">
              <div class="col-md-5">
                <div class="d-flex">
                  <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>
                  <div class="lines-small w-100 ml-3 "></div>



                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex">
                  <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>
                  <div class="lines-small w-100 ml-3 "></div>



                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex">
                  <div class="rounded-circle lines-small" style="width:15px; height: 15px; margin-top: 6px"></div>

                  <div class="lines-small w-100 ml-3 "></div>


                </div>
              </div>
            </div>

          </div>

          <div class="col-md-5">
            <div [ngClass]="{'border-left pl-3': loaders.isSiteLtr(), 'border-right pr-3': loaders.isSiteRtl()}">
              <div  class="lines   mb-4"></div>
<div class="w-75">
  <div class="row gutters-5">
    <div class="col-md-3 mb-2">
      <div class="rounded hotel_image shine " style="height: 30px"></div>
    </div>
    <div class="col-md-3 mb-2">
      <div class="rounded hotel_image shine " style="height: 30px"></div>
    </div>    <div class="col-md-3 mb-2">
    <div class="rounded hotel_image shine " style="height: 30px"></div>
  </div>    <div class="col-md-3 mb-2">
    <div class="rounded hotel_image shine " style="height: 30px"></div>
  </div>
    <div class="col-md-3 mb-2">
      <div class="rounded hotel_image shine " style="height: 30px"></div>
    </div>
    <div class="col-md-3 mb-2">
      <div class="rounded hotel_image shine " style="height: 30px"></div>
    </div>    <div class="col-md-3 mb-2">
    <div class="rounded hotel_image shine " style="height: 30px"></div>
  </div>    <div class="col-md-3 mb-2">
    <div class="rounded hotel_image shine " style="height: 30px"></div>
  </div>
  </div>

</div>

            </div>

          </div>
        </div>



      </div>

    </div>
    <div class="col-md-4  d-md-block d-none">
      <div  class=" p-0 mb-4">
        <div   class="hotel_image shine  rounded-top" style="height: 250px;"></div>

<div class="border-loader rounded-bottom p-3">
  <div  class="lines w-75  mb-3 light"></div>
  <div  class="lines w-25 mb-3 light"></div>


</div>
      </div>
    </div>


  </div>


</div>
