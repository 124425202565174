<div class="workspace-login main-container pb-5" [ngClass]="{'gray-pass-bg' : dataService.desktopView}">
  <div class="container pb-5" [ngClass]="{'desktop-design text-center': dataService.desktopView, 'mb-5 pt-5 pt-lg-2': dataService.mobileView}"   *ngIf="!signUpErrorMsg && !invalidInvitation">
    <img width="471" height="422" loading="lazy" class="fixed-img rounded" src="assets/img/triphop-Signup-photo.jpg" *ngIf="dataService.desktopView">
 <!--   <div class="custom-container mx-auto" >-->
      <div class="login-form px-lg-5 py-5 corporate-box workspace-registration-container" [ngClass]="{'white-box position-relative' : dataService.desktopView, 'mx-auto': dataService.mobileView,
      'mr-auto': dataService.isSiteLtr() && dataService.desktopView, 'ml-auto': dataService.isSiteRtl() && dataService.desktopView}">
        <div class="text-center">
          <h4 class="font-24 pt-md-4 mb-3 font-weight-bold text-center" i18n="@@inviteSignUp1">
            Sign up to {{companyName}}
          </h4>
        </div>
        <p class="gray-text font-16 pb-4 mb-3 text-center px-4 workspace-registration-container mx-auto" *ngIf="!dataService.corporateLocale.includes('ar')" i18n="@@inviteSignUp2" [innerHTML]="'Enter your name and password to &lt;br&gt; sign up'"></p>
        <p class="gray-text font-16 pb-4 mb-3 text-center px-4 workspace-registration-container mx-auto" *ngIf="dataService.corporateLocale.includes('ar')" i18n="@@inviteSignUp2">Enter your name and password to &lt;br&gt; sign up</p>
        <form name="loginForm" [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUp()" class="pt-3 px-lg-3 pb-2" [ngClass]="{ 'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
          <div class="form-group with-icon position-relative form-type-textfield form-name-email d-flex align-items-center">
            <input  class="form-control" placeholder="{{email}}" disabled />
          <!--  <input type="email" name="email"  class="form-control extra-height email-without-domain" formControlName="email" autocomplete="off" />-->
           <!-- <p class="pl-2 pl-lg-0 gray-text mb-0 mx-auto">{{companyDomain}}</p>-->
          </div>
          <div class="row gutters-5">
            <div class="col-md-6">
              <div class="form-group mb-2">
                <input type="text" name="firstName"  class="form-control placeholder-text-field" formControlName="firstName" placeholder="First Name" autocomplete="off"
                       i18n-placeholder="@@firstNamePlaceHolder" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <div *ngIf="submitted && f.firstName.errors" class="text-danger smaller mb-2">
                  <div *ngIf="f.firstName.errors.required" i18n="@@requiredFirstNameError">First name is required</div>
                  <div *ngIf="f.firstName.errors.pattern" i18n="@@englishCharsAreAllowedError">Only English characters are allowed</div>

                </div>
              </div>


            </div>
            <div class="col-md-6">
              <div class="form-group mb-2">
                <input type="text" name="lastName"  class="form-control placeholder-text-field" formControlName="lastName" placeholder="Last Name" autocomplete="off"
                       i18n-placeholder="@@lastNamePlaceHolder" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"  />
                <div *ngIf="submitted && f.lastName.errors" class="text-danger smaller mb-2">
                  <div *ngIf="f.lastName.errors.required" i18n="@@requiredLastNameError">Last name is required</div>
                  <div *ngIf="f.lastName.errors.pattern" i18n="@@englishCharsAreAllowedError">Only English characters are allowed</div>

                </div>
              </div>
            </div>
          </div>
          <p class="gray-text font-12 mb-4 pb-2" i18n="@@inviteSignUp3">Enter your correct name, it cannot be changed later</p>
          <div class="form-group with-icon position-relative  form-type-password form-name-password">
            <input (keyup)="wrongPassFlag = false" class="form-control placeholder-text-field" formControlName="password" placeholder="Enter Your Password" autocomplete="new-password"
                   i18n-placeholder="@@enterPasswordPlaceHolder" [type]="this.dataService.isTextFieldType ? 'password' : 'text'" [ngClass]="{ 'is-invalid':  submitted  && f.password.errors}" />
            <i class="pointer" (click)="this.dataService.togglePasswordFieldType()"  [ngClass]="this.dataService.isTextFieldType ? 'icon-eye' : 'icon-eye-blocked'"></i>
          </div>
          <div *ngIf="submitted && f.password.errors" class="text-danger smaller mb-2">
            <div *ngIf="f.password.errors.required" i18n="@@requiredPasswordError">Password is required</div>

          </div>
            <p class="gray-text pt-4"> <ng-container i18n="@@create8">By creating an account, you agree to SafarHub's </ng-container> <a i18n="@@terms" class="btn-link px-2"  [href]="env.termsUrl" target="_blank"> Terms </a> <ng-container i18n="@@and"> and </ng-container> <a i18n="@@privacy" class="btn-link" [ngClass]="{ 'px-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl() }" [href]="env.privacyUrl" target="_blank"> Privacy Policy </a> <ng-container i18n="@@create9" *ngIf="dataService.corporateLocale.includes('ar')"> SafarHub </ng-container></p>

          <div class="row mt-lg-5" >
            <div class="col-12" [ngClass]="{ 'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
              <button  [ngClass]="{'m-progress': submitting }" class="btn green_btn btn-block font-weight-normal font-14 px-5 mt-auto workspace-btn-padding">
                <span class="mx-auto" i18n="@@signUpBtn">Sign Up</span>

              </button>
              <div class="text-danger mt-2">{{this.errorMsg}}</div>
            </div>
            <div class="col-12 d-flex align-items-center" [ngClass]="{ 'pr-lg-0': dataService.isSiteLtr(), 'pl-lg-0': dataService.isSiteRtl()}">
              <div>
                <p class="mb-0 mt-4"><a class="blue-text" (click)="this.router.navigate(['/' + companyID + '/login']);" i18n="@@alreadyMember">Already a member? Sign in</a></p>
              </div>
            </div>
          </div>

        </form>

      </div>

   <!-- </div>-->
  </div>


  <app-login-error-page *ngIf="signUpErrorMsg"></app-login-error-page>
  <app-login-error-page *ngIf="invalidInvitation" errorMsg="{{this.invalidInvitation}}"></app-login-error-page>
</div>
