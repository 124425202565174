import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SearchControllerMobileComponent } from '../search-controller-mobile/search-controller-mobile.component';
import { SearchControllerComponent } from '../search-controller/search-controller.component';
import { NgbDropdownModule, NgbCollapseModule, NgbTabsetModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatListModule
} from '@angular/material';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ForgotPasswordComponent } from '../controls/forgot-password/forgot-password.component';
import { CountryCode } from '../misc/country-code/country-code.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { PrintBookingComponent } from '../booking/print-booking/print-booking.component';
import { PrintFlightBookingComponent } from '../booking/print-flight-booking/print-flight-booking.component';
import { NotificationsComponent } from '../services/notifications/notifications.component';
import { LoadersComponent } from '../services/loaders/loaders.component';
import { NewPaymentCardComponent } from '../payment/new-payment-card/new-payment-card.component';
import { TagInputModule } from 'ngx-chips';
import { MessagesComponent } from '../services/messages/messages.component';
import { FlightsSearchControllerComponent } from '../flights-search/flights-search-controller/flights-search-controller.component';
import { FlightsSearchControllerMobileComponent } from '../flights-search/flights-search-controller-mobile/flights-search-controller-mobile.component';
import { FlightComponent } from '../availability/flight/flight.component';
import { AddEditFlyerProgramComponent } from '../user/add-edit-flyer-program/add-edit-flyer-program.component';
import { AddEditTravelDocumentComponent } from '../user/add-edit-travel-document/add-edit-travel-document.component';
import { RemoveProgramOrPassportComponent } from '../user/remove-program-or-passport/remove-program-or-passport.component';
import { DemoMaterialModule } from '../material-module';
import { SanitizeHtmlPipe } from "../services/sanitize-html.pipe";
import { AppLoaderComponent } from './components/loader/loader.component';

@NgModule({
  imports: [DemoMaterialModule, CommonModule, RouterModule, ReactiveFormsModule, NgbDropdownModule, NgbCollapseModule, NgbModule,
    NgbTabsetModule, SwiperModule, TagInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC4kJNUVBZ9__sTRMrWzyUwXEJVSwlsGac&libraries'
    }),
    AgmCoreModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatListModule, FormsModule
  ],
  declarations: [SanitizeHtmlPipe, AddEditFlyerProgramComponent, AddEditTravelDocumentComponent, RemoveProgramOrPassportComponent,
    FlightsSearchControllerMobileComponent, FlightsSearchControllerComponent, MessagesComponent, FlightComponent,
    NewPaymentCardComponent, PrintBookingComponent, PrintFlightBookingComponent, NotificationsComponent,
    SearchControllerMobileComponent, SearchControllerComponent,
    LoadersComponent, ForgotPasswordComponent, CountryCode, AppLoaderComponent],
  exports: [SanitizeHtmlPipe, AddEditFlyerProgramComponent, AddEditTravelDocumentComponent, RemoveProgramOrPassportComponent,
    FlightsSearchControllerMobileComponent, FlightsSearchControllerComponent, MessagesComponent, FlightComponent,
    NewPaymentCardComponent,
    SearchControllerMobileComponent, SearchControllerComponent, NgbDropdownModule, NgbCollapseModule, NgbTabsetModule, NgbModule,
    PrintBookingComponent, PrintFlightBookingComponent, NotificationsComponent, LoadersComponent,
    ForgotPasswordComponent, CountryCode, AppLoaderComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [SanitizeHtmlPipe, AddEditFlyerProgramComponent, AddEditTravelDocumentComponent, RemoveProgramOrPassportComponent,
        FlightsSearchControllerMobileComponent, FlightsSearchControllerComponent, MessagesComponent, FlightComponent,
        NewPaymentCardComponent, SearchControllerMobileComponent, SearchControllerComponent, NgbDropdownModule, NgbModule,
        NgbCollapseModule, NgbTabsetModule, AgmCoreModule, LoadersComponent,
        PrintBookingComponent, PrintFlightBookingComponent, NotificationsComponent,
        ForgotPasswordComponent, CountryCode]
    };
  }
}
