import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../workspace-dashboard/animations/animations';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../auth/auth.service';
import {User} from "../create-team/create-team.component";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-create-edit-team-page',
  templateUrl: './create-edit-team-page.component.html',
  styleUrls: ['./create-edit-team-page.component.scss'],
  animations: [ onMainContentChange ]
})
export class CreateEditTeamPageComponent implements OnInit {

  constructor(private titleService: Title, public dataService: DataService, private sidenavService: SidenavService, public router: Router, public auth: AuthServiceMain) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  urlArray;
  teamId;
  data;
//  teamName;
  ngOnInit(): void {
    this.dataService.createEditTeamPage = true;
    this.dataService.createEditPolicyPage = false;
    this.urlArray = (this.router.url).split('/');
    if(this.router.url.includes('teams') && this.router.url.includes('edit')) { // as no directive in this case (page not a modal)
      this.teamId = this.urlArray[3];
      this.titleService.setTitle('Edit team | SafarHub');
  /*    this.dataService.getTeamDetails(this.teamId).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.teamName = this.data.data.name;
          } else {
            this.dataService.messages(this.data.message, 'error');
          }
        }, error => {
          this.dataService.messages(environment.messages.error, 'error');
        }
      );*/
    }
    if(!this.teamId) {
      this.titleService.setTitle('Create team | SafarHub');
    }
  }

}
