import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { FormControl, NgModel } from '@angular/forms';
import {throwError, of, empty, Observable} from 'rxjs';
import { TagInputComponent as SourceTagInput } from 'ngx-chips';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

declare let fbAsyncInit: Function;
declare let window: any;
declare let FB: any;
export interface Email {
  address: string;
}
export class User {
  constructor(public description: string, public id: string, public email: string, public teamName?: string, public selected?: boolean) {
    if (selected === undefined) { selected = false;}
  }
}
@Component({
  selector: 'app-company-share-voucher',
  templateUrl: './company-share-voucher.component.html',
  styleUrls: ['./company-share-voucher.component.scss']
})
export class CompanyShareVoucherComponent implements OnInit {

  constructor(private route: ActivatedRoute, public Service: DataService, public router: Router) {}
  @ViewChild('teamInput') teamInput: ElementRef;
  showClearIcon = false;
  sendEmailFlag = false;
  emails = [] ;
  emailsGroup = '';
  placeholder = true;


  data;
  submitting;
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = '';
  dataUsers;
  submitted;
  refFromURLValue;
  identifierFromURLValue;
  emailFromURL = 'email';
  emailFromURLValue;
  errorMsg;
  @ViewChild('userInput') userInput: ElementRef;
  @ViewChild('tagInput', {static: true})
  tagInput: SourceTagInput;
  public validators = [ this.must_be_email.bind(this) ];
  public errorMessages = {
    must_be_email: $localize`:@@emailFormat:Please be sure to use a valid email format`
  };
  public onAddedFunc = this.beforeAdd.bind(this);
  private addFirstAttemptFailed = false;
  private must_be_email(control: FormControl) {

    if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
      return { must_be_email: true };
    }
    return null;
  }

  private removeFromArray(item, index) {
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  private beforeAdd(tag) {

    if (typeof tag === 'object') {
      tag = tag.value;
    }
    if (!this.validateEmail(tag)) {
      if (!this.addFirstAttemptFailed) {
        this.tagInput.setInputValue(tag);
      }
      return throwError(this.errorMessages.must_be_email);
    }

    this.addFirstAttemptFailed = false;
    this.emails.push(tag);
    return of(tag);
  }
  private validateEmail(text: string) {
    const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    return (text && EMAIL_REGEXP.test(text));
  }
  // End of Input Tag
  ngOnInit(): void {
    if (this.Service.desktopView) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('workspace-modal');
    }
    //this.usersAutoComplete();
    if (('' + this.router.url).indexOf('identifier') > 0) {
      this.identifierFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('identifier=') + 11);
      this.refFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('?') + 5, ('' + this.router.url).indexOf('&'));

    } else {
      this.route.queryParams.subscribe(params => {
        this.emailFromURLValue = params[this.emailFromURL];
      });

      this.identifierFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('&email=') + 7, ('' + this.router.url).indexOf('&lastname'));
      this.refFromURLValue = ('' + this.router.url).substring(('' + this.router.url).indexOf('?') + 5, ('' + this.router.url).indexOf('&'));

    }
  }
  submitVoucher() {
    this.submitting = true;
    this.Service.alerts = [];

    if (this.emails.length === 0) {
      this.submitting = false;
      return;
    }
let emailsArray = '';
    let stringEmails = '';
    this.emails.forEach((email, index) => {
      if(index === 0){
        emailsArray = '?emails=' + email;
        stringEmails = email;
      } else{
        emailsArray += '&emails=' + email;
        stringEmails += ', ' + email;
      }
    });

    setTimeout(() => {
      debugger
      this.Service.shareBookingByEmail(emailsArray, this.refFromURLValue).subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.tagInput.items = [];
              this.sendEmailFlag = true;
              this.emails = [] ;
              this.errorMsg = '';
              this.submitting = false;
              this.Service.messages($localize`:@@voucherSent:A voucher has been sent to` + ' ' + stringEmails, 'success');
              this.submitted = false;
              this.Service.modalOf();
              this.Service.BottomSheetOf();
            } else {
              this.errorMsg = this.data.message;
              this.submitting = false;
              this.Service.scrollTop();

            }

          },
          error => {
            this.submitting = false;
            this.errorMsg = environment.messages.error;
            this.Service.scrollTop();
          }
      );
    }, 2000);











    /*  this.Service.sendCompanyBookingVoucher(this.refFromURLValue, this.selectedUser.id).subscribe(
        data => {

          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitting = false;
            this.errorMsg = '';
            this.Service.modalOf();
            this.Service.BottomSheetOf();
            this.Service.scrollTop();
            this.Service.messages($localize`:@@voucherSent:A voucher has been sent to` + ' ' + this.selectedUser.email, 'success');
          } else {
            this.submitting = false;
            this.errorMsg = this.data.message;
          }

        }, error => {
          this.submitting = false;

          this.errorMsg = environment.messages.error;
        }
      );*/
  }
  usersAutoComplete() {
    this.Service.getCompanyUsersList().subscribe(
      dataList => {
        this.dataUsers = dataList;
        if (this.dataUsers.errorCode === 0) {
          this.dataUsers = this.dataUsers.data;

          this.dataUsers.forEach((element) => {
            if (element.teamName) {
              this.users.push(new User(element.description, element.id, element.email, element.teamName));
            } else {
              this.users.push(new User(element.description, element.id, element.email));
            }
          });
          this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith<string | User[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
          );

        } else {
          this.Service.messages(this.data.message, 'error');
        }
      }, error => {
        this.Service.messages(environment.messages.error, 'error');
      }
    );
  }
  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter(option => {
        return option.description.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.users.slice();
    }
  }
  displayFn(user: User): string {
    return user && user.description ? user.description : '';
  }
  reset() {
    setTimeout(() => {
      if (typeof this.userControl.value !== 'object') {
        this.userInput.nativeElement.value = '';
        this.userControl.setValue(null);
        this.lastFilter = '';
        this.selectedUser = null;
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith<string | User[]>(''),
          map(value => typeof value === 'string' ? value : this.lastFilter),
          map(filter => this.filter(filter))
        );
      }
    }, 400);
  }

  optionClicked(event: Event, user: User) {
    this.selectedUser = user;
    this.showUsersAutoCompletePopUp = false;
    this.Service.enableBodyScroll();
    if (this.Service.desktopView) {
      setTimeout(() => {
      const input = document.getElementById('userInputHtml') as HTMLInputElement;
      input.blur();
    }, 200);
    }
  }
  showUsersPopUp() {
    this.showUsersAutoCompletePopUp = true;
    this.Service.disableBodyScroll();
    if (this.selectedUser) {
      this.userControl.setValue(this.selectedUser);
    }
    setTimeout(() => {
      const input = document.getElementById('userInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }
}
