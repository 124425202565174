import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { SidenavService } from '../../../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../../../workspace-dashboard/animations/animations';
import { Router } from '@angular/router';
import { AuthServiceMain } from '../../../../auth/auth.service';
import { VariablesService } from '../../../../services/vars.service';
import * as moment from 'moment';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
  animations: [onMainContentChange],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class RequestsListComponent implements OnInit {

  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
    public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('My travel requests list | SafarHub');
    this.sidenavService.sideNavState$.subscribe(res => {
      this.dataService.onSideNavChange = res;
    });
  }
  data;
  approvalActions = false;
  selectedIndex;
  flightApprovalActions = false;
  selectedIndexFlight;
  ngOnInit(): void {
    this.dataService.getAllListRequesterApprovals();
  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.approval-drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.approval-drop-down' + this.selectedIndex).contains(event.target)) {
      this.approvalActions = false;
    }
    if (this.eref.nativeElement.querySelector('.flight-approval-drop-down' + this.selectedIndexFlight) && !this.eref.nativeElement.querySelector('.flight-approval-drop-down' + this.selectedIndexFlight).contains(event.target)) {
      this.flightApprovalActions = false;
    }
  }
  filterBy(filter) {
    this.dataService.requestSortType = filter;
    if (filter === 'approved') {
      this.dataService.requesterFilteredApprovalsList = this.dataService.requesterApprovedArray.slice();
      this.dataService.requesterFilteredApprovalsFlightList = this.dataService.requesterApprovedArrayFlight.slice();
    } else if (filter === 'pending_approval') {
      this.dataService.requesterFilteredApprovalsList = this.dataService.requesterPendingArray.slice();
      this.dataService.requesterFilteredApprovalsFlightList = this.dataService.requesterPendingArrayFlight.slice();
    } else if (filter === 'disapproved') {
      this.dataService.requesterFilteredApprovalsList = this.dataService.requesterDisapprovedArray.slice();
      this.dataService.requesterFilteredApprovalsFlightList = this.dataService.requesterDisapprovedArrayFlight.slice();
    } else if (filter === 'expired') {
      this.dataService.requesterFilteredApprovalsList = this.dataService.requesterExpiredArray.slice();
      this.dataService.requesterFilteredApprovalsFlightList = this.dataService.requesterExpiredArrayFlight.slice();
    } else if (filter === 'withdrawn') {
      this.dataService.requesterFilteredApprovalsList = this.dataService.requesterWithdrawnArray.slice();
      this.dataService.requesterFilteredApprovalsFlightList = this.dataService.requesterWithdrawnArrayFlight.slice();
    } else {
      this.dataService.requesterFilteredApprovalsList = this.dataService.requestsList.slice();
      this.dataService.requesterFilteredApprovalsFlightList = this.dataService.requestsFlightList.slice();
    }
  }
  /* formatCheckIn(date) {
     return moment(date).format('MMM') + ' ' + moment(date).format('DD');
   }*/
  formatCheckOut(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }
  withdrawApprovalRequestPopup(approvalId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@withdrawApprovalRequest:Withdraw Approval Request`, 'withdrawApprovalRequest', approvalId);
    } else {
      this.dataService.setBottomSheet('withdrawApprovalRequest', approvalId);
    }
  }
  withdrawApprovalRequestFlight(approvalId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@withdrawApprovalRequest:Withdraw Approval Request`, 'withdrawApprovalRequestFlight', approvalId);
    } else {
      this.dataService.setBottomSheet('withdrawApprovalRequestFlight', approvalId);
    }
  }
  refreshList() {
    this.dataService.getAllListRequesterApprovals();
  }
  /*formatDateSummary(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }*/
  /*formatDateSummaryNoYear(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }*/
}
