import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-policies-list',
  templateUrl: './policies-list.component.html',
  styleUrls: ['./policies-list.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class PoliciesListComponent implements OnInit {

  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
               public router: Router, public auth: AuthServiceMain) {
    this.titleService.setTitle('Travel policies list | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  data;
  urlArray;
  policyId;
  previousURL;
  removePolicyModalData;
  showActions = false;
  selectedIndex;
  ngOnInit(): void {
    this.urlArray = (this.router.url).split('/');
    this.policyId = this.urlArray[4];
    this.previousURL = this.urlArray[0] + '/' +  this.urlArray[1] + '/' +  this.urlArray[2];
    this.dataService.loadingFlag = false;
    this.dataService.getCompanyPolicies();
  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target) ){
      this.showActions = false;
    }
  }
  newPolicy() {
  /*  if (this.dataService.desktopView) {
      this.dataService.setModal('New Policy', 'createPolicy', '');
    } else {*/
      this.router.navigate([this.router.url + '/create']);
   // }
  }
  editPolicy(policyId) {
  /*  if (this.dataService.desktopView) {
      this.dataService.setModal('Edit Policy', 'editPolicy', policyId);
    } else {*/
      this.router.navigate([this.router.url + '/' + policyId + '/edit']);
   // }
  }
  removePolicy(policyIdParam, policyNameParam) {
    this.removePolicyModalData = {
      policyId: policyIdParam,
      policyName: policyNameParam
    }
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@removePolicy:Delete Policy`, 'removePolicy',  this.removePolicyModalData);
    } else {
      this.dataService.setBottomSheet('removePolicy',  this.removePolicyModalData);
    }
  }
}
