import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import * as moment from 'moment';
import {Location} from "@angular/common";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {environment} from "../../../environments/environment";

declare let window: any;

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {
  VoucherData;
  mapNearBy;
  hotelData;
  identifier;
  referenceNumber;
  upcomingBookingObj = {
    bookingStatus : 'CONFIRMED',
    includeUpcoming : true,
    includePast : false
  };
  dataUpcoming;
  constructor(@Inject(PLATFORM_ID) private platformId, public Service: DataService, public logedIn: AuthServiceMain, private titleService:Title, private route: ActivatedRoute, private router: Router) {
    this.titleService.setTitle('Booking Voucher | SafarHub');
  }

  ngOnInit() {
    this.Service.showHotelPrint = false;
    this.Service.showExpensifyInBooking();
    this.Service.newRate = null;
    if (isPlatformBrowser(this.platformId)) {

    this.Service.bookingSubmit = false;


    this.Service.alerts = [];
    this.Service.alertsOverRoute = [];


    if (JSON.parse(sessionStorage.getItem('hotelData'))) {
      this.hotelData = JSON.parse(sessionStorage.getItem('hotelData'));


    } else {

      this.hotelData = JSON.parse(sessionStorage.getItem('HoldRoomHotelData'));


    }

    if (sessionStorage.getItem('voucher')) {
      this.VoucherData = JSON.parse(sessionStorage.getItem('voucher'));
      this.Service.printHotelData = JSON.stringify(this.VoucherData.data);
      this.Service.expensifyLastModifiedDate  = this.VoucherData.data.expensifyLastModifiedDate;
      this.Service.expensifyLastReportName  = this.VoucherData.data.expensifyLastReportName;
      this.Service.triphopCardData = JSON.parse(sessionStorage.getItem('voucher'));
    } else {

      this.route.queryParams.subscribe(params => {
        let identifier = '';
        if (params.email) {

          identifier = params.email
        } else {

          identifier = params.lastname

        }
        if (this.Service.isCompany()) {
          this.router.navigate(['/' + this.logedIn.companyInfo.id + '/hotel/booking/details'], {queryParams: {ref: params.ref, identifier: identifier,},});
        } else{
          this.router.navigate(['bookings/manage'], {queryParams: {ref: params.ref, identifier: identifier,},});
        }


      });

    }


    if (!sessionStorage.getItem('voucurevist')) {


      window.dataLayer.push({
        event: 'fireRemarketing',

        ecomm_prodid: this.hotelData.giataId,
        ecomm_pagetype: 'purchase',
        ecomm_totalvalue: this.VoucherData.data.sellingPrice,
        hrental_id: this.hotelData.giataId,
        hrental_pagetype: 'conversionintent'


      });
      sessionStorage.setItem('voucurevist', 'true')
    }

    sessionStorage.removeItem('hotelData');
  }





      }


/*  formatDailyDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/

  print() {
    window.print();
  }
  openSendBookingVoucherPopUp() {
    if (this.Service.isCompany()) {
      if (this.Service.desktopView) {
        this.Service.setModal( $localize`:@@shareVoucher:Email to another address`, 'companySendBookingVoucher', '');
      } else {
        this.Service.setBottomSheet('companySendBookingVoucher', '');
      }
    } else {
      this.Service.setModal( $localize`:@@shareVoucher:SEmail to another address`, 'SendBookingVoucher', '');
    }
  }
  shareByEmailMobile() {
    if (this.Service.isCompany()) {
      this.Service.setBottomSheet('companySendBookingVoucher', '');
    } else {
      this.route.queryParams.subscribe(params => {
        this.referenceNumber = params.ref;
        if (params.email) {

          this.identifier = params.email;
        } else {

          this.identifier = params.lastname;

        }
        this.router.navigate(['/voucher/send'],
          { queryParams: { ref: this.referenceNumber, identifier: this.identifier } });
      });
    }
  }
  mapPopUp() {
    this.mapNearBy = {
      latitude: this.VoucherData.data.hotelLatitude,
      longitude: this.VoucherData.data.hotelLongitude
    };
    this.Service.setModal( this.VoucherData.data.hotelName, 'voucherMap', this.mapNearBy);
  }
  formatDate(date) {
    return 'Last upload date: ' + moment(date).format('DD') + ' ' + moment(date).format('MMM') + ', ' +  moment(date).format('YYYY');
  }
}
