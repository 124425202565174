import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
//import * as moment from 'moment';
import { AuthServiceMain } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Title } from "@angular/platform-browser";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-manage-flight-booking',
  templateUrl: './manage-flight-booking.component.html',
  styleUrls: ['./manage-flight-booking.component.scss']
})
export class ManageFlightBookingComponent implements OnInit {

  constructor(public datePipe: DatePipe, private titleService: Title, private http: HttpClient, public router: Router,
    public Service: DataService, private route: ActivatedRoute, private logedIn: AuthServiceMain) {
    this.titleService.setTitle('My trip details | SafarHub');
    this.Service.componentMethodCalled$.subscribe(
      () => {

        if (this.logedIn.isLogedIn && this.Service.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
          this.Service.inactiveWorkspace = true;
        }
        if (this.logedIn.isLogedIn && this.Service.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive === false) {
          this.Service.showExpensifyInBooking();
        }

      });

  }
  refFromURL = 'ref';
  refFromURLValue;
  identifierFromURL = 'identifier';
  identifierFromURLValue;
  type = 'LAST_NAME';
  retrieveObj;
  data;
  dataBooking;
  bookingDetails;
  departureDetails;
  returnDetails;
  summaryDeparture;
  summaryReturn;
  departRulesArray = [];
  departBag = [];
  returnRulesArray = [];
  returnBag = [];
  rules;
  // showPrint = false;
  bookingType;
  // printData;
  ngOnInit(): void {
    this.Service.showPrint = false;
    this.route.queryParams.subscribe(params => {
      this.refFromURLValue = params[this.refFromURL];
      this.identifierFromURLValue = params[this.identifierFromURL];
    });

    if (this.refFromURLValue !== null && this.identifierFromURLValue !== null && this.refFromURLValue !== undefined && this.identifierFromURLValue !== undefined) {
      this.Service.useForm = false;


      if (this.identifierFromURLValue.indexOf('@') > -1) {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'EMAIL',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      } else {
        this.retrieveObj = {
          customerIdentifier: {
            type: 'LAST_NAME',
            value: this.identifierFromURLValue
          },
          referenceNumber: this.refFromURLValue
        };
      }
      this.getBookingDetails(this.retrieveObj);
    }
  }
  getBookingDetails(retrieveObj) {

    if (this.logedIn.isLogedIn && this.Service.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
      this.Service.inactiveWorkspace = true;
    } else if (this.logedIn.isLogedIn && this.Service.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive === false) {
      this.Service.showExpensifyInBooking();
    }
    this.Service.retrieveAirBooking(retrieveObj).subscribe(
      dataBooking => {
        this.dataBooking = dataBooking;
        if (this.dataBooking.errorCode === 0) {



          this.bookingDetails = this.dataBooking.data;
          this.Service.printData = JSON.stringify(this.bookingDetails);
          if (this.dataBooking.data.bookingStatus === 'CANCELLED') {
            this.bookingType = 'cancelled';
          } else {
            if (this.dataBooking.data.past) {
              this.bookingType = 'completed';
            } else {
              this.bookingType = 'confirmed';
            }
          }
          this.summaryDeparture = this.dataBooking.data.airItinerary.originDestinationOptionsGroups[0].summary;
          this.departureDetails = this.dataBooking.data.airItinerary.originDestinationOptionsGroups[0].originDestinationOptions;
          if (this.dataBooking.data.airItinerary.originDestinationOptionsGroups[1]) {
            this.summaryReturn = this.dataBooking.data.airItinerary.originDestinationOptionsGroups[1].summary;
            this.returnDetails = this.dataBooking.data.airItinerary.originDestinationOptionsGroups[1].originDestinationOptions;
          }


          if (this.dataBooking.data.tripDetails && this.dataBooking.data.tripDetails.itineraryInfo.reservationItems) {
            this.rules = this.dataBooking.data.tripDetails.itineraryInfo.reservationItems;
            this.departureDetails.forEach((flight) => {
              this.rules.forEach((rule) => {
                if (rule.baggageDescription) {
                  if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departRulesArray.length) {
                    this.departRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggageDescription });
                    this.departBag.push(rule.baggageDescription);
                  } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departBag.includes(rule.baggageDescription)) {
                    this.departRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggageDescription });
                    this.departBag.push(rule.baggageDescription);
                  }
                } else {
                  if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departRulesArray.length) {
                    this.departRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggage });
                    this.departBag.push(rule.baggage);
                  } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departBag.includes(rule.baggage)) {
                    this.departRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggage });
                    this.departBag.push(rule.baggage);
                  }
                }
              });
            });

            if (this.dataBooking.data.airItinerary.originDestinationOptionsGroups[1]) {
              this.returnDetails.forEach((flight) => {
                this.rules.forEach((rule) => {
                  if (rule.baggageDescription) {
                    if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnRulesArray.length) {
                      this.returnRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggageDescription });
                      this.returnBag.push(rule.baggageDescription);
                    } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnBag.includes(rule.baggageDescription)) {
                      this.returnRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggageDescription });
                      this.returnBag.push(rule.baggageDescription);
                    }
                  } else {
                    if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnRulesArray.length) {
                      this.returnRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggage });
                      this.returnBag.push(rule.baggage);
                    } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnBag.includes(rule.baggage)) {
                      this.returnRulesArray.push({ flightNo: rule.flightNo, baggage: rule.baggage });
                      this.returnBag.push(rule.baggage);
                    }
                  }
                });
              });
            }
          }







        } else {
          this.Service.messages(this.dataBooking.message, 'error');
        }
      },
      error => {
        this.Service.messages(environment.messages.error, 'error');
      }
    );
  }
  print() {
    setTimeout(() => {
      window.print();
    }, 1000);


  }
  shareByEmailMobile() {
    if (this.Service.isCompany()) {
      this.Service.setBottomSheet('companySendBookingVoucher', '');
    } else {

    }
  }
  openSendBookingVoucherPopUp() {
    if (this.Service.isCompany()) {
      if (this.Service.desktopView) {
        this.Service.setModal($localize`:@@shareVoucher:Email to another address`, 'companySendBookingVoucher', '');
      } else {
        this.Service.setBottomSheet('companySendBookingVoucher', '');
      }
    } else {
      this.Service.setModal($localize`:@@shareVoucher:Email to another address`, 'SendBookingVoucher', '');
    }

  }
  /* formatDateSummary(date) {
     return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
   }*/
  /*formatDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/
}
