<div class="right-side-drawer bg-white animate-C" [ngStyle]="{'direction' : dataService.siteDirection.toLowerCase()}"
     [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl(), 'active': dataService.rightSideDrawerVisible, 'custom-box': this.dataService.rightSideDrawerName !== 'flightDetails'}">
  <i class="icon-close-light gray-text position-absolute close-right pointer" (click)="this.dataService.rightSideDrawerOff()" *ngIf="this.dataService.rightSideDrawerName !== 'flightDetails'"></i>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'travelDocument'" i18n="@@travelDoc">Travel Document</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'flyerProgram'" i18n="@@flyerProgramOne">Frequent Flyer Program</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'memberInfo'" i18n="@@memberInfo">Member info</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'memberPassword'" i18n="@@changePassword">Change Password</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'editCompanyInfo'" i18n="@@personalInfo">Company Info</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'editCompanyMainAddress'" i18n="@@officeAddress">Office address</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'editCompanyOtherAddress'" i18n="@@officeBranches">Office branches</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'addCompanyOtherAddress'" i18n="@@officeBranchesAdd">Add office branch</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'memberPreferences'" i18n="@@userPreferences">Preferences</div>
  <div class="border-bottom px-3 py-4 bold-600 font-16" *ngIf="this.dataService.rightSideDrawerName === 'companyPreferences'">Company Preferences</div>

<app-flight *ngIf="this.dataService.rightSideDrawerName === 'flightDetails'"></app-flight>
  <app-add-edit-travel-document *ngIf="this.dataService.rightSideDrawerName === 'travelDocument'"></app-add-edit-travel-document>
  <app-add-edit-flyer-program *ngIf="this.dataService.rightSideDrawerName === 'flyerProgram'"></app-add-edit-flyer-program>
  <app-update-user-info *ngIf="this.dataService.rightSideDrawerName === 'memberInfo'"></app-update-user-info>
  <app-user-password *ngIf="this.dataService.rightSideDrawerName === 'memberPassword'"></app-user-password>
  <app-account-update *ngIf="this.dataService.rightSideDrawerName === 'editCompanyInfo' ||
  this.dataService.rightSideDrawerName === 'editCompanyMainAddress' || this.dataService.rightSideDrawerName === 'editCompanyOtherAddress' || this.dataService.rightSideDrawerName === 'addCompanyOtherAddress'"></app-account-update>
 <app-update-user-preferences *ngIf="this.dataService.rightSideDrawerName === 'memberPreferences'"></app-update-user-preferences>
 <app-account-preferences-update *ngIf="this.dataService.rightSideDrawerName === 'companyPreferences'"></app-account-preferences-update>
</div>


<div *ngIf="this.dataService.rightSideDrawerOn"  (click)="this.dataService.rightSideDrawerOff()" class="modal-backdrop show"></div>
