import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import * as moment from 'moment';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import {environment} from '../../../environments/environment';
declare let window: any;
@Component({
  selector: 'app-flight-voucher',
  templateUrl: './flight-voucher.component.html',
  styleUrls: ['./flight-voucher.component.scss']
})
export class FlightVoucherComponent implements OnInit {
  VoucherData;
  flightData;
  identifier;
  referenceNumber;
/*  upcomingBookingObj = {
    bookingStatus : 'CONFIRMED',
    includeUpcoming : true,
    includePast : false
  };
  dataUpcoming;*/
  departureDetails;
  returnDetails;
  summaryDeparture;
  summaryReturn;
  departRulesArray = [];
  departBag = [];
  returnRulesArray = [];
  returnBag = [];
  rules;
  data;
  noTicketNo;
  constructor(@Inject(PLATFORM_ID) private platformId, public Service: DataService, public logedIn: AuthServiceMain, private titleService:Title, private route: ActivatedRoute, private router: Router) {
    this.titleService.setTitle('Booking Voucher | SafarHub');
  }

  ngOnInit() {
    this.Service.showPrint = false;
    this.Service.flightBookingSubmit = false;
   // this.Service.showExpensifyInBooking();
    this.Service.flightNewData = null;
    if (isPlatformBrowser(this.platformId)) {

      this.Service.bookingSubmit = false;


      this.Service.alerts = [];
      this.Service.alertsOverRoute = [];


      if (JSON.parse(sessionStorage.getItem('flightData'))) {
        this.flightData = JSON.parse(sessionStorage.getItem('flightData'));
      }

      if (sessionStorage.getItem('flightVoucher')) {
        this.VoucherData = JSON.parse(sessionStorage.getItem('flightVoucher'));
        // this.Service.getFlightRules(this.VoucherData.details.data.airItinerary.fareInfo.fareKey);

        this.summaryDeparture = this.VoucherData.details.data.airItinerary.originDestinationOptionsGroups[0].summary;
        if (this.VoucherData.trip === 'oneway') {
          this.departureDetails = this.VoucherData.details.data.airItinerary.originDestinationOptionsGroups[0].originDestinationOptions;
        } else if (this.VoucherData.trip === 'roundtrip') {
          this.summaryReturn = this.VoucherData.details.data.airItinerary.originDestinationOptionsGroups[1].summary;
          this.departureDetails = this.VoucherData.details.data.airItinerary.originDestinationOptionsGroups[0].originDestinationOptions;
          this.returnDetails = this.VoucherData.details.data.airItinerary.originDestinationOptionsGroups[1].originDestinationOptions;
        }
        if(this.VoucherData.details.data.tripDetails) {
          this.VoucherData.details.data.tripDetails.itineraryInfo.passengers.forEach((traveler) => {
            if(!traveler.eTicketNumber){
              this.noTicketNo = true;
            }
          });
        } else {
          this.noTicketNo = true;
        }

        /*    this.Service.flightRules(this.VoucherData.details.data.airItinerary.fareInfo.fareKey).subscribe(
              data => {
                this.data = data;
                if (this.data.errorCode === 0) {
                  this.rules = this.data.data.baggageInfo;*/
        this.Service.printData = JSON.stringify(this.VoucherData.details.data);
        if(this.VoucherData.details.data.tripDetails && this.VoucherData.details.data.tripDetails.itineraryInfo.reservationItems) {
          this.rules = this.VoucherData.details.data.tripDetails.itineraryInfo.reservationItems;
        this.departureDetails.forEach((flight) => {
          this.rules.forEach((rule) => {
            if(rule.baggageDescription) {
              if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departRulesArray.length) {
                this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                this.departBag.push(rule.baggageDescription);
              } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departBag.includes(rule.baggageDescription)) {
                this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                this.departBag.push(rule.baggageDescription);
              }
            } else{
              if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departRulesArray.length) {
                this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                this.departBag.push(rule.baggage);
              } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.departBag.includes(rule.baggage)) {
                this.departRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                this.departBag.push(rule.baggage);
              }
            }
          });
        });

        if (this.VoucherData.trip === 'roundtrip') {
          this.returnDetails.forEach((flight) => {
            this.rules.forEach((rule) => {
              if(rule.baggageDescription){
              if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnRulesArray.length) {
                this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                this.returnBag.push(rule.baggageDescription);
              } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnBag.includes(rule.baggageDescription)) {
                this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggageDescription});
                this.returnBag.push(rule.baggageDescription);
              }
            } else{
                if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnRulesArray.length) {
                  this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                  this.returnBag.push(rule.baggage);
                } else if (rule.marketingAirlineCode + rule.flightNumber === flight.flightSegment.marketingAirlineCode + flight.flightSegment.flightNumber && !this.returnBag.includes(rule.baggage)) {
                  this.returnRulesArray.push({flightNo: rule.flightNo, baggage: rule.baggage});
                  this.returnBag.push(rule.baggage);
                }
              }
            });
          });
        }
      }
          /*  } else {
              this.Service.errorMsg = this.data.message;
            }
          }, error => {
            this.Service.errorMsg = environment.messages.error;
          }
        );*/





     /*   this.Service.expensifyLastModifiedDate  = this.VoucherData.data.expensifyLastModifiedDate;
        this.Service.expensifyLastReportName  = this.VoucherData.data.expensifyLastReportName;
        this.Service.triphopCardData = JSON.parse(sessionStorage.getItem('voucher'));
        if(!environment.production) {
        }*/
        //  this.Service.PushNotificationsPopUp()

      } else {

        this.route.queryParams.subscribe(params => {
          let identifierVal = '';
          if (params.email) {
            identifierVal = params.email;
          } else {
            identifierVal = params.lastname;
          }
          if (this.Service.isCompany()) {
            this.router.navigate(['/' + this.logedIn.companyInfo.id + '/flight/booking/details'], {queryParams: {ref: params.ref, identifier: identifierVal}});
          } else{
          //  this.router.navigate(['bookings/manage'], {queryParams: {ref: params.ref, identifier: identifier,},});
          }


        });

      }


      if (!sessionStorage.getItem('voucurevist')) {


       /* window.dataLayer.push({
          event: 'fireRemarketing',

          ecomm_prodid: this.hotelData.giataId,
          ecomm_pagetype: 'purchase',
          ecomm_totalvalue: this.VoucherData.data.sellingPrice,
          hrental_id: this.hotelData.giataId,
          hrental_pagetype: 'conversionintent'


        });
        sessionStorage.setItem('voucurevist', 'true')*/
      }

      sessionStorage.removeItem('flightData');
    }

   /* if(!this.Service.isCompany() && this.logedIn.userPrime.currentPlan.prePass && this.logedIn.userPrime.currentPlan.prePass === true){

      this.logedIn.fetchUserData();



    }*/




  }

 /* formatDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/

  print() {
    setTimeout(() => {
      window.print();
    }, 1000);


  }
  openSendBookingVoucherPopUp() {
    if (this.Service.isCompany()) {
      if (this.Service.desktopView) {
        this.Service.setModal( $localize`:@@shareVoucher:Email to another address`, 'companySendBookingVoucher', '');
      } else {
        this.Service.setBottomSheet('companySendBookingVoucher', '');
      }
    } else {
      this.Service.setModal( $localize`:@@shareVoucher:Email to another address`, 'SendBookingVoucher', '');
    }
  }
  shareByEmailMobile() {
    if (this.Service.isCompany()) {
      this.Service.setBottomSheet('companySendBookingVoucher', '');
    } else {
      this.route.queryParams.subscribe(params => {
        this.referenceNumber = params.ref;
        if (params.email) {

          this.identifier = params.email;
        } else {

          this.identifier = params.lastname;

        }
        this.router.navigate(['/voucher/send'],
          { queryParams: { ref: this.referenceNumber, identifier: this.identifier } });
      });
    }
  }

  /*formatDateSummary(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }*/
}
