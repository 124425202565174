<div *ngIf="logedIn.isLogedIn" class="trips-page">
  <div *ngIf="!(canceledBooking && completedBooking && canceledBooking) || this.dataService.hotellistLoader" class="loading_icon_caption text-center mt-5"></div>
  <div *ngIf="!(canceledBooking && completedBooking && canceledBooking) ||this.dataService.hotellistLoader" class="loading-message text-center" i18n="@@loading"> Loading </div>

  <div *ngIf="canceledBooking && completedBooking && canceledBooking" >


    <div *ngIf="!dataService.isCompany() && upcomingBooking && upcomingBooking.length === 0 && completedBooking && completedBooking.length === 0 && completedBooking && canceledBooking.length === 0 && !this.dataService.hotellistLoader">

      <div class="row mt-5 pt-lg-3 trips">
        <div class="col-sm-6 mb-5">
          <h2 class="font-weight-bold pb-4 pt-3 pt-lg-3 gray-primaray-text font-18" *ngIf="!dataService.isCompany()">My Trips</h2>
          <p class="font-16 gray-text-dark mb-1">You have no trips for now</p>
          <p class="font-16 gray-text-dark">Your trips will appear here once you book.</p>
          <a class="green_btn mt-4 font-16 sharp-font w-100" routerLink="/" >Book Your Trip </a>
        </div>
        <div class="col-sm-6 pt-4">
          <img width="362" height="200" loading="lazy" class="initial-height" src="assets/img/no_search_reult.png">
        </div>
      </div>
    </div>

    <div class="company-section-with-thick-border border" [ngClass]="{'custom-container': dataService.desktopView,
    'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
    'thick-top rounded-bottom': dataService.mobileView}"
         *ngIf="dataService.isCompany() && upcomingBookingMain && upcomingBookingMain.length === 0 && completedBookingMain && completedBookingMain.length === 0 && completedBookingMain && canceledBookingMain.length === 0 && !this.dataService.hotellistLoader">
      <div class="row mx-0 p-4 h-100 justify-content-center align-items-center">
        <div class="col-md-8 font-16 ">
          <p class="mb-0 gray-primaray-text" i18n="@@myTripsMsg">Your trips will appear here once you book a trip.</p>
        </div>
        <div class="col-md-4" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
          <button   (click)="this.router.navigate(['/' + this.logedIn.companyInfo.id]);"
                    class="w-100 btn px-5 py-2 mt-4 mb-3 my-lg-2 rounded text-center sharp-font bold-500 ml-auto font-14 find-btn btn-primary" i18n="@@menuItemBookTrip">Book a trip</button>
        </div>
      </div>
    </div>

    <div *ngIf="upcomingBookingMain || completedBookingMain || canceledBookingMain" class="fav-hotels-in-trips max-width-600">

      <div *ngIf="(upcomingBookingMain &&upcomingBookingMain.length)  || (completedBookingMain && completedBookingMain.length )|| (canceledBookingMain && canceledBookingMain.length)  && !this.dataService.hotellistLoader">
        <div id="sticky-booking-menu" class="bookings-menu"
             [ngClass]="{'sticky' : isSticky}" *ngIf="!this.dataService.hotellistLoader">
          <a [ngClass]="{ 'selected': isUpcoming, 'disabled': upcomingBookingMain.length === 0 }" (click)="isUpcoming = true; isCompleted = false; isCanceled = false;  triggerScrollToOffsetOnly('upcoming');" class="bolder" i18n="@@upcoming">Upcoming</a> <span class="px-3">|</span>
          <a [ngClass]="{ 'selected': isCompleted, 'disabled': completedBookingMain.length === 0 }" (click)="isUpcoming = false; isCompleted = true; isCanceled = false; triggerScrollToOffsetOnly('completed');" class="bolder" i18n="@@completed">Completed</a> <span class="px-3">|</span>
          <a [ngClass]="{ 'selected': isCanceled, 'disabled': canceledBookingMain.length === 0 }" (click)="isUpcoming = false; isCompleted = false; isCanceled = true; triggerScrollToOffsetOnly('canceled');" class="bolder" i18n="@@canceled">Canceled</a>
        </div>
        <div class="col-md-4 px-0 form-group mt-4" *ngIf="dataService.inactiveWorkspace">
          <input  class="form-control" id="search"  type="text" name="search" i18n-placeholder="@@searchForUsers" placeholder="Search for users"  (focus)="filterByText($event);" (keyup)="filterByText($event);"/>
        </div>
        <div>

          <p id="upcoming" class="gray-primaray-text font-18-to-16 bold-600 my-4" *ngIf="upcomingBooking && upcomingBooking.length !==0 " i18n="@@upcomingStays">Upcoming Stays</p>
          <div *ngFor="let item of upcomingBooking; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <div class="d-flex">
                <i class="icon-hotel-item font-25 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mt-1 mb-2">{{dataService.formatDateEEMMMdy(item.checkinDate)}}</p>
              </div>
            <div class="mb-5 hotel-item rounded pointer position-relative p-4" [ngClass]="{'d-flex': dataService.desktopView && !dataService.isCompany()}" (click)="viewDetails({ref: item.referenceNumber, identifier : item.emailAddress}, item.bookingType)">


              <div class="details" [ngClass]="{'w-80': dataService.desktopView && !dataService.isCompany()}">
                        <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{item.hotelName}}</h2>
                          <div class="gray-text mb-3 pb-3 pr-3">{{item.hotelAddress? item.hotelAddress + ', ' :
                            ''}}
                            <br *ngIf="item.cityName">
                            {{item.cityName? item.cityName : ''}}{{(item.stateCode && item.cityName)? ', ' :
                              ''}}{{item.stateCode? item.stateCode : ''}}{{((item.stateCode || item.cityName ) &&
                              (item.countryName))? ', ' : ''}}{{item.countryName? item.countryName : ''}}
                          </div>
                          <div class="gray-text mb-1">{{dataService.formatDateMMMdd(item.checkinDate)}} - {{dataService.formatDateMMMdd(item.checkoutDate)}}
                          </div>
                        <p *ngIf="dataService.inactiveWorkspace" class="view-guest font-16"> {{item.guestFirstName}} {{item.guestLastName}}</p>
                      </div>

              <ng-container *ngIf="!dataService.isCompany()">
              <div class="hotel_image position-relative w-20" *ngIf="dataService.desktopView">
                <div *ngIf="item.hotelImages[0] && item.hotelImages[0].url && item.hotelImages[0].url.includes('https://')" >
                  <img width="1000" height="667" class="rounded" [src]="item.hotelImages[0].url " onError="this.src='assets/img/no_image.png'"
                       loading="lazy" alt="hotel image">
                </div>

                <div *ngIf="item.hotelImages[0] && item.hotelImages[0].url && !item.hotelImages[0].url.includes('https://') " >
                  <img width="1000" height="667" class="rounded" [src]="item.hotelImages[0].url.replace('http://','https://')"
                       loading="lazy" alt="hotel image" onError="this.src='assets/img/no_image.png'">
                </div>
                <ng-container *ngIf="!item.hotelImages[0]">
                  <img width="279" height="239" class="rounded" loading="lazy" src="assets/img/no_image.png" />

                </ng-container>

                <span class="position-absolute font-20" *ngIf="this.upcomingBooking[i].triphopId && !dataService.inactiveWorkspace"
                      [ngClass]="{ 'icon-colored-heart': this.upcomingBooking[i].favorite, 'icon-heart' : !this.upcomingBooking[i].favorite}"
                      (click)="this.dataService.toggleFavoriteHotel(this.upcomingBooking[i].triphopId); this.upcomingBooking[i].favorite = !this.upcomingBooking[i].favorite;
                      $event.stopPropagation()">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </div>
              </ng-container>


              <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
                <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">
                  <div class="promo-triangle"></div>
                  <div class="promo-text text-white font-12 px-3 pt-2">${{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>
                </div>

            </div>
            </div>
            <div *ngIf="item.bookingType === 'FLIGHT'">
              <div class="d-flex">
                <i class="icon-flight-item font-18 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mb-2">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[0].summary.departureDate)}}
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]"> - </span>
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]" class="gray-text-dark">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[1].summary.departureDate)}}</span>
                </p>
              </div>
              <div class="position-relative mb-5" (click)="viewDetails({ref: item.hrsReferenceNumber, identifier : item.bookRequest.email}, item.bookingType)">

              <div class="p-4 hotel-item rounded pointer" *ngFor="let destination of item.airItinerary.originDestinationOptionsGroups">

                  <div class="details">
                    <div class="row">
                      <div class="col-8">
                    <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{destination.summary.departureAirport.city}} - {{destination.summary.arrivalAirport.city}}</h2>
                    <p class="primaray-text font-weight-bold mt-3">
                      <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.departureDate)">10:15pm</span> -
                      <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.arrivalDate)">10:15pm</span>

                      <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                      <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) > 1"> +{{dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate)}}</span>
                      <span class="font-weight-normal" *ngIf="dataService.checkPreviousDay(destination.summary.departureDate, destination.summary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span>
                    </p>
                    <p class="gray-text-dark font-14"><span>{{destination.summary.departureAirport.iata}}</span><span class="super-vertical-align"> ____ </span><span>{{destination.summary.arrivalAirport.iata}}</span></p>

                      </div>
                      <div class="col-4" [ngClass]="{'text-right': dataService.mobileView}">
                        <div [ngClass]="{'h-100 d-flex pt-4': dataService.mobileView}">
                          <p class="mb-0 primaray-text" [ngClass]="{'my-auto ml-auto': dataService.mobileView}"><span [innerHTML]="this.dataService.calDuration(destination.summary.journeyDurationInMins)">6h 45m</span>
                            <br/>
                            <span *ngIf="destination.summary.totalStops === 1" i18n="@@oneStop"> 1 stop</span>
                            <span *ngIf="destination.summary.totalStops === 2" i18n="@@twoStops"> 2 stops</span>
                            <span *ngIf="destination.summary.totalStops > 2"> {{destination.summary.totalStops}} <ng-container i18n="@@stops">stops</ng-container></span>
                            <span class="green-text" *ngIf="destination.summary.totalStops === 0" i18n="@@nonStop">Nonstop</span>
                          </p>
                        </div>
                        <!-- <div  *ngFor="let stop of destination.summary.stopSummaries ,  let i = index" >
                           <p class="gray-text-dark mb-0">
                             <span [innerHTML]="this.dataService.calDuration(stop.stopDurationInMinutes)"></span> in <span>{{stop.airport.iata}}</span>
                           </p>
                         </div>-->
                        <!-- <p class="green-text" *ngIf="destination.summary.totalStops === 0">Nonstop</p>-->
                      </div>
                    </div>
                    <div class="airlines">
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length === 1">
                        {{destination.summary.airlineInfoSummary.marketingAirlines[0]}}. {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length > 1">
                        <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                        <span *ngFor="let airlineItem of destination.summary.airlineInfoSummary.marketingAirlines">
                                    {{airlineItem}}.
                                  </span>
                        {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                    </div>
                  </div>
                <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
                <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">
                  <div class="promo-triangle"></div>
                  <div class="promo-text text-white font-12 px-3 pt-2">${{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>
                </div>
              </div>

              </div>
            </div>
          </div>


          <p id="completed" class="gray-primaray-text font-18-to-16 bold-600 my-4" *ngIf="completedBooking.length !==0 " i18n="@@completedStays">Completed Stays</p>
          <div class="scroll-animation" *ngFor="let item of completedBooking | slice : 0 : showMoreCompleted; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <div class="d-flex">
                <i class="icon-hotel-item font-25 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mt-1 mb-2">{{dataService.formatDateEEMMMdy(item.checkinDate)}}</p>
              </div>
            <div class="mb-5 p-4 hotel-item rounded pointer position-relative" [ngClass]="{'d-flex': dataService.desktopView && !dataService.isCompany()}"  (click)="viewDetails({ref: item.referenceNumber, identifier : item.emailAddress}, item.bookingType)">
                      <div class="details" [ngClass]="{'w-80': dataService.desktopView && !dataService.isCompany()}">
                        <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{item.hotelName}}</h2>
                          <div class="gray-text mb-3 pr-3">{{item.hotelAddress? item.hotelAddress + ', ' :
                            ''}}
                            <br *ngIf="item.cityName">
                            {{item.cityName? item.cityName : ''}}{{(item.stateCode && item.cityName)? ', ' :
                            ''}}{{item.stateCode? item.stateCode : ''}}{{((item.stateCode || item.cityName ) &&
                            (item.countryName))? ', ' : ''}}{{item.countryName? item.countryName : ''}}
                          </div>
                          <div class="gray-text mb-1">{{dataService.formatDateMMMdd(item.checkinDate)}} - {{dataService.formatDateMMMdd(item.checkoutDate)}}
                          </div>
                        <div *ngIf="item.searchQuery && !dataService.isCompany()">
                          <a [routerLink]="[availabilityUrl]" [queryParams]="{searchQuery: item.searchQuery}" (click)="$event.stopPropagation()"
                             class="blue-text font-14" i18n="@@bookAgain">Book Again</a>
                        </div>

                        <p *ngIf="dataService.inactiveWorkspace" class="view-guest font-16"> {{item.guestFirstName}} {{item.guestLastName}}</p>
                        <div class="col-lg-5 col-md-6 pl-3 pl-lg-0 d-flex align-items-end flex-column">
                        </div>
                      </div>

              <ng-container *ngIf="!dataService.isCompany()">
              <div class="hotel_image position-relative w-20" *ngIf="dataService.desktopView">
                <div class="flex-column pt-1" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" *ngIf="item.hotelImages[0] && item.hotelImages[0].url && item.hotelImages[0].url.includes('https://')" >
                  <img width="1000" height="667" class="rounded" [src]="item.hotelImages[0].url " onError="this.src='assets/img/no_image.png'"
                       loading="lazy" alt="hotel image">
                </div>

                <div class="flex-column pt-1" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" *ngIf="item.hotelImages[0] && item.hotelImages[0].url && !item.hotelImages[0].url.includes('https://') " >
                  <img width="1000" height="667" class="rounded" [src]="item.hotelImages[0].url.replace('http://','https://')"
                       loading="lazy" alt="hotel image" onError="this.src='assets/img/no_image.png'">
                </div>
                <div class="flex-column" *ngIf="!item.hotelImages[0]">
                  <img width="279" height="239" class="rounded" loading="lazy" src="assets/img/no_image.png" />
                </div>
                <span class="position-absolute font-20" *ngIf="this.completedBooking[i].triphopId && !dataService.inactiveWorkspace"
                      [ngClass]="{ 'icon-colored-heart': this.completedBooking[i].favorite, 'icon-heart' : !this.completedBooking[i].favorite}"
                      (click)="this.dataService.toggleFavoriteHotel(this.completedBooking[i].triphopId); this.completedBooking[i].favorite = !this.completedBooking[i].favorite">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>

              </div>
              </ng-container>






              <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
              <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">
                <div class="promo-triangle"></div>
                <div class="promo-text text-white font-12 px-3 pt-2">${{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>
              </div>
            </div>
            </div>
            <div *ngIf="item.bookingType === 'FLIGHT'">
              <div class="d-flex">
                <i class="icon-flight-item font-18 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mb-2">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[0].summary.departureDate)}}
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]"> - </span>
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]" class="gray-text-dark">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[1].summary.departureDate)}}</span>
                </p>
              </div>
              <div class="position-relative mb-5" (click)="viewDetails({ref: item.hrsReferenceNumber, identifier : item.bookRequest.email}, item.bookingType)">

                <div class="p-4 hotel-item rounded pointer" *ngFor="let destination of item.airItinerary.originDestinationOptionsGroups">

                  <div class="details">
                    <div class="row">
                      <div class="col-8">
                        <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{destination.summary.departureAirport.city}} - {{destination.summary.arrivalAirport.city}}</h2>
                        <p class="primaray-text font-weight-bold mt-3">
                          <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.departureDate)">10:15pm</span> -
                          <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.arrivalDate)">10:15pm</span>

                          <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                          <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) > 1"> +{{dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate)}}</span>
                          <span class="font-weight-normal" *ngIf="dataService.checkPreviousDay(destination.summary.departureDate, destination.summary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span>
                        </p>
                        <p class="gray-text-dark font-14"><span>{{destination.summary.departureAirport.iata}}</span><span class="super-vertical-align"> ____ </span><span>{{destination.summary.arrivalAirport.iata}}</span></p>

                      </div>
                      <div class="col-4" [ngClass]="{'text-right': dataService.mobileView}">
                        <div [ngClass]="{'h-100 d-flex pt-4': dataService.mobileView}">
                          <p class="mb-0 primaray-text" [ngClass]="{'my-auto ml-auto': dataService.mobileView}"><span [innerHTML]="this.dataService.calDuration(destination.summary.journeyDurationInMins)">6h 45m</span>
                            <br/>
                            <span *ngIf="destination.summary.totalStops === 1" i18n="@@oneStop"> 1 stop</span>
                            <span *ngIf="destination.summary.totalStops === 2" i18n="@@twoStops"> 2 stops</span>
                            <span *ngIf="destination.summary.totalStops > 2"> {{destination.summary.totalStops}} <ng-container i18n="@@stops">stops</ng-container></span>
                            <span class="green-text" *ngIf="destination.summary.totalStops === 0" i18n="@@nonStop">Nonstop</span>
                          </p>
                        </div>
                        <!-- <div  *ngFor="let stop of destination.summary.stopSummaries ,  let i = index" >
                           <p class="gray-text-dark mb-0">
                             <span [innerHTML]="this.dataService.calDuration(stop.stopDurationInMinutes)"></span> in <span>{{stop.airport.iata}}</span>
                           </p>
                         </div>-->
                        <!-- <p class="green-text" *ngIf="destination.summary.totalStops === 0">Nonstop</p>-->
                      </div>
                    </div>
                    <div class="airlines">
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length === 1">
                        {{destination.summary.airlineInfoSummary.marketingAirlines[0]}}. {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length > 1">
                        <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                        <span *ngFor="let airlineItem of destination.summary.airlineInfoSummary.marketingAirlines">
                                    {{airlineItem}}.
                                  </span>
                        {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                    </div>
                  </div>
                  <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
                  <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">
                    <div class="promo-triangle"></div>
                    <div class="promo-text text-white font-12 px-3 pt-2">${{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div id="show-more-completed" class="text-center no-data-cont mt-5 mb-5 position-relative" *ngIf="showMoreCompleted < completedBooking.length">
            <a (click)="showMoreCompleted = showMoreCompleted + 3" class="no-data-box center_text sharp-font font-12 bold" i18n="@@showMore">
              Show more </a>
          </div>



          <p id="canceled" class="gray-primaray-text font-18-to-16 bold-600 my-4" *ngIf="canceledBooking.length !==0 " i18n="@@canceledBookings">Canceled Bookings</p>
          <div class="scroll-animation" *ngFor="let item of canceledBooking| slice : 0 : showMoreCanceled; let i = index">
            <div *ngIf="item.bookingType === 'HOTEL'">
              <div class="d-flex">
                <i class="icon-hotel-item font-25 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mt-1 mb-2">{{dataService.formatDateEEMMMdy(item.checkinDate)}}</p>
              </div>
            <div class="mb-5 p-4 hotel-item rounded pointer position-relative" [ngClass]="{'d-flex': dataService.desktopView && !dataService.isCompany()}"  (click)="viewDetails({ref: item.referenceNumber, identifier : item.emailAddress}, item.bookingType)">
                      <div class="details" [ngClass]="{'w-80': dataService.desktopView && !dataService.isCompany()}">
                          <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{item.hotelName}}</h2>
                          <div class="gray-text mb-3 pb-3 pr-3">{{item.hotelAddress? item.hotelAddress + ', ' :
                            ''}}
                            <br *ngIf="item.cityName">
                            {{item.cityName? item.cityName : ''}}{{(item.stateCode && item.cityName)? ', ' :
                              ''}}{{item.stateCode? item.stateCode : ''}}{{((item.stateCode || item.cityName ) &&
                              (item.countryName))? ', ' : ''}}{{item.countryName? item.countryName : ''}}
                          </div>
                          <div class="gray-text mb-1">{{dataService.formatDateMMMdd(item.checkinDate)}} - {{dataService.formatDateMMMdd(item.checkoutDate)}}
                          </div>
                        <p *ngIf="dataService.inactiveWorkspace" class="view-guest font-16"> {{item.guestFirstName}} {{item.guestLastName}}</p>
                        <div *ngIf="item.searchQuery && !dataService.isCompany()">
                          <a [routerLink]="[availabilityUrl]" [queryParams]="{searchQuery: item.searchQuery}" (click)="$event.stopPropagation()"
                             class="blue-text font-14" i18n="@@bookAgain">Book Again</a>
                        </div>

                      </div>
              <ng-container *ngIf="!dataService.isCompany()">
              <div class="hotel_image position-relative w-20" *ngIf="dataService.desktopView">
                <div class="flex-column pt-1" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" *ngIf="item.hotelImages[0] && item.hotelImages[0].url && item.hotelImages[0].url.includes('https://')" >
                  <img width="1000" height="667" class="rounded" [src]="item.hotelImages[0].url " onError="this.src='assets/img/no_image.png'"
                       loading="lazy" alt="hotel image">
                </div>

                <div class="flex-column pt-1" [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" *ngIf="item.hotelImages[0] && item.hotelImages[0].url && !item.hotelImages[0].url.includes('https://') " >
                  <img width="1000" height="667" class="rounded" [src]="item.hotelImages[0].url.replace('http://','https://')"
                       loading="lazy" alt="hotel image" onError="this.src='assets/img/no_image.png'">
                </div>
                <div class="flex-column" *ngIf="!item.hotelImages[0]">
                  <img width="279" height="239" class="rounded" loading="lazy" src="assets/img/no_image.png" />
                </div>

                <span class="position-absolute font-20" *ngIf="this.canceledBooking[i].triphopId && !dataService.inactiveWorkspace"
                      [ngClass]="{ 'icon-colored-heart': this.canceledBooking[i].favorite, 'icon-heart' : !this.canceledBooking[i].favorite}"
                      (click)="this.dataService.toggleFavoriteHotel(this.canceledBooking[i].triphopId); this.canceledBooking[i].favorite = !this.canceledBooking[i].favorite">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>

            </div>
              </ng-container>


              <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
                <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">
                  <div class="promo-triangle"></div>
                  <div class="promo-text text-white font-12 px-3 pt-2">${{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>
                </div>
            </div>
            </div>
            <div *ngIf="item.bookingType === 'FLIGHT'">
              <div class="d-flex">
                <i class="icon-flight-item font-18 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i>
                <p class="gray-primaray-text mb-2">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[0].summary.departureDate)}}
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]"> - </span>
                  <span *ngIf="item.airItinerary.originDestinationOptionsGroups[1]" class="gray-text-dark">{{dataService.formatDateEEMMMdy(item.airItinerary.originDestinationOptionsGroups[1].summary.departureDate)}}</span>
                </p>
              </div>
              <div class="position-relative mb-5" (click)="viewDetails({ref: item.hrsReferenceNumber, identifier : item.bookRequest.email}, item.bookingType)">

                <div class="p-4 hotel-item rounded pointer" *ngFor="let destination of item.airItinerary.originDestinationOptionsGroups">

                  <div class="details">
                    <div class="row">
                      <div class="col-8">
                        <h2 class="gray-primaray-text bold-600 font-16 mb-2">{{destination.summary.departureAirport.city}} - {{destination.summary.arrivalAirport.city}}</h2>
                        <p class="primaray-text font-weight-bold mt-3">
                          <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.departureDate)">10:15pm</span> -
                          <span class="mb-0 primaray-text" [innerHTML]="this.dataService.formatDateTime12Hours(destination.summary.arrivalDate)">10:15pm</span>

                          <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) === 1" i18n="@@nextDay"> (Next day)</span>
                          <span class="font-weight-normal" *ngIf="dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate) > 1"> +{{dataService.checkNextDay(destination.summary.departureDate, destination.summary.arrivalDate)}}</span>
                          <span class="font-weight-normal" *ngIf="dataService.checkPreviousDay(destination.summary.departureDate, destination.summary.arrivalDate)" i18n="@@prevDay"> (Previous day)</span>
                        </p>
                        <p class="gray-text-dark font-14"><span>{{destination.summary.departureAirport.iata}}</span><span class="super-vertical-align"> ____ </span><span>{{destination.summary.arrivalAirport.iata}}</span></p>

                      </div>
                      <div class="col-4" [ngClass]="{'text-right': dataService.mobileView}">
                        <div [ngClass]="{'h-100 d-flex pt-4': dataService.mobileView}">
                          <p class="mb-0 primaray-text" [ngClass]="{'my-auto ml-auto': dataService.mobileView}"><span [innerHTML]="this.dataService.calDuration(destination.summary.journeyDurationInMins)">6h 45m</span>
                            <br/>
                            <span *ngIf="destination.summary.totalStops === 1" i18n="@@oneStop"> 1 stop</span>
                            <span *ngIf="destination.summary.totalStops === 2" i18n="@@twoStops"> 2 stops</span>
                            <span *ngIf="destination.summary.totalStops > 2"> {{destination.summary.totalStops}} <ng-container i18n="@@stops">stops</ng-container></span>
                            <span class="green-text" *ngIf="destination.summary.totalStops === 0" i18n="@@nonStop">Nonstop</span>
                          </p>
                        </div>
                        <!-- <div  *ngFor="let stop of destination.summary.stopSummaries ,  let i = index" >
                           <p class="gray-text-dark mb-0">
                             <span [innerHTML]="this.dataService.calDuration(stop.stopDurationInMinutes)"></span> in <span>{{stop.airport.iata}}</span>
                           </p>
                         </div>-->
                        <!-- <p class="green-text" *ngIf="destination.summary.totalStops === 0">Nonstop</p>-->
                      </div>
                    </div>
                    <div class="airlines">
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length === 1">
                        {{destination.summary.airlineInfoSummary.marketingAirlines[0]}}. {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                      <p class="mb-0 gray-text-dark" *ngIf="destination.summary.airlineInfoSummary.marketingAirlines.length > 1">
                        <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                        <span *ngFor="let airlineItem of destination.summary.airlineInfoSummary.marketingAirlines">
                                    {{airlineItem}}.
                                  </span>
                        {{destination.originDestinationOptions[0].flightSegment.cabinClassName}}
                      </p>
                    </div>
                  </div>
                  <div *ngIf="item.rewardAmount && dataService.mobileView" style="height: 34px"></div>
                  <div class="reward-promo d-flex position-absolute" [ngClass]="{'inactive-rewards': dataService.inactiveWorkspace}" *ngIf="item.rewardAmount">
                    <div class="promo-triangle"></div>
                    <div class="promo-text text-white font-12 px-3 pt-2">${{item.rewardAmount  |  number : '1.0-0'}} <ng-container i18n="@@inRewards">in Rewards</ng-container></div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div id="show-more-canceled" class="text-center no-data-cont mt-5 mb-5 position-relative" *ngIf="showMoreCanceled < canceledBooking.length">
            <a (click)="showMoreCanceled = showMoreCanceled + 10" class="no-data-box center_text sharp-font font-12 bold" i18n="@@showMore">
              Show more </a>
          </div>

        </div>





      </div>
    </div>
  </div>
</div>
