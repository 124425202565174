<h1 class="green-text bolder mb-md-2 mb-3">
  <i class="icon-locked"></i
  ><ng-container i18n="@@secureBooking">Secure Booking</ng-container>&nbsp;<span
    class="smaller"
    ><ng-container i18n="@@almostDone"
      >(You are almost done)</ng-container
    ></span
  >
</h1>

<h4 class="orange-text mb-2">
  <i class="icon-warning"></i>&nbsp;<ng-container i18n="@@actFast"
    >Act fast! Pricing and availability may change!</ng-container
  >
</h4>

<div
  [ngClass]="{
    'text-right': Service.isSiteLtr(),
    'text-left': Service.isSiteRtl()
  }"
>
  <a
    class="font-weight-bold d-inline-block blue-text mt-2"
    *ngIf="!this.Auth.isLogedIn"
    (click)="this.Service.openloginDialogue(); this.SendBookingFormFields()"
    >Already a member? Sign In</a
  >

  <span
    *ngIf="this.Auth.isLogedIn && this.Auth.userInfo"
    (click)="this.Auth.logout()"
    class="pointer blue-text"
    [ngClass]="{ 'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl() }"
    i18n="@@notAndSign"
    >Not {{ this.Auth.userInfo.name }}? Sign out
  </span>
</div>

<form
  [formGroup]="bookingForm"
  (ngSubmit)="onSubmit()"
  id="bookingForm"
  class="hotel-booking"
>
  <div class="section-info radius mb-4 mt-4">
    <div class="section-header">
      <span class="primaray-text font-weight-bold"
        ><i class="icon-user"></i>&nbsp;<ng-container i18n="@@guestDetails"
          >Guest Details</ng-container
        ></span
      >
    </div>

    <div class="section-body p-5">
      <h5 class="bolder text-medium" i18n="@@guestName">Guest Name</h5>
      <div
        class="row gutters-10 mb-4"
        *ngIf="this.travelerId && this.travelerId !== 'guest'"
      >
        <div class="col-sm-6">
          <div class="d-flex align-items-center form-control">
            {{ traveler?.travelerName }}
          </div>
        </div>
      </div>
      <div
        class="row gutters-10"
        *ngIf="this.travelerId && this.travelerId === 'guest'"
      >
        <div
          class="form-group col-sm-6 col-md-6"
          [ngClass]="{
            'pr-lg-2': Service.isSiteLtr(),
            'pl-lg-2': Service.isSiteRtl()
          }"
        >
          <input
            pattern="^[A-Za-z -]+$"
            class="form-control"
            formControlName="FirstName"
            type="text"
            name="FirstName"
            i18n-placeholder="@@firstNamePlaceHolder"
            placeholder="First Name"
            [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }"
          />

          <div
            *ngIf="
              submitted && f.FirstName.errors && f.FirstName.errors.required
            "
            class="invalid-feedback"
            i18n="@@requiredFirstNameError"
          >
            First Name is required
          </div>
        </div>

        <div
          class="form-group col-sm-6 col-md-6"
          [ngClass]="{
            'pr-lg-2': Service.isSiteLtr(),
            'pl-lg-2': Service.isSiteRtl()
          }"
        >
          <input
            pattern="^[A-Za-z -]+$"
            class="form-control"
            formControlName="LastName"
            type="text"
            name="LastName"
            i18n-placeholder="@@lastNamePlaceHolder"
            placeholder="Last Name"
            [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }"
          />
          <div
            *ngIf="submitted && f.LastName.errors && f.LastName.errors.required"
            class="invalid-feedback"
            i18n="@@requiredLastNameError"
          >
            Last name is required
          </div>
        </div>
      </div>
      <div class="row gutters-10" *ngIf="!this.Service.isCompany()">
        <div
          class="form-group col-sm-6 col-md-6"
          [ngClass]="{
            'pr-lg-2': Service.isSiteLtr(),
            'pl-lg-2': Service.isSiteRtl()
          }"
        >
          <input
            pattern="^[A-Za-z -]+$"
            class="form-control"
            formControlName="FirstName"
            type="text"
            name="FirstName"
            i18n-placeholder="@@firstNamePlaceHolder"
            placeholder="First Name"
            [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }"
          />

          <div
            *ngIf="
              submitted && f.FirstName.errors && f.FirstName.errors.required
            "
            class="invalid-feedback"
            i18n="@@requiredFirstNameError"
          >
            First Name is required
          </div>
          <div
            *ngIf="
              submitted && f.FirstName.errors && !f.FirstName.errors.required
            "
            class="invalid-feedback"
            i18n="@@invalidFirstName"
          >
            Invalid first name
          </div>
        </div>

        <div
          class="form-group col-sm-6 col-md-6"
          [ngClass]="{
            'pr-lg-2': Service.isSiteLtr(),
            'pl-lg-2': Service.isSiteRtl()
          }"
        >
          <input
            pattern="^[A-Za-z -]+$"
            class="form-control"
            formControlName="LastName"
            type="text"
            name="LastName"
            i18n-placeholder="@@lastNamePlaceHolder"
            placeholder="Last Name"
            [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }"
          />
          <div
            *ngIf="submitted && f.LastName.errors && f.LastName.errors.required"
            class="invalid-feedback"
            i18n="@@requiredLastNameError"
          >
            Last name is required
          </div>
          <div
            *ngIf="
              submitted && f.LastName.errors && !f.LastName.errors.required
            "
            class="invalid-feedback"
            i18n="@@invalidLastName"
          >
            Invalid last name
          </div>
        </div>
      </div>
      <h5 class="bolder text-medium mt-2" i18n="@@howContactYou">
        How can we contact you?
      </h5>
      <div class="row gutters-10">
        <div
          class="col-sm-6"
          *ngIf="this.travelerId && this.travelerId === 'guest'"
        >
          <div class="form-group with-icon">
            <input
              class="form-control"
              formControlName="email"
              type="text"
              name="email"
              i18n-placeholder="@@emailAddress"
              placeholder="Email address"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              autocomplete="off"
            />
            <i class="icon-email"></i>
          </div>
          <div *ngIf="submitted && f.email.errors" class="smaller text-danger">
            <div *ngIf="f.email.errors.required" i18n="@@requiredEmailError">
              email is required
            </div>
            <div *ngIf="f.email.errors.email" i18n="@@invalidEmail">
              Invalid email
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group with-icon">
            <input
              class="form-control"
              formControlName="phone"
              pattern="[[\+]{0,1}(\d{0,20}|[\(][\+]{0,1}\d{2,}[\20)]*\d{5,20}|\d{2,6}[\-]{1}\d{2,20}[\-]*\d{3,20})]*"
              type="tel"
              name="phone"
              placeholder="{{ phonePlaceHolder }}"
              [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
            />
            <i class="icon-phone"></i>
          </div>
          <div *ngIf="submitted && f.phone.errors" class="smaller text-danger">
            <div *ngIf="f.phone.errors.required" i18n="@@phoneNumberRequired">
              Phone number is required
            </div>
            <div
              *ngIf="f.phone.errors && !f.phone.errors.required"
              i18n="@@invalidPhoneNumber"
            >
              Invalid phone number
            </div>
          </div>
        </div>

        <div class="col-sm-6" *ngIf="Service.isCompany()">
          <div
            [ngClass]="{
              'text-right': Service.isSiteRtl(),
              'text-left': Service.isSiteLtr()
            }"
          >
            <span
              class="pt-3 blue-text d-inline-block clickable-cursor text-medium mb-2 mb-md-0"
              id="special"
              (click)="specialReq = !specialReq"
              i18n="@@anySpecialRequests"
            >
              Any special requests?
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="specialReq && Service.isCompany()" class="form-group">
        <textarea
          rows="4"
          [ngClass]="{ 'is-invalid': f.specialRequests.errors }"
          formControlName="specialRequests"
          maxlength="500"
          name="specialRequests"
          class="form-control mt-2 mb-2 ng-pristine"
          i18n-placeholder="@@specialRequests"
          placeholder="Special Requests (Optional)"
        ></textarea>
        <div
          *ngIf="f.specialRequests.errors"
          class="invalid-feedback"
          i18n="@@messageTooLong"
        >
          Your message is too long
        </div>
        <span class="gray-text-dark"
          ><i class="icon-warning"></i>&nbsp;<ng-container
            i18n="@@specialRequestsGuaranteed"
            >Special requests are not guaranteed and subjected to
            availability</ng-container
          ></span
        >
      </div>
    </div>
  </div>

  <div class="section-info radius mb-4">
    <div class="section-header">
      <span class="primaray-text font-weight-bold"
        ><i class="icon-credit-card-alt"></i>&nbsp;<ng-container
          i18n="@@paymentDetails"
          >Payment Details</ng-container
        ></span
      >
    </div>

    <div class="section-body gray-text-dark p-5">
      <div class="row">
        <div
          class="col-md-7"
          [ngClass]="{ 'custom-col-width': this.Service.sideNavState }"
        >
          <h5 i18n="@@choosePaymentMethod">Please choose a payment method</h5>

          <div class="form-type-radio">
            <label for="new_card_payment_method" class="radio-inline">
              <input
                type="radio"
                name="payment_method"
                id="new_card_payment_method"
                checked="checked"
                (change)="onItemChange($event)"
                class="form-checkbox"
                value="new_card"
              />
              <span i18n="@@creditDebitCard">Credit / Debit Card </span></label
            >
          </div>

          <div
            *ngIf="
              savedCard &&
              savedCard.data &&
              savedCard.data.cards &&
              savedCard.data.cards.length > 0
            "
          >
            <select
              [ngClass]="{
                'd-block':
                  this.Auth.isLogedIn &&
                  paymentType === 'new_card' &&
                  savedCard.data
              }"
              [ngModelOptions]="{ standalone: true }"
              class="form-control mb-3 d-none"
              (change)="savedCardChange()"
              [(ngModel)]="SelectedsavedCard"
            >
              <ng-container
                *ngFor="let method of savedCard.data.cards; let i = index"
              >
                <option *ngIf="method.primary === false" [value]="method.token">
                  {{ method.alias }} - {{ method.brand }} ending with
                  {{ method.lastFour }}
                </option>
              </ng-container>
              <ng-container
                *ngFor="let method of savedCard.data.cards; let i = index"
              >
                <option
                  *ngIf="method.primary === true"
                  [ngValue]="method.token"
                  [selected]="true"
                >
                  {{ method.alias }} - {{ method.brand }} ending with
                  {{ method.lastFour }}
                </option>
              </ng-container>

              <option
                [ngClass]="{
                  'd-none':
                    this.travelerId &&
                    this.travelerId !== 'guest' &&
                    this.traveler?.personalPaymentMethodsDisabled
                }"
                value="new"
                i18n="@@newPaymentMethod"
              >
                Use a new payment method
              </option>
            </select>
          </div>
          <div
            class="mt-2"
            [ngClass]="{
              'd-none': paytype === 'savedCard' || paymentType !== 'new_card'
            }"
          >
            <div class="form-group">
              <label *ngIf="this.Service.desktopView" i18n="@@cardPlaceholder">
                Cardholder Name
              </label>
              <input
                *ngIf="this.Service.desktopView"
                type="text"
                name="HolderName"
                formControlName="HolderName"
                class="form-control"
                placeholder=""
                autocomplete="off"
                [ngClass]="{ 'is-invalid': cardholder || f.HolderName.errors }"
              />
              <input
                *ngIf="this.Service.mobileView"
                type="text"
                name="HolderName"
                formControlName="HolderName"
                class="form-control"
                i18n-placeholder="@@cardPlaceholder"
                placeholder="Cardholder Name"
                autocomplete="off"
                [ngClass]="{ 'is-invalid': cardholder || f.HolderName.errors }"
              />

              <div
                *ngIf="cardholder"
                class="text-danger font-12 pt-1"
                i18n="@@cardPlaceholderRequired"
              >
                Cardholder Name is required
              </div>

              <div
                *ngIf="f.HolderName.errors"
                class="smaller text-danger"
                i18n="@@cardPlaceholderInvalid"
              >
                Invalid Cardholder Name
              </div>
            </div>

            <div class="">
              <p *ngIf="this.Service.desktopView" i18n="@@enterCardDetails">
                Enter card details here
              </p>
              <div class="form-control">
                <div id="card-element" class="field"></div>
              </div>

              <div class="font-12 mt-1 text-danger">
                {{ stripeError }}
              </div>
            </div>
          </div>

          <div
            class="mt-2"
            *ngIf="
              paymentType === 'new_card' &&
              this.Auth.isLogedIn &&
              paytype !== 'savedCard' &&
              !(requiresApprovalData && !submittedApproval)
            "
          >
            <div class="form-item form-type-checkbox form-name-remember-card">
              <input
                [(ngModel)]="changeRememberMe"
                [ngModelOptions]="{ standalone: true }"
                id="remember_card"
                type="checkbox"
              />
              <label
                class="gray-text-dark"
                for="remember_card"
                i18n="@@rememberPaymentMethod"
              >
                Remember this payment method
              </label>
            </div>
          </div>
          <div
            class="gray-text font-12 pt-4 pb-4 pb-lg-0"
            *ngIf="
              paymentType === 'new_card' &&
              this.Auth.isLogedIn &&
              paytype !== 'savedCard' &&
              requiresApprovalData &&
              !submittedApproval
            "
            i18n="@@yourCardwillBeSaved"
          >
            Your card will be saved and used once the booking is approved
          </div>

          <div
            class="paypal-text mt-2 d-none"
            [ngClass]="{ 'd-block': paymentType !== 'new_card' }"
            i18n="@@makeSurePaymDetails"
          >
            Make sure to fill all the details then press the checkout button at
            the bottom, you will be redirected to complete the payment. After
            you complete, you will come back to SafarHub to view your booking
            confirmation.
          </div>
        </div>

        <div
          class="col-md-5 border-sm-0"
          [ngClass]="{
            'border-left': Service.isSiteLtr(),
            'border-right': Service.isSiteRtl(),
            'custom-col-width': this.Service.sideNavState
          }"
        >
          <h3 class="gray-primaray-text text-medium" i18n="@@WeAccept">
            We accept
          </h3>
          <ul class="list-inline card-type-list">
            <li class="list-inline-item">
              <img
                width="48"
                height="30"
                loading="lazy"
                src="assets/img/payment_methods/visa.png"
                alt="visa"
              />
            </li>
            <li class="list-inline-item">
              <img
                width="48"
                height="30"
                loading="lazy"
                src="assets/img/payment_methods/mastercard.png"
                alt="mastercard"
              />
            </li>
          </ul>

          <h3 class="gray-primaray-text text-medium" i18n="@@countOnUs">
            You can count on us
          </h3>
          <ul class="checks">
            <li class="mb-1" i18n="@@secureData">
              Data is transmitted securely
            </li>
            <li class="mb-1" i18n="@@protectInfo">
              We protect your personal information
            </li>
          </ul>

          <div class="d-none d-md-block">
            <a
              (click)="openSSL()"
              style="
                border: none;
                margin-bottom: 1rem;
                zoom: 0.6;
                cursor: pointer;
              "
              ><img
                width="130px"
                src="assets/img/ssl_seal_1.png"
                alt="Ssl seal"
            /></a>
            <div i18n="@@infoExchanged">
              Information exchanged is encrypted before transmission.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.Service.newRate">
        <div
          class="border-top mt-4 pt-4"
          *ngIf="this.Service.newRate.comment && readMore === true"
        >
          <h4 class="mt-0 mb-3 bolder" i18n="@@additionalInfo">
            Additional Info.
          </h4>
          <div
            [innerHTML]="this.Service.newRate.comment"
            class="text-medium"
          ></div>
        </div>
        <div
          class="border-top mt-4 pt-4"
          *ngIf="this.Service.newRate.comment && readMore !== true"
        >
          <h4 class="mt-0 mb-3 bolder" i18n="@@additionalInfo">
            Additional Info.
          </h4>
          <div
            [innerHTML]="this.Service.newRate.comment | slice : 0 : 460"
            class="text-medium"
          ></div>
        </div>
        <p (click)="readMore = !readMore" class="btn-link mb-2">
          <span *ngIf="readMore === false" i18n="@@readMore"> Read More </span>
        </p>

        <div class="border-top mt-4 pt-4">
          <h4 class="mt-0 mb-3 bolder" i18n="@@cancellationPolicies">
            Cancellation Policies
          </h4>
          <div
            *ngIf="
              this.Service.newRate.refundable &&
              this.Service.newRate.cancellationPolicies.length > 0
            "
          >
            <div
              class="text-medium"
              *ngFor="let policy of this.Service.newRate.cancellationPolicies"
            >
              {{ policy.description }}
            </div>
          </div>
          <div
            class="non-refundable"
            *ngIf="
              !this.Service.newRate.refundable ||
              this.Service.newRate.cancellationPolicies.length == 0
            "
            i18n="@@nonrefundable"
          >
            Non-refundable
          </div>
        </div>
        <div class="border-top mt-4 pt-4">
          <h4 class="mt-0 mb-3 bolder" i18n="@@reservationTerms">
            Reservation Terms
          </h4>
          <div class="text-medium">
            <p>
              <ng-container i18n="@@completeBookAgree"
                >By completing this booking, you agree to the</ng-container
              >&nbsp;<a
                *ngIf="Service.isCompany()"
                [href]="env.termsUrl"
                target="_blank"
                ><ng-container i18n="@@termsAndConditions"
                  >terms and conditions</ng-container
                ><small><i class="fas fa-external-link-alt"></i></small></a
              >&nbsp;<ng-container i18n="@@ofSafarHub"
                >of SafarHub.</ng-container
              >
            </p>
            <p>
              <span i18n="@@youWillCharged">You will be charged </span
              ><span
                >{{ this.Service.newRate.chargeAmountCurrency }}
                {{ this.Service.newRate.chargeAmount }} </span
              >
              <span *ngIf="Service.isSiteRtl()" i18n="@@fromYou">from you</span>
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="this.Service.newRate">
        <div *ngIf="this.Service.newRate.errorCode === 0">
          <div
            class="border-top mt-4 pt-4"
            *ngIf="
              (this.Service.newRate || this.Service.newRate) &&
              this.Service.newRate.comment
            "
          >
            <h4 class="mt-0 mb-3 bolder" i18n="@@additionalInfo">
              Additional Info.
            </h4>
            <div
              [innerHTML]="this.Service.newRate.comment"
              class="text-medium"
            ></div>
          </div>
          <div
            class="border-top mt-4 pt-4"
            *ngIf="
              this.Service.newRate.refundable &&
              this.Service.newRate.cancellationPolicies.length > 0
            "
          >
            <h4 class="mt-0 mb-3 bolder" i18n="@@cancellationPolicies">
              Cancellation Policies
            </h4>
            <div>
              <div
                class="text-medium"
                *ngFor="let policy of this.Service.newRate.cancellationPolicies"
              >
                {{ policy.description }}
              </div>
            </div>
            <div
              class="non-refundable"
              *ngIf="
                !this.Service.newRate.refundable ||
                this.Service.newRate.cancellationPolicies.length == 0
              "
            >
              Non-refundable
            </div>
          </div>
          <div class="border-top mt-4 pt-4">
            <h4 class="mt-0 mb-3 bolder" i18n="@@reservationTerms">
              Reservation Terms
            </h4>
            <div class="text-medium">
              <p>
                <ng-container i18n="@@completeBookAgree"
                  >By completing this booking, you agree to the</ng-container
                >&nbsp;<a
                  *ngIf="this.Service.isCompany()"
                  [href]="env.termsUrl"
                  target="_blank"
                  ><ng-container i18n="@@termsAndConditions"
                    >terms and conditions</ng-container
                  ><small><i class="fas fa-external-link-alt"></i></small></a
                >&nbsp;<ng-container i18n="@@ofSafarHub"
                  >of SafarHub.</ng-container
                >
              </p>
              <p>
                <span i18n="@@youWillCharged">You will be charged </span
                ><span
                  >{{ this.Service.newRate.chargeAmountCurrency }}
                  {{ this.Service.newRate.chargeAmount }} </span
                > 
                <span *ngIf="Service.isSiteRtl()" i18n="@@fromYou">from you</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-action">
    <div class="hotel-details-side d-block d-md-none">
      <div class="white-box p-0 mb-4">
        <figure
          class="hotel-img-booking"
          *ngIf="
            Service.hotelData.images &&
            Service.hotelData.images[0] &&
            Service.hotelData.images[0].url.includes('https://')
          "
          [ngStyle]="{
            'background-image': 'url(' + Service.hotelData.images[0].url + ')'
          }"
        ></figure>

        <figure
          class="hotel-img-booking"
          *ngIf="
            Service.hotelData.images &&
            Service.hotelData.images[0] &&
            !Service.hotelData.images[0].url.includes('https://')
          "
          [ngStyle]="{
            'background-image':
              'url(' +
              Service.hotelData.images[0].url.replace('http://', 'https://') +
              ')'
          }"
        ></figure>

        <ng-container
          *ngIf="!Service.hotelData.images || !Service.hotelData.images[0]"
        >
          <img
            class="rounded-top booking-form-img-hotel w-100"
            src="assets/img/no_image.png"
          />
        </ng-container>

        <div class="p-4">
          <div class="border-bottom pb-3">
            <h4 class="primaray-text font-weight-bold">
              {{ Service.hotelData.name }}
            </h4>
            <div class="address-inner gray-text">
              <i class="icon-locator gray-text"></i>
              {{ Service.hotelData.cityName ? Service.hotelData.cityName : ""
              }}{{
                Service.hotelData.stateCode && Service.hotelData.cityName
                  ? ", "
                  : ""
              }}{{
                Service.hotelData.stateCode ? Service.hotelData.stateCode : ""
              }}{{
                (Service.hotelData.stateCode || Service.hotelData.cityName) &&
                Service.hotelData.countryName
                  ? ", "
                  : ""
              }}{{
                Service.hotelData.countryName
                  ? Service.hotelData.countryName
                  : ""
              }}
            </div>
          </div>

          <div
            class="pb-2"
            [ngClass]="{
              'border-bottom pt-2':
                Service.FacilitiesFeaturedavailabile === true
            }"
          >
            <ul
              *ngIf="Service.hotelData"
              class="facilities-main mb-0 list-unstyled"
            >
              <ng-container *ngFor="let item of Service.hotelData.myFacilities">
                <ng-container *ngIf="item.featured">
                  <li class="facilities-main-item d-inline-block mb-2 mt-2">
                    <span
                      class="d-block"
                      [ngClass]="{
                        'pr-5': Service.isSiteLtr(),
                        'pr-5': Service.isSiteRtl()
                      }"
                    >
                      <i [class]="'icon-' + item.hrsCode"></i> {{ item.title }}
                    </span>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="px-4">
          <div class="margin_bottom">
            <p *ngIf="Service.roomName" class="bolder">
              {{ Service.roomName }}
            </p>
          </div>
          <div
            *ngIf="
              this.Service.newRate && this.Service.newRate.hrsBoardCode != 'RO'
            "
            class="mb-3"
          >
            <i [ngClass]="'icon-' + this.Service.newRate.boardCode"> </i
            >{{ this.Service.newRate.boardName }}
          </div>
          <ng-container *ngIf="Service.params">
            <p>
              <span class="bolder"
                ><ng-container i18n="@@checkInNoDash">Check In</ng-container
                >:</span
              >
              {{ Service.params.checkIn | date : "EE, MMM d " }}
            </p>
            <p>
              <span class="bolder"
                ><ng-container i18n="@@checkOutNoDash">Check Out</ng-container
                >:</span
              >{{ Service.params.checkOut | date : "EE, MMM d " }}
            </p>
          </ng-container>

          <p class="margin_top">
            <span class="bolder"
              ><ng-container i18n="@@youSelected">You selected</ng-container>:
            </span>

            <span
              class="badge badge-secondary d-inline-block"
              [ngClass]="{
                'mr-1': Service.isSiteLtr(),
                'ml-1': Service.isSiteRtl()
              }"
              >{{ diffInDays }}
              <ng-container *ngIf="diffInDays === 1" i18n="@@nightNoNumber"
                >night</ng-container
              >
              <ng-container *ngIf="diffInDays === 2" i18n="@@twoNightsNoNumber"
                >nights</ng-container
              >
              <ng-container *ngIf="diffInDays > 2" i18n="@@nights"
                >nights</ng-container
              >
              <ng-container i18n="@@stay">Stay</ng-container>
            </span>

            <span class="badge badge-secondary d-inline-block">
              <ng-container *ngIf="PaxesCount === 1" i18n="@@guest">
                Guest</ng-container
              >
              <ng-container *ngIf="PaxesCount === 2" i18n="@@twoGuests"
                >Guests</ng-container
              >
              <ng-container *ngIf="PaxesCount > 2" i18n="@@guests"
                >Guests</ng-container
              >
            </span>
          </p>
        </div>
      </div>

      <div
        class="white-box mb-4 pb-4 pt-3 text-center position-relative"
        *ngIf="Service.hotelData && Service.hotelData.googleReview"
      >
        <div class="google-review-cont">
          <div
            class="super-big font-weight-bold gray-primaray-text align-middle d-inline-block"
            *ngIf="Service.hotelData.googleReview"
          >
            {{ Service.hotelData.googleReview.rating }}
          </div>
          <div
            class="d-inline-block align-middle"
            [ngClass]="{
              'text-left': Service.isSiteLtr(),
              'text-right': Service.isSiteRtl()
            }"
          >
            <div
              class="gray-text-dark font-weight-bold"
              *ngIf="Service.hotelData.googleReview.ratingDescription"
            >
              {{ Service.hotelData.googleReview.ratingDescription }}
            </div>
            <div class="rating-stars-google d-inline-block">
              <div
                class="rating-active"
                [style.width.%]="
                  Service.hotelData.googleReview.ratingPercentage * 100
                "
              ></div>
            </div>
            <br />
            <button
              (click)="
                this.Service.setModal(
                  reviewTitle,
                  'GoogleReview',
                  Service.hotelData
                )
              "
              class="btn-link d-inline-block"
            >
              <span
                [ngClass]="{
                  'mr-1': Service.isSiteLtr(),
                  'ml-1': Service.isSiteRtl()
                }"
                >{{
                  Service.hotelData.googleReview.userRatingsTotal
                    | number : "" : "en-US"
                }}</span
              ><ng-container i18n="@@reviews">reviews</ng-container>
            </button>
          </div>

          <div
            class="water-mark-google gray-text font-12"
            i18n="@@poweredByGoogle"
          >
            Powered by Google
          </div>
        </div>
      </div>

      <div
        *ngIf="tripAdviserdata && TripAdviserRating"
        class="white-box p-3 mb-4"
      >
        <div class="tripadvisor tripadvisor-container text-center">
          <img
            class="d-inline-block align-middle mt-1"
            src="assets/img/tripadvisor_logo.png"
          />
          <div
            class="trip-adviser-cont position-relative d-inline-block align-middle mt-1"
            [ngClass]="{
              'ml-1': Service.isSiteLtr(),
              'mr-1': Service.isSiteRtl()
            }"
          >
            <div class="trip-adviser-holder d-inline-block">
              <div class="trip-adviser-c"></div>

              <div class="trip-adviser-c"></div>
              <div class="trip-adviser-c"></div>
              <div class="trip-adviser-c"></div>
              <div class="trip-adviser-c"></div>
            </div>
            <div
              [style.width.%]="TripAdviserRating"
              class="trip-adviser-holder d-inline-block position-absolute"
            >
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
            </div>
          </div>
          <div class="reviews mt-2">
            <a
              [href]="tripAdviserdata.data.link"
              target="_blank"
              class="underkined"
              (click)="tripAdvisorTrigger()"
              i18n="@@basedOnReviews"
              >Based on
              {{
                tripAdviserdata.data.totalNumberOfReviews
                  | number : "" : "en-US"
              }}
              Reviews
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="this.Service.newRate">
        <div
          class="white-box p-0 mb-4 price_summary"
          *ngIf="
            this.Service.newRate &&
            this.Service.newRate &&
            this.Service.newRate.dailyRates
          "
        >
          <div class="p-3">
            <h5 class="text-big bolder mb-3" i18n="@@yourPriceSummary">
              Your Price Summary
            </h5>
            <div
              class="daily"
              *ngFor="let daily of this.Service.newRate.dailtyRates"
            >
              <div class="d-flex pb-1 text-medium">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  >{{ daily.date | date : "EE, MMM d " }}
                </span>
                <span
                  class="bolder"
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                  >${{
                    this.Service.newRate.averageNightlySellingPrice | number
                  }}</span
                >
              </div>
            </div>

            <div
              class="daily"
              *ngFor="let daily of this.Service.newRate.dailyRates"
            >
              <div class="d-flex pb-1 text-medium">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                >
                  {{ daily.date | date : "EE, MMM d " }}
                </span>
                <span
                  class="bolder"
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                >
                  ${{ daily.sellingPrice | number }}
                </span>
              </div>
            </div>

            <div
              class="discount d-flex text-medium"
              *ngIf="this.travelerId && this.travelerId === 'guest'"
            >
              <span
                [ngClass]="{
                  'mr-auto': Service.isSiteLtr(),
                  'ml-auto': Service.isSiteRtl()
                }"
                i18n="@@bookingFee"
              >
                Booking fee</span
              >
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                >${{
                  this.Service.newRate.guestBookingFee | number : "1.2-2"
                }}</span
              >
            </div>

            <div
              class="discount d-flex text-medium"
              *ngIf="!this.Service.newRate.taxIncluded"
            >
              <span
                [ngClass]="{
                  'mr-auto': Service.isSiteLtr(),
                  'ml-auto': Service.isSiteRtl()
                }"
                i18n="@@taxAndFees"
              >
                Tax and fees</span
              >
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                >${{ this.Service.newRate.tax | number }}</span
              >
            </div>
          </div>

          <div class="white-box-footer rounded-bottom p-3">
            <div class="d-flex font-20 bolder">
              <span class="primaray-text" i18n="@@totalPayNow">
                Total to pay now:
              </span>
              <span
                class="primaray-text"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                ><span class="currency">{{
                  this.Service.newRate.finalSellingPriceConvertedCurrency
                }}</span
                ><span class="amount">
                  {{ this.Service.newRate.finalSellingPriceConverted }}
                </span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.Service.newRate" class="section-info green radius"></div>
      <div class="mt-3">
        <div class="mt-3">
          <div class="small gray-text" i18n="@@ratesNote">
            * Rates, taxes and fees are quoted in US Dollars.
          </div>
        </div>
        <!-- <app-cost-reporting-manage></app-cost-reporting-manage> -->

        <div class="not-mobile">
          <br />

          <h5 class="text-medium bolder mb-2" i18n="@@whyBookWithUs">
            Why Book With Us?
          </h5>

          <div class="checks-container">
            <ul class="checks list-unstyled">
              <li class="mb-1" i18n="@@secureBooking">Secure Booking</li>
              <li class="mb-1" i18n="@@priceGuarantee">Price Guarantee</li>
              <li class="mb-1" i18n="@@247support">24/7 Customer Support</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="book_button_container d-none"
      [ngClass]="{
        'd-block':
          !requiresApprovalData ||
          (requiresApprovalData && paymentType !== 'paypal')
      }"
    >
      <div class="row">
        <div
          class="col-md-6 max-width-200"
          *ngIf="bookingForm"
          [ngClass]="{ 'max-width-250': paymentType === 'gpay' }"
        >
          <div
            class="d-none"
            [ngClass]="{ 'd-block': paymentType === 'new_card' }"
          >
            <button
              class="green_btn btn-block"
              [disabled]="
                this.Service.bookingSubmit || this.requiresApprovalData
              "
              [ngClass]="{ 'disabled-button': requiresApprovalData }"
              i18n="@@confirmBooking"
            >
              CONFIRM BOOKING
            </button>
          </div>
          <ng-container *ngIf="!requiresApprovalData">
            <div
              class="d-none"
              [ngClass]="{ 'd-block': paymentType === 'paypal' && ServiceUp }"
            >
              <div
                [ngClass]="{ 'd-none': bookingForm.invalid }"
                id="paypal-button-container"
              ></div>

              <div
                [ngClass]="{
                  'd-none': bookingForm.invalid === false,
                  'text-left': Service.isSiteLtr(),
                  'text-right': Service.isSiteRtl()
                }"
                class="text-danger"
                i18n="@@fillRequiredInfo"
              >
                Please fill all required info.
              </div>
            </div>
            <p
              *ngIf="ServiceDown"
              class="text-warning font-18 my-5"
              i18n="@@payPalNotAvailable"
            >
              PayPal is not available at the moment, try again later
            </p>
          </ng-container>
          <div class="d-none" [ngClass]="{ 'd-block': paymentType === 'gpay' }">
            <div
              [ngClass]="{ 'd-none': bookingForm.invalid }"
              id="google-pay-container"
              class="field"
            ></div>
            <div
              [ngClass]="{
                'd-none': bookingForm.invalid === false,
                'text-left': Service.isSiteLtr(),
                'text-right': Service.isSiteRtl()
              }"
              class="text-danger"
              i18n="@@fillRequiredInfo"
            >
              Please fill all required info.
            </div>
          </div>
        </div>

        <div class="col-md-6 mt-3 mt-md-0" *ngIf="this.Service.newRate">
          <span
            *ngIf="
              this.Service.newRate && this.Service.newRate.finalSellingPrice
            "
            class="primaray-text font-weight-bold text-big"
          >
            <ng-container i18n="@@Total">Total</ng-container>:&nbsp;<span
              class="value"
              ><ng-container *ngIf="Service.isSiteLtr()">{{
                this.Service.newRate.chargeAmountCurrency
              }}</ng-container>
              {{ this.Service.newRate.chargeAmount | number }}
              <ng-container *ngIf="Service.isSiteRtl()">{{
                this.Service.newRate.chargeAmountCurrency
              }}</ng-container>
            </span></span
          >
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="paymentType !== 'paypal'">
    <div
      class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
      *ngIf="
        requiresApprovalData &&
        requiresApprovalData.limitExceeded &&
        !submittedApproval
      "
    >
      <p>
        <ng-container i18n="@@approvalMsg1"
          >This booking exceeds your travel policy. Approval request will be
          sent to</ng-container
        >&nbsp;<span class="font-weight-bold"
          >{{ requiresApprovalData.firstName }}
          {{ requiresApprovalData.lastName }}</span
        ><ng-container i18n="@@approvalMsg2"
          >. Your room will be automatically booked once approved.</ng-container
        >
      </p>

      <p
        class="bold-600 mt-4 mb-2"
        *ngIf="Auth.userInfo && Auth.userInfo.effectivePolicyName"
      >
        <ng-container i18n="@@travelPolicy">Travel Policy</ng-container>&nbsp;
        <span class="font-weight-normal font-14"
          >({{ Auth.userInfo.effectivePolicyName }})</span
        >
      </p>

      <ng-container
        *ngIf="
          requiresApprovalData && !requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          class="mb-1 font-16 gray-text"
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
        >
          <i
            class="icon-check green-text font-12"
            *ngIf="!requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>
          <i
            class="icon-close-light text-danger font-12"
            *ngIf="requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>

          <span i18n="@@nightlyPriceCap">Nightly price cap</span>:<span
            class="px-2"
            >{{ requiresApprovalData.limit }}</span
          >
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          requiresApprovalData && requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          class="mb-1"
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
        >
          <i
            class="icon-close-light text-danger font-12"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>
          <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
            >Hotel booking allowed</span
          >
        </p>
      </ng-container>

      <div
        class="col-sm-5 px-0 mt-5 min-width-220"
        [ngClass]="{
          'pl-lg-5 ml-auto': Service.isSiteLtr(),
          'pr-lg-5 mr-auto': Service.isSiteRtl()
        }"
      >
        <button
          class="blue_btn btn-block font-16 py-1 sharp-font"
          [ngClass]="{ 'm-progress': submittingApproval }"
          i18n="@@requestapproval"
        >
          Request Approval
        </button>
      </div>
    </div>
    <div
      class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
      *ngIf="
        requiresApprovalData &&
        !requiresApprovalData.limitExceeded &&
        !submittedApproval
      "
    >
      <p>
        <ng-container i18n="@@approvalMsg3"
          >Booking approval is required. Approval request will be sent
          to</ng-container
        >&nbsp;
        <span class="font-weight-bold"
          >{{ requiresApprovalData.firstName }}
          {{ requiresApprovalData.lastName }}</span
        ><ng-container i18n="@@approvalMsg2"
          >. Your room will be automatically booked once approved.</ng-container
        >
      </p>

      <ng-container
        *ngIf="
          requiresApprovalData && !requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          class="mb-1 font-16 gray-text"
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
        >
          <i
            class="icon-check green-text font-12"
            *ngIf="!requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>
          <i
            class="icon-close-light text-danger font-12"
            *ngIf="requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>

          <span i18n="@@nightlyPriceCap">Nightly price cap</span>:<span
            class="px-2"
            >${{ requiresApprovalData.limit }}</span
          >
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          requiresApprovalData && requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          class="mb-1"
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
        >
          <i
            class="icon-close-light text-danger font-12"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>
          <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
            >Hotel booking allowed</span
          >
        </p>
      </ng-container>

      <div
        class="col-sm-5 px-0 mt-5 min-width-220"
        [ngClass]="{
          'pl-lg-5 ml-auto': Service.isSiteLtr(),
          'pr-lg-5 mr-auto': Service.isSiteRtl()
        }"
      >
        <button
          class="blue_btn btn-block font-16 py-1 sharp-font"
          [ngClass]="{ 'm-progress': submittingApproval }"
          i18n="@@requestapproval"
        >
          Request Approval
        </button>
      </div>
    </div>
  </ng-container>
  <div
    class="d-none mb-5"
    [ngClass]="{
      'd-block': paymentType === 'paypal' && ServiceUp && requiresApprovalData
    }"
  >
    <div
      class="border rounded mt-5 font-16 px-3 px-lg-5 pt-4 pt-lg-5 pb-4 gray-text-dark"
    >
      <ng-container
        *ngIf="
          requiresApprovalData &&
          requiresApprovalData.limitExceeded &&
          !submittedApproval
        "
      >
        <p>
          <ng-container i18n="@@approvalMsg1"
            >This booking exceeds your travel policy. Approval request will be
            sent to</ng-container
          >&nbsp;<span class="font-weight-bold"
            >{{ requiresApprovalData.firstName }}
            {{ requiresApprovalData.lastName }}</span
          ><ng-container i18n="@@approvalMsg2"
            >. Your room will be automatically booked once
            approved.</ng-container
          >
        </p>
        <p i18n="@@checkOutPayPal">
          Check out with PayPal to proceed with your approval request. we will
          only authorize the payment. The payment will be captured when your
          booking is approved.
        </p>
      </ng-container>
      <ng-container
        *ngIf="
          requiresApprovalData &&
          !requiresApprovalData.limitExceeded &&
          !submittedApproval
        "
      >
        <p *ngIf="requiresApprovalData">
          <ng-container i18n="@@approvalMsg3"
            >Booking approval is required. Approval request will be sent
            to</ng-container
          >&nbsp;
          <span class="font-weight-bold"
            >{{ requiresApprovalData.firstName }}
            {{ requiresApprovalData.lastName }}</span
          ><ng-container i18n="@@approvalMsg2"
            >. Your room will be automatically booked once
            approved.</ng-container
          >
        </p>
        <p i18n="@@checkOutPayPal">
          Check out with PayPal to proceed with your approval request. we will
          only authorize the payment. The payment will be captured when your
          booking is approved.
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          requiresApprovalData && !requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          class="mb-1 font-16 gray-text"
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
        >
          <i
            class="icon-check green-text font-12"
            *ngIf="!requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>
          <i
            class="icon-close-light text-danger font-12"
            *ngIf="requiresApprovalData.limitExceeded"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>

          <span i18n="@@nightlyPriceCap">Nightly price cap</span>:<span
            class="px-2"
            >${{ requiresApprovalData.limit }}</span
          >
        </p>
      </ng-container>

      <ng-container
        *ngIf="
          requiresApprovalData && requiresApprovalData.hotelBookingNotAllowed
        "
      >
        <p
          class="mb-1"
          [ngClass]="{
            'mr-0': Service.isSiteLtr(),
            'ml-0': Service.isSiteRtl()
          }"
        >
          <i
            class="icon-close-light text-danger font-12"
            [ngClass]="{
              'pr-2': Service.isSiteLtr(),
              'pl-2': Service.isSiteRtl()
            }"
          ></i>
          <span i18n="@@hotelBookingAllowed" class="font-16 gray-text"
            >Hotel booking allowed</span
          >
        </p>
      </ng-container>

      <div class="row mt-5 mx-lg-0">
        <div
          class="col-lg-6 max-width-220"
          [ngClass]="{
            'pl-0': Service.isSiteLtr(),
            'pr-0': Service.isSiteRtl()
          }"
        >
          <div
            [ngClass]="{ 'd-none': bookingForm.invalid }"
            id="paypal-button-container"
          ></div>
          <div
            [ngClass]="{
              'd-none': bookingForm.invalid === false,
              'text-left': Service.isSiteLtr(),
              'text-right': Service.isSiteRtl()
            }"
            class="text-danger"
            i18n="@@fillRequiredInfo"
          >
            Please fill all required info.
          </div>
          <p
            *ngIf="ServiceDown"
            class="text-warning font-18 my-5"
            i18n="@@payPalNotAvailable"
          >
            PayPal is not available at the moment, try again later
          </p>
        </div>
        <div
          class="col-lg-6 mt-3 mt-md-0"
          *ngIf="this.Service.newRate"
          [ngClass]="{
            'pl-0': Service.isSiteLtr(),
            'pr-0': Service.isSiteRtl()
          }"
        >
          <span
            *ngIf="
              this.Service.newRate && this.Service.newRate.finalSellingPrice
            "
            class="primaray-text font-weight-bold text-big"
          >
            <ng-container i18n="@@Total">Total</ng-container>:&nbsp;<span
              class="value"
              ><ng-container *ngIf="Service.isSiteLtr()">{{
                this.Service.newRate.finalSellingPriceConvertedCurrency
              }}</ng-container>
              {{ this.Service.newRate.finalSellingPriceConverted | number }}
              <ng-container *ngIf="Service.isSiteRtl()">{{
                this.Service.newRate.finalSellingPriceConvertedCurrency
              }}</ng-container>
            </span></span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="text-danger font-16 pt-3">{{ this.Service.errorMsg }}</div>
</form>
