<div class="main-dashboard-content" *ngIf="dataService.mobileView">
  <app-messages></app-messages>
  <div class="container pt-4 pt-lg-5 pb-5">
    <div class="mx-lg-5">
      <div class="pb-5 mb-3" *ngIf="this.auth.companyInfo && dataService.companyUserInfo">
        <a class="font-16 blue-text text-underline"  (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/members']);" i18n="@@members">Members</a>
        <span class="font-16 gray-text-dark "> / </span>
        <a class="font-16 blue-text text-underline"  *ngIf="dataService.companyUserInfo" (click)=" this.router.navigate([this.router.url.replace('/edit' , '')]);"> {{dataService.companyUserInfo.fullName}}</a>
        <span class="font-16 gray-text-dark "> / <ng-container i18n="@@edit">Edit</ng-container> </span>
      </div>
    </div>
    <div class="mx-lg-4">
      <app-user-update></app-user-update>
    </div>
  </div>
</div>

<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container" *ngIf="this.dataService.desktopView">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
    <!--  <div *ngIf="dataService.mobileView">

        <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="this.auth.companyInfo && dataService.companyUserInfo">
          <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
             (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/users']);">Members</a> <span class="font-18 gray-text-dark"> / </span>
          <a class="font-18 blue-text font-18 primaray-text font-weight-bold"
             *ngIf="dataService.companyUserInfo"
             (click)=" this.router.navigate([this.router.url.replace('/edit' , '')]);">  {{dataService.companyUserInfo.fullName}}
          </a>
        </div>

        <div class="pt-5">
          <app-user-update></app-user-update>
        </div>

      </div>-->
      <div class="container pt-4 pt-lg-5 pb-5">
        <div class="mx-lg-5">




       <div class="pb-5 mb-3" *ngIf="this.auth.companyInfo && dataService.companyUserInfo">
              <a class="font-16 blue-text text-underline"  (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/members']);" i18n="@@members">Members</a>
              <span class="font-16 gray-text-dark "> / </span>
         <a class="font-16 blue-text text-underline"  *ngIf="dataService.companyUserInfo" (click)=" this.router.navigate([this.router.url.replace('/edit' , '')]);"> {{dataService.companyUserInfo.fullName}}</a>
         <span class="font-16 gray-text-dark " > / <ng-container i18n="@@edit">Edit</ng-container> </span>
       </div>





        </div>
        <div class="mx-lg-4">
              <app-user-update></app-user-update>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





