<div
  class="modal"
  *ngIf="this.Modal.modalOn"
  [ngClass]="{ 'd-block': this.Modal.modalShow === true }"
>
  <div
    class="modal-dialog vertical-align-center"
    [ngClass]="{
      'modal-md': !this.smallWidthModalsArray.includes(this.Modal.modalName),
      'modal-width-400': this.Modal.modalName === 'passMembershipCancellation',
      'modal-width-790': this.Modal.modalName === 'NewPaymentCard' || this.Modal.modalName === 'hotelMap'
    }"
  >
    <div
      class="modal-content"
      [ngClass]="{ Visible: this.Modal.modalVisible === true }"
    >
      <div class="modal-header" *ngIf="this.Modal.headerOn">
        <h4
          class="modal-title font-16"
          id="modal-basic-title"
          [innerHTML]="this.Modal.modalTitle"
        ></h4>
        <button type="button" class="close" (click)="this.Modal.modalOf()">
          ×
        </button>
      </div>
      <div class="modal-body" [ngClass]="{ 'header-on': this.Modal.headerOn }">
        <app-approve-request
          *ngIf="this.Modal.modalName === 'tripApproval'"
          approvalId="{{ this.Modal.modalData.approvalId }}"
          ignoreRatePriceChange="{{
            this.Modal.modalData.ignoreRatePriceChange
          }}"
          averageNightlySellingPrice="{{
            this.Modal.modalData.averageNightlySellingPrice
          }}"
          sellingTotalFares="{{ this.Modal.modalData.sellingTotalFares }}"
          pageType="{{ this.Modal.modalData.pageType }}"
          isFlight="{{ this.Modal.modalData.isFlight }}"
        ></app-approve-request>
        <app-disapprove-request
          *ngIf="this.Modal.modalName === 'tripDisapproval'"
          approvalId="{{ this.Modal.modalData.approvalId }}"
          requesterName="{{ this.Modal.modalData.requesterName }}"
          commentAndReason="{{ this.Modal.modalData.commentAndReason }}"
          pageType="{{ this.Modal.modalData.pageType }}"
          isFlight="{{ this.Modal.modalData.isFlight }}"
        ></app-disapprove-request>
        <app-costs-manage
          *ngIf="Modal.modalName === 'manageCosts'"
          [id]="Modal.modalData.id"
          [name]="Modal.modalData.name"
          [isCenter]="Modal.modalData.isCenter"
          [description]="Modal.modalData.description"
        >
        </app-costs-manage>
        <app-cost-activation
          *ngIf="Modal.modalName === 'costActivation'"
          [id]="Modal.modalData.id"
          [status]="Modal.modalData.status"
          [isCenter]="Modal.modalData.isCenter"
          [isArchivingDenied]="Modal.modalData.isArchivingDenied"
        >
        </app-cost-activation>
        <div *ngIf="this.Modal.modalName === 'completeDuplicateBooking'">
          <div class="text-big gray-text-dark">{{ this.Modal.modalData }}</div>
          <div class="text-center mt-4">
            <button
              class="btn-outline-blue btn blue_btn_form"
              (click)="this.Modal.modalOf()"
            >
              No, Cancel
            </button>
            <button
              class="green_btn"
              [ngClass]="{
                'ml-2': Modal.isSiteLtr(),
                'mr-2': Modal.isSiteRtl()
              }"
              (click)="this.Modal.completeDuplicateBooking()"
            >
              Yes, Confirm
            </button>
          </div>
        </div>
        <app-request-demo
          *ngIf="this.Modal.modalName === 'requestDemo'"
        ></app-request-demo>
        <app-upload-expenses
          *ngIf="this.Modal.modalName === 'uploadExpenses'"
        ></app-upload-expenses>
        <app-contact-owner-msg
          *ngIf="this.Modal.modalName === 'contactOwner'"
        ></app-contact-owner-msg>
        <div *ngIf="this.Modal.modalName === 'GoogleReview'">
          <div class="google-review-cont popup mb-4 pt-3">
            <div
              class="super-big bigger gray-primaray-text align-middle d-inline-block"
            >
              {{ this.Modal.modalData.googleReview.rating }}
            </div>
            <div
              class="d-inline-block align-middle"
              [ngClass]="{
                'text-left': Modal.isSiteLtr(),
                'text-right': Modal.isSiteRtl()
              }"
            >
              <div class="gray-text-dark text-big font-weight-bold">
                {{ this.Modal.modalData.googleReview.ratingDescription }}
              </div>
              <div class="rating-stars-google d-inline-block">
                <div
                  class="rating-active"
                  [style.width.%]="
                    this.Modal.modalData.googleReview.ratingPercentage * 100
                  "
                ></div>
              </div>

              <b></b>
            </div>
            <span
              class="d-block font-weight-bold text-big gray-primaray-text mt-md-4 mb-md-4"
              ><span
                [ngClass]="{
                  'mr-1': Modal.isSiteLtr(),
                  'ml-1': Modal.isSiteRtl()
                }"
                >{{
                  this.Modal.modalData.userRatingsTotal | number : "" : "en-US"
                }}</span
              ><ng-container i18n="@@reviewsRecent"
                >reviews . Recent reviews:</ng-container
              ></span
            >
            <div class="google-review-holder">
              <div
                *ngFor="
                  let reviews of this.Modal.modalData.googleReview.reviews
                "
                class="google-review-item p-4 rounded light-box-shadow border-light mb-4"
              >
                <div class="d-flex">
                  <div
                    class="w-75"
                    [ngClass]="{
                      'mr-auto': Modal.isSiteLtr(),
                      'ml-auto': Modal.isSiteRtl()
                    }"
                  >
                    <div class="user-review d-inline-block">
                      <img
                        class="rounded-circle"
                        [src]="reviews.profile_photo_url"
                      />
                    </div>
                    <div
                      class="d-inline-block w-75 align-top"
                      [ngClass]="{
                        'ml-1': Modal.isSiteLtr(),
                        'mr-1': Modal.isSiteRtl()
                      }"
                    >
                      <div class="mb-2 user-name">
                        <a
                          class="gray-text-dark text-medium font-weight-bold"
                          href="{{ reviews.author_url }}"
                          target="_blank"
                        >
                          {{ reviews.author_name }}</a
                        >
                      </div>
                      <div class="date gray-text">
                        {{ reviews.relative_time_description }}
                      </div>
                    </div>
                  </div>

                  <div
                    [ngClass]="{
                      'ml-auto': Modal.isSiteLtr(),
                      'mr-auto': Modal.isSiteRtl()
                    }"
                  >
                    <div class="rating-value">
                      <div
                        class="super-big gray-text-dark align-middle d-inline-block"
                      >
                        {{ reviews.rating }}/5
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="mt-4"
                  [ngClass]="{
                    'ml-md-5 pl-md-4': Modal.isSiteLtr(),
                    'mr-md-5 pr-md-4': Modal.isSiteRtl()
                  }"
                >
                  <p class="gray-primaray-text">{{ reviews.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.Modal.modalName === 'EditPreferences'">
          <div
            [ngClass]="{
              'text-right': Modal.isSiteLtr(),
              'text-left': Modal.isSiteRtl()
            }"
          >
            <button
              (click)="Modal.modalOf()"
              class="close clickable-cursor"
              type="button"
            >
              ×
            </button>
          </div>
        </div>

        <app-new-payment-card
          *ngIf="this.Modal.modalName === 'NewPaymentCard'"
        ></app-new-payment-card>

        <app-forgot-password
          *ngIf="this.Modal.modalName === 'ForgotPassword'"
        ></app-forgot-password>
        <app-send-booking-voucher
          *ngIf="this.Modal.modalName === 'SendBookingVoucher'"
        ></app-send-booking-voucher>
        <!--   <app-company-share-voucher *ngIf="this.Modal.modalName === 'companySendBookingVoucher'"></app-company-share-voucher>-->
        <app-country-code
          *ngIf="this.Modal.modalName === 'CountryCode'"
        ></app-country-code>
        <app-company-share-hotel
          *ngIf="
            this.Modal.modalName === 'companyHotelShare' ||
            this.Modal.modalName === 'companySendBookingVoucher'
          "
        ></app-company-share-hotel>
        <app-remove-profile-image
          *ngIf="this.Modal.modalName === 'removeProfileImage'"
        ></app-remove-profile-image>
        <!--   <div *ngIf="this.Modal.modalName === 'removeProfileImage'">
      <div class="justify-content-center">
        <div class="d-flex mb-4">
          <i class="icon-warning-msg mr-3 pt-1"></i>
          <p class="gray-text-dark font-16">Are you sure you want to remove your profile image? This action cannot be undone!</p>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="this.Modal.modalOf()">Cancel </button>
          <button class="ml-2 btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5" (click)="this.Modal.removeUserProfileImage(); this.Modal.modalOf();">Remove</button>
        </div>
      </div>
    </div>-->

        <div *ngIf="this.Modal.modalName === 'deleteAddress'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16" i18n="@@AreSureDeleteAddress">
                Are you sure you want to delete {{ this.Modal.modalData.name }}?
                This action cannot be undone!
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-12 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn blue_btn font-12 py-0 px-5"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="this.Modal.deleteOfficeAddress(this.Modal.modalData)"
                i18n="@@deleteBtn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="this.Modal.modalName === 'delete_wallet'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                Are you sure you want to delete redemption channel? This action
                cannot be undone!
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-12 py-1 px-5"
                (click)="this.Modal.modalOf()"
              >
                Cancel
              </button>
              <button
                class="btn blue_btn font-12 py-0 px-5"
                [ngClass]="{
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.deleteWallet(this.Modal.modalData);
                  this.Modal.modalOf()
                "
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            this.Modal.modalName === 'withdrawApprovalRequest' ||
            this.Modal.modalName === 'withdrawApprovalRequestFlight'
          "
        >
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16" i18n="@@withdrawMsg">
                Are you sure you want to withdraw your request? This action
                cannot be undone!
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                *ngIf="this.Modal.modalName === 'withdrawApprovalRequest'"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.withdrawApprovalRequestFunc(this.Modal.modalData)
                "
                i18n="@@withdraw"
              >
                Withdraw
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                *ngIf="this.Modal.modalName === 'withdrawApprovalRequestFlight'"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.withdrawFlightApprovalRequestFunc(
                    this.Modal.modalData
                  )
                "
                i18n="@@withdraw"
              >
                Withdraw
              </button>
            </div>
            <p
              class="text-danger mt-3"
              [ngClass]="{
                'text-right': Modal.isSiteLtr(),
                'text-left': Modal.isSiteRtl()
              }"
            >
              {{ this.Modal.errorMsg }}
            </p>
          </div>
        </div>
        <div *ngIf="this.Modal.modalName === 'deleteSubscription'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                Are you sure you want to delete your subscription? This action
                cannot be undone!
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="this.Modal.deleteSubscription()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <!--  <div *ngIf="this.Modal.modalName === 'continueSubscription'">
      <div class="justify-content-center">
        <div class="d-flex mb-4">
          <p class="gray-text-dark font-16">Are you sure you want to continue your subscription?</p>
        </div>
        <div class="d-flex justify-content-end">


          <button class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5" (click)="this.Modal.modalOf()">Cancel </button>
          <button class="ml-2 btn blue_btn font-14 sharp-font bold-500 py-0 px-5" [ngClass]="{'m-progress': Modal.submitting }" (click)="this.Modal.deleteSubscription(); ">Continue</button>
        </div>
      </div>
    </div>-->
        <app-remove-program-or-passport
          *ngIf="
            this.Modal.modalName === 'deleteDoc' ||
            this.Modal.modalName === 'deleteProgram'
          "
        ></app-remove-program-or-passport>
        <app-workspace-reactivate-account
          *ngIf="this.Modal.modalName === 'reactivateAccount'"
        ></app-workspace-reactivate-account>
        <app-billing-plans
          *ngIf="this.Modal.modalName === 'editBillingPlan'"
        ></app-billing-plans>
        <app-switch-plan
          *ngIf="this.Modal.modalName === 'switchPlan'"
        ></app-switch-plan>
        <app-edit-user-payment
          *ngIf="this.Modal.modalName === 'editUserCard'"
        ></app-edit-user-payment>
        <app-update-billing-card
          *ngIf="this.Modal.modalName === 'editBilling'"
        ></app-update-billing-card>
        <app-edit-payment-alias
          *ngIf="this.Modal.modalName === 'editAlias'"
        ></app-edit-payment-alias>
        <div *ngIf="this.Modal.modalName === 'removeCompanyPayment'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16" i18n="@@deletePay">
                Are you sure you want to delete payment method? This action
                cannot be undone!
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="this.Modal.deleteCompanyPayment(this.Modal.modalData)"
                i18n="@@deleteBtn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="this.Modal.modalName === 'removeAdmin'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                Are you sure you want remove the user
                <span class="font-weight-bold">{{
                  this.Modal.modalData.userName
                }}</span>
                as admin?
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.setUserAsAdmin(this.Modal.modalData.userId, false)
                "
              >
                Remove admin
              </button>
            </div>
            <p
              class="text-danger mt-3"
              [ngClass]="{
                'text-right': Modal.isSiteLtr(),
                'text-left': Modal.isSiteRtl()
              }"
            >
              {{ this.Modal.errorMsg }}
            </p>
          </div>
        </div>
        <div *ngIf="this.Modal.modalName === 'addAdmin'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <p class="gray-text-dark font-16">
                Are you sure you want set the user
                <span class="font-weight-bold">{{
                  this.Modal.modalData.userName
                }}</span>
                as admin?
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
              >
                Cancel
              </button>
              <button
                class="btn blue_btn font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.setUserAsAdmin(this.Modal.modalData.userId, true)
                "
              >
                Set as admin
              </button>
            </div>
            <p
              class="text-danger mt-3"
              [ngClass]="{
                'text-right': Modal.isSiteLtr(),
                'text-left': Modal.isSiteRtl()
              }"
            >
              {{ this.Modal.errorMsg }}
            </p>
          </div>
        </div>

        <div *ngIf="this.Modal.modalName === 'removeTeam'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                <!--  <ng-container i18n="@@removeTeam1">Are you sure you want to delete</ng-container>&nbsp;
            <span class="font-weight-bold">{{this.Modal.modalData.teamNameValue}}</span>&nbsp;
            <ng-container i18n="@@removeTeam2">Team?</ng-container>&nbsp;-->
                <ng-container i18n="@@removeTeam3">By deleting</ng-container>
                <span> {{ this.Modal.modalData.teamNameValue }} </span>
                <ng-container i18n="@@removeTeam5"
                  >team, members will not be assigned to any travel
                  policy.</ng-container
                >
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.removeTeam(this.Modal.modalData.teamIdValue);
                  this.Modal.modalOf()
                "
                i18n="@@deleteBtn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <app-bulk-invite-users
          *ngIf="this.Modal.modalName === 'bulkInviteUsers'"
        ></app-bulk-invite-users>
        <app-user-update
          *ngIf="this.Modal.modalName === 'editUser'"
        ></app-user-update>
        <app-deactivate-subscription-reasons
          *ngIf="this.Modal.modalName === 'cancelSubscription'"
        ></app-deactivate-subscription-reasons>
        <app-workspace-remove-rewards
          *ngIf="this.Modal.modalName === 'deleteRewardsProgram'"
        ></app-workspace-remove-rewards>
        <app-user-deactivate
          *ngIf="this.Modal.modalName === 'deactivateUser'"
        ></app-user-deactivate>
        <app-user-reactivation-fees
          *ngIf="this.Modal.modalName === 'userReactivationFees'"
        ></app-user-reactivation-fees>
        <div *ngIf="this.Modal.modalName === 'cancelInvitation'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                <ng-container i18n="@@withdrawInvite1"
                  >Are you sure you want to withdraw</ng-container
                >
                <span class="font-weight-bold">{{ this.Modal.modalData }}</span>
                <ng-container i18n="@@withdrawInvite2"
                  >invitation?</ng-container
                >
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-3"
                [ngClass]="{
                  'm-progress': Modal.submitting,
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="this.Modal.cancelInvitation(this.Modal.modalData)"
                i18n="@@withdrawInvite"
              >
                Withdraw Invitation
              </button>
              <p
                class="text-danger"
                [ngClass]="{
                  'text-right': Modal.isSiteLtr(),
                  'text-left': Modal.isSiteRtl()
                }"
              >
                {{ Modal.errorMsg }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="this.Modal.modalName === 'removeMember'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                <ng-container i18n="@@removeMemberMsg1"
                  >Are you sure you want to remove</ng-container
                >&nbsp;<span class="font-weight-bold">{{
                  this.Modal.modalData.memberDescription
                }}</span>
                &nbsp;<ng-container i18n="@@fromTeam">from</ng-container
                >&nbsp;<span class="font-weight-bold">{{
                  this.Modal.modalData.teamName
                }}</span
                >&nbsp;<ng-container i18n="@@removeMemberMsg2"
                  >team?</ng-container
                >
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.removeMemberFromTeam(
                    this.Modal.modalData.teamId,
                    this.Modal.modalData.memberId
                  );
                  this.Modal.modalOf()
                "
                i18n="@@removeMemberWithThe"
              >
                Remove Member
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="this.Modal.modalName === 'removePolicy'">
          <div class="justify-content-center">
            <div class="d-flex mb-4">
              <i
                class="icon-warning-msg pt-1"
                [ngClass]="{
                  'mr-3': Modal.isSiteLtr(),
                  'ml-3': Modal.isSiteRtl()
                }"
              ></i>
              <p class="gray-text-dark font-16">
                <ng-container i18n="@@removePolicy3">By deleting</ng-container>
                <span> {{ this.Modal.modalData.policyName }} </span>
                <ng-container i18n="@@removePolicyMsg3"
                  >policy, members linked to this policy will not have any
                  travel restrictions.</ng-container
                >
              </p>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
                (click)="this.Modal.modalOf()"
                i18n="@@cancelBtn"
              >
                Cancel
              </button>
              <button
                class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
                [ngClass]="{
                  'ml-2': Modal.isSiteLtr(),
                  'mr-2': Modal.isSiteRtl()
                }"
                (click)="
                  this.Modal.removePolicy(this.Modal.modalData.policyId);
                  this.Modal.modalOf()
                "
                i18n="@@deleteBtn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <app-create-team
          *ngIf="this.Modal.modalName === 'createNewTeam'"
        ></app-create-team>
        <app-create-team
          *ngIf="this.Modal.modalName === 'editTeam'"
          teamId="{{ this.Modal.modalData }}"
        ></app-create-team>
        <app-create-policy
          *ngIf="this.Modal.modalName === 'createPolicy'"
        ></app-create-policy>
        <app-create-policy
          *ngIf="this.Modal.modalName === 'editPolicy'"
          policyId="{{ this.Modal.modalData }}"
        ></app-create-policy>
        <app-add-team-members
          *ngIf="this.Modal.modalName === 'addTeamMembers'"
        ></app-add-team-members>
        <app-invite-users
          *ngIf="this.Modal.modalName === 'inviteUsers'"
        ></app-invite-users>
        <div *ngIf="this.Modal.modalName === 'deletePayment'">
          <div class="d-flex mb-4">
            <i
              class="icon-warning-msg pt-1"
              [ngClass]="{
                'mr-3': Modal.isSiteLtr(),
                'ml-3': Modal.isSiteRtl()
              }"
            ></i>
            <p class="gray-text-dark font-16" i18n="@@deletePay">
              Are you sure you want to delete payment method? This action cannot
              be undone!
            </p>
          </div>
          <div class="d-flex justify-content-end">
            <button
              class="btn-outline-gray btn font-14 sharp-font bold-500 py-1 px-5"
              (click)="this.Modal.modalOf()"
              i18n="@@cancelBtn"
            >
              Cancel
            </button>
            <button
              class="btn btn-outline-red font-14 sharp-font bold-500 py-0 px-5"
              [ngClass]="{
                'm-progress': Modal.submitting,
                'ml-2': Modal.isSiteLtr(),
                'mr-2': Modal.isSiteRtl()
              }"
              (click)="this.Modal.deletePayment(this.Modal.modalData)"
              i18n="@@deleteCard"
            >
              Delete
            </button>
          </div>
        </div>
        <div *ngIf="this.Modal.modalName === 'roomInfo'">
          <div *ngIf="this.Modal.modalData">
            <div
              *ngIf="
                !this.Modal.modalData.description &&
                !this.Modal.modalData.facilities
              "
              class="warning bolder"
            >
              Unfortunately, no details available for this room
            </div>
            <div
              class="row no-gutters mb-3"
              *ngIf="
                (this.Modal.modalData.description ||
                  this.Modal.modalData.facilities) &&
                this.Modal.modalData.images &&
                  this.Modal.modalData.images[0].url &&
                  this.Modal.modalData.images.length > 0
              "
            >
              <div class="col-md-8">
                <swiper
                  #thumbview
                  class="swiper-container room-description-slider"
                  [config]="configLb"
                  (indexChange)="onIndexChange($event)"
                  fxFlex="auto"
                >
                  <div
                    *ngFor="
                      let image of this.Modal.modalData.images;
                      let i = index
                    "
                    class="swiper-slide"
                  >
                    <div
                      fxLayout="column"
                      fxLayoutAlign="center center"
                      fxFlexFill
                    >
                      <figure
                        *ngIf="image.url && image.url.includes('https://')"
                        [style.backgroundImage]="'url(' + image.url + ')'"
                        class="w-100 modal-img"
                        title="{{ image.caption }}"
                      ></figure>
                      <figure
                        *ngIf="image.url && !image.url.includes('https://')"
                        [style.backgroundImage]="
                          'url(' +
                          image.url.replace('http://', 'https://') +
                          ')'
                        "
                        class="w-100 modal-img"
                        title="{{ image.caption }}"
                      ></figure>
                    </div>
                  </div>
                </swiper>
                <div class="slider-prev modal-slider-arrow">
                  <i class="icon-chevron-left"></i>
                </div>
                <div class="slider-next modal-slider-arrow">
                  <i class="icon-chevron-right"></i>
                </div>
              </div>
              <div class="col-md-4">
                <ul
                  class="slidelist p-1 d-none d-md-flex row no-gutters list-unstyled"
                  *ngIf="this.Modal.modalData.images.length > 1"
                >
                  <li
                    *ngFor="
                      let image of this.Modal.modalData.images;
                      let i = index
                    "
                    class="col-md-3"
                    [class.active]="selectedIndex === i"
                    (click)="sliderindexSet(i)"
                  >
                    <img
                      *ngIf="image.url && image.url.includes('https://')"
                      class="w-100"
                      [src]="image.url"
                    />
                    <img
                      *ngIf="image.url && !image.url.includes('https://')"
                      class="w-100"
                      [src]="image.url.replace('http://', 'https://')"
                    />

                    <ng-container *ngIf="!image.url">
                      <img class="w-100" src="assets/img/no_image.png" />
                    </ng-container>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            *ngIf="this.Modal.modalData.description"
            [innerHTML]="this.Modal.modalData.description"
          ></div>

          <div *ngIf="this.Modal.modalData.facilities">
            <h4>Facilities</h4>
            <div class="facititle row">
              <div
                class="col-md-4"
                *ngFor="let item of this.Modal.modalData.facilities"
              >
                <div class="list-info">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="this.Modal.modalName === 'hotelMap'" class="mappopup-cont">
          <agm-map
            class="popup-map"
            [latitude]="this.Modal.modalData.latitude"
            [longitude]="this.Modal.modalData.longitude"
            [zoom]="16"
            [disableDefaultUI]="false"
            [zoomControl]="true"
          >
            <agm-marker
              [latitude]="this.Modal.modalData.latitude"
              [longitude]="this.Modal.modalData.longitude"
              iconUrl="assets/img/Union.png"
            >
            </agm-marker>
          </agm-map>
          <div
            class="hotel-info-landmarks d-block d-md-none"
            [ngClass]="{ active: this.Modal.modalData.isCollapsed }"
          >
            <div class="d-flex border-bottom">
              <div
                class="p-3"
                [ngClass]="{
                  'mr-auto': Modal.isSiteLtr(),
                  'ml-auto': Modal.isSiteRtl()
                }"
              >
                <b class="mb-0">See what's near you</b>
              </div>
              <div>
                <button
                  class="btn"
                  [ngClass]="{
                    'text-right': Modal.isSiteLtr(),
                    'text-left': Modal.isSiteRtl()
                  }"
                  [attr.aria-expanded]="!this.Modal.modalData.isCollapsed"
                  (click)="
                    this.Modal.modalData.isCollapsed =
                      !this.Modal.modalData.isCollapsed
                  "
                >
                  <i class="icon-down"></i>
                </button>
              </div>
            </div>
            <div
              class="hotel-info-right-landmarks"
              [ngbCollapse]="this.Modal.modalData.isCollapsed"
            >
              <div class="landmarks-list">
                <div
                  class="d-flex border-bottom p-2"
                  *ngFor="let item of this.Modal.modalData.nearByList"
                >
                  <span
                    class="w-50"
                    [ngClass]="{
                      'mr-auto': Modal.isSiteLtr(),
                      'ml-auto': Modal.isSiteRtl()
                    }"
                  >
                    {{ item.name }}</span
                  >
                  <span>
                    ({{ item.distanceFromPropertyInMiles }} Mile /
                    {{ item.distanceFromPropertyInKilometers }} KM)</span
                  >
                </div>
              </div>
            </div>

            <div class="hotel-info-right-landmarks">
              <div
                class="landmarks-list"
                *ngIf="this.Modal.modalData.nearByList"
              >
                <div
                  class="d-flex border-bottom p-2"
                  *ngFor="let item of this.Modal.modalData.nearByList"
                >
                  <span
                    class="w-50"
                    [ngClass]="{
                      'mr-auto': Modal.isSiteLtr(),
                      'ml-auto': Modal.isSiteRtl()
                    }"
                  >
                    {{ item.name }}</span
                  >
                  <span>
                    ({{ item.distanceFromPropertyInMiles }} Mile /
                    {{ item.distanceFromPropertyInKilometers }} KM)</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="this.Modal.modalName === 'voucherMap'"
          class="mappopup-cont"
        >
          <agm-map
            class="popup-map-full"
            [latitude]="this.Modal.modalData.latitude"
            [longitude]="this.Modal.modalData.longitude"
            [zoom]="16"
            [disableDefaultUI]="false"
            [zoomControl]="true"
          >
            <agm-marker
              [latitude]="this.Modal.modalData.latitude"
              [longitude]="this.Modal.modalData.longitude"
              iconUrl="assets/img/Union.png"
            >
            </agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="this.Modal.modalOn"
    (click)="this.Modal.modalOf()"
    class="modal-backdrop show"
  ></div>
  <div
    *ngIf="this.Modal.shadowClickOff"
    class="modal-backdrop-transparent show"
  ></div>
</div>
