import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import * as Lightpick from "../../../js/lightpickCustom/lightpick";
import { AuthServiceMain } from "../../auth/auth.service";
import { DataService } from "../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { v4 as uuid } from "uuid";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import { VariablesService } from "../../services/vars.service";
import { DatePipe } from "@angular/common";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
export class User {
  constructor(
    public description: string,
    public firstName: string,
    public lastName: string,
    public id: string,
    public email: string,
    public inactive: boolean,
    public profileImageBackgroundHexColor: string,
    public profileImage: string,
    public teamName?: string,
    public selected?: boolean
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}
@Component({
  selector: "app-flights-search-controller",
  templateUrl: "./flights-search-controller.component.html",
  styleUrls: ["./flights-search-controller.component.scss"],
  host: {
    "(document:click)": "onClickBody($event)",
  },
})
export class FlightsSearchControllerComponent implements OnInit {
  @Input() fromHomePage: boolean = false;
  data;
  hoveredDate: NgbDate | null = null;
  returnDate: NgbDate | null;
  departureDate: NgbDate | null;
  home = false;
  oldsearchQuery;
  oldcaption;
  destination = "";
  queryParams;
  autocompletelistDepart;
  autocompletelistReturn;
  returningRangeFormatted;
  pickerOneWay;
  picker;
  onclickActive = true;
  departingRangeFormatted;
  decreaseChildrenDisabled = true;
  decreaseAdultDisabled = false;
  returningRange;
  departingRange;
  searchQuery;
  searchQueryReturn;
  searchQueryDepart;
  adultCount = 1;
  paxCount = 1;
  childrenCount = 0;
  decreaseDisabled = false;
  increasDisabled = false;
  Query = "";
  entity;
  caption = "";
  captionReturn = "";
  captionDepart = "";
  children = [];
  ages = [];
  searchForm: FormGroup;
  submitted = false;
  validChild = false;
  Childrenpax = "";
  checki;
  childrenList = [];
  availability = false;
  SearchTextQuery = new Subject<string>();
  SearchTextQueryReturn = new Subject<string>();
  readyToSearch = true;
  submitting = false;
  urlArray;
  tripType = [
    $localize`:@@roundTrip:Round-trip`,
    $localize`:@@oneWay:One-way`,
    $localize`:@@multiCity:Multi-city`,
  ];
  roundTripLocalized = $localize`:@@roundTrip:Round-trip`;
  oneWayLocalized = $localize`:@@oneWay:One-way`;
  multiCityLocalized = $localize`:@@multiCity:Multi-city`;
  selectedClass = { value: "ECONOMY", name: $localize`:@@economy:Economy` };
  selectedTrip = $localize`:@@roundTrip:Round-trip`;
  selectedTripParam;
  arrivalCode;
  departureCode;
  returningRangeFormattedCity = [];
  arrivalCodeArray = [];

  allCities = [
    {
      SearchTextQuery: new Subject<string>(),
      SearchTextQueryReturn: new Subject<string>(),
      autocompletelistDepart: [],
      autocompletelistReturn: [],
      returningRange: null,
      departingRange: null,
      returnDate: null,
      departureDate: null,
      returningRangeFormatted: null,
      departingRangeFormatted: null,
      captionReturn: "",
      captionDepart: "",
      arrivalCode: null,
      departureCode: null,
      datePicker: null,
    },
    {
      SearchTextQuery: new Subject<string>(),
      SearchTextQueryReturn: new Subject<string>(),
      autocompletelistDepart: [],
      autocompletelistReturn: [],
      returningRange: null,
      departingRange: null,
      returnDate: null,
      departureDate: null,
      returningRangeFormatted: null,
      departingRangeFormatted: null,
      captionReturn: "",
      captionDepart: "",
      arrivalCode: null,
      departureCode: null,
      datePicker: null,
    },
  ];
  allCitiesLocalStorageReturnArray = [];
  allCitiesLocalStorageDepartArray = [];

  /***** guest changes *****/
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = "";
  dataUsers;
  @ViewChild("userInput") userInput: ElementRef;
  maxNoUserToShow: number = 3;
  guest: User = {
    description: $localize`:@@guestSelect:Guest`,
    firstName: "",
    lastName: "",
    id: "guest",
    email: null,
    inactive: false,
    profileImageBackgroundHexColor: "#" + "5aa4e1",
    profileImage: "assets/img/guest.svg",
    teamName: null,
    selected: false,
  };
  /***** guest changes *****/
  travelerId;
  constructor(
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public datePipe: DatePipe,
    public googleAnalyticsService: GoogleAnalyticsService,
    public vars: VariablesService,
    private formBuilder: FormBuilder,
    public Auth: AuthServiceMain,
    public restApi: DataService,
    public router: Router,
    public route: ActivatedRoute,
    private eref: ElementRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  showReturnDate() {
    this.returnDate = this.calendar.getNext(this.departureDate, "d", 0);
    this.returningRangeFormatted = this.datePipe.transform(
      this.formatter.format(this.returnDate),
      "EE, MMM dd"
    );
  }

  onDateSelection(date: NgbDate) {
    if (
      this.selectedTrip === "Round-trip" ||
      this.selectedTrip === this.roundTripLocalized
    ) {
      if (!this.departureDate && !this.returnDate) {
        this.departureDate = date;

        this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
        );
        this.returningRangeFormatted = "";
      } else if (
        this.departureDate &&
        !this.returnDate &&
        date &&
        (date.after(this.departureDate) || date.equals(this.departureDate))
      ) {
        this.returnDate = date;
        this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.returnDate),
          "EE, MMM dd"
        );
      } else {
        this.returnDate = null;
        this.departureDate = date;
        this.returningRangeFormatted = "";
        this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
        );
      }
      if (this.departureDate && this.returnDate) {
        this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.returnDate),
          "EE, MMM dd"
        );
      }
    } else if (
      this.selectedTrip === "One-way" ||
      this.selectedTrip === this.oneWayLocalized
    ) {
      this.departureDate = date;
      this.departingRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.departureDate),
        "EE, MMM dd"
      );
    }
  }

  doneDate() {
    if (!this.returnDate) {
      this.returnDate = this.calendar.getNext(this.departureDate, "d", 1);
      this.returningRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.returnDate),
        "EE, MMM dd"
      );
    }
  }

  isHovered(date: NgbDate) {
    if (
      this.selectedTrip === "Round-trip" ||
      this.selectedTrip === this.roundTripLocalized
    ) {
      return (
        this.departureDate &&
        !this.returnDate &&
        this.hoveredDate &&
        date.after(this.departureDate) &&
        date.before(this.hoveredDate)
      );
    } else if (
      this.selectedTrip === "One-way" ||
      this.selectedTrip === this.oneWayLocalized
    ) {
      return date.equals(this.departureDate);
    }
  }

  isInside(date: NgbDate) {
    return (
      this.returnDate &&
      date.after(this.departureDate) &&
      date.before(this.returnDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.departureDate) ||
      (this.returnDate && date.equals(this.returnDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (this.router.url.includes("travelerId=")) {
        this.travelerId = ("" + this.router.url).substring(
          ("" + this.router.url).indexOf("travelerId=") + 11
        );
      } else {
        this.travelerId = null;
      }
      if (this.Auth.isOwner() || this.Auth.isAdmin()) {
        if (this.travelerId && this.travelerId !== "guest") {
          this.restApi.getCompanyUserDetails(this.travelerId).subscribe(
            (data) => {
              this.data = data;
              if (this.data.errorCode === 0) {
                let element = this.data.data;
                if (element.teamName) {
                  this.selectedUser = new User(
                    element.firstName + " " + element.lastName,
                    element.firstName,
                    element.lastName,
                    element.id,
                    element.email,
                    element.inactive,
                    element.profileImageBackgroundHexColor,
                    element.profileImage,
                    element.teamName
                  );
                } else {
                  this.selectedUser = new User(
                    element.firstName + " " + element.lastName,
                    element.firstName,
                    element.lastName,
                    element.id,
                    element.email,
                    element.inactive,
                    element.profileImageBackgroundHexColor,
                    element.profileImage
                  );
                }
                this.userControl.setValue(this.selectedUser);
              } else {
                this.restApi.messages(this.data.message, "error");
              }
            },
            (error) => {
              this.restApi.messages(environment.messages.error, "error");
            }
          );
        } else if (this.travelerId === "guest") {
          this.selectedUser = this.guest;
          this.userControl.setValue(this.guest);
        }
      } else {
        if (this.Auth.userInfo.teamName) {
          this.selectedUser = new User(
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
            this.Auth.userInfo.name,
            this.Auth.userInfo.lastName,
            this.Auth.userInfo.id,
            this.Auth.userInfo.email,
            this.Auth.userInfo.inactive,
            this.Auth.userInfo.profileImageBackgroundHexColor,
            this.Auth.userInfo.profileImage,
            this.Auth.userInfo.teamName
          );
        } else {
          this.selectedUser = new User(
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
            this.Auth.userInfo.name,
            this.Auth.userInfo.lastName,
            this.Auth.userInfo.id,
            this.Auth.userInfo.email,
            this.Auth.userInfo.inactive,
            this.Auth.userInfo.profileImageBackgroundHexColor,
            this.Auth.userInfo.profileImage
          );
        }
        this.userControl.setValue(this.selectedUser);
      }
    });
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("search-page");
    if (localStorage.getItem("lastSearchReturn")) {
      const lastSearchReturn = JSON.parse(
        localStorage.getItem("lastSearchReturn")
      );
      this.restApi.captionReturn = lastSearchReturn.caption;
      this.searchQueryReturn = lastSearchReturn.caption;
      this.captionReturn = lastSearchReturn.caption;
      this.arrivalCode = lastSearchReturn.code;
      if (!localStorage.getItem("lastSearchDepartAndReturnArray")) {
        this.allCities[0].captionReturn = this.captionReturn;
        this.allCities[0].arrivalCode = this.arrivalCode;
      }
    }
    if (localStorage.getItem("lastSearchDepart")) {
      const lastSearchDepart = JSON.parse(
        localStorage.getItem("lastSearchDepart")
      );
      this.restApi.captionDepart = lastSearchDepart.caption;
      this.searchQueryDepart = lastSearchDepart.caption;
      this.captionDepart = lastSearchDepart.caption;
      this.departureCode = lastSearchDepart.code;
      if (!localStorage.getItem("lastSearchDepartAndReturnArray")) {
        this.allCities[0].captionDepart = this.captionDepart;
        this.allCities[0].departureCode = this.departureCode;
      }
    }
    if (localStorage.getItem("lastSearchDepartAndReturnArray")) {
      const lastSearchDepartAndReturnArray = JSON.parse(
        localStorage.getItem("lastSearchDepartAndReturnArray")
      );
      for (let i = 0; i < lastSearchDepartAndReturnArray; i++) {
        if (!this.allCities[i]) {
          this.allCities.push({
            SearchTextQuery: new Subject<string>(),
            SearchTextQueryReturn: new Subject<string>(),
            autocompletelistDepart: [],
            autocompletelistReturn: [],
            returningRange: null,
            departingRange: null,
            returnDate: null,
            departureDate: null,
            returningRangeFormatted: null,
            departingRangeFormatted: null,
            captionReturn: "",
            captionDepart: "",
            arrivalCode: null,
            departureCode: null,
            datePicker: null,
          });
        }
        const lastSearchDepart = JSON.parse(
          localStorage.getItem("lastSearchDepart" + i)
        );
        const lastSearchReturn = JSON.parse(
          localStorage.getItem("lastSearchReturn" + i)
        );
        this.allCitiesLocalStorageDepartArray.push(lastSearchDepart);
        this.allCitiesLocalStorageReturnArray.push(lastSearchReturn);
        if (this.restApi.captionDepartArray[i]) {
          this.restApi.captionDepartArray[i] = lastSearchDepart.caption;
        } else {
          this.restApi.captionDepartArray.push(lastSearchDepart.caption);
        }
        this.allCities[i].captionDepart = lastSearchDepart.caption;
        this.allCities[i].departureCode = lastSearchDepart.code;

        if (this.restApi.captionReturnArray[i]) {
          this.restApi.captionReturnArray[i] = lastSearchReturn.caption;
        } else {
          this.restApi.captionReturnArray.push(lastSearchReturn.caption);
        }
        this.allCities[i].captionReturn = lastSearchReturn.caption;
        this.allCities[i].arrivalCode = lastSearchReturn.code;
      }
      if (!localStorage.getItem("lastSearchReturn")) {
        this.restApi.captionReturn = this.allCities[0].captionReturn;
        this.captionReturn = this.allCities[0].captionReturn;
        this.arrivalCode = this.allCities[0].arrivalCode;
      }
      if (!localStorage.getItem("lastSearchDepart")) {
        this.restApi.captionDepart = this.allCities[0].captionDepart;
        this.captionDepart = this.allCities[0].captionDepart;
        this.departureCode = this.allCities[0].departureCode;
      }
    }
    this.urlArray = this.router.url.split("/");
    this.searchForm = this.formBuilder.group({}, {});
    for (let i = 0; i < this.allCities.length; i++) {
      this.searchForm.addControl(
        i.toString(),
        this.formBuilder.group({
          departAutoComplete: new FormControl(),
        })
      );
      this.searchForm.addControl(
        "return" + i,
        this.formBuilder.group({
          departAutoComplete: new FormControl(),
        })
      );
    }
    if (
      this.restApi.urlWithoutParams === "/availability" ||
      (this.restApi.isCompany() &&
        this.restApi.urlWithoutParams ===
          "/" + this.urlArray[1] + "/availability")
    ) {
      this.availability = true;
    }
    if (
      this.restApi.urlWithoutParams === "/" ||
      (this.restApi.isCompany() &&
        this.restApi.urlWithoutParams === "/" + this.urlArray[1])
    ) {
      this.home = true;
    }
    this.route.queryParams.subscribe((params) => {
      let flightSearchDates = undefined;
      if (JSON.parse(localStorage.getItem("flightSearchDates"))) {
        flightSearchDates = JSON.parse(
          localStorage.getItem("flightSearchDates")
        );
        if (
          moment(flightSearchDates.departureDate).isBefore(moment(), "day") &&
          flightSearchDates.returnDate
        ) {
          localStorage.setItem(
            "flightSearchDates",
            JSON.stringify({
              departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
              returnDate: moment().add(17, "days").format("YYYY-MM-DD"),
            })
          );
          flightSearchDates = {
            departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
            returnDate: moment().add(17, "days").format("YYYY-MM-DD"),
          };
        } else if (
          moment(flightSearchDates.departureDate).isBefore(moment(), "day")
        ) {
          localStorage.setItem(
            "flightSearchDates",
            JSON.stringify({
              departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
            })
          );
          flightSearchDates = {
            departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
          };
        }
      }
      this.queryParams = params;
      this.vars.classType.forEach((element) => {
        if (this.queryParams.cabinClass === element.value) {
          this.selectedClass = element;
        }
      });
      if (params.trip === "ONEWAY") {
        this.selectedTrip = this.oneWayLocalized;
        if (
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid()
        ) {
          if (
            !this.departingRange ||
            moment(this.departingRange).isBefore(moment(), "day")
          ) {
            this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
            this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
          }
        } else if (
          params.departureDate !== undefined &&
          params.paxes !== undefined
        ) {
          this.departingRange = moment(params.departureDate).format(
            "YYYY-MM-DD"
          );
          this.returningRange = moment(params.departureDate)
            .add(3, "days")
            .format("YYYY-MM-DD");
        } else {
          if (
            params.departureDate === undefined &&
            this.departingRange === ""
          ) {
            if (flightSearchDates) {
              this.departingRange = flightSearchDates.departureDate;
            } else {
              this.departingRange = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
              this.returningRange = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
            }
          } else if (!this.departingRange) {
            this.departingRange = moment(params.departureDate).format(
              "YYYY-MM-DD"
            );
            this.returningRange = moment(params.departureDate)
              .add(3, "days")
              .format("YYYY-MM-DD");
          }
        }
      } else if (params.trip === "MULTICITY") {
        this.selectedTrip = this.multiCityLocalized;
        const departureDateArray = params.departureDate.split(",");
        const citiesArrayLength = departureDateArray.length;
        for (let i = 0; i < citiesArrayLength; i++) {
          if (!this.allCities[i]) {
            this.allCities.push({
              SearchTextQuery: new Subject<string>(),
              SearchTextQueryReturn: new Subject<string>(),
              autocompletelistDepart: [],
              autocompletelistReturn: [],
              returningRange: null,
              departingRange: null,
              returnDate: null,
              departureDate: null,
              returningRangeFormatted: null,
              departingRangeFormatted: null,
              captionReturn: "",
              captionDepart: "",
              arrivalCode: null,
              departureCode: null,
              datePicker: null,
            });
          }
          this.allCities[i].departingRange = moment(
            departureDateArray[i]
          ).format("YYYY-MM-DD");
        }
      } else {
        if (
          params.returnDate === "null" ||
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid() ||
          !moment(params.returnDate).isValid() ||
          moment
            .utc(params.returnDate)
            .diff(moment.utc(params.departureDate), "days") < 0
        ) {
          if (
            !this.departingRange ||
            moment(this.departingRange).isBefore(moment(), "day")
          ) {
            this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
          }
          if (
            !this.returningRange ||
            moment(this.returningRange).isBefore(moment(), "day")
          ) {
            this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
          }
        } else if (
          params.departureDate !== undefined &&
          params.returnDate !== undefined &&
          params.paxes !== undefined
        ) {
          this.returningRange = moment(params.returnDate).format("YYYY-MM-DD");

          this.departingRange = moment(params.departureDate).format(
            "YYYY-MM-DD"
          );
        } else {
          if (params.departureDate === undefined && !this.departingRange) {
            if (flightSearchDates) {
              this.departingRange = flightSearchDates.departureDate;
            } else {
              this.departingRange = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
            }
          } else if (!this.departingRange) {
            this.departingRange = moment(params.departureDate).format(
              "YYYY-MM-DD"
            );
          }

          if (params.returnDate === undefined && !this.returningRange) {
            if (flightSearchDates) {
              this.returningRange = flightSearchDates.returnDate;
            } else {
              this.returningRange = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
            }
          } else if (!this.returningRange) {
            this.returningRange = moment(params.returnDate).format(
              "YYYY-MM-DD"
            );
          }
        }

        const days = moment
          .utc(this.returningRange)
          .diff(moment.utc(this.departingRange), "days");
        if (days < 0) {
          this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
          this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
        }
      }

      let splitDateDeparture = this.departingRange.split("-");
      let checkDepartureDateObj: any;
      checkDepartureDateObj = {
        year: Number(splitDateDeparture[0]),
        month: Number(splitDateDeparture[1]),
        day: Number(splitDateDeparture[2]),
      };
      this.departureDate = this.calendar.getNext(checkDepartureDateObj, "d", 0);
      let splitDateReturn = this.returningRange.split("-");
      let checkReturnDateObj: any;
      checkReturnDateObj = {
        year: Number(splitDateReturn[0]),
        month: Number(splitDateReturn[1]),
        day: Number(splitDateReturn[2]),
      };
      this.returnDate = this.calendar.getNext(checkReturnDateObj, "d", 0);
      this.departingRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.departureDate),
        "EE, MMM dd"
      );
      this.returningRangeFormatted = this.datePipe.transform(
        this.formatter.format(this.returnDate),
        "EE, MMM dd"
      );

      if (this.queryParams.paxes) {
        const childrenArr = this.queryParams.paxes.split(",");
        for (let i = 1; i < childrenArr.length; i++) {
          this.childrenList.push({ childList: childrenArr[i] });
          this.childrenCount++;
          this.decreaseChildrenDisabled = false;
          this.increaseCheck();
        }

        this.adultCount = Number(childrenArr[0]);
        if (childrenArr[1]) {
          this.childrenCount = Number(childrenArr[1]);
          this.paxCount = Number(childrenArr[1]) + Number(childrenArr[0]);
        } else {
          this.paxCount = Number(childrenArr[0]);
        }
      }
      //------------------ added to get paxes in dashboard from localStorage
      let urlArray = this.router.url.split("/");
      if (urlArray[urlArray.length - 1] === this.Auth.companyInfo.id) {
        if (!this.queryParams.paxes && localStorage.getItem("flightPaxes")) {
          const childrenArr = localStorage.getItem("flightPaxes").split(",");
          for (let i = 1; i < childrenArr.length; i++) {
            this.childrenList.push({ childList: childrenArr[i] });
            this.childrenCount++;
            this.decreaseChildrenDisabled = false;
            this.increaseCheck();
          }

          this.adultCount = Number(childrenArr[0]);
          if (childrenArr[1]) {
            this.childrenCount = Number(childrenArr[1]);
            this.paxCount = Number(childrenArr[1]) + Number(childrenArr[0]);
          } else {
            this.paxCount = Number(childrenArr[0]);
          }
        }
        if (!this.queryParams.cabinClass) {
          let selectedClass = localStorage.getItem("selectedClass");
          this.vars.classType.forEach((element) => {
            if (selectedClass === element.value) {
              this.selectedClass = element;
            }
          });
        }
        if (!this.queryParams.trip) {
          let trip = localStorage.getItem("selectedTripParam");
          if (trip === "ONEWAY") {
            this.selectedTrip = this.oneWayLocalized;
          } else if (trip === "ROUNDTRIP") {
            this.selectedTrip = this.roundTripLocalized;
          } else if (trip === "MULTICITY") {
            this.selectedTrip = this.multiCityLocalized;
          }
        }
      }
      //-------------------------------------------
      if (this.adultCount === 1) {
        this.decreaseAdultDisabled = true;
      }
    });
    this.SearchTextQueryReturn.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.searchQueryReturn = undefined;
      this.autoCompleteListReturn();
    });
    this.SearchTextQuery.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.searchQueryDepart = undefined;
      this.autoCompleteListDepart();
    });
    this.allCities.forEach((element, index) => {
      element.SearchTextQuery.pipe(
        // get value
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe((value) => {
        this.Query = value;
        this.readyToSearch = false;
        this.searchQuery = undefined;
        this.autoCompleteListDepartArray(index);
      });
      element.SearchTextQueryReturn.pipe(
        // get value
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe((value) => {
        this.Query = value;
        this.readyToSearch = false;
        this.searchQuery = undefined;
        this.autoCompleteListReturnArray(index);
      });
    });
    for (let i = 2; i < 18; i++) {
      this.ages[i] = i.toString();
    }
    this.increaseCheck();
    this.restApi.flightHistoryArray = JSON.parse(
      localStorage.getItem("flight_history_storage")
    );
    if (this.restApi.flightHistoryArray && this.restApi.flightHistoryArray[0]) {
      setTimeout(() => {
        this.restApi.captionReturn =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.arrivalCaptions[0];
        this.searchQueryReturn =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.arrivalCaptions[0];
        this.captionReturn =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.arrivalCaptions[0];
        this.arrivalCode =
          this.restApi.flightHistoryArray[0].airQueries[0].arrivalSearchCode;
        this.restApi.captionDepart =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.departureCaptions[0];
        this.searchQueryDepart =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.departureCaptions[0];
        this.captionDepart =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.departureCaptions[0];
        this.departureCode =
          this.restApi.flightHistoryArray[0].airQueries[0].departureSearchCode;
      }, 500);
    }

    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      this.users.push(this.guest);
    }
  }

  get f() {
    return this.searchForm.controls;
  }

  setSearchTextReturn(entity, dist) {
    this.Query = entity.caption;
    this.restApi.captionReturn = entity.caption;
    this.captionReturn = entity.caption;
    this.arrivalCode = entity.code;
    this.destination = dist;
    setTimeout(() => {
      this.autocompletelistReturn = [];
      this.searchQuery = entity.searchQuery;
      this.searchQueryReturn = entity.searchQuery;
      this.readyToSearch = true;
      localStorage.setItem("lastSearchReturn", JSON.stringify(entity));
    }, 500);
  }

  departReturnSwitch() {
    [this.restApi.captionDepart, this.restApi.captionReturn] = [
      this.restApi.captionReturn,
      this.restApi.captionDepart,
    ];
    [this.captionDepart, this.captionReturn] = [
      this.captionReturn,
      this.captionDepart,
    ];
    [this.departureCode, this.arrivalCode] = [
      this.arrivalCode,
      this.departureCode,
    ];
    [this.autocompletelistDepart, this.autocompletelistReturn] = [
      this.autocompletelistReturn,
      this.autocompletelistDepart,
    ];
    const tempEntityReturn = JSON.parse(
      localStorage.getItem("lastSearchReturn")
    );
    const tempEntityDepart = JSON.parse(
      localStorage.getItem("lastSearchDepart")
    );
    localStorage.setItem("lastSearchDepart", JSON.stringify(tempEntityReturn));
    localStorage.setItem("lastSearchReturn", JSON.stringify(tempEntityDepart));
  }

  departReturnSwitchArray(i) {
    [this.restApi.captionDepartArray[i], this.restApi.captionReturnArray[i]] = [
      this.restApi.captionReturnArray[i],
      this.restApi.captionDepartArray[i],
    ];
    [this.allCities[i].captionDepart, this.allCities[i].captionReturn] = [
      this.allCities[i].captionReturn,
      this.allCities[i].captionDepart,
    ];
    [this.allCities[i].departureCode, this.allCities[i].arrivalCode] = [
      this.allCities[i].arrivalCode,
      this.allCities[i].departureCode,
    ];
    [
      this.allCities[i].autocompletelistDepart,
      this.allCities[i].autocompletelistReturn,
    ] = [
      this.allCities[i].autocompletelistReturn,
      this.allCities[i].autocompletelistDepart,
    ];
    const tempEntityReturn = JSON.parse(
      localStorage.getItem("lastSearchReturn" + i)
    );
    const tempEntityDepart = JSON.parse(
      localStorage.getItem("lastSearchDepart" + i)
    );
    localStorage.setItem(
      "lastSearchDepart" + i,
      JSON.stringify(tempEntityReturn)
    );
    localStorage.setItem(
      "lastSearchReturn" + i,
      JSON.stringify(tempEntityDepart)
    );
  }

  setSearchTextDepart(entity, dist) {
    this.Query = entity.caption;
    this.restApi.captionDepart = entity.caption;
    this.captionDepart = entity.caption;
    this.destination = dist;
    this.departureCode = entity.code;
    setTimeout(() => {
      this.autocompletelistDepart = [];
      this.searchQuery = entity.searchQuery;
      this.searchQueryDepart = entity.searchQuery;
      this.readyToSearch = true;
      localStorage.setItem("lastSearchDepart", JSON.stringify(entity));
    }, 500);
  }

  setSearchTextDepartArray(entity, dist, index) {
    this.Query = entity.caption;
    if (this.restApi.captionDepartArray[index]) {
      this.restApi.captionDepartArray[index] = entity.caption;
    } else {
      this.restApi.captionDepartArray.push(entity.caption);
    }
    this.allCities[index].captionDepart = entity.caption;
    this.destination = dist;
    this.allCities[index].departureCode = entity.code;
    setTimeout(() => {
      this.allCities[index].autocompletelistDepart = [];
      this.searchQuery = entity.searchQuery;
      this.readyToSearch = true;
      if (this.allCitiesLocalStorageDepartArray[index]) {
        this.allCitiesLocalStorageDepartArray[index] = entity;
      } else {
        this.allCitiesLocalStorageDepartArray.push(entity);
      }
    }, 500);
  }
  setSearchTextReturnArray(entity, dist, index) {
    this.Query = entity.caption;
    if (this.restApi.captionReturnArray[index]) {
      this.restApi.captionReturnArray[index] = entity.caption;
    } else {
      this.restApi.captionReturnArray.push(entity.caption);
    }
    this.allCities[index].captionReturn = entity.caption;
    this.destination = dist;
    this.allCities[index].arrivalCode = entity.code;
    setTimeout(() => {
      this.allCities[index].autocompletelistReturn = [];
      this.searchQuery = entity.searchQuery;
      this.readyToSearch = true;
      if (this.allCitiesLocalStorageReturnArray[index]) {
        this.allCitiesLocalStorageReturnArray[index] = entity;
      } else {
        this.allCitiesLocalStorageReturnArray.push(entity);
      }
    }, 500);
  }

  removeCity(index) {
    this.allCities.splice(index, 1);
    this.searchForm.removeControl(index.toString());
    this.searchForm.removeControl("return" + [index]);
    localStorage.removeItem("lastSearchReturn" + index);
    localStorage.removeItem("lastSearchDepart" + index);
    this.allCitiesLocalStorageReturnArray.splice(index, 1);
    this.allCitiesLocalStorageDepartArray.splice(index, 1);
    this.restApi.captionReturnArray.splice(index, 1);
    this.restApi.captionDepartArray.splice(index, 1);
  }

  addCity() {
    this.allCities.push({
      SearchTextQuery: new Subject<string>(),
      SearchTextQueryReturn: new Subject<string>(),
      autocompletelistDepart: [],
      autocompletelistReturn: [],
      returningRange: null,
      departingRange: null,
      returnDate: null,
      departureDate: null,
      returningRangeFormatted: null,
      departingRangeFormatted: null,
      captionReturn: "",
      captionDepart: "",
      arrivalCode: null,
      departureCode: null,
      datePicker: null,
    });
    this.allCities.forEach((element, index) => {
      element.SearchTextQuery.pipe(
        // get value
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe((value) => {
        this.Query = value;
        this.readyToSearch = false;
        this.searchQuery = undefined;
        this.autoCompleteListDepartArray(index);
      });
      element.SearchTextQueryReturn.pipe(
        // get value
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe((value) => {
        this.Query = value;
        this.readyToSearch = false;
        this.searchQuery = undefined;
        this.autoCompleteListReturnArray(index);
      });
    });
    this.searchForm.addControl(
      (this.allCities.length - 1).toString(),
      this.formBuilder.group({
        departAutoComplete: new FormControl(),
      })
    );
    this.searchForm.addControl(
      "return" + (this.allCities.length - 1),
      this.formBuilder.group({
        departAutoComplete: new FormControl(),
      })
    );
    this.allCities.forEach((element, index) => {
      if (!element.departingRange) {
        element.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
      }
      if (!element.returningRange) {
        element.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
      }
      if (!element.datePicker) {
        setTimeout(() => {
          element.datePicker = new Lightpick({
            disabledDatesInRange: true,
            firstDay: 7,
            minDays: 0,
            minDate: moment(),
            startDate: element.departingRange,
            endDate: element.departingRange,
            maxDate: moment().add(11, "months"),
            field: document.getElementById("multiCity" + index),
            maxDays: 31,
            numberOfColumns: 2,
            numberOfMonths: 2,
            locale: {
              buttons: {
                prev: '<i class="icon-left2"></i>',
                next: '<i class="icon-right2"></i>',
              },
            },
            dropdowns: {
              years: false,
            },
            lang: this.restApi.corporateLocale,
            onClose: () => {
              element.departingRangeFormatted = this.datePipe.transform(
                element.datePicker.getStartDate(),
                "EE, MMM dd"
              );
              element.departingRange = moment(
                element.datePicker.getStartDate().format("YYYY-MM-DD")
              );
            },
            onSelect: () => {
              element.returningRangeFormatted = this.datePipe.transform(
                element.datePicker.getStartDate(),
                "EE, MMM dd"
              );
              element.returningRange = moment(
                element.datePicker.getStartDate().format("YYYY-MM-DD")
              );
            },
          });
          element.departingRange = moment(
            element.datePicker.getStartDate().format("YYYY-MM-DD")
          );
          element.departingRangeFormatted = this.datePipe.transform(
            element.datePicker.getStartDate(),
            "EE, MMM dd"
          );
        }, 200);
      }
    });
  }

  onSubmit() {
    this.restApi.alertsOverRoute = [];
    this.submitted = true;
    if (
      this.searchQueryReturn === undefined ||
      this.searchQueryDepart === undefined
    ) {
      return;
    }
    if (
      !this.departureCode ||
      !this.arrivalCode ||
      ((this.captionDepart === "" || this.captionReturn === "") &&
        (this.selectedTrip === "Round-trip" ||
          this.selectedTrip === "One-way" ||
          this.selectedTrip === this.roundTripLocalized ||
          this.selectedTrip === this.oneWayLocalized))
    ) {
      return;
    }
    let inValidMultiCity = false;
    if (
      this.selectedTrip === "Multi-city" ||
      this.selectedTrip === this.multiCityLocalized
    ) {
      this.allCities.forEach((element, index) => {
        if (element.captionDepart === "" || element.captionReturn === "") {
          inValidMultiCity = true;
        }
      });
      if (inValidMultiCity) {
        return;
      }
    }

    this.Childrenpax = "";
    if (this.childrenCount) {
      this.Childrenpax = "," + this.childrenCount;
    }
    localStorage.setItem("flightPaxes", this.adultCount + this.Childrenpax);
    localStorage.setItem("selectedClass", this.selectedClass.value);
    let searchType = "";
    let finalCheckDepart;
    let finalCheckReturn;
    let departMonth =
      (this.departureDate.month < 10 ? "0" : "") + this.departureDate.month;
    let departDay =
      (this.departureDate.day < 10 ? "0" : "") + this.departureDate.day;
    let returnMonth =
      (this.returnDate.month < 10 ? "0" : "") + this.returnDate.month;
    let returnDay = (this.returnDate.day < 10 ? "0" : "") + this.returnDate.day;
    finalCheckDepart =
      this.departureDate.year + "-" + departMonth + "-" + departDay;

    if (!this.restApi.isCompany()) {
      searchType = "flights/availability";
    } else {
      searchType = "/" + this.Auth.companyInfo.id + "/flights/availability";
    }

    let localStorageFlightItem = {
      roundTrip: false,
      cabinClass: this.selectedClass.value,
      airQueries: [
        {
          departureDate: finalCheckDepart,
          departureSearchCode: this.departureCode,
          departureSearchType: "AIRPORT",
          arrivalSearchCode: this.arrivalCode,
          arrivalSearchType: "AIRPORT",
        },
      ],
      lookupSearchCaptions: {
        departureCaptions: [this.captionDepart],
        arrivalCaptions: [this.captionReturn],
      },
      adultCount: this.adultCount,
      childCount: 0,
      infantCount: 0,
    };

    if (
      this.selectedTrip === "Round-trip" ||
      this.selectedTrip === this.roundTripLocalized
    ) {
      this.selectedTripParam = "ROUNDTRIP";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      finalCheckReturn =
        this.returnDate.year + "-" + returnMonth + "-" + returnDay;
      localStorageFlightItem.roundTrip = true;
      localStorageFlightItem.airQueries[0]["returnDate"] = finalCheckReturn;
      localStorage.setItem(
        "flightSearchDates",
        JSON.stringify({
          departureDate: finalCheckDepart,
          returnDate: finalCheckReturn,
        })
      );
      let arr = JSON.parse(localStorage.getItem("flight_history_storage"));
      if (
        arr &&
        JSON.stringify(localStorageFlightItem) === JSON.stringify(arr[0]) &&
        this.router.url.includes("availability")
      ) {
        if (this.travelerId === this.selectedUser.id) {
          return false;
        }
      }
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          cabinClass: this.selectedClass.value,
          departureDate: finalCheckDepart,
          departureCode: this.departureCode,
          arrivalCode: this.arrivalCode,
          paxes: this.adultCount + this.Childrenpax,
          returnDate: finalCheckReturn,
          travelerId: this.selectedUser.id,
        },
      });
    } else if (
      this.selectedTrip === "One-way" ||
      this.selectedTrip === this.oneWayLocalized
    ) {
      this.selectedTripParam = "ONEWAY";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      localStorage.setItem(
        "flightSearchDates",
        JSON.stringify({
          departureDate: finalCheckDepart,
          returnDate: moment(finalCheckDepart)
            .add(3, "days")
            .format("YYYY-MM-DD"),
        })
      );

      let arr = JSON.parse(localStorage.getItem("flight_history_storage"));
      if (
        arr &&
        JSON.stringify(localStorageFlightItem) === JSON.stringify(arr[0]) &&
        this.router.url.includes("availability")
      ) {
        if (this.travelerId === this.selectedUser.id) {
          return false;
        }
      }
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          cabinClass: this.selectedClass.value,
          departureDate: finalCheckDepart,
          departureCode: this.departureCode,
          arrivalCode: this.arrivalCode,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.selectedUser.id,
        },
      });
    } else if (
      this.selectedTrip === "Multi-city" ||
      this.selectedTrip === this.multiCityLocalized
    ) {
      this.selectedTripParam = "MULTICITY";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      let departureDateValues = "";
      let departureCodeValues = "";
      let arrivalCodeValues = "";
      this.allCities.forEach((element, index) => {
        if (index === 0) {
          departureDateValues = moment(element.departingRange).format(
            "YYYY-MM-DD"
          );
          departureCodeValues = element.departureCode;
          arrivalCodeValues = element.arrivalCode;
        } else if (index > 0) {
          departureDateValues +=
            "," + moment(element.departingRange).format("YYYY-MM-DD");
          departureCodeValues += "," + element.departureCode;
          arrivalCodeValues += "," + element.arrivalCode;
        }
        localStorage.setItem(
          "lastSearchReturn" + index,
          JSON.stringify(this.allCitiesLocalStorageReturnArray[index])
        );
        localStorage.setItem(
          "lastSearchDepart" + index,
          JSON.stringify(this.allCitiesLocalStorageDepartArray[index])
        );
      });
      localStorage.setItem(
        "lastSearchDepartAndReturnArray",
        this.allCities.length.toString()
      );
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          cabinClass: this.selectedClass.value,
          departureDate: departureDateValues,
          departureCode: departureCodeValues,
          arrivalCode: arrivalCodeValues,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.selectedUser.id,
        },
      });
    }

    if (!this.restApi.flightHistoryArray) {
      const historyArr = [];
      historyArr.unshift(localStorageFlightItem);
      localStorage.setItem(
        "flight_history_storage",
        JSON.stringify(historyArr)
      );
    } else {
      let historyArr = this.restApi.flightHistoryArray;
      if (historyArr.length === 5) {
        historyArr.splice(-1, 1);
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      } else {
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      }
    }
    this.router.onSameUrlNavigation = "reload";
  }

  decreaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;
  }
  increaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;

    if (this.childrenCount + this.adultCount === 9) {
      this.increasDisabled = true;
      return true;
    } else {
      this.increasDisabled = false;
    }
  }

  decreaseAdult() {
    this.adultCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.decreaseCheck();
    if (this.adultCount === 1) {
      this.decreaseAdultDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }

  increaseAdult() {
    this.adultCount++;
    this.paxCount++;
    this.decreaseAdultDisabled = false;
    this.increaseCheck();
  }

  decreaseChildren() {
    this.childrenCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.childrenList.pop();
    this.decreaseCheck();
    if (this.childrenCount === 0) {
      this.decreaseChildrenDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }

  increaseChildren() {
    this.childrenCount++;
    this.paxCount++;
    this.decreaseChildrenDisabled = false;
    this.increaseCheck();
    this.childrenList.push({ childList: "" });
  }

  autoCompleteListDepart() {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.autocompletelistDepart = this.data.data;
        });
    }
  }

  autoCompleteListDepartArray(index) {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.allCities[index].autocompletelistDepart = this.data.data;
        });
    }
  }

  autoCompleteListReturnArray(index) {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.allCities[index].autocompletelistReturn = this.data.data;
        });
    }
  }

  autoCompleteListReturn() {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.autocompletelistReturn = this.data.data;
        });
    }
  }

  autocompleteOnFocusDepart(event) {
    event.target.select();
    if (!sessionStorage.getItem("session-token")) {
      this.restApi.sessionToken = uuid();
      sessionStorage.setItem("session-token", this.restApi.sessionToken);
    }
  }

  autocompleteOnFocusReturn(event) {
    event.target.select();
    if (!sessionStorage.getItem("session-token")) {
      this.restApi.sessionToken = uuid();
      sessionStorage.setItem("session-token", this.restApi.sessionToken);
    }
  }

  onClickBody(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      if (!this.returnDate) {
        this.returnDate = this.calendar.getNext(this.departureDate, "d", 1);
        this.departingRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.departureDate),
          "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
          this.formatter.format(this.returnDate),
          "EE, MMM dd"
        );
      }
      this.validChild = false;
      this.onclickActive = true;
    }
  }

  keepOnMenu() {
    setTimeout(() => {
      if (this.onclickActive === true) {
        this.onclickActive = false;
      }
    }, 10);
  }

  consumerSearchTabs() {
    return (
      (!this.restApi.isCompany() &&
        this.router.url === "/pass/save-now-travel-later/welcome") ||
      (!this.restApi.isCompany() && this.restApi.urlWithoutParams === "/") ||
      (!this.restApi.isCompany() &&
        this.router.url === "/pass/free-trial-subscription/welcome")
    );
  }

  /********* guest changes *********/
  usersAutoComplete() {
    if (this.users.length < 2) {
      if (this.Auth.isOwner() || this.Auth.isAdmin()) {
        this.restApi.getCompanyUsersList().subscribe(
          (dataList) => {
            this.dataUsers = dataList;
            if (this.dataUsers.errorCode === 0) {
              this.dataUsers = this.dataUsers.data;
              this.dataUsers.forEach((element) => {
                if (!element.inactive) {
                  if (element.teamName) {
                    this.users.push(
                      new User(
                        element.description,
                        element.firstName,
                        element.lastName,
                        element.id,
                        element.email,
                        element.inactive,
                        element.profileImageBackgroundHexColor,
                        element.profileImage,
                        element.teamName
                      )
                    );
                  } else {
                    this.users.push(
                      new User(
                        element.description,
                        element.firstName,
                        element.lastName,
                        element.id,
                        element.email,
                        element.inactive,
                        element.profileImageBackgroundHexColor,
                        element.profileImage
                      )
                    );
                  }
                }
              });
              this.filteredUsers = this.userControl.valueChanges.pipe(
                startWith<string | User[]>(""),
                map((value) =>
                  typeof value === "string" ? value : this.lastFilter
                ),
                map((filter) => this.filter(filter))
              );
            } else {
              this.restApi.messages(this.data.message, "error");
            }
          },
          (error) => {
            this.restApi.messages(environment.messages.error, "error");
          }
        );
      } else {
        if (this.Auth.userInfo.teamName) {
          this.users.push(
            new User(
              this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
              this.Auth.userInfo.name,
              this.Auth.userInfo.lastName,
              this.Auth.userInfo.id,
              this.Auth.userInfo.email,
              this.Auth.userInfo.inactive,
              this.Auth.userInfo.profileImageBackgroundHexColor,
              this.Auth.userInfo.profileImage,
              this.Auth.userInfo.teamName
            )
          );
        } else {
          this.users.push(
            new User(
              this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
              this.Auth.userInfo.name,
              this.Auth.userInfo.lastName,
              this.Auth.userInfo.id,
              this.Auth.userInfo.email,
              this.Auth.userInfo.inactive,
              this.Auth.userInfo.profileImageBackgroundHexColor,
              this.Auth.userInfo.profileImage
            )
          );
        }
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith<string | User[]>(""),
          map((value) => (typeof value === "string" ? value : this.lastFilter)),
          map((filter) => this.filter(filter))
        );
      }
    }
  }

  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter((option) => {
        return option.description.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.users.slice();
    }
  }

  displayFn(user: User): string {
    return user && user.description ? user.description : "";
  }

  reset() {
    setTimeout(() => {
      if (typeof this.userControl.value !== "object") {
        this.userInput.nativeElement.value = "";
        this.userControl.setValue(null);
        this.lastFilter = "";
        this.selectedUser = null;
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith<string | User[]>(""),
          map((value) => (typeof value === "string" ? value : this.lastFilter)),
          map((filter) => this.filter(filter))
        );
      }
    }, 400);
  }

  optionClicked(event: Event, user: User) {
    this.selectedUser = user;
    this.showUsersAutoCompletePopUp = false;
    this.restApi.enableBodyScroll();
  }
}
