import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import {
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import * as Lightpick from "../../../js/lightpickCustom/lightpick";
import { DataService } from "../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthServiceMain } from "../../auth/auth.service";
import { v4 as uuid } from "uuid";
import { Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { VariablesService } from "../../services/vars.service";
import { DatePipe } from "@angular/common";
import { environment } from "../../../environments/environment";
import { User } from "../flights-search-controller/flights-search-controller.component";

@Component({
  selector: "app-flights-search-controller-mobile",
  templateUrl: "./flights-search-controller-mobile.component.html",
  styleUrls: ["./flights-search-controller-mobile.component.scss"],
})
export class FlightsSearchControllerMobileComponent implements OnInit {

  data;
  home = false;
  oldsearchQuery;
  oldcaption;
  destination = "";
  queryParams;
  autocompletelist;
  returningRangeFormatted;
  pickerOneWay;
  picker;
  onclickActive = true;
  departingRangeFormatted;
  decreaseChildrenDisabled = true;
  decreaseAdultDisabled = false;
  returningRange;
  departingRange;
  returnDate;
  departureDate;
  searchQuery;
  searchQueryReturn;
  searchQueryDepart;
  adultCount = 1;
  paxCount = 1;
  childrenCount = 0;
  decreaseDisabled = false;
  increasDisabled = false;
  Query = "";
  entity;
  caption = "";
  captionReturn = "";
  captionDepart = "";
  children = [];
  ages = [];
  searchForm: FormGroup;
  submitted = false;
  validChild = false;
  Childrenpax = "";
  checki;
  childrenList = [];
  availability = false;
  SearchTextQuery = new Subject<string>();
  SearchTextQueryReturn = new Subject<string>();
  readyToSearch = true;
  submitting = false;
  urlArray;
  tripType = [
    $localize`:@@roundTrip:Round-trip`,
    $localize`:@@oneWay:One-way`,
    $localize`:@@multiCity:Multi-city`,
  ];
  roundTripLocalized = $localize`:@@roundTrip:Round-trip`;
  oneWayLocalized = $localize`:@@oneWay:One-way`;
  multiCityLocalized = $localize`:@@multiCity:Multi-city`;
  selectedClass = { value: "ECONOMY", name: $localize`:@@economy:Economy` };
  selectedTrip = $localize`:@@roundTrip:Round-trip`;
  selectedTripParam;
  arrivalCode;
  departureCode;
  beforeChange = "";
  textFillDepart = false;
  textFillReturn = false;
  showSearchPopUpautocompleteDepart = false;
  showSearchPopUpautocompleteReturn = false;
  autocompleteOn = true;
  activePopUp = false;
  showSearchPopDates = false;
  searchValid = false;
  showSearchPopPaxes = false;
  showSearchPopUpActive = false;
  captionbyClickDepart = "Select an origin";
  captionbyClickReturn = "Select a destination";
  setSearchTextDepartActive = false;
  setSearchTextReturnActive = false;
  dateActive = true;
  /***** guest changes *****/
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = "";
  dataUsers;
  @ViewChild("userInput") userInput: ElementRef;
  maxNoUserToShow: number = 3;
  guest: User = {
    description: $localize`:@@guestSelect:Guest`,
    firstName: "",
    lastName: "",
    id: "guest",
    email: null,
    inactive: false,
    profileImageBackgroundHexColor: "#" + "5aa4e1",
    profileImage: "assets/img/guest.svg",
    teamName: null,
    selected: false,
  };
  /***** guest changes *****/
  travelerId;
  constructor(
    public datePipe: DatePipe,
    public googleAnalyticsService: GoogleAnalyticsService,
    public vars: VariablesService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public Auth: AuthServiceMain,
    public restApi: DataService,
    public router: Router,
    public route: ActivatedRoute,
    private eref: ElementRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (this.router.url.includes("travelerId=")) {
        this.travelerId = ("" + this.router.url).substring(
          ("" + this.router.url).indexOf("travelerId=") + 11
        );
      } else {
        this.travelerId = null;
      }
      if (this.Auth.isOwner() || this.Auth.isAdmin()) {
        if (this.travelerId && this.travelerId !== "guest") {
          this.restApi.getCompanyUserDetails(this.travelerId).subscribe(
            (data) => {
              this.data = data;
              if (this.data.errorCode === 0) {
                let element = this.data.data;
                if (element.teamName) {
                  this.selectedUser = new User(
                    element.firstName + " " + element.lastName,
                    element.firstName,
                    element.lastName,
                    element.id,
                    element.email,
                    element.inactive,
                    element.profileImageBackgroundHexColor,
                    element.profileImage,
                    element.teamName
                  );
                } else {
                  this.selectedUser = new User(
                    element.firstName + " " + element.lastName,
                    element.firstName,
                    element.lastName,
                    element.id,
                    element.email,
                    element.inactive,
                    element.profileImageBackgroundHexColor,
                    element.profileImage
                  );
                }
                this.userControl.setValue(this.selectedUser);
              } else {
                this.restApi.messages(this.data.message, "error");
              }
            },
            (error) => {
              this.restApi.messages(environment.messages.error, "error");
            }
          );
        } else if (this.travelerId === "guest") {
          this.selectedUser = this.guest;
          this.userControl.setValue(this.guest);
        }
      } else {
        if (this.Auth.userInfo.teamName) {
          this.selectedUser = new User(
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
            this.Auth.userInfo.name,
            this.Auth.userInfo.lastName,
            this.Auth.userInfo.id,
            this.Auth.userInfo.email,
            this.Auth.userInfo.inactive,
            this.Auth.userInfo.profileImageBackgroundHexColor,
            this.Auth.userInfo.profileImage,
            this.Auth.userInfo.teamName
          );
        } else {
          this.selectedUser = new User(
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
            this.Auth.userInfo.name,
            this.Auth.userInfo.lastName,
            this.Auth.userInfo.id,
            this.Auth.userInfo.email,
            this.Auth.userInfo.inactive,
            this.Auth.userInfo.profileImageBackgroundHexColor,
            this.Auth.userInfo.profileImage
          );
        }
        this.userControl.setValue(this.selectedUser);
      }
    });
    if (localStorage.getItem("lastSearchReturn")) {
      const lastSearchReturn = JSON.parse(
        localStorage.getItem("lastSearchReturn")
      );
      this.restApi.captionReturn = lastSearchReturn.caption;
      this.captionReturn = lastSearchReturn.caption;
      this.arrivalCode = lastSearchReturn.code;
    }
    if (localStorage.getItem("lastSearchDepart")) {
      const lastSearchDepart = JSON.parse(
        localStorage.getItem("lastSearchDepart")
      );
      this.restApi.captionDepart = lastSearchDepart.caption;
      this.captionDepart = lastSearchDepart.caption;
      this.departureCode = lastSearchDepart.code;
    }

    /*  this.SearchTextQuery.pipe(
      // get value
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {

        this.Query = value;
        this.autoCompleteList()


      });*/

    this.urlArray = this.router.url.split("/");
    this.searchForm = this.formBuilder.group({}, {});

    if (
      this.restApi.urlWithoutParams === "/availability" ||
      (this.restApi.isCompany() &&
        this.restApi.urlWithoutParams ===
          "/" + this.urlArray[1] + "/availability")
    ) {
      this.availability = true;
    }
    if (
      this.restApi.urlWithoutParams === "/" ||
      (this.restApi.isCompany() &&
        this.restApi.urlWithoutParams === "/" + this.urlArray[1])
    ) {
      this.home = true;
    }

    //  },2000);

    this.route.queryParams.subscribe((params) => {
      let flightSearchDates = undefined;
      if (JSON.parse(localStorage.getItem("flightSearchDates"))) {
        flightSearchDates = JSON.parse(
          localStorage.getItem("flightSearchDates")
        );
        if (
          moment(flightSearchDates.departureDate).isBefore(moment(), "day") &&
          flightSearchDates.returnDate
        ) {
          localStorage.setItem(
            "flightSearchDates",
            JSON.stringify({
              departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
              returnDate: moment().add(17, "days").format("YYYY-MM-DD"),
            })
          );
          flightSearchDates = {
            departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
            returnDate: moment().add(17, "days").format("YYYY-MM-DD"),
          };
        } else if (
          moment(flightSearchDates.departureDate).isBefore(moment(), "day")
        ) {
          localStorage.setItem(
            "flightSearchDates",
            JSON.stringify({
              departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
            })
          );
          flightSearchDates = {
            departureDate: moment().add(14, "days").format("YYYY-MM-DD"),
          };
        }
      }

      this.queryParams = params;
      this.vars.classType.forEach((element) => {
        if (this.queryParams.cabinClass === element.value) {
          this.selectedClass = element;
        }
      });
      if (params.trip === "ONEWAY") {
        this.selectedTrip = this.oneWayLocalized;
        if (
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid()
        ) {
          if (
            !this.departingRange ||
            moment(this.departingRange).isBefore(moment(), "day")
          ) {
            this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
            this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
          }
        } else if (
          params.departureDate !== undefined &&
          params.paxes !== undefined
        ) {
          this.departingRange = moment(params.departureDate).format(
            "YYYY-MM-DD"
          );
          this.returningRange = moment(params.departureDate)
            .add(3, "days")
            .format("YYYY-MM-DD");
        } else {
          if (
            params.departureDate === undefined &&
            this.departingRange === ""
          ) {
            if (flightSearchDates) {
              this.departingRange = flightSearchDates.departureDate;
            } else {
              this.departingRange = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
              this.returningRange = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
            }
          } else if (!this.departingRange) {
            this.departingRange = moment(params.departureDate).format(
              "YYYY-MM-DD"
            );
            this.returningRange = moment(params.departureDate)
              .add(3, "days")
              .format("YYYY-MM-DD");
          }
        }
        //   this.callOneWayDatePicker();
      } else {
        if (
          params.returnDate === "null" ||
          params.departureDate === "null" ||
          moment(params.departureDate).isBefore(moment(), "day") ||
          !moment(params.departureDate).isValid() ||
          !moment(params.returnDate).isValid() ||
          moment
            .utc(params.returnDate)
            .diff(moment.utc(params.departureDate), "days") < 0
        ) {
          if (
            !this.departingRange ||
            moment(this.departingRange).isBefore(moment(), "day")
          ) {
            this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
          }
          if (
            !this.returningRange ||
            moment(this.returningRange).isBefore(moment(), "day")
          ) {
            this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
          }
        } else if (
          params.departureDate !== undefined &&
          params.returnDate !== undefined &&
          params.paxes !== undefined
        ) {
          this.returningRange = moment(params.returnDate).format("YYYY-MM-DD");

          this.departingRange = moment(params.departureDate).format(
            "YYYY-MM-DD"
          );
          //    this.router.navigate([urlWithoutParams], { queryParams: { searchQuery: this.queryParams.searchQuery, checkIn:  this.CheckinRange, checkOut: this.CheckoutRange, paxes: this.queryParams.paxes  },  });
        } else {
          if (
            params.departureDate === undefined &&
            this.departingRange === ""
          ) {
            if (flightSearchDates) {
              this.departingRange = flightSearchDates.departureDate;
            } else {
              this.departingRange = moment()
                .add(14, "days")
                .format("YYYY-MM-DD");
            }
            //      this.router.navigate([urlWithoutParams], { queryParams: { searchQuery: this.queryParams.searchQuery, checkIn:  this.CheckinRange, checkOut: params.checkOut, paxes: this.queryParams.paxes  },  });
          } else if (!this.departingRange) {
            this.departingRange = moment(params.departureDate).format(
              "YYYY-MM-DD"
            );
          }

          if (params.returnDate === undefined && !this.returningRange) {
            if (flightSearchDates) {
              this.returningRange = flightSearchDates.returnDate;
            } else {
              this.returningRange = moment()
                .add(17, "days")
                .format("YYYY-MM-DD");
            }
            //   this.router.navigate([urlWithoutParams], { queryParams: { searchQuery: this.queryParams.searchQuery, checkIn:  params.checkIn, checkOut: this.CheckoutRange, paxes: this.queryParams.paxes  },  });
          } else if (!this.returningRange) {
            this.returningRange = moment(params.returnDate).format(
              "YYYY-MM-DD"
            );
          }
        }

        const days = moment
          .utc(this.returningRange)
          .diff(moment.utc(this.departingRange), "days");
        if (days < 0) {
          this.departingRange = moment().add(14, "days").format("YYYY-MM-DD");
          this.returningRange = moment().add(17, "days").format("YYYY-MM-DD");
        }
        // this.callRoundTripDatePicker();
      }

      if (this.queryParams.paxes) {
        const childrenArr = this.queryParams.paxes.split(",");
        /*  for (let i = 1 ; i < childrenArr.length; i++ ) {


            this.childrenList.push({childList: childrenArr[i]} );
            this.childrenCount++;

            this.decreaseChildrenDisabled = false;
            this.increaseCheck();

            //    this.addButtonClick();

          }*/

        this.adultCount = Number(childrenArr[0]);
        if (childrenArr[1]) {
          this.childrenCount = Number(childrenArr[1]);
          this.paxCount = Number(childrenArr[1]) + Number(childrenArr[0]);
        } else {
          this.paxCount = Number(childrenArr[0]);
        }
      }

      //------------------ added to get paxes in dashboard from localStorage
      let urlArray = this.router.url.split("/");
      if (urlArray[urlArray.length - 1] === this.Auth.companyInfo.id) {
        if (!this.queryParams.paxes && localStorage.getItem("flightPaxes")) {
          const childrenArr = localStorage.getItem("flightPaxes").split(",");
          for (let i = 1; i < childrenArr.length; i++) {
            this.childrenList.push({ childList: childrenArr[i] });
            this.childrenCount++;

            this.decreaseChildrenDisabled = false;
            this.increaseCheck();

            //    this.addButtonClick();
          }

          this.adultCount = Number(childrenArr[0]);
          if (childrenArr[1]) {
            this.childrenCount = Number(childrenArr[1]);
            this.paxCount = Number(childrenArr[1]) + Number(childrenArr[0]);
          } else {
            this.paxCount = Number(childrenArr[0]);
          }
        }

        if (!this.queryParams.cabinClass) {
          let selectedClass = localStorage.getItem("selectedClass");
          this.vars.classType.forEach((element) => {
            if (selectedClass === element.value) {
              this.selectedClass = element;
            }
          });
        }
        if (!this.queryParams.trip) {
          let trip = localStorage.getItem("selectedTripParam");
          if (trip === "ONEWAY") {
            this.selectedTrip = this.oneWayLocalized;
            this.callOneWayDatePicker();
          } else if (trip === "ROUNDTRIP") {
            this.selectedTrip = this.roundTripLocalized;
          } else if (trip === "MULTICITY") {
            this.selectedTrip = this.multiCityLocalized;
          }
        }
      }
      //-------------------------------------------

      if (this.adultCount === 1) {
        this.decreaseAdultDisabled = true;
      }

      this.departingRangeFormatted = this.datePipe.transform(
        this.departingRange,
        "EE, MMM dd"
      );
      this.returningRangeFormatted = this.datePipe.transform(
        this.returningRange,
        "EE, MMM dd"
      );
    });

    /*  this.route.queryParams.subscribe(params => {
        this.departingRange = moment().add(14, 'days').format('YYYY-MM-DD');
        this.returningRange  = moment().add(17, 'days').format('YYYY-MM-DD');

        this.callRoundTripDatePicker();


          if (this.adultCount === 1) {
          this.decreaseAdultDisabled = true;


        }
      });*/

    this.SearchTextQueryReturn.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.autoCompleteList();
    });
    this.SearchTextQuery.pipe(
      // get value
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.Query = value;
      this.readyToSearch = false;
      this.searchQuery = undefined;
      this.autoCompleteList();
    });

    for (let i = 2; i < 18; i++) {
      this.ages[i] = i.toString();
    }

    this.restApi.flightHistoryArray = JSON.parse(
      localStorage.getItem("flight_history_storage")
    );
    if (this.restApi.flightHistoryArray && this.restApi.flightHistoryArray[0]) {
      setTimeout(() => {
        this.restApi.captionReturn =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.arrivalCaptions[0];
        this.searchQueryReturn =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.arrivalCaptions[0];
        this.captionReturn =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.arrivalCaptions[0];
        this.arrivalCode =
          this.restApi.flightHistoryArray[0].airQueries[0].arrivalSearchCode;
        this.restApi.captionDepart =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.departureCaptions[0];
        this.searchQueryDepart =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.departureCaptions[0];
        this.captionDepart =
          this.restApi.flightHistoryArray[0].lookupSearchCaptions.departureCaptions[0];
        this.departureCode =
          this.restApi.flightHistoryArray[0].airQueries[0].departureSearchCode;
        this.departingRange = moment(
          this.restApi.flightHistoryArray[0].airQueries[0].departureDate
        ).format("YYYY-MM-DD");
        this.returningRange = moment(
          this.restApi.flightHistoryArray[0].airQueries[0].returnDate
        ).format("YYYY-MM-DD");
        this.departingRangeFormatted = this.datePipe.transform(
          this.departingRange,
          "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
          this.returningRange,
          "EE, MMM dd"
        );
      }, 500);
    }

    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      this.users.push(this.guest);
    }
    this.usersAutoComplete();
  }

  callRoundTripDatePicker() {
    if (this.pickerOneWay) {
      this.pickerOneWay.destroy();
    }
    if (
      !this.returningRange ||
      moment(this.returningRange).isBefore(moment(this.departingRange), "day")
    ) {
      this.returningRange = moment(this.departingRange)
        .add(0, "days")
        .format("YYYY-MM-DD");
      this.returningRangeFormatted = this.datePipe.transform(
        this.returningRange,
        "EE, MMM dd"
      );
    }
    if (!this.picker) {
      setTimeout(() => {
        this.picker = new Lightpick({
          disabledDatesInRange: false,
          firstDay: 0,
          singleMode: false,
          repick: true,
          minDays: 1,
          minDate: moment(),
          maxDate: moment().add(11, "months"),
          startDate: this.departingRange,
          endDate: this.returningRange,
          field: document.getElementById("dateRangeDepart"),
          singleDate: false,
          numberOfColumns: 1,
          numberOfMonths: 12,
          inline: true,
          dropdowns: {
            years: false,
            months: false,
          },
          lang: this.restApi.corporateLocale,
          onSelect: () => {
            this.departingRangeFormatted = this.datePipe.transform(
              this.picker.getStartDate(),
              "EE, MMM dd"
            );
            if (this.picker.getEndDate() == null) {
              this.picker.setDateRange(
                moment(this.picker.getStartDate()),
                moment(this.picker.getStartDate()).add(1, "day")
              );
            }
            this.returningRangeFormatted = this.datePipe.transform(
              this.picker.getEndDate(),
              "EE, MMM dd"
            );
            this.returningRange = moment(
              this.picker.getEndDate().format("YYYY-MM-DD")
            );
            this.departingRange = moment(
              this.picker.getStartDate().format("YYYY-MM-DD")
            );
          },
        });

        this.returningRange = moment(
          this.picker.getEndDate().format("YYYY-MM-DD")
        );
        this.departingRange = moment(
          this.picker.getStartDate().format("YYYY-MM-DD")
        );
        this.departingRangeFormatted = this.datePipe.transform(
          this.picker.getStartDate(),
          "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
          this.picker.getEndDate(),
          "EE, MMM dd"
        );
      }, 200);
    }
  }

  callOneWayDatePicker() {
    if (this.picker) {
      this.picker.destroy();
    }
    if (!this.pickerOneWay) {
      setTimeout(() => {
        this.pickerOneWay = new Lightpick({
          disabledDatesInRange: false,
          firstDay: 7,
          singleMode: true,
          repick: true,
          minDays: 0,
          minDate: moment(),
          startDate: this.departingRange,
          endDate: this.departingRange,
          maxDate: moment().add(11, "months"),
          field: document.getElementById("oneWay"),
          maxDays: 31,
          inline: true,
          numberOfColumns: 1,
          numberOfMonths: 12,
          dropdowns: {
            years: false,
            months: false,
          },
          lang: this.restApi.corporateLocale,
          onSelect: () => {
            this.departingRangeFormatted = this.datePipe.transform(
              this.pickerOneWay.getStartDate(),
              "EE, MMM dd"
            );
            this.departingRange = moment(
              this.pickerOneWay.getStartDate().format("YYYY-MM-DD")
            );
            this.returningRangeFormatted = this.datePipe.transform(
              this.pickerOneWay.getStartDate(),
              "EE, MMM dd"
            );
            this.returningRange = moment(
              this.pickerOneWay.getStartDate().format("YYYY-MM-DD")
            );
          },
        });
        this.departingRange = moment(
          this.pickerOneWay.getStartDate().format("YYYY-MM-DD")
        );
        this.departingRangeFormatted = this.datePipe.transform(
          this.pickerOneWay.getStartDate(),
          "EE, MMM dd"
        );
        this.returningRangeFormatted = this.datePipe.transform(
          this.pickerOneWay.getStartDate(),
          "EE, MMM dd"
        );
        this.returningRange = moment(
          this.pickerOneWay.getStartDate().format("YYYY-MM-DD")
        );
      }, 200);
    }
  }
  setSearchTextReturn(entity, dist) {
    this.Query = entity.caption;
    this.restApi.captionReturn = entity.caption;
    this.captionReturn = entity.caption;
    this.arrivalCode = entity.code;
    this.activePopUp = false;
    this.destination = dist;
    this.captionbyClickReturn = entity.caption;
    this.setSearchTextReturnActive = true;
    this.restApi.enableBodyScroll();
    this.textFillReturn = true;
    this.hideSearchPopUpSearchReturn();
    this.autocompleteOn = true;
    setTimeout(() => {
      this.autocompletelist = [];
      localStorage.setItem("lastSearchReturn", JSON.stringify(entity));
    }, 500);
  }
  showSearchPopUp(item) {
    if (item === "dates") {
      this.activePopUp = true;
      if (this.dateActive === true) {
        this.dateActive = false;
        if (
          this.selectedTrip === "Round-trip" ||
          this.selectedTrip === this.roundTripLocalized
        ) {
          if (this.pickerOneWay) {
            this.pickerOneWay.destroy();
          }
          this.callRoundTripDatePicker();
        } else if (
          this.selectedTrip === "One-way" ||
          this.selectedTrip === this.oneWayLocalized
        ) {
          if (this.picker) {
            this.picker.destroy();
          }
          this.callOneWayDatePicker();
        }
      }
      /*  setTimeout(() => {
        let startDate: HTMLElement = document.querySelector('.is-start-date') as HTMLElement;
        let mobile_dates: HTMLElement = document.querySelector('.mobile_dates') as HTMLElement;
        mobile_dates.scrollTo(0, startDate.offsetTop - 300);
      }, 200);*/
      this.showSearchPopPaxes = false;
      this.showSearchPopUpautocompleteDepart = false;
      this.showSearchPopUpautocompleteReturn = false;
      this.showSearchPopDates = true;
    } else if (item === "captionReturn") {
      this.showSearchPopUpautocompleteReturn = true;
      this.showSearchPopUpautocompleteDepart = false;
      this.showSearchPopDates = false;
      this.showSearchPopPaxes = false;
      setTimeout(() => {
        const returnInputField1 = document.getElementById(
          "returnInputField1"
        ) as HTMLInputElement;
        const returnInputField2 = document.getElementById(
          "returnInputField2"
        ) as HTMLInputElement;
        if (returnInputField1) {
          returnInputField1.select();
        }
        if (returnInputField2) {
          returnInputField2.select();
        }
      }, 200);
    } else if (item === "paxes") {
      this.activePopUp = true;
      this.showSearchPopPaxes = true;
      this.showSearchPopDates = false;
      this.showSearchPopUpautocompleteDepart = false;
      this.showSearchPopUpautocompleteReturn = false;
    }
    this.searchValid = false;
    this.restApi.disableBodyScroll();
  }
  departReturnSwitch() {
    [this.restApi.captionDepart, this.restApi.captionReturn] = [
      this.restApi.captionReturn,
      this.restApi.captionDepart,
    ];
    [this.captionDepart, this.captionReturn] = [
      this.captionReturn,
      this.captionDepart,
    ];
    [this.departureCode, this.arrivalCode] = [
      this.arrivalCode,
      this.departureCode,
    ];
    const tempEntityReturn = JSON.parse(
      localStorage.getItem("lastSearchReturn")
    );
    const tempEntityDepart = JSON.parse(
      localStorage.getItem("lastSearchDepart")
    );
    localStorage.setItem("lastSearchDepart", JSON.stringify(tempEntityReturn));
    localStorage.setItem("lastSearchReturn", JSON.stringify(tempEntityDepart));
  }
  setSearchTextDepart(entity, dist) {
    this.Query = entity.caption;
    this.restApi.captionDepart = entity.caption;
    this.captionDepart = entity.caption;
    this.destination = dist;
    this.departureCode = entity.code;

    this.activePopUp = false;
    this.captionbyClickDepart = entity.caption;
    this.setSearchTextDepartActive = true;
    this.restApi.enableBodyScroll();
    this.textFillDepart = true;
    this.hideSearchPopUpSearchDepart();
    // this.showSearchPopUp('captionReturn');
    this.autocompleteOn = true;

    setTimeout(() => {
      this.autocompletelist = [];
      localStorage.setItem("lastSearchDepart", JSON.stringify(entity));
    }, 500);
  }
  onSubmit() {
    this.restApi.alertsOverRoute = [];
    this.submitted = true;
    if (this.captionDepart === "") {
      // this.showSearchPopUpautocompleteDepart = true;
      this.searchValid = true;
      return;
    }
    if (this.captionReturn === "") {
      //   this.showSearchPopUpautocompleteReturn = true;
      this.searchValid = true;
      return;
    }
    this.Childrenpax = "";
    if (this.childrenList.length) {
      this.Childrenpax = "," + this.childrenList.length;
    }
    localStorage.setItem("flightPaxes", this.adultCount + this.Childrenpax);
    localStorage.setItem("selectedClass", this.selectedClass.value);
    /* for (let i = 0; i < this.childrenList.length; i++) {
       if (this.childrenList.length > 0 && this.childrenList[i].childList === '') {
         this.validChild = true;
         return false;
       } else {
         if (this.childrenList[i].childList !== 'undefined' && this.childrenList[i].childList !== undefined) {
           this.Childrenpax  +=  ',' + this.childrenList[i].childList  ;
           this.validChild = false;
         } else {
           this.validChild = true;
           return false;
         }
       }
     }*/
    let searchType = "";

    if (!this.restApi.isCompany()) {
      searchType = "flights/availability";
    } else {
      searchType =
        "/" + this.Auth.companyInfo.id + "/flights/availability";
    }
    this.restApi.isCollapsedFlight = true;

    let localStorageFlightItem = {
      roundTrip: false,
      cabinClass: this.selectedClass.value,
      airQueries: [
        {
          departureSearchCode: this.departureCode,
          departureSearchType: "AIRPORT",
          arrivalSearchCode: this.arrivalCode,
          arrivalSearchType: "AIRPORT",
        },
      ],
      lookupSearchCaptions: {
        departureCaptions: [this.captionDepart],
        arrivalCaptions: [this.captionReturn],
      },
      adultCount: this.adultCount,
      childCount: 0,
      infantCount: 0,
    };

    if (
      this.selectedTrip === "Round-trip" ||
      this.selectedTrip === this.roundTripLocalized
    ) {
      this.selectedTripParam = "ROUNDTRIP";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      this.departureDate = moment(this.departingRange).format("YYYY-MM-DD");
      this.returnDate = moment(this.returningRange).format("YYYY-MM-DD");
      localStorageFlightItem.roundTrip = true;
      localStorageFlightItem.airQueries[0]["returnDate"] = this.returnDate;
      localStorageFlightItem.airQueries[0]["departureDate"] =
        this.departureDate;
      localStorage.setItem(
        "flightSearchDates",
        JSON.stringify({
          departureDate: this.departureDate,
          returnDate: this.returnDate,
        })
      );

      let arr = JSON.parse(localStorage.getItem("flight_history_storage"));
      if (
        arr &&
        JSON.stringify(localStorageFlightItem) === JSON.stringify(arr[0]) &&
        this.router.url.includes("availability")
      ) {
        if (this.travelerId === this.selectedUser.id) {
          return false;
        }
      }
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          cabinClass: this.selectedClass.value,
          departureDate: this.departureDate,
          departureCode: this.departureCode,
          arrivalCode: this.arrivalCode,
          paxes: this.adultCount + this.Childrenpax,
          returnDate: this.returnDate,
          travelerId: this.selectedUser.id,
        },
      });
    } else if (
      this.selectedTrip === "One-way" ||
      this.selectedTrip === this.oneWayLocalized
    ) {
      this.selectedTripParam = "ONEWAY";
      localStorage.setItem("selectedTripParam", this.selectedTripParam);
      this.departureDate = moment(this.departingRange).format("YYYY-MM-DD");
      localStorageFlightItem.airQueries[0]["departureDate"] =
        this.departureDate;
      localStorage.setItem(
        "flightSearchDates",
        JSON.stringify({
          departureDate: this.departureDate,
          returnDate: moment(this.departureDate)
            .add(3, "days")
            .format("YYYY-MM-DD"),
        })
      );

      let arr = JSON.parse(localStorage.getItem("flight_history_storage"));
      if (
        arr &&
        JSON.stringify(localStorageFlightItem) === JSON.stringify(arr[0]) &&
        this.router.url.includes("availability")
      ) {
        if (this.travelerId === this.selectedUser.id) {
          return false;
        }
      }
      this.router.navigate([searchType], {
        queryParams: {
          trip: this.selectedTripParam,
          cabinClass: this.selectedClass.value,
          departureDate: this.departureDate,
          departureCode: this.departureCode,
          arrivalCode: this.arrivalCode,
          paxes: this.adultCount + this.Childrenpax,
          travelerId: this.selectedUser.id,
        },
      });
    }

    if (!this.restApi.flightHistoryArray) {
      const historyArr = [];
      historyArr.unshift(localStorageFlightItem);
      localStorage.setItem(
        "flight_history_storage",
        JSON.stringify(historyArr)
      );
    } else {
      let historyArr = this.restApi.flightHistoryArray;
      /*  historyArr = historyArr.filter(cls =>{
          return   !this.searchQuery.includes(cls.searchQuery)
        } );*/

      if (historyArr.length === 5) {
        historyArr.splice(-1, 1);
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      } else {
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      }
    }

    this.router.onSameUrlNavigation = "reload";
  }

  decreaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;
  }
  increaseCheck() {
    this.paxCount = this.childrenCount + this.adultCount;

    if (this.childrenCount + this.adultCount === 9) {
      this.increasDisabled = true;
      return true;
    } else {
      this.increasDisabled = false;
    }
  }

  decreaseAdult() {
    this.adultCount--;
    this.paxCount--;
    this.increasDisabled = false;
    this.decreaseCheck();
    if (this.adultCount === 1) {
      this.decreaseAdultDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }

  increaseAdult() {
    this.adultCount++;
    this.paxCount++;
    this.decreaseAdultDisabled = false;
    this.increaseCheck();
  }

  decreaseChildren() {
    //    this.removeButtonClick();
    this.childrenCount--;
    this.paxCount--;
    this.increasDisabled = false;

    this.childrenList.pop();
    this.decreaseCheck();
    if (this.childrenCount === 0) {
      this.decreaseChildrenDisabled = true;
      return true;
    } else {
      this.decreaseDisabled = false;
    }
  }
  increaseChildren() {
    this.childrenCount++;
    this.paxCount++;
    this.decreaseChildrenDisabled = false;
    this.increaseCheck();
    this.childrenList.push({ childList: "" });
    // this.addButtonClick();
  }

  autoCompleteList() {
    if (this.Query && this.Query.length > 2) {
      this.restApi
        .autoCompleteAirports(
          this.Query,
          sessionStorage.getItem("session-token")
        )
        .subscribe((success) => {
          this.data = success;
          this.autocompletelist = this.data.data;
        });
    }
  }

  autocompleteOnFocus(event) {
    event.target.select();

    if (!sessionStorage.getItem("session-token")) {
      this.restApi.sessionToken = uuid();

      sessionStorage.setItem("session-token", this.restApi.sessionToken);
    }
  }

  onClickBody(event) {
    if (!this.eref.nativeElement.contains(event.target)) {
      this.validChild = false;
      this.onclickActive = true;
    }
  }

  keepOnMenu() {
    setTimeout(() => {
      if (this.onclickActive === true) {
        //  this.validChild = true;
        this.onclickActive = false;
      }
    }, 10);
  }

  hideSearchPopUp() {
    this.validChild = false;
    this.activePopUp = false;

    this.showSearchPopUpActive = false;
    this.restApi.enableBodyScroll();

    if (this.availability && this.showSearchPopDates) {
      this.onSubmit();
    }
  }

  hideSearchPopUpSearchReturn() {
    this.Query = this.beforeChange;
    if (this.Query === null || this.Query === undefined || this.Query === "") {
      this.captionReturn = this.restApi.captionReturn;
    }
    this.showSearchPopUpautocompleteReturn = false;
    this.autocompleteOn = true;
    this.validChild = false;
    this.activePopUp = false;
    this.restApi.enableBodyScroll();
  }
  hideSearchPopUpSearchDepart() {
    this.Query = this.beforeChange;
    if (this.Query === null || this.Query === undefined || this.Query === "") {
      this.captionDepart = this.restApi.captionDepart;
    }
    this.showSearchPopUpautocompleteDepart = false;
    this.autocompleteOn = true;
    this.validChild = false;
    this.activePopUp = false;
    this.restApi.enableBodyScroll();
  }

  autocompleteOnClickDepart(event) {
    if (this.autocompleteOn) {
      this.beforeChange = this.Query;

      this.showSearchPopDates = false;
      this.showSearchPopPaxes = false;

      this.showSearchPopUpautocompleteDepart = true;

      if (
        (this.Query && this.Query.length > 0) ||
        (this.restApi.captionDepart && this.restApi.captionDepart.length > 0) ||
        (this.captionDepart && this.captionDepart.length > 0)
      )
        this.textFillDepart = true;

      this.restApi.sessionToken = uuid();
      sessionStorage.setItem("session-token", this.restApi.sessionToken);

      if (this.autocompleteOn) {
        this.beforeChange = this.Query;
        this.showSearchPopDates = false;
        this.showSearchPopPaxes = false;

        this.showSearchPopUpautocompleteDepart = true;

        if (
          (this.Query && this.Query.length > 0) ||
          (this.restApi.captionDepart &&
            this.restApi.captionDepart.length > 0) ||
          (this.captionDepart && this.captionDepart.length > 0)
        )
          this.textFillDepart = true;

        this.restApi.sessionToken = uuid();
        sessionStorage.setItem("session-token", this.restApi.sessionToken);

        this.showSearchPopUpautocompleteDepart = true;
        this.autocompleteOn = false;
      }

      this.restApi.disableBodyScroll();
      setTimeout(() => {
        this.restApi.scrollTop();

        event.target.select();
      }, 200);
    }
  }
  autocompleteOnClickReturn(event) {
    if (this.autocompleteOn) {
      this.beforeChange = this.Query;
      this.showSearchPopDates = false;
      this.showSearchPopPaxes = false;

      this.showSearchPopUpautocompleteReturn = true;

      if (
        (this.Query && this.Query.length > 0) ||
        (this.restApi.captionReturn && this.restApi.captionReturn.length > 0) ||
        (this.captionReturn && this.captionReturn.length > 0)
      )
        this.textFillReturn = true;

      this.restApi.sessionToken = uuid();
      sessionStorage.setItem("session-token", this.restApi.sessionToken);

      if (this.autocompleteOn) {
        this.beforeChange = this.Query;
        this.showSearchPopDates = false;
        this.showSearchPopPaxes = false;

        this.showSearchPopUpautocompleteReturn = true;

        if (
          (this.Query && this.Query.length > 0) ||
          (this.restApi.captionReturn &&
            this.restApi.captionReturn.length > 0) ||
          (this.captionReturn && this.captionReturn.length > 0)
        )
          this.textFillReturn = true;

        this.restApi.sessionToken = uuid();
        sessionStorage.setItem("session-token", this.restApi.sessionToken);

        this.showSearchPopUpautocompleteReturn = true;
        this.autocompleteOn = false;
      }

      this.restApi.disableBodyScroll();
      setTimeout(() => {
        this.restApi.scrollTop();

        event.target.select();
      }, 200);
    }
  }
  consumerSearchTabs() {
    return (
      (!this.restApi.isCompany() &&
        this.router.url === "/pass/save-now-travel-later/welcome") ||
      (!this.restApi.isCompany() && this.restApi.urlWithoutParams === "/") ||
      (!this.restApi.isCompany() &&
        this.router.url === "/pass/free-trial-subscription/welcome")
    );
  }

  /********* guest changes *********/
  usersAutoComplete() {
    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      this.restApi.getCompanyUsersList().subscribe(
        (dataList) => {
          this.dataUsers = dataList;
          if (this.dataUsers.errorCode === 0) {
            this.dataUsers = this.dataUsers.data;
            this.dataUsers.forEach((element) => {
              if (!element.inactive) {
                if (element.teamName) {
                  this.users.push(
                    new User(
                      element.description,
                      element.firstName,
                      element.lastName,
                      element.id,
                      element.email,
                      element.inactive,
                      element.profileImageBackgroundHexColor,
                      element.profileImage,
                      element.teamName
                    )
                  );
                } else {
                  this.users.push(
                    new User(
                      element.description,
                      element.firstName,
                      element.lastName,
                      element.id,
                      element.email,
                      element.inactive,
                      element.profileImageBackgroundHexColor,
                      element.profileImage
                    )
                  );
                }
              }
            });
            this.filteredUsers = this.userControl.valueChanges.pipe(
              startWith<string | User[]>(""),
              map((value) =>
                typeof value === "string" ? value : this.lastFilter
              ),
              map((filter) => this.filter(filter))
            );
          } else {
            this.restApi.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.restApi.messages(environment.messages.error, "error");
        }
      );
    } else {
      if (this.Auth.userInfo.teamName) {
        this.users.push(
          new User(
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
            this.Auth.userInfo.name,
            this.Auth.userInfo.lastName,
            this.Auth.userInfo.id,
            this.Auth.userInfo.email,
            this.Auth.userInfo.inactive,
            this.Auth.userInfo.profileImageBackgroundHexColor,
            this.Auth.userInfo.profileImage,
            this.Auth.userInfo.teamName
          )
        );
      } else {
        this.users.push(
          new User(
            this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
            this.Auth.userInfo.name,
            this.Auth.userInfo.lastName,
            this.Auth.userInfo.id,
            this.Auth.userInfo.email,
            this.Auth.userInfo.inactive,
            this.Auth.userInfo.profileImageBackgroundHexColor,
            this.Auth.userInfo.profileImage
          )
        );
      }
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith<string | User[]>(""),
        map((value) => (typeof value === "string" ? value : this.lastFilter)),
        map((filter) => this.filter(filter))
      );
    }
  }
  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter((option) => {
        return option.description.toLowerCase().includes(filter.toLowerCase());
      });
    } else {
      return this.users.slice();
    }
  }
  displayFn(user: User): string {
    return user && user.description ? user.description : "";
  }
  reset() {
    setTimeout(() => {
      if (typeof this.userControl.value !== "object") {
        this.userInput.nativeElement.value = "";
        this.userControl.setValue(null);
        this.lastFilter = "";
        this.selectedUser = null;
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith<string | User[]>(""),
          map((value) => (typeof value === "string" ? value : this.lastFilter)),
          map((filter) => this.filter(filter))
        );
      }
    }, 400);
  }

  optionClicked(event: Event, user: User) {
    this.selectedUser = user;
    this.showUsersAutoCompletePopUp = false;
    this.restApi.enableBodyScroll();
  }
  showUsersPopUp() {
    this.showUsersAutoCompletePopUp = true;
    this.restApi.disableBodyScroll();
    if (this.selectedUser) {
      this.userControl.setValue(this.selectedUser);
    }
    setTimeout(() => {
      const input = document.getElementById("userInput") as HTMLInputElement;
      if (input) {
        input.select();
        input.focus();
      }
    }, 200);
    return;
  }
}
