import {Component, Injectable, OnInit,} from '@angular/core';
import {DataService} from '../data.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class BottomSheetComponent implements OnInit {

  constructor(public Service: DataService) {
  }

  ngOnInit() {
  }
}
