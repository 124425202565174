<div class="container pt-4 pt-lg-5 ">
  <div class="mx-lg-5 normal-letter-spacing" #screen>
    <div class="d-flex max-width-title justify-content-end">
      <div class="d-flex">
        <p class="pt-2" data-html2canvas-ignore="true"
          [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}">
          <i class="icon-schedule font-18 pb-1 pointer" (click)="viewReport = 'table'"
            [ngClass]="{'active-icon': viewReport === 'table', 'inactive-icon inactive-table-icon': viewReport === 'chart'}"></i>
        </p>
        <p class="pt-2" (click)="viewReport = 'chart'" data-html2canvas-ignore="true">
          <i class="icon-bar-chart font-18 pb-1 pointer"
            [ngClass]="{'active-icon': viewReport === 'chart', 'inactive-icon inactive-chart-icon': viewReport === 'table'}"></i>
        </p>
        <!--  <p class="pt-2" (click)="viewReport = 'chart'">
          <i class="icon-workspace-approvals font-18 pb-1 pointer"
             [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" (click)="downloadPDF()"
             *ngIf="viewReport === 'chart'"></i>
        </p>-->
      </div>
      <!--   <i class="font-18 pointer placeholder-text icon-download" data-html2canvas-ignore="true"
           [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
           (click)="downloadPDF()" *ngIf="dataService.desktopView && viewReport === 'chart'"></i>
        <i class="font-18 pointer placeholder-text icon-download" data-html2canvas-ignore="true"
           [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
           (click)="downloadPDFTables()" *ngIf="dataService.desktopView && viewReport === 'table'"></i>-->
    </div>
    <div *ngIf="!this.reports || !this.reports === undefined || this.reports === null || this.dataService.loadingFlag"
      class="loading_icon_caption text-center mt-5"></div>
    <div *ngIf="!this.reports || !this.reports === undefined || this.reports === null || this.dataService.loadingFlag"
      class="loading-message text-center" i18n="@@loading"> Loading </div>

    <div class="pt-5 pb-0 pb-5">

      <div *ngIf="reports && !this.dataService.loadingFlag">
        <div>
          <div class="row" [ngClass]="{'px-3': dataService.mobileView}" *ngIf="viewReport === 'chart'">
            <div class="col-lg-6 pb-2" [ngClass]="{'border-bottom pb-5': dataService.mobileView}">
              <div style="display: block;" class="pb-5">
                <canvas baseChart id="account-overview" [data]="pieChartData" [labels]="pieChartLabels"
                  [chartType]="pieChartType" [colors]="chartColors" [options]="pieChartOptions"
                  [plugins]="reportChartPlugin" [legend]="pieChartLegend">
                </canvas>
              </div>
              <p class="bold-600 font-16 text-center gray-primaray-text" i18n="@@accountOverview"
                id="account-overview-text">Account Overview</p>
            </div>
            <div class="col-lg-6 pb-2" [ngClass]="{'pt-5 mt-5': dataService.mobileView}">
              <div style="display: block;" class="pb-5"
                *ngIf="this.reports.approvedApprovals || this.reports.disapprovedApprovals || this.reports.pendingApprovals">
                <canvas baseChart id="approvals" [data]="approvalsPieChartData" [labels]="approvalsPieChartLabels"
                  [colors]="chartColors" [chartType]="approvalsPieChartType" [options]="approvalsPieChartOptions"
                  [plugins]="reportChartPlugin" [legend]="approvalsPieChartLegend">
                </canvas>
              </div>
              <div class="d-flex py-5"
                *ngIf="!this.reports.approvedApprovals && !this.reports.disapprovedApprovals && !this.reports.pendingApprovals">
                <img class="mx-auto no-data-max-height" src="assets/img/pieChartNoData.jpg" alt="no approvals data" />
              </div>
              <p class="bold-600 font-16 text-center gray-primaray-text" i18n="@@menuItemApprovals">Approvals</p>
            </div>

            <div class="col-lg-6 pb-2 pt-5 mt-5 border-top">
              <div style="display: block;" class="pt-5 pb-4"
                *ngIf="this.reports.totalNumberOfPolicySavings || this.reports.totalNumberOfPolicyViolations">
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                  [colors]="barChartColors" [plugins]="reportChartPlugin" [legend]="barChartLegend"
                  [chartType]="barChartType">
                </canvas>
              </div>
              <div class="d-flex py-5"
                *ngIf="!this.reports.totalNumberOfPolicySavings && !this.reports.totalNumberOfPolicyViolations">
                <img class="mx-auto no-data-max-height" src="assets/img/barChartNoData.jpg"
                  alt="no policy limit data" />
              </div>
              <p class="bold-600 font-16 text-center gray-primaray-text" i18n="@@policyOverview">Policy Overview</p>
            </div>
            <div class="col-lg-6 pb-2 pt-5 mt-5 border-top">
              <div style="display: block;" class="pt-5 pb-4" *ngIf="reports.topDestinations.length">
                <canvas baseChart [datasets]="topDestBarChartData" [colors]="barChartColors"
                  [options]="topDestBarChartOptions" [plugins]="reportChartPlugin" [labels]="topDestBarChartLabels"
                  [legend]="topDestBarChartLegend" [chartType]="topDestBarChartType">
                </canvas>
              </div>
              <div class="d-flex py-5" *ngIf="!reports.topDestinations.length">
                <img class="mx-auto no-data-max-height" src="assets/img/barChartNoData.jpg"
                  alt="no top destinations data" />
              </div>
              <p class="bold-600 font-16 text-center gray-primaray-text" i18n="@@topDestinations">Top Destinations</p>
            </div>
            <div class="pb-5 col-lg-6 pb-2 pt-5 mt-5 border-top border-bottom">
              <div style="display: block;" class="pt-5 pb-4" *ngIf="reports.topTravelers.length">
                <canvas baseChart [datasets]="topTravelersBarChartData" [colors]="barChartColors"
                  [options]="topTravelersBarChartOptions" [labels]="topTravelersBarChartLabels"
                  [plugins]="reportChartPlugin" [legend]="topTravelersBarChartLegend"
                  [chartType]="topTravelersBarChartType">
                </canvas>
              </div>
              <div class="d-flex my-5" *ngIf="!reports.topTravelers.length">
                <img class="mx-auto no-data-max-height" src="assets/img/barChartNoData.jpg"
                  alt="no top travelers data" />
              </div>
              <p class="bold-600 font-16 text-center gray-primaray-text" i18n="@@topTravelers">Top Travelers</p>
            </div>
            <div class="col-lg-6 pb-2 pt-5 mt-5 border-top border-bottom" *ngIf="dataService.desktopView">

            </div>

          </div>
          <div class="row pt-5" *ngIf="viewReport === 'chart'">
            <div class="table-section-container rounded p-4 mx-3 mb-5"
              [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
              <div class="d-flex">
                <img class="mb-3 pb-3" src="assets/img/hotel_bookings.svg" alt="wait">
                <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@bookingsHotels">Hotel bookings</p>
              </div>
              <div class="row mb-5" [ngClass]="{'text-center': dataService.desktopView}">
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@numberOfBookings">Total bookings</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfBookings}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@confirmedBookings">Confirmed</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfConfirmedBookings}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@canceledBookings">Canceled bookings</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfCanceledBookings}}</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>
              <div class="row" [ngClass]="{'text-center hotel-p': dataService.desktopView}">
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                  <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.totalSpentAmount | number: '1.0-0'}}</p>
                </div>
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}"
                  style="width: 60px"></div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@refundAmountCanceled">Total refund</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.totalRefundAmount | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3 min-w210">
                  <p class="mb-2 gray-text" i18n="@@approvalAvgNightlyPriceSmall">Average nightly price</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.avgNightlyPrice | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>

            </div>

            <div class="table-section-container rounded p-4 mx-3 mb-5"
              [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
              <div class="d-flex">
                <img class="mb-3 pb-3" src="assets/img/flight_bookings.svg" alt="wait">
                <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@bookingsFlights">Flight bookings</p>
              </div>
              <ng-container *ngIf="reports.flightsDashboardSummary">
                <div class="row mb-5" [ngClass]="{'text-center flight-p': dataService.desktopView}">
                  <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                    <p class="mb-2 gray-text" i18n="@@numberOfFlights">Total flights</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfItineraries}}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@confirmedFlights">Confirmed</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfConfirmedItineraries}}</p>

                    <p class="mb-2 gray-text mt-5" i18n="@@canceledFlights">Canceled</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfCanceledItineraries}}</p>

                  </div>

                  <div class="col-md-3 border-right border-left">
                    <p class="mb-2 gray-text" i18n="@@upcomingFlights">Upcoming</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfUpcomingItineraries}}</p>

                    <p class="mb-2 gray-text mt-5" i18n="@@completedFlights">Completed</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfCompletedItineraries}}</p>

                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@internationalFlights">International</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfInternationalItineraries}}</p>

                    <p class="mb-2 gray-text mt-5" i18n="@@domesticFlights">Domestic flights</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      {{reports.flightsDashboardSummary.totalNumberOfDomesticItineraries}}</p>

                  </div>
                </div>
                <div class="row" [ngClass]="{'text-center flight-p1': dataService.desktopView}">
                  <div [ngClass]="{'col-auto ': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                    <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      ${{reports.flightsDashboardSummary.totalSpentAmount | number: '1.0-0'}}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@totalRefundAmount">Total refund</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      ${{reports.flightsDashboardSummary.totalRefundAmount | number: '1.0-0'}}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@avgPricePerFlight">Avg flight price</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                      ${{reports.flightsDashboardSummary.avgPricePerItinerary | number: '1.0-0'}}</p>
                  </div>
                  <div class="col-md-3">
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!reports.flightsDashboardSummary">
                <div class="row mb-5" [ngClass]="{'text-center flight-p': dataService.desktopView}">
                  <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                    <p class="mb-2 gray-text" i18n="@@numberOfFlights">Total flights</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@confirmedFlights">Confirmed</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>

                    <p class="mb-2 gray-text mt-5" i18n="@@canceledFlights">Canceled</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>

                  </div>

                  <div class="col-md-3 border-right border-left">
                    <p class="mb-2 gray-text" i18n="@@upcomingFlights">Upcoming</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>

                    <p class="mb-2 gray-text mt-5" i18n="@@completedFlights">Completed</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>

                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@internationalFlights">International</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>

                    <p class="mb-2 gray-text mt-5" i18n="@@domesticFlights">Domestic flights</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>

                  </div>
                </div>
                <div class="row" [ngClass]="{'text-center flight-p1': dataService.desktopView}">
                  <div [ngClass]="{'col-auto ': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                    <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@totalRefundAmount">Total refund</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                  </div>
                  <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@avgPricePerFlight">Avg flight price</p>
                    <p class="mb-2 font-16"
                      [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                  </div>
                  <div class="col-md-3">
                  </div>
                </div>
              </ng-container>
            </div>
            <!--    <div class="col-lg-6 pb-2 pt-5 mt-5">
          <div class="table-header initial-width rounded py-4">
            <p class="font-weight-bold font-16 px-3" i18n="@@bookingsHotels">Bookings (Hotels)</p>
            <div class="px-4 font-14">
              <div class="d-flex">
                <p class="mb-2" i18n="@@numberOfBookings">Number of bookings</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfBookings}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@confirmedBookings">Confirmed bookings</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfConfirmedBookings}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@canceledBookings">Canceled bookings</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfCanceledBookings}}</p>
              </div>

              <div class="d-flex">
                <p class="mb-2" i18n="@@totalSpendAmount">Total spend amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.totalSpentAmount | number: '1.0-0'}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@refundAmountCanceled">Refund amount (canceled)</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.totalRefundAmount | number: '1.0-0'}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@approvalAvgNightlyPriceSmall">Average nightly price</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.avgNightlyPrice | number: '1.0-0'}}</p>
              </div>
            </div>
          </div>
        </div>
         <div class="col-lg-6 pb-2 pt-5 mt-5">

              <div class="table-header initial-width rounded py-4 mb-5">

              <p class="font-weight-bold font-16 px-3" i18n="@@bookingsFlights">Bookings (Flights)</p>
            <div class="px-4 font-14" *ngIf="reports.flightsDashboardSummary">
              <div class="d-flex">
                <p class="mb-2" i18n="@@numberOfFlights">Number of flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@confirmedFlights">Confirmed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfConfirmedItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@canceledFlights">Canceled flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfCanceledItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@completedFlights">Completed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfCompletedItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@upcomingFlights">Upcoming flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfUpcomingItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@internationalFlights">International flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfInternationalItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@domesticFlights">Domestic flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfDomesticItineraries}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@avgPricePerFlight">Average price per flight</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.flightsDashboardSummary.avgPricePerItinerary | number: '1.0-0'}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@totalSpendAmountFlights">Total spend amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.flightsDashboardSummary.totalSpentAmount | number: '1.0-0'}}</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@totalRefundAmount">Total refund amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.flightsDashboardSummary.totalRefundAmount | number: '1.0-0'}}</p>
              </div>
            </div>


            <div class="px-4 font-14" *ngIf="!reports.flightsDashboardSummary">
              <div class="d-flex">
                <p class="mb-2" i18n="@@numberOfFlights">Number of flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@confirmedFlights">Confirmed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@canceledFlights">Canceled flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@completedFlights">Completed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@upcomingFlights">Upcoming flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@internationalFlights">International flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@domesticFlights">Domestic flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@avgPricePerFlight">Average price per flight</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@totalSpendAmount">Total spend amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
              </div>
              <div class="d-flex">
                <p class="mb-2" i18n="@@totalRefundAmount">Total refund amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
              </div>
            </div>

          </div>


        </div>-->



          </div>
        </div>
        <div class="row " *ngIf="viewReport === 'table'">
          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/account_overview.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@accountOverview">Account overview</p>
            </div>
            <div class="row" [ngClass]="{'text-center account-p': dataService.desktopView}">
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@activeMembers">Active Members</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.activeMembers}}</p>
              </div>
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@pendingInvitations">Pending Invitations</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.pendingInvitations}}</p>
              </div>
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@inactiveMembers">Inactive Members</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.inactiveMembers}}</p>
              </div>
              <div class="col-md-3">
              </div>
            </div>

          </div>

          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/trips_approvals.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@menuItemApprovals">Trips approval</p>
            </div>
            <div class="row" [ngClass]="{'text-center trips-p': dataService.desktopView}">
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@approvalRequests">Approval requests</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.totalNumberOfApprovals}}</p>
              </div>
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@approved">Approved</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.approvedApprovals}}</p>
              </div>
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@pending">Pending</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.pendingApprovals}}</p>
              </div>
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@declined">Declined</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.disapprovedApprovals}}</p>
              </div>
            </div>

          </div>

          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/policy_overview.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@policyOverview">Policy overview</p>
            </div>
            <div class="row" [ngClass]="{'text-center policy-p': dataService.desktopView}">
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@tripsWithinPolicy">Trips within policy</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.totalNumberOfApprovals}}</p>
              </div>
              <div class="col-md-3">
                <p class="mb-2 gray-text" i18n="@@tripsOutOfPolicy">Trips out of policy</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                  {{reports.approvedApprovals}}</p>
              </div>
              <div class="col-md-3">
              </div>
              <div class="col-md-3">
              </div>
            </div>

          </div>

          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/top_destination.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@topDestinations">Top destinations </p>
            </div>
            <div class="row" [ngClass]="{'text-center destination-p': dataService.desktopView}">
              <div *ngFor="let item of reports.topDestinations; let i = index"
                [ngClass]="{'min-w220': i === 1, 'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                <p class="mb-2 gray-text">{{item.name}}</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{item.count}}
                </p>
              </div>
              <div class="col-auto" *ngIf="!reports.topDestinations.length">
                <p class="mb-2 gray-text" i18n="@@noTopDestinations">No top destinations</p>
              </div>
            </div>

          </div>

          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/top_travelers.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@topTravelers">Top travelers</p>
            </div>
            <div class="row"
              [ngClass]="{'text-center': dataService.desktopView, 'travelers-p': reports.topTravelers && reports.topTravelers.length && dataService.desktopView}">
              <div *ngFor="let item of reports.topTravelers; let i = index"
                [ngClass]="{'min-w220': i === 1, 'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                <p class="mb-2 gray-text">{{item.name}}</p>
                <p class="mb-2 font-16"
                  [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{item.count}}
                </p>
              </div>
              <div class="col-md-3" *ngIf="!reports.topDestinations.length">
                <p class="mb-2 gray-text " *ngIf="!reports.topTravelers.length" i18n="@@noTopTravelers"
                  [ngClass]="{'no-travelers-p': dataService.desktopView}">No top travelers</p>
              </div>
            </div>

          </div>

          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/hotel_bookings.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@bookingsHotels">Hotel bookings</p>
            </div>
            <ng-container *ngIf="reports.totalNumberOfBookings > 0">
              <div class="row" [ngClass]="{'mb-5': dataService.desktopView}"
                [ngClass]="{'text-center': dataService.desktopView}">
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@numberOfBookings">Total bookings</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfBookings}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@confirmedBookings">Confirmed</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfConfirmedBookings}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@canceledBookings">Canceled</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfCanceledBookings}}</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>
              <div class="row" [ngClass]="{'text-center hotel-p mt-5': dataService.desktopView}">
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                  <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.totalSpentAmount | number: '1.0-0'}}</p>
                </div>
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}"
                  style="width: 60px"></div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@refundAmountCanceled">Total refund</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.totalRefundAmount | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3 min-w210">
                  <p class="mb-2 gray-text" i18n="@@approvalAvgNightlyPriceSmall">Average nightly price</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.avgNightlyPrice | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="reports.totalNumberOfBookings === 0">
              <div class="row" [ngClass]="{'text-center hotel-p': dataService.desktopView}">
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                  <p class="mb-2 gray-text" i18n="@@numberOfBookings">Total bookings</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.totalNumberOfBookings}}</p>
                </div>
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}"
                  style="width: 20px"></div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.totalSpentAmount | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@refundAmountCanceled">Total refund</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.totalRefundAmount | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3 min-w210">
                  <p class="mb-2 gray-text" i18n="@@approvalAvgNightlyPriceSmall">Average nightly price</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.avgNightlyPrice | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>
            </ng-container>
          </div>

          <div class="table-section-container rounded p-4 mx-3 mb-5"
            [ngClass]="{'w-75': dataService.desktopView, 'w-100': dataService.mobileView}">
            <div class="d-flex">
              <img class="mb-3 pb-3" src="assets/img/flight_bookings.svg" alt="wait">
              <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@bookingsFlights">Flight bookings</p>
            </div>
            <ng-container *ngIf="reports.flightsDashboardSummary">
              <div class="row" [ngClass]="{'mb-5 text-center flight-p': dataService.desktopView}">
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                  <p class="mb-2 gray-text" i18n="@@numberOfFlights">Total flights</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfItineraries}}</p>
                </div>
                <div class="col-md-3" *ngIf="reports.flightsDashboardSummary.totalNumberOfItineraries > 0">
                  <p class="mb-2 gray-text" i18n="@@confirmedFlights">Confirmed</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfConfirmedItineraries}}</p>

                  <p class="mb-2 gray-text" [ngClass]="{'mt-5': dataService.desktopView}" i18n="@@canceledFlights">
                    Canceled</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfCanceledItineraries}}</p>

                </div>

                <div class="col-md-3 border-right border-left"
                  *ngIf="reports.flightsDashboardSummary.totalNumberOfItineraries > 0">
                  <p class="mb-2 gray-text" i18n="@@upcomingFlights">Upcoming</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfUpcomingItineraries}}</p>

                  <p class="mb-2 gray-text" [ngClass]="{'mt-5': dataService.desktopView}" i18n="@@completedFlights">
                    Completed</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfCompletedItineraries}}</p>

                </div>
                <div class="col-md-3" *ngIf="reports.flightsDashboardSummary.totalNumberOfItineraries > 0">
                  <p class="mb-2 gray-text" i18n="@@internationalFlights">International</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfInternationalItineraries}}</p>

                  <p class="mb-2 gray-text" [ngClass]="{'mt-5': dataService.desktopView}" i18n="@@domesticFlights">
                    Domestic flights</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    {{reports.flightsDashboardSummary.totalNumberOfDomesticItineraries}}</p>

                </div>
              </div>
              <div class="row" [ngClass]="{'text-center flight-p1': dataService.desktopView}">
                <div [ngClass]="{'col-auto ': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                  <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.flightsDashboardSummary.totalSpentAmount | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@totalRefundAmount">Total refund</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.flightsDashboardSummary.totalRefundAmount | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@avgPricePerFlight">Avg flight price</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                    ${{reports.flightsDashboardSummary.avgPricePerItinerary | number: '1.0-0'}}</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!reports.flightsDashboardSummary">
              <div class="row" [ngClass]="{'text-center flight-p': dataService.desktopView}">
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}">
                  <p class="mb-2 gray-text" i18n="@@numberOfFlights">Total flights</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-3': dataService.mobileView}"
                  style="width: 20px"></div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@totalSpendAmount">Total spend</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@totalRefundAmount">Total refund</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                </div>
                <div class="col-md-3">
                  <p class="mb-2 gray-text" i18n="@@avgPricePerFlight">Avg flight price</p>
                  <p class="mb-2 font-16"
                    [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                </div>
                <div class="col-md-3">
                </div>
              </div>
            </ng-container>
          </div>


          <!--<div class="d-none">
            <div class="col-lg-7">
              <div class="row">
                <div class="col-lg-6 pb-2">
                  <div class="table-header rounded py-4 mb-5">
                    <p class="font-weight-bold font-16 px-3" i18n="@@accountOverview">Account Overview</p>
                    <div class="px-4 font-14">
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@activeMembers">Active Members</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.activeMembers}}</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@pendingInvitations">Pending Invitations</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.pendingInvitations}}</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@inactiveMembers">Inactive Members</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.inactiveMembers}}</p>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pb-2">

                  <div class="table-header rounded py-4 mb-5">
                    <p class="font-weight-bold font-16 px-3" i18n="@@menuItemApprovals">Approvals</p>
                    <div class="px-4 font-14">
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@approvalRequests">Approval requests</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfApprovals}}</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@approved">Approved</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.approvedApprovals}}</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@declined">Declined</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.disapprovedApprovals}}</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@pending">Pending</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.pendingApprovals}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 pb-2">
                  <div class="table-header rounded py-4 mb-5">
                    <p class="font-weight-bold font-16 px-3" i18n="@@policyOverview">Policy Overview</p>
                    <div class="px-4 font-14">
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@bookingWithinPolicyLimit">Booking within the policy limit</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfPolicySavings}}</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-2" i18n="@@bookingViolatePolicyLimit">Booking violate the policy limit</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfPolicyViolations}}</p>
                      </div>


                    </div>
                  </div>
                </div>
                <div class="col-lg-6 pb-2">
                  <div class="table-header rounded py-4 mb-5">
                    <p class="font-weight-bold font-16 px-3" i18n="@@topDestinations">Top Destinations</p>
                    <div class="px-4 font-14" *ngFor="let item of reports.topDestinations">
                      <div class="d-flex">
                        <p class="mb-2">{{item.name}}</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{item.count}}</p>
                      </div>
                    </div>
                    <div class="px-4 font-14" *ngIf="!reports.topDestinations.length" i18n="@@noTopDestinations">No top destinations</div>
                  </div>
                </div>
                <div class="col-lg-6 pb-2">
                  <div class="table-header rounded py-4 mb-5">
                    <p class="font-weight-bold font-16 px-3" i18n="@@topTravelers">Top Travelers</p>
                    <div class="px-4 font-14" *ngFor="let item of reports.topTravelers">
                      <div class="d-flex">
                        <p class="mb-2">{{item.name}}</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{item.count}}</p>
                      </div>

                    </div>
                    <div class="px-4 font-14" *ngIf="!reports.topTravelers.length" i18n="@@noTopTravelers">No top travelers</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
            <div class="table-header rounded py-4 mb-5">
              <p class="font-weight-bold font-16 px-3" i18n="@@bookingsHotels">Bookings (Hotels)</p>
              <div class="px-4 font-14">
                <div class="d-flex">
                  <p class="mb-2" i18n="@@numberOfBookings">Number of bookings</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfBookings}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@confirmedBookings">Confirmed bookings</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfConfirmedBookings}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@canceledBookings">Canceled bookings</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.totalNumberOfCanceledBookings}}</p>
                </div>

                <div class="d-flex">
                  <p class="mb-2" i18n="@@totalSpendAmount">Total spend amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.totalSpentAmount | number: '1.0-0'}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@refundAmountCanceled">Refund amount (canceled)</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.totalRefundAmount | number: '1.0-0'}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@approvalAvgNightlyPriceSmall">Average nightly price</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.avgNightlyPrice | number: '1.0-0'}}</p>
                </div>
              </div>
            </div>

            <div class="table-header rounded py-4 mb-5">
              <p class="font-weight-bold font-16 px-3" i18n="@@bookingsFlights">Bookings (Flights)</p>
              <div class="px-4 font-14" *ngIf="reports.flightsDashboardSummary">
                <div class="d-flex">
                  <p class="mb-2" i18n="@@numberOfFlights">Number of flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@confirmedFlights">Confirmed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfConfirmedItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@canceledFlights">Canceled flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfCanceledItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@completedFlights">Completed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfCompletedItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@upcomingFlights">Upcoming flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfUpcomingItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@internationalFlights">International flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfInternationalItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@domesticFlights">Domestic flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">{{reports.flightsDashboardSummary.totalNumberOfDomesticItineraries}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@avgPricePerFlight">Average price per flight</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.flightsDashboardSummary.avgPricePerItinerary | number: '1.0-0'}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@totalSpendAmount">Total spend amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.flightsDashboardSummary.totalSpentAmount | number: '1.0-0'}}</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@totalRefundAmount">Total refund amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">${{reports.flightsDashboardSummary.totalRefundAmount | number: '1.0-0'}}</p>
                </div>
              </div>


              <div class="px-4 font-14" *ngIf="!reports.flightsDashboardSummary">
                <div class="d-flex">
                  <p class="mb-2" i18n="@@numberOfFlights">Number of flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@confirmedFlights">Confirmed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@canceledFlights">Canceled flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@completedFlights">Completed flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@upcomingFlights">Upcoming flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@internationalFlights">International flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@domesticFlights">Domestic flights</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@avgPricePerFlight">Average price per flight</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@totalSpendAmount">Total spend amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                </div>
                <div class="d-flex">
                  <p class="mb-2" i18n="@@totalRefundAmount">Total refund amount</p> <p class="mb-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">$0</p>
                </div>
              </div>

            </div>




          </div>
          </div>-->
        </div>




      </div>







    </div>
  </div>


  <div class="d-none" id="download">
    <img #canvas>
  </div>

</div>