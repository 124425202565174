<form
  [formGroup]="searchForm"
  (ngSubmit)="onSubmit()"
  class="flights-controller"
>
  <div class="justify-content-end">
    <div
      class="d-flex top-dropdown mb-3 mt-2"
      [ngClass]="{
        'blue-text': restApi.isCompany() || !consumerSearchTabs(),
        'text-white': consumerSearchTabs()
      }"
      *ngIf="
        !showSearchPopUpautocompleteDepart && !showSearchPopUpautocompleteReturn
      "
    >
      <div ngbDropdown class="position-relative">
        <div id="trip" ngbDropdownToggle class="pointer">
          <span>{{ selectedTrip }}</span>
          <i
            class="icon-arrow-down"
            [ngClass]="{
              'pl-2': restApi.isSiteLtr(),
              'pr-2': restApi.isSiteRtl()
            }"
          ></i>
        </div>
        <div
          ngbDropdownMenu
          aria-labelledby="trip"
          class="trip-drop-down px-3 pt-3 font-14 gray-text-dark"
          [ngClass]="{ 'text-right': restApi.isSiteRtl() }"
        >
          <p
            (click)="
              selectedTrip = this.roundTripLocalized; callRoundTripDatePicker()
            "
            ngbDropdownToggle
            class="pointer"
            i18n="@@roundTrip"
          >
            Round-trip
          </p>
          <p
            (click)="
              selectedTrip = this.oneWayLocalized; callOneWayDatePicker()
            "
            ngbDropdownToggle
            class="pointer"
            i18n="@@oneWay"
          >
            One-way
          </p>
        </div>
      </div>
      <div ngbDropdown class="position-relative">
        <div
          id="classType"
          ngbDropdownToggle
          class="pointer"
          [ngClass]="{
            'ml-5': restApi.isSiteLtr(),
            'mr-5': restApi.isSiteRtl()
          }"
        >
          <span>{{ selectedClass.name }}</span>
          <i
            class="icon-arrow-down"
            [ngClass]="{
              'pl-2': restApi.isSiteLtr(),
              'pr-2': restApi.isSiteRtl()
            }"
          ></i>
        </div>
        <div
          ngbDropdownMenu
          aria-labelledby="classType"
          class="px-3 pt-3 font-14 gray-text-dark"
          [ngClass]="{ 'text-right': restApi.isSiteRtl() }"
        >
          <div *ngFor="let item of vars.classType">
            <p (click)="selectedClass = item" ngbDropdownToggle class="pointer">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class=""
      [ngClass]="{ 'in action-popup': showSearchPopUpautocompleteDepart }"
    >
      <div [ngClass]="{ header: showSearchPopUpautocompleteDepart }">
        <div *ngIf="showSearchPopUpautocompleteDepart === true && !validChild">
          <div class="info" i18n="@@searchDeparture">
            Search for departure airport
          </div>
          <div
            class="close"
            (click)="hideSearchPopUpSearchDepart()"
            i18n="@@cancelBtn"
          >
            Cancel
          </div>
        </div>
        <div
          class="form-group position-relative m-0 with-icon mobile-search-field mb-3"
        >
          <input
            (focus)="autocompleteOnClickDepart($event)"
            [ngClass]="{
              'is-invalid': submitted && captionDepart === '',
              'arial-font-style': !restApi.isCompany(),
              'flight-input': !showSearchPopUpautocompleteDepart
            }"
            (ngModelChange)="this.SearchTextQuery.next($event)"
            required
            *ngIf="!availability"
            autocomplete="off"
            [(ngModel)]="captionDepart"
            name="search"
            i18n-placeholder="@@selectOriginPlaceholder"
            placeholder="Select an origin"
            type="text"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
          <input
            (focus)="autocompleteOnClickDepart($event)"
            [ngClass]="{
              'is-invalid': submitted && restApi.captionDepart === '',
              'arial-font-style': !restApi.isCompany(),
              'flight-input': !showSearchPopUpautocompleteDepart
            }"
            (ngModelChange)="this.SearchTextQuery.next($event)"
            required
            *ngIf="availability"
            autocomplete="off"
            [(ngModel)]="this.restApi.captionDepart"
            [ngModelOptions]="{ standalone: true }"
            name="search"
            i18n-placeholder="@@selectOriginPlaceholder"
            placeholder="Select an origin"
            type="text"
            class="form-control"
          />
          <span
            (focus)="autocompleteOnClickDepart($event)"
            *ngIf="
              restApi.captionDepart !== undefined &&
              restApi.captionDepart.length === 0 &&
              availability
            "
            class="gray-text placeholder-search"
            [ngClass]="{ 'arial-font-style': !restApi.isCompany() }"
            i18n="@@selectOrigin"
            >Select an origin</span
          >

          <label
            *ngIf="!showSearchPopUpautocompleteDepart"
            class="form-control-label gray-text font-12"
            i18n="@@from"
            >From</label
          >

          <i
            *ngIf="!showSearchPopUpautocompleteDepart"
            class="icon-locator gray-text"
          ></i>
          <span
            *ngIf="
              !activePopUp &&
              !showSearchPopUpautocompleteDepart &&
              !showSearchPopUpautocompleteReturn
            "
            (click)="departReturnSwitch()"
            class="icon-top-down-switch position-absolute font-20 left-right-switch pointer"
            ><span class="path1"></span><span class="path2"></span
            ><span class="path3"></span><span class="path4"></span
            ><span class="path5"></span
          ></span>
        </div>
        <div
          *ngIf="searchValid && !showSearchPopUpautocompleteDepart"
          class="text-danger mb-3"
          [ngClass]="{
            'text-left': restApi.isSiteLtr(),
            'text-right': restApi.isSiteRtl()
          }"
          i18n="@@pleaseSelectOrigin"
        >
          Please select an origin
        </div>
      </div>

      <div
        class="body"
        *ngIf="showSearchPopUpautocompleteDepart === true && !validChild"
      >
        <div class="autocomplete_wrapper">
          <ng-container
            *ngFor="let autocompletetype of autocompletelist; let i = index"
          >
            <ng-container *ngIf="autocompletetype.name === 'Airports'">
              <ng-container *ngFor="let entity of autocompletetype.entities">
                <div
                  *ngIf="autocompletetype.entities.length && entity.code !== ''"
                >
                  <li
                    data-id="{{ entity.code }}"
                    tabindex="-1"
                    [value]="entity.caption"
                    (click)="setSearchTextDepart(entity, autocompletetype.name)"
                  >
                    <span class="d-block">
                      {{ entity.caption }}
                    </span>
                  </li>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      class=""
      [ngClass]="{ 'in action-popup': showSearchPopUpautocompleteReturn }"
    >
      <div [ngClass]="{ header: showSearchPopUpautocompleteReturn }">
        <div *ngIf="showSearchPopUpautocompleteReturn === true && !validChild">
          <div class="info" i18n="@@searchArrival">
            Search for arrival airport
          </div>
          <div
            class="close"
            (click)="hideSearchPopUpSearchReturn()"
            i18n="@@cancelBtn"
          >
            Cancel
          </div>
        </div>

        <div
          class="form-group position-relative m-0 with-icon mobile-search-field mb-3"
        >
          <input
            (focus)="autocompleteOnClickReturn($event)"
            [ngClass]="{
              'is-invalid': submitted && captionReturn === '',
              'arial-font-style': !restApi.isCompany(),
              'flight-input': !showSearchPopUpautocompleteReturn
            }"
            (ngModelChange)="this.SearchTextQuery.next($event)"
            required
            *ngIf="!availability"
            autocomplete="off"
            [(ngModel)]="captionReturn"
            name="search"
            i18n-placeholder="@@selectDesPlaceholder"
            placeholder="Select a destination"
            type="text"
            id="returnInputField1"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
          />
          <input
            (focus)="autocompleteOnClickReturn($event)"
            [ngClass]="{
              'is-invalid': submitted && restApi.captionReturn === '',
              'arial-font-style': !restApi.isCompany(),
              'flight-input': !showSearchPopUpautocompleteReturn
            }"
            (ngModelChange)="this.SearchTextQuery.next($event)"
            required
            *ngIf="availability"
            autocomplete="off"
            [(ngModel)]="this.restApi.captionReturn"
            [ngModelOptions]="{ standalone: true }"
            name="search"
            i18n-placeholder="@@selectDesPlaceholder"
            placeholder="Select a destination"
            type="text"
            id="returnInputField2"
            class="form-control"
          />
          <span
            (focus)="autocompleteOnClickReturn($event)"
            *ngIf="
              restApi.captionReturn !== undefined &&
              restApi.captionReturn.length === 0 &&
              availability
            "
            class="gray-text placeholder-search"
            [ngClass]="{ 'arial-font-style': !restApi.isCompany() }"
            i18n="@@selectDes"
            >Select a destination</span
          >

          <label
            *ngIf="!showSearchPopUpautocompleteReturn"
            class="form-control-label gray-text font-12"
            i18n="@@to"
            >To</label
          >

          <i
            *ngIf="!showSearchPopUpautocompleteReturn"
            class="icon-locator gray-text"
          ></i>
        </div>
        <div
          *ngIf="searchValid && !showSearchPopUpautocompleteReturn"
          class="text-danger mb-3"
          [ngClass]="{
            'text-left': restApi.isSiteLtr(),
            'text-right': restApi.isSiteRtl()
          }"
          i18n="@@pleaseSelectDes"
        >
          Please select a destination
        </div>
      </div>

      <div
        class="body"
        *ngIf="showSearchPopUpautocompleteReturn === true && !validChild"
      >
        <div class="autocomplete_wrapper">
          <ng-container
            *ngFor="let autocompletetype of autocompletelist; let i = index"
          >
            <ng-container *ngIf="autocompletetype.name === 'Airports'">
              <ng-container *ngFor="let entity of autocompletetype.entities">
                <div
                  *ngIf="autocompletetype.entities.length && entity.code !== ''"
                >
                  <li
                    data-id="{{ entity.code }}"
                    tabindex="-1"
                    [value]="entity.caption"
                    (click)="setSearchTextReturn(entity, autocompletetype.name)"
                  >
                    <span class="d-block">
                      {{ entity.caption }}
                    </span>
                  </li>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div>
      <div class="d-flex">
        <div class="w-half">
          <div
            class="form-group mb-3 with-icon calendar-item"
            (click)="showSearchPopUp('dates')"
          >
            <div class="calendar-value-cont font-14">
              <span class="d-inline-block">
                <span id="check-in-date-desktop">{{
                  departingRangeFormatted
                }}</span>
              </span>
            </div>
            <div class="form-control form-control-sm"></div>
            <i class="icon-dates gray-text"></i>
            <label
              class="form-control-label gray-text font-12"
              i18n="@@departing"
              >Departing</label
            >
          </div>
        </div>
        <div
          class="w-half"
          [ngClass]="{
            'ml-auto': restApi.isSiteLtr(),
            'mr-auto': restApi.isSiteRtl()
          }"
        >
          <div
            class="form-group mb-3 with-icon calendar-item"
            [ngClass]="{
              'disabled-field':
                selectedTrip === 'One-way' ||
                this.selectedTrip === this.oneWayLocalized
            }"
            (click)="showSearchPopUp('dates')"
          >
            <div class="calendar-value-cont font-14">
              <span class="d-inline-block">
                <span
                  *ngIf="
                    selectedTrip !== 'One-way' &&
                    this.selectedTrip !== this.oneWayLocalized
                  "
                  id="return-date-desktop"
                  >{{ returningRangeFormatted }}</span
                >
              </span>
            </div>
            <div class="form-control form-control-sm"></div>
            <i class="icon-dates gray-text"></i>
            <label
              class="form-control-label gray-text font-12"
              i18n="@@returning"
              >Returning</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="position-relative" (click)="showUsersPopUp()">
      <div class="paxes-cont-input form-group mb-3 with-icon calendar-item">
        <div class="form-name-paxes-summary form-control">
          <div class="input_wrapper">
            <p *ngIf="selectedUser" class="mt-2 pt-1">
              {{ selectedUser.description }}
            </p>
            <span
              *ngIf="!selectedUser"
              class="gray-text traveller placeholder-traveller px-4"
              i18n="@@whoTravelling"
              >Who's travelling?</span
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <button
        value="submit"
        class="btn-block green_btn btn-block"
        [ngClass]="{ 'm-progress': submitting }"
        i18n="@@searchBtn"
      >
        Search
      </button>
    </div>
  </div>

  <div
    class="action-popup"
    [ngClass]="{ in: activePopUp || validChild === true }"
  >
    <div class="header">
      <div
        class="form-name-dates-summary picker-new-header"
        *ngIf="showSearchPopDates && !validChild"
      >
        <div class="dates row no-gutters px-2">
          <div class="date col-5 text-center">
            <div
              class="d-inline-block"
              [ngClass]="{
                'text-left': restApi.isSiteLtr(),
                'text-right': restApi.isSiteRtl()
              }"
            >
              <div class="title" i18n="@@departing">Departing</div>
              <div class="value">{{ departingRangeFormatted }}</div>
            </div>
          </div>
          <ng-container *ngIf="selectedTrip !== 'One-way'">
            <div class="between col-2 text-center">
              <i class="icon-arrow-right2" *ngIf="restApi.isSiteLtr()"></i>
              <i class="icon-arrow-left2" *ngIf="restApi.isSiteRtl()"></i>
            </div>
            <div class="date col-5 text-center">
              <div
                class="d-inline-block"
                [ngClass]="{
                  'text-left': restApi.isSiteLtr(),
                  'text-right': restApi.isSiteRtl()
                }"
              >
                <div class="title" i18n="@@returning">Returning</div>
                <div class="value">{{ returningRangeFormatted }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="showSearchPopPaxes || validChild">
        <div class="info" i18n="@@whoIsTravel">Who's traveling?</div>
      </div>

      <div
        *ngIf="showSearchPopPaxes === true || validChild"
        class="summary_item"
      >
        <span class="pax_count ng-binding">{{ adultCount }}</span>

        <span class="night-cont">
          <ng-container
            *ngIf="adultCount === 0 || adultCount === 1"
            i18n="@@adult"
          >
            Adult</ng-container
          >
          <ng-container *ngIf="adultCount === 2" i18n="@@twoAdults"
            >Adults</ng-container
          >
          <ng-container *ngIf="adultCount > 2" i18n="@@adults"
            >Adults</ng-container
          >
        </span>

        <span class="pax_count d-none">{{ childrenCount }} </span>

        <span class="night-cont d-none">
          <ng-container
            *ngIf="childrenCount === 0 || childrenCount === 1"
            i18n="@@child"
          >
            Child</ng-container
          >
          <ng-container *ngIf="childrenCount === 2" i18n="@@twoChildren"
            >Children</ng-container
          >
          <ng-container *ngIf="childrenCount > 2" i18n="@@children"
            >Children</ng-container
          >
        </span>

        <span i18n="@@max9">(max 9)</span>
      </div>
    </div>
    <div class="body">
      <div
        class="mobile_dates"
        [ngClass]="showSearchPopDates && !validChild ? 'd-block' : 'd-none'"
      >
        <div class="dates-search-popup">
          <input
            *ngIf="
              selectedTrip === 'Round-trip' ||
              this.selectedTrip === this.roundTripLocalized
            "
            type="text"
            id="dateRangeDepart"
            class="form-control form-control-sm"
            name="dates"
          />
          <input
            *ngIf="
              selectedTrip === 'One-way' ||
              this.selectedTrip === this.oneWayLocalized
            "
            type="text"
            id="oneWay"
            class="form-control form-control-sm"
            name="dates"
          />
        </div>
      </div>

      <div class="p-2" *ngIf="showSearchPopPaxes === true || validChild">
        <div class="people_number-menu">
          <div class="adults row no-gutters">
            <div class="col">
              <span
                class="paxes-icon icon-minus col"
                [ngClass]="{ disabled: decreaseAdultDisabled === true }"
                (click)="decreaseAdult()"
              >
                <i class="icon-min"></i
              ></span>
            </div>
            <div class="col text-center">
              <span class="count adults_count">
                <span class="pax_count">{{ adultCount }} </span>
                <ng-container
                  *ngIf="adultCount === 0 || adultCount === 1"
                  i18n="@@adult"
                >
                  Adult</ng-container
                >
                <ng-container *ngIf="adultCount === 2" i18n="@@twoAdults"
                  >Adults</ng-container
                >
                <ng-container *ngIf="adultCount > 2" i18n="@@adults"
                  >Adults</ng-container
                >
              </span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-right': restApi.isSiteLtr(),
                'text-left': restApi.isSiteRtl()
              }"
            >
              <span
                [ngClass]="{ disabled: increasDisabled === true }"
                class="paxes-icon icon-add"
                (click)="increaseAdult()"
              >
                <i class="icon-plus"></i>
              </span>
            </div>
          </div>

          <div class="adults row no-gutters d-none">
            <div class="col">
              <span
                class="paxes-icon icon-minus"
                [ngClass]="{ disabled: decreaseChildrenDisabled === true }"
                (click)="decreaseChildren()"
              >
                <i class="icon-min"></i
              ></span>
            </div>

            <div class="col text-center">
              <span class="count adults_count">
                <span class="pax_count"> {{ childrenCount }} </span>
                <ng-container
                  *ngIf="childrenCount === 0 || childrenCount === 1"
                  i18n="@@child"
                >
                  Child</ng-container
                >
                <ng-container *ngIf="childrenCount === 2" i18n="@@twoChildren"
                  >Children</ng-container
                >
                <ng-container *ngIf="childrenCount > 2" i18n="@@children"
                  >Children</ng-container
                >
              </span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-right': restApi.isSiteLtr(),
                'text-left': restApi.isSiteRtl()
              }"
            >
              <span
                class="paxes-icon icon-add"
                [ngClass]="{ disabled: increasDisabled === true }"
                (click)="increaseChildren()"
              >
                <i class="icon-plus"></i>
              </span>
            </div>
          </div>

          <div>
            <div class="rows clearfix d-none" *ngIf="childrenCount > 0">
              <div
                class="pl-4 pr-4 pt-3 pb-3 text-center d-block"
                i18n="@@childAge"
              >
                Child age at check-in
              </div>

              <div
                class="item clearfix d-flex"
                *ngFor="let childrens of childrenList; let i = index"
              >
                <label class="name">
                  <ng-container i18n="@@child">Child</ng-container> {{ i + 1 }}
                </label>

                <div class="select">
                  {{ childrenList[i] }}
                  <select
                    *ngIf="childrenList"
                    [(ngModel)]="childrens.childList"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let number of ages" [ngValue]="number">
                      {{ number }}
                    </option>
                  </select>
                  <i class="icon-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="validChild" class="text-danger" i18n="@@childAgeRequired">
          Children ages are required
        </div>
      </div>

      <div
        [ngClass]="{ 'd-block': validChild }"
        class="green_btn btn btn-block text-medium d-none"
        (click)="hideSearchPopUp()"
        i18n="@@done"
      >
        Done
      </div>
      <div
        [ngClass]="{
          'd-none':
            showSearchPopUpautocompleteReturn === true ||
            showSearchPopUpautocompleteDepart ||
            validChild
        }"
        class="green_btn btn btn-block text-medium"
        (click)="hideSearchPopUp()"
        i18n="@@done"
      >
        Done
      </div>
    </div>
  </div>
</form>

<div class="justify-content-end create-team">
  <div [ngClass]="{ 'in action-popup': showUsersAutoCompletePopUp }">
    <div [ngClass]="{ header: showUsersAutoCompletePopUp }">
      <div *ngIf="showUsersAutoCompletePopUp">
        <div *ngIf="showUsersAutoCompletePopUp">
          <div class="info">
            <span i18n="@@whoTravelling">Who's travelling?</span>
          </div>
        </div>
      </div>

      <div
        class="position-relative mobile-search-field"
        *ngIf="showUsersAutoCompletePopUp"
      >
        <div class="form-group mb-3">
          <mat-form-field class="w-100 chips-container border rounded">
            <input
              class="bg-white min-height mx-0 my-0"
              [ngClass]="{
                'pl-4': restApi.isSiteLtr(),
                'pr-4': restApi.isSiteRtl()
              }"
              #userInput
              id="userInput"
              type="text"
              matInput
              [formControl]="userControl"
              [matAutocomplete]="autoUser"
              (blur)="reset()"
            />
            <mat-autocomplete
              #autoUser="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option
                class="px-2"
                *ngFor="
                  let user of filteredUsers
                    | async
                    | slice : 0 : maxNoUserToShow
                "
                [value]="user"
              >
                <div
                  class="d-flex mat-checkbox-container"
                  (click)="optionClicked($event, user)"
                  *ngIf="user.id !== 'guest'"
                >
                  <div
                    class="name-first-letters small"
                    [ngClass]="{
                      'mr-3': restApi.isSiteLtr(),
                      'ml-3': restApi.isSiteRtl()
                    }"
                    [ngStyle]="{
                      'background-color': user.profileImageBackgroundHexColor
                    }"
                  >
                    <img
                      loading="lazy"
                      class="mb-1 user-img d-inline-block"
                      onError="this.src='assets/img/default-user.png'"
                      [src]="user.profileImage"
                    />
                  </div>
                  <div class="dotted-text d-flex align-items-center">
                    <p class="mb-0 gray-primaray-text">
                      <span>{{ user.description }}</span>
                    </p>
                  </div>
                </div>

                <div
                  class="d-flex mat-checkbox-container"
                  (click)="optionClicked($event, user)"
                  *ngIf="user.id === 'guest'"
                >
                  <img
                    width="35"
                    height="35"
                    loading="lazy"
                    *ngIf="user.profileImage"
                    class="user-img d-inline-block"
                    [ngClass]="{
                      'mr-3': restApi.isSiteLtr(),
                      'ml-3': restApi.isSiteRtl()
                    }"
                    onError="this.src='assets/img/default-user.png'"
                    [src]="user.profileImage"
                  />
                  <div
                    class="name-first-letters small"
                    [ngClass]="{
                      'mr-3': restApi.isSiteLtr(),
                      'ml-3': restApi.isSiteRtl()
                    }"
                    *ngIf="!user.profileImage"
                    [ngStyle]="{
                      'background-color': user.profileImageBackgroundHexColor
                    }"
                  >
                    {{ user.firstName.substring(0, 1).toUpperCase()
                    }}{{ user.lastName.substring(0, 1).toUpperCase() }}
                  </div>
                  <div class="dotted-text">
                    <p class="mb-0 gray-primaray-text">
                      <span>{{ user.description }}</span>
                    </p>
                    <p class="gray-text mb-0 dotted-text dotted-text-direction">
                      {{ user.email }}
                    </p>
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
