import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { AuthServiceMain } from './auth.service';
import {DataService} from "../services/data.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthServiceMain, public router: Router, public dataService: DataService,) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.authService.user.pipe(
      take(1),
      map(user => {

       /* const isAuth = !!user;
        if (isAuth) {
          return true;
        }*/
        const destinationUrl = router.url.slice(1);
        if (this.authService.isLogedIn) {
          return true;
        } else {
          if (this.dataService.isCompany()) {
            const companyID =  router.url.split('/');
            return this.router.parseUrl('/' + companyID[1] + '/login?destination=' + encodeURIComponent(destinationUrl));
          } else{
            return this.router.parseUrl('/user/login?destination=' + destinationUrl);
          }
        }
       // return this.router.createUrlTree(['/auth']);
      })
      // tap(isAuth => {
      //   if (!isAuth) {
      //     this.router.navigate(['/auth']);
      //   }
      // })
    );




  }
}
