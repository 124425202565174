<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
    [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content pb-5">
      <app-messages></app-messages>
      <!--   <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView && auth.companyInfo && auth.companyInfo.inactive === false">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Billing </span>
      </div>-->

      <div class="billing-page container pt-4 pt-lg-5 pb-0 pb-lg-5">
        <div class="mx-lg-5">
          <div class="row" *ngIf="auth.companyInfo && auth.companyInfo.inactive === false">
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3">
                <a class="font-16 blue-text text-underline"
                  (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);"
                  i18n="@@menuItemSettings">Settings</a>
                <span class="font-16 gray-text-dark"> / <ng-container i18n="@@menuItemBilling">Billing</ng-container>
                </span>
              </div>
            </div>
          </div>

          <ng-container *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === false">
            <p class="font-20-to-18 gray-primaray-text bold-600" i18n="@@mySubscription">My Subscription</p>
            <p class="font-18 gray-text-dark mb-5"
              *ngIf="this.dataService.workspaceSubscriptionDetails && this.dataService.workspaceSubscriptionDetails.current && this.dataService.workspaceSubscriptionDetails.current.trialEnd  &&  (formatDate(this.dataService.workspaceSubscriptionDetails.current.trialEnd) >= formatDate(this.date))">
              Your free trial ends on <span
                [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.current.trialEnd)"></span>.
            </p>
            <p class="font-16 gray-text-dark mb-5"> {{subscriptionDesc}} </p>
            <!-- <p class="font-16 mb-2 mb-lg-1" [ngClass]="{' table-header p-3  primaray-text': dataService.desktopView}">Current Subscription</p>-->
            <!-- <hr class="mt-1 d-none d-lg-block"/>-->
            <div *ngIf="dataService.desktopView"
              [ngClass]="{'pl-3 border-top border-bottom pt-2 pb-3': dataService.desktopView}">
              <div class="row pt-3 pb-3 font-16 gray-primaray-text">
                <div class="col" i18n="@@billingCycle">Billing Cycle</div>
                <div class="col" i18n="@@nextBillingDate">
                  Next Billing Date
                  <!--  <div *ngIf="this.dataService.workspaceSubscriptionDetails && this.dataService.workspaceSubscriptionDetails.current">
              <span *ngIf="this.dataService.workspaceSubscriptionDetails.current.value ||  this.dataService.workspaceSubscriptionDetails.next">Next Billing Date</span>
              <span *ngIf="!this.dataService.workspaceSubscriptionDetails.current.value && !this.dataService.workspaceSubscriptionDetails.next">End Date</span>
              </div>-->
                </div>
                <div class="col" i18n="@@nextInvoice">Next invoice</div>
                <div class="col" i18n="@@activeMembers">Active Members</div>
                <div class="col"></div>
              </div>
              <div *ngIf="this.dataService.workspaceSubscriptionDetails">
                <div class="row py-3">
                  <div class="col">
                    <ng-container *ngIf="this.auth.isOwner()">
                      <div
                        (click)="showPlans(this.dataService.workspaceSubscriptionDetails.current.cycle, this.dataService.workspaceSubscriptionDetails.current.planId)"
                        class=" d-flex mb-1 pointer" *ngIf="!this.dataService.workspaceSubscriptionDetails.next">
                        <span
                          class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.current.cycle}}</span>
                        <i class="my-1 font-12 icon-pencil-icon padding-icon border rounded small"
                          [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></i>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!this.auth.isOwner()">
                      <div (click)="contactOwner()" class="d-flex mb-1 pointer"
                        *ngIf="!this.dataService.workspaceSubscriptionDetails.next">
                        <span
                          class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.current.cycle}}</span>
                        <i class="my-1 font-12 icon-pencil-icon padding-icon border rounded small"
                          [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></i>
                      </div>
                    </ng-container>
                    <div class="mb-1" *ngIf="this.dataService.workspaceSubscriptionDetails.next">
                      <span
                        class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.current.cycle}}</span>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex">
                      <div class="border rounded p-2 placeholder-text"
                        *ngIf="!this.dataService.workspaceSubscriptionDetails.current.cancelAtPeriodEnd"
                        [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.current.periodEnd)">
                      </div>
                      <div class="border rounded p-2 placeholder-text px-5"
                        *ngIf="this.dataService.workspaceSubscriptionDetails.current.cancelAtPeriodEnd">&mdash;</div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex">
                      <div class="border rounded p-2 placeholder-text"
                        *ngIf="this.dataService.workspaceSubscriptionDetails.current.value">
                        ${{this.dataService.workspaceSubscriptionDetails.current.value}}</div>
                      <div class="border rounded p-2 placeholder-text px-5"
                        *ngIf="!this.dataService.workspaceSubscriptionDetails.current.value">&mdash;</div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex">
                      <div class="border rounded p-2 placeholder-text">
                        {{this.dataService.workspaceSubscriptionDetails.current.quantity}}</div>
                    </div>
                  </div>
                  <div class="col"></div>
                </div>

              </div>
            </div>
            <div class="white-box p-3" *ngIf="this.dataService.workspaceSubscriptionDetails && dataService.mobileView">
              <div class="mb-5">
                <p class="font-16 gray-primaray-text" i18n="@@billingCycle">Billing Cycle</p>
                <ng-container *ngIf="this.auth.isOwner()">
                  <div
                    (click)="showPlans(this.dataService.workspaceSubscriptionDetails.current.cycle, this.dataService.workspaceSubscriptionDetails.current.planId)"
                    class=" d-flex mb-1 pointer" *ngIf="!this.dataService.workspaceSubscriptionDetails.next">
                    <span
                      class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.current.cycle}}</span>
                    <i class="my-1 font-12 icon-pencil-icon padding-icon border rounded small"
                      [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!this.auth.isOwner()">
                  <div (click)="contactOwner()" class="mb-1 pointer"
                    *ngIf="!this.dataService.workspaceSubscriptionDetails.next">
                    <span
                      class="d-flex border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.current.cycle}}</span>
                    <i class="my-1 font-12 icon-pencil-icon padding-icon border rounded small"
                      [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></i>
                  </div>
                </ng-container>
                <div class="mb-1" *ngIf="this.dataService.workspaceSubscriptionDetails.next">
                  <span
                    class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.current.cycle}}</span>
                </div>
              </div>

              <div class="mb-5">
                <p class="font-16 gray-primaray-text">
                  <span i18n="@@nextBillingDate">Next Billing Date</span>
                  <!--   <ng-container *ngIf="this.dataService.workspaceSubscriptionDetails && this.dataService.workspaceSubscriptionDetails.current">
                <span *ngIf="this.dataService.workspaceSubscriptionDetails.current.value ||  this.dataService.workspaceSubscriptionDetails.next">Next Billing Date</span>
                <span *ngIf="!this.dataService.workspaceSubscriptionDetails.current.value && !this.dataService.workspaceSubscriptionDetails.next">End Date</span>
              </ng-container>-->
                </p>
                <p>
                  <span class="border rounded p-2 placeholder-text"
                    *ngIf="!this.dataService.workspaceSubscriptionDetails.current.cancelAtPeriodEnd"
                    [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.current.periodEnd)"></span>
                  <span class="border rounded p-2 placeholder-text"
                    *ngIf="this.dataService.workspaceSubscriptionDetails.current.cancelAtPeriodEnd">&mdash;</span>
                </p>
              </div>

              <div class="mb-5">
                <p class="font-16 gray-primaray-text" i18n="@@nextInvoice">Next invoice</p>
                <p>
                  <span class="border rounded p-2 placeholder-text"
                    *ngIf="this.dataService.workspaceSubscriptionDetails.current.value">
                    ${{this.dataService.workspaceSubscriptionDetails.current.value}}</span>
                  <span class="border rounded p-2 placeholder-text"
                    *ngIf="!this.dataService.workspaceSubscriptionDetails.current.value">
                    &mdash;</span>
                </p>
              </div>


              <p class="font-16 gray-primaray-text" i18n="@@activeMembers">Active Members</p>
              <p>
                <span class="border rounded p-2 placeholder-text">
                  {{this.dataService.workspaceSubscriptionDetails.current.quantity}}</span>
              </p>

            </div>

            <div class="mb-4"
              *ngIf="this.dataService.workspaceSubscriptionDetails && this.dataService.workspaceSubscriptionDetails.next">
              <p class="font-20 bold-600 mt-5 mb-2 mb-lg-3 pt-4" i18n="@@nextSubscription">Next Subscription</p>
              <!-- <hr class="d-none d-lg-block mt-1"/>-->
              <div *ngIf="dataService.desktopView"
                [ngClass]="{'pl-3 border-top border-bottom pt-2 pb-3': dataService.desktopView}">
                <div class="row pt-3 pb-3 font-16 gray-primaray-text">
                  <div class="col" i18n="@@billingCycle">Billing Cycle</div>
                  <div class="col" i18n="@@nextBillingDate">Next Billing Date</div>
                  <div class="col" i18n="@@nextInvoice">Next invoice</div>
                  <div class="col" i18n="@@activeMembers">Active Members</div>
                  <div class="col"></div>
                </div>
                <div>
                  <div class="row py-3">
                    <div class="col">
                      <div class="mb-1">
                        <span
                          class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.next.cycle}}</span>
                      </div>
                    </div>
                    <div class="col">
                      <span class="border rounded p-2 placeholder-text"
                        [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.next.periodStart)"></span>
                    </div>
                    <div class="col">
                      <span
                        class="border rounded p-2 placeholder-text">${{this.dataService.workspaceSubscriptionDetails.next.value}}</span>
                    </div>
                    <div class="col">
                      <span
                        class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.next.quantity}}</span>
                    </div>
                    <div class="col">
                      <a class="blue-text font-16 text-underline" (click)="deleteSubscription();"
                        i18n="@@deleteBtn">Delete</a>
                    </div>
                  </div>

                </div>
              </div>



              <div class="card-bg p-3" *ngIf="dataService.mobileView">
                <div class="mb-5">
                  <p class="font-16 gray-primaray-text" i18n="@@billingCycle">Billing Cycle</p>
                  <div class="mb-1">
                    <span
                      class="border rounded p-2 placeholder-text">{{this.dataService.workspaceSubscriptionDetails.next.cycle}}</span>
                  </div>
                </div>

                <div class="mb-5">
                  <p class="font-16 gray-primaray-text" i18n="@@nextBillingDate">Next Billing Date</p>
                  <p>
                    <span class="border rounded p-2 placeholder-text"
                      [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.next.periodStart)"></span>
                  </p>
                </div>

                <div class="mb-5">
                  <p class="font-16 gray-primaray-text" i18n="@@nextInvoice">Next invoice</p>
                  <p>
                    <span class="border rounded p-2 placeholder-text">
                      ${{this.dataService.workspaceSubscriptionDetails.next.value}}</span>
                  </p>
                </div>


                <p class="font-16 gray-primaray-text" i18n="@@activeMembers">Active Members</p>
                <p>
                  <span class="border rounded p-2 placeholder-text">
                    {{this.dataService.workspaceSubscriptionDetails.next.quantity}}</span>
                </p>
                <p> <a class="blue-text font-16 text-underline pt-4" (click)="deleteSubscription();"
                    i18n="@@deleteBtn">Delete</a></p>
              </div>


            </div>
            <div class="mt-5 mx-lg-0 px-4 px-lg-5 py-4 company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                    'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                    'thick-top rounded-bottom': dataService.mobileView}" *ngIf="this.dataService.workspaceSubscriptionDetails && (
               (this.dataService.workspaceSubscriptionDetails.current && !this.dataService.workspaceSubscriptionDetails.current.value && !this.dataService.workspaceSubscriptionDetails.next && this.dataService.workspaceSubscriptionDetails.current.cycle !== annualLocalizedText)
                || (this.dataService.workspaceSubscriptionDetails.next && this.dataService.workspaceSubscriptionDetails.next.cycle !== annualLocalizedText)
                || (this.dataService.workspaceSubscriptionDetails.current && this.dataService.workspaceSubscriptionDetails.current.cycle !== annualLocalizedText && !this.dataService.workspaceSubscriptionDetails.next)
                )">
              <div class="row py-3 h-100 justify-content-center align-items-center">
                <div class="col-md-7 d-flex">
                  <!--  <div class="mr-5 pt-2" *ngIf="dataService.desktopView">
                  <img width="50" height="50" loading="lazy"  src="assets/img/workspace-billing-and-plans.png" alt="wait">
                </div>-->
                  <div>
                    <p class="font-18 bold-600 primaray-text" i18n="@@save10annual">Save more with Annual Billing </p>
                    <span class="font-16 gray-text-dark" i18n="@@switchAndSave">Switch to annual subscription and enjoy
                      savings on your subscription.</span>
                  </div>

                </div>
                <div class="col-md-5"
                  [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
                  <ng-container *ngIf="this.auth.isOwner()">
                    <button (click)="switchToAnnualPlan()"
                      class="btn px-5 py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center sharp-font bold-500 font-14"
                      [ngClass]="{'w-100 btn-block bg-white primaray-text desktop-btn mt-5': dataService.mobileView, 'btn-primary text-white': dataService.desktopView,
                'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" i18n="@@switchToAnnual">Switch
                      to Annual</button>
                  </ng-container>
                  <ng-container *ngIf="!this.auth.isOwner()">
                    <button (click)="contactOwner()"
                      class="btn px-5 py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center sharp-font bold-500 font-14"
                      [ngClass]="{'w-100 btn-block bg-white primaray-text desktop-btn mt-5': dataService.mobileView, 'btn-primary text-white': dataService.desktopView,
                          'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
                      i18n="@@switchToAnnual">Switch to Annual</button>
                  </ng-container>
                </div>
              </div>
            </div>


            <div class="mt-5 mx-lg-0 px-4 px-lg-5 py-5 company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                  'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                  'thick-top rounded-bottom': dataService.mobileView}"
              *ngIf="this.dataService.workspaceSubscriptionDetails && this.dataService.workspaceSubscriptionDetails.current && !this.dataService.workspaceSubscriptionDetails.current.value && !this.dataService.workspaceSubscriptionDetails.next ">
              <div class="row py-3 h-100 justify-content-center align-items-center">
                <div class="col-md-7 d-flex">
                  <div>
                    <p class=" font-18 mb-1 gray-text-dark">
                      <ng-container i18n="@@subWillEndOn">Your subscription will end on</ng-container>&nbsp;<span
                        [innerHTML]="dataService.formatDateMMMdy(this.dataService.workspaceSubscriptionDetails.current.periodEnd)">.</span>
                    </p>
                    <span class=" font-18 gray-text-dark" i18n="@@subWillDeactivate">
                      Your account will be deactivated and members will not be able to login.
                    </span>
                    <!--<span class="font-18-to-16" [ngClass]="{'gray-text-dark': dataService.desktopView, 'text-white': dataService.mobileView}"></span>-->
                  </div>

                </div>
                <div class="col-md-5"
                  [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
                  <button (click)="continueSubscription()"
                    class="btn px-5 py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center sharp-font bold-500 font-14"
                    [ngClass]="{'w-100 btn-block bg-white primaray-text': dataService.mobileView, 'btn-primary text-white': dataService.desktopView, 'm-progress': submitting,
                          'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
                    i18n="@@keepMySub">Keep My Subscription</button>
                </div>
              </div>
            </div>

          </ng-container>



          <ng-container *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive">

            <div class="mt-5 mx-lg-0 px-4 px-lg-5 py-3 minus-margin company-section-with-thick-border border" [ngClass]="{'minus-margin': dataService.desktopView,
                     'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(),
                     'thick-top rounded-bottom': dataService.mobileView}">
              <div class="row py-3 h-100 justify-content-center align-items-center">
                <div class="col-md-7 d-flex">
                  <div>
                    <p class="bold-500 font-16 gray-primaray-text"><ng-container i18n="@@subExpiredOn">Your subscription
                        expired on</ng-container>&nbsp;<span *ngIf="dataService.workspaceLastSubscription"
                        [innerHTML]="dataService.formatDateMMMdy(dataService.workspaceLastSubscription.periodEnd)"></span>.
                    </p>
                    <span class="font-16 gray-primaray-text" i18n="@@limitedAccount">You have limited access to your
                      account. Subscribe again and get access to booking and other features. </span>
                  </div>

                </div>
                <div class="col-md-5"
                  [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
                  <button (click)="dataService.reactivateAccount()"
                    class="btn px-5 py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center sharp-font bold-500 font-14"
                    [ngClass]="{'w-100 btn-block bg-white primaray-text desktop-btn mt-5': dataService.mobileView, 'btn-primary text-white': dataService.desktopView,
                              'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
                    i18n="@@activateSubscription">
                    Activate Subscription</button>
                </div>
              </div>
            </div>


            <p class="font-20 gray-primaray-text bold-600 mt-5" i18n="@@lastSub">Last Subscription</p>
            <!--   <hr class="mt-1 d-none d-lg-block"/>-->
            <div *ngIf="dataService.desktopView"
              [ngClass]="{'pl-3 border-top border-bottom pt-2 pb-3': dataService.desktopView}">
              <div class="row pt-3 pb-3 font-16 gray-primaray-text">
                <div class="col" i18n="@@billingCycle">Billing Cycle</div>
                <div class="col" i18n="@@lastBillingDate">Last Billing Date</div>
                <div class="col" i18n="@@lastInvoice">Last Invoice</div>
                <div class="col" i18n="@@activeMembers">Active Members</div>
                <div class="col"></div>
              </div>
              <div *ngIf="this.dataService.workspaceLastSubscription">
                <div class="row py-3">
                  <div class="col">
                    <div class="mb-1">
                      <span
                        class="border rounded p-2 placeholder-text">{{this.dataService.workspaceLastSubscription.cycle}}</span>
                    </div>
                  </div>
                  <div class="col">
                    <span class="border rounded p-2 placeholder-text"
                      [innerHTML]="dataService.formatDateMMMdy(dataService.workspaceLastSubscription.periodEnd)"></span>
                  </div>
                  <div class="col">
                    <span *ngIf="dataService.workspaceLastSubscription.value"
                      class="border rounded p-2 placeholder-text">${{dataService.workspaceLastSubscription.value}}</span>
                    <span *ngIf="!dataService.workspaceLastSubscription.value"
                      class="border rounded p-2 placeholder-text px-5">&mdash;</span>
                  </div>
                  <div class="col">
                    <span
                      class="border rounded p-2 placeholder-text">{{dataService.workspaceLastSubscription.quantity}}</span>
                  </div>
                  <div class="col"></div>
                </div>

              </div>
            </div>
            <div class="white-box p-3" *ngIf="dataService.workspaceLastSubscription && dataService.mobileView">
              <div class="mb-5">
                <p class="font-16 gray-primaray-text" i18n="@@billingCycle">Billing Cycle</p>
                <div class="mb-1 pointer">
                  <span
                    class="border rounded p-2 placeholder-text">{{dataService.workspaceLastSubscription.cycle}}</span>
                </div>
              </div>

              <div class="mb-5">
                <p class="font-16 gray-primaray-text" i18n="@@lastBillingDate">Last Billing Date</p>
                <p>
                  <span class="border rounded p-2 placeholder-text"
                    [innerHTML]="dataService.formatDateMMMdy(dataService.workspaceLastSubscription.periodEnd)"></span>
                </p>
              </div>

              <div class="mb-5">
                <p class="font-16 gray-primaray-text" i18n="@@lastInvoice">Last Invoice</p>
                <p>
                  <span *ngIf="dataService.workspaceLastSubscription.value" class="border rounded p-2 placeholder-text">
                    ${{dataService.workspaceLastSubscription.value}}</span>
                  <span *ngIf="!dataService.workspaceLastSubscription.value"
                    class="border rounded p-2 placeholder-text px-5">
                    &mdash;</span>
                </p>
              </div>


              <p class="font-16 gray-primaray-text" i18n="@@activeMembers">Active Members</p>
              <p>
                <span class="border rounded p-2 placeholder-text">
                  {{dataService.workspaceLastSubscription.quantity}}</span>
              </p>

            </div>

          </ng-container>
          <p class="font-20 gray-primaray-text bold-600 pt-5" i18n="@@paymentMethods">Payment Methods</p>
          <!-- <ng-container *ngIf="this.auth.userInfo && this.auth.userInfo.companyMembership.role.toLowerCase() !== 'owner'">
            <p class="gray-text-dark font-18 mb-5">Please contact your company owner if you need details about your company subscription payment methods </p>
          </ng-container>-->

          <div class="mt-lg-5 mb-5 px-4 px-lg-5 py-3 py-lg-4 company-section-with-thick-border border"
            [ngClass]="{'rounded-right': dataService.desktopView && dataService.isSiteLtr(), 'rounded-left': dataService.desktopView && dataService.isSiteRtl(), 'thick-top rounded-bottom': dataService.mobileView}"
            *ngIf="!this.dataService.companyBillingList">
            <div class="row h-100 justify-content-center align-items-center py-3">
              <div class="col-md-7 d-flex">
                <span class="gray-primaray-text font-16" i18n="@@noPayCardInAccount">You don’t have any payment card
                  added to your account</span>
              </div>
              <div class="col-md-5"
                [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
                <button class="btn px-5 py-2 mt-4 mb-3 mt-lg-0 mb-lg-0 rounded text-center  sharp-font bold-500 font-14"
                  [ngClass]="{'w-100 btn-block bg-white primaray-text desktop-btn mt-5': dataService.mobileView, 'btn-primary text-white': dataService.desktopView,
                         'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
                  (click)="addNewPayment()" i18n="@@addPaymentMethod">Add Payment Method</button>
              </div>
            </div>
          </div>


          <div class="container pb-5 items-full-list"
            *ngIf="this.dataService.companyBillingList && this.dataService.companyBillingList.length > 0 && this.dataService.companyBillingExpiryList.length > 0">
            <!--    <div class="row px-3 py-3 primaray-text font-weight-bold font-16 table-header mt-4">
              <div class="col-5">Card</div>
              <div class="col-5"></div>
              <div class="col-2"></div>
            </div>-->

            <div *ngFor="let item of this.dataService.companyBillingList; let i = index">
              <div class="row px-0 px-lg-3 pb-3 pb-lg-4 pt-4 hover-row-list"
                [ngClass]="{'border-bottom': dataService.desktopView, 'rounded border mb-4': dataService.mobileView}">
                <div class="col-lg-5"
                  [ngClass]="{'h-100 justify-content-center align-items-center': dataService.desktopView}">
                  <div class="d-flex">
                    <div class="card_type card_type_{{item.brand}}" [ngClass]="{'text-right': dataService.desktopView,
                  'mr-4 text-right': dataService.isSiteLtr(), 'ml-4 text-left': dataService.isSiteRtl()}"></div>
                    <div [ngClass]="{'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}">
                      <div class="mb-1" *ngIf="item.alias && !item.readOnly"><span class="font-16">{{item.alias}}</span>
                      </div>
                      <div class="mb-1" *ngIf="!item.alias && !item.readOnly"><span class="font-16" i18n="@@noAlias">No
                          alias</span></div>

                      <!--   <ng-container *ngIf="this.auth.userInfo && this.auth.userInfo.companyMembership && this.auth.userInfo.companyMembership.role && auth.userInfo.companyMembership.role.toLowerCase() === 'owner'">
                       <div (click)="editAlias(item.id, item.paymentGatewayProvider, item.alias, item.primary)" class="mb-1 pointer" *ngIf="item.alias && !item.readOnly"><span>{{item.alias}}</span><i class="font-12 icon-pencil-icon p-2 border rounded small ml-3 bg-white"></i></div>
                       <div (click)="editAlias(item.id, item.paymentGatewayProvider, null, item.primary)" class="mb-1 pointer" *ngIf="!item.alias && !item.readOnly"><span>No alias</span><i class="font-12 icon-pencil-icon p-2 border rounded small ml-3 bg-white"></i></div>
                       </ng-container>
                       <ng-container *ngIf="this.auth.userInfo && this.auth.userInfo.companyMembership && this.auth.userInfo.companyMembership.role && auth.userInfo.companyMembership.role.toLowerCase() !== 'owner'">
                         <div (click)="contactOwner()" class="mb-1 pointer" *ngIf="item.alias && !item.readOnly"><span>{{item.alias}}</span><i class="font-12 icon-pencil-icon p-2 border rounded small ml-3 bg-white"></i></div>
                         <div (click)="contactOwner()" class="mb-1 pointer" *ngIf="!item.alias && !item.readOnly"><span>No alias</span><i class="font-12 icon-pencil-icon p-2 border rounded small ml-3 bg-white"></i></div>
                       </ng-container>-->
                      <p class="gray-text mb-0 font-14" dir="ltr"> **** **** **** {{item.lastFour}}</p>
                    </div>
                  </div>
                  <div class="d-flex" *ngIf="dataService.mobileView">
                    <div style="min-width: 66px;"> <span *ngIf="item.primary"
                        class="text-bg py-1 px-2 font-12 gray-text-dark" i18n="@@primary">Primary</span> </div>
                    <!--   <span class="font-14 gray-text mb-0" [ngClass]="{'mt-3' : item.primary}" *ngIf="item.primary || item.readOnly">This card will be used for your subscription billing</span>-->
                    <span *ngIf="this.dataService.companyBillingExpiryList[i]" class="text-danger mb-0"><i
                        class="icon-warning-msg"
                        [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i><span
                        i18n="@@expired">Expired</span></span>
                  </div>
                </div>
                <div class="col-lg-5 pt-2" *ngIf="dataService.desktopView"><span *ngIf="item.primary"
                    class="text-bg py-2 px-3 font-12 gray-text-dark" i18n="@@primary">Primary</span><br />
                  <p class="font-14 gray-text mb-0" [ngClass]="{'mt-3' : item.primary}"
                    *ngIf="item.primary || item.readOnly" i18n="@@cardUsedForSub">This card will be used for your
                    subscription billing</p>
                  <p *ngIf="this.dataService.companyBillingExpiryList[i]" class="text-danger mb-0"><i
                      class="icon-warning-msg"
                      [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i><span
                      i18n="@@expired">Expired</span></p>
                </div>

                <div class="col-lg-2 pt-2"
                  [ngClass]="{'text-left': dataService.isSiteLtr(), 'text-right': dataService.isSiteRtl()}">
                  <div class="d-flex">
                    <div class=" actions">
                      <i class="drop-down{{i}} icon-three-dots font-18 ellipsis gray-text pointer p-3"
                        [ngClass]="{'primary': item.primary, 'primary-expired': item.primary && this.dataService.companyBillingExpiryList[i]}"
                        (click)="showActions = !showActions; selectedIndex = i"></i>
                      <div class=" border shadow font-14 position-absolute bg-white drop"
                        [ngClass]="{'primary-drop': item.primary, 'primary-expired-drop': item.primary && this.dataService.companyBillingExpiryList[i]}"
                        *ngIf="showActions && i === selectedIndex">

                        <ng-container *ngIf="this.auth.isOwner()">
                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="editAlias(item.id, item.paymentGatewayProvider, item.alias, item.primary)"
                            i18n="@@edit">Edit</p>

                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="!item.primary && !item.readOnly"
                            (click)="setAsPrimary(item.id, item.paymentGatewayProvider)" i18n="@@setPrimary">Set as
                            primary</p>


                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="!item.primary && item.readOnly" (click)="addNewPayment()" i18n="@@changeCard">Change
                            Card</p>

                          <p class="pointer py-2 placeholder-text prevent-pointer-event"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="item.primary || item.readOnly" i18n="@@setPrimary">Set as primary</p>


                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="!item.primary && !item.readOnly" (click)="removePayment(item.id);"
                            i18n="@@deleteBtn">Delete</p>

                          <p class="pointer py-2 placeholder-text prevent-pointer-event"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="item.primary || item.readOnly" i18n="@@deleteBtn">Delete</p>
                        </ng-container>


                        <ng-container *ngIf="!this.auth.isOwner()">
                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            (click)="contactOwner()" i18n="@@edit">Edit</p>

                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="!item.primary && !item.readOnly" (click)="contactOwner()" i18n="@@setPrimary">Set as
                            primary</p>


                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="!item.primary && item.readOnly" (click)="contactOwner()" i18n="@@changeCard">Change
                            Card</p>

                          <p class="pointer py-2 placeholder-text prevent-pointer-event"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="item.primary || item.readOnly" i18n="@@setPrimary">Set as primary</p>


                          <p class="pointer py-2"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="!item.primary && !item.readOnly" (click)="contactOwner()" i18n="@@deleteBtn">Delete
                          </p>

                          <p class="pointer py-2 placeholder-text prevent-pointer-event"
                            [ngClass]="{'pl-4': dataService.isSiteLtr(), 'pr-4': dataService.isSiteRtl()}"
                            *ngIf="item.primary || item.readOnly" i18n="@@deleteBtn">Delete</p>
                        </ng-container>

                      </div>
                    </div>
                  </div>
                  <!-- <p  *ngIf="!item.primary && item.readOnly" class="action-hide-show ml-3"><a class="blue-text" (click)="addNewPayment()">Change Card</a></p>
                  <p  *ngIf="!item.primary && !item.readOnly" class="action-hide-show ml-3"><a class="blue-text" (click)="removePayment(item.id);">Delete</a></p>-->
                </div>
              </div>

            </div>


          </div>



          <!--   <div *ngIf="dataService.mobileView && this.dataService.companyBillingList && this.dataService.companyBillingList.length > 0 && this.dataService.companyBillingExpiryList.length > 0">
            <div *ngFor="let item of this.dataService.companyBillingList; let i = index">
              <div class="p-3 card-bg mb-4">
                <div class="d-flex">
                  <div class="mr-4 text-right card_type card_type_{{item.brand}}"></div>
                  <div class="w-100">
                    <div class="d-flex">
                      <div class="pointer title-font-style" *ngIf="item.alias"><span>{{item.alias}}</span></div>
                      <div class="pointer title-font-style" *ngIf="!item.alias"><span>No alias</span></div>
                    </div>
                    <p class="gray-text mb-2 body-font-style"> **** **** **** {{item.lastFour}}</p>
                    <span *ngIf="item.primary" class="text-center ml-auto border rounded py-1 px-4 font-weight-bold placeholder-text body-font-style bg-white">Primary<br/></span>
                    <p class="font-14 gray-text mb-2" [ngClass]="{'mt-3' : item.primary}" *ngIf="item.primary || item.readOnly">This card will be used for your subscription billing</p>
                    <p *ngIf="this.dataService.companyBillingExpiryList[i]" class="text-danger mb-0 body-font-style"><i class="icon-warning-msg mr-3"></i>Expired</p>
                  </div>
                </div>
                  <div class="d-flex mb-0 mt-4">
                    <a *ngIf="!item.primary && !item.readOnly" class="blue-text action-font-style left-action" (click)="removePayment(item.id);">Delete</a>
                    <a *ngIf="!item.primary && item.readOnly" class="blue-text action-font-style right-action ml-auto" (click)="addNewPayment();">Change Card</a>
                    <ng-container *ngIf="this.auth.isOwner()">
                    <a *ngIf="item.alias && !item.readOnly" class="blue-text action-font-style right-action ml-auto" (click)="editAlias(item.id, item.paymentGatewayProvider, item.alias, item.primary)">Edit</a>
                    <a *ngIf="!item.alias && !item.readOnly" class="blue-text action-font-style right-action ml-auto"  (click)="editAlias(item.id, item.paymentGatewayProvider, null, item.primary)">Edit</a>
                    </ng-container>
                    <ng-container *ngIf="!this.auth.isOwner()">
                      <a *ngIf="item.alias && !item.readOnly" class="blue-text action-font-style right-action ml-auto" (click)="contactOwner()">Edit</a>
                      <a *ngIf="!item.alias && !item.readOnly" class="blue-text action-font-style right-action ml-auto"  (click)="contactOwner()">Edit</a>
                    </ng-container>
                  </div>

              </div>

            </div>


          </div>-->







          <div class="row">
            <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-6': dataService.mobileView}"
              *ngIf="this.auth.isOwner()">
              <button (click)="addNewPayment()"
                class="btn-outline-blue btn px-5 w-100 mb-3 font-14 sharp-font bold-500"> <span class="px-lg-4"
                  i18n="@@addNewCard">Add New Card</span> </button>
            </div>
            <div [ngClass]="{'col-auto': dataService.desktopView, 'col-md-6': dataService.mobileView,
            'pr-lg-5': dataService.isSiteLtr(), 'pl-lg-5': dataService.isSiteRtl()}">
              <button
                (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing/history']);"
                class="btn-outline-blue btn px-5 w-100 mb-3 font-14 sharp-font bold-500" i18n="@@viewBillingHistory">
                View Billing History </button>
            </div>
          </div>


          <div
            *ngIf="this.dataService.workspaceSubscriptionDetails &&  this.dataService.workspaceSubscriptionDetails.current && this.auth.isOwner()">
            <ng-container
              *ngIf="this.auth.companyInfo && this.auth.companyInfo.inactive === false  &&  this.dataService.workspaceSubscriptionDetails.current.value ||  this.dataService.workspaceSubscriptionDetails.next">
              <p class="mt-5 pt-5 gray-primaray-text bold-500" i18n="@@SubCancel">Subscription Cancellation</p>
              <p class="gray-text-dark"><ng-container i18n="@@cancelYourSub">Cancel your
                  subscription</ng-container>&nbsp;<a class="blue-text"
                  (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing/cancel-subscription']);"
                  i18n="@@here">here</a>&nbsp;<ng-container i18n="@@orContactUsAt">or contact us
                  at</ng-container>&nbsp;<a href="mailto:support@safarhub.com?Subject=Question"
                  target="_top">support@safarhub.com</a></p>
            </ng-container>
          </div>
        </div>
      </div>



    </div>
  </mat-sidenav-content>
</mat-sidenav-container>