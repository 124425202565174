import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { AuthServiceMain } from "../../../auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-workspace-deactivated-account",
  templateUrl: "./workspace-deactivated-account.component.html",
  styleUrls: ["./workspace-deactivated-account.component.scss"],
})
export class WorkspaceDeactivatedAccountComponent implements OnInit {
  
  image = new FormData();
  data;
  submitting = false;
  usersList;
  searchUrl;
  dataUpcoming;
  upcomingBooking;
  urlArray;
  companyID;

  constructor(
    public router: Router,
    public dataService: DataService,
    public auth: AuthServiceMain
  ) {}
  upcomingBookingObj = {
    bookingStatus: "CONFIRMED",
    includeUpcoming: true,
    includePast: false,
  };

  ngOnInit(): void {
    this.dataService.getLastSubscriptionDetails();
    this.dataService.companyListBillingService();
    this.urlArray = this.router.url.split("/");
    this.companyID = this.urlArray[1];
    this.dataService.inactiveWorkspace = true;
    this.dataService
      .listBookings(
        this.upcomingBookingObj.bookingStatus,
        this.upcomingBookingObj.includeUpcoming,
        this.upcomingBookingObj.includePast
      )
      .subscribe(
        (data) => {
          this.dataUpcoming = data;
          if (this.dataUpcoming.errorCode === 0) {
            this.upcomingBooking = this.dataUpcoming.data;

            this.dataService.hotellistLoader = false;
          } else {
            this.dataService.messages(this.dataUpcoming.message, "error");
            this.dataService.hotellistLoader = false;
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
          this.dataService.hotellistLoader = false;
        }
      );
  }
  /*formatDailyDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }*/
  /*formatItemDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/
  viewDetails(obj, bookingType) {
    if (this.dataService.isCompany()) {
      this.router.navigate(
        [
          "/" +
            this.auth.companyInfo.id +
            "/" +
            bookingType.toLowerCase() +
            "/booking/details",
        ],
        { queryParams: obj }
      );
    } else {
    }
  }
  /* formatDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }*/
}
