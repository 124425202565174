import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { SwiperComponent } from "ngx-swiper-wrapper";
import { DataService } from "../data.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class ModalComponent implements OnInit {
  smallWidthModalsArray = [
    "CountryCode",
    "createPolicy",
    "createNewTeam",
    "cancelSubscription",
    "editBillingPlan",
    "editBilling",
    "switchPlan",
    "editUserCard",
    "deleteRewardsProgram",
    "companyHotelShare",
    "companySendBookingVoucher",
    "passMembershipCancellation",
    "addTeamMembers",
    "removePolicy",
    "removeTeam",
    "manageCosts",
  ];
  configLb = {
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    },
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 0,
    observer: true,
  };
  form: FormGroup;
  isCollapsed = false;
  selectedIndex = 0;
  @ViewChild("thumbview", { static: false }) thumbview: SwiperComponent;

  constructor(public Modal: DataService) {
    this.Modal.SliderUpdate$.subscribe(() => {
      setTimeout(() => {
        if (this.Modal.modalData && this.Modal.modalData.images) {
          if (this.thumbview) {
            this.thumbview.directiveRef.update();
          }
        }
      }, 500);
    });
  }

  ngOnInit() {
    this.Modal.errorMsg = "";
  }

  smallWidth() {
    this.smallWidthModalsArray.forEach((element) => {
      return this.Modal.modalName !== element;
    });
  }

  onIndexChange(e) {
    this.selectedIndex = e;
    this.thumbview.directiveRef.init();
  }
  sliderindexSet(e) {
    this.thumbview.directiveRef.setIndex(e, 110, true);
    this.thumbview.directiveRef.init();
  }
}
