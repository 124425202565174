import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import { SidenavService} from '../../workspace-dashboard/sidenav.service';
import { onMainContentChange } from '../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-workspace-settings',
  templateUrl: './workspace-settings.component.html',
  styleUrls: ['./workspace-settings.component.scss'],
  animations: [ onMainContentChange ]
})
export class WorkspaceSettingsComponent implements OnInit {
 // public onSideNavChange: boolean;
  constructor( public dataService: DataService, private sidenavService: SidenavService, public router: Router) {
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  env = environment;
  ngOnInit(): void {
  }

}
