import { FooterComponent } from "./common/footer/footer.component";
import { ModalComponent } from "./services/modal/modal.component";
import { BottomSheetComponent } from "./services/bottom-sheet/bottom-sheet.component";
import { SendBookingVoucherComponent } from "./controls/send-booking-voucher/send-booking-voucher.component";
import { RedirectComponent } from "./misc/redirect/redirect.component";
import { CookiesBarComponent } from "./controls/cookies-bar/cookies-bar.component";
import { SnakBarComponent } from "./services/snak-bar/snak-bar.component";
import { ReleaseNumberComponent } from "./misc/release-number/release-number.component";
import { ModalNotificationComponent } from "./services/modal-notification/modal-notification.component";
import { AppComponent } from "./app.component";
import { PaymentModule } from "./payment/payment.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AgmCoreModule } from "@agm/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { RouterModule, UrlSerializer } from "@angular/router";
import { SwiperModule } from "ngx-swiper-wrapper";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { SharedModule } from "./shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { GoogleAnalytics4Service } from "./google-analytics4.service";
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
} from "angularx-social-login";
import { AuthInterceptorService } from "./auth/auth-interceptor.service";
import { TagInputModule } from "ngx-chips";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { AngularFireRemoteConfigModule } from "@angular/fire/remote-config";
import { DeviceDetectorModule } from "ngx-device-detector";
import { CorporateTravelModule } from "./corporate-travel/corporate-travel.module";
import { ManageWorkspacePopupComponent } from "./common/manage-workspace-popup/manage-workspace-popup.component";
import { WorkspaceHeaderComponent } from "./common/workspace-header/workspace-header.component";
import { RightSideDrawerComponent } from "./services/right-side-drawer/right-side-drawer.component";
import { SlackFooterComponent } from "./common/slack-footer/slack-footer.component";
import { CommonModule, DatePipe } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { registerLocaleData } from "@angular/common";
import localeAr from "@angular/common/locales/ar";
import { LanguageInterceptor } from "./interceptors/language.interceptor";
import { CustomUrlSerializer } from "./custom-url-serializer";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { CustomDatepickerI18nService } from "./services/datepicker-localization/custom-datepicker-i18n.service";

registerLocaleData(localeAr);
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ModalComponent,
    BottomSheetComponent,
    SendBookingVoucherComponent,
    RedirectComponent,
    CookiesBarComponent,
    SnakBarComponent,
    ReleaseNumberComponent,
    ModalNotificationComponent,
    ManageWorkspacePopupComponent,
    WorkspaceHeaderComponent,
    RightSideDrawerComponent,
    SlackFooterComponent,
  ],
  imports: [
    SharedModule.forRoot(),
    AngularFireAuthModule,
    AngularFireRemoteConfigModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    ScrollToModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SwiperModule,
    SocialLoginModule,
    RouterModule,
    ScrollingModule,
    AgmCoreModule,
    DragDropModule,
    PaymentModule,
    TagInputModule,
    CorporateTravelModule,
    ChartsModule,
    DeviceDetectorModule.forRoot(),
    CommonModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    DatePipe,
    GoogleAnalyticsService,
    GoogleAnalytics4Service,
    // change default locale to ar from here and from polyfills.ts
    { provide: LOCALE_ID, useValue: localStorage.getItem("locale") || "en-US" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    { 
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18nService
    },
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,
    },
  ],
  exports: [FooterComponent, CommonModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "806015619199-01enfnb5b3vvlpkiq8tqbpknc448m2mj.apps.googleusercontent.com"
      ),
    },
  ]);
  return config;
}
