<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'initial-margin-value':
        this.dataService.initialMarginValue && this.dataService.desktopView
    }"
    [@onMainContentChange]="
      this.dataService.onSideNavChange
        ? 'open' + dataService.siteDirection
        : 'close' + dataService.siteDirection
    "
  >
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div
        class="container mt-4 mt-lg-5 mb-5 extra-padding"
        *ngIf="this.dataService.approvalData"
      >
        <div class="row mx-lg-4">
          <div class="col">
            <div class="pb-2 pb-lg-5 mb-3">
              <a
                class="font-16 blue-text text-underline"
                (click)="
                  this.router.navigate([
                    '/' + this.auth.companyInfo.id + '/approvals'
                  ])
                "
                i18n="@@menuItemApprovals"
                >Approvals</a
              >
              <span class="font-16 gray-text-dark">
                / {{ this.dataService.approvalData.requesterFirstname }}
              </span>
              <span class="font-16 gray-text-dark"
                >({{
                  this.dataService.approvalHotelData.cityName
                    ? this.dataService.approvalHotelData.cityName
                    : ""
                }}{{
                  this.dataService.approvalHotelData.stateCode &&
                  this.dataService.approvalHotelData.cityName
                    ? ", "
                    : ""
                }}{{
                  this.dataService.approvalHotelData.stateCode
                    ? this.dataService.approvalHotelData.stateCode
                    : ""
                }})
              </span>
            </div>

            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'approved'
              "
              i18n="@@approved"
              class="bold-600 font-16 green-text"
            >
              Approved
            </p>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'pending_approval'
              "
              i18n="@@pendingApproval"
              class="bold-600 font-16 yellow-text"
            >
              Pending Approval
            </p>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'disapproved'
              "
              class="bold-600 font-16 text-danger mb-0"
              i18n="@@declined"
            >
              Declined
            </p>
            <div
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() ===
                'disapproved'
              "
            >
              <ng-container
                *ngFor="let item of this.dataService.approvalData.operations"
              >
                <p class="font-12 gray-text">{{ item.reason }}</p>
              </ng-container>
            </div>
            <p
              *ngIf="
                this.dataService.approvalData.status.toLowerCase() === 'expired'
              "
              class="bold-600 font-16 gray-text"
              i18n="@@expired"
            >
              Expired
            </p>
          </div>
        </div>
        <div class="row mx-lg-4">
          <div class="col-lg-8 pt-3">
            <div class="border rounded pt-4 px-lg-4">
              <div
                class="px-4 px-lg-0"
                [ngClass]="{ 'd-flex': dataService.desktopView }"
              >
                <div
                  class="d-flex"
                  [ngClass]="{
                    'mr-5 pr-4':
                      dataService.desktopView && dataService.isSiteLtr(),
                    'ml-5 pl-4':
                      dataService.desktopView && dataService.isSiteRtl()
                  }"
                >
                  <div>
                    <img
                      width="50"
                      height="50"
                      loading="lazy"
                      *ngIf="dataService.approvalData.requesterProfileImageUrl"
                      class="user-img d-inline-block my-3"
                      onError="this.src='assets/img/default-user.png'"
                      [src]="dataService.approvalData.requesterProfileImageUrl"
                    />
                    <div
                      *ngIf="!dataService.approvalData.requesterProfileImageUrl"
                      class="name-first-letters requester-img my-3"
                      [ngStyle]="{
                        'background-color':
                          dataService.approvalData
                            .requesterProfileImageBackgroundHexColor
                      }"
                    >
                      {{
                        dataService.approvalData.requesterFirstname
                          .substring(0, 1)
                          .toUpperCase()
                      }}{{
                        dataService.approvalData.requesterLastname
                          .substring(0, 1)
                          .toUpperCase()
                      }}
                    </div>
                  </div>
                  <div class="pt-4">
                    <p
                      class="gray-primaray-text bold-600 font-16 mb-0"
                      [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl(),
                        'mt-2': !dataService.approvalData.requesterTeamName,
                        'dotted-text dotted-text-direction':
                          dataService.mobileView
                      }"
                    >
                      <span class="pr-2">{{
                        this.dataService.approvalData.requesterFirstname
                      }}</span
                      >{{ this.dataService.approvalData.requesterLastname }}
                    </p>
                    <p
                      *ngIf="this.dataService.approvalData.requesterTeamName"
                      class="gray-text font-14"
                      [ngClass]="{
                        'ml-3': dataService.isSiteLtr(),
                        'mr-3': dataService.isSiteRtl()
                      }"
                    >
                      {{ this.dataService.approvalData.requesterTeamName }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="gray-text mt-4 px-4 px-lg-0">
                <ng-container i18n="@@bookedFor">Booked for</ng-container>
                {{ dataService.approvalData.guestFirstname }}
                {{ dataService.approvalData.guestLastname }}
              </div>
              <div class="price-container pb-4 gray-primaray-text font-16 mt-3">
                <div class="px-4 px-lg-0">
                  <p class="bold-600 mb-2" i18n="@@tripSummary">Trip Summary</p>

                  <p
                    class="mb-1"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@approvalAvgNightlyPrice"
                  >
                    Average Nightly Price
                  </p>
                  <p class="gray-text mb-1">
                    ${{
                      this.dataService.approvalHotelData.rooms[0].rates[0]
                        .averageNightlySellingPrice
                    }}
                    <i
                      *ngIf="this.dataService.approvalData.limitExceeded"
                      class="icon-warning-msg yellow-text font-12"
                    ></i>
                  </p>

                  <p
                    class="mb-1 mt-4"
                    [ngClass]="{
                      'mr-0': dataService.isSiteLtr(),
                      'ml-0': dataService.isSiteRtl()
                    }"
                    i18n="@@totalPrice"
                  >
                    Total Price
                  </p>
                  <p class="gray-text mb-1">
                    ${{
                      this.dataService.approvalHotelData.rooms[0].rates[0]
                        .finalSellingPrice
                    }}
                  </p>
                </div>
                <hr />
                <div class="px-4 px-lg-0">
                  <ng-container *ngIf="this.dataService.approvalData.policy">
                    <p class="bold-600 mt-4 mb-2">
                      <ng-container i18n="@@travelPolicy"
                        >Travel Policy</ng-container
                      >&nbsp;
                      <span class="font-weight-normal font-14"
                        >({{ this.dataService.approvalData.policy.name }})</span
                      >
                    </p>

                    <ng-container
                      *ngIf="
                        !dataService.approvalData.policy.hotelBookingNotAllowed
                      "
                    >
                      <p
                        class="mb-1 font-16 gray-text"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-check green-text font-12"
                          *ngIf="!this.dataService.approvalData.limitExceeded"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <i
                          class="icon-close-light text-danger font-12"
                          *ngIf="this.dataService.approvalData.limitExceeded"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>

                        <span i18n="@@nightlyPriceCap">Nightly price cap</span
                        >:<span
                          class="px-2"
                          *ngIf="
                            this.dataService.approvalData.appliedException &&
                            this.dataService.approvalData.appliedException
                              .avgNightlyPriceLimit
                          "
                          >${{
                            this.dataService.approvalData.appliedException
                              .avgNightlyPriceLimit
                          }}</span
                        >
                        <span
                          class="px-2"
                          *ngIf="
                            !this.dataService.approvalData.appliedException &&
                            this.dataService.approvalData.policy
                              .companyPolicyHotelRules &&
                            this.dataService.approvalData.policy
                              .companyPolicyHotelRules.avgNightlyPriceLimit
                          "
                          >${{
                            this.dataService.approvalData.policy
                              .companyPolicyHotelRules.avgNightlyPriceLimit
                          }}</span
                        >
                      </p>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        dataService.approvalData.policy.hotelBookingNotAllowed
                      "
                    >
                      <p
                        class="mb-1"
                        [ngClass]="{
                          'mr-0': dataService.isSiteLtr(),
                          'ml-0': dataService.isSiteRtl()
                        }"
                      >
                        <i
                          class="icon-close-light text-danger font-12"
                          [ngClass]="{
                            'pr-2': dataService.isSiteLtr(),
                            'pl-2': dataService.isSiteRtl()
                          }"
                        ></i>
                        <span
                          i18n="@@hotelBookingAllowed"
                          class="font-16 gray-text"
                          >Hotel booking allowed</span
                        >
                      </p>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="border rounded px-4 pt-4 mt-4">
              <p
                class="font-16 gray-primaray-text bold-600"
                i18n="@@cancellationPolicies"
              >
                Cancellation Policies
              </p>

              <p
                class="gray-text-dark font-14 cancel-text"
                *ngFor="
                  let policy of this.dataService.approvalHotelData.rooms[0]
                    .rates[0].cancellationPolicies
                "
              >
                {{ policy.description }}
              </p>

              <p
                class="non-refundable gray-text-dark"
                *ngIf="
                  !this.dataService.approvalHotelData.rooms[0].rates[0]
                    .cancellationPolicies ||
                  (this.dataService.approvalHotelData.rooms[0].rates[0]
                    .cancellationPolicies &&
                    !this.dataService.approvalHotelData.rooms[0].rates[0]
                      .cancellationPolicies.length)
                "
                i18n="@@nonrefundable"
              >
                Non Refundable
              </p>
              <ng-container
                *ngIf="
                  this.dataService.approvalData.status.toLowerCase() !==
                    'disapproved' &&
                  this.dataService.approvalData.status.toLowerCase() !==
                    'expired'
                "
              >
                <p
                  class="font-16 gray-primaray-text bold-600 mt-4"
                  i18n="@@paymentMethod"
                >
                  Payment Method
                </p>
                <p
                  class="font-14 gray-text-dark"
                  *ngIf="
                    this.dataService.approvalData.status.toLowerCase() ===
                    'pending_approval'
                  "
                >
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'STRIPE'
                    "
                  >
                    <ng-container i18n="@@cardEndsWith"
                      >Card ending with</ng-container
                    >
                    {{ this.dataService.approvalData.payment.lastFour }}
                    <ng-container i18n="@@willCharged"
                      >will be charged with total of</ng-container
                    >
                    ${{
                      this.dataService.approvalHotelData.rooms[0].rates[0]
                        .finalSellingPrice | number : "1.0-2"
                    }}
                  </span>
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'PAYPAL'
                    "
                  >
                    <ng-container i18n="@@payPalWillCharge"
                      >will be charged to PayPal</ng-container
                    >
                    ({{ this.dataService.approvalData.payment.email }})
                  </span>
                </p>
                <p
                  class="font-14 gray-text-dark"
                  *ngIf="
                    this.dataService.approvalData.status.toLowerCase() ===
                    'approved'
                  "
                >
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'STRIPE'
                    "
                  >
                    ${{
                      this.dataService.approvalHotelData.rooms[0].rates[0]
                        .finalSellingPrice | number : "1.0-2"
                    }}
                    <ng-container i18n="@@charged"
                      >charged to card ending with</ng-container
                    >
                    {{ this.dataService.approvalData.payment.lastFour }}
                  </span>
                  <span
                    *ngIf="
                      this.dataService.approvalData.payment.channelProvider ===
                      'PAYPAL'
                    "
                  >
                    <ng-container i18n="@@payPalCharged"
                      >Charged to PayPal</ng-container
                    >
                    ({{ this.dataService.approvalData.payment.email }})
                  </span>
                </p>
              </ng-container>
            </div>
            <div class="mt-4" *ngIf="dataService.desktopView">
              <div
                class="mb-2 pt-4"
                *ngFor="let msg of this.dataService.approvalData.operations"
              >
                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'"
                >
                  <div
                    class="d-flex"
                    [ngClass]="{
                      'mr-5 pr-4': dataService.isSiteLtr(),
                      'ml-5 pl-4': dataService.isSiteRtl()
                    }"
                  >
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.requesterProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .requesterProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.requesterFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.requesterLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                      >
                        <span class="pr-2">{{
                          this.dataService.approvalData.requesterFirstname
                        }}</span
                        >{{ this.dataService.approvalData.requesterLastname }}
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark">
                    {{ msg.comment }}
                  </div>
                </div>

                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'REQUEST_MORE_INFO'"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="dataService.approvalData.approverProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="dataService.approvalData.approverProfileImageUrl"
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.approverProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .approverProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.approverFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.approverLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                        i18n="@@me"
                      >
                        Me
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark gray-bg">
                    {{ msg.comment }}
                  </div>
                </div>
              </div>
            </div>

            <form
              class="mb-5"
              [formGroup]="approvalForm"
              *ngIf="
                dataService.desktopView &&
                this.dataService.approvalData.status.toLowerCase() ===
                  'pending_approval'
              "
            >
              <textarea
                name="comment"
                id="comment"
                formControlName="comment"
                class="form-control pt-3 mt-5"
                i18n-placeholder="@@LeaveComments"
                placeholder="Leave your comments here"
                rows="6"
              ></textarea>
              <p class="text-danger font-12">{{ errorMsg }}</p>

              <div class="d-flex">
                <div
                  class="actions"
                  [ngClass]="{
                    'ml-auto': dataService.isSiteLtr(),
                    'mr-auto': dataService.isSiteRtl()
                  }"
                >
                  <button
                    class="approval-drop-down mt-5 btn-block blue_btn font-14 bold-500 px-4 d-flex"
                    (click)="approvalActions = !approvalActions"
                  >
                    <span
                      class="mx-auto sharp-font"
                      *ngIf="
                        this.dataService.approvalData.status.toLowerCase() ===
                        'pending_approval'
                      "
                      i18n="@@pending"
                      >Pending</span
                    >
                    <i
                      class="icon-double-arrows-down white padding-arrow font-12"
                    ></i>
                  </button>
                  <div
                    class="border rounded shadow font-14 pt-3 position-absolute bg-white drop"
                    *ngIf="approvalActions"
                  >
                    <p
                      class="pointer"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }"
                      (click)="
                        approve(
                          this.dataService.approvalData.id,
                          this.dataService.approvalHotelData.rooms[0].rates[0]
                            .averageNightlySellingPrice
                        )
                      "
                      i18n="@@approve"
                    >
                      Approve
                    </p>
                    <p
                      class="pointer"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }"
                      (click)="
                        disapprove(
                          this.dataService.approvalData.id,
                          this.dataService.approvalData.requesterFirstname +
                            ' ' +
                            this.dataService.approvalData.requesterLastname
                        )
                      "
                      i18n="@@decline"
                    >
                      Decline
                    </p>
                    <p
                      class="pointer"
                      [ngClass]="{
                        'pl-4': dataService.isSiteLtr(),
                        'pr-4': dataService.isSiteRtl()
                      }"
                      (click)="approvalSubmitMoreInfo()"
                      i18n="@@clarify"
                    >
                      Request Clarification
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
            class="col-lg-4 hotel-details-side pt-3"
            *ngIf="this.dataService.approvalHotelData"
          >
            <div class="p-0 mb-4 white-box">
              <div id="map">
                <agm-map
                  [latitude]="this.dataService.approvalHotelData.latitude"
                  [longitude]="this.dataService.approvalHotelData.longitude"
                  [zoom]="16"
                  [disableDefaultUI]="false"
                  [zoomControl]="false"
                >
                  <agm-marker
                    [latitude]="this.dataService.approvalHotelData.latitude"
                    [longitude]="this.dataService.approvalHotelData.longitude"
                    [label]="this.dataService.approvalLabelOptions"
                    iconUrl="assets/img/union-wide1.png"
                  >
                  </agm-marker>
                </agm-map>
              </div>
              <div class="p-4">
                <div class="border-bottom pb-3">
                  <h4 class="primaray-text bold-600">
                    {{ this.dataService.approvalHotelData.name }}
                  </h4>
                  <div class="address-inner gray-text">
                    <i class="icon-locator gray-text"></i>
                    {{
                      dataService.approvalHotelData.address
                        ? dataService.approvalHotelData.address + ", "
                        : ""
                    }}
                    {{
                      this.dataService.approvalHotelData.cityName
                        ? this.dataService.approvalHotelData.cityName
                        : ""
                    }}{{
                      this.dataService.approvalHotelData.stateCode &&
                      this.dataService.approvalHotelData.cityName
                        ? ", "
                        : ""
                    }}{{
                      this.dataService.approvalHotelData.stateCode
                        ? this.dataService.approvalHotelData.stateCode
                        : ""
                    }}{{
                      (this.dataService.approvalHotelData.stateCode ||
                        this.dataService.approvalHotelData.cityName) &&
                      this.dataService.approvalHotelData.countryName
                        ? ", "
                        : ""
                    }}{{
                      this.dataService.approvalHotelData.countryName
                        ? this.dataService.approvalHotelData.countryName
                        : ""
                    }}
                  </div>
                </div>

                <div
                  class="pb-2"
                  [ngClass]="{
                    'border-bottom pt-2':
                      this.dataService.approvalFacilitiesFeaturedavailabile ===
                      true
                  }"
                >
                  <ul
                    *ngIf="this.dataService.approvalHotelData"
                    class="facilities-main mb-0 list-unstyled"
                  >
                    <ng-container
                      *ngFor="
                        let item of this.dataService.approvalHotelData
                          .myFacilities
                      "
                    >
                      <ng-container *ngIf="item.featured">
                        <li
                          class="facilities-main-item d-inline-block mb-2 mt-2"
                        >
                          <span
                            class="d-block"
                            [ngClass]="{
                              'pr-5': dataService.isSiteLtr(),
                              'pl-5': dataService.isSiteRtl()
                            }"
                          >
                            <i [class]="'icon-' + item.hrsCode"></i>
                            {{ item.title }}
                          </span>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <div class="px-4">
                <div class="margin_bottom">
                  <p
                    class="mb-0 font-16"
                    *ngIf="
                      this.dataService.approvalHotelData.rooms[0].rates[0]
                        .roomName
                    "
                  >
                    {{
                      dataService.camelCaseText(
                        this.dataService.approvalHotelData.rooms[0].rates[0]
                          .roomName
                      )
                    }}
                  </p>
                </div>
                <div
                  *ngIf="
                    this.dataService.approvalHotelData.lowestSellingPriceRate &&
                    this.dataService.approvalHotelData.lowestSellingPriceRate
                      .hrsBoardCode != 'RO'
                  "
                  class="mb-3"
                >
                  <i
                    [ngClass]="
                      'icon-' +
                      this.dataService.approvalHotelData.lowestSellingPriceRate
                        .boardCode
                    "
                  >
                  </i
                  >{{
                    this.dataService.approvalHotelData.lowestSellingPriceRate
                      .boardName
                  }}
                </div>
                <ng-container>
                  <p class="mb-0">
                    <span class="check-date-margin"
                      ><ng-container i18n="@@checkInNoDash"
                        >Check In</ng-container
                      >:</span
                    >
                    {{
                      this.dataService.approvalBookingInfo.searchRequest.checkIn
                        | date : "EE, MMM d, y"
                    }}
                  </p>
                  <p>
                    <span
                      [ngClass]="{
                        'mr-2': dataService.isSiteLtr(),
                        'ml-2': dataService.isSiteRtl()
                      }"
                      ><ng-container i18n="@@checkOutNoDash"
                        >Check Out</ng-container
                      >:</span
                    >
                    {{
                      this.dataService.approvalBookingInfo.searchRequest
                        .checkOut | date : "EE, MMM d, y"
                    }}
                  </p>
                </ng-container>

                <p class="margin_top">
                  <span
                    class="badge badge-secondary d-inline-block font-12"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                  >
                    <span
                      class="night-cont"
                      *ngIf="
                        diffInDays(
                          this.dataService.approvalBookingInfo.searchRequest
                            .checkOut,
                          this.dataService.approvalBookingInfo.searchRequest
                            .checkIn
                        ) > 1;
                        else Night
                      "
                    >
                      <ng-container
                        *ngIf="
                          diffInDays(
                            this.dataService.approvalBookingInfo.searchRequest
                              .checkOut,
                            this.dataService.approvalBookingInfo.searchRequest
                              .checkIn
                          ) > 2
                        "
                      >
                        {{
                          diffInDays(
                            this.dataService.approvalBookingInfo.searchRequest
                              .checkOut,
                            this.dataService.approvalBookingInfo.searchRequest
                              .checkIn
                          )
                        }}
                        <ng-container i18n="@@nights"
                          >nights</ng-container
                        ></ng-container
                      >
                      <ng-container
                        i18n="@@twoNights"
                        *ngIf="
                          diffInDays(
                            this.dataService.approvalBookingInfo.searchRequest
                              .checkOut,
                            this.dataService.approvalBookingInfo.searchRequest
                              .checkIn
                          ) === 2
                        "
                      >
                        2 nights</ng-container
                      >
                    </span>

                    <span class="night-cont">
                      <ng-template #Night>
                        <ng-container i18n="@@night">1 night</ng-container>
                      </ng-template>
                    </span>
                    <ng-container i18n="@@stay">Stay</ng-container>
                  </span>
                  <span
                    class="badge badge-secondary d-inline-block font-12"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                    *ngIf="this.dataService.approvalPaxes + 1 > 1"
                  >
                    <span
                      class="night-cont font-12"
                      *ngIf="this.dataService.approvalPaxes + 1 > 2"
                      >{{ this.dataService.approvalPaxes + 1 }}
                      <ng-container i18n="@@guests">Guests</ng-container></span
                    >
                    <span
                      class="night-cont font-12"
                      *ngIf="this.dataService.approvalPaxes + 1 === 2"
                      >{{ this.dataService.approvalPaxes + 1 }}
                      <ng-container i18n="@@twoGuests"
                        >Guests</ng-container
                      ></span
                    >
                  </span>
                  <span
                    class="badge badge-secondary d-inline-block font-12"
                    [ngClass]="{
                      'mr-1': dataService.isSiteLtr(),
                      'ml-1': dataService.isSiteRtl()
                    }"
                    *ngIf="this.dataService.approvalPaxes + 1 === 1"
                  >
                    <span class="night-cont font-12"
                      >1 <ng-container i18n="@@guest">Guest</ng-container></span
                    >
                  </span>
                </p>
              </div>
            </div>
            <div class="p-0 mb-4 white-box">
              <div class="px-4 py-3">
                <h5
                  class="font-18 bold-600 gray-primaray-text mb-3"
                  i18n="@@priceSummary"
                >
                  Price Summary
                </h5>

                <div
                  class="daily"
                  *ngFor="
                    let daily of this.dataService.approvalHotelData.rooms[0]
                      .rates[0].dailyRates
                  "
                >
                  <div class="d-flex pb-1 gray-primaray-text">
                    <span class="font-14">
                      {{ dataService.formatDateEEMMMdy(daily.date) }}</span
                    >
                    <span
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                    >
                      <span class="currency font-14">$</span
                      ><span class="amount font-14">{{
                        daily.sellingPrice | number
                      }}</span>
                    </span>
                  </div>
                </div>

                <div
                  class="discount"
                  *ngIf="
                    !this.dataService.approvalHotelData.rooms[0].rates[0]
                      .taxIncluded &&
                    this.dataService.approvalHotelData.rooms[0].rates[0].tax
                  "
                >
                  <div class="d-flex pb-1 gray-primaray-text">
                    <span class="font-14" i18n="@@taxAndFees">
                      Tax and fees</span
                    >
                    <span
                      [ngClass]="{
                        'ml-auto': dataService.isSiteLtr(),
                        'mr-auto': dataService.isSiteRtl()
                      }"
                      ><span class="currency font-14">$</span
                      ><span class="amount font-14">{{
                        this.dataService.approvalHotelData.rooms[0].rates[0].tax
                      }}</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="rounded-bottom py-3 px-4 white-box-footer">
                <div class="d-flex font-21 bold-600">
                  <span class="primaray-text" i18n="@@Total">Total </span>
                  <span
                    class="primaray-text"
                    [ngClass]="{
                      'ml-auto': dataService.isSiteLtr(),
                      'mr-auto': dataService.isSiteRtl()
                    }"
                  >
                    <span class="currency">$</span
                    ><span class="amount">{{
                      dataService.approvalHotelData.rooms[0].rates[0]
                        .finalSellingPrice
                    }}</span></span
                  >
                </div>
              </div>
            </div>
            <app-cost-reporting-details
              [costCentersIds]="
                dataService.approvalData?.approvalRequest?.costCenterId
                  ? [dataService.approvalData?.approvalRequest?.costCenterId]
                  : ''
              "
              [labelsIds]="
                dataService.approvalData?.approvalRequest?.labelsIds
                  ? dataService.approvalData?.approvalRequest?.labelsIds
                  : []
              "
            >
            </app-cost-reporting-details>
          </div>
          <div class="col-lg-8">
            <ng-container *ngIf="dataService.mobileView">
              <div
                class="mb-2 pt-4"
                *ngFor="let msg of this.dataService.approvalData.operations"
              >
                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'SUBMIT_MORE_INFO'"
                >
                  <div
                    class="d-flex"
                    [ngClass]="{
                      'mr-5 pr-4': dataService.isSiteLtr(),
                      'ml-5 pl-4': dataService.isSiteRtl()
                    }"
                  >
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="
                          dataService.approvalData.requesterProfileImageUrl
                        "
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.requesterProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .requesterProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.requesterFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.requesterLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'ml-2': dataService.isSiteRtl()
                        }"
                      >
                        <span class="pr-2">{{
                          this.dataService.approvalData.requesterFirstname
                        }}</span
                        >{{ this.dataService.approvalData.requesterLastname }}
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark">
                    {{ msg.comment }}
                  </div>
                </div>

                <div
                  [ngClass]="{ 'chat-max-width': dataService.desktopView }"
                  *ngIf="msg.operationType === 'REQUEST_MORE_INFO'"
                >
                  <div class="d-flex">
                    <div>
                      <img
                        width="35"
                        height="35"
                        loading="lazy"
                        *ngIf="dataService.approvalData.approverProfileImageUrl"
                        class="user-img d-inline-block my-3"
                        onError="this.src='assets/img/default-user.png'"
                        [src]="dataService.approvalData.approverProfileImageUrl"
                      />
                      <div
                        *ngIf="
                          !dataService.approvalData.approverProfileImageUrl
                        "
                        class="name-first-letters small my-3"
                        [ngStyle]="{
                          'background-color':
                            dataService.approvalData
                              .approverProfileImageBackgroundHexColor
                        }"
                      >
                        {{
                          dataService.approvalData.approverFirstname
                            .substring(0, 1)
                            .toUpperCase()
                        }}{{
                          dataService.approvalData.approverLastname
                            .substring(0, 1)
                            .toUpperCase()
                        }}
                      </div>
                    </div>
                    <div class="pt-4">
                      <p
                        class="primaray-text bold-600 font-18 minus-margin-bottom"
                        [ngClass]="{
                          'ml-2': dataService.isSiteLtr(),
                          'mr-2': dataService.isSiteRtl()
                        }"
                        i18n="@@me"
                      >
                        Me
                      </p>
                    </div>
                  </div>
                  <div class="border rounded p-4 gray-text-dark gray-bg">
                    {{ msg.comment }}
                  </div>
                </div>
              </div>
            </ng-container>
            <form
              class="mb-5"
              [formGroup]="approvalForm"
              *ngIf="
                dataService.mobileView &&
                this.dataService.approvalData.status.toLowerCase() ===
                  'pending_approval'
              "
            >
              <textarea
                name="comment"
                id="comment"
                formControlName="comment"
                class="form-control pt-3 mt-5"
                i18n-placeholder="@@LeaveComments"
                placeholder="Leave your comments here"
                rows="6"
              ></textarea>
              <p class="text-danger font-12">{{ errorMsg }}</p>
              <div class="row">
                <div class="col-md-4">
                  <button
                    class="mt-5 btn-block blue_btn sharp-font bold-500 font-14 px-4 text-center"
                    (click)="
                      showApprovalsMobileActions(
                        this.dataService.approvalData.id,
                        this.dataService.approvalHotelData.rooms[0].rates[0]
                          .averageNightlySellingPrice,
                        this.dataService.approvalData.requesterFirstname +
                          ' ' +
                          this.dataService.approvalData.requesterLastname
                      )
                    "
                    i18n="@@pending"
                  >
                    Pending
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
