import { Component, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthServiceMain } from "../../auth/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-workspace-login",
  templateUrl: "./workspace-login.component.html",
  styleUrls: ["./workspace-login.component.scss"],
})
export class WorkspaceLoginComponent implements OnInit {
  constructor(
    private titleService: Title,
    public router: Router,
    public dataService: DataService,
    public auth: AuthServiceMain,
    public route: ActivatedRoute
  ) {
    this.titleService.setTitle("Sign in to your workspace | SafarHub");
  }

  urlArray;
  data;
  companyID;

  ngOnInit(): void {
    this.urlArray = this.router.url.split("/");
    this.companyID = this.urlArray[1];
    if (this.auth.isLogedIn) {
      this.router.navigate(["/" + this.companyID]);
    }
  }
}
