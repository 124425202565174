import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../../services/data.service";
import { SidenavService } from "../../../workspace-dashboard/sidenav.service";
import { onMainContentChange } from "../../../workspace-dashboard/animations/animations";
import { Router } from "@angular/router";
import { VariablesService } from "../../../../services/vars.service";
import { environment } from "../../../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthServiceMain } from "../../../../auth/auth.service";

@Component({
  selector: "app-flight-request-details",
  templateUrl: "./flight-request-details.component.html",
  styleUrls: ["./flight-request-details.component.scss"],
  animations: [onMainContentChange],
})
export class FlightRequestDetailsComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    public dataService: DataService,
    private sidenavService: SidenavService,
    public router: Router,
    public vars: VariablesService,
    public auth: AuthServiceMain
  ) {
    this.sidenavService.sideNavState$.subscribe((res) => {
      this.dataService.onSideNavChange = res;
    });
  }

  urlArray = this.router.url.split("/");
  approvalId = this.urlArray[5];
  data;
  requestMoreInfoForm: FormGroup;
  submitting;
  submitted;
  ngOnInit(): void {
    this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
    this.requestMoreInfoForm = this.formBuilder.group(
      {
        moreComment: ["", [Validators.required]],
      },
      {}
    );
  }
  get c() {
    return this.requestMoreInfoForm.controls;
  }
  requestSubmitMoreInfo() {
    this.submitted = true;
    this.submitting = true;
    if (this.requestMoreInfoForm.invalid) {
      this.submitting = false;
      return;
    }
    this.dataService
      .flightApprovalSubmitMoreInfo(
        this.approvalId,
        this.requestMoreInfoForm.controls.moreComment.value
      )
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
            this.submitting = false;
            this.submitted = false;
            this.requestMoreInfoForm.get("moreComment").setValue("");
          } else {
            this.dataService.messages(this.data.message, "error");
            this.submitting = false;
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
          this.submitting = false;
        }
      );
  }
  withdrawApprovalRequestPopup(approvalId) {
    if (this.dataService.desktopView) {
      this.dataService.setModal(
        $localize`:@@withdrawApprovalRequest:Withdraw Approval Request`,
        "withdrawApprovalRequestFlight",
        approvalId
      );
    } else {
      this.dataService.setBottomSheet(
        "withdrawApprovalRequestFlight",
        approvalId
      );
    }
  }
  showDetails(itemData, tripType) {
    let tripVal = "oneway";
    if (tripType) {
      tripVal = "roundtrip";
    }
    const flightData = {
      details: itemData,
      trip: tripVal,
      approvalDetails: true,
    };
    this.dataService.setRightSideDrawer("flightDetails", flightData);
  }
}
