import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { GoogleAnalyticsService } from "../../../google-analytics.service";
import { GoogleAnalytics4Service } from "../../../google-analytics4.service";
declare let ga: Function;
@Component({
  selector: "app-approve-request",
  templateUrl: "./approve-request.component.html",
  styleUrls: ["./approve-request.component.scss"],
})
export class ApproveRequestComponent implements OnInit {

  constructor(
    public googleAnalytics4Service: GoogleAnalytics4Service,
    public dataService: DataService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {}

  errorMsg;
  @Input() approvalId: string;
  @Input() ignoreRatePriceChange: string;
  @Input() averageNightlySellingPrice: string;
  @Input() sellingTotalFares: string;
  @Input() isFlight: string;
  @Input() pageType: string;
  data;
  priceChange;
  submitting;
  newPrice;
  dataFlight;

  ngOnInit(): void {
    this.averageNightlySellingPrice = "22";
    this.newPrice = 55;
  }

  confirmTripApproval(approvalId, ignoreRatePriceChange, isFlight) {
    this.submitting = true;
    this.dataService
      .approveTrip(approvalId, ignoreRatePriceChange, isFlight)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.addGA4();
            if (this.pageType === "list") {
              this.dataService.globalListApproversApprovals("PENDING_APPROVAL");
            } else {
              if (this.isFlight) {
                this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
              } else {
                this.dataService.getApprovalDetailsInfo(this.approvalId);
                this.googleAnalyticsService.eventEmitter(
                  "EventStatus",
                  "Booking-PayPal",
                  "Successful",
                  1
                );
                ga("ecommerce:clear");
                this.googleAnalyticsService.addTransaction(
                  this.data.data.referenceNumber,
                  this.data.data.sellingPrice
                );
                this.googleAnalyticsService.addItem(
                  this.data.data.referenceNumber,
                  this.data.data.sellingPrice,
                  this.data.data.hotelName
                );
              }
              this.dataService
                .getListApproversApprovals("PENDING_APPROVAL")
                .subscribe(
                  (dataApprove) => {
                    this.data = dataApprove;
                    if (this.data.errorCode === 0) {
                      const pendingArray = this.data.data.filter(
                        (item) =>
                          item.status.toLowerCase() === "pending_approval"
                      );
                      this.dataService
                        .getFlightListApproversApprovals("PENDING_APPROVAL")
                        .subscribe(
                          (dataApproveFlight) => {
                            this.dataFlight = dataApproveFlight;
                            if (this.dataFlight.errorCode === 0) {
                              const pendingArrayFlight =
                                this.dataFlight.data.filter(
                                  (item) =>
                                    item.status.toLowerCase() ===
                                    "pending_approval"
                                );
                              localStorage.setItem(
                                "workspaceApprovalsBadgeFlight",
                                pendingArrayFlight.length
                              );
                              this.dataService.workspaceApprovalsBadge =
                                pendingArray.length + pendingArrayFlight.length;
                            } else {
                              this.dataService.messages(
                                this.dataFlight.message,
                                "error"
                              );
                              this.submitting = false;
                            }
                          },
                          (error) => {
                            this.dataService.messages(
                              environment.messages.error,
                              "error"
                            );
                            this.submitting = false;
                          }
                        );
                    } else {
                      this.dataService.messages(this.data.message, "error");
                      this.submitting = false;
                    }
                  },
                  (error) => {
                    this.dataService.messages(
                      environment.messages.error,
                      "error"
                    );
                    this.submitting = false;
                  }
                );
            }
            if (this.dataService.desktopView) {
              this.dataService.modalOf();
              this.dataService.scrollTop();
            }
            if (this.dataService.mobileView) {
              this.dataService.BottomSheetOf();
            }
            this.submitting = false;
          } else if (this.data.errorCode === 195) {
            this.dataService.modalTitle = $localize`:@@warningPriceChanged:Warning, Price changed`;
            this.dataService.BottomSheetName = "changePrice";
            if (this.isFlight) {
              this.newPrice =
                this.data.data.airItinerary.fareInfo.sellingTotalFares.total.amount;
            } else {
              this.newPrice = this.data.data.averageNightlySellingPrice;
            }
            this.submitting = false;
            this.priceChange = true;
          } else {
            //  this.messages(this.data.message, 'error');
            this.errorMsg = this.data.message;
            this.submitting = false;
          }
        },
        (error) => {
          //  this.messages(environment.messages.error, 'error');
          this.errorMsg = this.data.message;
          this.submitting = false;
        }
      );
  }
  confirmTripApprovalAfterPriceChange(
    approvalId,
    ignoreRatePriceChange,
    isFlight
  ) {
    this.submitting = true;
    this.dataService
      .approveTrip(approvalId, ignoreRatePriceChange, isFlight)
      .subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.addGA4();
            if (this.pageType === "list") {
              this.dataService.globalListApproversApprovals("PENDING_APPROVAL");
            } else {
              if (this.isFlight) {
                this.dataService.getFlightApprovalDetailsInfo(this.approvalId);
              } else {
                this.dataService.getApprovalDetailsInfo(this.approvalId);
                this.googleAnalyticsService.eventEmitter(
                  "EventStatus",
                  "Booking-PayPal",
                  "Successful",
                  1
                );
                ga("ecommerce:clear");
                this.googleAnalyticsService.addTransaction(
                  this.data.data.referenceNumber,
                  this.data.data.sellingPrice
                );
                this.googleAnalyticsService.addItem(
                  this.data.data.referenceNumber,
                  this.data.data.sellingPrice,
                  this.data.data.hotelName
                );
              }
              this.dataService
                .getListApproversApprovals("PENDING_APPROVAL")
                .subscribe(
                  (dataApprove) => {
                    this.data = dataApprove;
                    if (this.data.errorCode === 0) {
                      const pendingArray = this.data.data.filter(
                        (item) =>
                          item.status.toLowerCase() === "pending_approval"
                      );
                      this.dataService
                        .getFlightListApproversApprovals("PENDING_APPROVAL")
                        .subscribe(
                          (dataApproveFlight) => {
                            this.dataFlight = dataApproveFlight;
                            if (this.dataFlight.errorCode === 0) {
                              const pendingArrayFlight =
                                this.dataFlight.data.filter(
                                  (item) =>
                                    item.status.toLowerCase() ===
                                    "pending_approval"
                                );
                              localStorage.setItem(
                                "workspaceApprovalsBadgeFlight",
                                pendingArrayFlight.length
                              );
                              this.dataService.workspaceApprovalsBadge =
                                pendingArray.length + pendingArrayFlight.length;
                            } else {
                              this.errorMsg = this.dataFlight.message;
                              this.submitting = false;
                            }
                          },
                          (error) => {
                            this.errorMsg = environment.messages.error;
                            this.submitting = false;
                          }
                        );
                      /*  const pendingArray = this.data.data.filter(item => item.status.toLowerCase() === 'pending_approval');
                  localStorage.setItem('workspaceApprovalsBadge', pendingArray.length);
                  this.dataService.workspaceApprovalsBadge = pendingArray.length;*/
                    } else {
                      this.errorMsg = this.data.message;
                      this.submitting = false;
                    }
                  },
                  (error) => {
                    this.errorMsg = environment.messages.error;
                    this.submitting = false;
                  }
                );
            }
            if (this.dataService.desktopView) {
              this.dataService.modalOf();
              this.dataService.scrollTop();
            }
            if (this.dataService.mobileView) {
              this.dataService.BottomSheetOf();
            }
            this.submitting = false;
          } else {
            this.errorMsg = this.data.message;
            this.submitting = false;
          }
        },
        (error) => {
          this.errorMsg = environment.messages.error;
          this.submitting = false;
        }
      );
  }
  addGA4() {
    if (this.isFlight) {
      // GA4
      let tripType = "One-way";
      if (this.data.data.bookRequest.searchRequest.roundTrip) {
        tripType = "Round-trip";
      }

      let name =
        this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
          .departureAirport.city +
        " - " +
        this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
          .arrivalAirport.city;
      let location = "";
      if (
        this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
          .arrivalAirport.city
      ) {
        location =
          this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
            .arrivalAirport.city;
      }
      if (
        this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
          .arrivalAirport.state
      ) {
        location +=
          ", " +
          this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
            .arrivalAirport.state;
      }
      if (
        this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
          .arrivalAirport.country
      ) {
        location +=
          ", " +
          this.data.data.airItinerary.originDestinationOptionsGroups[0].summary
            .arrivalAirport.country;
      }
      /* this.googleAnalytics4Service.addItem(tripType, name, this.data.data.companyId, this.data.data.airItinerary.fareInfo.sellingTotalFares.total.currencyCode,
          'Flight', this.data.data.airItinerary.fareInfo.sellingTotalFares.total.amount, location, 'Approval');*/
      this.googleAnalytics4Service.addTransaction(
        this.data.data.airItinerary.fareInfo.sellingTotalFares.total
          .currencyCode,
        this.data.data.hrsReferenceNumber,
        this.data.data.airItinerary.fareInfo.sellingTotalFares.total.amount,
        this.data.data.companyId,
        name,
        location,
        "Flight",
        "Approval"
      );
      // end of GA4
    } else {
      // GA4
      let location = "";
      if (this.data.data.cityName) {
        location = this.data.data.cityName;
      }
      if (this.data.data.stateName) {
        location += ", " + this.data.data.stateName;
      }
      if (this.data.data.countryName) {
        location += ", " + this.data.data.countryName;
      }
      /* this.googleAnalytics4Service.addItem(this.data.data.triphopId, this.data.data.hotelName, this.data.data.companyId, this.data.data.currency.currency,
          'Hotel', this.data.data.sellingPrice, location, 'Approval');*/
      this.googleAnalytics4Service.addTransaction(
        this.data.data.currency.currency,
        this.data.data.referenceNumber,
        this.data.data.sellingPrice,
        this.data.data.companyId,
        name,
        location,
        "Flight",
        "Approval"
      );
      // end of GA4
    }
  }
}
