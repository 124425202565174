import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DataService } from "../../services/data.service";
import { StripeService, Elements, Element as StripeElement } from "ngx-stripe";
import {
  ScrollToService,
  ScrollToConfigOptions,
} from "@nicky-lenaers/ngx-scroll-to";
import * as moment from "moment";
import { GoogleAnalyticsService } from "../../google-analytics.service";
import { AuthServiceMain } from "../../auth/auth.service";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { VariablesService } from "../../services/vars.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { GoogleAnalytics4Service } from "../../google-analytics4.service";
import { CostBookingStateService } from "src/app/services/states/cost-booking-state.service";

declare let paypal: any;
declare let google: any;
declare let window: any;
export class User {
  constructor(
    public description: string,
    public firstName: string,
    public lastName: string,
    public id: string,
    public email: string,
    public teamName?: string,
    public selected?: boolean
  ) {
    if (selected === undefined) {
      selected = false;
    }
  }
}

@Component({
  selector: "app-workspace-flight-booking-form",
  templateUrl: "./workspace-flight-booking-form.component.html",
  styleUrls: ["./workspace-flight-booking-form.component.scss"],
})
export class WorkspaceFlightBookingFormComponent implements OnInit {
  env = environment;
  getApprovalsList;
  ServiceDown: boolean = false;
  ServiceUp: boolean = false;
  userInfohasProperty = false;
  changeRememberMe = false;
  changeRememberMeData;
  PAypalPrice;
  paymentType = "new_card";
  elements: Elements;
  googlrSuported = false;
  card: StripeElement;
  stripeError = "";
  sscid;
  paytype = "STRIPE";
  cardToken;
  paymentID;
  submitted = false;
  data;
  cardholder = false;
  SelectedsavedCard = "";
  CardSelected;
  errorMsg = "";
  specialReq = false;
  savedCard;
  showcardControl = false;
  public showSuccess = false;
  public showCancel = false;
  public showError = false;
  PaxesCount: any;
  rate;
  diffInDays;
  TripAdviserRating;
  tripAdviserdata;
  extraCashback;
  previousUrl = sessionStorage.getItem("previousUrl");
  formFirstName = "";
  formLastName = "";
  formEmail = "";
  formPhone = "";
  invalidCardNumber = false;
  teamPersonalPaymentMethodsDisabled;
  genderValue;
  genderArray: any = [
    { id: "MALE", name: $localize`:@@male:Male`, isSelected: false },
    { id: "FEMALE", name: $localize`:@@female:Female`, isSelected: false },
  ];
  monthsArray: any = [
    { id: "01", name: $localize`:@@jan:01 - Jan`, isSelected: false },
    { id: "02", name: $localize`:@@feb:02 - Feb`, isSelected: false },
    { id: "03", name: $localize`:@@mar:03 - Mar`, isSelected: false },
    { id: "04", name: $localize`:@@apr:04 - Apr`, isSelected: false },
    { id: "05", name: $localize`:@@may:05 - May`, isSelected: false },
    { id: "06", name: $localize`:@@jun:06 - Jun`, isSelected: false },
    { id: "07", name: $localize`:@@jul:07 - Jul`, isSelected: false },
    { id: "08", name: $localize`:@@aug:08 - Aug`, isSelected: false },
    { id: "09", name: $localize`:@@sep:09 - Sep`, isSelected: false },
    { id: "10", name: $localize`:@@oct:10 - Oct`, isSelected: false },
    { id: "11", name: $localize`:@@nov:11 - Nov`, isSelected: false },
    { id: "12", name: $localize`:@@dec:12 - Dec`, isSelected: false },
  ];
  daysArray: any = [
    { id: "01", isSelected: false },
    { id: "02", isSelected: false },
    { id: "03", isSelected: false },
    { id: "04", isSelected: false },
    { id: "05", isSelected: false },
    { id: "06", isSelected: false },
    { id: "07", isSelected: false },
    { id: "08", isSelected: false },
    { id: "09", isSelected: false },
    { id: "10", isSelected: false },
    { id: "11", isSelected: false },
    { id: "12", isSelected: false },
    { id: "13", isSelected: false },
    { id: "14", isSelected: false },
    { id: "15", isSelected: false },
    { id: "16", isSelected: false },
    { id: "17", isSelected: false },
    { id: "18", isSelected: false },
    { id: "19", isSelected: false },
    { id: "20", isSelected: false },
    { id: "21", isSelected: false },
    { id: "22", isSelected: false },
    { id: "23", isSelected: false },
    { id: "24", isSelected: false },
    { id: "25", isSelected: false },
    { id: "26", isSelected: false },
    { id: "27", isSelected: false },
    { id: "28", isSelected: false },
    { id: "29", isSelected: false },
    { id: "30", isSelected: false },
    { id: "31", isSelected: false },
  ];
  yearArray = [];
  @ViewChild("priceElem", { static: false }) priceElem?: ElementRef;
  selectedDay;
  selectedMonth;
  selectedYear;
  bodErrorMsg;
  minDate = new Date();
  formatExpiryDateText;
  adultFormatExpiryDateText;
  adultsDOBErrorMsg;
  adultsSelectedMonth;
  adultsSelectedDay;
  adultsSelectedYear;
  adultsExpiryDate;
  expiryDate;
  selectedPassportIssueCountryCode;
  otherAdultsTravelers;
  travelersArray = [];
  userControl = new FormControl();
  users = [];
  showUsersAutoCompletePopUp = false;
  selectedUser;
  filteredUsers: Observable<any[]>;
  lastFilter: string = "";
  dataUsers;
  requiresApprovalData;
  submittingApproval;
  submittedApproval;
  voucherUrl;
  phonePlaceHolder = "Phone number";
  summaryDetailsDepart;
  summaryDetailsReturn;
  userInfoObj = JSON.parse(localStorage.getItem("userInfo"));
  programsList;
  @ViewChild("userInput") userInput: ElementRef;
  travelerId;
  traveler;

  constructor(
    public googleAnalytics4Service: GoogleAnalytics4Service,
    public router: Router,
    public vars: VariablesService,
    private formBuilder: FormBuilder,
    public Service: DataService,
    public costBookingState: CostBookingStateService,
    private stripeService: StripeService,
    public Auth: AuthServiceMain,
    private scrollToService: ScrollToService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.Service.loadScript(
      "https://www.paypalobjects.com/api/checkout.min.js"
    );
    this.Service.loadScript("https://pay.google.com/gp/p/js/pay.js");
    this.Service.componentMethodCalled$.subscribe(() => {
      if (
        this.Service.urlWithoutParams === "/flights/availability/booking" ||
        (this.Auth.isLogedIn &&
          this.Auth.companyInfo &&
          this.Service.urlWithoutParams ===
            "/" + this.Auth.companyInfo.id + "/flights/availability/booking")
      ) {
        this.Service.flightBookingPage = true;
        this.showcardControl = false;
        this.submitted = false;
        if (this.Auth.isLogedIn) {
          this.Service.savedCard(this.travelerId).subscribe((success) => {
            this.savedCard = success;
            if (this.savedCard.errorCode === 0) {
              if (
                this.savedCard.data &&
                this.savedCard.data.cards &&
                this.savedCard.data.cards.length > 0
              ) {
                this.paytype = "savedCard";
                this.savedCard.data.cards.forEach((element, index) => {
                  if (element.primary === true) {
                    this.SelectedsavedCard = element.token;
                  }
                });
              } else {
                this.paytype = "STRIPE";
              }
            } else {
              this.paytype = "STRIPE";
            }
            if (
              this.Auth.isLogedIn &&
              this.savedCard.data &&
              this.savedCard.data.length
            ) {
              this.showcardControl = false;
            }

            if (this.Auth.isLogedIn && !this.savedCard.data) {
              this.showcardControl = true;
            }
          });
        } else {
          this.paytype = "STRIPE";
        }
        for (var prop in this.Auth.userInfo) {
          if (this.Auth.userInfo.hasOwnProperty(prop)) {
            this.userInfohasProperty = true;
            break;
          }
        }
      }
    });
    this.Service.changePrice$.subscribe(() => {
      setTimeout(() => {
        if (this.Service.flightNewData) {
          this.googlePay(
            this.Service.flightData.details.fareInfo.sellingTotalFares.total.amount.toString()
          );
          this.PayPalConfig(
            this.Service.flightData.details.fareInfo.sellingTotalFares.total.amount.toString()
          );
          this.summaryDetailsDepart =
            this.Service.flightNewData.details.originDestinationOptionsGroups[0].summary;
          if (this.Service.flightData.trip === "roundtrip") {
            this.summaryDetailsReturn =
              this.Service.flightNewData.details.originDestinationOptionsGroups[1].summary;
          }
        }
      }, 15000);
    });
    this.Service.getCookie("shareasaleSSCID");
  }

  ngOnInit() {
    this.selectedUser = this.userInfoObj;
    if (this.Service.isCompany()) {
      this.phonePlaceHolder = "";
    }
    this.travelersArray = this.Service.flightParams.paxes.split(",");
    this.otherAdultsTravelers = this.travelersArray[0] - 1;
    this.adultsExpiryDate = new Array(this.otherAdultsTravelers);
    this.adultFormatExpiryDateText = new Array(this.otherAdultsTravelers);
    this.adultsDOBErrorMsg = new Array(this.otherAdultsTravelers);
    this.adultsSelectedMonth = new Array(this.otherAdultsTravelers);
    this.adultsSelectedDay = new Array(this.otherAdultsTravelers);
    this.adultsSelectedYear = new Array(this.otherAdultsTravelers);
    for (let i = Number(new Date().getFullYear()); i >= 1900; i--) {
      this.yearArray.push(i);
    }
    this.Service.loaderText = $localize`:@@bookingYourFlight:Booking your flight ..`;
    setTimeout(() => {
      if (this.Service.flightNewData) {
        this.googlePay(
          this.Service.flightData.details.fareInfo.sellingTotalFares.total.amount.toString()
        );
        this.PayPalConfig(
          this.Service.flightData.details.fareInfo.sellingTotalFares.total.amount.toString()
        );
        this.summaryDetailsDepart =
          this.Service.flightNewData.details.originDestinationOptionsGroups[0].summary;
        if (this.Service.flightData.trip === "roundtrip") {
          this.summaryDetailsReturn =
            this.Service.flightNewData.details.originDestinationOptionsGroups[1].summary;
        }
      }
    }, 15000);
    this.errorMsg = "";
    this.Service.errorMsg = "";
    this.Service.flightBookingForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      LastName: ["", Validators.required],
      HolderName: ["", Validators.pattern("^[A-Za-z -]+$")],
      phone: ["", Validators.required],
      password: [""],
      FirstName: ["", Validators.required],
      dayOfBirth: ["", Validators.required],
      monthOfBirth: ["", Validators.required],
      yearOfBirth: ["", Validators.required],
      passportIssueCountryCode: ["", Validators.required],
      passportNumber: ["", Validators.required],
      programNumber: [""],
      flyerProgram: [""],
      mealRequest: [""],
      passportExpiry: ["", Validators.required],
      gender: ["", Validators.required],
    });
    for (let i = 0; i < this.otherAdultsTravelers; i++) {
      this.Service.flightBookingForm.addControl(
        "FirstName" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "LastName" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "gender" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "dayOfBirth" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "monthOfBirth" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "yearOfBirth" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "passportIssueCountryCode" + i,
        new FormControl("", Validators.required)
      );
      this.Service.flightBookingForm.addControl(
        "passportNumber" + i,
        new FormControl("", Validators.required)
      );
      const namePassport = "passportExpiry" + i;
      this.Service.flightBookingForm.addControl(
        i.toString(),
        this.formBuilder.group({
          adultsPassportExpiry: new FormControl(),
        })
      );
      this.Service.flightBookingForm.addControl(
        "flyerProgram" + i,
        new FormControl()
      );
      this.Service.flightBookingForm.addControl(
        "programNumber" + i,
        new FormControl()
      );
      this.Service.flightBookingForm.addControl(
        "mealRequest" + i,
        new FormControl()
      );
    }
    const PaxesArray: any = this.Service.flightParams.paxes.split(",");

    this.PaxesCount = PaxesArray.length + Number(PaxesArray[0]) - 1;
    this.Service.flightBookingSubmit = false;
    this.submittingApproval = false;
    this.Service.needRequestApproval = false;
    this.Service.alerts = [];
    this.travelerId = sessionStorage.getItem("travelerIdFlight");
    if (this.Auth.isOwner() || this.Auth.isAdmin()) {
      if (
        !this.travelerId ||
        this.travelerId === "" ||
        this.travelerId === "guest"
      ) {
        this.Service.flightBookingForm.get("monthOfBirth").setValue(null);
        this.Service.flightBookingForm.get("dayOfBirth").setValue(null);
        this.Service.flightBookingForm.get("yearOfBirth").setValue(null);
      } else {
        this.Service.getCompanyUserDetails(this.travelerId).subscribe(
          (suc) => {
            this.data = suc;
            if (this.data.errorCode === 0) {
              let travelerInfo = this.data.data;
              this.traveler = {
                travelerPersonalPaymentMethodsDisabled:
                  this.data.data.personalPaymentMethodsDisabled,
                travelerName:
                  this.data.data.firstName + " " + this.data.data.lastName,
              };

              if (
                this.Service.flightBookingForm.controls.FirstName.value === ""
              ) {
                this.Service.flightBookingForm
                  .get("FirstName")
                  .setValue(travelerInfo.firstName);
              }
              if (
                this.Service.flightBookingForm.controls.LastName.value === ""
              ) {
                this.Service.flightBookingForm
                  .get("LastName")
                  .setValue(travelerInfo.lastName);
              }
              if (this.Service.flightBookingForm.controls.phone.value === "") {
                this.Service.flightBookingForm
                  .get("phone")
                  .setValue(travelerInfo.phoneNumber);
              }
              if (this.Service.flightBookingForm.controls.email.value === "") {
                this.Service.flightBookingForm
                  .get("email")
                  .setValue(travelerInfo.email);
              }
              if (
                travelerInfo.frequentFlyerPrograms &&
                travelerInfo.frequentFlyerPrograms.length
              ) {
                this.Service.flightBookingForm
                  .get("flyerProgram")
                  .setValue(travelerInfo.frequentFlyerPrograms[0].code);
                this.Service.flightBookingForm
                  .get("programNumber")
                  .setValue(travelerInfo.frequentFlyerPrograms[0].number);
              }
              if (
                travelerInfo.travelDocuments &&
                travelerInfo.travelDocuments.length
              ) {
                this.Service.flightBookingForm
                  .get("passportIssueCountryCode")
                  .setValue(travelerInfo.travelDocuments[0].issuingCountryCode);
                this.Service.flightBookingForm
                  .get("passportNumber")
                  .setValue(travelerInfo.travelDocuments[0].number);
                this.Service.flightBookingForm
                  .get("passportExpiry")
                  .setValue(
                    new Date(travelerInfo.travelDocuments[0].expiryDate)
                  );
                this.formatExpiryDateText =
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                    "MMM"
                  ) +
                  " " +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                    "DD"
                  ) +
                  ", " +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                    "YYYY"
                  );
                this.expiryDate =
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                    "YYYY"
                  ) +
                  "-" +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                    "MM"
                  ) +
                  "-" +
                  moment(travelerInfo.travelDocuments[0].expiryDate).format(
                    "DD"
                  );
              }
              if (travelerInfo.gender) {
                this.Service.flightBookingForm
                  .get("gender")
                  .setValue(travelerInfo.gender);
              }
              if (travelerInfo.birthDate) {
                this.Service.flightBookingForm
                  .get("monthOfBirth")
                  .setValue(moment(travelerInfo.birthDate).format("MM"));
                this.Service.flightBookingForm
                  .get("dayOfBirth")
                  .setValue(moment(travelerInfo.birthDate).format("DD"));
                this.Service.flightBookingForm
                  .get("yearOfBirth")
                  .setValue(
                    Number(moment(travelerInfo.birthDate).format("YYYY"))
                  );
                this.selectedDay = moment(travelerInfo.birthDate).format("DD");
                this.selectedMonth = moment(travelerInfo.birthDate).format(
                  "MM"
                );
                this.selectedYear = moment(travelerInfo.birthDate).format(
                  "YYYY"
                );
              } else {
                this.Service.flightBookingForm
                  .get("monthOfBirth")
                  .setValue(null);
                this.Service.flightBookingForm.get("dayOfBirth").setValue(null);
                this.Service.flightBookingForm
                  .get("yearOfBirth")
                  .setValue(null);
              }

              if (this.data.data.phoneNumberCountryCode) {
                let selected = this.vars.countries.filter(
                  (item) =>
                    item.dial_code === "+" + travelerInfo.phoneNumberCountryCode
                );
                if (selected.length > 0) {
                  this.Service.defultCountry.dial_code =
                    "+" + travelerInfo.phoneNumberCountryCode;
                } else {
                  selected = this.vars.countries.filter(
                    (item) => item.code === travelerInfo.phoneNumberCountryCode
                  );
                  this.Service.defultCountry.dial_code = selected[0].dial_code;
                }
                this.Service.defultCountry.code = selected[0].code;
              }
            }
          },
          (err) => {}
        );
      }
    } else {
      this.traveler = {
        travelerPersonalPaymentMethodsDisabled:
          this.Auth.userInfo.personalPaymentMethodsDisabled,
        travelerName:
          this.Auth.userInfo.name + " " + this.Auth.userInfo.lastName,
      };

      if (this.Service.flightBookingForm.controls.FirstName.value === "") {
        this.Service.flightBookingForm
          .get("FirstName")
          .setValue(this.Auth.userInfo.name);
      }
      if (this.Service.flightBookingForm.controls.LastName.value === "") {
        this.Service.flightBookingForm
          .get("LastName")
          .setValue(this.Auth.userInfo.lastName);
      }
      if (this.Service.flightBookingForm.controls.phone.value === "") {
        this.Service.flightBookingForm
          .get("phone")
          .setValue(this.Auth.userInfo.phoneNumber);
      }
      if (this.Service.flightBookingForm.controls.email.value === "") {
        this.Service.flightBookingForm
          .get("email")
          .setValue(this.Auth.userInfo.email);
      }
      if (
        this.Auth.userInfo.frequentFlyerPrograms &&
        this.Auth.userInfo.frequentFlyerPrograms.length
      ) {
        this.Service.flightBookingForm
          .get("flyerProgram")
          .setValue(this.Auth.userInfo.frequentFlyerPrograms[0].code);
        this.Service.flightBookingForm
          .get("programNumber")
          .setValue(this.Auth.userInfo.frequentFlyerPrograms[0].number);
      }
      if (
        this.Auth.userInfo.travelDocuments &&
        this.Auth.userInfo.travelDocuments.length
      ) {
        this.Service.flightBookingForm
          .get("passportIssueCountryCode")
          .setValue(this.Auth.userInfo.travelDocuments[0].issuingCountryCode);
        this.Service.flightBookingForm
          .get("passportNumber")
          .setValue(this.Auth.userInfo.travelDocuments[0].number);
        this.Service.flightBookingForm
          .get("passportExpiry")
          .setValue(new Date(this.Auth.userInfo.travelDocuments[0].expiryDate));
        this.formatExpiryDateText =
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
            "MMM"
          ) +
          " " +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
            "DD"
          ) +
          ", " +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
            "YYYY"
          );
        this.expiryDate =
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
            "YYYY"
          ) +
          "-" +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format(
            "MM"
          ) +
          "-" +
          moment(this.Auth.userInfo.travelDocuments[0].expiryDate).format("DD");
      }
      if (this.Auth.userInfo.gender) {
        this.Service.flightBookingForm
          .get("gender")
          .setValue(this.Auth.userInfo.gender);
      }
      if (this.Auth.userInfo.birthDate) {
        this.Service.flightBookingForm
          .get("monthOfBirth")
          .setValue(moment(this.Auth.userInfo.birthDate).format("MM"));
        this.Service.flightBookingForm
          .get("dayOfBirth")
          .setValue(moment(this.Auth.userInfo.birthDate).format("DD"));
        this.Service.flightBookingForm
          .get("yearOfBirth")
          .setValue(
            Number(moment(this.Auth.userInfo.birthDate).format("YYYY"))
          );
        this.selectedDay = moment(this.Auth.userInfo.birthDate).format("DD");
        this.selectedMonth = moment(this.Auth.userInfo.birthDate).format("MM");
        this.selectedYear = moment(this.Auth.userInfo.birthDate).format("YYYY");
      } else {
        this.Service.flightBookingForm.get("monthOfBirth").setValue(null);
        this.Service.flightBookingForm.get("dayOfBirth").setValue(null);
        this.Service.flightBookingForm.get("yearOfBirth").setValue(null);
      }

      if (this.Auth.userInfo.phoneNumberCountryCode) {
        let selected = this.vars.countries.filter(
          (item) =>
            item.dial_code === "+" + this.Auth.userInfo.phoneNumberCountryCode
        );
        if (selected.length > 0) {
          this.Service.defultCountry.dial_code =
            "+" + this.Auth.userInfo.phoneNumberCountryCode;
        } else {
          selected = this.vars.countries.filter(
            (item) => item.code === this.Auth.userInfo.phoneNumberCountryCode
          );
          this.Service.defultCountry.dial_code = selected[0].dial_code;
        }
        this.Service.defultCountry.code = selected[0].code;
      }
    }

    this.rate = JSON.parse(sessionStorage.getItem("flightData"));
    this.Service.requiresAirApprovalCheck(
      this.Service.flightData.details.fareInfo.fareKey
    ).subscribe((data) => {
      this.data = data;
      if (this.data.errorCode === 0) {
        this.requiresApprovalData = this.data.data;
        if (this.requiresApprovalData) {
          this.Service.needRequestApproval = true;
        }
      }
    });
    this.stripeService.elements().subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#515e80",
              color: "#515e80",
              fontWeight: 300,
              fontFamily:
                'Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              fontSize: "14px",
              "::placeholder": {
                color: "#515e80",
              },
            },
          },
        });
        this.card.mount("#card-element");
      }
    });

    if (this.Auth.isLogedIn) {
      this.Service.savedCard(this.travelerId).subscribe((success) => {
        this.savedCard = success;

        if (this.savedCard.errorCode === 0) {
          this.paytype = "savedCard";

          if (
            this.savedCard.data &&
            this.savedCard.data.cards &&
            this.savedCard.data.cards.length > 0
          ) {
            this.paytype = "savedCard";
            this.savedCard.data.cards.forEach((element, index) => {
              if (index === 0) {
                this.SelectedsavedCard = element.token;
              }

              if (element.primary === true) {
                this.SelectedsavedCard = element.token;
              }
            });
          } else {
            this.paytype = "STRIPE";
          }
        } else {
          this.paytype = "STRIPE";
        }

        if (
          this.Auth.isLogedIn &&
          this.savedCard.data &&
          this.savedCard.data.length
        ) {
          this.showcardControl = false;
        }

        if (this.Auth.isLogedIn && !this.savedCard.data) {
          this.showcardControl = true;
        }
      });
    }
    if (this.Service.flightNewData) {
      this.PAypalPrice =
        this.Service.flightData.details.fareInfo.sellingTotalFares.total.amount;
    }
  }
  formatExpiryDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.expiryDate =
      moment(event.value).format("YYYY") +
      "-" +
      moment(event.value).format("MM") +
      "-" +
      moment(event.value).format("DD");
    this.formatExpiryDateText =
      moment(event.value).format("MMM") +
      " " +
      moment(event.value).format("DD") +
      ", " +
      moment(event.value).format("YYYY");
  }

  adultsFormatExpiryDate(event: MatDatepickerInputEvent<Date>, index) {
    this.adultsExpiryDate[index] =
      moment(event.value).format("YYYY") +
      "-" +
      moment(event.value).format("MM") +
      "-" +
      moment(event.value).format("DD");
    this.adultFormatExpiryDateText[index] =
      moment(event.value).format("MMM") +
      " " +
      moment(event.value).format("DD") +
      ", " +
      moment(event.value).format("YYYY");
  }
  changeDay() {
    this.bodErrorMsg = "";
    this.selectedDay = this.Service.flightBookingForm.controls.dayOfBirth.value;
    this.checkDateOfBirth();
  }
  changeMonth() {
    this.bodErrorMsg = "";
    this.selectedMonth =
      this.Service.flightBookingForm.controls.monthOfBirth.value;
    this.checkDateOfBirth();
  }
  changeYear() {
    this.bodErrorMsg = "";
    this.selectedYear =
      this.Service.flightBookingForm.controls.yearOfBirth.value;
    this.checkDateOfBirth();
  }
  checkDateOfBirth() {
    if (this.selectedDay && this.selectedMonth && this.selectedYear) {
      const month30days = [4, 6, 9, 11];
      const day = Number("" + this.selectedDay);
      const month = Number("" + this.selectedMonth);
      const year = Number("" + this.selectedYear);
      if (month === 2 && day > 29) {
        this.bodErrorMsg = "select a correct date of birth";
      } else if (month30days.includes(month) && day > 30) {
        this.bodErrorMsg = "select a correct date of birth";
      } else if (
        !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) &&
        month === 2 &&
        day > 28
      ) {
        this.bodErrorMsg = "select a correct date of birth";
      } else {
        const today = new Date();
        let age = today.getFullYear() - year;
        const m = today.getMonth() + 1 - month;
        if (m < 0 || (m === 0 && today.getDate() < day)) {
          age--;
        }
        if (age < 18) {
          this.bodErrorMsg = "Primary traveler must be 18 or older.";
        }
      }
    }
  }

  adultsChangeDay(index) {
    this.adultsDOBErrorMsg[index] = "";
    this.adultsSelectedDay[index] =
      this.Service.flightBookingForm.controls["dayOfBirth" + index].value;
    this.adultsCheckDateOfBirth(index);
  }
  adultsChangeMonth(index) {
    this.adultsDOBErrorMsg[index] = "";
    this.adultsSelectedMonth[index] =
      this.Service.flightBookingForm.controls["monthOfBirth" + index].value;
    this.adultsCheckDateOfBirth(index);
  }
  adultsChangeYear(index) {
    this.adultsDOBErrorMsg[index] = "";
    this.adultsSelectedYear[index] =
      this.Service.flightBookingForm.controls["yearOfBirth" + index].value;
    this.adultsCheckDateOfBirth(index);
  }
  adultsCheckDateOfBirth(index) {
    if (
      this.adultsSelectedDay[index] &&
      this.adultsSelectedMonth[index] &&
      this.adultsSelectedYear[index]
    ) {
      const month30days = [4, 6, 9, 11];
      const day = Number("" + this.adultsSelectedDay[index]);
      const month = Number("" + this.adultsSelectedMonth[index]);
      const year = Number("" + this.adultsSelectedYear[index]);
      if (month === 2 && day > 29) {
        this.adultsDOBErrorMsg[index] = "select a correct date of birth";
      } else if (month30days.includes(month) && day > 30) {
        this.adultsDOBErrorMsg[index] = "select a correct date of birth";
      } else if (
        !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) &&
        month === 2 &&
        day > 28
      ) {
        this.adultsDOBErrorMsg[index] = "select a correct date of birth";
      } else {
        const today = new Date();
        let age = today.getFullYear() - year;
        const m = today.getMonth() + 1 - month;
        if (m < 0 || (m === 0 && today.getDate() < day)) {
          age--;
        }
        if (age < 18) {
          this.adultsDOBErrorMsg[index] =
            "Primary traveler must be 18 or older.";
        }
      }
    }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: "bookingForm",
      duration: 650,
      easing: "easeOutElastic",
      offset: 20,
    };

    this.scrollToService.scrollTo(config);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.Service.flightBookingForm.controls;
  }

  isValid(fieldName) {
    return this.Service.flightBookingForm.controls[fieldName].valid;
  }

  onSubmit() {
    if (this.Auth.isManager() || this.Auth.isAdmin() || this.Auth.isOwner()) {
      this.getApprovalsList = true;
    }
    let header = "";
    if (this.Service.sscid) {
      header = this.Service.sscid;
    }
    this.errorMsg = "";
    this.cardholder = false;
    if (this.Service.flightBookingForm.controls.HolderName.value === "") {
      this.cardholder = true;
    } else {
      this.cardholder = false;
    }
    this.submitted = true;
    this.Service.alerts = [];
    const isValidCostReporting = this.costBookingState
      .setIsSubmittedAndCheckValidation(this.submitted)
      .valueOf();
    if (!isValidCostReporting) {
      this.Service.scrollTop();
      return;
    }

    if (
      (this.Service.flightBookingForm.invalid ||
        (this.Service.isCompany() && !this.selectedUser)) &&
      this.paytype !== "PAYPAL"
    ) {
      this.triggerScrollTo();
      this.Service.scrollTop();
      return;
    }
    let finalCompanyObj = {} as any;
    const dataObj = {} as any;
    dataObj.adults = [];
    const mainTraveler = {} as any;
    mainTraveler.firstName =
      this.Service.flightBookingForm.controls.FirstName.value;
    mainTraveler.lastName =
      this.Service.flightBookingForm.controls.LastName.value;
    mainTraveler.gender = this.Service.flightBookingForm.controls.gender.value;
    mainTraveler.birthDate =
      this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
    mainTraveler.passportNumber =
      this.Service.flightBookingForm.controls.passportNumber.value;
    (mainTraveler.passportExpiryDate = this.expiryDate),
      (mainTraveler.passportIssueCountryCode =
        this.Service.flightBookingForm.controls.passportIssueCountryCode.value);
    mainTraveler.frequentFlyerProgramCode =
      this.Service.flightBookingForm.controls.flyerProgram.value;
    mainTraveler.frequentFlyerNumber =
      this.Service.flightBookingForm.controls.programNumber.value;
    mainTraveler.mealPlan =
      this.Service.flightBookingForm.controls.mealRequest.value;
    dataObj.adults.push(mainTraveler);
    for (let i = 0; i < this.otherAdultsTravelers; i++) {
      const adultTraveler = {} as any;
      adultTraveler.firstName =
        this.Service.flightBookingForm.controls["FirstName" + i].value;
      adultTraveler.lastName =
        this.Service.flightBookingForm.controls["LastName" + i].value;
      adultTraveler.gender =
        this.Service.flightBookingForm.controls["gender" + i].value;
      adultTraveler.birthDate =
        this.adultsSelectedYear[i] +
        "-" +
        this.adultsSelectedMonth[i] +
        "-" +
        this.adultsSelectedDay[i];
      adultTraveler.passportNumber =
        this.Service.flightBookingForm.controls["passportNumber" + i].value;
      (adultTraveler.passportExpiryDate = this.adultsExpiryDate[i]),
        (adultTraveler.passportIssueCountryCode =
          this.Service.flightBookingForm.controls[
            "passportIssueCountryCode" + i
          ].value);
      adultTraveler.frequentFlyerProgramCode =
        this.Service.flightBookingForm.controls["flyerProgram" + i].value;
      adultTraveler.frequentFlyerNumber =
        this.Service.flightBookingForm.controls["programNumber" + i].value;
      adultTraveler.mealPlan =
        this.Service.flightBookingForm.controls["mealRequest" + i].value;
      dataObj.adults.push(adultTraveler);
    }
    dataObj.phoneNumber = this.Service.flightBookingForm.controls.phone.value;
    dataObj.email = this.Service.flightBookingForm.controls.email.value;
    dataObj.phoneNumberCountryCode =
      this.Service.defultCountry.dial_code.replace("+", "");
    dataObj.searchRequest = {} as any;
    dataObj.searchRequest.cabinClass = this.Service.flightParams.cabinClass;
    dataObj.searchRequest.roundTrip =
      this.Service.flightParams.trip === "ROUNDTRIP";
    dataObj.searchRequest.adultCount = this.travelersArray[0];
    if (this.travelersArray[1]) {
      dataObj.searchRequest.childCount = this.travelersArray[1];
    } else {
      dataObj.searchRequest.childCount = 0;
    }
    dataObj.searchRequest.infantCount = 0;
    dataObj.searchRequest.airQueries = [];
    const airQueriesValues = {} as any;
    airQueriesValues.departureDate = this.Service.flightParams.departureDate;
    airQueriesValues.departureSearchCode =
      this.Service.flightParams.departureCode;
    airQueriesValues.departureSearchType = "AIRPORT";
    airQueriesValues.arrivalSearchCode = this.Service.flightParams.arrivalCode;
    airQueriesValues.arrivalSearchType = "AIRPORT";
    if (this.Service.flightParams.trip === "ROUNDTRIP") {
      airQueriesValues.returnDate = this.Service.flightParams.returnDate;
    }
    dataObj.searchRequest.airQueries.push(airQueriesValues);
    dataObj.airItineraryCode =
      this.Service.flightNewData.details.fareInfo.fareKey;
    dataObj.sellingPrice =
      this.Service.flightNewData.details.fareInfo.sellingTotalFares.total.amount;
    dataObj.paymentGatewayProvider = "STRIPE";

    if (
      this.costBookingState?.selectedCostCenters &&
      this.costBookingState?.selectedCostCenters.length
    ) {
      dataObj.costCenterId = this.costBookingState?.selectedCostCenters[0]?.id;
    }

    if (
      this.costBookingState?.selectedLabels &&
      this.costBookingState?.selectedLabels.length
    ) {
      dataObj.labelsIds = this.costBookingState.selectedLabels?.map(
        (att) => att.id
      );
    }

    if (this.paytype === "STRIPE") {
        if (this.cardholder || this.f.HolderName.errors) {
          this.triggerScrollTo();
          return;
        }
        if (!this.requiresApprovalData) {
          this.Service.flightBookingSubmit = true;
        } else {
          this.submittingApproval = true;
        }
        const name = this.Service.flightBookingForm.controls.HolderName.value;
        if (this.requiresApprovalData) {
          this.stripeService
            .createToken(this.card, { name })
            .subscribe((result) => {
              if (result.token) {
                let dataObjAddPayment = {
                  token: result.token.id,
                  paymentGatewayProvider: "STRIPE",
                  channelType: "CREDIT_CARD",
                };

                this.Service.addPayment(dataObjAddPayment).subscribe(
                  (dataSave) => {
                    this.data = dataSave;
                    if (this.data.errorCode === 0) {
                      dataObj.stripeToken = this.data.data.token;
                      this.cardToken = result.token.id;
                      this.stripeError = "";
                      this.invalidCardNumber = false;
                      dataObj.guestUserId = this.travelerId;
                      if (this.travelerId === "guest") {
                        finalCompanyObj = {
                          bookRequest: dataObj,
                          guestUserId: this.travelerId,
                          guestFirstName:
                            this.Service.flightBookingForm.controls.FirstName
                              .value,
                          guestLastName:
                            this.Service.flightBookingForm.controls.LastName
                              .value,
                          guestEmail:
                            this.Service.flightBookingForm.controls.email.value,
                        };
                      } else {
                        finalCompanyObj = {
                          bookRequest: dataObj,
                          guestUserId: this.travelerId,
                        };
                      }
                      /***********************/
                      if (
                        this.costBookingState?.selectedCostCenters &&
                        this.costBookingState?.selectedCostCenters.length
                      ) {
                        finalCompanyObj.costCenterId =
                          this.costBookingState?.selectedCostCenters[0]?.id;
                      }

                      if (
                        this.costBookingState?.selectedLabels &&
                        this.costBookingState?.selectedLabels.length
                      ) {
                        finalCompanyObj.labelsIds =
                          this.costBookingState.selectedLabels?.map(
                            (att) => att.id
                          );
                      }

                      this.Service.bookingFormFlight(
                        finalCompanyObj,
                        header
                      ).subscribe(
                        (data) => {
                          this.data = data;
                          this.errorMsg = this.data.message;

                          if (this.data.errorCode === 0) {
                            this.googleAnalyticsService.eventEmitter(
                              "FlightStats",
                              "Booking",
                              "Successful",
                              1
                            );
                            this.Service.errorMsg = "";

                            if (this.requiresApprovalData) {
                              this.submittedApproval = true;
                              this.router.navigate([
                                "/" +
                                  this.Auth.companyInfo.id +
                                  "/user/flights/requests/" +
                                  this.data.data.id,
                              ]);
                              this.submittingApproval = false;
                              if (this.getApprovalsList) {
                                this.Service.getFlightListApproversApprovals(
                                  "PENDING_APPROVAL"
                                ).subscribe(
                                  (dataApprove) => {
                                    this.data = dataApprove;
                                    if (this.data.errorCode === 0) {
                                      const pendingArray =
                                        this.data.data.filter(
                                          (item) =>
                                            item.status.toLowerCase() ===
                                            "pending_approval"
                                        );
                                      localStorage.setItem(
                                        "workspaceFlightApprovalsBadge",
                                        pendingArray.length
                                      );
                                      this.Service.workspaceFlightApprovalsBadge =
                                        pendingArray.length;
                                    } else {
                                      this.Service.messages(
                                        this.data.message,
                                        "error"
                                      );
                                    }
                                  },
                                  (error) => {
                                    this.Service.messages(
                                      environment.messages.error,
                                      "error"
                                    );
                                  }
                                );
                              }
                              return;
                            }
                            // GA4
                            let tripType = "One-way";
                            if (
                              this.data.data.bookRequest.searchRequest.roundTrip
                            ) {
                              tripType = "Round-trip";
                            }

                            let name =
                              this.data.data.airItinerary
                                .originDestinationOptionsGroups[0].summary
                                .departureAirport.city +
                              " - " +
                              this.data.data.airItinerary
                                .originDestinationOptionsGroups[0].summary
                                .arrivalAirport.city;
                            let location = "";
                            if (
                              this.data.data.airItinerary
                                .originDestinationOptionsGroups[0].summary
                                .arrivalAirport.city
                            ) {
                              location =
                                this.data.data.airItinerary
                                  .originDestinationOptionsGroups[0].summary
                                  .arrivalAirport.city;
                            }
                            if (
                              this.data.data.airItinerary
                                .originDestinationOptionsGroups[0].summary
                                .arrivalAirport.state
                            ) {
                              location +=
                                ", " +
                                this.data.data.airItinerary
                                  .originDestinationOptionsGroups[0].summary
                                  .arrivalAirport.state;
                            }
                            if (
                              this.data.data.airItinerary
                                .originDestinationOptionsGroups[0].summary
                                .arrivalAirport.country
                            ) {
                              location +=
                                ", " +
                                this.data.data.airItinerary
                                  .originDestinationOptionsGroups[0].summary
                                  .arrivalAirport.country;
                            }
                            this.googleAnalytics4Service.addTransaction(
                              this.data.data.airItinerary.fareInfo
                                .sellingTotalFares.total.currencyCode,
                              this.data.data.hrsReferenceNumber,
                              this.data.data.airItinerary.fareInfo
                                .sellingTotalFares.total.amount,
                              this.data.data.companyId,
                              name,
                              location,
                              "Flight",
                              "Self"
                            );
                            // end of GA4

                            sessionStorage.setItem(
                              "flightVoucher",
                              JSON.stringify({
                                details: this.data,
                                trip: this.Service.flightNewData.trip,
                              })
                            );

                            if (
                              this.changeRememberMe &&
                              !this.requiresApprovalData
                            ) {
                              this.stripeService
                                .createToken(this.card, { name })
                                .subscribe((res) => {
                                  if (res.token) {
                                    let dataObjAddPayment = {
                                      token: res.token.id,
                                      paymentGatewayProvider: "STRIPE",
                                      channelType: "CREDIT_CARD",
                                    };

                                    this.Service.addPayment(
                                      dataObjAddPayment
                                    ).subscribe(
                                      (res) => {
                                        if (this.Service.isCompany()) {
                                          this.router.navigate(
                                            [
                                              "/" +
                                                this.Auth.companyInfo.id +
                                                "/flights/availability/voucher",
                                            ],
                                            {
                                              queryParams: {
                                                ref: this.data.data
                                                  .hrsReferenceNumber,
                                                email:
                                                  this.data.data.bookRequest
                                                    .email,
                                                lastname:
                                                  this.data.data.bookRequest
                                                    .adults[0].lastName,
                                              },
                                            }
                                          );
                                        } else {
                                          this.router.navigate(
                                            ["/flights/availability/voucher"],
                                            {
                                              queryParams: {
                                                ref: this.data.data
                                                  .hrsReferenceNumber,
                                                email:
                                                  this.data.data.bookRequest
                                                    .email,
                                                lastname:
                                                  this.data.data.bookRequest
                                                    .adults[0].lastName,
                                              },
                                            }
                                          );
                                        }
                                      },
                                      (err) => {
                                        if (this.Service.isCompany()) {
                                          this.router.navigate(
                                            [
                                              "/" +
                                                this.Auth.companyInfo.id +
                                                "/flights/availability/voucher",
                                            ],
                                            {
                                              queryParams: {
                                                ref: this.data.data
                                                  .hrsReferenceNumber,
                                                email:
                                                  this.data.data.bookRequest
                                                    .email,
                                                lastname:
                                                  this.data.data.bookRequest
                                                    .adults[0].lastName,
                                              },
                                            }
                                          );
                                        } else {
                                          this.router.navigate(
                                            ["/flights/availability/voucher"],
                                            {
                                              queryParams: {
                                                ref: this.data.data
                                                  .hrsReferenceNumber,
                                                email:
                                                  this.data.data.bookRequest
                                                    .email,
                                                lastname:
                                                  this.data.data.bookRequest
                                                    .adults[0].lastName,
                                              },
                                            }
                                          );
                                        }
                                      }
                                    );
                                  }
                                });
                            } else {
                              if (this.Service.isCompany()) {
                                this.router.navigate(
                                  [
                                    "/" +
                                      this.Auth.companyInfo.id +
                                      "/flights/availability/voucher",
                                  ],
                                  {
                                    queryParams: {
                                      ref: this.data.data.hrsReferenceNumber,
                                      email: this.data.data.bookRequest.email,
                                      lastname:
                                        this.data.data.bookRequest.adults[0]
                                          .lastName,
                                    },
                                  }
                                );
                              } else {
                                this.router.navigate(
                                  ["/flights/availability/voucher"],
                                  {
                                    queryParams: {
                                      ref: this.data.data.hrsReferenceNumber,
                                      email: this.data.data.bookRequest.email,
                                      lastname:
                                        this.data.data.bookRequest.adults[0]
                                          .lastName,
                                    },
                                  }
                                );
                              }
                            }

                            this.notSignInSignUP();
                          } else if (this.data.errorCode === 20) {
                            this.router.navigateByUrl(this.previousUrl);
                            this.Service.messagesOverRoute(
                              this.errorMsg,
                              "warning"
                            );
                            this.Service.flightBookingSubmit = false;
                            this.submittingApproval = false;
                          } else if (this.data.errorCode === 54) {
                            this.Service.flightBookingSubmit = false;
                            this.submittingApproval = false;
                            this.Service.RememberSaveCard =
                              this.changeRememberMe;
                            this.Service.setModal(
                              '<i class="icon-warning"></i> warning',
                              "completeDuplicateBooking",
                              this.data.message
                            );
                          } else {
                            this.googleAnalyticsService.eventEmitter(
                              "FlightStats",
                              "Booking",
                              "Failed",
                              1
                            );
                            this.Service.flightBookingSubmit = false;
                            this.submittingApproval = false;
                            this.Service.errorMsg = this.errorMsg;
                          }
                        },
                        (error) => {
                          this.Service.errorMsg = this.errorMsg;
                          this.Service.flightBookingSubmit = false;
                          this.submittingApproval = false;
                        }
                      );

                      //   }
                    } else {
                      this.Service.bookingSubmit = false;
                      this.submittingApproval = false;
                      this.Service.messages(this.data.message, "error");
                      return;
                    }
                  },
                  (error) => {
                    this.Service.bookingSubmit = false;
                    this.submittingApproval = false;
                    this.Service.messages(environment.messages.error, "error");
                    return;
                  }
                );
              } else if (result.error) {
                // Error creating the token
                this.Service.flightBookingSubmit = false;
                this.submittingApproval = false;
                this.stripeError = result.error.message;
                this.invalidCardNumber = true;
              }
            });
        } else {
          this.stripeService
            .createToken(this.card, { name })
            .subscribe((result) => {
              if (result.token) {
                this.cardToken = result.token.id;
                dataObj.stripeToken = this.cardToken;
                this.stripeError = "";
                this.invalidCardNumber = false;
                /**********************/
                dataObj.guestUserId = this.travelerId;
                if (this.travelerId === "guest") {
                  finalCompanyObj = {
                    bookRequest: dataObj,
                    guestUserId: this.travelerId,
                    guestFirstName:
                      this.Service.flightBookingForm.controls.FirstName.value,
                    guestLastName:
                      this.Service.flightBookingForm.controls.LastName.value,
                    guestEmail:
                      this.Service.flightBookingForm.controls.email.value,
                  };
                } else {
                  finalCompanyObj = {
                    bookRequest: dataObj,
                    guestUserId: this.travelerId,
                  };
                }
                /***********************/
                if (
                  this.costBookingState?.selectedCostCenters &&
                  this.costBookingState?.selectedCostCenters.length
                ) {
                  finalCompanyObj.costCenterId =
                    this.costBookingState?.selectedCostCenters[0]?.id;
                }

                if (
                  this.costBookingState?.selectedLabels &&
                  this.costBookingState?.selectedLabels.length
                ) {
                  finalCompanyObj.labelsIds =
                    this.costBookingState.selectedLabels?.map((att) => att.id);
                }

                this.Service.bookingFormFlight(
                  finalCompanyObj,
                  header
                ).subscribe(
                  (data) => {
                    this.data = data;
                    this.errorMsg = this.data.message;

                    if (this.data.errorCode === 0) {
                      this.googleAnalyticsService.eventEmitter(
                        "FlightStats",
                        "Booking",
                        "Successful",
                        1
                      );
                      this.Service.errorMsg = "";
                      if (this.requiresApprovalData) {
                        this.submittedApproval = true;
                        this.router.navigate([
                          "/" +
                            this.Auth.companyInfo.id +
                            "/user/flights/requests/" +
                            this.data.data.id,
                        ]);
                        this.submittingApproval = false;
                        if (this.getApprovalsList) {
                          this.Service.getFlightListApproversApprovals(
                            "PENDING_APPROVAL"
                          ).subscribe(
                            (dataApprove) => {
                              this.data = dataApprove;
                              if (this.data.errorCode === 0) {
                                const pendingArray = this.data.data.filter(
                                  (item) =>
                                    item.status.toLowerCase() ===
                                    "pending_approval"
                                );
                                localStorage.setItem(
                                  "workspaceFlightApprovalsBadge",
                                  pendingArray.length
                                );
                                this.Service.workspaceFlightApprovalsBadge =
                                  pendingArray.length;
                              } else {
                                this.Service.messages(
                                  this.data.message,
                                  "error"
                                );
                              }
                            },
                            (error) => {
                              this.Service.messages(
                                environment.messages.error,
                                "error"
                              );
                            }
                          );
                        }
                        return;
                      }

                      // GA4
                      let tripType = "One-way";
                      if (this.data.data.bookRequest.searchRequest.roundTrip) {
                        tripType = "Round-trip";
                      }

                      let name =
                        this.data.data.airItinerary
                          .originDestinationOptionsGroups[0].summary
                          .departureAirport.city +
                        " - " +
                        this.data.data.airItinerary
                          .originDestinationOptionsGroups[0].summary
                          .arrivalAirport.city;
                      let location = "";
                      if (
                        this.data.data.airItinerary
                          .originDestinationOptionsGroups[0].summary
                          .arrivalAirport.city
                      ) {
                        location =
                          this.data.data.airItinerary
                            .originDestinationOptionsGroups[0].summary
                            .arrivalAirport.city;
                      }
                      if (
                        this.data.data.airItinerary
                          .originDestinationOptionsGroups[0].summary
                          .arrivalAirport.state
                      ) {
                        location +=
                          ", " +
                          this.data.data.airItinerary
                            .originDestinationOptionsGroups[0].summary
                            .arrivalAirport.state;
                      }
                      if (
                        this.data.data.airItinerary
                          .originDestinationOptionsGroups[0].summary
                          .arrivalAirport.country
                      ) {
                        location +=
                          ", " +
                          this.data.data.airItinerary
                            .originDestinationOptionsGroups[0].summary
                            .arrivalAirport.country;
                      }
                      this.googleAnalytics4Service.addTransaction(
                        this.data.data.airItinerary.fareInfo.sellingTotalFares
                          .total.currencyCode,
                        this.data.data.hrsReferenceNumber,
                        this.data.data.airItinerary.fareInfo.sellingTotalFares
                          .total.amount,
                        this.data.data.companyId,
                        name,
                        location,
                        "Flight",
                        "Self"
                      );
                      // end of GA4
                      sessionStorage.setItem(
                        "flightVoucher",
                        JSON.stringify({
                          details: this.data,
                          trip: this.Service.flightNewData.trip,
                        })
                      );

                      if (this.changeRememberMe && !this.requiresApprovalData) {
                        this.stripeService
                          .createToken(this.card, { name })
                          .subscribe((res) => {
                            if (res.token) {
                              let dataObjAddPayment = {
                                token: res.token.id,
                                paymentGatewayProvider: "STRIPE",
                                channelType: "CREDIT_CARD",
                              };

                              this.Service.addPayment(
                                dataObjAddPayment
                              ).subscribe(
                                (res) => {
                                  if (this.Service.isCompany()) {
                                    this.router.navigate(
                                      [
                                        "/" +
                                          this.Auth.companyInfo.id +
                                          "/flights/availability/voucher",
                                      ],
                                      {
                                        queryParams: {
                                          ref: this.data.data
                                            .hrsReferenceNumber,
                                          email:
                                            this.data.data.bookRequest.email,
                                          lastname:
                                            this.data.data.bookRequest.adults[0]
                                              .lastName,
                                        },
                                      }
                                    );
                                  } else {
                                    this.router.navigate(
                                      ["/flights/availability/voucher"],
                                      {
                                        queryParams: {
                                          ref: this.data.data
                                            .hrsReferenceNumber,
                                          email:
                                            this.data.data.bookRequest.email,
                                          lastname:
                                            this.data.data.bookRequest.adults[0]
                                              .lastName,
                                        },
                                      }
                                    );
                                  }
                                },
                                (err) => {
                                  if (this.Service.isCompany()) {
                                    this.router.navigate(
                                      [
                                        "/" +
                                          this.Auth.companyInfo.id +
                                          "/flights/availability/voucher",
                                      ],
                                      {
                                        queryParams: {
                                          ref: this.data.data
                                            .hrsReferenceNumber,
                                          email:
                                            this.data.data.bookRequest.email,
                                          lastname:
                                            this.data.data.bookRequest.adults[0]
                                              .lastName,
                                        },
                                      }
                                    );
                                  } else {
                                    this.router.navigate(
                                      ["/flights/availability/voucher"],
                                      {
                                        queryParams: {
                                          ref: this.data.data
                                            .hrsReferenceNumber,
                                          email:
                                            this.data.data.bookRequest.email,
                                          lastname:
                                            this.data.data.bookRequest.adults[0]
                                              .lastName,
                                        },
                                      }
                                    );
                                  }
                                }
                              );
                            }
                          });
                      } else {
                        if (this.Service.isCompany()) {
                          this.router.navigate(
                            [
                              "/" +
                                this.Auth.companyInfo.id +
                                "/flights/availability/voucher",
                            ],
                            {
                              queryParams: {
                                ref: this.data.data.hrsReferenceNumber,
                                email: this.data.data.bookRequest.email,
                                lastname:
                                  this.data.data.bookRequest.adults[0].lastName,
                              },
                            }
                          );
                        } else {
                          this.router.navigate(
                            ["/flights/availability/voucher"],
                            {
                              queryParams: {
                                ref: this.data.data.hrsReferenceNumber,
                                email: this.data.data.bookRequest.email,
                                lastname:
                                  this.data.data.bookRequest.adults[0].lastName,
                              },
                            }
                          );
                        }
                      }

                      this.notSignInSignUP();
                    } else if (this.data.errorCode === 20) {
                      this.router.navigateByUrl(this.previousUrl);
                      this.Service.messagesOverRoute(this.errorMsg, "warning");
                      this.Service.flightBookingSubmit = false;
                      this.submittingApproval = false;
                    } else if (this.data.errorCode === 54) {
                      this.Service.flightBookingSubmit = false;
                      this.submittingApproval = false;
                      this.Service.RememberSaveCard = this.changeRememberMe;
                      this.Service.setModal(
                        '<i class="icon-warning"></i> warning',
                        "completeDuplicateBooking",
                        this.data.message
                      );
                    } else {
                      this.googleAnalyticsService.eventEmitter(
                        "FlightStats",
                        "Booking",
                        "Failed",
                        1
                      );
                      this.Service.flightBookingSubmit = false;
                      this.submittingApproval = false;
                      this.Service.errorMsg = this.errorMsg;
                    }
                  },
                  (error) => {
                    this.Service.errorMsg = this.errorMsg;
                    this.Service.flightBookingSubmit = false;
                    this.submittingApproval = false;
                  }
                );
              } else if (result.error) {
                // Error creating the token
                this.Service.flightBookingSubmit = false;
                this.stripeError = result.error.message;
                this.submittingApproval = false;
                this.invalidCardNumber = true;
              }
            });
        }
    } else if (this.paytype === "savedCard") {
      this.CardSelected = this.SelectedsavedCard;
      if (!this.requiresApprovalData) {
        this.Service.flightBookingSubmit = true;
      } else {
        this.submittingApproval = true;
      }
      dataObj.stripeToken = this.CardSelected;

      /**********************/
      dataObj.guestUserId = this.travelerId;
      if (this.travelerId === "guest") {
        finalCompanyObj = {
          bookRequest: dataObj,
          guestUserId: this.travelerId,
          guestFirstName:
            this.Service.flightBookingForm.controls.FirstName.value,
          guestLastName: this.Service.flightBookingForm.controls.LastName.value,
          guestEmail: this.Service.flightBookingForm.controls.email.value,
        };
      } else {
        finalCompanyObj = {
          bookRequest: dataObj,
          guestUserId: this.travelerId,
        };
      }
      /***********************/
      if (
        this.costBookingState?.selectedCostCenters &&
        this.costBookingState?.selectedCostCenters.length
      ) {
        finalCompanyObj.costCenterId =
          this.costBookingState?.selectedCostCenters[0]?.id;
      }

      if (
        this.costBookingState?.selectedLabels &&
        this.costBookingState?.selectedLabels.length
      ) {
        finalCompanyObj.labelsIds = this.costBookingState.selectedLabels?.map(
          (att) => att.id
        );
      }

      this.Service.bookingFormFlight(finalCompanyObj, header).subscribe(
        (data) => {
          this.data = data;
          this.errorMsg = this.data.message;

          if (this.data.errorCode === 0) {
            this.googleAnalyticsService.eventEmitter(
              "FlightStats",
              "Booking",
              "Successful",
              1
            );
            this.Service.errorMsg = "";
            if (this.requiresApprovalData) {
              this.submittedApproval = true;
              this.router.navigate([
                "/" +
                  this.Auth.companyInfo.id +
                  "/user/flights/requests/" +
                  this.data.data.id,
              ]);
              this.submittingApproval = false;
              if (this.getApprovalsList) {
                this.Service.getFlightListApproversApprovals(
                  "PENDING_APPROVAL"
                ).subscribe(
                  (dataApprove) => {
                    this.data = dataApprove;
                    if (this.data.errorCode === 0) {
                      const pendingArray = this.data.data.filter(
                        (item) =>
                          item.status.toLowerCase() === "pending_approval"
                      );
                      localStorage.setItem(
                        "workspaceFlightApprovalsBadge",
                        pendingArray.length
                      );
                      this.Service.workspaceFlightApprovalsBadge =
                        pendingArray.length;
                    } else {
                      this.Service.messages(this.data.message, "error");
                    }
                  },
                  (error) => {
                    this.Service.messages(environment.messages.error, "error");
                  }
                );
              }
              return;
            }

            // GA4
            let tripType = "One-way";
            if (this.data.data.bookRequest.searchRequest.roundTrip) {
              tripType = "Round-trip";
            }

            let name =
              this.data.data.airItinerary.originDestinationOptionsGroups[0]
                .summary.departureAirport.city +
              " - " +
              this.data.data.airItinerary.originDestinationOptionsGroups[0]
                .summary.arrivalAirport.city;
            let location = "";
            if (
              this.data.data.airItinerary.originDestinationOptionsGroups[0]
                .summary.arrivalAirport.city
            ) {
              location =
                this.data.data.airItinerary.originDestinationOptionsGroups[0]
                  .summary.arrivalAirport.city;
            }
            if (
              this.data.data.airItinerary.originDestinationOptionsGroups[0]
                .summary.arrivalAirport.state
            ) {
              location +=
                ", " +
                this.data.data.airItinerary.originDestinationOptionsGroups[0]
                  .summary.arrivalAirport.state;
            }
            if (
              this.data.data.airItinerary.originDestinationOptionsGroups[0]
                .summary.arrivalAirport.country
            ) {
              location +=
                ", " +
                this.data.data.airItinerary.originDestinationOptionsGroups[0]
                  .summary.arrivalAirport.country;
            }
            this.googleAnalytics4Service.addTransaction(
              this.data.data.airItinerary.fareInfo.sellingTotalFares.total
                .currencyCode,
              this.data.data.hrsReferenceNumber,
              this.data.data.airItinerary.fareInfo.sellingTotalFares.total
                .amount,
              this.data.data.companyId,
              name,
              location,
              "Flight",
              "Self"
            );
            // end of GA4

            if (
              this.Service.RemoteConfg &&
              this.Service.RemoteConfg.enable_share_a_sale &&
              this.Service.RemoteConfg.enable_share_a_sale._value === "true" &&
              this.Service.sscid
            ) {
              this.Service.loadImg(
                this.data.data.hrsReferenceNumber,
                this.Service.sscid
              );
            }
            sessionStorage.setItem(
              "flightVoucher",
              JSON.stringify({
                details: this.data,
                trip: this.Service.flightNewData.trip,
              })
            );

            if (this.Service.isCompany()) {
              this.router.navigate(
                [
                  "/" +
                    this.Auth.companyInfo.id +
                    "/flights/availability/voucher",
                ],
                {
                  queryParams: {
                    ref: this.data.data.hrsReferenceNumber,
                    email: this.data.data.bookRequest.email,
                    lastname: this.data.data.bookRequest.adults[0].lastName,
                  },
                }
              );
            } else {
              this.router.navigate(["/flights/availability/voucher"], {
                queryParams: {
                  ref: this.data.data.hrsReferenceNumber,
                  email: this.data.data.bookRequest.email,
                  lastname: this.data.data.bookRequest.adults[0].lastName,
                },
              });
            }
            this.notSignInSignUP();
          } else if (this.data.errorCode === 20) {
            this.router.navigateByUrl(this.previousUrl);

            this.Service.messagesOverRoute(this.errorMsg, "warning");
            this.Service.flightBookingSubmit = false;
            this.submittingApproval = false;
          } else if (this.data.errorCode === 54) {
            this.Service.setModal(
              '<i class="icon-warning"></i> warning',
              "completeDuplicateBooking",
              this.data.message
            );
            this.Service.flightBookingSubmit = false;
            this.submittingApproval = false;
          } else {
            this.Service.errorMsg = this.errorMsg;
            this.googleAnalyticsService.eventEmitter(
              "FlightStats",
              "Booking",
              "Failed",
              1
            );
            this.Service.flightBookingSubmit = false;
            this.submittingApproval = false;
          }
        },
        (error) => {
          this.Service.errorMsg = this.errorMsg;
          this.Service.flightBookingSubmit = false;
          this.submittingApproval = false;
        }
      );
    }
  }

  private resetStatus(): void {
    this.showError = false;
    this.showSuccess = false;
    this.showCancel = false;
  }

  onItemChange(value) {
    this.paymentType = value.target.defaultValue;
    this.Service.flightBookingForm.get("HolderName").setValue("");
  }

  savedCardChange() {
    if (this.SelectedsavedCard !== "new") {
      this.paytype = "savedCard";
      this.showcardControl = false;
      this.changeRememberMe = false;
    } else {
      this.showcardControl = true;
      this.paytype = "STRIPE";
    }
    if (this.Auth.isLogedIn && this.SelectedsavedCard === "new") {
      this.showcardControl = true;
    }
    this.Service.flightBookingForm.get("HolderName").setValue("");
  }

  PayPalConfig(price) {
    const dis = this;
    const myNode = document.getElementById("paypal-button-container");

    if (myNode) {
      while (myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
    }
    const payPalStyle = {
      size: "responsive",
      color: "gold",
      shape: "rect",
      label: "pay",
      height: 55,
    };
    if (this.requiresApprovalData) {
      payPalStyle.color = "blue";
      payPalStyle.label = "checkout";
    }
    if (paypal.Button) {
      paypal.Button.render(
        {
          env: environment.PAYPAL_AUTHEND, // sandbox | production

          style: payPalStyle,

          client: {
            sandbox: environment.paypal,
            production: environment.paypal,
          },

          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,

          // payment() is called when the button is clicked
          payment(data, actions) {
            const startPaypalTime = new Date().getTime();

            // Make a call to the REST api to create the payment

            return actions.payment.create({
              payment: {
                intent: "authorize",
                transactions: [
                  {
                    amount: { total: price, currency: "USD" },
                  },
                ],
              },
            });
          },

          // onAuthorize() is called when the buyer approves the payment
          onAuthorize(data, actions) {
            // Make a call to the REST api to execute the payment
            return actions.payment.execute().then(() => {
              dis.paymentID = data.paymentID;
              dis.paytype = "paypal";
              dis.onSubmit();
            });
          },
          onCancel: function (data, actions) {
            // Show a cancel page or return to cart
          },
        },
        "#paypal-button-container"
      );
    }
  }

  getFrequentFlyerPrograms() {
    if (!this.programsList) {
      this.Service.getAirLoyaltyPrograms().subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.programsList = this.data.data;
          } else {
            this.Service.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.Service.messages(environment.messages.error, "error");
        }
      );
    }
  }

  notSignInSignUP() {
    if (
      !this.Auth.isLogedIn &&
      this.Service.flightBookingForm.controls.password.value !== ""
    ) {
      this.Auth.signUp(
        this.Service.flightBookingForm.controls.FirstName.value,
        this.Service.flightBookingForm.controls.LastName.value,
        this.Service.flightBookingForm.controls.email.value,
        this.Service.flightBookingForm.controls.password.value,
        "",
        ""
      ).subscribe();
    }
  }

  googlePay(price) {
    const dis = this;
    const baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
    };

    const allowedCardNetworks = [
      "AMEX",
      "DISCOVER",
      "INTERAC",
      "JCB",
      "MASTERCARD",
      "VISA",
    ];

    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

    const tokenizationSpecification = {
      type: "PAYMENT_GATEWAY",
      parameters: {
        gateway: "stripe",
        "stripe:version": "2018-10-31",
        "stripe:publishableKey": environment.stripeKey,
      },
    };

    const baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
      },
    };

    /**
     * Describe your site's support for the CARD payment method including optional
     * fields
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
     */
    const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
      tokenizationSpecification: tokenizationSpecification,
    });

    /**
     * An initialized google.payments.api.PaymentsClient object or null if not yet set
     *
     * @see {@link getGooglePaymentsClient}
     */
    let paymentsClient = null;

    /**
     * Configure your site's support for payment methods supported by the Google Pay
     * API.
     *
     * Each member of allowedPaymentMethods should contain only the required fields,
     * allowing reuse of this base request when determining a viewer's ability
     * to pay and later requesting a supported payment method
     *
     * @returns {object} Google Pay API version, payment methods supported by the site
     */
    function getGoogleIsReadyToPayRequest() {
      return Object.assign({}, baseRequest, {
        allowedPaymentMethods: [baseCardPaymentMethod],
      });
    }

    /**
     * Configure support for the Google Pay API
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
     * @returns {object} PaymentDataRequest fields
     */
    function getGooglePaymentDataRequest() {
      let paymentDataRequest: any;
      paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
      paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
      paymentDataRequest.merchantInfo = {
        merchantId: "07587491259641497211",
        merchantName: "Triphop, Inc.",
      };
      return paymentDataRequest;
    }

    function getGooglePaymentsClient() {
      if (paymentsClient === null) {
        paymentsClient = new google.payments.api.PaymentsClient({
          environment: environment.googlePayEnv,
        });
      } else {
        dis.Service.flightBookingPage = false;
      }
      return paymentsClient;
    }

    // function onGooglePayLoaded() {
    if (typeof google !== "undefined") {
      paymentsClient = getGooglePaymentsClient();
    } else {
      dis.Service.flightBookingPage = false;
      return;
    }
    paymentsClient
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(function (response) {
        dis.Service.flightBookingPage = false;

        if (response.result) {
          dis.googlrSuported = true;

          addGooglePayButton();
        }
      })
      .catch(function (err) {
        // show error in developer console for debugging
        dis.errorMsg = environment.messages.error;
        dis.Service.flightBookingPage = false;
      });

    function addGooglePayButton() {
      const myNode = document.getElementById("google-pay-container");

      while (myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
      const paymentsClient = getGooglePaymentsClient();
      const button = paymentsClient.createButton({
        onClick: onGooglePaymentButtonClicked,
      });
      document.getElementById("google-pay-container").appendChild(button);
    }

    function getGoogleTransactionInfo() {
      return {
        countryCode: "US",
        currencyCode: "USD",
        totalPriceStatus: "FINAL",
        // set to cart total
        totalPrice: price,
      };
    }

    /**
     * Prefetch payment data to improve performance
     *
     * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
     */
    function prefetchGooglePaymentData() {
      const paymentDataRequest = getGooglePaymentDataRequest();
      // transactionInfo must be set but does not affect cache
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        currencyCode: "USD",
      };
      const paymentsClient = getGooglePaymentsClient();
      paymentsClient.prefetchPaymentData(paymentDataRequest);
    }

    /**
     * Show Google Pay payment sheet when Google Pay payment button is clicked
     */
    function onGooglePaymentButtonClicked() {
      const paymentDataRequest = getGooglePaymentDataRequest();
      paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

      const paymentsClient = getGooglePaymentsClient();
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(function (paymentData) {
          // handle the response

          processPayment(paymentData);
        })
        .catch(function (err) {
          // show error in developer console for debugging
          dis.errorMsg = environment.messages.error;
          dis.Service.flightBookingPage = false;
        });
    }

    /**
     * Process payment data returned by the Google Pay API
     *
     * @param {object} paymentData response from Google Pay API after user approves payment
     * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
     */
    function processPayment(paymentData) {
      // show returned data in developer console for debugging
      const token = JSON.parse(
        paymentData.paymentMethodData.tokenizationData.token
      ).id;
      dis.paytype = "STRIPE";
      dis.cardToken = token;
      dis.errorMsg = "";
      dis.onSubmit();
    }
  }

  SendBookingFormFields() {
    if (this.Service.mobileView) {
      sessionStorage.setItem(
        "flightBookingFormFields",
        JSON.stringify(this.Service.flightBookingForm.value)
      );
    }
  }

  openSSL() {
    window.open(
      "https://www.ssl.com/team/a5c-1bauoij/site_seals/0532d156-58ecc6a5/site_report",
      "site_report",
      "height=500, width=400, top=100, left=100"
    );
    return false;
  }

  showDetails(itemData, tripType) {
    const flightData = {
      details: itemData,
      trip: tripType,
    };
    this.Service.setRightSideDrawer("flightDetails", flightData);
  }

  formatDate(date) {
    return (
      moment(date).format("ddd") +
      ", " +
      moment(date).format("MMM") +
      " " +
      moment(date).format("DD")
    );
  }
  passportCountryChange(event) {
    let keepSearching = true;
    this.Auth.userInfo.travelDocuments.forEach((element) => {
      if (keepSearching) {
        if (element.issuingCountryCode === event.target.value) {
          keepSearching = false;
          this.Service.flightBookingForm
            .get("passportNumber")
            .setValue(element.number);
          this.Service.flightBookingForm
            .get("passportExpiry")
            .setValue(new Date(element.expiryDate));
          this.formatExpiryDateText =
            moment(element.expiryDate).format("MMM") +
            " " +
            moment(element.expiryDate).format("DD") +
            ", " +
            moment(element.expiryDate).format("YYYY");
          this.expiryDate =
            moment(element.expiryDate).format("YYYY") +
            "-" +
            moment(element.expiryDate).format("MM") +
            "-" +
            moment(element.expiryDate).format("DD");
        } else {
          this.Service.flightBookingForm.get("passportNumber").setValue(null);
          this.Service.flightBookingForm.get("passportExpiry").setValue(null);
          this.formatExpiryDateText = null;
          this.expiryDate = null;
        }
      }
    });
  }

  programChange(event) {
    let keepSearching = true;
    this.Auth.userInfo.frequentFlyerPrograms.forEach((element) => {
      if (keepSearching) {
        if (element.code === event.target.value) {
          keepSearching = false;
          this.Service.flightBookingForm
            .get("programNumber")
            .setValue(element.number);
        } else {
          this.Service.flightBookingForm.get("programNumber").setValue(null);
        }
      }
    });
  }
}
