import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SharedModule } from '../shared/shared.module';
import { SharedAvailabilityModule } from '../availability/shared.availability.module';
import { SharedUserModule } from '../user/shared.user.module';
import { CorporateTravelRoutingModule } from './corporate-travel-routing.module';
import { WorkspaceComponent } from './workspace-landing-page/workspace.component';
import { WorkspaceSignupComponent } from './workspace-signup/workspace-signup.component';
import { CompanyAccountFormComponent } from './company-account-form/company-account-form.component';
import { WorkspaceLoginComponent } from './workspace-login/workspace-login.component';
import { UploadCompanyLogoComponent } from './upload-company-logo/upload-company-logo.component';
import { DndDirective } from './upload-company-logo/dnd.directive';
import { WorkspaceOnboardingComponent } from './workspace-onboarding/workspace-onboarding.component';
import { WorkspaceDashboardHomeComponent } from './workspace-dashboard/workspace-dashboard-home/workspace-dashboard-home.component';
import { CompanyLoginComponent } from './company-login/company-login.component';
import { SendVerificationEmailComponent } from './send-verification-email/send-verification-email.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import { LoginErrorPageComponent } from './login-error-page/login-error-page.component';
import { CompanyInvitationSignupComponent } from './company-invitation-signup/company-invitation-signup.component';
import { LeftMenuComponent } from './workspace-dashboard/left-menu/left-menu.component';
import { SidenavService } from './workspace-dashboard/sidenav.service';
import { MiddleButtonComponent } from './workspace-dashboard/middle-button/middle-button.component';
import { WorkspaceSettingsComponent } from './workspace-all-settings/workspace-settings/workspace-settings.component';
import { CreateTeamComponent } from './workspace-teams/create-team/create-team.component';
import { TeamsListComponent } from './workspace-teams/teams-list/teams-list.component';
import { TeamDetailsComponent } from './workspace-teams/team-details/team-details.component';
import { AddNewPaymentMethodComponent } from './workspace-all-settings/workspace-payments/add-new-payment-method/add-new-payment-method.component';
import { AddTeamMembersComponent } from './workspace-teams/add-team-members/add-team-members.component';
import { UserProfileComponent } from './workspace-user/user-profile/user-profile.component';
import { PoliciesListComponent } from './workspace-all-settings/workspace-policies/policies-list/policies-list.component';
import { CreatePolicyComponent } from './workspace-all-settings/workspace-policies/create-policy/create-policy.component';
import { PolicyDetailsComponent } from './workspace-all-settings/workspace-policies/policy-details/policy-details.component';
import { UsersListComponent } from './workspace-all-settings/workspace-users/users-list/users-list.component';
import { InviteUsersComponent } from './workspace-all-settings/workspace-users/invite-users/invite-users.component';
import { UserDetailsComponent } from './workspace-all-settings/workspace-users/user-details/user-details.component';
import { UserUpdateComponent } from './workspace-all-settings/workspace-users/user-update/user-update.component';
import { UserDeactivateComponent } from './workspace-all-settings/workspace-users/user-deactivate/user-deactivate.component';
import { TagInputModule } from 'ngx-chips';
import { AccountInfoComponent } from './workspace-all-settings/workspace-account/account-info/account-info.component';
import { AccountUpdateComponent } from './workspace-all-settings/workspace-account/account-update/account-update.component';
import { PaymentMethodsListComponent } from './workspace-all-settings/workspace-payments/payment-methods-list/payment-methods-list.component';
import { EditPaymentAliasComponent } from './workspace-all-settings/workspace-payments/edit-payment-alias/edit-payment-alias.component';
import { HotelsListComponent } from './workspace-availability/hotels-list/hotels-list.component';
import { HotelDetailsComponent } from './workspace-availability/hotel-details/hotel-details.component';
import { RouterModule } from '@angular/router';
import { BookingPageComponent } from './workspace-availability/booking-page/booking-page.component';
import { VoucherPageComponent } from './workspace-booking/voucher-page/voucher-page.component';
import { ApprovalsListComponent } from './workspace-approvals/approvals-list/approvals-list.component';
import { RequestsListComponent } from './workspace-user/user-request-approvals/requests-list/requests-list.component';
import { ApprovalsDetailsComponent } from './workspace-approvals/approvals-details/approvals-details.component';
import { ApproveRequestComponent } from './workspace-approvals/approve-request/approve-request.component';
import { DisapproveRequestComponent } from './workspace-approvals/disapprove-request/disapprove-request.component';
import { RequestDetailsComponent } from './workspace-user/user-request-approvals/request-details/request-details.component';
import { BillingSubscriptionComponent } from './workspace-all-settings/workspace-billing-and-plans/billing-subscription/billing-subscription.component';
import { BillingPlansComponent } from './workspace-all-settings/workspace-billing-and-plans/billing-plans/billing-plans.component';
import { BillingHistoryComponent } from './workspace-all-settings/workspace-billing-and-plans/billing-history/billing-history.component';
import { SwitchPlanComponent } from './workspace-all-settings/workspace-billing-and-plans/switch-plan/switch-plan.component';
import { UpdateBillingCardComponent } from './workspace-all-settings/workspace-billing-and-plans/update-billing-card/update-billing-card.component';
import { UserPaymentsListComponent } from './workspace-user/user-payment/user-payments-list/user-payments-list.component';
import { UserFavoritesComponent } from './workspace-user/user-favorites/user-favorites.component';
import { UserTripsComponent } from './workspace-user/user-trips/user-trips.component';
import { EditUserPaymentComponent } from './workspace-user/user-payment/edit-user-payment/edit-user-payment.component';
import { UserManageBookingComponent } from './workspace-user/user-bookings/user-manage-booking/user-manage-booking.component';
import { UserCancelBookingComponent } from './workspace-user/user-bookings/user-cancel-booking/user-cancel-booking.component';
import { WorkspaceDashboardControlComponent } from './workspace-dashboard/workspace-dashboard-control/workspace-dashboard-control.component';
import { UserReactivationFeesComponent } from './workspace-all-settings/workspace-users/user-reactivation-fees/user-reactivation-fees.component';
import { DeactivateSubscriptionComponent } from './workspace-all-settings/workspace-billing-and-plans/deactivate-subscription/deactivate-subscription.component';
import { DeactivateSubscriptionReasonsComponent } from './workspace-all-settings/workspace-billing-and-plans/deactivate-subscription-reasons/deactivate-subscription-reasons.component';
import { WorkspaceDeactivatedAccountComponent } from './workspace-dashboard/workspace-deactivated-account/workspace-deactivated-account.component';
import { WorkspaceReactivateAccountComponent } from './workspace-reactivate-account/workspace-reactivate-account.component';
import { WorkspaceReportsComponent } from './workspace-reports/workspace-reports.component';
import { TransferOwnershipComponent } from './workspace-all-settings/workspace-account/transfer-ownership/transfer-ownership.component';
import { CompanyShareHotelComponent } from './company-shares/company-share-hotel/company-share-hotel.component';
import { CompanyShareVoucherComponent } from './company-shares/company-share-voucher/company-share-voucher.component';
import { SavingsCalculatorComponent } from './savings-calculator/savings-calculator.component';
import { UserProfileImageComponent } from './workspace-user/user-profile-image/user-profile-image.component';
import { IntegrationsListComponent } from './workspace-all-settings/workspace-integrations/integrations-list/integrations-list.component';
import { IntegrationSettingsComponent } from './workspace-all-settings/workspace-integrations/integration-settings/integration-settings.component';
import { RemoveProfileImageComponent } from './workspace-user/remove-profile-image/remove-profile-image.component';
import { UploadExpensesComponent } from './workspace-all-settings/workspace-integrations/upload-expenses/upload-expenses.component';
import { ContactOwnerMsgComponent } from './workspace-all-settings/workspace-billing-and-plans/contact-owner-msg/contact-owner-msg.component';
import { BulkInviteUsersComponent } from './workspace-all-settings/workspace-users/bulk-invite-users/bulk-invite-users.component';
import { CreateEditTeamPageComponent } from './workspace-teams/create-edit-team-page/create-edit-team-page.component';
import { InviteUsersPageComponent } from './workspace-all-settings/workspace-users/invite-users-page/invite-users-page.component';
import { CreateEditPolicyPageComponent } from './workspace-all-settings/workspace-policies/create-edit-policy-page/create-edit-policy-page.component';
import { UserUpdatePageComponent } from './workspace-all-settings/workspace-users/user-update-page/user-update-page.component';
import { FlightListComponent } from './workspace-availability/flight-list/flight-list.component';
import { FlightBookingPageComponent } from './workspace-availability/flight-booking-page/flight-booking-page.component';
import { FlightVoucherPageComponent } from './workspace-booking/flight-voucher-page/flight-voucher-page.component';
import { WorkspaceRewardsSettingsComponent } from './workspace-all-settings/workspace-rewards/workspace-rewards-settings/workspace-rewards-settings.component';
import { WorkspaceRemoveRewardsComponent } from './workspace-all-settings/workspace-rewards/workspace-remove-rewards/workspace-remove-rewards.component';
import { FlightRequestDetailsComponent } from './workspace-user/user-request-approvals/flight-request-details/flight-request-details.component';
import { UserRewardsComponent } from './workspace-user/user-rewards/user-rewards.component';
import { UserManageFlightBookingComponent } from './workspace-user/user-bookings/user-manage-flight-booking/user-manage-flight-booking.component';
import { FlightApprovalsDetailsComponent } from './workspace-approvals/flight-approvals-details/flight-approvals-details.component';
import { UpdateUserInfoComponent } from './workspace-user/update-user-info/update-user-info.component';
import { SlackWorkspaceLoginComponent } from './slack-connection/slack-workspace-login/slack-workspace-login.component';
import { SlackConnectComponent } from './slack-connection/slack-connect/slack-connect.component';
import { CompanyLoginFormComponent } from './workspace-controls/company-login-form/company-login-form.component';
import { WorkspaceLoginFormComponent } from './workspace-controls/workspace-login-form/workspace-login-form.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { SlackInstallationComponent } from './slack-connection/slack-installation/slack-installation.component';
import { UserRewardsHistoryComponent } from './workspace-user/user-rewards-history/user-rewards-history.component';
import { SlackThankYouPageComponent } from './slack-connection/slack-thank-you-page/slack-thank-you-page.component';
import { SlackErrorPageComponent } from './slack-connection/slack-error-page/slack-error-page.component';
import { ChartsModule } from "ng2-charts";
import { UserPasswordComponent } from './workspace-user/user-password/user-password.component';
import { WorkspaceCostsListSettingsComponent } from './workspace-all-settings/workspace-costs/workspace-costs-list/workspace-costs-list.component';
import { CostsManageComponent } from './workspace-all-settings/workspace-costs/workspace-costs-manage/costs-manage.component';
import { CostActivationComponent } from './workspace-all-settings/workspace-costs/cost-activation/cost-activation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CostCentersListComponent } from './workspace-all-settings/workspace-costs/workspace-costs-list/cost-centers-list/cost-centers-list.component';
import { LabelsListComponent } from './workspace-all-settings/workspace-costs/workspace-costs-list/labels-list/labels-list.component';
import { CostReportingDetailsModule } from '../controls/cost-reporting-details/cost-reporting-details.module';
import { DashboardComponent } from './workspace-reports/dashboard/dashboard.component';
import { ReportsComponent } from './workspace-reports/reports/reports.component';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { UpdateUserPreferencesComponent } from './workspace-user/update-user-preferences/update-user-preferences.component';
import { CustomDatepickerI18nService } from '../services/datepicker-localization/custom-datepicker-i18n.service';
import { AccountPreferencesUpdateComponent } from './workspace-all-settings/workspace-account/account-preferences-update/account-preferences-update.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    CreateTeamComponent,
    AddTeamMembersComponent,
    CreatePolicyComponent,
    InviteUsersComponent,
    UserDeactivateComponent,
    UserUpdateComponent,
    EditPaymentAliasComponent,
    ApproveRequestComponent,
    DisapproveRequestComponent,
    UpdateBillingCardComponent,
    SwitchPlanComponent,
    BillingPlansComponent,
    EditUserPaymentComponent,
    UserReactivationFeesComponent,
    DeactivateSubscriptionReasonsComponent,
    WorkspaceReactivateAccountComponent,
    CompanyShareHotelComponent,
    CompanyShareVoucherComponent,
    WorkspaceComponent,
    SavingsCalculatorComponent,
    UserProfileImageComponent,
    RemoveProfileImageComponent,
    UploadExpensesComponent,
    ContactOwnerMsgComponent,
    BulkInviteUsersComponent,
    WorkspaceRemoveRewardsComponent,
    UpdateUserInfoComponent,
    RequestDemoComponent,
    UserPasswordComponent,
    AccountUpdateComponent,
    CostsManageComponent,
    CostActivationComponent,
    CostCentersListComponent,
    LabelsListComponent,
    CommonModule,
    DashboardComponent,
    ReportsComponent,
    TableModule,
    CalendarModule,
    UpdateUserPreferencesComponent,
    AccountPreferencesUpdateComponent
  ],
  imports: [
    ChartsModule,
    TagInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    SharedAvailabilityModule,
    SharedUserModule,
    RouterModule,
    FormsModule,
    SwiperModule,
    CorporateTravelRoutingModule,
    CostReportingDetailsModule,
    TableModule,
    CalendarModule
  ],
  declarations:
    [WorkspaceComponent,
      WorkspaceSignupComponent,
      CompanyAccountFormComponent,
      WorkspaceLoginComponent,
      UploadCompanyLogoComponent,
      DndDirective,
      WorkspaceOnboardingComponent,
      WorkspaceDashboardHomeComponent,
      CompanyLoginComponent,
      SendVerificationEmailComponent,
      LoginErrorPageComponent,
      CompanyInvitationSignupComponent,
      LeftMenuComponent,
      MiddleButtonComponent,
      WorkspaceSettingsComponent,
      CreateTeamComponent,
      TeamsListComponent,
      TeamDetailsComponent,
      AddNewPaymentMethodComponent,
      AddTeamMembersComponent,
      UserProfileComponent,
      PoliciesListComponent,
      CreatePolicyComponent,
      PolicyDetailsComponent,
      UsersListComponent,
      InviteUsersComponent,
      UserDetailsComponent,
      UserUpdateComponent,
      UserDeactivateComponent,
      AccountInfoComponent,
      AccountUpdateComponent,
      PaymentMethodsListComponent,
      EditPaymentAliasComponent,
      HotelsListComponent,
      HotelDetailsComponent,
      BookingPageComponent,
      VoucherPageComponent,
      ApprovalsListComponent,
      RequestsListComponent,
      ApprovalsDetailsComponent,
      ApproveRequestComponent,
      DisapproveRequestComponent,
      RequestDetailsComponent,
      BillingSubscriptionComponent,
      BillingPlansComponent,
      BillingHistoryComponent,
      SwitchPlanComponent,
      UpdateBillingCardComponent,
      UserPaymentsListComponent,
      UserFavoritesComponent,
      UserTripsComponent,
      EditUserPaymentComponent,
      UserManageBookingComponent,
      UserCancelBookingComponent,
      WorkspaceDashboardControlComponent,
      UserReactivationFeesComponent,
      DeactivateSubscriptionComponent,
      DeactivateSubscriptionReasonsComponent,
      WorkspaceDeactivatedAccountComponent,
      WorkspaceReactivateAccountComponent,
      WorkspaceReportsComponent,
      TransferOwnershipComponent,
      CompanyShareHotelComponent,
      CompanyShareVoucherComponent,
      SavingsCalculatorComponent,
      UserProfileImageComponent,
      IntegrationsListComponent,
      IntegrationSettingsComponent,
      RemoveProfileImageComponent,
      UploadExpensesComponent,
      ContactOwnerMsgComponent,
      BulkInviteUsersComponent,
      CreateEditTeamPageComponent,
      InviteUsersPageComponent,
      CreateEditPolicyPageComponent,
      UserUpdatePageComponent,
      FlightListComponent,
      FlightBookingPageComponent,
      FlightVoucherPageComponent,
      WorkspaceRewardsSettingsComponent,
      WorkspaceRemoveRewardsComponent,
      FlightRequestDetailsComponent,
      UserRewardsComponent,
      UserManageFlightBookingComponent,
      FlightApprovalsDetailsComponent,
      UpdateUserInfoComponent,
      SlackWorkspaceLoginComponent,
      SlackConnectComponent,
      CompanyLoginFormComponent,
      WorkspaceLoginFormComponent,
      RequestDemoComponent,
      SlackInstallationComponent,
      UserRewardsHistoryComponent,
      SlackThankYouPageComponent,
      SlackErrorPageComponent,
      UserPasswordComponent,
      WorkspaceCostsListSettingsComponent,
      CostsManageComponent,
      CostActivationComponent,
      CostCentersListComponent,
      LabelsListComponent,
      DashboardComponent,
      ReportsComponent,
      UpdateUserPreferencesComponent,
      AccountPreferencesUpdateComponent
    ],
  providers: [SidenavService, CustomDatepickerI18nService],
})
export class CorporateTravelModule { }
