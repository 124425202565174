<div class="container my-5" [ngClass]="{ 'd-none': Service.bookingPage }">
  <div class="row">
    <div class="col-md-8">
      <app-workspace-booking-form
        *ngIf="Service.isCompany()"
      ></app-workspace-booking-form>
    </div>

    <div class="col-md-4 hotel-details-side d-md-block d-none">
      <div class="white-box p-0 mb-4" *ngIf="Service.hotelData">
        <figure
          class="hotel-img-booking"
          *ngIf="
            Service.hotelData.images &&
            Service.hotelData.images[0] &&
            Service.hotelData.images[0].url.includes('https://')
          "
          [ngStyle]="{
            'background-image': 'url(' + Service.hotelData.images[0].url + ')'
          }"
        ></figure>

        <figure
          class="hotel-img-booking"
          *ngIf="
            Service.hotelData.images &&
            Service.hotelData.images[0] &&
            !Service.hotelData.images[0].url.includes('https://')
          "
          [ngStyle]="{
            'background-image':
              'url(' +
              Service.hotelData.images[0].url.replace('http://', 'https://') +
              ')'
          }"
        ></figure>

        <ng-container
          *ngIf="!Service.hotelData.images || !Service.hotelData.images[0]"
        >
          <img
            class="rounded-top booking-form-img-hotel w-100"
            src="assets/img/no_image.png"
          />
        </ng-container>
        <div class="p-4">
          <div class="border-bottom pb-3">
            <h4 class="primaray-text font-weight-bold">
              {{ Service.hotelData.name }}
            </h4>
            <div class="address-inner gray-text">
              <i class="icon-locator gray-text"></i>
              {{ Service.hotelData.cityName ? Service.hotelData.cityName : ""
              }}{{
                Service.hotelData.stateCode && Service.hotelData.cityName
                  ? ", "
                  : ""
              }}{{
                Service.hotelData.stateCode ? Service.hotelData.stateCode : ""
              }}{{
                (Service.hotelData.stateCode || Service.hotelData.cityName) &&
                Service.hotelData.countryName
                  ? ", "
                  : ""
              }}{{
                Service.hotelData.countryName
                  ? Service.hotelData.countryName
                  : ""
              }}
            </div>
          </div>

          <div
            class="pb-2"
            [ngClass]="{
              'border-bottom pt-2':
                Service.FacilitiesFeaturedavailabile === true
            }"
          >
            <ul
              *ngIf="Service.hotelData"
              class="facilities-main mb-0 list-unstyled"
            >
              <ng-container *ngFor="let item of Service.hotelData.myFacilities">
                <ng-container *ngIf="item.featured">
                  <li class="facilities-main-item d-inline-block mb-2 mt-2">
                    <span
                      class="d-block"
                      [ngClass]="{
                        'pr-5': Service.isSiteLtr(),
                        'pl-5 pr-0': Service.isSiteRtl()
                      }"
                    >
                      <i [class]="'icon-' + item.hrsCode"></i> {{ item.title }}
                    </span>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="px-4">
          <div class="margin_bottom">
            <p *ngIf="Service.roomName" class="bolder">
              {{ Service.roomName }}
            </p>
          </div>
          <div
            *ngIf="
              this.Service.newRate && this.Service.newRate.hrsBoardCode != 'RO'
            "
            class="mb-3"
          >
            <i [ngClass]="'icon-' + this.Service.newRate.boardCode"> </i
            >{{ this.Service.newRate.boardName }}
          </div>
          <ng-container *ngIf="Service.params">
            <p>
              <span [ngClass]="{ bolder: !Service.isCompany() }"
                ><ng-container i18n="@@checkInNoDash">Check In</ng-container
                >:</span
              >
              {{ Service.params.checkIn | date : "EE, MMM d " }}
            </p>
            <p>
              <span [ngClass]="{ bolder: !Service.isCompany() }"
                ><ng-container i18n="@@checkOutNoDash">Check Out</ng-container
                >:</span
              >
              {{ Service.params.checkOut | date : "EE, MMM d " }}
            </p>
          </ng-container>

          <p class="margin_top">
            <span [ngClass]="{ bolder: !Service.isCompany() }"
              ><ng-container i18n="@@youSelected">You selected</ng-container>:
            </span>

            <span
              class="badge badge-secondary d-inline-block"
              [ngClass]="{
                'mr-1': Service.isSiteLtr(),
                'ml-1': Service.isSiteRtl()
              }"
              >{{ diffInDays }}
              <ng-container *ngIf="diffInDays === 1" i18n="@@nightNoNumber"
                >night</ng-container
              >
              <ng-container *ngIf="diffInDays === 2" i18n="@@twoNightsNoNumber"
                >nights</ng-container
              >
              <ng-container *ngIf="diffInDays > 2" i18n="@@nights"
                >nights</ng-container
              >
              &nbsp;
              <ng-container i18n="@@stay">Stay</ng-container>
            </span>
            <span class="badge badge-secondary d-inline-block">
              {{PaxesCount}}
              <ng-container *ngIf="PaxesCount === 1" i18n="@@guest">
                Guest</ng-container
              >
              <ng-container *ngIf="PaxesCount === 2" i18n="@@twoGuests"
                >Guests</ng-container
              >
              <ng-container *ngIf="PaxesCount > 2" i18n="@@guests"
                >Guests</ng-container
              >
            </span>
          </p>
        </div>
      </div>

      <div
        class="white-box mb-4 pb-4 pt-3 text-center position-relative"
        *ngIf="Service.hotelData && Service.hotelData.googleReview"
      >
        <div class="google-review-cont">
          <div
            class="super-big font-weight-bold gray-primaray-text align-middle d-inline-block"
            *ngIf="Service.hotelData.googleReview"
          >
            {{ Service.hotelData.googleReview.rating }}
          </div>
          <div
            class="d-inline-block align-middle"
            [ngClass]="{
              'text-left': Service.isSiteLtr(),
              'text-right': Service.isSiteRtl()
            }"
          >
            <div
              class="gray-text-dark font-weight-bold"
              *ngIf="Service.hotelData.googleReview.ratingDescription"
            >
              {{ Service.hotelData.googleReview.ratingDescription }}
            </div>
            <div class="rating-stars-google d-inline-block">
              <div
                class="rating-active"
                [style.width.%]="
                  Service.hotelData.googleReview.ratingPercentage * 100
                "
              ></div>
            </div>
            <br />
            <button
              (click)="
                this.Service.setModal(
                  reviewTitle,
                  'GoogleReview',
                  Service.hotelData
                )
              "
              class="btn-link d-inline-block"
            >
              <span
                [ngClass]="{
                  'mr-2': Service.isSiteLtr(),
                  'ml-2': Service.isSiteRtl()
                }"
                >{{
                  Service.hotelData.googleReview.userRatingsTotal
                    | number : "" : "en-US"
                }}</span
              ><ng-container i18n="@@reviews">reviews</ng-container>
            </button>
          </div>

          <div
            class="water-mark-google gray-text font-12"
            i18n="@@poweredByGoogle"
          >
            Powered by Google
          </div>
        </div>
      </div>
      <div
        *ngIf="tripAdviserdata && TripAdviserRating"
        class="white-box p-3 mb-4"
      >
        <div class="tripadvisor tripadvisor-container text-center">
          <img
            class="d-inline-block align-middle mt-1"
            src="assets/img/tripadvisor_logo.png"
          />
          <div
            class="trip-adviser-cont position-relative d-inline-block align-middle mt-1"
            [ngClass]="{
              'ml-2': Service.isSiteLtr(),
              'mr-2': Service.isSiteRtl()
            }"
          >
            <div class="trip-adviser-holder d-inline-block">
              <div class="trip-adviser-c"></div>

              <div class="trip-adviser-c"></div>
              <div class="trip-adviser-c"></div>
              <div class="trip-adviser-c"></div>
              <div class="trip-adviser-c"></div>
            </div>
            <div
              [style.width.%]="TripAdviserRating"
              class="trip-adviser-holder d-inline-block position-absolute"
            >
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
              <div class="trip-adviser-d"></div>
            </div>
          </div>
          <div class="reviews mt-2">
            <a
              [href]="tripAdviserdata.data.link"
              target="_blank"
              class="underkined"
              (click)="tripAdvisorTrigger()"
              i18n="@@basedOnReviews"
              >Based on
              {{
                tripAdviserdata.data.totalNumberOfReviews
                  | number : "" : "en-US"
              }}
              Reviews
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="this.Service.newRate">
        <div
          class="white-box p-0 mb-4 price_summary"
          *ngIf="
            this.Service.newRate &&
            this.Service.newRate &&
            this.Service.newRate.dailyRates
          "
        >
          <div class="p-3">
            <h5
              class="text-big mb-3"
              [ngClass]="{ bolder: !Service.isCompany() }"
              i18n="@@yourPriceSummary"
            >
              Your Price Summary
            </h5>
            <div
              class="daily"
              *ngFor="let daily of this.Service.newRate.dailtyRates"
            >
              <div class="d-flex pb-1 text-medium">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  >{{ daily.date | date : "EE, MMM d " }}
                </span>
                <span
                  class="bolder"
                  [ngClass]="{
                    'ml-auto': Service.isSiteLtr(),
                    'mr-auto': Service.isSiteRtl()
                  }"
                  >{{
                    this.Service.newRate.averageNightlyConvertedCurrency
                  }} {{
                    this.Service.newRate.averageNightlyConverted | number
                  }}</span
                >
              </div>
            </div>
            <ng-container
              *ngIf="
                Auth.isPrime && this.Service.newRate.savings;
                else sellingPrice
              "
            >
              <div
                class="daily"
                *ngFor="let daily of this.Service.newRate.dailyRates"
              >
                <div class="d-flex pb-1 text-medium">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                  >
                    {{ daily.date | date : "EE, MMM d " }}
                  </span>
                  <span
                    class="bolder"
                    [ngClass]="{
                      'ml-auto': Service.isSiteLtr(),
                      'mr-auto': Service.isSiteRtl()
                    }"
                  >
                  {{ daily.sellingPriceConvertedCurrency }} {{ daily.sellingPriceConverted | number }}
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-template #sellingPrice>
              <div
                class="daily"
                *ngFor="let daily of this.Service.newRate.dailyRates"
              >
                <div class="d-flex pb-1 text-medium">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                  >
                    {{ daily.date | date : "EE, MMM d " }}
                  </span>
                  <span
                    class="bolder"
                    [ngClass]="{
                      'ml-auto': Service.isSiteLtr(),
                      'mr-auto': Service.isSiteRtl()
                    }"
                  >
                  {{ daily.sellingPriceConvertedCurrency }} {{ daily.sellingPriceConverted | number }}
                  </span>
                </div>
              </div>
            </ng-template>
            <div
              class="discount d-flex text-medium"
              *ngIf="
                this.Service.newRate &&
                this.Service.newRate.userData.travelerId === 'guest'
              "
            >
              <span
                [ngClass]="{
                  'mr-auto': Service.isSiteLtr(),
                  'ml-auto': Service.isSiteRtl()
                }"
                i18n="@@bookingFee"
              >
                Booking fee</span
              >
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                >${{
                  this.Service.newRate.guestBookingFee | number : "1.2-2"
                }}</span
              >
            </div>
            <div
              class="discount d-flex text-medium"
              *ngIf="!this.Service.newRate.taxIncluded"
            >
              <span
                [ngClass]="{
                  'mr-auto': Service.isSiteLtr(),
                  'ml-auto': Service.isSiteRtl()
                }"
                i18n="@@taxAndFees"
              >
                Tax and fees</span
              >
              <span
                class="bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                >${{ this.Service.newRate.tax | number }}</span
              >
            </div>
          </div>
          <div class="white-box-footer rounded-bottom p-3">
            <div class="d-flex font-20">
              <span
                class="primaray-text"
                [ngClass]="{ bolder: !Service.isCompany() }"
                i18n="@@totalPayNow"
              >
                Total to pay now:
              </span>
              <span
                class="primaray-text bolder"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                ><span class="currency">{{this.Service.newRate.finalSellingPriceConvertedCurrency}}</span
                ><span class="amount"> {{
                  this.Service.newRate.finalSellingPriceConverted
                }} </span></span
              >
            </div>
          </div>
        </div>
        <div
          class="white-box p-0"
          *ngIf="
            this.Service.newRate.userData &&
            this.Service.newRate.userData.rewardAmount
          "
        >
          <div
            class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"
          >
            <ng-container i18n="@@rewards">Rewards</ng-container>:
            <ng-container *ngIf="Service.isSiteLtr()">$</ng-container
            >{{ this.Service.newRate.userData.rewardAmount | number : "1.2-2"
            }}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
          </div>
          <div
            class="px-3 pt-3 pb-4 gray-primaray-text font-16"
            i18n="@@uponCompleting"
          >
            You will earn ${{
              this.Service.newRate.userData.rewardAmount | number : "1.2-2"
            }}
            upon completing this stay
          </div>
        </div>
      </div>

      <app-cost-reporting-manage></app-cost-reporting-manage>

      <div class="mt-3">
        <div class="not-mobile">
          <br />
          <h5 class="text-medium bolder mb-2" i18n="@@whyBookWithUs">
            Why Book With Us?
          </h5>
          <div class="checks-container">
            <ul class="checks list-unstyled">
              <li class="mb-1" i18n="@@secureBooking">Secure Booking</li>
              <li class="mb-1" i18n="@@priceGuarantee">Price Guarantee</li>
              <li class="mb-1" i18n="@@247support">24/7 Customer Support</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  [ngClass]="{ active: this.Service.ratesExpiry }"
  class="snackbar d-block d-md-flex"
>
  <p>
    <i
      class="icon-warning"
      [ngClass]="{ 'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl() }"
    ></i>
    &nbsp;<ng-container i18n="@@refreshHotel"
      >Refresh this hotel page to get the latest rates.</ng-container
    >
  </p>
  <a
    class="text-uppercase col-md d-block"
    [ngClass]="{
      'text-md-right': Service.isSiteLtr(),
      'text-md-left': Service.isSiteRtl()
    }"
    (click)="refreshPage()"
  >
    <i
      class="icon-refresh font-16"
      [ngClass]="{ 'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl() }"
    ></i
    >&nbsp;<ng-container i18n="@@refresh">refresh</ng-container></a
  >
</div>

<app-loaders></app-loaders>
