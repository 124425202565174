
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
   <!--   <div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a> <span class="font-18 gray-text-dark font-weight-bold"> / </span>

        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/integrations']);">Integrations</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Expensify </span>
      </div>-->

      <div class="container pb-5 pt-4 pt-lg-5" >
        <div class="mx-lg-5">
        <div class="row">

          <div class="col">
            <div class="pb-4 pb-lg-5 mb-3">
              <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);" i18n="@@menuItemSettings">Settings</a> <span class="font-16 gray-text-dark"> / </span>
              <a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/integrations']);" i18n="@@integrations">Integrations</a>
              <span class="font-16 gray-text-dark "> / Expensify </span>
            </div>
          </div>

        </div>
        <div class="row mb-5">
          <div class="col-12">
            <p class="gray-text-dark font-16" i18n="@@integrationsMsg">Members will be able to upload all expenses in one click into their Expensify account..</p>
          </div>
        </div>

        <div class="row pb-5" >
          <div class="col-lg-6" >
            <div class="row">
              <div class="col-lg-8 fields-min-width" *ngIf="loaded">
                <img width="150" height="23" loading="lazy" class="max-100 initial-height mb-3" src="/assets/img/expensify-logo--reverse.png" />
                <p class="mb-5">{{connected ? connectedLocalizedText : disconnectedLocalizedText}} <i class="icon-filled-circle smaller"
                                                                                [ngClass]="{'green-text': connected, 'text-danger': !connected}"></i></p>

                <form [formGroup]="integrationSettingForm" name="userAddressForm">
                  <div>

                    <div class="form-group mb-4">
                      <label for="partnerUserID" class="gray-primaray-text font-16" i18n="@@partnerId">Partner User ID</label>
                      <input *ngIf="!savedIntegrationData" id="partnerUserID" formControlName="partnerUserID" name="partnerUserID" class="form-control placeholder-text-field"
                             [ngClass]="{ 'is-invalid': submitted && ctrl.partnerUserID.errors }" >
                      <div class="form-control dots-div gray-text" *ngIf="savedIntegrationData">
                        {{this.expensifyId}}
                      </div>
                      <div *ngIf="submitted && ctrl.partnerUserID.errors" class="text-danger my-2">
                        <div *ngIf="ctrl.partnerUserID.errors.required" i18n="@@partnerUserIDrequired">Partner User ID is required</div>
                      </div>
                    </div>


                    <div class="form-group mb-4">
                      <label for="partnerUserSecret" class="gray-primaray-text font-16" i18n="@@partnerUserSecret">Partner User Secret</label>
                     <div class="form-control dots-div" *ngIf="savedIntegrationData">
                       <i *ngFor="let item of [].constructor(16); let i = index" class="icon-filled-circle smaller gray-text"
                          [ngClass]="{'mr-1': dataService.isSiteLtr(), 'ml-1': dataService.isSiteRtl()}"></i>
                    </div>
                      <!-- <input id="partnerUserSecret" type="text" formControlName="partnerUserSecret" name="partnerUserSecret" class="form-control placeholder-text-field">-->
                        <input *ngIf="!savedIntegrationData" #partnerUserSecret id='partnerUserSecret' autocapitalize="off"  autocomplete="off"  type="text"  name="partnerUserSecret"
                               class="form-control" formControlName="partnerUserSecret" [type]="this.dataService.isTextFieldType ? 'password' : 'text'"
                               (keydown)="checkCarPressed($event)" (keyup)="checkCarReleased($event)" (focus)="partnerUserSecret.select()"
                              [ngClass]="{ 'is-invalid': submitted && isSecretEmpty }"/>
                      <div *ngIf="submitted && isSecretEmpty" class="text-danger my-2">
                        <div *ngIf="isSecretEmpty" i18n="@@partnerUserSecretRequired">Partner User Secret is required</div>
                      </div>
                    </div>

                  </div>

                  <div class="row d-flex mb-3" >
                    <div class="col-md-6 order-lg-1" [ngClass]="{'pl-lg-2': connected || savedIntegrationData}" *ngIf="!connected">
                      <button *ngIf="!savedIntegrationData" class="mb-3 mb-lg-5 mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" (click)="connect()">
                        <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@connect">Connect</span>
                      </button>
                      <button *ngIf="savedIntegrationData" class="mb-3 mb-lg-5 mt-3 px-0 blue_btn btn-block sharp-font bold-500 d-flex px-0" (click)="enableAccount(true)">
                        <span class="mx-auto font-14" [ngClass]="{'m-progress': submitting}" i18n="@@connect">Connect</span>
                      </button>
                    </div>
                    <div class="col-md-6 order-lg-0" [ngClass]="{'pr-lg-2': dataService.isSiteLtr(), 'pl-lg-2': dataService.isSiteRtl()}" *ngIf="connected || savedIntegrationData">
                      <button  (click)="enableAccount(false);" *ngIf="disconnectOrRemoveCTA === this.disconnectLocalizedText"
                              class="mb-5 mt-3 btn-block btn btn-outline-red d-flex px-5" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        <span class="mx-auto text text-danger font-14 sharp-font bold-500">{{disconnectOrRemoveCTA}}</span>
                      </button>
                      <button  (click)="removeAccount();" *ngIf="disconnectOrRemoveCTA === this.removeAccountLocalizedText;"
                               class="mb-5 mt-3 btn-block btn btn-outline-red d-flex px-0" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        <span class="mx-auto text text-danger font-14 sharp-font bold-500">{{disconnectOrRemoveCTA}}</span>
                      </button>
                    </div>
                  </div>
                  <p class="text-danger mt-3">{{errorMsg}}</p>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6"  *ngIf="dataService.desktopView">
            <div class="rounded-bottom p-4 font-14 right-box-style company-section-with-thick-border thick-top"
                 [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
              <p class="mb-5 font-16 bold-600 gray-primaray-text" i18n="@@expensifyInteg">Expensify integration</p>
          <div class="font-14 gray-text-dark">
              <p><ng-container i18n="@@integrationsMsg1">1- Click on</ng-container>&nbsp;<a class="blue-text border-0" href="https://www.expensify.com/tools/integrations/" target="_blank">https://www.expensify.com/tools/integrations</a></p>
              <p i18n="@@integrationsMsg2"> 2- Login with your Expensify account</p>
              <p i18n="@@integrationsMsg3"> 3- A pair of credentials: partnerUserID and partnerUserSecret will be generated and shown on the page.</p>
              <p i18n="@@integrationsMsg4"> 4- If you already have the credentials generated, contact Expensify account admin to get the partnerUserSecret or click on the regenerate your partnerUserSecret.</p>
          </div>
            </div>
          </div>
        </div>
        </div>
      </div>




    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





