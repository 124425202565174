<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
    [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <div class="container pt-4 pt-lg-5 ">
        <div class="row">
          <div class="col-lg-9" [ngClass]="{'px-0': dataService.mobileView}">
            <div class="row font-16-to-14 font-weight-bold filtering blue-text rounded pb-2">
              <div class="col-auto py-1 pr-0">
                <span class="pointer px-lg-4 font-24 gray-primaray-text bold-600"
                  [ngClass]="{'border-right': dataService.isSiteLtr(), 'border-left': dataService.isSiteRtl(), 'inactive' : !isDashboard, 'pl-4 pr-3': dataService.mobileView && dataService.isSiteLtr(), 'pr-4 pl-3': dataService.mobileView && dataService.isSiteRtl()}"
                  (click)="setIsDashboard()"
                  i18n="@@dashboard">
                  Dashboard
                </span>
              </div>
              <div class="col-auto py-1">
                <span class="pointer font-24 gray-primaray-text bold-600"
                  [ngClass]="{'mr-lg-auto': dataService.isSiteLtr(), 'ml-lg-auto': dataService.isSiteRtl(), 'inactive' : isDashboard, 'pr-4 pl-3': dataService.mobileView && dataService.isSiteLtr(), 'pl-4 pr-3': dataService.mobileView && dataService.isSiteRtl()}"
                  (click)="setIsDashboard(false)"
                  i18n="@@menuItemReports">
                  Reports
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isDashboard">
          <app-dashboard></app-dashboard>
        </div>

        <div *ngIf="!isDashboard">
          <app-reports></app-reports>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>