import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceMain } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { VariablesService } from 'src/app/services/vars.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CostManagementService } from 'src/app/services/cost-management.service';
import { environment } from 'src/environments/environment';
import { Calendar } from 'primeng/calendar';
import { StatisticsService } from 'src/app/services/statistics.service';
import { StatisticsFilter, Statistics } from 'src/app/shared/model/statistics/statistics';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  loader: boolean = false;
  isSubmitted: boolean = false;
  btnLoader: boolean = false;

  travelers: any[] = [];
  costCenters: any[] = [];
  labels: any[] = [];
  selectedTravelers: any[] = [];
  selectedCostCenters: any[] = [];
  selectedLabels: any[] = [];
  travelerDropdownSettings: IDropdownSettings = {};
  costCentersDropdownSettings: IDropdownSettings = {};
  labelsDropdownSettings: IDropdownSettings = {};

  bookingStatistics: Statistics = new Statistics();
  statisticsFilter: StatisticsFilter = new StatisticsFilter();

  date: Date[] | undefined;
  maxDate: Date;
  isValidRange: boolean = true;
  displayedColumns = displayedColumns;

  constructor(
    public vars: VariablesService,
    public router: Router,
    public dataService: DataService,
    public costManagementService: CostManagementService,
    public statisticsService: StatisticsService,
    public auth: AuthServiceMain) {
    this.statisticsFilter = new StatisticsFilter();
  }

  ngOnInit(): void {
    this.maxDate = new Date();

    this.getAsyncData();
    this.prepareDropDownSettings();
  }

  getAsyncData() {
    this.loader = true;
    Promise.all([this.getTraveler(), this.getCostCenters(), this.getLabels()])
      .then((result: any) => {
        this.travelers = result[0]?.data ?? [];
        this.costCenters = result[1]?.data ?? [];
        this.labels = result[2]?.data ?? [];

        if (result[0].errorCode) {
          this.handleFailed(result[0].message);
        }

        if (result[1].errorCode) {
          this.handleFailed(result[1].message);
        }

        if (result[2].errorCode) {
          this.handleFailed(result[2].message);
        }

        const guest = { description: $localize`:@@guest:Guest`, id: 'guest' };
        this.travelers.push(guest);
      })
      .catch((error) => {
        this.handleFailed(error.message);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  handleFailed(error) {
    this.dataService.messages(error ?? environment.messages.error, 'error');
  }

  getTeams() {
    return this.dataService.getTeamsList().toPromise();
  }

  getTraveler() {
    return this.dataService.getCompanyUsersList().toPromise();
  }

  getCostCenters() {
    return this.costManagementService.getCostCenters().toPromise();
  }

  getLabels() {
    return this.costManagementService.getLabels().toPromise();
  }

  generateReport() {
    this.isSubmitted = true;
    if (!this.date || !this.isValidRange) {
      return;
    }

    this.btnLoader = true;

    this.statisticsFilter.travelersIds = this.selectedTravelers.map(att => att.id);
    this.statisticsFilter.costCentersIds = this.selectedCostCenters.map(att => att.id);
    this.statisticsFilter.labelsIds = this.selectedLabels.map(att => att.id);
    this.statisticsFilter.startDate = this.getFormattedDate(this.date[0]);
    this.statisticsFilter.endDate = !this.date[1] ? this.getFormattedDate(this.date[0]) : this.getFormattedDate(this.date[1]);

    this.statisticsService.getBookingsStatistics(this.statisticsFilter).subscribe(
      (result: any) => {
        if (result.errorCode) {
          this.handleFailed(result.message);
          return;
        }

        this.bookingStatistics = result.data;
        this.isSubmitted = false;
      },
      (error) => {
        this.handleFailed(error.message);
      }
    ).add(() => {
      this.btnLoader = false;
    })
  }

  toggleCalendar(calendar: Calendar) {
    calendar.overlayVisible = false;
  }

  onSelectDate() {
    if (!this.date[1]) {
      return;
    }

    this.isValidRange = !this.isDateRangeMoreThanOneYear(this.date);
  }

  exportCSV(dt) {
    dt.exportFilename = "Reports";
    dt._value = dt._value.map((att) => {
      return {
        ...att,
        bookingDate: this.dataService.formatDateMMMdy(att.bookingDate),
        startDate: this.dataService.formatDateMMMdy(att.startDate),
        endDate: this.dataService.formatDateMMMdy(att.endDate),
      }
    })
    dt.exportCSV();
  }

  isDateRangeMoreThanOneYear(dates: Date[]): boolean {
    const startDate = dates[0];
    const endDate = dates[1];
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    return timeDifference > oneYearInMilliseconds;
  }

  getFormattedDate(date: Date = null): string {
    if (!date) {
      return '';
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  prepareDropDownSettings() {
    const sharedDDLSettings = {
      singleSelection: false,
      idField: 'id',
      itemsShowLimit: 3,
      limitSelection: 5,
      allowSearchFilter: true
    };

    this.travelerDropdownSettings = { ...sharedDDLSettings, textField: 'description', searchPlaceholderText: $localize`:@@searchBtn:Search` };
    this.costCentersDropdownSettings = { ...sharedDDLSettings, textField: 'name', searchPlaceholderText: $localize`:@@searchBtn:Search` };
    this.labelsDropdownSettings = { ...sharedDDLSettings, textField: 'name', searchPlaceholderText: $localize`:@@searchBtn:Search` };
  }
}

const displayedColumns = [
  { header: 'Booking Date', field: 'bookingDate' },
  { header: 'Traveler FName', field: 'travelerFirstName' },
  { header: 'Traveler LName', field: 'travelerLastName' },
  { header: 'Traveler Email', field: 'travelerEmail' },
  { header: 'Booker', field: 'booker' },
  { header: 'Traveler Type', field: 'travelerType' },
  { header: 'Start Date', field: 'startDate' },
  { header: 'End Date', field: 'endDate' },
  { header: 'Service', field: 'service' },
  { header: 'Status', field: 'status' },
  { header: 'Amount', field: 'amount' },
  { header: 'Refund', field: 'refund' },
  { header: 'Policy Name', field: 'policyName' },
  { header: 'In policy', field: 'inPolicy' },
  { header: 'Approval Required', field: 'approvalRequired' },
  { header: 'Approver Name', field: 'approverName' },
  { header: 'Destination Country', field: 'destinationCountry' },
  { header: 'Destination City', field: 'destinationCity' },
  { header: 'Ref Number', field: 'refNumber' },
  { header: 'Cost Center', field: 'costCenter' },
  { header: 'Labels', field: 'labels' }
];