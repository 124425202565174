import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {Title} from "@angular/platform-browser";
import {letProto} from "rxjs-compat/operator/let";
import {AuthServiceMain} from "../../auth/auth.service";

@Component({
  template: `<div class='full-white-loader'> </div>`,
})
export class RedirectComponent implements OnInit {
params;
  data;
  searchQuery;
  checkIn = '';
  checkOut = '';
  paxes = '2';
  constructor( public route: ActivatedRoute, public logedIn: AuthServiceMain, public router: Router, public Service: DataService, private titleService:Title) {
    this.titleService.setTitle('Redirecting ...'); }

  ngOnInit() {
debugger

    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (this.params.city || this.params.state) {

        this.Service.autoComplete(this.params.city + ', ' +  this.params.state, '').subscribe(
          suc => {

            this.data = suc;
            if(!this.data.data.length){
              this.router.navigate(['/']);

            }
           this.searchQuery = this.data.data[0].entities[0].searchQuery;

            if (this.params.paxes) {
              this.paxes = this.params.paxes;
              if (this.params.children) {
                let childrenArray = [];

                let children = Number(this.params.children) ;
                for (let child = 0; child < children; child++) {
                  childrenArray.push(child+1)

                }

                this.paxes = this.params.paxes + ',' + childrenArray.toString();


              }

            }

            if (this.params.checkIn) {
              this.checkIn = this.params.checkIn
            } else{
              this.checkIn = 'null'

            }
            if (this.params.checkOut) {
              this.checkOut = this.params.checkOut;
            } else{
              this.checkOut = 'null'

            }
            let  Extraparams = '';

            for (const param in this.params) {
              if (param !== 'children' && param !== 'paxes' && param !== 'checkOut' && param !== 'checkIn' &&  param !== 'city' &&  param !== 'state') {

                Extraparams +='&'+ param+'='+ this.params[param]



              }
            }


        //    this.router.navigate(['/availability'],{ queryParams: { searchQuery: this.searchQuery, paxes: this.paxes, checkIn:this.checkIn, checkOut:this.checkOut},  });
            if (this.Service.isCompany() ) {
              this.router.navigateByUrl('/' + this.logedIn.companyInfo.id + '/availability?searchQuery=' + this.searchQuery + '&paxes=' + this.paxes + '&checkIn=' + this.checkIn + '&checkOut=' + this.checkOut + Extraparams)
            } else{
              this.router.navigateByUrl('/availability?searchQuery=' + this.searchQuery + '&paxes=' + this.paxes + '&checkIn=' + this.checkIn + '&checkOut=' + this.checkOut + Extraparams)

            }


          }




        );





      } else {

        this.router.navigate(['/']);


      }


    })
  }

}
