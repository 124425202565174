import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';
import {AuthServiceMain} from '../../auth/auth.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-workspace-reactivate-account',
  templateUrl: './workspace-reactivate-account.component.html',
  styleUrls: ['./workspace-reactivate-account.component.scss']
})
export class WorkspaceReactivateAccountComponent implements OnInit {

  constructor( public router: Router, public dataService: DataService, public auth: AuthServiceMain) { }
data;
  fees;
  activeArray;
  plans;
  submitting;
  errorMsg;
  selected = false;
  // showNoPaymentMsg = false;
  ngOnInit(): void {
    if (!this.dataService.companyBillingList ||  (this.dataService.companyBillingList && !this.dataService.companyBillingList.length)) {
      this.dataService.showNoPaymentMsg = true;
    }
    if (!this.dataService.showNoPaymentMsg) {

    this.reactivateFees(this.dataService.workspacePlanId);
    this.dataService.getCompanyUsersList().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.activeArray = this.data.data.filter(item => item.inactive === false);
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
    this.dataService.getWorkspacePlans().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.plans = this.data.data;
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );

  }
  }
  reactivateFees(planId) {
    this.dataService.workspacePlanId = planId;
    this.dataService.companyReactivationFees(planId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.fees = this.data.data;
        } else {
          this.errorMsg = this.data.message;
        }
      }, error => {
        this.errorMsg = environment.messages.error;
      }
    );
  }
  confirm() {
    this.submitting = true;
      this.dataService.companyBillingList.forEach((element, index) => {
        if (element.primary) {
          this.dataService.reactivateCompany(element.id, this.dataService.workspacePlanId).subscribe(
            data => {
              this.data = data;
              if (this.data.errorCode === 0) {
               // this.auth.fetchCompanyData();
                this.dataService.modalOf();
                this.dataService.BottomSheetOf();
                this.submitting = false;
                this.auth.companyInfo.inactive = this.data.data.inactive;
                localStorage.setItem('companyInfo', JSON.stringify(this.auth.companyInfo));
                if(this.router.url !== '/' + this.auth.companyInfo.id + '/settings/billing') {
                  this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/billing']);
                }
                if (!sessionStorage.getItem('defaultTeamInfo') && this.auth.companyInfo && this.auth.companyInfo.inactive === false ) {
                  this.dataService.getSubscriptionDetails();
                  this.dataService.getTeamDetails(0).subscribe(
                    dataTeam => {
                      this.data = dataTeam;
                      if (this.data.errorCode === 0) {
                        sessionStorage.setItem('defaultTeamInfo', JSON.stringify(this.data.data));
                      } else {
                        this.dataService.messages(this.data.message, 'error');
                      }
                    }, error => {
                      this.dataService.messages(environment.messages.error, 'error');
                    }
                  );
                }
              } else {
                this.submitting = false;
                this.errorMsg = this.data.message;
              }
            }, error => {
              this.submitting = false;
              this.errorMsg = environment.messages.error;
            }
          );
        }
      });
  }
  addNewPayment() {
    if (this.dataService.desktopView) {
      this.dataService.setModal($localize`:@@addNewCard:Add new card`, 'NewPaymentCard', '');
    } else {
      this.dataService.setBottomSheet('NewPaymentCard', '');
    }
  }
}
