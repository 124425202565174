import { Component, OnInit } from '@angular/core';
import {AuthServiceMain} from '../../auth/auth.service';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-manage-workspace-popup',
  templateUrl: './manage-workspace-popup.component.html',
  styleUrls: ['./manage-workspace-popup.component.scss']
})
export class ManageWorkspacePopupComponent implements OnInit {

  constructor(public auth: AuthServiceMain, public dataService: DataService, public router: Router) {
    this.dataService.componentMethodCalled$.subscribe(
      () => {
        if (this.auth.isLogedIn && this.auth.companyInfo) {

          this.dataService.workspaceApprovalsBadge = localStorage.getItem('workspaceApprovalsBadge');
        }
      });
  }
  env = environment;
  ngOnInit(): void {
 /*   if (!this.auth.companyInfo) {
      this.auth.fetchCompanyData();
    }*/
    setTimeout( () => {

      if (this.auth.isLogedIn && !this.auth.companyInfo) {
        this.auth.fetchCompanyData();
      }

    }, 1000);
    this.dataService.workspaceApprovalsBadge = localStorage.getItem('workspaceApprovalsBadge');
  }

  closeMenu() {
    this.dataService.manageWorkspaceMenuActive = false;
    this.dataService.landingWorkspaceMenuActive = false;
    this.dataService.userWorkspaceMenuActive = false;
    this.dataService.enableBodyScroll();
  }
}
