<div class="flight-details" [ngStyle]="{'direction' : dataService.siteDirection.toLowerCase()}">
<div class="fixed-bar-header bg-white px-3" *ngIf="dataService.mobileView" [ngClass]="{'flight-list-page': this.router.url.includes('flights/availability') && !this.router.url.includes('flights/availability/booking')}">
  <div class="d-flex pt-3">
    <p class="font-18 bold-600 gray-primaray-text" i18n="@@flightDetails">Flight details</p>
    <i class="icon-close-light gray-text pointer pt-2" [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}" (click)="this.dataService.rightSideDrawerOff()"></i>
  </div>
</div>

<div class="flight-main-container" [ngClass]="{'table-header': dataService.desktopView, 'flight-list-page': this.router.url.includes('flights/availability') && !this.router.url.includes('flights/availability/booking')}">
  <div class="d-flex">
    <p class="font-20 primaray-text font-weight-bold" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" [innerHTML]="formatDate(summaryDeparture.departureDate)"></p>
    <p class="pt-1"><span class="gray-text-dark font-16" *ngIf="dataService.desktopView"> {{summaryDeparture.departureAirport.city}} <span class="text-lowercase" i18n="@@to">to</span> {{summaryDeparture.arrivalAirport.city}}</span></p>
  </div>
 <div [ngClass]="{'bg-white rounded border shadow-sm p-4': dataService.desktopView}">
    <div class="row" [ngClass]="{'mx-0': dataService.mobileView}">
      <div class="col-md-7 col-lg-8">
        <ng-container *ngFor="let flight of departureDetails ,  let i = index" >
        <div class="d-flex">
          <img width="26.5" height="27" loading="lazy" class="max-100 img-min-width" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"
               [src]="flight.flightSegment.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />
          <div>
            <div [ngClass]="{'d-flex' : dataService.desktopView}">
              <p class="mb-0 font-16 gray-text-dark" [ngClass]="{'pr-lg-4': dataService.isSiteLtr(), 'pl-lg-4': dataService.isSiteRtl()}">{{flight.flightSegment.marketingAirlineName}} {{flight.flightSegment.marketingAirlineCode}} {{flight.flightSegment.flightNumber}}</p>
              <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded flex-2" [ngClass]="{'my-1' : dataService.mobileView}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus" i18n="@@noteBus">Note: This is bus service</p>
              <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded flex-2" [ngClass]="{'my-1' : dataService.mobileView}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train" i18n="@@noteTrain">Note: This is train service</p>
            </div>

            <p class="gray-text mb-0" *ngIf="flight.flightSegment.operatingAirline"><ng-container i18n="@@operatedBy">Operated by</ng-container> {{flight.flightSegment.operatingAirline.name}}</p>
            <p  class="gray-text mb-0" [ngClass]="{'text-right': dataService.isSiteRtl()}">{{flight.flightSegment.cabinClassName}}</p>

          </div>
        </div>



        <div class="d-flex mt-3">
          <i class="icon-fly-from placeholder-text font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
          <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
          <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>
          <p class="mb-0 gray-text-dark"><span [innerHTML]="this.dataService.formatDateTime12Hours(flight.flightSegment.departureDateTime)">10:15pm</span><ng-container *ngIf="dataService.desktopView"> - {{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</ng-container></p>
        </div>
        <div class="d-flex pb-2">
          <span class="border-right-square" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></span>
          <div>
            <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="dataService.desktopView">{{flight.flightSegment.departureAirport.city}}</p>
            <p class="mb-4 gray-text-dark" [ngClass]="{'ml-4 pl-1': dataService.isSiteLtr(), 'mr-4 pr-1': dataService.isSiteRtl()}" *ngIf="dataService.mobileView">{{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</p>
            <p class="mb-4 font-12 gray-text" [ngClass]="{'ml-4 pl-1 text-left': dataService.isSiteLtr(), 'mr-4 pr-1 text-right': dataService.isSiteRtl()}"><ng-container i18n="@@duration">Duration:</ng-container>&nbsp; <span [innerHTML]="this.dataService.calDuration(flight.flightSegment.journeyDuration)">2h 33m</span></p>
          </div>
        </div>
        <div class="d-flex">
          <i class="icon-fly-to placeholder-text font-18" [ngClass]="{'mr-2 pr-1': dataService.isSiteLtr(), 'ml-2 pl-1': dataService.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
          <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
          <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>
          <p class="mb-0 gray-text-dark"><span [innerHTML]="this.dataService.formatDateTime12Hours(flight.flightSegment.arrivalDateTime)">10:15pm</span>
            <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) === 1" i18n="@@nextDay"> (Next day)</span>
            <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) > 1"> +{{dataService.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)}}</span>
            <span class="font-14 font-weight-normal" *ngIf="dataService.checkPreviousDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)" i18n="@@prevDay"> (Previous day)</span>
            <ng-container *ngIf="dataService.desktopView"> - </ng-container><br *ngIf="dataService.mobileView"/>{{flight.flightSegment.arrivalAirport.name}} ({{flight.flightSegment.arrivalAirport.iata}})</p>
        </div>
          <div class="d-flex pb-2">
            <span [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}"></span>
            <div>
              <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="dataService.desktopView">{{flight.flightSegment.arrivalAirport.city}}</p>
              </div>
          </div>

          <div *ngIf="flight.stopDurationInMinutes" class="primaray-text font-16 mb-5 mt-4 "><i class="icon-wall-clock align-middle mr-2"></i> <span [innerHTML]="this.dataService.calDuration(flight.stopDurationInMinutes)">2h 33m</span>&nbsp; <ng-container i18n="@@layoverIn">layover in</ng-container> {{flight.flightSegment.arrivalAirport.city}}<span *ngIf="!flight.flightSegment.airportChangeDescription"> ({{flight.flightSegment.arrivalAirport.iata}})</span></div>
          <div class="position-relative py-4" *ngIf="flight.flightSegment.airportChangeDescription">
            <div class="position-absolute font-12 text-white orange-bg change-stop rounded px-2 px-lg-3">{{flight.flightSegment.airportChangeDescription}}</div>
          </div>
        </ng-container>

      </div>

      <div class="col-md-5 col-lg-4" [ngClass]="{'text-right': dataService.isSiteRtl()}" *ngIf="dataService.desktopView">
        <ng-container *ngIf="!this.item.approvalDetails">
        <p class="font-20 gray-primaray-text mb-4" i18n="@@fareRules">Fare Rules</p>
          <p class="gray-text mb-1"><i class="icon-carry-on-bag font-16" [ngClass]="{'mr-2 pr-1': dataService.isSiteLtr(), 'ml-2 pl-1': dataService.isSiteRtl()}"></i><ng-container i18n="@@carryOnBag">Carry-on bag included</ng-container></p>
        <div class="lines-small w-50" *ngIf="rulesLoader"></div>
        <ng-container *ngIf="rules && !rulesLoader">
          <ng-container *ngFor="let bag of departRulesArray">
            <p class="gray-text mb-1"> <i class="icon-checked-bag font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i>{{bag.baggage}} <span *ngIf="departRulesArray.length > 1"> ({{bag.flightNo}})</span></p>
          </ng-container>
        </ng-container>
        <p class="green-text mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === true" i18n="@@refundable">Refundable</p>
        <p class="gray-text-dark mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === false" i18n="@@nonrefundable">Non Refundable</p>
        </ng-container>
      </div>
    </div>



 </div>


<div *ngIf="returnDetails">
  <div class="d-flex mt-5  pt-4">
    <p class="font-20 primaray-text font-weight-bold" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"
          [innerHTML]="formatDate(summaryReturn.departureDate)"></p>
    <p class="pt-1"><span class="gray-text-dark font-16" *ngIf="dataService.desktopView"> {{summaryReturn.departureAirport.city}} <span class="text-lowercase" i18n="@@to">to</span> {{summaryReturn.arrivalAirport.city}}</span></p>

  </div>
  <div [ngClass]="{'bg-white rounded border shadow-sm p-4': dataService.desktopView}">
    <div class="row" [ngClass]="{'mx-0': dataService.mobileView}">
      <div class="col-md-7 col-lg-8">
        <ng-container *ngFor="let flight of returnDetails ,  let i = index" >
          <div class="d-flex">
            <img width="26.5" height="27" loading="lazy" class="max-100 img-min-width" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"
                 [src]="flight.flightSegment.marketingAirlineLogoUrl"  onError="this.src='/assets/img/airline.png'" />
            <div>
              <div [ngClass]="{'d-flex' : dataService.desktopView}">
                <p class="mb-0 font-16 gray-text-dark" [ngClass]="{'pr-lg-4': dataService.isSiteLtr(), 'pl-lg-4': dataService.isSiteRtl()}">{{flight.flightSegment.marketingAirlineName}} {{flight.flightSegment.marketingAirlineCode}} {{flight.flightSegment.flightNumber}}</p>
                <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded flex-2" [ngClass]="{'my-1' : dataService.mobileView}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus" i18n="@@noteBus">Note: This is bus service</p>
                <p class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded flex-2" [ngClass]="{'my-1' : dataService.mobileView}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train" i18n="@@noteTrain">Note: This is train service</p>
              </div>

              <p class="gray-text mb-0" *ngIf="flight.flightSegment.operatingAirline"><ng-container i18n="@@operatedBy">Operated by</ng-container> {{flight.flightSegment.operatingAirline.name}}</p>
              <p  class="gray-text mb-0" [ngClass]="{'text-right': dataService.isSiteRtl()}">{{flight.flightSegment.cabinClassName}}</p>
            </div>
          </div>



          <div class="d-flex mt-3">
            <i class="icon-fly-from placeholder-text font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
            <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
            <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>
            <p class="mb-0 gray-text-dark"><span [innerHTML]="this.dataService.formatDateTime12Hours(flight.flightSegment.departureDateTime)">10:15pm</span><ng-container *ngIf="dataService.desktopView"> - {{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</ng-container></p>
          </div>
          <div class="d-flex pb-2">
            <span class="border-right-square" [ngClass]="{'ml-2': dataService.isSiteLtr(), 'mr-2': dataService.isSiteRtl()}"></span>
            <div>
              <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="dataService.desktopView">{{flight.flightSegment.departureAirport.city}}</p>
              <p class="mb-4 gray-text-dark" [ngClass]="{'ml-4 pl-1': dataService.isSiteLtr(), 'mr-4 pr-1': dataService.isSiteRtl()}" *ngIf="dataService.mobileView">{{flight.flightSegment.departureAirport.name}} ({{flight.flightSegment.departureAirport.iata}})</p>
              <p class="mb-4 font-12 gray-text" [ngClass]="{'ml-4 pl-1 text-left': dataService.isSiteLtr(), 'mr-4 pr-1 text-right': dataService.isSiteRtl()}"><ng-container i18n="@@duration">Duration:</ng-container>&nbsp; <span [innerHTML]="this.dataService.calDuration(flight.flightSegment.journeyDuration)">2h 33m</span></p>
            </div>
          </div>
          <div class="d-flex">
            <i class="icon-fly-to placeholder-text font-18" [ngClass]="{'mr-2 pr-1': dataService.isSiteLtr(), 'ml-2 pl-1': dataService.isSiteRtl()}" *ngIf="!flight.flightSegment.departureAirport.bus && !flight.flightSegment.arrivalAirport.bus && !flight.flightSegment.departureAirport.train && !flight.flightSegment.arrivalAirport.train"></i>
            <i class="icon-bus-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus"></i>
            <i class="icon-train-station placeholder-text font-18" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}" *ngIf="flight.flightSegment.departureAirport.train || flight.flightSegment.arrivalAirport.train"></i>
            <p class="mb-0 gray-text-dark"><span [innerHTML]="this.dataService.formatDateTime12Hours(flight.flightSegment.arrivalDateTime)">10:15pm</span>  <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) === 1" i18n="@@nextDay"> (Next day)</span>
              <span class="font-14 font-weight-normal" *ngIf="dataService.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime) > 1"> +{{dataService.checkNextDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)}}</span>
              <span class="font-14 font-weight-normal" *ngIf="dataService.checkPreviousDay(flight.flightSegment.departureDateTime, flight.flightSegment.arrivalDateTime)" i18n="@@prevDay"> (Previous day)</span>
              <ng-container *ngIf="dataService.desktopView"> - </ng-container><br *ngIf="dataService.mobileView"/>{{flight.flightSegment.arrivalAirport.name}} ({{flight.flightSegment.arrivalAirport.iata}})</p>
          </div>
          <div class="d-flex pb-2">
            <span [ngClass]="{'ml-3': dataService.isSiteLtr(), 'mr-3': dataService.isSiteRtl()}"></span>
            <div>
              <p class="extra-left-margin gray-text" [ngClass]="{'bus-margin': flight.flightSegment.departureAirport.bus || flight.flightSegment.arrivalAirport.bus}" *ngIf="dataService.desktopView">{{flight.flightSegment.arrivalAirport.city}}</p>
            </div>
          </div>

          <div *ngIf="flight.stopDurationInMinutes" class="primaray-text font-16 mb-5 mt-4 "><i class="icon-wall-clock align-middle mr-2"></i> <span [innerHTML]="this.dataService.calDuration(flight.stopDurationInMinutes)">2h 33m</span>&nbsp; <ng-container i18n="@@layoverIn">layover in</ng-container> {{flight.flightSegment.arrivalAirport.city}}<span *ngIf="!flight.flightSegment.airportChangeDescription"> ({{flight.flightSegment.arrivalAirport.iata}})</span></div>
          <div class="position-relative py-4" *ngIf="flight.flightSegment.airportChangeDescription">
            <div class="position-absolute font-12 text-white orange-bg change-stop rounded px-2 px-lg-3">{{flight.flightSegment.airportChangeDescription}}</div>
          </div>
        </ng-container>
      </div>

      <div class="col-md-5 col-lg-4" [ngClass]="{'text-right': dataService.isSiteRtl()}" *ngIf="dataService.desktopView">
        <ng-container *ngIf="!this.item.approvalDetails">
        <p class="font-20 gray-primaray-text mb-4" i18n="@@fareRules">Fare Rules</p>
        <p class="gray-text mb-1"><i class="icon-carry-on-bag font-16" [ngClass]="{'mr-2 pr-1': dataService.isSiteLtr(), 'ml-2 pl-1': dataService.isSiteRtl()}"></i><ng-container i18n="@@carryOnBag">Carry-on bag included</ng-container></p>
        <div class="lines-small w-50" *ngIf="rulesLoader"></div>
        <ng-container *ngIf="rules && !rulesLoader">
          <ng-container *ngFor="let bag of returnRulesArray">
            <p class="gray-text mb-1"> <i class="icon-checked-bag font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i>{{bag.baggage}} <span *ngIf="returnRulesArray.length > 1"> ({{bag.flightNo}})</span></p>
          </ng-container>
        </ng-container>
          <p class="green-text mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === true" i18n="@@refundable">Refundable</p>
          <p class="gray-text-dark mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === false" i18n="@@nonrefundable">Non Refundable</p>
        </ng-container>


      </div>
    </div>



  </div>
</div>


<div *ngIf="dataService.mobileView">
  <ng-container *ngIf="!this.item.approvalDetails">
  <p class="font-16 gray-primaray-text mb-4 pt-5 font-weight-bold" i18n="@@fareRules">Fare Rules</p>


<div class="white-box rounded border">
  <div class="d-flex">
    <p class="mb-0 gray-text-dark">{{this.summaryDeparture.departureAirport.name}}</p>
    <div [ngClass]="{'pl-2': dataService.isSiteLtr(), 'pr-2': dataService.isSiteRtl()}"><i class="icon-flight-item font-11 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i></div>
  </div>
  <p class="mb-4 gray-text-dark">{{this.summaryDeparture.arrivalAirport.name}}</p>

    <p class="gray-text mb-1"><i class="icon-carry-on-bag font-16" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i><ng-container i18n="@@carryOnBag">Carry-on bag included</ng-container></p>
    <div class="lines-small w-50" *ngIf="rulesLoader"></div>
    <ng-container *ngIf="rules && !rulesLoader">
      <ng-container *ngFor="let bag of departRulesArray">
        <p class="gray-text mb-1"> <i class="icon-checked-bag font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i>{{bag.baggage}} <span *ngIf="departRulesArray.length > 1"> ({{bag.flightNo}})</span></p>
      </ng-container>
    </ng-container>
    <p class="green-text mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === true" i18n="@@refundable">Refundable</p>
    <p class="gray-text-dark mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === false" i18n="@@nonrefundable">Non Refundable</p>
</div>


  <div class="white-box rounded border mt-3" *ngIf="returnDetails">
    <div class="d-flex">
      <p class="mb-0 gray-text-dark">{{this.summaryReturn.departureAirport.name}}</p>
      <div [ngClass]="{'pr-2': dataService.isSiteLtr(), 'pl-2': dataService.isSiteRtl()}"><i class="icon-flight-item font-11 gray-text" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i></div>
    </div>
    <p class="mb-4 gray-text-dark">{{this.summaryReturn.arrivalAirport.name}}</p>
    <p class="gray-text mb-1"><i class="icon-carry-on-bag font-16" [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}"></i><ng-container i18n="@@carryOnBag">Carry-on bag included</ng-container></p>
    <div class="lines-small w-50" *ngIf="rulesLoader"></div>
    <ng-container *ngIf="rules && !rulesLoader">
      <ng-container *ngFor="let bag of returnRulesArray">
        <p class="gray-text mb-1"> <i class="icon-checked-bag font-20" [ngClass]="{'mr-2': dataService.isSiteLtr(), 'ml-2': dataService.isSiteRtl()}"></i>{{bag.baggage}} <span *ngIf="returnRulesArray.length > 1"> ({{bag.flightNo}})</span></p>
      </ng-container>
    </ng-container>
    <p class="green-text mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === true" i18n="@@refundable">Refundable</p>
    <p class="gray-text-dark mt-4 pt-2" *ngIf="this.item.details.fareInfo.refundable === false" i18n="@@nonrefundable">Non Refundable</p>
  </div>



  </ng-container>
</div>



</div>
<div class="fixed-bar bg-white px-0 px-lg-3" *ngIf="!this.router.url.includes('booking') && !this.item.approvalDetails" [ngClass]="{'flight-list-page': this.router.url.includes('flights/availability') && !this.router.url.includes('flights/availability/booking')}">
  <div class="select-flight d-flex mx-auto d-block pb-5 pt-0 pt-lg-5 px-3 position-relative" *ngIf="dataService.desktopView">
    <div class="text-danger my-auto" [ngClass]="{'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}"
         *ngIf="(item.details.userData && item.details.userData.policyViolated) || this.item.flightBookingNotAllowed === true"
    i18n="@@outOfPolicy">
      Out of policy
    </div>
    <div class="reward-promo d-flex position-absolute" *ngIf="item.details.userData && item.details.userData.rewardAmount">
      <div class="promo-text text-white font-12 px-4 pt-2"><ng-container i18n="@@earn">Earn</ng-container>&nbsp;<ng-container *ngIf="dataService.isSiteLtr()">$</ng-container>{{item.details.userData.rewardAmount  |  number : '1.0-0'}}<ng-container *ngIf="dataService.isSiteRtl()">$</ng-container>&nbsp;<ng-container i18n="@@inRewards">in Rewards</ng-container></div>
      <div class="promo-triangle right-side-triangle"></div>
    </div>
    <div [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
      <div class="d-flex">
      <div [ngClass]="{'mr-3': dataService.isSiteLtr(), 'ml-3': dataService.isSiteRtl()}">
        <p class="font-22-to-18 my-0 primaray-text font-weight-bold">${{item.details.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}</p>
        <p class="gray-text mb-0" *ngIf="this.item.trip === 'roundtrip'" i18n="@@roundTripPerTraveler">Round trip per traveler</p>
        <p class="gray-text mb-0" *ngIf="this.item.trip === 'oneway'" i18n="@@perTraveler">Per traveler</p>
      </div>
      <div>
        <button class="green_btn btn btn-block font-14 sharp-font bold-500 px-5 py-1" (click)="this.dataService.bookingFlight(item, $event, this.travelerId)" i18n="@@selectFlight">Select Flight</button>
      </div>
    </div>
    </div>
    <p class="text-danger min-p position-absolute"><span>{{this.dataService.errorMsg}}</span></p>
  </div>

  <div *ngIf="dataService.mobileView">
    <div class="select-flight pb-4 pt-0 pt-lg-5" [ngClass]="{'pr-3': dataService.isSiteLtr(), 'pl-3': dataService.isSiteRtl()}">

      <div class="d-flex py-3">
        <div class="my-auto min-width-155" [ngClass]="{'mr-auto': dataService.isSiteLtr(), 'ml-auto': dataService.isSiteRtl()}">
          <div class="text-danger" [ngClass]="{'pl-3': dataService.isSiteLtr(), 'pr-3': dataService.isSiteRtl()}"
               *ngIf="(item.details.userData && item.details.userData.policyViolated) || this.item.flightBookingNotAllowed === true"
          i18n="@@outOfPolicy">
            Out of policy
          </div>
          <div class="reward-promo d-flex " *ngIf="item.details.userData && item.details.userData.rewardAmount">
            <div class="text-nowrap promo-text text-white font-12 px-3 pt-2">Earn&nbsp;<ng-container *ngIf="dataService.isSiteLtr()">$</ng-container>{{item.details.userData.rewardAmount  |  number : '1.0-0'}}<ng-container *ngIf="dataService.isSiteRtl()">$</ng-container>&nbsp;<ng-container i18n="@@inRewards">in Rewards</ng-container></div>
            <div class="promo-triangle right-side-triangle"></div>
          </div>
        </div>





          <div [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}">
            <p [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" class="font-22-to-18 my-0 primaray-text font-weight-bold">${{item.details.fareInfo.sellingTotalFares.totalPerPassenger.amount | number : '1.0-0'}}</p>
            <p [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" class="gray-text mb-0" *ngIf="this.item.trip === 'roundtrip'" i18n="@@roundTripPerTraveler">Round trip per traveler</p>
            <p [ngClass]="{'text-right': dataService.isSiteLtr(), 'text-left': dataService.isSiteRtl()}" class="gray-text mb-0" *ngIf="this.item.trip === 'oneway'" i18n="@@perTraveler">Per traveler</p>
          </div>

      </div>


      <div [ngClass]="{'pl-3': dataService.isSiteLtr(), 'pr-3': dataService.isSiteRtl()}">
        <button class="green_btn btn btn-block font-14 sharp-font bold-500 px-5 py-1" (click)="this.dataService.bookingFlight(item, $event, this.travelerId)" i18n="@@selectFlight">Select Flight</button>
        <p class="text-danger" [ngClass]="{'min-p position-absolute': dataService.desktopView, 'mt-2': dataService.mobileView}"><span>{{this.dataService.errorMsg}}</span></p>
      </div>

    </div>
  </div>



</div>

</div>
