
<app-middle-button *ngIf="this.dataService.desktopView"></app-middle-button>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="dataService.desktopView" #leftSidenav mode="side" opened>
    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{'initial-margin-value' : this.dataService.initialMarginValue && this.dataService.desktopView}"
                         [@onMainContentChange]="this.dataService.onSideNavChange ? 'open' + dataService.siteDirection: 'close' + dataService.siteDirection">
    <div class="main-dashboard-content">
      <app-messages></app-messages>
      <!--<div class="gray-pass-bg border-0 py-3 pl-3" *ngIf="dataService.mobileView">
        <a class="font-18 blue-text font-18 primaray-text font-weight-bold" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);">Settings</a>
        <span class="font-18 gray-text-dark font-weight-bold"> / Integrations </span>
      </div>-->

      <div class="container pt-4 pt-lg-5 pb-5">
        <div class="mx-lg-5">
          <div class="row"  >
            <div class="col">
              <div class="pb-4 pb-lg-5 mb-3" >
                <ng-container *ngIf="auth.companyInfo && auth.companyInfo.inactive === false"><a class="font-16 blue-text text-underline" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings']);" i18n="@@menuItemSettings">Settings</a> </ng-container>
                <span class="font-16 gray-text-dark"> / <ng-container i18n="@@integrations">Integrations</ng-container> </span>
              </div>
            </div>
          </div>

          <p class="font-20 gray-primaray-text bold-600 " i18n="@@connectedApps">Connected Apps</p>
          <div *ngIf="loaded">
            <div class="row px-3 mx-lg-0 mt-4 pt-3 pb-3 gray-primaray-text table-header rounded bold-600" *ngIf="dataService.desktopView">
              <div class="col-md-4" i18n="@@appName">App</div>
              <div class="col-md-4" i18n="@@status">Status</div>
              <div class="col-md-4"></div>

            </div>
            <div class="row px-0 px-lg-3 hover-row-list border-bottom mx-0 py-4 pointer" *ngIf="connected"
                 (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/integrations/expensify']);">
              <div class="col-md-4 py-1">
                <img width="150" height="23" loading="lazy" class="max-100 initial-height" src="/assets/img/expensify-logo--reverse.png" />
              </div>
              <div class="col-md-4 py-1"><ng-container i18n="@@connected">Connected</ng-container>&nbsp;<i class="icon-filled-circle green-text smaller"></i></div>
              <div class="col-md-4 text-center"></div>
            </div>
            <div class="row px-0 px-lg-3 hover-row-list mx-0 py-4" [ngClass]="{'border-bottom': dataService.desktopView, 'white-box': dataService.mobileView}" *ngIf="!connected && dataService.desktopView">
              <div class="col-md-4 py-1">
                <img width="150" height="23" loading="lazy" class="max-100 initial-height" src="/assets/img/expensify-logo--reverse.png" />
              </div>
              <div class="col-md-4 py-1"><ng-container i18n="@@disconnected">Disconnected</ng-container>&nbsp;<i class="icon-filled-circle text-danger smaller"></i></div>
              <div class="col-md-4" [ngClass]="{'text-center': dataService.desktopView, 'text-right': dataService.mobileView}">
                <p [ngClass]="{'action-hide-show': dataService.desktopView}">
                  <a class="blue-text" (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/integrations/expensify']);" i18n="@@connect">Connect</a>
                </p></div>
            </div>

            <div class="row px-0 px-lg-3 hover-row-list white-box mx-0 py-4"
                 (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/integrations/expensify']);"
                 *ngIf="!connected && dataService.mobileView">
              <div class="col-md-4 py-1">
                <img width="150" height="23" loading="lazy" class="max-100 initial-height" src="/assets/img/expensify-logo--reverse.png" />
              </div>
              <div class="col-md-4 py-1"><ng-container i18n="@@disconnected">Disconnected</ng-container>&nbsp;<i class="icon-filled-circle text-danger smaller"></i></div>
            </div>
          </div>

       <!--   <div  *ngIf=" dataService.mobileView && loaded">
            <div class="card-bg p-3 mb-5">
             <p><img width="150" height="23" loading="lazy" class="max-100 initial-height" src="/assets/img/expensify-logo--reverse.png" /></p>



              <p class="body-font-style placeholder-text"><span class="py-2 pr-2 gray-primaray-text">connected</span> <i class="icon-filled-circle green-text smaller"></i></p>

              <div class="d-flex mb-0 mt-4">
                <a class="blue-text action-font-style right-action ml-auto"
                   (click)="this.router.navigate(['/' + this.auth.companyInfo.id + '/settings/integrations/expensify']);">Connect</a>
              </div>

            </div>
          </div>-->
        </div>
      </div>



    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





