import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AuthServiceMain} from '../../auth/auth.service';
import * as moment from 'moment';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { isPlatformBrowser } from '@angular/common';
import {environment} from '../../../environments/environment';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-hotel-flight-trips-control',
  templateUrl: './hotel-flight-trips-control.component.html',
  styleUrls: ['./hotel-flight-trips-control.component.scss']
})
export class HotelFlightTripsControlComponent implements OnInit {
  constructor(private titleService: Title, @Inject(PLATFORM_ID) private platformId, private scrollToService: ScrollToService, private http: HttpClient, public  router: Router,
              public logedIn: AuthServiceMain, public  dataService: DataService) {
    this.titleService.setTitle('My trips | SafarHub');
    if(this.logedIn.isLogedIn && this.dataService.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
      this.dataService.inactiveWorkspace = true;
    }

  }
  upcomingBookingObj = {
    bookingStatus : 'CONFIRMED',
    includeUpcoming : true,
    includePast : false
  };
  canceledBookingObj = {
    bookingStatus : 'CANCELLED',
    includeUpcoming : true,
    includePast : true
  };
  completedBookingObj = {
    bookingStatus : 'CONFIRMED',
    includeUpcoming : false,
    includePast : true
  };
  upcomingBooking;
  canceledBooking;
  completedBooking;
  upcomingBookingMain;
  canceledBookingMain;
  completedBookingMain;
  dataUpcoming;
  dataCanceled;
  dataCompleted;
  isUpcoming = true;
  isCompleted = false;
  isCanceled = false;
  isSticky = false;
  getCompleted;
  selectCompleted;
  getUpComing;
  selectUpcoming;
  getCanceled;
  selectCanceled;
  showMoreCompleted = 3;
  showMoreCanceled = 3;
  bookingsUrl;
  availabilityUrl;
  companyInfoObj = JSON.parse(localStorage.getItem('companyInfo'));
  ngOnInit() {
      this.bookingsUrl = '/bookings/manage';
      this.availabilityUrl = '/availability/hotel';
      if(this.logedIn.isLogedIn && this.dataService.isCompany() && this.logedIn.companyInfo && this.logedIn.companyInfo.inactive) {
      this.dataService.inactiveWorkspace = true;
      if (this.dataService.isCompany()) {
        this.bookingsUrl = '/' + this.companyInfoObj.id + '/hotel/booking/details';
        this.availabilityUrl = '/' + this.companyInfoObj.id + '/availability/hotel';
      } else {
        this.bookingsUrl = '/bookings/manage';
        this.availabilityUrl = '/availability/hotel';
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      this.dataService.alerts = [];
      this.dataService.alertsOverRoute = [];
      this.dataService.hotellistLoader = true;
      this.dataService.flightHotelListBookings(this.upcomingBookingObj.bookingStatus, this.upcomingBookingObj.includeUpcoming,
        this.upcomingBookingObj.includePast).subscribe(
        data => {
          this.dataUpcoming = data;
          if (this.dataUpcoming.errorCode === 0) {
            this.upcomingBooking = this.dataUpcoming.data;



            this.upcomingBooking.sort((a, b) => {
              let aDate;
              let bDate;
              if (a.bookingType === 'FLIGHT') {
                  aDate = new Date(a.airItinerary.originDestinationOptionsGroups[0].summary.departureDate);
              } else {
                aDate = new Date(a.checkinDate);
              }

              if (b.bookingType === 'FLIGHT') {
                bDate = new Date(b.airItinerary.originDestinationOptionsGroups[0].summary.departureDate);
              } else {
                bDate = new Date(b.checkinDate);
              }
              if (aDate < bDate) {
                return 1;
              } else if (bDate < aDate) {
                return -1;
              } else {
                return 0;
              }

            });


            this.upcomingBookingMain = this.dataUpcoming.data;

            this.dataService.hotellistLoader = false;
          } else {
            this.dataService.messages(this.dataUpcoming.message, 'error');
            this.dataService.hotellistLoader = false;
          }

        },
        error => {
          this.dataService.messages(environment.messages.error, 'error');
          this.dataService.hotellistLoader = false;
        }
      );


      this.dataService.flightHotelListBookings(this.completedBookingObj.bookingStatus, this.completedBookingObj.includeUpcoming,
        this.completedBookingObj.includePast).subscribe(
        data => {
          this.dataCompleted = data;
          if (this.dataCompleted.errorCode === 0) {

            this.completedBooking = this.dataCompleted.data;


            this.completedBooking.sort((a, b) => {
              let aDate;
              let bDate;
              if (a.bookingType === 'FLIGHT') {
                aDate = new Date(a.airItinerary.originDestinationOptionsGroups[0].summary.departureDate);
              } else {
                aDate = new Date(a.checkinDate);
              }

              if (b.bookingType === 'FLIGHT') {
                bDate = new Date(b.airItinerary.originDestinationOptionsGroups[0].summary.departureDate);
              } else {
                bDate = new Date(b.checkinDate);
              }
              if (aDate < bDate) {
                return 1;
              } else if (bDate < aDate) {
                return -1;
              } else {
                return 0;
              }

            });

            this.completedBookingMain = this.dataCompleted.data;

          } else {
            this.dataService.messages(this.dataCompleted.message, 'error');
            this.dataService.hotellistLoader = false;
          }

        },
        error => {
          this.dataService.messages(environment.messages.error, 'error');
          this.dataService.hotellistLoader = false;
        }
      );

      this.dataService.flightHotelListBookings(this.canceledBookingObj.bookingStatus, this.canceledBookingObj.includeUpcoming,
        this.canceledBookingObj.includePast).subscribe(
        data => {
          this.dataCanceled = data;
          if (this.dataCanceled.errorCode === 0) {

            this.canceledBooking = this.dataCanceled.data;


            this.canceledBooking.sort((a, b) => {
              let aDate;
              let bDate;
              if (a.bookingType === 'FLIGHT') {
                aDate = new Date(a.airItinerary.originDestinationOptionsGroups[0].summary.departureDate);
              } else {
                aDate = new Date(a.checkinDate);
              }

              if (b.bookingType === 'FLIGHT') {
                bDate = new Date(b.airItinerary.originDestinationOptionsGroups[0].summary.departureDate);
              } else {
                bDate = new Date(b.checkinDate);
              }
              if (aDate < bDate) {
                return 1;
              } else if (bDate < aDate) {
                return -1;
              } else {
                return 0;
              }

            });



            this.canceledBookingMain = this.dataCanceled.data;

          } else {
            // this.dataService.hotellistLoader = false;
            this.dataService.messages(this.canceledBooking.message, 'error');
            this.dataService.hotellistLoader = false;
          }

        },
        error => {
          this.dataService.messages(environment.messages.error, 'error');
          this.dataService.hotellistLoader = false;
        }
      );

    }
  }
  filterByText(event) {
    if (event.target.value) {
      this.upcomingBooking = this.upcomingBooking.filter(item => {
        return item.guestFirstName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0 || item.guestLasttName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0
          || item.emailAddress.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0;
      });
      this.completedBooking = this.completedBooking.filter(item => {
        return item.guestFirstName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0 || item.guestLasttName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0
          || item.emailAddress.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0;
      });
      this.canceledBooking = this.canceledBooking.filter(item => {
        return item.guestFirstName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0 || item.guestLasttName?.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0
          || item.emailAddress.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0;
      });
    } else {
      this.upcomingBooking = this.upcomingBookingMain.slice();
      this.completedBooking = this.completedBookingMain.slice();
      this.canceledBooking =  this.canceledBookingMain.slice();
    }

  }
/*  formatDailyDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }*/
 /* formatItemDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/
  public triggerScrollToOffsetOnly(destination) {
    if (this.isSticky) {
      const config: ScrollToConfigOptions = {
        offset: -49,
        target : destination,
        duration : 100
      };
      this.scrollToService.scrollTo(config);
    } else{
      const config: ScrollToConfigOptions = {
        offset: -75,
        target : destination,
        duration : 100
      };
      this.scrollToService.scrollTo(config);
    }
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (isPlatformBrowser(this.platformId)) {

      this.getUpComing = document.getElementById('upcoming');
      if (this.getUpComing) {
        this.selectUpcoming = this.getUpComing.offsetTop;
        if (window.pageYOffset > this.selectUpcoming) {
          this.isUpcoming = true;
          this.isCompleted = false;
          this.isCanceled = false;
        }
      }
      this.getCompleted = document.getElementById('completed');
      if (this.getCompleted) {
        this.selectCompleted = this.getCompleted.offsetTop;
        if (window.pageYOffset > this.selectCompleted - 100) {
          this.isUpcoming = false;
          this.isCompleted = true;
          this.isCanceled = false;
        }
      }
      this.getCanceled = document.getElementById('canceled');
      if (this.getCanceled) {
        this.selectCanceled = this.getCanceled.offsetTop;
        if (window.pageYOffset > this.selectCanceled - 100) {
          this.isUpcoming = false;
          this.isCompleted = false;
          this.isCanceled = true;
        }
      }
      this.isSticky = window.pageYOffset >= 250;
    }}
  viewDetails(obj, bookingType) {
    if (this.dataService.isCompany()) {
      this.router.navigate(['/' + this.companyInfoObj.id + '/' + bookingType.toLowerCase() + '/booking/details'], {queryParams: obj});
    } else {
      if (bookingType.toLowerCase() === 'hotel') {
        this.router.navigate(['/bookings/manage'], {queryParams: obj});
      } else {
        this.router.navigate(['/' + bookingType.toLowerCase() + '/booking/details'], {queryParams: obj});
      }
    }
  }
}
