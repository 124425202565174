import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../auth/auth.service';
import {DataService} from '../../services/data.service';
import {environment} from "../../../environments/environment";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-favorites-control',
  templateUrl: './favorites-control.component.html',
  styleUrls: ['./favorites-control.component.scss']
})
export class FavoritesControlComponent implements OnInit {

  constructor(public  router: Router, private titleService: Title,
              public logedIn: AuthServiceMain, public  dataService: DataService) {
    this.titleService.setTitle('My favorites hotels | SafarHub');
    this.dataService.componentMethodCalled$.subscribe(
      () => {
        if (!this.dataService.isCompany()) {
          this.hotelNavigateUrl = '/availability/hotel';
        } else{
          this.hotelNavigateUrl = '/' + this.logedIn.companyInfo.id + '/availability/hotel';
        }

      }
    );

  }
  toBeDeletedGroup;
  favData;
  isFav = [];
  indexArray;
  emailsToBeDeleted = '';
  deleteFav;
  hotelNavigateUrl;
  ngOnInit() {
    this.isFav[0] = true;
    this.dataService.alerts = [];
    this.dataService.alertsOverRoute = [];
    this.dataService.hotellistLoader = true;
    this.dataService.favoritesList = [];
    this.dataService.getFavorites().subscribe(

      data => {
        this.favData = data;
        if (this.favData.errorCode === 0) {
          this.dataService.favoriteGroups = this.favData.data;
          for (let i in  this.dataService.favoriteGroups) {
            this.dataService.favoritesList.push({caption: i, items: this.dataService.favoriteGroups[i]});
          }
          for(var i = 0; i < this.dataService.favoritesList.length; i++) {
            for(var y = 0; y < this.dataService.favoritesList[i].items.length; y++) {
              this.indexArray = '' + i + y;
              this.isFav[this.indexArray] = true;
            }
          }

            if (!this.dataService.isCompany()) {
              this.hotelNavigateUrl = '/availability/hotel';
            } else{
              this.hotelNavigateUrl = '/' + this.logedIn.companyInfo.id + '/availability/hotel';
            }

          this.dataService.hotellistLoader = false;
        } else {
          this.dataService.messages(this.favData.message, 'error');
          this.dataService.hotellistLoader = false;
        }

      },
      error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.dataService.hotellistLoader = false;
      }
    );
  }

  getWidth(rating) {
    return {width: (rating * 100) / 5 + '%'};
  }

  trashAllFavHotels(caption) {
    for (let i in  this.dataService.favoriteGroups) {
      if (i === caption) {
        this.toBeDeletedGroup = this.dataService.favoriteGroups[i];
        for (let x = 0; x < this.toBeDeletedGroup.length; x++) {
          if (x === this.toBeDeletedGroup.length - 1) {
            this.emailsToBeDeleted += this.toBeDeletedGroup[x].id;
          } else {
            this.emailsToBeDeleted += this.toBeDeletedGroup[x].id + '&id=';
          }
        }
        this.dataService.favDelete(this.emailsToBeDeleted).subscribe(
          data => {
            this.deleteFav = data;
            if (this.deleteFav.errorCode === 0) {
              this.dataService.getFavoritesList();
            } else {
            }

          },
          error => {

          }
        );
      }
    }

  }
  openHotelSharePopUp(hotel) {
    localStorage.setItem('shareHotelImage', hotel.imageUrl);
    localStorage.setItem('shareHotelName', hotel.name);
    localStorage.setItem('shareHotelAddress', hotel.address);

      if (this.dataService.desktopView) {
        this.dataService.setModal($localize`:@@shareHotel:Share a hotel`, 'companyHotelShare', '');
      } else {
        this.dataService.setBottomSheet('companyHotelShare', '');
      }

  }
}
