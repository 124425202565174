<div class="container pt-4 pt-lg-5 normal-letter-spacing">
    <div class="row">
        <div class="col-4">
            <p-calendar #dateCalendar [(ngModel)]="date" [maxDate]="maxDate" showOtherMonths="true" showIcon="true"
                numberOfMonths="2" selectionMode="range" readonlyInput="true" inputId="range" required="true"
                placeholder="Select dates" i18n-placeholder="@@selectDates" (onSelect)="onSelectDate()"
                [ngClass]="{'border-danger': ( isSubmitted && (!date || !isValidRange))}" dateFormat="M dd, yy">
                <p-footer>
                    <hr class="my-0">
                    <div class="d-flex">
                        <button class="btn btn-primary m-2" (click)="toggleCalendar(dateCalendar)"
                            [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}"
                            i18n="@@done">
                            Done
                        </button>
                    </div>
                </p-footer>
            </p-calendar>
            <p *ngIf="isSubmitted && !date" class="text-danger mt-1" i18n="@@datesRquired">
                Dates are required
            </p>
            <p *ngIf="isSubmitted && !isValidRange" class="text-danger mt-1" i18n="@@maxDateRange">
                Dates range must be maximun one year
            </p>
        </div>
        <div class="col-8"></div>
    </div>
    <p class="my-3 mt-4" i18n="@@filterOptional">Filter (Optional)</p>
    <div class="row mb-4">
        <div class="col">
            <ng-multiselect-dropdown name="travelers" [(ngModel)]="selectedTravelers"
                [settings]="travelerDropdownSettings" [data]="travelers" placeholder="Filter by traveler" i18n-placeholder="@@filterByTraveler" class="w-100">
            </ng-multiselect-dropdown>
        </div>
        <div class="col">
            <ng-multiselect-dropdown name="costcenters" [(ngModel)]="selectedCostCenters"
                [settings]="costCentersDropdownSettings" [data]="costCenters" placeholder="Filter by cost center" i18n-placeholder="@@filterByCostCenter"
                class="w-100">
            </ng-multiselect-dropdown>
        </div>
        <div class="col">
            <ng-multiselect-dropdown name="labels" [(ngModel)]="selectedLabels" [settings]="labelsDropdownSettings"
                [data]="labels" placeholder="Filter by labels" i18n-placeholder="@@filterByLabels" class="w-100">
            </ng-multiselect-dropdown>
        </div>
    </div>
    <div class="row">
        <div class="col-8"></div>
        <div class="pb-4 col-4">
            <button value="submit" class="btn-block green_btn btn-block" [ngClass]="{'m-progress': btnLoader }"
                (click)="generateReport()"
                i18n="@@generateReport">
                Generate Report
            </button>
        </div>
    </div>
    <div class="row">
        <div class="table-section-container rounded p-4 mb-5 pr-4 col me-15">
            <div class="d-flex">
                <img class="mb-3 pb-3" src="assets/img/account_overview.svg" alt="wait">
                <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@totals">Totals</p>
            </div>
            <div class="row" [ngClass]="{'text-center account-p': dataService.desktopView}">
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@spends">Spends</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.totals.totalAmount ?
                        '$' + (bookingStatistics.totals.totalAmount | number: '1.0-0') : '-'}}
                    </p>

                </div>
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@refunds">Refunds</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.totals.totalRefunds ?
                        '$' + (bookingStatistics.totals.totalRefunds | number: '1.0-0') : '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@bookings">Bookings</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.totals.totalBookings || '-'}}
                </div>
                <div class="col-md-3">
                </div>
            </div>
        </div>
        <div class="col ms-15"></div>
    </div>
    <div class="row">
        <div class="table-section-container rounded p-4 mb-5 col me-15">
            <div class="d-flex">
                <img class="mb-3 pb-3" src="assets/img/flight_bookings.svg" alt="wait">
                <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@flights">Flights</p>
            </div>
            <div class="row" [ngClass]="{'text-center account-p': dataService.desktopView}">
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@spends">Spends</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.airBookingsTotals.totalAmount ?
                        '$' + (bookingStatistics.airBookingsTotals.totalAmount | number: '1.0-0') : '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@refunds">Refunds</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.airBookingsTotals.totalRefunds ?
                        '$' + (bookingStatistics.airBookingsTotals.totalRefunds | number: '1.0-0') : '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@bookings">Bookings</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.airBookingsTotals.totalBookings || '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                </div>
            </div>
        </div>
        <div class="table-section-container rounded p-4 mb-5 col ms-15">
            <div class="d-flex">
                <img class="mb-3 pb-3" src="assets/img/hotel_bookings.svg" alt="wait">
                <p class="bold-600 font-16 px-2 pb-3 pt-4px" i18n="@@hotels">Hotels</p>
            </div>
            <div class="row" [ngClass]="{'text-center account-p': dataService.desktopView}">
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@spends">Spends</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.hotelBookingsTotals.totalAmount ?
                        '$' + (bookingStatistics.hotelBookingsTotals.totalAmount | number: '1.0-0') : '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@refunds">Refunds</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.hotelBookingsTotals.totalRefunds ?
                        '$' + (bookingStatistics.hotelBookingsTotals.totalRefunds | number: '1.0-0') : '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                    <p class="mb-2 gray-text" i18n="@@bookings">Bookings</p>
                    <p class="mb-2 font-16"
                        [ngClass]="{'ml-auto': dataService.isSiteLtr(), 'mr-auto': dataService.isSiteRtl()}">
                        {{bookingStatistics.hotelBookingsTotals.totalBookings || '-'}}
                    </p>
                </div>
                <div class="col-md-3">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-50" *ngIf="bookingStatistics.transactions.length">
        <div class="col-12">
            <p-table #dt [scrollable]="true" scrollHeight="500px" [resizableColumns]="true" [columns]="displayedColumns"
                [value]="bookingStatistics.transactions" [rowHover]="true" [rows]="20" [rowHover]="true">
                <ng-template pTemplate="caption">
                    <div class="ui-helper-clearfix" [ngClass]="{'d-flex': dataService.desktopView}">
                        <div class="min-width-workspace-btn mx-0">
                            <button class="btn btn-outline-blue font-14 sharp-font bold-500 mx-0"
                                (click)="exportCSV(dt)" [ngClass]="{'btn-block': dataService.desktopView}" i18n="@@downloadCSV">
                                Download CSV
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" pResizableColumn>
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr class="ui-selectable-row">
                        <td>{{ (dataService.formatDateMMMdy(item.bookingDate)) || '-' }}</td>
                        <td>{{ item.travelerFirstName || '-' }}</td>
                        <td>{{ item.travelerLastName || '-' }}</td>
                        <td>{{ item.travelerEmail || '-' }}</td>
                        <td>{{ item.booker || '-' }}</td>
                        <td>{{ item.travelerType || '-' }}</td>
                        <td>{{ (dataService.formatDateMMMdy(item.startDate)) || '-' }}</td>
                        <td>{{ (dataService.formatDateMMMdy(item.endDate)) || '-' }}</td>
                        <td>{{ item.service || '-' }}</td>
                        <td>{{ item.status || '-' }}</td>
                        <td>{{ item.amount || '-' }}</td>
                        <td>{{ item.refund || '-' }}</td>
                        <td>{{ item.policyName || '-' }}</td>
                        <td>{{ item.inPolicy || '-' }}</td>
                        <td>{{ item.approvalRequired || '-' }}</td>
                        <td>{{ item.approverName || '-' }}</td>
                        <td>{{ item.destinationCountry || '-' }}</td>
                        <td>{{ item.destinationCity || '-' }}</td>
                        <td>{{ item.refNumber || '-' }}</td>
                        <td>{{ item.costCenter || '-' }}</td>
                        <td>{{ item.labels ? item.labels.join(", ") : '-' }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>