import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {VariablesService} from "../../services/vars.service";
import {AuthServiceMain} from "../../auth/auth.service";

@Component({
  selector: 'app-workspace-onboarding',
  templateUrl: './workspace-onboarding.component.html',
  styleUrls: ['./workspace-onboarding.component.scss']
})
export class WorkspaceOnboardingComponent implements OnInit {
  urlArray;
  companyID;
  disabledNext = true;
  data;
  numberOfEmployees;
  submitting = false;
  constructor(public auth: AuthServiceMain, public dataService: DataService, public router: Router, public vars: VariablesService) { }
  ngOnInit(): void {
    this.urlArray = (this.router.url).split('/');
    this.companyID = this.urlArray[1];
  }
  changeEmployeesNumber(employeesNumber){
    this.disabledNext = false;
    this.numberOfEmployees = employeesNumber;
  }
updateCompany() {
    this.submitting = true;
    this.dataService.updateCompany('?numberOfEmployees=' +  this.numberOfEmployees).subscribe(
    data => {
      this.data = data;
      if (this.data.errorCode === 0) {
        this.auth.fetchCompanyData();
        this.router.navigate(['/' + this.companyID + '/upload-logo']);
        this.submitting = false;
      } else {
        this.submitting = false;
        this.dataService.messages(this.data.message, 'error');
      }
    }, error => {
      this.submitting = false;
      this.dataService.messages(environment.messages.error, 'error');
    }
  );
}
}
