<div class="manage-booking container mt-4 mb-5">
  <div class="d-flex">
    <h2
      class="green-text bolder p-2"
      [ngClass]="{
        'mr-auto': Service.isSiteLtr(),
        'ml-auto': Service.isSiteRtl(),
        'font-24': Service.desktopView,
        'font-14': Service.mobileView
      }"
    >
      <i
        class="icon-success font-24-to-18"
        [ngClass]="{ 'mr-3': Service.isSiteLtr(), 'ml-3': Service.isSiteRtl() }"
      >
      </i>
      <span
        *ngIf="!Service.inactiveWorkspace && bookingType === 'cancelled'"
        i18n="@@activeBookingCanceled"
        >Your booking is canceled</span
      >
      <span
        *ngIf="!Service.inactiveWorkspace && bookingType === 'completed'"
        i18n="@@activeBookingCompleted"
        >Your booking is completed</span
      >
      <span
        *ngIf="!Service.inactiveWorkspace && bookingType === 'confirmed'"
        i18n="@@activeBookingConfirmed"
        >Your booking is confirmed</span
      >
      <span
        *ngIf="Service.inactiveWorkspace && bookingType === 'cancelled'"
        i18n="@@inActiveBookingCanceled"
        >Booking is canceled</span
      >
      <span
        *ngIf="Service.inactiveWorkspace && bookingType === 'completed'"
        i18n="@@inActiveBookingCompleted"
        >Booking is completed</span
      >
      <span
        *ngIf="Service.inactiveWorkspace && bookingType === 'confirmed'"
        i18n="@@inActiveBookingConfirmed"
        >Booking is confirmed</span
      >
    </h2>
    <button
      class="btn btn-link karla-font font-16"
      [ngClass]="{ 'pr-3': Service.isSiteLtr(), 'p-3': Service.isSiteRtl() }"
      (click)="Service.showPrint = true; print()"
      *ngIf="Service.desktopView"
      i18n="@@print"
    >
      Print
    </button>
    <button
      (click)="openSendBookingVoucherPopUp()"
      class="btn btn-link karla-font font-16"
      *ngIf="Service.desktopView && !Service.inactiveWorkspace"
      i18n="@@email"
    >
      Email
    </button>
  </div>
  <div class="row">
    <div
      class="col-md-8 custom-col8"
      [ngClass]="{ 'px-0': Service.mobileView }"
      *ngIf="bookingDetails"
    >
      <div
        class="table-header pt-4 pb-2"
        [ngClass]="{
          'rounded border': Service.desktopView,
          'border-top border-bottom': Service.mobileView
        }"
      >
        <p class="gray-primaray-text font-16 mb-0" i18n="@@travelDates">
          Travel Dates
        </p>
        <p class="mb-5 font-14">
          <span
            class="gray-text-dark"
            [innerHTML]="
              Service.formatDateMMMdy(summaryDeparture.departureDate)
            "
          ></span>
          <span *ngIf="summaryReturn"> - </span>
          <span
            *ngIf="summaryReturn"
            class="gray-text-dark"
            [innerHTML]="Service.formatDateMMMdy(summaryReturn.departureDate)"
          ></span>
        </p>
        <p
          class="gray-primaray-text font-16 mb-0"
          i18n="@@safarHubConfirmationNumber"
        >
          SafarHub confirmation number
        </p>
        <p class="gray-text-dark font-14 mb-5">
          {{ this.bookingDetails.hrsReferenceNumber }}
        </p>
        <div class="mb-5">
          <ng-container *ngIf="bookingDetails.tripDetails">
            <p
              class="gray-primaray-text font-16 mb-0"
              i18n="@@airlinesConfirmationNumbers"
            >
              Airlines confirmation numbers
            </p>
            <ng-container
              *ngFor="
                let airline of bookingDetails.tripDetails.itineraryInfo
                  .reservationItems
              "
            >
              <p class="gray-text-dark font-14 mb-0">
                {{ airline.marketingAirlineCode }}
                <ng-container i18n="@@airline">Airline</ng-container>:
                {{ airline.airlinePNR }}
              </p>
            </ng-container>
          </ng-container>
        </div>
        <div>
          <p class="gray-primaray-text font-16 mb-0" i18n="@@theTraverlers">
            Travelers
          </p>
          <div *ngIf="bookingDetails.tripDetails" class="pb-3">
            <ng-container
              *ngFor="
                let traveler of bookingDetails.tripDetails.itineraryInfo
                  .passengers
              "
            >
              <p class="gray-text-dark font-14 mb-0">
                {{ traveler.firstName }} {{ traveler.lastName }}
                <span class="font-14 text-capitalize gray-text"
                  >- {{ traveler.type.toLowerCase() }}</span
                >
              </p>
              <p class="gray-text font-14 mb-0" *ngIf="traveler.eTicketNumber">
                <ng-container i18n="@@ticket">Ticket</ng-container>&nbsp;#
                {{ traveler.eTicketNumber }}
              </p>
            </ng-container>
          </div>
          <ng-container *ngIf="!bookingDetails.tripDetails">
            <ng-container *ngIf="bookingDetails.bookRequest.adults">
              <ng-container
                *ngFor="let traveler of bookingDetails.bookRequest.adults"
              >
                <p class="gray-text-dark font-14">
                  {{ traveler.firstName }} {{ traveler.lastName }}
                  <span class="font-14 text-capitalize gray-text"
                    >-&nbsp;<ng-container i18n="@@adult"
                      >Adult</ng-container
                    ></span
                  >
                </p>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="bookingDetails.bookRequest.children">
              <ng-container
                *ngFor="let traveler of bookingDetails.bookRequest.children"
              >
                <p class="gray-text-dark font-14">
                  {{ traveler.firstName }} {{ traveler.lastName }}
                  <span class="font-14 text-capitalize gray-text"
                    >-&nbsp;<ng-container i18n="@@child"
                      >Child</ng-container
                    ></span
                  >
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        class="table-header pt-4 mt-5"
        [ngClass]="{
          'rounded border': Service.desktopView,
          'bg-white': Service.mobileView
        }"
      >
        <p class="mb-3 mb-lg-0">
          <span
            class="font-20 primaray-text font-weight-bold"
            [ngClass]="{
              'mr-3': Service.isSiteLtr(),
              'ml-3': Service.isSiteRtl()
            }"
            i18n="@@departure"
            >Departure</span
          >
          <span
            class="gray-text-dark"
            *ngIf="Service.desktopView"
            [innerHTML]="
              Service.formatDateEEMMMdy(summaryDeparture.departureDate)
            "
          ></span>
        </p>
        <p class="gray-primaray-text" *ngIf="Service.desktopView">
          {{ summaryDeparture.departureAirport.city }}
          <span class="text-lowercase" i18n="@@to">to</span>
          {{ summaryDeparture.arrivalAirport.city }}
        </p>
        <div
          class="bg-white"
          [ngClass]="{ 'rounded border shadow-sm p-4': Service.desktopView }"
        >
          <div class="row">
            <div class="col-md-7 col-lg-8">
              <ng-container
                *ngFor="let flight of departureDetails; let i = index"
              >
                <div class="d-flex">
                  <img
                    width="26.5"
                    height="27"
                    loading="lazy"
                    class="max-100 img-min-width"
                    [ngClass]="{
                      'mr-3': Service.isSiteLtr(),
                      'ml-3': Service.isSiteRtl()
                    }"
                    [src]="flight.flightSegment.marketingAirlineLogoUrl"
                    onError="this.src='/assets/img/airline.png'"
                  />
                  <div>
                    <p class="mb-0 font-16 gray-text-dark">
                      {{ flight.flightSegment.marketingAirlineName }}
                      {{ flight.flightSegment.marketingAirlineCode }}
                      {{ flight.flightSegment.flightNumber }}
                    </p>
                    <p
                      class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1"
                      *ngIf="
                        flight.flightSegment.departureAirport.bus ||
                        flight.flightSegment.arrivalAirport.bus
                      "
                      i18n="@@noteBus"
                    >
                      Note: This is bus service
                    </p>
                    <p
                      class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1"
                      *ngIf="
                        flight.flightSegment.departureAirport.train ||
                        flight.flightSegment.arrivalAirport.train
                      "
                      i18n="@@noteTrain"
                    >
                      Note: This is train service
                    </p>
                    <p
                      class="gray-text mb-0"
                      *ngIf="flight.flightSegment.operatingAirline"
                    >
                      <ng-container i18n="@@operatedBy"
                        >Operated by</ng-container
                      >
                      {{ flight.flightSegment.operatingAirline.name }}
                    </p>
                    <p class="gray-text mb-0">
                      {{ flight.flightSegment.cabinClassName }}
                    </p>
                  </div>
                </div>
                <div class="d-flex mt-3">
                  <i
                    class="icon-fly-from placeholder-text font-20"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="
                      !flight.flightSegment.departureAirport.bus &&
                      !flight.flightSegment.arrivalAirport.bus &&
                      !flight.flightSegment.departureAirport.train &&
                      !flight.flightSegment.arrivalAirport.train
                    "
                  ></i>
                  <i
                    class="icon-bus-station placeholder-text font-18"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="
                      flight.flightSegment.departureAirport.bus ||
                      flight.flightSegment.arrivalAirport.bus
                    "
                  ></i>
                  <i
                    class="icon-train-station placeholder-text font-18"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="
                      flight.flightSegment.departureAirport.train ||
                      flight.flightSegment.arrivalAirport.train
                    "
                  ></i>
                  <p class="mb-0 gray-text-dark">
                    <span
                      [innerHTML]="
                        this.Service.formatDateTime12Hours(
                          flight.flightSegment.departureDateTime
                        )
                      "
                      >10:15pm</span
                    ><ng-container *ngIf="Service.desktopView">
                      - {{ flight.flightSegment.departureAirport.name }} ({{
                        flight.flightSegment.departureAirport.iata
                      }})</ng-container
                    >
                  </p>
                </div>
                <div class="d-flex pb-2">
                  <span
                    class="border-right-square"
                    [ngClass]="{
                      'ml-2': Service.isSiteLtr(),
                      'mr-2': Service.isSiteRtl()
                    }"
                  ></span>
                  <div>
                    <p
                      class="extra-left-margin gray-text"
                      [ngClass]="{
                        'bus-margin':
                          flight.flightSegment.departureAirport.bus ||
                          flight.flightSegment.arrivalAirport.bus
                      }"
                      *ngIf="Service.desktopView"
                    >
                      {{ flight.flightSegment.departureAirport.city }}
                    </p>
                    <p
                      class="mb-4 gray-text-dark"
                      [ngClass]="{
                        'ml-4 pl-1': Service.isSiteLtr(),
                        'mr-4 pr-1': Service.isSiteRtl()
                      }"
                      *ngIf="Service.mobileView"
                    >
                      {{ flight.flightSegment.departureAirport.name }} ({{
                        flight.flightSegment.departureAirport.iata
                      }})
                    </p>
                    <p
                      class="mb-4 font-12 gray-text"
                      [ngClass]="{
                        'ml-4 pl-1 text-left': Service.isSiteLtr(),
                        'mr-4 pr-1 text-right': Service.isSiteRtl()
                      }"
                    >
                      <ng-container i18n="@@duration">Duration:</ng-container
                      >&nbsp;
                      <span
                        [innerHTML]="
                          this.Service.calDuration(
                            flight.flightSegment.journeyDuration
                          )
                        "
                        >2h 33m</span
                      >
                    </p>
                  </div>
                </div>
                <div class="d-flex">
                  <i
                    class="icon-fly-to placeholder-text font-18"
                    [ngClass]="{
                      'mr-2 pr-1': Service.isSiteLtr(),
                      'ml-2 pl-1': Service.isSiteRtl()
                    }"
                    *ngIf="
                      !flight.flightSegment.departureAirport.bus &&
                      !flight.flightSegment.arrivalAirport.bus &&
                      !flight.flightSegment.departureAirport.train &&
                      !flight.flightSegment.arrivalAirport.train
                    "
                  ></i>
                  <i
                    class="icon-bus-station placeholder-text font-18"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="
                      flight.flightSegment.departureAirport.bus ||
                      flight.flightSegment.arrivalAirport.bus
                    "
                  ></i>
                  <i
                    class="icon-train-station placeholder-text font-18"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                    *ngIf="
                      flight.flightSegment.departureAirport.train ||
                      flight.flightSegment.arrivalAirport.train
                    "
                  ></i>
                  <p class="mb-0 gray-text-dark">
                    <span
                      [innerHTML]="
                        this.Service.formatDateTime12Hours(
                          flight.flightSegment.arrivalDateTime
                        )
                      "
                      >10:15pm</span
                    >
                    <span
                      class="font-14 font-weight-normal"
                      *ngIf="
                        Service.checkNextDay(
                          flight.flightSegment.departureDateTime,
                          flight.flightSegment.arrivalDateTime
                        ) === 1
                      "
                      i18n="@@nextDay"
                    >
                      (Next day)</span
                    >
                    <span
                      class="font-14 font-weight-normal"
                      *ngIf="
                        Service.checkNextDay(
                          flight.flightSegment.departureDateTime,
                          flight.flightSegment.arrivalDateTime
                        ) > 1
                      "
                    >
                      +{{
                        Service.checkNextDay(
                          flight.flightSegment.departureDateTime,
                          flight.flightSegment.arrivalDateTime
                        )
                      }}</span
                    >
                    <span
                      class="font-14 font-weight-normal"
                      *ngIf="
                        Service.checkPreviousDay(
                          flight.flightSegment.departureDateTime,
                          flight.flightSegment.arrivalDateTime
                        )
                      "
                      i18n="@@prevDay"
                    >
                      (Previous day)</span
                    >
                    <ng-container *ngIf="Service.desktopView"> - </ng-container
                    ><br *ngIf="Service.mobileView" />{{
                      flight.flightSegment.arrivalAirport.name
                    }}
                    ({{ flight.flightSegment.arrivalAirport.iata }})
                  </p>
                </div>
                <div class="d-flex pb-2">
                  <span
                    [ngClass]="{
                      'ml-3': Service.isSiteLtr(),
                      'mr-3': Service.isSiteRtl()
                    }"
                  ></span>
                  <div>
                    <p
                      class="extra-left-margin gray-text"
                      [ngClass]="{
                        'bus-margin':
                          flight.flightSegment.departureAirport.bus ||
                          flight.flightSegment.arrivalAirport.bus
                      }"
                      *ngIf="Service.desktopView"
                    >
                      {{ flight.flightSegment.arrivalAirport.city }}
                    </p>
                  </div>
                </div>
                <div
                  *ngIf="flight.stopDurationInMinutes"
                  class="primaray-text font-16 mb-5 mt-4"
                >
                  <i class="icon-wall-clock align-middle mr-2"></i>
                  <span
                    [innerHTML]="
                      this.Service.calDuration(flight.stopDurationInMinutes)
                    "
                    >2h 33m</span
                  >&nbsp;<ng-container i18n="@@layoverIn"
                    >layover in</ng-container
                  >
                  {{ flight.flightSegment.arrivalAirport.city
                  }}<span
                    *ngIf="!flight.flightSegment.airportChangeDescription"
                  >
                    ({{ flight.flightSegment.arrivalAirport.iata }})</span
                  >
                </div>
                <div
                  class="position-relative py-4"
                  *ngIf="flight.flightSegment.airportChangeDescription"
                >
                  <div
                    class="position-absolute font-12 text-white orange-bg change-stop rounded px-3"
                  >
                    {{ flight.flightSegment.airportChangeDescription }}
                  </div>
                </div>
              </ng-container>
            </div>
            <div
              class="col-md-5 col-lg-4"
              *ngIf="Service.desktopView"
              id="fare-rules"
            >
              <p class="font-20 gray-primaray-text mb-4" i18n="@@fareRules">
                Fare Rules
              </p>
              <p class="gray-text mb-1">
                <i
                  class="icon-carry-on-bag font-16"
                  [ngClass]="{
                    'mr-3': Service.isSiteLtr(),
                    'ml-3': Service.isSiteRtl()
                  }"
                ></i
                ><ng-container i18n="@@carryOnBag"
                  >Carry-on bag included</ng-container
                >
              </p>
              <ng-container *ngIf="rules">
                <ng-container *ngFor="let bag of departRulesArray">
                  <p class="gray-text mb-1">
                    <i
                      class="icon-checked-bag font-20"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                    ></i
                    >{{ bag.baggage }}
                    <span *ngIf="departRulesArray.length > 1">
                      ({{ bag.flightNo }})</span
                    >
                  </p>
                </ng-container>
              </ng-container>
              <p
                class="green-text mt-4 pt-2"
                *ngIf="bookingDetails.airItinerary.fareInfo.refundable === true"
                i18n="@@refundable"
              >
                Refundable
              </p>
              <p
                class="gray-text-dark mt-4 pt-2"
                *ngIf="
                  bookingDetails.airItinerary.fareInfo.refundable === false
                "
                i18n="@@nonrefundable"
              >
                Non Refundable
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="returnDetails">
          <p class="mb-3 mb-lg-0 mt-5 pt-4">
            <span
              class="font-20 primaray-text font-weight-bold"
              [ngClass]="{
                'mr-3': Service.isSiteLtr(),
                'ml-3': Service.isSiteRtl()
              }"
              i18n="@@return"
              >Return</span
            >
            <span
              class="gray-text-dark"
              *ngIf="Service.desktopView"
              [innerHTML]="
                Service.formatDateEEMMMdy(summaryReturn.departureDate)
              "
            ></span>
          </p>
          <p class="gray-primaray-text" *ngIf="Service.desktopView">
            {{ summaryReturn.departureAirport.city }}
            <span class="text-lowercase" i18n="@@to">to</span>
            {{ summaryReturn.arrivalAirport.city }}
          </p>
          <div
            class="bg-white"
            [ngClass]="{ 'rounded border shadow-sm p-4': Service.desktopView }"
          >
            <div class="row">
              <div class="col-md-7 col-lg-8">
                <ng-container
                  *ngFor="let flight of returnDetails; let i = index"
                >
                  <div class="d-flex">
                    <img
                      width="26.5"
                      height="27"
                      loading="lazy"
                      class="max-100 img-min-width"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      [src]="flight.flightSegment.marketingAirlineLogoUrl"
                      onError="this.src='/assets/img/airline.png'"
                    />
                    <div>
                      <p class="mb-0 font-16 gray-text-dark">
                        {{ flight.flightSegment.marketingAirlineName }}
                        {{ flight.flightSegment.marketingAirlineCode }}
                        {{ flight.flightSegment.flightNumber }}
                      </p>
                      <p
                        class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1"
                        *ngIf="
                          flight.flightSegment.departureAirport.bus ||
                          flight.flightSegment.arrivalAirport.bus
                        "
                        i18n="@@noteBus"
                      >
                        Note: This is bus service
                      </p>
                      <p
                        class="font-12 orange-bg text-white mb-0 py-2 px-3 rounded my-1"
                        *ngIf="
                          flight.flightSegment.departureAirport.train ||
                          flight.flightSegment.arrivalAirport.train
                        "
                        i18n="@@noteTrain"
                      >
                        Note: This is train service
                      </p>
                      <p
                        class="gray-text mb-0"
                        *ngIf="flight.flightSegment.operatingAirline"
                      >
                        <ng-container i18n="@@operatedBy"
                          >Operated by</ng-container
                        >
                        {{ flight.flightSegment.operatingAirline.name }}
                      </p>
                      <p class="gray-text mb-0">
                        {{ flight.flightSegment.cabinClassName }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex mt-3">
                    <i
                      class="icon-fly-from placeholder-text font-20"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      *ngIf="
                        !flight.flightSegment.departureAirport.bus &&
                        !flight.flightSegment.arrivalAirport.bus &&
                        !flight.flightSegment.departureAirport.train &&
                        !flight.flightSegment.arrivalAirport.train
                      "
                    ></i>
                    <i
                      class="icon-bus-station placeholder-text font-18"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      *ngIf="
                        flight.flightSegment.departureAirport.bus ||
                        flight.flightSegment.arrivalAirport.bus
                      "
                    ></i>
                    <i
                      class="icon-train-station placeholder-text font-18"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      *ngIf="
                        flight.flightSegment.departureAirport.train ||
                        flight.flightSegment.arrivalAirport.train
                      "
                    ></i>
                    <p class="mb-0 gray-text-dark">
                      <span
                        [innerHTML]="
                          this.Service.formatDateTime12Hours(
                            flight.flightSegment.departureDateTime
                          )
                        "
                        >10:15pm</span
                      ><ng-container *ngIf="Service.desktopView">
                        - {{ flight.flightSegment.departureAirport.name }} ({{
                          flight.flightSegment.departureAirport.iata
                        }})</ng-container
                      >
                    </p>
                  </div>
                  <div class="d-flex pb-2">
                    <span
                      class="border-right-square"
                      [ngClass]="{
                        'ml-2': Service.isSiteLtr(),
                        'mr-2': Service.isSiteRtl()
                      }"
                    ></span>
                    <div>
                      <p
                        class="extra-left-margin gray-text"
                        [ngClass]="{
                          'bus-margin':
                            flight.flightSegment.departureAirport.bus ||
                            flight.flightSegment.arrivalAirport.bus
                        }"
                        *ngIf="Service.desktopView"
                      >
                        {{ flight.flightSegment.departureAirport.city }}
                      </p>
                      <p
                        class="mb-4 gray-text-dark"
                        [ngClass]="{
                          'ml-4 pl-1': Service.isSiteLtr(),
                          'mr-4 pr-1': Service.isSiteRtl()
                        }"
                        *ngIf="Service.mobileView"
                      >
                        {{ flight.flightSegment.departureAirport.name }} ({{
                          flight.flightSegment.departureAirport.iata
                        }})
                      </p>
                      <p
                        class="mb-4 font-12 gray-text"
                        [ngClass]="{
                          'ml-4 pl-1 text-left': Service.isSiteLtr(),
                          'mr-4 pr-1 text-right': Service.isSiteRtl()
                        }"
                      >
                        <ng-container i18n="@@duration">Duration:</ng-container
                        >&nbsp;
                        <span
                          [innerHTML]="
                            this.Service.calDuration(
                              flight.flightSegment.journeyDuration
                            )
                          "
                          >2h 33m</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <i
                      class="icon-fly-to placeholder-text font-18"
                      [ngClass]="{
                        'mr-2 pr-1': Service.isSiteLtr(),
                        'ml-2 pl-1': Service.isSiteRtl()
                      }"
                      *ngIf="
                        !flight.flightSegment.departureAirport.bus &&
                        !flight.flightSegment.arrivalAirport.bus &&
                        !flight.flightSegment.departureAirport.train &&
                        !flight.flightSegment.arrivalAirport.train
                      "
                    ></i>
                    <i
                      class="icon-bus-station placeholder-text font-18"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      *ngIf="
                        flight.flightSegment.departureAirport.bus ||
                        flight.flightSegment.arrivalAirport.bus
                      "
                    ></i>
                    <i
                      class="icon-train-station placeholder-text font-18"
                      [ngClass]="{
                        'mr-2': Service.isSiteLtr(),
                        'ml-2': Service.isSiteRtl()
                      }"
                      *ngIf="
                        flight.flightSegment.departureAirport.train ||
                        flight.flightSegment.arrivalAirport.train
                      "
                    ></i>
                    <p class="mb-0 gray-text-dark">
                      <span
                        [innerHTML]="
                          this.Service.formatDateTime12Hours(
                            flight.flightSegment.arrivalDateTime
                          )
                        "
                        >10:15pm</span
                      >
                      <span
                        class="font-14 font-weight-normal"
                        *ngIf="
                          Service.checkNextDay(
                            flight.flightSegment.departureDateTime,
                            flight.flightSegment.arrivalDateTime
                          ) === 1
                        "
                        i18n="@@nextDay"
                      >
                        (Next day)</span
                      >
                      <span
                        class="font-14 font-weight-normal"
                        *ngIf="
                          Service.checkNextDay(
                            flight.flightSegment.departureDateTime,
                            flight.flightSegment.arrivalDateTime
                          ) > 1
                        "
                      >
                        +{{
                          Service.checkNextDay(
                            flight.flightSegment.departureDateTime,
                            flight.flightSegment.arrivalDateTime
                          )
                        }}</span
                      >
                      <span
                        class="font-14 font-weight-normal"
                        *ngIf="
                          Service.checkPreviousDay(
                            flight.flightSegment.departureDateTime,
                            flight.flightSegment.arrivalDateTime
                          )
                        "
                        i18n="@@prevDay"
                      >
                        (Previous day)</span
                      >
                      <ng-container *ngIf="Service.desktopView">
                        - </ng-container
                      ><br *ngIf="Service.mobileView" />{{
                        flight.flightSegment.arrivalAirport.name
                      }}
                      ({{ flight.flightSegment.arrivalAirport.iata }})
                    </p>
                  </div>
                  <div class="d-flex pb-2">
                    <span
                      [ngClass]="{
                        'ml-3': Service.isSiteLtr(),
                        'mr-3': Service.isSiteRtl()
                      }"
                    ></span>
                    <div>
                      <p
                        class="extra-left-margin gray-text"
                        [ngClass]="{
                          'bus-margin':
                            flight.flightSegment.departureAirport.bus ||
                            flight.flightSegment.arrivalAirport.bus
                        }"
                        *ngIf="Service.desktopView"
                      >
                        {{ flight.flightSegment.arrivalAirport.city }}
                      </p>
                    </div>
                  </div>
                  <div
                    *ngIf="flight.stopDurationInMinutes"
                    class="primaray-text font-16 mb-5 mt-4"
                  >
                    <i class="icon-wall-clock align-middle mr-2"></i>
                    <span
                      [innerHTML]="
                        this.Service.calDuration(flight.stopDurationInMinutes)
                      "
                      >2h 33m</span
                    >&nbsp;<ng-container i18n="@@layoverIn"
                      >layover in</ng-container
                    >
                    {{ flight.flightSegment.arrivalAirport.city
                    }}<span
                      *ngIf="!flight.flightSegment.airportChangeDescription"
                    >
                      ({{ flight.flightSegment.arrivalAirport.iata }})</span
                    >
                  </div>
                  <div
                    class="position-relative py-4"
                    *ngIf="flight.flightSegment.airportChangeDescription"
                  >
                    <div
                      class="position-absolute font-12 text-white orange-bg change-stop rounded px-3"
                    >
                      {{ flight.flightSegment.airportChangeDescription }}
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="col-md-5 col-lg-4" *ngIf="Service.desktopView">
                <p class="font-20 gray-primaray-text mb-4" i18n="@@fareRules">
                  Fare Rules
                </p>
                <p class="gray-text mb-1">
                  <i
                    class="icon-carry-on-bag font-16"
                    [ngClass]="{
                      'mr-3': Service.isSiteLtr(),
                      'ml-3': Service.isSiteRtl()
                    }"
                  ></i
                  ><ng-container i18n="@@carryOnBag"
                    >Carry-on bag included</ng-container
                  >
                </p>
                <ng-container *ngIf="rules">
                  <ng-container *ngFor="let bag of returnRulesArray">
                    <p class="gray-text mb-1">
                      <i
                        class="icon-checked-bag font-20"
                        [ngClass]="{
                          'mr-2': Service.isSiteLtr(),
                          'ml-2': Service.isSiteRtl()
                        }"
                      ></i
                      >{{ bag.baggage }}
                      <span *ngIf="returnRulesArray.length > 1">
                        ({{ bag.flightNo }})</span
                      >
                    </p>
                  </ng-container>
                </ng-container>
                <p
                  class="green-text mt-4 pt-2"
                  *ngIf="
                    bookingDetails.airItinerary.fareInfo.refundable === true
                  "
                  i18n="@@refundable"
                >
                  Refundable
                </p>
                <p
                  class="gray-text-dark mt-4 pt-2"
                  *ngIf="
                    bookingDetails.airItinerary.fareInfo.refundable === false
                  "
                  i18n="@@nonrefundable"
                >
                  Non Refundable
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="table-header bg-white pb-0"
        *ngIf="bookingDetails && Service.mobileView"
      >
        <div>
          <p
            class="font-16 gray-primaray-text mb-4 font-weight-bold"
            i18n="@@fareRules"
          >
            Fare Rules
          </p>
          <div class="white-box rounded border" id="fare-rules">
            <div class="d-flex">
              <p class="mb-0 gray-text-dark">
                {{ this.summaryDeparture.departureAirport.name }}
              </p>
              <div
                [ngClass]="{
                  'pl-2': Service.isSiteLtr(),
                  'pr-2': Service.isSiteRtl()
                }"
              >
                <i
                  class="icon-flight-item font-11 gray-text"
                  [ngClass]="{
                    'mr-2': Service.isSiteLtr(),
                    'ml-2': Service.isSiteRtl()
                  }"
                ></i>
              </div>
            </div>
            <p class="mb-4 gray-text-dark">
              {{ this.summaryDeparture.arrivalAirport.name }}
            </p>
            <p class="gray-text mb-1">
              <i
                class="icon-carry-on-bag font-16"
                [ngClass]="{
                  'mr-3': Service.isSiteLtr(),
                  'ml-3': Service.isSiteRtl()
                }"
              ></i
              ><ng-container i18n="@@carryOnBag"
                >Carry-on bag included</ng-container
              >
            </p>
            <ng-container *ngIf="rules">
              <ng-container *ngFor="let bag of departRulesArray">
                <p class="gray-text mb-1">
                  <i
                    class="icon-checked-bag font-20"
                    [ngClass]="{
                      'mr-2': Service.isSiteLtr(),
                      'ml-2': Service.isSiteRtl()
                    }"
                  ></i
                  >{{ bag.baggage }}
                  <span *ngIf="departRulesArray.length > 1">
                    ({{ bag.flightNo }})</span
                  >
                </p>
              </ng-container>
            </ng-container>
            <p
              class="green-text mt-4 pt-2"
              *ngIf="
                this.bookingDetails.airItinerary.fareInfo.refundable === true
              "
              i18n="@@refundable"
            >
              Refundable
            </p>
            <p
              class="gray-text-dark mt-4 pt-2"
              *ngIf="
                this.bookingDetails.airItinerary.fareInfo.refundable === false
              "
              i18n="@@nonrefundable"
            >
              Non Refundable
            </p>
          </div>

          <div class="white-box rounded border mt-3" *ngIf="returnDetails">
            <div class="d-flex">
              <p class="mb-0 gray-text-dark">
                {{ this.summaryReturn.departureAirport.name }}
              </p>
              <div
                [ngClass]="{
                  'pl-2': Service.isSiteLtr(),
                  'pr-2': Service.isSiteRtl()
                }"
              >
                <i
                  class="icon-flight-item font-11 gray-text"
                  [ngClass]="{
                    'mr-2': Service.isSiteLtr(),
                    'ml-2': Service.isSiteRtl()
                  }"
                ></i>
              </div>
            </div>
            <p class="mb-4 gray-text-dark">
              {{ this.summaryReturn.arrivalAirport.name }}
            </p>
            <p class="gray-text mb-1">
              <i
                class="icon-carry-on-bag font-16"
                [ngClass]="{
                  'mr-3': Service.isSiteLtr(),
                  'ml-3': Service.isSiteRtl()
                }"
              ></i
              ><ng-container i18n="@@carryOnBag"
                >Carry-on bag included</ng-container
              >
            </p>
            <ng-container *ngIf="rules">
              <ng-container *ngFor="let bag of returnRulesArray">
                <p class="gray-text mb-1">
                  <i
                    class="icon-checked-bag font-20"
                    [ngClass]="{
                      'mr-3': Service.isSiteLtr(),
                      'ml-3': Service.isSiteRtl()
                    }"
                  ></i
                  >{{ bag.baggage }}
                  <span *ngIf="returnRulesArray.length > 1">
                    ({{ bag.flightNo }})</span
                  >
                </p>
              </ng-container>
            </ng-container>
            <p
              class="green-text mt-4 pt-2"
              *ngIf="
                this.bookingDetails.airItinerary.fareInfo.refundable === true
              "
              i18n="@@refundable"
            >
              Refundable
            </p>
            <p
              class="gray-text-dark mt-4 pt-2"
              *ngIf="
                this.bookingDetails.airItinerary.fareInfo.refundable === false
              "
              i18n="@@nonrefundable"
            >
              Non Refundable
            </p>
          </div>
        </div>
        <div *ngIf="Service.mobileView" class="mt-3">
          <button
            class="btn btn-link karla-font font-16"
            [ngClass]="{
              'pr-3': Service.isSiteLtr(),
              'pl-3': Service.isSiteRtl()
            }"
            (click)="Service.showHotelPrint = true; print()"
            i18n="@@print"
          >
            Print
          </button>
          <button
            *ngIf="!Service.inactiveWorkspace"
            (click)="shareByEmailMobile()"
            class="btn btn-link karla-font font-16"
            i18n="@@email"
          >
            Email
          </button>
        </div>
        <div class="white-box p-0 my-4 price_summary">
          <div class="p-3">
            <h5
              class="text-big mb-3"
              [ngClass]="{ bolder: !Service.isCompany() }"
              i18n="@@priceSummary"
            >
              Price Summary
            </h5>
            <ng-container
              *ngFor="
                let traveler of bookingDetails.airItinerary.fareInfo
                  .fareBreakdown;
                let travelerIndex = index
              "
            >
              <ng-container
                *ngFor="
                  let travelerRepeat of [].constructor(
                    traveler.passengerTypeQuantity.quantity
                  );
                  let repeatIndex = index
                "
              >
                <div class="discount d-flex font-16">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    *ngIf="travelerIndex === 0"
                    ><ng-container i18n="@@traveler">Traveler</ng-container
                    >{{ repeatIndex + 1 }}:
                    <span class="text-capitalize">{{
                      traveler.passengerTypeQuantity.type.toLowerCase()
                    }}</span></span
                  >
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    *ngIf="travelerIndex === 1"
                    ><ng-container i18n="@@traveler">Traveler</ng-container
                    >{{
                      bookingDetails.airItinerary.fareInfo.fareBreakdown[0]
                        .passengerTypeQuantity.quantity +
                        repeatIndex +
                        1
                    }}:
                    <span class="text-capitalize">{{
                      traveler.passengerTypeQuantity.type.toLowerCase()
                    }}</span></span
                  >
                  <span
                    >${{
                      traveler.sellingPassengerFare.totalFare.amount
                        | number : "1.2-2"
                    }}</span
                  >
                </div>
                <div class="discount d-flex font-14 gray-text my-2">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@flight"
                    >Flight</span
                  >
                  <span
                    >${{
                      traveler.sellingPassengerFare.baseFare.amount
                        | number : "1.2-2"
                    }}</span
                  >
                </div>
                <div class="discount d-flex font-14 gray-text mb-4">
                  <span
                    [ngClass]="{
                      'mr-auto': Service.isSiteLtr(),
                      'ml-auto': Service.isSiteRtl()
                    }"
                    i18n="@@taxAndFees"
                    >Tax and fees</span
                  >
                  <span>
                    <span
                      >${{
                        this.Service.calTaxes(
                          traveler.sellingPassengerFare.taxes
                        ) | number : "1.2-2"
                      }}</span
                    >
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="white-box-footer rounded-bottom p-3">
            <div class="d-flex font-20 bold-600">
              <span
                class="primaray-text"
                [ngClass]="{ bolder: !Service.isCompany() }"
                i18n="@@Total"
                >Total</span
              >
              <span
                class="primaray-text"
                [ngClass]="{
                  'ml-auto': Service.isSiteLtr(),
                  'mr-auto': Service.isSiteRtl()
                }"
                ><span class="currency"
                  >${{
                    bookingDetails.airItinerary.fareInfo.sellingTotalFares.total
                      .amount | number : "1.0-2"
                  }}</span
                ><span class="amount"></span
              ></span>
            </div>
          </div>
        </div>
        <div
          class="white-box p-0"
          *ngIf="bookingDetails.rewardAmount && bookingType !== 'cancelled'"
        >
          <div
            class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"
          >
            <ng-container i18n="@@rewards">Rewards</ng-container>:
            <ng-container *ngIf="Service.isSiteLtr()">$</ng-container
            >{{ bookingDetails.rewardAmount | number : "1.2-2"
            }}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
          </div>
          <div
            class="px-3 pt-3 pb-4 gray-primaray-text font-16"
            i18n="@@manageFlightUponCompleting"
          >
            You will earn ${{
              bookingDetails.rewardAmount | number : "1.2-2"
            }}
            upon completing this trip
          </div>
        </div>
        <app-cost-reporting-details
          [costCentersIds]="
            bookingDetails?.costCenter ? [bookingDetails?.costCenter] : ''
          "
          [labelsIds]="bookingDetails?.labels ? bookingDetails?.labels : []"
        >
        </app-cost-reporting-details>
      </div>
      <div
        class="table-header pt-4 mt-5"
        [ngClass]="{
          'rounded border': Service.desktopView,
          'border-top border-bottom': Service.mobileView
        }"
      >
        <p class="font-18 gray-primaray-text" i18n="@@beforeYouTravel1">
          Before you travel
        </p>
        <ul
          class="gray-text-dark"
          [ngClass]="{
            'pl-4': Service.isSiteLtr(),
            'pr-4': Service.isSiteRtl()
          }"
        >
          <li i18n="@@beforeYouTravel2">
            Make sure to have your E-ticket with you at the airport.
          </li>
          <li i18n="@@beforeYouTravel3">
            Bring your government-issued photo ID at the airport check-in and
            security.
          </li>
          <li i18n="@@beforeYouTravel4">
            For international travel, make sure to bring all necessary travel
            documentation (passport, visa, transit permit or any other documents
            required at the destination).
          </li>
        </ul>
        <p class="gray-text-dark mb-0 mt-5" i18n="@@bookingDetails4">
          You can contact the airline for the following
        </p>
        <ul
          class="gray-text-dark"
          [ngClass]="{
            'pl-4': Service.isSiteLtr(),
            'pr-4': Service.isSiteRtl()
          }"
        >
          <li i18n="@@beforeYouTravel5">Special meals</li>
          <li i18n="@@beforeYouTravel6">Seat assignment</li>
          <li i18n="@@beforeYouTravel7">Any required special assistance</li>
        </ul>
        <p class="font-16 gray-primaray-text mt-5" i18n="@@cancellation1">
          Cancellation
        </p>
        <p
          class="gray-text-dark mb-0"
          *ngIf="
            bookingDetails.airItinerary.fareInfo.refundable !== null &&
            bookingDetails.airItinerary.fareInfo.refundable !== undefined &&
            bookingDetails.airItinerary.fareInfo.refundable === false
          "
          i18n="@@nonRefundTicket"
        >
          Ticket is non refundable
        </p>
        <p
          class="gray-text-dark mb-0"
          *ngIf="
            bookingDetails.airItinerary.fareInfo.refundable !== null &&
            bookingDetails.airItinerary.fareInfo.refundable !== undefined &&
            bookingDetails.airItinerary.fareInfo.refundable === true
          "
          i18n="@@refundTicket"
        >
          Ticket is refundable
        </p>
        <p class="gray-text-dark mb-0" i18n="@@flightCancellation">
          We do not charge any change or cancellation fee. When the airline
          charge for such fee based on their rules and policies, the cost will
          be passed on you.
        </p>
        <p class="font-16 gray-primaray-text mt-5" i18n="@@needOurHelp">
          Need our help?
        </p>
        <p class="gray-text-dark" i18n="@@contactSupport">
          You can contact us by email on support@safarhub.com
        </p>
      </div>
    </div>
    <div
      class="col-md-4 custom-col4"
      *ngIf="bookingDetails && Service.desktopView"
    >
      <div class="white-box p-0 mb-4 price_summary">
        <div class="p-3">
          <h5
            class="text-big mb-3"
            [ngClass]="{ bolder: !Service.isCompany() }"
            i18n="@@priceSummary"
          >
            Price Summary
          </h5>
          <ng-container
            *ngFor="
              let traveler of bookingDetails.airItinerary.fareInfo
                .fareBreakdown;
              let travelerIndex = index
            "
          >
            <ng-container
              *ngFor="
                let travelerRepeat of [].constructor(
                  traveler.passengerTypeQuantity.quantity
                );
                let repeatIndex = index
              "
            >
              <div class="discount d-flex font-16">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  *ngIf="travelerIndex === 0"
                  ><ng-container i18n="@@traveler">Traveler</ng-container
                  >{{ repeatIndex + 1 }}:
                  <span class="text-capitalize">{{
                    traveler.passengerTypeQuantity.type.toLowerCase()
                  }}</span></span
                >
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  *ngIf="travelerIndex === 1"
                  ><ng-container i18n="@@traveler">Traveler</ng-container
                  >{{
                    bookingDetails.airItinerary.fareInfo.fareBreakdown[0]
                      .passengerTypeQuantity.quantity +
                      repeatIndex +
                      1
                  }}:
                  <span class="text-capitalize">{{
                    traveler.passengerTypeQuantity.type.toLowerCase()
                  }}</span></span
                >
                <span
                  >${{
                    traveler.sellingPassengerFare.totalFare.amount
                      | number : "1.2-2"
                  }}</span
                >
              </div>
              <div class="discount d-flex font-14 gray-text my-2">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@flight"
                  >Flight</span
                >
                <span
                  >${{
                    traveler.sellingPassengerFare.baseFare.amount
                      | number : "1.2-2"
                  }}</span
                >
              </div>
              <div class="discount d-flex font-14 gray-text mb-4">
                <span
                  [ngClass]="{
                    'mr-auto': Service.isSiteLtr(),
                    'ml-auto': Service.isSiteRtl()
                  }"
                  i18n="@@taxAndFees"
                  >Tax and fees</span
                >
                <span>
                  <span
                    >${{
                      this.Service.calTaxes(traveler.sellingPassengerFare.taxes)
                        | number : "1.2-2"
                    }}</span
                  >
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="white-box-footer rounded-bottom p-3">
          <div class="d-flex font-20">
            <span
              class="primaray-text"
              [ngClass]="{ bolder: !Service.isCompany() }"
              i18n="@@Total"
            >
              Total
            </span>
            <span
              class="primaray-text bolder"
              [ngClass]="{
                'ml-auto': Service.isSiteLtr(),
                'mr-auto': Service.isSiteRtl()
              }"
              ><span class="currency"
                >${{
                  bookingDetails.airItinerary.fareInfo.sellingTotalFares.total
                    .amount | number : "1.0-2"
                }}</span
              ><span class="amount"></span
            ></span>
          </div>
        </div>
      </div>
      <div
        class="white-box p-0"
        *ngIf="bookingDetails.rewardAmount && bookingType !== 'cancelled'"
      >
        <div
          class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold"
        >
          <ng-container i18n="@@rewards">Rewards</ng-container>:
          <ng-container *ngIf="Service.isSiteLtr()">$</ng-container
          >{{ bookingDetails.rewardAmount | number : "1.2-2"
          }}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
        </div>
        <div
          class="px-3 pt-3 pb-4 gray-primaray-text font-16"
          i18n="@@manageFlightUponCompleting"
        >
          You will earn ${{
            bookingDetails.rewardAmount | number : "1.2-2"
          }}
          upon completing this trip
        </div>
      </div>
      <app-cost-reporting-details
        [costCentersIds]="
          bookingDetails?.costCenter ? [bookingDetails?.costCenter] : ''
        "
        [labelsIds]="bookingDetails?.labels ? bookingDetails?.labels : []"
      >
      </app-cost-reporting-details>
    </div>
  </div>
</div>
<app-notifications
  *ngIf="Service.ShowPushNotificationsPopUp"
></app-notifications>
