import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { environment } from "../../../../environments/environment";
import { AuthServiceMain } from "../../../auth/auth.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { GoogleAnalytics4Service } from "../../../google-analytics4.service";

@Component({
  selector: "app-workspace-dashboard-control",
  templateUrl: "./workspace-dashboard-control.component.html",
  styleUrls: ["./workspace-dashboard-control.component.scss"],
})
export class WorkspaceDashboardControlComponent implements OnInit {
  
  constructor(
    public googleAnalytics4Service: GoogleAnalytics4Service,
    public formatter: NgbDateParserFormatter,
    private titleService: Title,
    public router: Router,
    public dataService: DataService,
    public auth: AuthServiceMain
  ) {
    this.titleService.setTitle("Book a trip | SafarHub");
    this.dataService.componentMethodCalled$.subscribe(() => {
    });
  }
  env = environment;
  flight = $localize`:@@flights:Flights`;
  hotels = $localize`:@@hotels:Hotels`;
  upcomingBookingObj = {
    bookingStatus: "CONFIRMED",
    includeUpcoming: true,
    includePast: false,
  };
  image = new FormData();
  data;
  submitting = false;
  usersList;
  searchUrl;
  dataUpcoming;
  upcomingBooking;
  urlArray;
  companyID;

  ngOnInit(): void {
    setTimeout(() => {
      let flightsTab = document.getElementById("flights") as HTMLElement;
      if (flightsTab && this.dataService.flightTabActive) {
        flightsTab.click();
      }
    }, 200);

    setTimeout(() => {
      this.dataService.flightTabActive = false;
    }, 700);

    this.dataService.fetchFlightSearchHistory("false").subscribe((success) => {
      this.data = success;
      if (this.data.errorCode === 0 && this.data.data) {
        this.dataService.flightHistoryArray = this.data.data.searches.slice(
          0,
          5
        );
      }
    });

    this.urlArray = this.router.url.split("/");
    this.companyID = this.urlArray[1];
    if (this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()) {
      this.dataService.getCompanyUsersList().subscribe(
        (data) => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.usersList = this.data.data;
          } else {
            this.dataService.messages(this.data.message, "error");
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
        }
      );
      if (this.auth.isAdmin() || this.auth.isOwner()) {
        this.dataService.getCompanyPolicies();
      }
    }

    if (this.auth.isManager() || this.auth.isAdmin() || this.auth.isOwner()) {
      this.dataService.globalListApproversApprovals("PENDING_APPROVAL");
    }

    this.dataService
      .flightHotelListBookings(
        this.upcomingBookingObj.bookingStatus,
        this.upcomingBookingObj.includeUpcoming,
        this.upcomingBookingObj.includePast
      )
      .subscribe(
        (data) => {
          this.dataUpcoming = data;
          if (this.dataUpcoming.errorCode === 0) {
            this.upcomingBooking = this.dataUpcoming.data;

            this.dataService.hotellistLoader = false;
          } else {
            this.dataService.messages(this.dataUpcoming.message, "error");
            this.dataService.hotellistLoader = false;
          }
        },
        (error) => {
          this.dataService.messages(environment.messages.error, "error");
          this.dataService.hotellistLoader = false;
        }
      );
  }
  clearHistory() {
    if (this.auth.isLogedIn) {
      this.dataService.fetchSearchHistory("true").subscribe();
    }
    if (localStorage.getItem("history_storage")) {
      localStorage.removeItem("history_storage");
    }
    this.dataService.historyArray = [];
  }
  openHistoryUrl(item) {
    if (item.viewMode === "LIST" || item.viewMode === "GEO") {
      this.searchUrl = "/" + this.companyID + "/availability";
    } else {
      this.searchUrl = "/" + this.companyID + "/availability/hotel";
    }
    this.router.navigate([this.searchUrl], {
      queryParams: {
        searchQuery: item.searchQuery,
        searchType: "GOOGLE_PLACE",
        checkIn: item.checkIn,
        checkOut: item.checkOut,
        travelerId: item.travelerId || this.auth.userInfo.id,
      },
      queryParamsHandling: "merge",
    });
  }

  clearFlightHistory() {
    if (this.auth.isLogedIn) {
      this.dataService.fetchFlightSearchHistory("true").subscribe();
    }
    if (localStorage.getItem("flight_history_storage")) {
      localStorage.removeItem("flight_history_storage");
    }
    this.dataService.flightHistoryArray = [];
  }
  openFlightHistoryUrl(item) {
    localStorage.setItem(
      "lastSearchDepart",
      JSON.stringify({
        caption: item.lookupSearchCaptions.departureCaptions[0],
        code: item.airQueries[0].departureSearchCode,
        //          name: "Ataturk Airport",
        searchQuery: item.airQueries[0].departureSearchCode + ";AIRPORT",
        type: "AIRPORT",
        viewMode: "LIST",
        travelerId: item.travelerId || this.auth.userInfo.id,
      })
    );
    localStorage.setItem(
      "lastSearchReturn",
      JSON.stringify({
        caption: item.lookupSearchCaptions.arrivalCaptions[0],
        code: item.airQueries[0].arrivalSearchCode,
        //  name: "Ataturk Airport",
        searchQuery: item.airQueries[0].arrivalSearchCode + ";AIRPORT",
        type: "AIRPORT",
        viewMode: "LIST",
      })
    );

    let localStorageFlightItem = {
      roundTrip: false,
      cabinClass: item.cabinClass,
      airQueries: [
        {
          departureDate: item.airQueries[0].departureDate,
          departureSearchCode: item.airQueries[0].departureSearchCode,
          departureSearchType: "AIRPORT",
          arrivalSearchCode: item.airQueries[0].arrivalSearchCode,
          arrivalSearchType: "AIRPORT",
          lookupSearchCaptions: {
            departureCaptions: [item.lookupSearchCaptions.departureCaptions[0]],
            arrivalCaptions: [item.lookupSearchCaptions.arrivalCaptions[0]],
          },
        },
      ],
      adultCount: item.adultCount,
      childCount: 0,
      infantCount: 0,
      travelerId: item.travelerId || this.auth.userInfo.id,
    };

    let searchType =
      "/" + this.auth.companyInfo.id + "/flights/availability";
    debugger;
    this.dataService.captionReturn =
      item.lookupSearchCaptions.arrivalCaptions[0];
    this.dataService.captionDepart =
      item.lookupSearchCaptions.departureCaptions[0];
    if (item.roundTrip) {
      localStorageFlightItem.roundTrip = true;
      localStorageFlightItem.airQueries[0]["returnDate"] =
        item.airQueries[0].returnDate;
      this.router.navigate([searchType], {
        queryParams: {
          trip: "ROUNDTRIP",
          cabinClass: item.cabinClass,
          departureDate: item.airQueries[0].departureDate,
          departureCode: item.airQueries[0].departureSearchCode,
          arrivalCode: item.airQueries[0].arrivalSearchCode,
          paxes: item.adultCount,
          returnDate: item.airQueries[0].returnDate,
          travelerId: item.travelerId || this.auth.userInfo.id,
        },
      });
    } else {
      this.router.navigate([searchType], {
        queryParams: {
          trip: "ONEWAY",
          cabinClass: item.cabinClass,
          departureDate: item.airQueries[0].departureDate,
          departureCode: item.airQueries[0].departureSearchCode,
          arrivalCode: item.airQueries[0].arrivalSearchCode,
          paxes: item.adultCount,
          returnDate: item.airQueries[0].returnDate,
          travelerId: item.travelerId || this.auth.userInfo.id,
        },
      });
    }

    if (!this.dataService.flightHistoryArray) {
      const historyArr = [];
      historyArr.unshift(localStorageFlightItem);
      localStorage.setItem(
        "flight_history_storage",
        JSON.stringify(historyArr)
      );
    } else {
      let historyArr = this.dataService.flightHistoryArray;
      /*  historyArr = historyArr.filter(cls =>{
              return   !this.searchQuery.includes(cls.searchQuery)
            } );*/
      debugger;
      if (historyArr.length === 5) {
        historyArr.splice(-1, 1);
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      } else {
        historyArr.unshift(localStorageFlightItem);
        localStorage.setItem(
          "flight_history_storage",
          JSON.stringify(historyArr)
        );
      }
    }
  }

  /* onFileSelect(event) {
     if (event.target.files.length > 0) {
       this.submitting = true;
       if(this.image.get('logo')) {
         this.image.delete('logo');
       }
       this.image.append('logo', event.target.files[0]) ;
       this.dataService.updateCompanyLogo(this.image).subscribe(
         data => {
           this.data = data;
           if (this.data.errorCode === 0) {
             // this.imageUrl =  this.data.data.logoUrl;
             this.submitting = false;
             this.auth.fetchCompanyData();
           } else {
             this.submitting = false;
             this.dataService.messages(this.data.message, 'error');
           }
         }, error => {
           this.submitting = false;
           this.dataService.messages(environment.messages.error, 'error');
         }
       );
     }
   }*/
  /* formatDailyDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD');
  }*/
  /*  formatItemDate(date) {
    return moment(date).format('ddd') + ' ' + moment(date).format('MMM')
      + ' ' + moment(date).format('DD') + ', ' + moment(date).format('YYYY');
  }*/
  viewDetails(obj, bookingType) {
    if (this.dataService.isCompany()) {
      this.router.navigate(
        [
          "/" +
            this.auth.companyInfo.id +
            "/" +
            bookingType.toLowerCase() +
            "/booking/details",
        ],
        { queryParams: obj }
      );
    } else {
    }
  }
}
