import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {DataService} from '../../../services/data.service';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../auth/auth.service';
export interface Policy {
  name: string;
  policyId: string;
}
export class User {
  constructor(public description: string, public id: string, public teamName?: string, public selected?: boolean) {
    if (selected === undefined) { selected = false;}
  }
}
export class Payment {
  constructor(public description: string, public id: string, public selected?: boolean) {
    if (selected === undefined) { selected = false;}
  }
}
@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss']
})
export class CreateTeamComponent implements OnInit {

  @ViewChild('userInput') userInput: ElementRef;
  @ViewChild('managerInput') managerInput: ElementRef;
  @ViewChild('policyInput') policyInput: ElementRef;
  @ViewChild('paymentInput') paymentInput: ElementRef;
  @ViewChild('nameInput') nameInput: ElementRef;
  @Input() teamId: string;

  applyBlur = true;
  errorMessage;
  errorMessagePay;
  errorMessageUser;
  userControl = new FormControl();
  managerControl = new FormControl();
  policyControl = new FormControl();
  paymentControl = new FormControl();
  createTeamForm: FormGroup;
  submitted = false;
  submitting = false;
  submittingPayment = false;
  separatorKeysCodes = [ENTER, COMMA];
  addOnBlur: false;
  data;
  dataUsers;
  options = [];
  showMembersAutoCompletePopUp = false;
  showPolicyAutoCompletePopUp = false;
  showManagerAutoCompletePopUp = false;
  showPaymentsAutoCompletePopUp = false;
  showTeamNameAutoCompletePopUp = false;
  focusInputMobile;
  focusInputDesktop;
  teamDetails;
  users = [];
  payments = [];
  selectedPayments: Payment[] = new Array<Payment>();
  selectedUsers: User[] = new Array<User>();
  mobileTempSelectedUsers: User[] = new Array<User>();
  mobileTempSelectedPayments: Payment[] = new Array<Payment>();
  selectedManager;
  noManagerWarning = false;
  selectedPolicy;
  filteredPayments: Observable<Payment[]>;
  filteredUsers: Observable<User[]>;
  filteredManager: Observable<User[]>;
  filteredPolicies: Observable<any[]>;
  lastFilter: string = '';
  lastFilterPolicy: string = '';
  editTeamPayments = false;
  editTeamMembers = false;
  editTeamManager = false;
  editTeamPolicy = false;
  editTeamName = false;
  policyLoader;
  managerLoader;
  nameLoader;
  NoPolicy: Policy = {name: $localize`:@@unassigned:Unassigned`, policyId: null};
  NoManager: User = {description: $localize`:@@unassigned:Unassigned`, id: null};
  NoManagerLocalized =  $localize`:@@unassigned:Unassigned`;
  urlArray;
  dataPay;
  getAllPaymentFlag = false;
  restrictTeam = false;

  constructor(private formBuilder: FormBuilder, public dataService: DataService, public router: Router, public auth: AuthServiceMain) {}

  ngOnInit() {
    this.options.push(this.NoPolicy);
    this.users.push(this.NoManager);
    this.urlArray = (this.router.url).split('/');
    if(this.router.url.includes('edit') && this.router.url.includes('teams')) { // as no directive in this case (page in mobile view not a modal)
      this.teamId = this.urlArray[3];
    }
    this.createTeamForm = this.formBuilder.group({
      teamName: ['', [Validators.required]],
    }, {
    });
    if (this.teamId) {
      this.fetchEditFormData();
    }
    if (!this.teamId) {
      this.usersAutoComplete();
    }
    this.policyAutoComplete();
    if (!this.teamId) {
      this.paymentsAutoComplete();
    }
  }
  usersAutoComplete(){
    this.dataService.getCompanyUsersList().subscribe(
      dataList => {
        this.dataUsers = dataList;
        if (this.dataUsers.errorCode === 0) {
          this.dataUsers = this.dataUsers.data;

          this.dataUsers.forEach((element) => {
            if (element.teamName) {
              this.users.push(new User(element.description, element.id, element.teamName));
            } else {
              this.users.push(new User(element.description, element.id));
            }
          });
          if (this.teamId) {
            this.users.forEach((element) => {
              this.selectedUsers.forEach((elementSelected) => {
                if (elementSelected.id === element.id){
                  element.selected = true;
                }
              });
            });
          }
          this.filteredUsers = this.userControl.valueChanges.pipe(
            startWith<string | User[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
          );
          this.filteredManager = this.managerControl.valueChanges.pipe(
            startWith<string | User[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
          );



        } else {
        //  this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      }, error => {
        // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  policyAutoComplete(){
    // this.checkClass();
    this.dataService.policyList().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          if (this.data.data) {
            this.options = [];
            this.options.push(this.NoPolicy);
            this.data.data.forEach((element) => {
              this.options.push({name: element.policy.name, policyId: element.policy.policyId});
              this.filteredPolicies = this.policyControl.valueChanges
                .pipe(
                  startWith(''),
                  map(value => typeof value === 'string' ? value : this.lastFilterPolicy),
                  map(name => name ? this.filterPolicy(name) : this.options.slice())
                );
            });
          } else{
            this.filteredPolicies = this.policyControl.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : this.lastFilterPolicy),
                map(name => name ? this.filterPolicy(name) : this.options.slice())
              );
          }
        } else {
         // this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      }, error => {
       // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  paymentsAutoComplete(){
    this.dataService.companyListPaymentMethods().subscribe(
      data => {
        this.dataPay = data;
        if (this.dataPay.errorCode === 0) {
          this.dataService.companyPaymentMethods = this.dataPay.data;
          this.getAllPaymentFlag = true;
          this.payments = [];
          this.dataService.companyPaymentMethods.forEach((element) => {
            if (element.card.alias) {
              this.payments.push(new Payment(element.card.alias, element.card.id));
            } else {
              this.payments.push(new Payment(element.card.brand + ' ' + element.card.lastFour, element.card.id));
            }
          });
          if (this.teamId || this.selectedPayments.length) {
            this.payments.forEach((element) => {
              this.selectedPayments.forEach((elementSelected) => {
                if (elementSelected.id === element.id){
                  element.selected = true;
                }
              });
            });
          }
          this.filteredPayments = this.paymentControl.valueChanges.pipe(
            startWith<string | Payment[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filterPayment(filter))
          );
        } else {
        }

      },
      error => {

      }
    );
  }
  fetchEditFormData(){
    this.dataService.getTeamDetails(this.teamId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.teamInfo = this.data.data;
          if(this.dataService.teamInfo.teamManager) {
            this.selectedManager = new User(this.dataService.teamInfo.teamManager, this.dataService.teamInfo.teamManagerId, this.dataService.teamInfo.name);
          } else {
            this.selectedManager = this.NoManager;
          }
          this.restrictTeam = this.dataService.teamInfo.personalPaymentMethodsDisabled;
          this.managerControl.setValue(this.selectedManager);
          if (this.dataService.teamInfo.policyName) {
            const policyIdValue = this.dataService.teamInfo.policyId.substr(this.dataService.teamInfo.policyId.indexOf(':') + 2, this.dataService.teamInfo.policyId.length - 1);
            this.selectedPolicy = {name: this.dataService.teamInfo.policyName, policyId: policyIdValue};
          } else {
            this.selectedPolicy = this.NoPolicy;
          }
          this.policyControl.setValue(this.selectedPolicy);

          this.createTeamForm.get('teamName').setValue(this.dataService.teamInfo.name);
          if(this.dataService.teamInfo && this.teamId) {
            this.getEditFormTeamMembers();
          }
        } else {
         // this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      }, error => {
       // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );

    this.dataService.getTeamPaymentMethods(this.teamId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.teamPaymentMethods = this.data.data;
          if(this.dataService.teamPaymentMethods) {
            this.dataService.teamPaymentMethods.forEach((element) => {
              if (element.alias) {
                this.selectedPayments.push(new Payment(element.alias, element.id, true));
              } else {
                this.selectedPayments.push(new Payment(element.brand + ' ' + element.lastFour, element.id, true));
              }
            });
          }
          if(this.teamId) {
            this.paymentsAutoComplete();
          }
          } else {
          // this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      }, error => {
       // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );

  }
  getEditFormTeamMembers() {
    this.dataService.getTeamMembers(this.teamId).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.dataService.teamMembers = this.data.data;
          if(this.dataService.teamMembers) {
            this.dataService.teamMembers.forEach((element) => {
              this.selectedUsers.push(new User(element.description, element.id, this.dataService.teamInfo.name, true));
            });
          }
          if(this.teamId) {
            this.usersAutoComplete();
          }
        } else {
          // this.dataService.messages(this.data.message, 'error');
          this.errorMessage = this.data.message;
        }
      }, error => {
        // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  get c() { return this.createTeamForm.controls; }

  filter(filter: string): User[] {
    this.lastFilter = filter;
    if (filter) {
      return this.users.filter(option => {
        return option.description.toLowerCase().includes(filter.toLowerCase()) && option.description !== 'Unassigned' && option.description !== $localize`:@@unassigned:Unassigned`;
      });
    } else {
      return this.users.slice();
    }
  }

  displayFn(user: User): string {
    return user && user.description ? user.description : '';
  }
  reset() {
    this.userInput.nativeElement.value = '';
    this.userControl.setValue(null);
    this.lastFilter = '';
    this.filteredUsers = this.userControl.valueChanges.pipe(
      startWith<string | User[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );
  }
  resetBlur() {

    this.userInput.nativeElement.value = '';
    this.userControl.setValue(null);
  }
  optionClicked(event: Event, user: User) {
    this.userInput.nativeElement.value = '';
    this.userControl.setValue(null);
    event.stopPropagation();
    this.toggleSelection(user);
  }
  resetManager() {
    setTimeout(() => {
      if(this.applyBlur) {
      if (typeof this.managerControl.value !== 'object') {
        if (this.managerControl.value === null || this.managerControl.value === '') {
          if (this.teamId) {
            this.managerLoader = true;
            this.dataService.removeTeamManager(this.teamId).subscribe(
              data => {
                this.data = data;
                if (this.data.errorCode === 0) {
                  this.managerLoader = false;
                  this.desktopManagerBlur();
                  this.dataService.getTeamInfo(this.teamId);
                } else {
                  this.managerLoader = false;
                  this.errorMessage = this.data.message;
                }
              }, error => {
                this.managerLoader = false;
                this.errorMessage = environment.messages.error;
              }
            );
          }
          this.managerInput.nativeElement.value = '';
          this.lastFilter = '';
          this.selectedManager = this.NoManager;
          this.managerControl.setValue(this.selectedManager);
        } else {
        this.managerInput.nativeElement.value = '';
        this.managerControl.setValue(null);
        this.lastFilter = '';
        this.selectedManager = null;
        if (this.teamId) {
          if (this.dataService.teamInfo.teamManager) {
            this.selectedManager = new User(this.dataService.teamInfo.teamManager, this.dataService.teamInfo.teamManagerId, this.dataService.teamInfo.name);
          } else {
            this.selectedManager = this.NoManager;
          }
          this.managerControl.setValue(this.selectedManager);
        }

        this.filteredManager = this.managerControl.valueChanges.pipe(
          startWith<string | User[]>(''),
          map(value => typeof value === 'string' ? value : this.lastFilter),
          map(filter => this.filter(filter))
        );
      }
      }
        this.editTeamManager = false;
        if(this.teamId && this.selectedManager === this.NoManager){
          this.noManagerWarning = true;
        } else{
          this.noManagerWarning = false;
        }
    }
    }, 400);
  }
  toggleSelection(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.selectedUsers.push(user);
    } else {
      this.users.forEach((element) => {
        if (user.id === element.id){
          element.selected = false;
        }
      });
      const i = this.selectedUsers.findIndex(value => value.description === user.description && value.id === user.id);
      this.selectedUsers.splice(i, 1);
    }

  }
  toggleSelectionPayment(payment: Payment) {
    payment.selected = !payment.selected;
    if (payment.selected) {
      this.selectedPayments.push(payment);
    } else {
      this.payments.forEach((element) => {
        if (payment.id === element.id){
          element.selected = false;
        }
      });
      const i = this.selectedPayments.findIndex(value => value.description === payment.description && value.id === payment.id);
      this.selectedPayments.splice(i, 1);
    }
  }
  filterPayment(filter: string): Payment[] {
    this.lastFilter = filter;
    if (filter) {
      return this.payments.filter(option => {
        return option.description.toLowerCase().indexOf(filter.toLowerCase()) === 0;
      });
    } else {
      return this.payments.slice();
    }
  }
  displayFnPayment(payment: Payment): string {
    return payment && payment.description ? payment.description : '';
  }

  resetPayment() {
    this.paymentInput.nativeElement.value = '';
    this.paymentControl.setValue(null);
    this.lastFilter = '';
   /* this.filteredPayments = this.paymentControl.valueChanges.pipe(
      startWith<string | Payment[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filterPayment(filter))
    );*/

    this.paymentsAutoComplete();
  }
  resetPaymentBlur() {

    this.paymentInput.nativeElement.value = '';
    this.paymentControl.setValue(null);
  }
  optionClickedPayment(event: Event, payment: Payment) {
    this.paymentInput.nativeElement.value = '';
    this.paymentControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionPayment(payment);
  }
  optionClickedManager(event: Event, user: User) {
    if(this.teamId && this.selectedManager !== this.NoManager && user === this.NoManager){
      this.noManagerWarning = true;
    } else{
      this.noManagerWarning = false;
    }

    this.selectedManager = user;
    if(this.dataService.mobileView){
      this.hidePopUp();
    }

    const managerInputHtml = document.getElementById('managerInputHtml') as HTMLInputElement;
    if (this.teamId) {
      this.managerLoader = true;
      if (this.selectedManager && this.selectedManager.id === null) {
        this.dataService.removeTeamManager(this.teamId).subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.managerLoader = false;
              this.dataService.getTeamInfo(this.teamId);
              this.editTeamManager = false;
              if (this.dataService.desktopView) {
                managerInputHtml.blur();
              }
            } else {
              // this.dataService.messages(this.data.message, 'error');
              this.managerLoader = false;
              this.errorMessage = this.data.message;
            }
          }, error => {
            // this.dataService.messages(environment.messages.error, 'error');
            this.managerLoader = false;
            this.errorMessage = environment.messages.error;
          }
        );
      } else if (this.selectedManager && this.selectedManager.id) {
        const teamDetails = '?teamId=' + this.teamId + '&teamManagerId=' + this.selectedManager.id;
        this.dataService.updateTeam(teamDetails).subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.managerLoader = false;
              this.dataService.getTeamInfo(this.teamId);
              this.editTeamManager = false;
              if (this.dataService.desktopView) {
                managerInputHtml.blur();
              }
            } else {
             // this.dataService.messages(this.data.message, 'error');
              this.managerLoader = false;
              this.errorMessage = this.data.message;
            }
          }, error => {
            // this.dataService.messages(environment.messages.error, 'error');
            this.managerLoader = false;
            this.errorMessage = environment.messages.error;
          }
        );
      }
    }
  }
  private filterPolicy(name: string): Policy[] {
    this.lastFilterPolicy = name;
    if (name) {
      return this.options.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) === 0 && option.name !== 'Unassigned'&& option.name !== $localize`:@@unassigned:Unassigned`);
    } else {
      return this.options.slice();
    }
  }
  displayFnPolicy(policy: Policy): string {
    return policy && policy.name ? policy.name : '';
  }
  resetPolicy() {
    setTimeout(() => {
      if(this.applyBlur) {
      if (typeof this.policyControl.value !== 'object') {
        if (this.policyControl.value === null || this.policyControl.value === '') {

if(this.teamId) {
  this.policyLoader = true;
  this.dataService.removeTeamPolicy(this.teamId).subscribe(
    data => {
      this.data = data;
      if (this.data.errorCode === 0) {
        this.policyLoader = false;
        this.dataService.getTeamInfo(this.teamId);
        this.desktopPolicyBlur();
      } else {
        this.policyLoader = false;
        this.errorMessage = this.data.message;
      }
    }, error => {
      this.policyLoader = false;
      this.errorMessage = environment.messages.error;
    }
  );
}




          this.policyInput.nativeElement.value = '';
          this.lastFilterPolicy = '';
          this.selectedPolicy = this.NoPolicy;
          this.policyControl.setValue(this.selectedPolicy);
        } else {

        this.policyInput.nativeElement.value = '';
        this.policyControl.setValue(null);
        this.lastFilterPolicy = '';
        this.selectedPolicy = null;
        if (this.teamId) {
          if (this.dataService.teamInfo.policyName) {
            const policyIdValue = this.dataService.teamInfo.policyId.substr(this.dataService.teamInfo.policyId.indexOf(':') + 2, this.dataService.teamInfo.policyId.length - 1);
            this.selectedPolicy = {name: this.dataService.teamInfo.policyName, policyId: policyIdValue};
          } else {
            this.selectedPolicy = this.NoPolicy;
          }
          this.policyControl.setValue(this.selectedPolicy);
        }

      }
        this.filteredPolicies = this.policyControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : this.lastFilterPolicy),
            map(name => name ? this.filterPolicy(name) : this.options.slice())
          );
      }
        this.editTeamPolicy = false;
    }
    }, 200);
  }

  optionClickedPolicy(event: Event, policy: Policy) {
    this.selectedPolicy = policy;
    if(this.dataService.mobileView){
      this.hidePopUp();
    }
    const policyInputHtml = document.getElementById('policyInputHtml') as HTMLInputElement;
    if (this.teamId) {
      this.policyLoader = true;
      if (this.selectedPolicy && this.selectedPolicy.policyId === null) {
        this.dataService.removeTeamPolicy(this.teamId).subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.policyLoader = false;
              this.dataService.getTeamInfo(this.teamId);
              if (this.dataService.desktopView) {
                policyInputHtml.blur();
              }
            } else {
            //  this.dataService.messages(this.data.message, 'error');
              this.policyLoader = false;
              this.errorMessage = this.data.message;
            }
          }, error => {
           // this.dataService.messages(environment.messages.error, 'error');
            this.policyLoader = false;
            this.errorMessage = environment.messages.error;
          }
        );
      } else if (this.selectedPolicy && this.selectedPolicy.policyId) {
        const teamDetails = '?teamId=' + this.teamId + '&policyId=' + this.selectedPolicy.policyId;
        this.dataService.updateTeam(teamDetails).subscribe(
          data => {
            this.data = data;
            if (this.data.errorCode === 0) {
              this.policyLoader = false;
              this.dataService.getTeamInfo(this.teamId);
              if (this.dataService.desktopView) {
                policyInputHtml.blur();
              }
            } else {
              this.policyLoader = false;
              this.errorMessage = this.data.message;
            }
          }, error => {
           // this.dataService.messages(environment.messages.error, 'error');
            this.policyLoader = false;
            this.errorMessage = environment.messages.error;
          }
        );
      }
    }
  }
  optionClickedMobile(event: Event, user: User) {
    this.userInput.nativeElement.value = '';
    this.userControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionTemp(user);
  }
  toggleSelectionTemp(user: User) {
    user.selected = !user.selected;
    if (user.selected) {
      this.mobileTempSelectedUsers.push(user);
    }  else {
      this.users.forEach((element) => {
        if (user.id === element.id){
          element.selected = false;
        }
      });
      const i = this.mobileTempSelectedUsers.findIndex(value => value.description === user.description && value.id === user.id);
      this.mobileTempSelectedUsers.splice(i, 1);
    }
    this.mobileFocus();
  }
  saveSelectedUsers() {
    this.selectedUsers = [];
    this.mobileTempSelectedUsers.forEach((element) => {
      this.selectedUsers.push(element);
    });
  }

  optionClickedMobilePayment(event: Event, payment: Payment) {
    this.paymentInput.nativeElement.value = '';
    this.paymentControl.setValue(null);
    event.stopPropagation();
    this.toggleSelectionTempPayment(payment);
  }
  toggleSelectionTempPayment(payment: Payment) {
    payment.selected = !payment.selected;
    if (payment.selected) {
      this.mobileTempSelectedPayments.push(payment);
    }   else {
      this.payments.forEach((element) => {
        if (payment.id === element.id){
          element.selected = false;
        }
      });
      const i = this.mobileTempSelectedPayments.findIndex(value => value.description === payment.description && value.id === payment.id);
      this.mobileTempSelectedPayments.splice(i, 1);
    }
    this.mobileFocus();
  }
  saveSelectedPayments() {
    this.selectedPayments = [];
    this.mobileTempSelectedPayments.forEach((element) => {
      this.selectedPayments.push(element);
    });
  }
  showMembersPopUp() {
    if (this.dataService.mobileView) {
      this.mobileTempSelectedUsers = [];
      this.showMembersAutoCompletePopUp = true;
      this.dataService.disableBodyScroll();
      this.selectedUsers.forEach((element, index) => {
        this.mobileTempSelectedUsers.push(element);
        this.mobileTempSelectedUsers[index].selected = true;
      });
      setTimeout(() => {
        const input = document.getElementById('userInput') as HTMLInputElement;
        input.focus();
        input.select();
      }, 200);
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('members-popup');
      return;
    }
  }
  showPaymentsPopUp() {
    if (this.dataService.mobileView) {
      this.mobileTempSelectedPayments = [];
      this.showPaymentsAutoCompletePopUp = true;
      this.dataService.disableBodyScroll();
      this.selectedPayments.forEach((element, index) => {
        this.mobileTempSelectedPayments.push(element);
        this.mobileTempSelectedPayments[index].selected = true;
      });
      setTimeout(() => {
        const input = document.getElementById('paymentInput') as HTMLInputElement;
        input.focus();
        input.select();
      }, 200);
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('members-popup');
      return;
    }
  }
  showPolicyPopUp() {
    this.showPolicyAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedPolicy) {
      this.policyControl.setValue(this.selectedPolicy);
    }
    setTimeout(() => {
      const input = document.getElementById('policyInput') as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  showTeamNamePopUp() {
    this.showTeamNameAutoCompletePopUp = true;
    this.createTeamForm.get('teamName').setValue(this.dataService.teamInfo.name);
    this.dataService.disableBodyScroll();
    setTimeout(() => {
      const input = document.getElementById('nameInput') as HTMLInputElement;
      input.focus();
      input.select();
    }, 200);
    return;
  }
  showManagerPopUp() {
    this.showManagerAutoCompletePopUp = true;
    this.dataService.disableBodyScroll();
    if (this.selectedManager) {
      this.managerControl.setValue(this.selectedManager);
    }
    setTimeout(() => {
      const input = document.getElementById('managerInput') as HTMLInputElement;
      input.select();
      input.focus();
    }, 200);
    return;
  }
  hidePopUp() {
    this.showPolicyAutoCompletePopUp = false;
    this.showMembersAutoCompletePopUp = false;
    this.showManagerAutoCompletePopUp = false;
    this.showPaymentsAutoCompletePopUp = false;
    this.showTeamNameAutoCompletePopUp = false;
    this.dataService.enableBodyScroll();
    this.mobileTempSelectedUsers.forEach((element) => {
      element.selected = false;
    });
    this.mobileTempSelectedPayments.forEach((element) => {
      element.selected = false;
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('members-popup');
  }
  cancelCreate() {
    if (this.dataService.desktopView && !this.dataService.createEditTeamPage) {
      this.dataService.modalOf();
    } else {
      this.router.navigate([this.router.url.replace('/create' , '')]);
    }
  }
  onSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.createTeamForm.invalid) {
      this.submitting = false;
      this.dataService.scrollTop();
      return;
    }
    this.teamDetails = '?name=' + encodeURIComponent(this.createTeamForm.controls.teamName.value);
    if (this.selectedPolicy && this.selectedPolicy.policyId) {
      this.teamDetails += '&policyId=' + this.selectedPolicy.policyId;
    }
    if (this.selectedManager && this.selectedManager.id) {
      this.teamDetails += '&teamManagerId=' + this.selectedManager.id;
    }
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((element) => {
        this.teamDetails += '&userIds=' + element.id;
      });
    }
    if (this.selectedPayments.length > 0) {
      this.selectedPayments.forEach((element) => {
        this.teamDetails += '&paymentMethodIds=' + encodeURIComponent(element.id);
      });
    }
    this.dataService.createTeam(this.teamDetails).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.submitting = false;
          if (this.restrictTeam) {
            this.disablePersonalPayments(this.data.data.teamId);
          }
          this.dataService.getCompanyTeamsList();
          this.dataService.modalOf();
          this.dataService.messages($localize`:@@teamCreated:New team is created successfully`, 'success');
          if (this.dataService.desktopView && !this.dataService.createEditTeamPage) {
            this.dataService.modalOf();
          } else {
          this.router.navigate([this.router.url.replace('/create' , '')]);
          }
        } else {
          this.submitting = false;
          this.errorMessage = this.data.message;
        }
      }, error => {
        this.submitting = false;
       // this.dataService.messages(environment.messages.error, 'error');
        this.errorMessage = environment.messages.error;
      }
    );
  }
  restoreTeamName() {
    if (this.teamId) {
      this.createTeamForm.get('teamName').setValue(this.dataService.teamInfo.name);
    }
  }
  enterAction() {
    if (this.teamId) {
this.editTeamNameDone();
    } else {
      this.onSubmit();
    }
  }
  editTeamNameDone() {
if(this.teamId){
  this.submitted = true;
}
    if (this.teamId && this.createTeamForm.controls.teamName.value !== '') {
      this.nameLoader = true;

if(this.dataService.teamInfo.name !== this.createTeamForm.controls.teamName.value){
      const teamDetails = '?teamId=' + this.teamId + '&name=' + encodeURIComponent(this.createTeamForm.controls.teamName.value);
      this.dataService.updateTeam(teamDetails).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.editTeamName = false;
            this.submitted = false;
            this.nameLoader = false;
            this.dataService.getTeamInfo(this.teamId);
            if(this.dataService.mobileView){
              this.hidePopUp();
            }
          } else {
          //  this.dataService.messages(this.data.message, 'error');
            this.nameLoader = false;
            this.errorMessage = this.data.message;
          }
        }, error => {
        //  this.dataService.messages(environment.messages.error, 'error');
          this.errorMessage = environment.messages.error;
          this.nameLoader = false;
        }
      );
    } else{
  this.editTeamName = false;
  this.nameLoader = false;
}
    } else {
      this.editTeamName = true;
    }
  }
  cancelEditTeamMembers() {
    this.selectedUsers = [];
    if(this.dataService.teamMembers) {
      this.dataService.teamMembers.forEach((element) => {
        this.selectedUsers.push(new User(element.description, element.id, this.dataService.teamInfo.name, true));
      });
    }
    this.users.forEach((element) => {
      element.selected = false;
      this.selectedUsers.forEach((elementSelected) => {
        if (elementSelected.id === element.id){
          element.selected = true;
        }
      });
    });
    this.editTeamMembers = false;
  }
  saveEditTeamMembers(){
    this.submitting = true;
    let mainUsersArray = [];
    let selectedUserArray = [];
    let addUeser = [];
    let removeUsers = [];
    if (this.dataService.teamMembers)  {
      this.dataService.teamMembers.forEach((element) => {
        mainUsersArray.push(element.id);
      });
    }
    this.selectedUsers.forEach((element) => {
      selectedUserArray.push(element.id);
    });
    selectedUserArray.forEach((selected) => {
      if (!mainUsersArray.includes(selected)) {
        addUeser.push(selected);
      }
    });
    mainUsersArray.forEach((main) => {
      if (!selectedUserArray.includes(main)) {
        removeUsers.push(main);
      }
    });
    if (addUeser.length) {
      let add = '';
      addUeser.forEach((id, index) => {
        if (index === 0) {
          add = id;
        } else{
          add += '&userIds=' + id;
        }
      });
      this.dataService.addUsersToTeam(this.teamId, add).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.editTeamMembers = false;
            this.dataService.getCompanyTeamMembers(this.teamId);
            this.dataService.getTeamInfo(this.teamId);
            this.submitting = false;
          } else {
          //  this.dataService.messages(this.data.message, 'error');
            this.errorMessageUser = this.data.message;
            this.submitting = false;
          }
        }, error => {
         // this.dataService.messages(environment.messages.error, 'error');
          this.errorMessageUser = environment.messages.error;
          this.submitting = false;
        }
      );
    }
    if (removeUsers.length) {
      let remove = '';
      removeUsers.forEach((id, index) => {
        if (index === 0) {
          remove += id;
        } else {
          remove += '&userIds=' + id;
        }
      });
      this.dataService.removeUsersFromTeam(this.teamId, remove).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.editTeamMembers = false;
            this.dataService.getCompanyTeamMembers(this.teamId);
            this.dataService.getTeamInfo(this.teamId);
            this.submitting = false;
          } else {
           // this.dataService.messages(this.data.message, 'error');
            this.errorMessageUser = this.data.message;
            this.submitting = false;
          }
        }, error => {
          // this.dataService.messages(environment.messages.error, 'error');
          this.errorMessageUser = environment.messages.error;
          this.submitting = false;
        }
      );
    }
    if (addUeser.length === 0 && removeUsers.length === 0) {
      this.editTeamMembers = false;
      this.submitting = false;
    }
  }


  cancelEditTeamPayments() {
    this.selectedPayments = [];
    if (!this.dataService.teamPaymentMethods) {
      this.dataService.getCompanyTeamPaymentMethods(this.teamId);
    }
    if (this.dataService.teamPaymentMethods) {
      this.dataService.teamPaymentMethods.forEach((element) => {
        if (element.alias) {
          this.selectedPayments.push(new Payment(element.alias, element.id, true));
        } else {
          this.selectedPayments.push(new Payment(element.brand + ' ' + element.lastFour, element.id, true));
        }
      });
    }
    this.payments.forEach((element) => {
      element.selected = false;
      this.selectedPayments.forEach((elementSelected) => {
        if (elementSelected.id === element.id){
          element.selected = true;
        }
      });
    });
    this.editTeamPayments = false;
  }
  saveEditTeamPayments(){
    this.submittingPayment = true;
    let mainPaymentsArray = [];
    let selectedPaymentsArray = [];
    let addPayments = [];
    let removePayments = [];
    if (!this.dataService.teamPaymentMethods) {
      this.dataService.getCompanyTeamPaymentMethods(this.teamId);
    }
    if (this.dataService.teamPaymentMethods)  {
      this.dataService.teamPaymentMethods.forEach((element) => {
        mainPaymentsArray.push(element.id);
      });
    }
    this.selectedPayments.forEach((element) => {
      selectedPaymentsArray.push(element.id);
    });
    selectedPaymentsArray.forEach((selected) => {
      if (!mainPaymentsArray.includes(selected)) {
        addPayments.push(selected);
      }
    });
    mainPaymentsArray.forEach((main) => {
      if (!selectedPaymentsArray.includes(main)) {
        removePayments.push(main);
      }
    });
    if (addPayments.length) {
      let add = '';
      addPayments.forEach((id, index) => {
        if (index === 0) {
          add = encodeURIComponent(id);
        } else{
          add += '&paymentMethodIds=' + encodeURIComponent(id);
        }
      });
      this.dataService.addPaymentsToTeam(this.teamId, add).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getCompanyTeamPaymentMethods(this.teamId);
            this.editTeamPayments = false;
            this.submittingPayment = false;
          } else {
           // this.dataService.messages(this.data.message, 'error');
            this.errorMessagePay = this.data.message;
            this.submittingPayment = false;
          }
        }, error => {
         // this.dataService.messages(environment.messages.error, 'error');
          this.errorMessagePay = environment.messages.error;
          this.submittingPayment = false;
        }
      );
    }
    if (removePayments.length) {
      let remove = '';
      removePayments.forEach((id, index) => {
        if (index === 0) {
          remove += encodeURIComponent(id);
        } else {
          remove += '&paymentMethodIds=' + encodeURIComponent(id);
        }
      });
      this.dataService.removePaymentsFromTeam(this.teamId, remove).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.dataService.getCompanyTeamPaymentMethods(this.teamId);
            this.editTeamPayments = false;
            this.submittingPayment = false;
          } else {
         //   this.dataService.messages(this.data.message, 'error');
            this.errorMessagePay = this.data.message;
            this.submittingPayment = false;
          }
        }, error => {
         // this.dataService.messages(environment.messages.error, 'error');
          this.errorMessagePay = environment.messages.error;
          this.submittingPayment = false;
        }
      );
    }
    if (addPayments.length === 0 && removePayments.length === 0) {
      this.editTeamPayments = false;
      this.submittingPayment = false;
    }
  }

  mobileFocus() {
    if(this.showPaymentsAutoCompletePopUp || this.showMembersAutoCompletePopUp || this.showManagerAutoCompletePopUp || this.showPolicyAutoCompletePopUp) {
    setTimeout(() => {
      if (this.showPaymentsAutoCompletePopUp) {
        this.focusInputMobile = document.getElementById('paymentInput') as HTMLInputElement;
      }
      if (this.showMembersAutoCompletePopUp) {
        this.focusInputMobile = document.getElementById('userInput') as HTMLInputElement;
      }
      if (this.showManagerAutoCompletePopUp) {
        this.focusInputMobile = document.getElementById('managerInput') as HTMLInputElement;
      }
      if (this.showPolicyAutoCompletePopUp) {
        this.focusInputMobile = document.getElementById('policyInput') as HTMLInputElement;
      }
      this.focusInputMobile.focus();
      this.focusInputMobile.select();
    }, 200);
  }
  }
  desktopMemberFocus() {
    if(this.dataService.desktopView) {
      setTimeout(() => {
        this.focusInputDesktop = document.getElementById('desktopUserInput') as HTMLInputElement;
        this.focusInputDesktop.focus();
        this.focusInputDesktop.select();
      }, 200);
    }
  }
  desktopPaymentFocus() {
    if(this.dataService.desktopView) {
      setTimeout(() => {
        this.focusInputDesktop = document.getElementById('desktopPaymentInput') as HTMLInputElement;
        this.focusInputDesktop.focus();
        this.focusInputDesktop.select();
      }, 200);
    }
  }
  desktopPolicyFocus() {
    if(this.dataService.desktopView) {
      setTimeout(() => {
        this.policyControl.setValue('');
        this.editTeamPolicy = true;
        this.applyBlur = true;
      }, 300);
    }
  }
  desktopManagerFocus() {
    if(this.dataService.desktopView) {
      setTimeout(() => {
        this.managerControl.setValue('');
        this.editTeamManager = true;
        this.applyBlur = true;
      }, 300);
    }
  }
  onEnterPolicy() {

    if (typeof this.policyControl.value === 'object') {
      this.optionClickedPolicy(null, this.policyControl.value);
    } else if ( this.policyControl.value === null ||  this.policyControl.value === '') {
      this.resetPolicy();
    }
  }
  onEnterManager() {
    if (typeof this.policyControl.value === 'object') {
      this.optionClickedManager(null, this.managerControl.value);
    } else if ( this.managerControl.value === null ||  this.managerControl.value === '') {
      this.resetPolicy();
    }
  }
  restoreSelectedPolicy() {
    this.policyControl.setValue(this.selectedPolicy);
    this.editTeamPolicy = false;
    this.desktopPolicyBlur();
  }
  restoreSelectedManager() {
    this.managerControl.setValue(this.selectedManager);
    this.editTeamManager = false;
    this.desktopManagerBlur();
  }
  desktopManagerBlur() {
    if (this.dataService.desktopView) {
      const input = document.getElementById('managerInputHtml') as HTMLInputElement;
      if(input) {
        input.blur();
      }
    }
  }
  desktopPolicyBlur() {
    if (this.dataService.desktopView) {
      const input = document.getElementById('policyInputHtml') as HTMLInputElement;
      if(input) {
        input.blur();
      }
    }
  }
  cancelEditTempPayments(){
    this.mobileTempSelectedPayments = [];
    this.payments.forEach((element) => {
      element.selected = false;
      this.selectedPayments.forEach((elementSelected) => {
        if (elementSelected.id === element.id){
          element.selected = true;
        }
      });
    });
    this.selectedPayments.forEach((element, index) => {
      this.mobileTempSelectedPayments.push(element);
      this.mobileTempSelectedPayments[index].selected = true;
    });
  }

  cancelEditTempUsers(){
    this.mobileTempSelectedUsers = [];
    this.users.forEach((element) => {
      element.selected = false;
      this.selectedUsers.forEach((elementSelected) => {
        if (elementSelected.id === element.id){
          element.selected = true;
        }
      });
    });
    this.selectedUsers.forEach((element, index) => {
      this.mobileTempSelectedUsers.push(element);
      this.mobileTempSelectedUsers[index].selected = true;
    });
  }
  disablePersonalPayments(teamId) {
    this.dataService.disableTeamPersonalPayments(teamId, this.restrictTeam).subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
        //  this.dataService.getCompanyUserInfo(this.userId);
        } else {
          this.errorMessage = this.data.message;
        }
      }, error => {
        this.errorMessage = environment.messages.error;
      }
    );
  }
}
