import {Component, ElementRef, OnInit} from '@angular/core';
import {DataService} from '../../../../services/data.service';
import { SidenavService} from '../../../workspace-dashboard/sidenav.service';
import {onMainContentChange} from '../../../workspace-dashboard/animations/animations';
import {Router} from '@angular/router';
import {AuthServiceMain} from '../../../../auth/auth.service';
import {VariablesService} from '../../../../services/vars.service';
import {environment} from '../../../../../environments/environment';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss'],
  animations: [ onMainContentChange ],
  host: {
    '(body:click)': 'onClickBody($event)',
  },
})
export class BillingHistoryComponent implements OnInit {
    historyList;
    data;
    loaded = false;
  constructor(private titleService: Title, private eref: ElementRef, public dataService: DataService, private sidenavService: SidenavService,
              public router: Router, public auth: AuthServiceMain, public vars: VariablesService) {
    this.titleService.setTitle('Account billing history | SafarHub');
    this.sidenavService.sideNavState$.subscribe( res => {
      this.dataService.onSideNavChange = res;
    });
  }
  showActions = false;
  selectedIndex;
  ngOnInit(): void {
    this.dataService.invoices().subscribe(
      data => {
        this.data = data;
        if (this.data.errorCode === 0) {
          this.historyList = this.data.data;
          this.loaded = true;
        } else {
          this.dataService.messages(this.data.message, 'error');
          this.loaded = true;
        }
      }, error => {
        this.dataService.messages(environment.messages.error, 'error');
        this.loaded = true;
      }
    );
  }
  onClickBody(event) {
    if (this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex) && !this.eref.nativeElement.querySelector('.drop-down' + this.selectedIndex).contains(event.target) ){
      this.showActions = false;
    }
  }
 /* formatDate(date) {
    return moment(date).format('MMM') + ' ' + moment(date).format('DD') + ', ' +  moment(date).format('YYYY');
  }*/
}
