<div class="container mt-0 mt-lg-5 mb-5" [ngClass]="{'d-none': Service.flightBookingPage, 'px-0': Service.mobileView}">
  <div class="row"
    [ngClass]="{'no-gutters': Service.mobileView && Service.isCompany(), 'mx-0': Service.mobileView && !Service.isCompany()}">

    <div class="col-md-8 custom-col8">
      <app-workspace-flight-booking-form *ngIf="Service.isCompany()"></app-workspace-flight-booking-form>
    </div>

    <div class="col-md-4 custom-col4 hotel-details-side" *ngIf="Service.flightData && Service.desktopView">

      <div class="table-header rounded p-3 mb-4">
        <p class="mb-0"><span class="font-20 primaray-text font-weight-bold"
            [innerHTML]="Service.formatDateEEMMMdd(summaryDetailsDepart.departureDate)"></span></p>
        <p><span class="gray-text-dark font-16"> {{summaryDetailsDepart.departureAirport.city}} <span
              class="text-lowercase" i18n="@@to">to</span> {{summaryDetailsDepart.arrivalAirport.city}}</span></p>

        <div class="white-box px-0 py-3">
          <div class="row h-100">
            <div class="col-1 pt-1" [ngClass]="{'pr-0': Service.isSiteLtr(), 'pl-0': Service.isSiteRtl()}">
            </div>
            <div class="col-1 px-0">
              <img width="26.5" height="27" loading="lazy" class="max-100 initial-height img-min-width"
                [src]="summaryDetailsDepart.airlineInfoSummary.marketingAirlineLogoUrl"
                onError="this.src='/assets/img/airline.png'" />
            </div>
            <div class="col-10 position-relative"
              [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}">
              <p class="font-18 primaray-text font-weight-bold mb-0">
                <span class="mb-0 primaray-text"
                  [innerHTML]="this.Service.formatDateTime12Hours(summaryDetailsDepart.departureDate)">10:15pm</span> -
                <span class="mb-0 primaray-text"
                  [innerHTML]="this.Service.formatDateTime12Hours(summaryDetailsDepart.arrivalDate)">10:15pm</span>
                <span class="font-14 font-weight-normal"
                  *ngIf="Service.checkNextDay(summaryDetailsDepart.departureDate, summaryDetailsDepart.arrivalDate) === 1"
                  i18n="@@nextDay"> (Next day)</span>
                <span class="font-14 font-weight-normal"
                  *ngIf="Service.checkNextDay(summaryDetailsDepart.departureDate, summaryDetailsDepart.arrivalDate) > 1">
                  +{{Service.checkNextDay(summaryDetailsDepart.departureDate, summaryDetailsDepart.arrivalDate)}}</span>
                <span class="font-14 font-weight-normal"
                  *ngIf="Service.checkPreviousDay(summaryDetailsDepart.departureDate, summaryDetailsDepart.arrivalDate)"
                  i18n="@@prevDay"> (Previous day)</span>
              </p>


              <div class="d-flex">
                <p class="gray-text-dark font-14 text-nowrap">
                  <span>{{summaryDetailsDepart.departureAirport.iata}}</span><span class="super-vertical-align"> ____
                  </span><span>{{summaryDetailsDepart.arrivalAirport.iata}}</span></p>
                <p class="mb-0 primaray-text pt-small"
                  [ngClass]="{'ml-3': Service.isSiteLtr(), 'mr-3': Service.isSiteRtl()}"><span
                    [innerHTML]="this.Service.calDuration(summaryDetailsDepart.journeyDurationInMins)">6h 45m</span>
                  <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                    *ngIf="summaryDetailsDepart.totalStops === 1">(<ng-container i18n="@@oneStop">1
                      stop</ng-container>)</span>
                  <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                    *ngIf="summaryDetailsDepart.totalStops === 2">(<ng-container i18n="@@twoStops">2
                      stops</ng-container>)</span>
                  <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                    *ngIf="summaryDetailsDepart.totalStops > 2">({{summaryDetailsDepart.totalStops}}&nbsp;<ng-container
                      i18n="@@stops">stops</ng-container>)</span>
                </p>
                <p class="gray-text-dark ml-2 pt-small text-lowercase" *ngIf="summaryDetailsDepart.totalStops === 0">
                  (<ng-container i18n="@@nonStop">nonstop</ng-container>)</p>
              </div>

              <!--<p class="mb-0 gray-text-dark">{{summaryDetailsDepart.airlineInfoSummary.airline}}. {{Service.flightData.details.originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.cabinClassName}}</p>
            <div class="gray-text" *ngIf="summaryDetailsDepart.airlineInfoSummary.operatedBy.length">
              <p class="mb-0" *ngFor="let operator of summaryDetailsDepart.airlineInfoSummary.operatedBy" >
              </p>
            </div>-->

              <p class="mb-0 gray-text-dark"
                *ngIf="summaryDetailsDepart.airlineInfoSummary.marketingAirlines.length === 1">
                {{summaryDetailsDepart.airlineInfoSummary.marketingAirlines[0]}}.
                {{Service.flightData.details.originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.cabinClassName}}
              </p>
              <p class="mb-0 gray-text-dark"
                *ngIf="summaryDetailsDepart.airlineInfoSummary.marketingAirlines.length > 1">
                <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                <span *ngFor="let airlineItem of summaryDetailsDepart.airlineInfoSummary.marketingAirlines">
                  {{airlineItem}}.
                </span>
                {{Service.flightData.details.originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.cabinClassName}}
              </p>
              <div class="gray-text" *ngIf="summaryDetailsDepart.airlineInfoSummary.operatedBy.length">
                <p class="mb-0"
                  *ngFor="let operator of summaryDetailsDepart.airlineInfoSummary.operatedBy ,  let indexOperator = index">
                  {{operator}}
                </p>
              </div>


            </div>
          </div>
        </div>
        <ng-container *ngIf="Service.flightData.trip === 'roundtrip'">
          <p class="mt-4 mb-0"><span class="font-20 primaray-text font-weight-bold"
              [innerHTML]="Service.formatDateEEMMMdd(summaryDetailsReturn.departureDate)"></span></p>
          <p><span class="gray-text-dark font-16"> {{summaryDetailsReturn.departureAirport.city}} <span
                class="text-lowercase" i18n="@@to">to</span> {{summaryDetailsReturn.arrivalAirport.city}}</span></p>

          <div class="white-box px-0 py-3">
            <div class="row h-100">
              <div class="col-1 pt-1" [ngClass]="{'pr-0': Service.isSiteLtr(), 'pl-0': Service.isSiteRtl()}">
              </div>
              <div class="col-1 px-0">
                <img width="26.5" height="27" loading="lazy" class="max-100 initial-height img-min-width"
                  [src]="summaryDetailsReturn.airlineInfoSummary.marketingAirlineLogoUrl"
                  onError="this.src='/assets/img/airline.png'" />
              </div>
              <div class="col-10 position-relative"
                [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}">
                <p class="font-18 primaray-text font-weight-bold mb-0">
                  <span class="mb-0 primaray-text"
                    [innerHTML]="this.Service.formatDateTime12Hours(summaryDetailsReturn.departureDate)">10:15pm</span>
                  -
                  <span class="mb-0 primaray-text"
                    [innerHTML]="this.Service.formatDateTime12Hours(summaryDetailsReturn.arrivalDate)">10:15pm</span>
                  <span class="font-14 font-weight-normal"
                    *ngIf="Service.checkNextDay(summaryDetailsReturn.departureDate, summaryDetailsReturn.arrivalDate) === 1"
                    i18n="@@nextDay"> (Next day)</span>
                  <span class="font-14 font-weight-normal"
                    *ngIf="Service.checkNextDay(summaryDetailsReturn.departureDate, summaryDetailsReturn.arrivalDate) > 1">
                    +{{Service.checkNextDay(summaryDetailsReturn.departureDate,
                    summaryDetailsReturn.arrivalDate)}}</span>

                  <span class="font-14 font-weight-normal"
                    *ngIf="Service.checkPreviousDay(summaryDetailsReturn.departureDate, summaryDetailsReturn.arrivalDate)"
                    i18n="@@prevDay"> (Previous day)</span>
                </p>


                <div class="d-flex">
                  <p class="gray-text-dark font-14 text-nowrap">
                    <span>{{summaryDetailsReturn.departureAirport.iata}}</span><span class="super-vertical-align"> ____
                    </span><span>{{summaryDetailsReturn.arrivalAirport.iata}}</span></p>
                  <p class="mb-0 primaray-text pt-small"
                    [ngClass]="{'ml-3': Service.isSiteLtr(), 'mr-3': Service.isSiteRtl()}"><span
                      [innerHTML]="this.Service.calDuration(summaryDetailsReturn.journeyDurationInMins)">6h 45m</span>
                    <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                      *ngIf="summaryDetailsReturn.totalStops === 1">(<ng-container i18n="@@oneStop">1
                        stop</ng-container>)</span>
                    <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                      *ngIf="summaryDetailsReturn.totalStops === 2">(<ng-container i18n="@@twoStops">2
                        stops</ng-container>)</span>
                    <span [ngClass]="{'pl-2': Service.isSiteLtr(), 'pr-2': Service.isSiteRtl()}"
                      *ngIf="summaryDetailsReturn.totalStops > 2">({{summaryDetailsReturn.totalStops}}&nbsp;<ng-container
                        i18n="@@stops">stops</ng-container>)</span>
                  </p>
                  <p class="gray-text-dark ml-2 pt-small text-lowercase" *ngIf="summaryDetailsReturn.totalStops === 0">
                    (<ng-container i18n="@@nonStop">nonstop</ng-container>)</p>
                </div>


                <!-- <p class="mb-0 gray-text-dark">{{summaryDetailsReturn.airlineInfoSummary.airline}}. {{Service.flightData.details.originDestinationOptionsGroups[1].originDestinationOptions[0].flightSegment.cabinClassName}}</p>
                <div class="gray-text" *ngIf="summaryDetailsReturn.airlineInfoSummary.operatedBy.length">
                  <p class="mb-0" *ngFor="let operator of summaryDetailsReturn.airlineInfoSummary.operatedBy" >
                  </p>
                </div>-->


                <p class="mb-0 gray-text-dark"
                  *ngIf="summaryDetailsReturn.airlineInfoSummary.marketingAirlines.length === 1">
                  {{summaryDetailsReturn.airlineInfoSummary.marketingAirlines[0]}}.
                  {{Service.flightData.details.originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.cabinClassName}}
                </p>
                <p class="mb-0 gray-text-dark"
                  *ngIf="summaryDetailsReturn.airlineInfoSummary.marketingAirlines.length > 1">
                  <span i18n="@@multipleAirlines">Multiple Airlines: </span>
                  <span *ngFor="let airlineItem of summaryDetailsReturn.airlineInfoSummary.marketingAirlines">
                    {{airlineItem}}.
                  </span>
                  {{Service.flightData.details.originDestinationOptionsGroups[0].originDestinationOptions[0].flightSegment.cabinClassName}}
                </p>
                <div class="gray-text" *ngIf="summaryDetailsReturn.airlineInfoSummary.operatedBy.length">
                  <p class="mb-0"
                    *ngFor="let operator of summaryDetailsReturn.airlineInfoSummary.operatedBy ,  let indexOperator = index">
                    {{operator}}
                  </p>
                </div>





              </div>
            </div>
          </div>
        </ng-container>
        <p class="text-right mb-0 mt-3"><span class="blue-text pointer"
            (click)="showDetails(Service.flightData.details, Service.flightData.trip)" i18n="@@showTripDetails">Show
            trip details</span></p>
      </div>


      <div class="white-box p-3 mb-4">
        <p class="font-18 mb-4" i18n="@@baggageInfo">Baggage info</p>
        <ng-container *ngIf="Service.rules">
          <div *ngFor="let item of Service.rules.baggageInfo">
            <p class="gray-primaray-text mt-3">{{item.departure}} - {{item.arrival}}</p>
            <p class="gray-text mb-1"><i class="icon-carry-on-bag font-16"
                [ngClass]="{'mr-2 pr-1': Service.isSiteLtr(), 'ml-2 pl-1': Service.isSiteRtl()}"></i><ng-container
                i18n="@@carryOnBag">Carry-on bag included</ng-container></p>
            <p class="gray-text mb-1" *ngIf="item.baggageDescription"><i class="icon-checked-bag font-20"
                [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}"></i>{{item.baggageDescription}}
            </p>
            <p class="gray-text mb-1" *ngIf="!item.baggageDescription"><i class="icon-checked-bag font-20"
                [ngClass]="{'mr-2': Service.isSiteLtr(), 'ml-2': Service.isSiteRtl()}"></i>{{item.baggage}} </p>
          </div>
        </ng-container>
      </div>
      <div class="white-box p-0 mb-4 price_summary">
        <div class=" p-3 ">
          <h5 class="text-big mb-3" [ngClass]="{'bolder' : !Service.isCompany()}" i18n="@@priceSummary">Price Summary
          </h5>

          <ng-container
            *ngFor="let traveler of Service.flightData.details.fareInfo.fareBreakdown; let travelerIndex = index">
            <ng-container
              *ngFor="let travelerRepeat of [].constructor(traveler.passengerTypeQuantity.quantity) ; let repeatIndex = index;">
              <div class="discount d-flex font-16">
                <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"
                  *ngIf="travelerIndex === 0"><ng-container i18n="@@traveler">Traveler</ng-container>{{repeatIndex +
                  1}}: <span class="text-capitalize">{{traveler.passengerTypeQuantity.type.toLowerCase()}}</span></span>
                <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"
                  *ngIf="travelerIndex === 1"><ng-container
                    i18n="@@traveler">Traveler</ng-container>{{(Service.flightData.details.fareInfo.fareBreakdown[0].passengerTypeQuantity.quantity)
                  + repeatIndex + 1}}: <span
                    class="text-capitalize">{{traveler.passengerTypeQuantity.type.toLowerCase()}}</span></span>
                <span
                  [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">${{traveler.sellingPassengerFare.totalFare.amount
                  | number: '1.2-2'}}</span>
              </div>
              <div class="discount d-flex font-14 gray-text my-2">
                <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"
                  i18n="@@flight">Flight</span> <span
                  [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">${{traveler.sellingPassengerFare.baseFare.amount
                  | number: '1.2-2'}}</span>
              </div>
              <div class="discount d-flex font-14 gray-text mb-4">
                <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"
                  i18n="@@taxAndFees">Tax and fees</span> <span
                  [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">
                  <span
                    [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">${{this.Service.calTaxes(traveler.sellingPassengerFare.taxes)
                    | number: '1.2-2' }}</span>
                </span>
              </div>
            </ng-container>
          </ng-container>
          <div class="discount d-flex font-14 gray-text mb-4"
            *ngIf="Service.flightData.details.userData.travelerId === 'guest'">
            <span [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}"
              i18n="@@bookingFee">Booking fee</span> <span
              [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}">
              <span
                [ngClass]="{'mr-auto': Service.isSiteLtr(), 'ml-auto': Service.isSiteRtl()}">${{Service.flightData.details.fareInfo.sellingTotalFares.guestFee
                | number: '1.2-2' }}</span>
            </span>
          </div>
        </div>



        <div class="white-box-footer rounded-bottom p-3">
          <div class="d-flex font-20">
            <span class="primaray-text" [ngClass]="{'bolder' : !Service.isCompany()}" i18n="@@totalPayNow"> Total to pay
              now: </span>
            <span class="primaray-text bolder"
              [ngClass]="{'ml-auto': Service.isSiteLtr(), 'mr-auto': Service.isSiteRtl()}"><span
                class="currency">${{Service.flightData.details.fareInfo.sellingTotalFares.total.amount | number:
                '1.0-2'}}</span><span class="amount"></span></span>
          </div>
        </div>
      </div>

      <div class="white-box p-0"
        *ngIf="this.Service.flightData.details.userData && this.Service.flightData.details.userData.rewardAmount">
        <div class="white-box-purple-header p-3 rounded-top text-white font-18 font-weight-bold">
          <ng-container i18n="@@rewards">Rewards</ng-container>: <ng-container
            *ngIf="Service.isSiteLtr()">$</ng-container>{{this.Service.flightData.details.userData.rewardAmount | number
          : '1.2-2'}}<ng-container *ngIf="Service.isSiteRtl()">$</ng-container>
        </div>
        <div class="px-3 pt-3 pb-4 gray-primaray-text font-16" i18n="@@uponCompletingFlightBooking">
          You will earn ${{this.Service.flightData.details.userData.rewardAmount | number : '1.2-2'}} upon completing
          this trip
        </div>
      </div>
      <app-cost-reporting-manage></app-cost-reporting-manage>
    </div>
  </div>
</div>
<!--<div [ngClass]="{'active': this.Service.ratesExpiry}"  class="snackbar d-block d-md-flex"> <p><i class="icon-warning mr-2"></i> Refresh this flight page to get the latest rates.</p><a class="text-uppercase col-md d-block text-md-right text-left" (click)="refreshPage();"> <i class="icon-refresh font-16 mr-2"></i> refresh</a></div>-->
<app-loaders></app-loaders>