import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthServiceMain} from '../../auth/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(public dataService: DataService, public router: Router, private route: ActivatedRoute,
              private logedIn: AuthServiceMain, private formBuilder: FormBuilder, private titleService:Title) {
  this.titleService.setTitle('Reset My Password | SafarHub'); }
  resetPasswordCode = 'code';
  data;
  resetPasswordForm: FormGroup;
  passwordFormSubmitted = false;
  submitting = false;
  ngOnInit() {
    this.dataService.alerts = [];
    if (this.logedIn.isLogedIn) {
      this.logedIn.logout();
    }
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]]
    }, {
    });
    this.route.queryParams.subscribe(params => {
      this.dataService.resetPasswordCodeValue = params[this.resetPasswordCode];
    });
    // this.router.navigate(['/user/password/reset']);

    if (this.dataService.resetPasswordCodeValue === ''
      || this.dataService.resetPasswordCodeValue === null || this.dataService.resetPasswordCodeValue === undefined) {
      this.dataService.messages(environment.messages.PASSWORD_RESET_ERROR, 'error');
    }

  }
  get d() { return this.resetPasswordForm.controls; }
  submitForm() {
    this.passwordFormSubmitted = true;
    this.submitting = true;
    if (this.resetPasswordForm.invalid) {
      this.submitting = false;
      return;
    }
    if (this.dataService.resetPasswordCodeValue) {
      this.dataService.resetPassword(this.resetPasswordForm.controls.password.value,
        this.dataService.resetPasswordCodeValue).subscribe(
        data => {
          this.data = data;
          if (this.data.errorCode === 0) {
            this.submitting = false;
            this.dataService.resetPasswordMessage = environment.messages.PASSWORD_RESET_SUCCESSFUL;
            this.dataService.resetPasswordMessageType = 'success';
            if  (!this.dataService.isCompany()) {
              this.router.navigate(['/user/login']);
            } else {
              const urlArray = (this.router.url).split('/');
              this.router.navigate(['/' + urlArray[1] + '/login']);
            }
          } else {
            this.submitting = false;
            this.dataService.messages(this.data.message, 'error');
          }
        },
        error => {
          this.dataService.messages(environment.messages.PASSWORD_RESET_ERROR, 'error');
        }
      );

    } else {
      this.submitting = false;
      this.dataService.messages(environment.messages.PASSWORD_RESET_ERROR, 'error');
    }
  }


}
